import { Injectable } from "@angular/core";
import { BankIFSCModel } from "../models/bank.ifsc.model";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";

@Injectable()
export class BankIFSCService {
  public bankifsclist: Array<BankIFSCModel> = new Array<BankIFSCModel>();

  constructor(private http: HttpClient, private appsetting: AppSettings) {}

  async getBankInfoOnIFSCCode(ifscCode: string): Promise<BankIFSCModel> {
    let retvalue: BankIFSCModel = this.bankifsclist.find(
      obj => obj.ifscCode == ifscCode
    );
    if (retvalue) {
      return retvalue;
    } else {
      retvalue = await this.getBankOfIFSCApi(ifscCode);
      if (retvalue) {
        this.bankifsclist.push(retvalue);
      }
      return retvalue;
    }
  }
  async getBankOfIFSCApi(ifscCode: string): Promise<BankIFSCModel> {
    let header = new HttpHeaders();
    //let param: HttpParams = new HttpParams();
    //param = param.append("ifscCode", ifscCode);
    return await this.http
      .get(this.appsetting.get("server-url") + "/api/bankofifsc/" + ifscCode, {
        headers: header,
        observe: "body",
        //params: param,
        responseType: "json"
      })
      .map(res => res)
      .toPromise()
      .then(data => {
        if (data["success"]) {
          return data["result"];
        } else {
          if (data["message"] == "NOIFSCCODEPROVIDED") {
            console.error("IFSC Code not provided");
          } else if (data["message"] == "INVALIDIFSCCODE") {
            console.error("IFSC is not valid");
          } else if (data["message"] == "ERROR") {
            console.error("Error in api");
          }
          return undefined;
        }
      })
      .catch(err => {
        console.error("Error exception in api - " + err);
        return undefined;
      });
  }

  saveBankIFSC(bankifsc: BankIFSCModel, actiontype: string) {
    let header = new HttpHeaders();
    if (actiontype == "new") {
      return this.http
        .post(
          this.appsetting.get("server-url") + "/api/newbankifsc",
          bankifsc,
          { headers: header }
        )
        .map(res => res);
    } else {
      return this.http
        .post(
          this.appsetting.get("server-url") + "/api/updatebankifsc",
          bankifsc,
          { headers: header }
        )
        .map(res => res);
    }
  }

  getBankIFSCList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (sortField) {
      if (sortField.sortFieldName && sortField.sortDirection != "") {
        httpParam = httpParam.append("sortDirection", sortField.sortDirection);
      }
      if (sortField.sortFieldName && sortField.sortFieldName != "") {
        httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
      }
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/bankifsclist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getBankIFSC(ifscCode: string) {
    let header = new HttpHeaders();
    //let httpParam: HttpParams = new HttpParams();
    //httpParam = httpParam.append("ifscCode", ifscCode);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/bankofifsc/" + ifscCode, {
        headers: header,
        observe: "body",
        //params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }
}
