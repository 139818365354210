import { MerchantModel } from "src/app/models/merchant.model";
import { UPIQRCodeDetailsModel } from 'src/app/models/UPIQRCodeDetailsModel';

export class TransactionModel {
  id?: number;
  mobileNumber?: number;
  amount?: number;
  email?: string;
  status?: number;
  userId?: number;
  merchantId?: number;
  orgid?: number;
  bankMDR?: number;
  ezMDR?: number;
  serviceTax?: number;
  datetime?: Date;
  otpDatetime?: Date;
  transactionType?: string;
  cardPanNo?: string;
  statusDescription?: string;
  customerid?: number;
  payoutStatus?: number;
  payoutDatetime?: Date;
  loyaltyId?: number;
  usedPoints?: number;
  agPayoutSTS?: number;
  aquirerCode?: string;
  switchlab?: string;
  cashbackAmt?: number;
  aquirerMDR?: number;
  acqPayoutStatus?: number;
  acqPayoutDatetime?: Date;
  aaCertificate?: string;
  aid?: string;
  sysTmutltyee?: number;
  scriptResult?: string;
  dsaMDR?: number;
  appVer?: string;
  tvr?: string;
  tsi?: string;
  cardDetail?: CardDetailModel;
  merchant?: MerchantModel;
}

export class CardDetailModel {
  id?: number;
  transactionId?: number;
  terminalId?: string;
  cardHolderName?: string;
  cardType?: string;
  ipAddress?: string;
  imeino?: string;
  lattitude?: string;
  longitude?: string;
  customerMobile?: string;
  customerEmail?: string;
  rrno?: string;
  authCode?: string;
  batchNo?: string;
  stan?: string;
  pinEntered?: string;
  applicationName?: string;
  de22?: string;
  
aid?: any;
amount?: any;
appName?: any;
appVersion?: any;
batchNumber?: any;
card?: any;
invoiceNumber?: any;
message?: any;
mid?: any;
rrn?: any;
tc?: any;
tid?: any;
tranDate?:any;
tsi?:any;
tvr?:any; 

}

export class UPITransactionModel {
  id?: string;
  datetime?: Date;
  lastupdateon?: Date;
  mid?: string;
  terminalId?: string;
  orgRespCode?: string;
  ts?: string;
  payeeVPAId?: string;
  payerVPAId?: string;
  txnId?: string;
  type?: string;
  settAmount?: number;
  settCurrency?: string;
  approvalNo?: string;
  orgStatus?: string;
  ackNotifyUrl?: string;
  phoneNo?: string;
  cub?: string;
  rrn? : string;
	refundUniqueId? : string;
	ackStatus? : string;
  ackDateTime? : string;
  dsaId?: string;
  remarks?: string;
  merchant?: MerchantModel;
  upiQrCodeDetails? : UPIQRCodeDetailsModel
}


