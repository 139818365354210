import { Component, OnInit, Inject } from "@angular/core";
import { AuthService } from "../../framework/services/auth.service";
import { Router } from "@angular/router";
import { ImageconfigService } from "../../framework/services/imageconfig.service";
import { MenuService } from "../../framework/services/menu.service";
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import { AppSettings } from "src/app/framework/config/AppSettings";
import { global } from "src/app/framework/modules/global";
import { SimpleMessage } from "src/app/framework/modules/util.class";
import { Tools } from "src/app/framework/modules/tools";
import { NgInformationModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  username: { type: String };
  password: { type: String };
  hideinvlidloginmsg: boolean = true;
  loginerrormsg: string = "";
  forgeterrormsg: string = "";
  closeResult: string;
  userId: String = "";
  version: string = "";
  showOtpPopup: boolean = false;
  otp: string = '';
  constructor(
    private authService: AuthService,
    private router: Router,
    public imagePath: ImageconfigService,
    private menuservice: MenuService,
    private modalService: NgbModal,
    private ngbModalService: NgbModal,
    private tools: Tools,
    private auth: AuthService,
  ) {

    //sessionStorage.clear();
    this.hideinvlidloginmsg = true;
    this.loginerrormsg = "";

  }

  onLoginSubmit() {
    const user = {
      username: this.username,
      password: this.password
    };
    //console.log("start");
    this.authService.authenticateUser(user).subscribe(
      data => {
         console.log("success");
        if (data.success) {
          if(data.result.forcePwdReset===true){
              this.router.navigate(["changepassword"]);
            }
            else{
          //this.authService.storeUserData(data.result.token,data.result.name);
         this.showOtpPopup=true;
         this.otp="";
          // this.authService.loadPrivileges().then(() => {
          //   this.menuservice.loadMenus().then(() => {
          //     this.hideinvlidloginmsg = true;
          //     this.loginerrormsg = "";
          //     this.authService.isSessionTimeout = false;
          //     this.router.navigate(["main"]);
          //   });
          // });
        }
          //this.flashmessage.show("You are logged in",{cssClass : "alert-success", timeout : 3000});
        } else {
          //this.flashmessage.show(data.msg,{cssClass : "alert-danger", timeout : 3000});
          this.hideinvlidloginmsg = false;
          if (data.message == "LOCKED") {
            this.loginerrormsg = "Account is locked!";
          } else if (data.message == "AUTHFAILED") {
            this.loginerrormsg = "Authentication failed!";
          } else if (data.message == "PASSWORDFORMATERROR") {
            this.loginerrormsg = "Password format is not correct!";
          } else {
            this.loginerrormsg = "Error in login!";
          }
          this.router.navigate(["/login"]);
        }
      },
      err => {
        this.hideinvlidloginmsg = true;
        this.loginerrormsg = "Error in login!";
      }
    );
  }

  verifyOtp() {
    console.log(this.otp)
    let objmsg: SimpleMessage = new SimpleMessage();
    this.auth.verifyOTPLogin(this.otp).subscribe(
      data => {
        if (data["success"]) {
          this.showOtpPopup=false;
          this.authService.loadPrivileges().then(() => {
            this.menuservice.loadMenus().then(() => {
              this.hideinvlidloginmsg = true;
              this.loginerrormsg = "";
              this.authService.isSessionTimeout = false;
              this.router.navigate(["main"]);
            });
          });
        } else {
          switch (data["message"]) {
            case "Phone Nummber not registered for user":
              objmsg.title = "Warning";
              objmsg.message = "Phone Number not registered for user!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
            case "Error":
              objmsg.title = "Error";
              objmsg.message = "Error!";
              objmsg.btnClass = "btn-error";
              this.tools.simpleMessage(objmsg);
              break;
            case "INVALIDUSER":
              objmsg.title = "Warning";
              objmsg.message = "Invalid user!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
            case "AUTHFAILED":
              objmsg.title = "Warning";
              objmsg.message = "Authentication failed!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
              case "otp request not found":
                objmsg.title = "Warning";
                objmsg.message = "OTP request not found!";
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
             
          }
        }
      },
      error => {
        console.error("Error =" + error);
        objmsg.title = "Error";
        objmsg.message = "Error!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );

  }

  closePopup() {
    this.showOtpPopup = false;
  }

  ngOnInit() {
    this.setVersion();
  }
  forgotPasswordClick(content) {
    this.userId = "";
    this.forgeterrormsg = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
          console.log(result);
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  onForgotPassword() {
    this.hideinvlidloginmsg = false;
    let objmsg: SimpleMessage = new SimpleMessage();

    if (this.userId == "" || this.userId == null) {
      this.hideinvlidloginmsg = false;
      this.forgeterrormsg = "Please provide user name";
      return false;
    } else {
      this.authService.forgotpassword(this.userId).subscribe(data => {
        if (data["success"]) {
          this.afterSuccess();
          //alert("Mail Sent! Check Your Mail");
          // this.modalService.dismissAll("Mail Sent");
        } else {
          switch (data["message"]) {
            case "INVALIDUSERNAME":
              this.forgeterrormsg = "User name is not valid";
              break;
            case "MAXATTEMPTEXCEED":
              this.hideinvlidloginmsg = false;
              this.forgeterrormsg = "Failed - as maximum attempt completed";
              break;
          }
        }
      });
      this.hideinvlidloginmsg = true;
      this.forgeterrormsg = "";
    }
  }

  afterSuccess() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.size = "sm";
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRef.componentInstance.title = "Success";
    modalRef.componentInstance.message = "Mail Sent! Check Your Mail!";
    modalRef.componentInstance.btnCLass = "btn-success";
    modalRef.result.then(
      result => {
        this.modalService.dismissAll("Mail Sent");
      },
      reason => {
        this.modalService.dismissAll("Mail Sent");
      }
    );
  }

  setVersion() {
    this.authService.getAppVersion().subscribe(data => {
      if (data["success"]) {
        this.version = "version - " + data["result"];
      } else {
        this.version = "";
        console.error("Failed to get version!")
      }
    }, err => {
        this.version = "";
        console.error("Failed to get version!")
    })
  }
}
