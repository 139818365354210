import { Injectable } from "@angular/core";
import { AppSettings } from "../framework/config/AppSettings";
import { Tools } from "../framework/modules/tools";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass, FileModel } from "../framework/modules/util.class";
import {
  MerchantModel,
  MerchantFiles,
  CompanyFiles,
} from "../models/merchant.model";
import { BankSourcingBranchModel } from "../models/BankSourcingBranch.model";

@Injectable()
export class BankMerchantService {
  bankSourcingBranchList: Array<BankSourcingBranchModel>;
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}

  getBankMerchantList(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let sourcingBranchId: string = undefined;
    if (searchField.sourcingBranch && searchField.sourcingBranch.length > 0) {
      sourcingBranchId = searchField.sourcingBranch[0].bankSourcingBranchId;
    }
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (searchField.agreementDate) {
      httpParam = httpParam.append(
        "agreementDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.agreementDate[0])
      );
      httpParam = httpParam.append(
        "agreementDateTill",
        this.tools.formatDateToDDMMMYY(searchField.agreementDate[1])
      );
    }
    if (searchField.merchantStatus && searchField.merchantStatus != "") {
      httpParam = httpParam.append(
        "merchantStatus",
        searchField.merchantStatus
      );
    }
    if (sourcingBranchId) {
      httpParam = httpParam.append("sourcingBranchId", sourcingBranchId);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/bankmerchants", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }

  getBankMerchant(merchantId: number) {
    let header = new HttpHeaders();
    //let httpParam: HttpParams = new HttpParams();
    //httpParam = httpParam.append("merchantId", "" + merchantId);
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/bankmerchant/" + merchantId,
        {
          headers: header,
          observe: "body",
          //params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }
  getSourcingBranch() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/sourcingbranch", {
        headers: header,
        observe: "body",
        responseType: "json",
      })
      .map((res) => res);
  }
  saveBankMerchant(
    merchant: MerchantModel,
    merchantFiles: MerchantFiles,
    companyFiles: CompanyFiles
  ) {
    // const header : HttpHeaders = new HttpHeaders();
    const header: HttpHeaders = new HttpHeaders();

    // header.append("Content-Type","application/x-www-form-urlencoded");
    let mfiles: Array<FileModel> = new Array<FileModel>();
    let cfiles: Array<FileModel> = new Array<FileModel>();

    if (merchantFiles) {
      Object.keys(merchantFiles).forEach((key) => {
        if (merchantFiles[key] && merchantFiles[key] instanceof File) {
          let tfile: FileModel = new FileModel();
          tfile.name = key;
          tfile.file = merchantFiles[key];
          mfiles.push(tfile);
        }
      });
    }
    if (companyFiles) {
      Object.keys(companyFiles).forEach((key) => {
        if (companyFiles[key] && companyFiles[key] instanceof File) {
          let tfile: FileModel = new FileModel();
          tfile.name = key;
          tfile.file = companyFiles[key];
          cfiles.push(tfile);
        }
      });
    }
    let formdata: FormData = new FormData();
    if (
      merchant.companyProfile &&
      Object.keys(merchant.companyProfile).length == 0
    ) {
      merchant.companyProfile = undefined;
    }

    let foundFiles: boolean = false;
    if (mfiles && mfiles.length > 0) {
      mfiles.forEach((f) => {
        foundFiles = true;
        formdata.append("merchantfiles", f.file, f.name);
      });
    }
    if (cfiles && cfiles.length > 0) {
      cfiles.forEach((f) => {
        foundFiles = true;
        formdata.append("companyfiles", f.file, f.name);
      });
    }
    if (foundFiles) {
      const blobOverrides = new Blob([JSON.stringify(merchant)], {
        type: "application/json",
      });
      formdata.append("merchant", blobOverrides);
      return this.http
        .post(
          this.appsetting.get("server-url") + "/api/bankmerchantwithfiles",
          formdata,
          { headers: header }
        )
        .map((res) => res);
    } else {
      return this.http
        .post(
          this.appsetting.get("server-url") + "/api/bankmerchant",
          merchant,
          { headers: header, responseType: "json" }
        )
        .map((res) => res);
    }
  }
}
