import { Component, OnInit, ViewChild } from '@angular/core';
import { GridtableComponent, gridColumns, GridPage } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { SortClass, SimpleMessage } from 'src/app/framework/modules/util.class';
import { Tools } from 'src/app/framework/modules/tools';
import { global } from 'src/app/framework/modules/global';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { AuthService } from 'src/app/framework/services/auth.service';
import { ReportService } from 'src/app/services/report.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-settlement-summary-report',
  templateUrl: './settlement-summary-report.component.html',
  styleUrls: ['./settlement-summary-report.component.css']
})
export class SettlementSummaryReportComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "TRANSACTION DATE",
      columnFieldName: "txnDate",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS,
    },
    {
      columnHeader: "TERMINAL Id",
      columnFieldName: "tid",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "TRANS TYPE",
      columnFieldName: "transactionType",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "TRANS VALUE",
      columnFieldName: "grossAmount",
      columnClasses: "c-col-pc-8 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "MDR",
      columnFieldName: "merchantMDR",
      columnClasses: "c-col-pc-8 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "GST ON MDR",
      columnFieldName: "gst",
      columnClasses: "c-col-pc-8 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "NEFT FEE",
      columnFieldName: "neftFee",
      columnClasses: "c-col-pc-8 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "RENT DEDUCTION",
      columnFieldName: "rentDeduction",
      columnClasses: "c-col-pc-10 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "NET SETTLEMENT AMOUNT",
      columnFieldName: "netPayment",
      columnClasses: "c-col-pc-10 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "SETTLEMENT DATE",
      columnFieldName: "settlementDate",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS,
    }
  ];
  sortFields = [
    { name: "TxnDate", display: "Transaction DateTime" },
    { name: "TID", display: "Terminal Id" },
    { name: "TransactionType", display: "Transaction Type" },
    { name: "SettleDate", display: "Settlement Date" },
    { name: "NetPayment", display: "Net Payment" }
  ];

  topbartext: TopbarText[] = [
    {
      key: "settlementsummaryreport",
      displayText: "SETTLEMENT SUMMARY",
      class: "col-md-4",
      anchorClass: "h6",
    },
  ];
  searchField = {
    dateFrom: undefined,
    dateTill: undefined,
    transactionType: undefined,
  };
  datalist: Array<any>;
  page: GridPage;
  _menuId: string;
  //isDetailScreen : boolean=false;

  sort: SortClass;
  transactionTypeList : string[]
  constructor(
    public tools: Tools,
    private objGlobal: global,
    private csvservice: ExporttocsvService,
    private auth: AuthService,
    private reportService: ReportService
  ) {
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    this._menuId = this.tools.getMenuName("main/settlementsummaryreport");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "txnDate";
    //let d = moment();
    //d = d.add(-1, "month");
    this.searchField.dateFrom = new Date();
    this.searchField.dateTill = new Date();
    this.searchField.transactionType = undefined;
    this.setTransactionType();
  }

  ngOnInit() {}

  getReportData(opt) {
    let searchField = {};
    searchField["transactionType"] = this.searchField.transactionType;
    if (this.searchField.dateFrom || this.searchField.dateTill) {
      searchField["dateRange"] = new Array<Date>();
      searchField["dateRange"].push(this.searchField.dateFrom);
      searchField["dateRange"].push(this.searchField.dateTill);
    }
    this.reportService
      .getSettlementSummaryReport(this.page, searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message =
              "Failed to settlement summary report!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to settlement summary report!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  exportSettlementSummaryReportForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    let searchField = {};
    //if (!this.allowAggregatorSelection()) {
    //  this.searchField.aggregatorId = this.auth.getUserDistributorId();
    //}
    //searchField["aggregatorId"] = this.searchField.aggregatorId;
    if (this.searchField.dateFrom || this.searchField.dateTill) {
      searchField["dateRange"] = new Array<Date>();
      searchField["dateRange"].push(this.searchField.dateFrom);
      searchField["dateRange"].push(this.searchField.dateTill);
    }
    searchField["transactionType"] = this.searchField.transactionType;

    this.reportService
      .getSettlementSummaryReport(page, searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<any>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "settlement_summary"
            );
          } else {
            alert("Failed to settlement summary report!");
          }
        },
        (error) => {
          alert("Failed to settlement summary report!");
        }
      );
  }
  onExcelClick() {
    this.exportSettlementSummaryReportForExcel();
  }

  allowAggregatorSelection() {
    return this.auth.getUserDistributorId() ? false : true;
  }

  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }

  setTransactionType() {
    this.reportService.getPaymentDistinctTranType().subscribe(res => {
      if (res["success"]) {
        this.transactionTypeList = res["result"];
      } else {
        console.error("api error to fetch transaction type list");
      }
    }, err => console.error("server error to fetch transaction type list"));
  }
}
