import { EmployeeModel } from './employee.model';
import { AggregatorDistributorModel } from './aggregator.distributor.model';
import { MerchantModel } from './merchant.model';
import { ProductModel } from './product.model';
import { DeviceStockModel } from './device.model';

export class IndentModel {
    indentId? : number;
    indentDate? : Date;
    indentNumber? : string;
    typeOfSale? : string;
    purchaser? : AggregatorDistributorModel=new AggregatorDistributorModel();
    salesPerson? : EmployeeModel=new EmployeeModel();
    employee? : EmployeeModel = new EmployeeModel();
    merchant? : MerchantModel=new MerchantModel();
    product? : ProductModel=new ProductModel();
    quantity? : number;
    rate? : number;
    totalAmount? : number;
    totalReceivedAmount? : number;
    payments? : Array<IndentPaymentModel>;
    currentStatus? : string;
    skipBHAction? : string;
    indentActions? : Array<IndentActionModel>;
    comment? : string; 
    devices? : Array<DeviceStockModel>;
}

export class IndentPaymentModel {
    indentPaymentId? : number;
    indent? : IndentModel;
    paymentDate? : Date;
    typeOfPayment? : String;
    ifscCode? : String;
    amount ? : number;      
}
export class IndentActionModel {    
    indentActionId? : number;
    indent? : IndentModel;
    action? : string;
    actionUser? : string;
    actionDate? : Date;
    comment? : string;
    seq? : number;
}