import { Component, OnInit, ViewChild } from "@angular/core";
import { PincodeModel } from "src/app/models/pincode.model";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import {
  GridPage,
  GridtableComponent,
  gridColumns
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { Tools } from "src/app/framework/modules/tools";
import { Router } from "@angular/router";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { PincodeService } from "src/app/services/pincode.service";
import { global } from "src/app/framework/modules/global";
import { AuthService } from "src/app/framework/services/auth.service";
import { NgYesNoModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";

@Component({
  selector: "app-pincode",
  templateUrl: "./pincode.component.html",
  styleUrls: ["./pincode.component.css"]
})
export class PincodeComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-5" },
    {
      columnHeader: "Pincode",
      columnFieldName: "pinCode",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "City",
      columnFieldName: "taluk",
      columnClasses: "c-col-pc-26"
    },
    {
      columnHeader: "District",
      columnFieldName: "district",
      columnClasses: "c-col-pc-26"
    },
    {
      columnHeader: "State",
      columnFieldName: "state",
      columnClasses: "c-col-pc-26"
    }
  ];
  sortFields = [
    { name: "pinCode", display: "Pincode" },
    { name: "taluk", display: "City" },
    { name: "district", display: "District" },
    { name: "state", display: "State" }
  ];

  topbartext: TopbarText[] = [
    { key: "pincodelist", displayText: "Pincode List" }
  ];

  searchField = {
    searchText: ""
  };
  sort: SortClass;
  datalist: Array<PincodeModel>;
  data: PincodeModel;
  _data: any;
  isDetailScreen: boolean = false;
  _menuId: string;
  _action: string = "";
  page: GridPage;
  _isChange: boolean = false;
  _ifscCode: string = "";

  constructor(
    public tools: Tools,
    private auth: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private objpincodeService: PincodeService,
    private objGlobal: global,
    private csvservice: ExporttocsvService
  ) {
    this.sort = new SortClass();
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );

    this.data = new PincodeModel();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/pincode");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "pinCode";
    this.getPincodeList();
  }

  ngOnInit() {}

  onRowDblClick(e: any) {
    if (e.pinCode) {
      this.topbartext = [
        { key: "pincodedetail", displayText: "Pincode Detail" }
      ];
      this._action = "update";
      this.isDetailScreen = true;
      this.getData(e.pinCode);
    }
  }
  getData(pinCode: string) {
    this.objpincodeService.getPincode(pinCode).subscribe(
      data => {
        if (data["success"]) {
          this.data = data["result"];
          this._data = this.tools.copyFullObject(this.data);
          this._action = "update";
        } else {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          switch (data["message"]) {
            case "INVALIDPINCODE":
              objmsg.title = "Error";
              objmsg.message = "Invalid Pincode!";
              objmsg.btnClass = "btn-warning";
              break;
            default:
              objmsg.title = "Error";
              objmsg.message = "Failed to get data!";
              objmsg.btnClass = "btn-warning";
          }
          this.tools.simpleMessage(objmsg);
        }
      },
      err => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to get data!";
        objmsg.btnClass = "btn-warning";
      }
    );
  }

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }

  onAddNew() {
    this._action = "new";
    this.isDetailScreen = true;
    this.topbartext = [{ key: "addpincode", displayText: "Add Pincode" }];
    this.data = new PincodeModel();
    this._data = this.tools.copyFullObject(this.data);
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "pincode data has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
            this.topbartext = [
              { key: "pincodelist", displayText: "Pincode List" }
            ];
            if (this._isChange == true) {
              this._isChange = false;
              this.getPincodeList();
            }
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
      this.topbartext = [{ key: "pincodelist", displayText: "Pincode List" }];
      if (this._isChange == true) {
        this._isChange = false;
        this.getPincodeList();
      }
    }
  }

  isModified() {
    if (!(this.data && this._data)) {
      return false;
    }
    if (this.data.pinCode != this._data.pinCode) {
      return true;
    } else if (this.data.taluk != this._data.taluk) {
      return true;
    } else if (this.data.district != this._data.district) {
      return true;
    } else if (this.data.state != this._data.state) {
      return true;
    }
    return false;
  }

  getPincodeList() {
    this.objpincodeService
      .getPincodeList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get pincode list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get pincode list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  savePincode() {
    this.objpincodeService
      .savePincode(this.data, this._action)
      .subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        if (data["success"]) {
          objmsg.title = "Information";
          objmsg.message = "Pincode data saved successfully!";
          objmsg.btnClass = "btn-info";
          this.data = data["result"];
          this._data = this.tools.copyFullObject(this.data);
          this._isChange = true;
          this._action = "update";
        } else {
          if (data["message"] == "ALREADYEXISTS") {
            objmsg.title = "Warning";
            objmsg.message = "Pincode already exists!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "INVALIDPINCOOE") {
            objmsg.title = "Error";
            objmsg.message = "Invalid Pincode to modify!";
            objmsg.btnClass = "btn-warning";
          } else {
            objmsg.title = "Error";
            objmsg.message = "Failed to save Pincode data!";
            objmsg.btnClass = "btn-error";
          }
        }
        this.tools.simpleMessage(objmsg);
      });
  }

  disableSubmit(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }

  disableCancel(f: any) {
    return !this.isModified();
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  onCancelClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to discard changes?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.data = this.tools.copyFullObject(this._data);
        }
      },
      reason => {}
    );
  }

  getPincodeListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.objpincodeService
      .getPincodeList(page, this.searchField, sort)
      .subscribe(
        data => {
          let obj: Array<PincodeModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "pincode"
            );
          } else {
            alert("Failed to get Pincode list!");
          }
        },
        error => {
          alert("Failed to get Pincode list!");
        }
      );
  }
  onExcelClick() {
    this.getPincodeListForExcel();
  }
}
