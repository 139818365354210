import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  MerchantModel,
  MerchantBankDetailModel,
  MerchantCompanyProfileModel,
  MerchantRiskModel,
  BankMerchantModel,
  MerchantFiles,
  CompanyFiles,
} from "src/app/models/merchant.model";
import { Tools } from "src/app/framework/modules/tools";
import { AuthService } from "src/app/framework/services/auth.service";
import { MerchantService } from "src/app/services/merchant.service";
import { MCCModel } from "src/app/models/MCC.model";
import { MCCService } from "src/app/services/mcc.service";
import { MDRPlanService } from "src/app/services/mdr.plan.service";
import { MDRPlanModel } from "src/app/models/mdr.plan.model";
import { TransRatesModel } from "src/app/models/trans.rate";
import { RentalPlanModel } from "src/app/models/rental.plan.model";
import { RentalplanService } from "src/app/services/rentalplan.service";
import { PincodeModel } from "src/app/models/pincode.model";
import { PincodeService } from "src/app/services/pincode.service";
import { SimpleMessage } from "../../framework/modules/util.class";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BankMerchantService } from "src/app/services/bank.merchant.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  NgYesNoModalContent,
  NgInformationModalContent,
} from "src/app/framework/util/components/modalcomponent/modal-content";
import { BankSourcingBranchModel } from "src/app/models/BankSourcingBranch.model";
import { Router } from '@angular/router';
import { BankIFSCService } from 'src/app/services/bank.ifsc.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-bankmerchantpreboard-detail",
  templateUrl: "./bankmerchantpreboard-detail.component.html",
  styleUrls: ["./bankmerchantpreboard-detail.component.css"],
})
export class BankmerchantpreboardDetailComponent implements OnInit {
  @Input()
  addPriv: boolean = false;
  @Input()
  editPriv: boolean = false;
  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  data: MerchantModel;
  _data: MerchantModel;
  merchantBank: MerchantBankDetailModel;
  _merchantBank: MerchantBankDetailModel;
  dataCompanyProfile: MerchantCompanyProfileModel;
  _dataCompanyProfile: MerchantCompanyProfileModel;
  bankmerchant: BankMerchantModel;
  _bankmerchant: BankMerchantModel;
  _action: string;

  mccDropdownSettings = {
    singleSelection: true,
    idField: "mccCode",
    textField: "description",
    allowSearchFilter: true,
    maxHeight: 200,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: "",
  };
  sourcingBranchDropdownSettings = {
    singleSelection: true,
    idField: "bankSourcingBranchId",
    textField: "sourcingBranchName",
    allowSearchFilter: true,
    maxHeight: 200,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: "",
  };
  mcclist: Array<MCCModel>;
  selMcc: Array<MCCModel>;
  mdrplanlist: Array<MDRPlanModel>;
  rentalplanlist: Array<RentalPlanModel>;
  mdrPlanTransRates: Array<TransRatesModel>;
  _newMdrPlanTransRates: Array<TransRatesModel>;
  _mdrPlanTransRates: Array<TransRatesModel>;
  _rateDebitCardLessThan2k: number;
  _rateDebitCardGreaterThan2k: number;
  _rateCreditCardNonPremium: number;
  _rateCreditCardPremium: number;
  _rateCreditCardSuperPremium: number;
  _rateInternationalCard: number;
  _rateCashAtPOS: number;
  _isFileModified: boolean;
  merchantfiles: MerchantFiles;
  companyfiles: CompanyFiles;
  previewFileType: string;
  _downloadedMerchantFiles: MerchantFiles;
  _downloadedCompanyFiles: CompanyFiles;
  imgURL: any;
  pdf: boolean = false;
  sourcingbranchlist: Array<BankSourcingBranchModel>;
  selSourcingBranch: Array<BankSourcingBranchModel>;
  _isBankMerchantMenu: boolean = false;
  constructor(
    public tools: Tools,
    private auth: AuthService,
    public merchantservice: MerchantService,
    private mccservice: MCCService,
    private mdrplanservice: MDRPlanService,
    private rentalplanservice: RentalplanService,
    private pincodeservice: PincodeService,
    private bankmerchantservice: BankMerchantService,
    private sanitizer: DomSanitizer,
    private ngbModalService: NgbModal,
    private router: Router,
    private bankifscservice : BankIFSCService
  ) {
    this.data = new MerchantModel();
    this.merchantBank = new MerchantBankDetailModel();
    this.dataCompanyProfile = new MerchantCompanyProfileModel();
    this.bankmerchant = new BankMerchantModel();
    this._data = this.tools.copyFullObject(this.data);
    merchantservice.loadMerchantConfig();
    this.populateMccList();
    
    if (this.router.url.substring(1, this.router.url.length) == "main/bankportal-merchantmanagement") {
      this._isBankMerchantMenu = true;
      this.loadBankSourcingBranch();
    }
    this.loadMDRandRentalPlanList();
    this.imgURL = "assets/images/blank.png";
  }

  ngOnInit() {}
  async loadMDRandRentalPlanList() {
    this.mdrplanlist = await this.mdrplanservice.loadMDRPlanDetailList();
    this.rentalplanlist = await this.rentalplanservice.loadRentalPlanDetailList();
  }
  resetChanges() {
    _downloadedMerchantFiles: undefined;
    this._downloadedCompanyFiles = undefined;
    this.merchantfiles = new MerchantFiles();
    this.companyfiles = new CompanyFiles();
  }
  addNew() {
    this.resetChanges();
    this._isFileModified = false;
    this.selMcc = undefined;
    this.selSourcingBranch = undefined;
    this._action = "new";
    this.data = new MerchantModel();
    this.data.isBankMerchant = true;
    this._data = new MerchantModel();
    this.merchantBank = new MerchantBankDetailModel();
    this._merchantBank = new MerchantBankDetailModel();
    this.dataCompanyProfile = new MerchantCompanyProfileModel();
    this._dataCompanyProfile = new MerchantCompanyProfileModel();
    this.bankmerchant = new BankMerchantModel();
    this._bankmerchant = new BankMerchantModel();
  }
  loadBankSourcingBranch() {
    if (this.bankmerchantservice.bankSourcingBranchList) {
      let sourcingbranchlist = [];
      if (this.bankmerchantservice.bankSourcingBranchList) {
        this.bankmerchantservice.bankSourcingBranchList.forEach(sourcingbranch => {
          sourcingbranchlist.push({
            bankSourcingBranchId : sourcingbranch.bankSourcingBranchId,
            sourcingBranchName: sourcingbranch.sourcingBranchCode + " - " + sourcingbranch.sourcingBranchName,
            bankIFSC : sourcingbranch.bankIFSC
            })
        })
      }
      this.sourcingbranchlist = sourcingbranchlist;
    } else {
      this.bankmerchantservice.getSourcingBranch().subscribe((data) => {
        if (data["success"]) {
          this.bankmerchantservice.bankSourcingBranchList = data["result"];
          this.sourcingbranchlist = this.bankmerchantservice.bankSourcingBranchList;
        } else {
          console.error("Error from api while fetching sourcingbranch list!");
          this.sourcingbranchlist = undefined;
        }
      });
    }
  }
  async onSelectMDRPlan() {
    const selmdrplan = this.mdrplanlist.filter(
      (itm) => itm.code == this.data.merchantMDRPlan
    );
    if (selmdrplan && selmdrplan.length > 0) {
      this.mdrPlanTransRates = selmdrplan[0].transRates;
      this.data.merchantCategory = selmdrplan[0].merchantCategory;
      this.data.partnerSharing = selmdrplan[0].partnerSharing;
      this.setDefaultMDRRates();
    } else {
      this.mdrPlanTransRates = this._newMdrPlanTransRates;
      this.data.merchantCategory = undefined;
      this.data.partnerSharing = undefined;
    }
  }
  setDefaultMDRRates() {
    this.bankmerchant.cashAtPOSRate = undefined;
    this.bankmerchant.creditCardNonPremiumRate = undefined;
    this.bankmerchant.creditCardPremiumRate = undefined;
    this.bankmerchant.creditCardSuperPremiumRate = undefined;
    this.bankmerchant.debitCardGreaterThan2kRate = undefined;
    this.bankmerchant.debitCardLessThan2kRate = undefined;
    this.bankmerchant.internationalCardRate = undefined;
    if (this.mdrPlanTransRates && this.mdrPlanTransRates.length > 0) {
      this.mdrPlanTransRates.forEach((element) => {
        if (
          element.transCategory.category == "D" &&
          element.transCategory.subcategory == "domesticDebitUpTo2000"
        ) {
          this.bankmerchant.debitCardLessThan2kRate = element.mmPercent;
        }
        if (
          element.transCategory.category == "D" &&
          element.transCategory.subcategory == "domesticDebitAbove2000"
        ) {
          this.bankmerchant.debitCardGreaterThan2kRate = element.mmPercent;
        }
        if (
          element.transCategory.category == "C" &&
          element.transCategory.subcategory == "classicOrGoldOrElectron"
        ) {
          this.bankmerchant.creditCardNonPremiumRate = element.mmPercent;
        }
        if (
          element.transCategory.category == "C" &&
          element.transCategory.subcategory == "platinumOrRewards"
        ) {
          this.bankmerchant.creditCardPremiumRate = element.mmPercent;
        }
        if (
          element.transCategory.category == "C" &&
          element.transCategory.subcategory ==
            "businessOrCorporateOrcommercialOrpurchasing"
        ) {
          this.bankmerchant.creditCardSuperPremiumRate = element.mmPercent;
        }
        if (
          element.transCategory.category == "C" &&
          element.transCategory.subcategory == "internationalDebitCard"
        ) {
          this.bankmerchant.internationalCardRate = element.mmPercent;
        }
        if (
          element.transCategory.category == "O" &&
          element.transCategory.subcategory == "cap1To100"
        ) {
          this.bankmerchant.cashAtPOSRate = element.mmFixed;
        }
      });
    }
  }
  async onSelectRentalPlan() {
    const selrentalplan = this.rentalplanlist.filter(
      (itm) => itm.code == this.bankmerchant.rentalPlan
    );
    if (selrentalplan && selrentalplan.length > 0) {
      this.bankmerchant.rentFrequency = selrentalplan[0].rentFrequency;
      this.bankmerchant.rentAmount = selrentalplan[0].rent;
    } else {
      this.bankmerchant.rentFrequency = undefined;
      this.bankmerchant.rentAmount = undefined;
    }
  }

  getMerchantStatus() {
    switch (this.data.merchantStatus) {
      case "PB": {
        return "Pre-Boareded";
      }
      case "S": {
        return "Boarding";
      }
      case "R": {
        return "Ready for approval";
      }
      case "A": {
        return "Approved";
      }
      case "C": {
        return "Boarded";
      }
      default:
        return undefined;
    }
  }
  populateMccList() {
    this.mccservice.getAllMCC().subscribe(
      (res) => {
        if (res["success"]) {
          this.mcclist = res["result"];
        } else {
          this.mcclist = new Array<MCCModel>();
          console.error("failed to fetch mcc list!");
        }
      },
      (err) => {
        this.mcclist = new Array<MCCModel>();
        console.error("error while fetching mcc list!" + err);
      }
    );
  }
  disableCancel() {
    return !this.isModified();
  }

  isInternationCardAcceptance() {
    return this.bankmerchant.internationalCardAcceptance;
  }
  onOffInternationCardAcceptance() {
    let val: boolean = false;
    if (this.bankmerchant.internationalCardAcceptance) {
      val =
        this.bankmerchant.internationalCardAcceptance == true ? false : false;
    } else {
      val = true;
    }
    this.bankmerchant.internationalCardAcceptance = val;
  }

  disableSave(f: any) {
    //return false;
    let isDisableSave: boolean = true;
    if (this.data.merchantStatus && this.data.merchantStatus != "BB") {
      return true;
    }
    if (f.valid) {
      const isModified: boolean = this.isModified();
      if (isModified) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }
  requiredGST() {
    return !(this.dataCompanyProfile.businessEntityType == "01");
  }
  phoneNumberValidationMsg() {
    return this.tools.phoneValidationMsg();
  }
  emailValidationMsg() {
    return this.tools.emailValidationMsg();
  }
  gstValidationMsg() {
    return this.tools.gstValidationMsg();
  }
  panValidationMsg() {
    return this.tools.panValidationMsg();
  }
  aadhaarValidationMsg() {
    return this.tools.aadhaarValidationMsg();
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.addPriv;
    } else if (this._action == "update") {
      isAllowSave = this.editPriv;
    }
    return isAllowSave;
  }
  isModified() {
    if (this._isFileModified == true) {
      return true;
    }
    if (!this.data && !this._data) {
      return false;
    }
    if (!this.data && this._data) {
      return true;
    }
    if (this.data && !this._data) {
      return true;
    }
    if (
      !this.tools.isDateSame(this.data.agreementDate, this._data.agreementDate)
    ) {
      return true;
    }
    if (this.data.authPersonFirstName != this._data.authPersonFirstName) {
      return true;
    }
    if (this.data.authPersonLastName != this._data.authPersonLastName) {
      return true;
    }
    if (this.data.companyName != this._data.companyName) {
      return true;
    }
    if (this.data.phoneNumber != this._data.phoneNumber) {
      return true;
    }
    if (this.data.emailId != this._data.emailId) {
      return true;
    }
    if (this.data.gst != this._data.gst) {
      return true;
    }
    if (this.data.pan != this._data.pan) {
      return true;
    }
    if (this.data.aadhaar != this._data.aadhaar) {
      return true;
    }
    if (this.data.businessAddress != this._data.businessAddress) {
      return true;
    }
    if (this.data.businessAddressCity != this._data.businessAddressCity) {
      return true;
    }
    if (
      this.data.businessAddressDistrict != this._data.businessAddressDistrict
    ) {
      return true;
    }
    if (this.data.businessAddressPinCode != this._data.businessAddressPinCode) {
      return true;
    }
    if (this.data.businessAddressState != this._data.businessAddressState) {
      return true;
    }
    if (this.data.preboardingDate && !this._data.preboardingDate) {
      return true;
    }
    if (this.data.merchantCategory != this._data.merchantCategory) {
      return true;
    }
    if (this.data.merchantMDRPlan != this._data.merchantMDRPlan) {
      return true;
    }
    if (this.isModifiedBankMerchant()) {
      return true;
    }
    if (this.isModifiedMerchantBankData()) {
      return true;
    }
    if (this.isModifiedCompanyData()) {
      return true;
    }
    return false;
  }
  isModifiedCompanyData() {
    if (!this.dataCompanyProfile && !this._dataCompanyProfile) {
      return false;
    }
    if (this.dataCompanyProfile && !this._dataCompanyProfile) {
      return true;
    }
    if (!this.dataCompanyProfile && this._dataCompanyProfile) {
      return true;
    }
    if (
      this.dataCompanyProfile.businessEntityType !=
      this._dataCompanyProfile.businessEntityType
    ) {
      return true;
    }
    let newMCCCode: string = undefined;
    if (this.selMcc && this.selMcc.length > 0) {
      newMCCCode = this.selMcc[0].mccCode;
    }
    if (newMCCCode != this.dataCompanyProfile.companyMCC) {
      return true;
    }
    return false;
  }
  isModifiedMerchantBankData() {
    if (!this.merchantBank && !this._merchantBank) {
      return false;
    }
    if (this.merchantBank && !this._merchantBank) {
      return true;
    }
    if (!this.merchantBank && this._merchantBank) {
      return true;
    }
    if (this.merchantBank.accountType != this._merchantBank.accountType) {
      return true;
    }
    if (this.merchantBank.accountNumber != this._merchantBank.accountNumber) {
      return true;
    }
    if (this.merchantBank.ifscCode != this._merchantBank.ifscCode) {
      return true;
    }
    if (this.merchantBank.bankName != this._merchantBank.bankName) {
      return true;
    }
    if (this.merchantBank.branchName != this._merchantBank.branchName) {
      return true;
    }
    if (this.merchantBank.city != this._merchantBank.city) {
      return true;
    }
    if (this.merchantBank.state != this._merchantBank.state) {
      return true;
    }
    if (this.merchantBank.pinCode != this._merchantBank.pinCode) {
      return true;
    }
    return false;
  }
  isModifiedBankMerchant() {
    if (!this.bankmerchant && !this._bankmerchant) {
      return false;
    }
    if (this.bankmerchant && !this._bankmerchant) {
      return true;
    }
    if (!this.bankmerchant && this._bankmerchant) {
      return true;
    }
    if (
      this.bankmerchant.debitCardLessThan2kRate !=
      this._bankmerchant.debitCardLessThan2kRate
    ) {
      return true;
    }
    if (
      this.bankmerchant.debitCardGreaterThan2kRate !=
      this._bankmerchant.debitCardGreaterThan2kRate
    ) {
      return true;
    }
    if (
      this.bankmerchant.creditCardNonPremiumRate !=
      this._bankmerchant.creditCardNonPremiumRate
    ) {
      return true;
    }
    if (
      this.bankmerchant.creditCardPremiumRate !=
      this._bankmerchant.creditCardPremiumRate
    ) {
      return true;
    }
    if (
      this.bankmerchant.creditCardSuperPremiumRate !=
      this._bankmerchant.creditCardSuperPremiumRate
    ) {
      return true;
    }
    if (
      this.bankmerchant.internationalCardRate !=
      this._bankmerchant.internationalCardRate
    ) {
      return true;
    }
    if (this.bankmerchant.cashAtPOSRate != this._bankmerchant.cashAtPOSRate) {
      return true;
    }
    if (
      this.bankmerchant.numberOfTerminal != this._bankmerchant.numberOfTerminal
    ) {
      return true;
    }
    if (this.bankmerchant.rentAmount != this._bankmerchant.rentAmount) {
      return true;
    }
    if (this.bankmerchant.rentFrequency != this._bankmerchant.rentFrequency) {
      return true;
    }
    if (this.bankmerchant.rentalPlan != this._bankmerchant.rentalPlan) {
      return true;
    }
    if (
      !this.tools.isDateSame(
        this.bankmerchant.rentStartDate,
        this._bankmerchant.rentStartDate
      )
    ) {
      return true;
    }
    return false;
  }
  async setBusinessAddressPincodeData() {
    const pincodedata: PincodeModel = await this.pincodeservice.getPincodeData(
      this.data.businessAddressPinCode
    );
    if (pincodedata) {
      this.data.businessAddressCity = pincodedata.taluk;
      this.data.businessAddressDistrict = pincodedata.district;
      this.data.businessAddressState = pincodedata.state;
    } else {
      this.data.businessAddressCity = undefined;
      this.data.businessAddressDistrict = undefined;
      this.data.businessAddressState = undefined;
    }
  }

  getDetail(id: number) {
    this.resetChanges();
    this._action = "update";
    this._isFileModified = false;
    if (id) {
      this.bankmerchantservice.getBankMerchant(id).subscribe(
        (data) => {
          
          if (data["success"]) {
            this.data = data["result"];
            this.distributeData();
          } else {
            if (data["message"] == "NOT-FOUND") {
              let objmsg: SimpleMessage = new SimpleMessage();
              let ngmodaloptions: NgbModalOptions = {};
              ngmodaloptions.backdrop = "static";
              ngmodaloptions.size = "sm";
              objmsg.title = "Error";
              objmsg.message = "Invalid Merchant Id!";
              objmsg.btnClass = "btn-error";
              this.tools.simpleMessage(objmsg);
            } else if (data["message"] == "Error") {
              let objmsg: SimpleMessage = new SimpleMessage();
              let ngmodaloptions: NgbModalOptions = {};
              ngmodaloptions.backdrop = "static";
              ngmodaloptions.size = "sm";
              objmsg.title = "Error";
              objmsg.message = "Error found in response!";
              objmsg.btnClass = "btn-error";
              this.tools.simpleMessage(objmsg);
            }
          }
        },
        (err) => {
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Error";
          objmsg.message = "Error while fetching record!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }
  distributeData() {
    this.selMcc = undefined;
    this.selSourcingBranch = undefined;
    if (this.data) {
      if (this.data.agreementDate) {
        this.data.agreementDate = new Date(this.data.agreementDate);
      }
      this.selMcc = undefined;
      if (this.data.companyProfile) {
        this.dataCompanyProfile = this.data.companyProfile;
        this.selMcc = this.mcclist.filter(
          (obj) => obj.mccCode == this.dataCompanyProfile.companyMCC
        );
      } else {
        this.dataCompanyProfile = new MerchantCompanyProfileModel();
      }
      if (this.data.bankDetail) {
        this.merchantBank = this.data.bankDetail;
      } else {
        this.merchantBank = new MerchantBankDetailModel();
      }
      if (this.data.bankmerchant) {
        this.bankmerchant = this.data.bankmerchant;
        if (this.bankmerchant.rentStartDate) {
          this.bankmerchant.rentStartDate = new Date(
            this.bankmerchant.rentStartDate
          );
        }
        this.selSourcingBranch = undefined;
        if (this.bankmerchant.sourcingBranchId) {
          if (this.sourcingbranchlist && this.sourcingbranchlist.length > 0) {
            const selSB = this.sourcingbranchlist.filter(
              (obj) =>
                obj.bankSourcingBranchId == this.bankmerchant.sourcingBranchId
            );

            if (selSB && selSB.length > 0) {
              this.selSourcingBranch = [
                {
                  bankSourcingBranchId: selSB[0].bankSourcingBranchId,
                  sourcingBranchName: selSB[0].sourcingBranchName,
                },
              ];
            } else {
              this.selSourcingBranch = [
                { bankSourcingBranchId: this.bankmerchant.sourcingBranchId },
              ];
            }
          }
        }
      } else {
        this.bankmerchant = new BankMerchantModel();
      }
    }
    this._data = this.tools.copyFullObject(this.data);
    this._dataCompanyProfile = this.tools.copyFullObject(
      this.dataCompanyProfile
    );
    this._merchantBank = this.tools.copyFullObject(this.merchantBank);
    this._bankmerchant = this.tools.copyFullObject(this.bankmerchant);
  }

  setTransRateToFields(transrate: TransRatesModel) {
    this._rateDebitCardLessThan2k = undefined;
    this._rateDebitCardGreaterThan2k = undefined;
    this._rateCreditCardNonPremium = undefined;
    this._rateCreditCardPremium = undefined;
    this._rateCreditCardSuperPremium = undefined;
    this._rateInternationalCard = undefined;
    this._rateCashAtPOS = undefined;
    if (this.data.transRates) {
      for (let i: number = 0; i < this.data.transRates.length; i++) {
        if (this.data.transRates[i].transCategory) {
          const transrate = this.data.transRates[i];
          if (
            transrate.transCategory.category == "D" &&
            transrate.transCategory.subcategory == "domesticDebitUpTo2000"
          ) {
            this._rateDebitCardLessThan2k = transrate.mmPercent;
          }
          if (
            transrate.transCategory.category == "D" &&
            transrate.transCategory.subcategory == "domesticDebitAbove2000"
          ) {
            this._rateDebitCardGreaterThan2k = transrate.mmPercent;
          }
          if (
            transrate.transCategory.category == "C" &&
            transrate.transCategory.subcategory == "classicOrGoldOrElectron"
          ) {
            this._rateCreditCardNonPremium = transrate.mmPercent;
          }
          if (
            transrate.transCategory.category == "C" &&
            transrate.transCategory.subcategory == "platinumOrRewards"
          ) {
            this._rateCreditCardPremium = transrate.mmPercent;
          }
          if (
            transrate.transCategory.category == "C" &&
            (transrate.transCategory.subcategory ==
              "businessOrCorporateOrcommercialOrpurchasing|signatureOrInfiniteOrothers" ||
              transrate.transCategory.subcategory ==
                "businessOrCorporateOrcommercialOrpurchasing" ||
              transrate.transCategory.subcategory ==
                "signatureOrInfiniteOrothers")
          ) {
            this._rateCreditCardSuperPremium = transrate.mmPercent;
          }
        }
      }
    }
  }
  handleMerchantFileInput(files: FileList, inputName: string) {
    if (files && files.length > 0) {
      var mimeType = files.item(0).type;
      if (mimeType.match(/image\/*/) != null || mimeType == "application/pdf") {
        if (!this.merchantfiles) {
          this.merchantfiles = new MerchantFiles();
        }
        if (inputName == "panFile") {
          this.merchantfiles.panFile = files.item(0);
        } else if (inputName == "gstFile") {
          this.merchantfiles.gstFile = files.item(0);
        } else if (inputName == "aadhaarFile") {
          this.merchantfiles.aadhaarFile = files.item(0);
        } else if (inputName == "voterIdFile") {
          this.merchantfiles.voterIdFile = files.item(0);
        } else if (inputName == "otherIdFile") {
          this.merchantfiles.otherIdFile = files.item(0);
        } else if (inputName == "cancelledChequeFile") {
          this.merchantfiles.cancelledChequeFile = files.item(0);
        } else if (inputName == "passportFile") {
          this.merchantfiles.passportFile = files.item(0);
        }
        this._isFileModified = true;
      } else {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Alert";
        objmsg.message = "Selected file type is not valid!";
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    }
  }
  handleCompanyFileInput(files: FileList, inputName: string) {
    if (files && files.length > 0) {
      var mimeType = files.item(0).type;
      if (mimeType.match(/image\/*/) != null || mimeType == "application/pdf") {
        if (!this.companyfiles) {
          this.companyfiles = new CompanyFiles();
        }
        if (inputName == "panFile") {
          this.companyfiles.panFile = files.item(0);
        } else if (inputName == "gstFile") {
          this.companyfiles.gstFile = files.item(0);
        } else if (inputName == "addressProofFile") {
          this.companyfiles.addressProofFile = files.item(0);
        } else if (inputName == "establishmentLicenseFile") {
          this.companyfiles.establishmentLicenseFile = files.item(0);
        } else if (inputName == "partnershipDeedFile") {
          this.companyfiles.partnershipDeedFile = files.item(0);
        } else if (inputName == "certificateOfIncorporationFile") {
          this.companyfiles.certificateOfIncorporationFile = files.item(0);
        } else if (inputName == "certificateMemorandumFile") {
          this.companyfiles.memorandumFile = files.item(0);
        } else if (inputName == "otherDoc1") {
          this.companyfiles.otherDoc1File = files.item(0);
        } else if (inputName == "otherDoc2") {
          this.companyfiles.otherDoc2File = files.item(0);
        } else if (inputName == "brdirlist") {
          this.companyfiles.brDirListFile = files.item(0);
        } else if (inputName == "electricitybill") {
          this.companyfiles.electricityBillFile = files.item(0);
        } else if (inputName == "telephonebill") {
          this.companyfiles.telephoneBillFile = files.item(0);
        } else if (inputName == "photograph") {
          this.companyfiles.photographFile = files.item(0);
        } else if (inputName == "cancelledChequeFile") {
          this.companyfiles.cancelledChequeFile = files.item(0);
        }
        this._isFileModified = true;
      } else {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Alert";
        objmsg.message = "Selected file type is not valid!";
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    }
  }
  isCompanyGstFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile && this.dataCompanyProfile.companyGstFileName) ||
      (this.companyfiles && this.companyfiles.gstFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isMerchantPanFileExists() {
    return (
      (this.data && this.data.panFileName) ||
      (this.merchantfiles && this.merchantfiles.panFile)
    );
  }
  isMerchantAadhaarExists() {
    return (
      (this.data && this.data.aadhaarFileName) ||
      (this.merchantfiles && this.merchantfiles.aadhaarFile)
    );
  }
  disableMerchantPanPreview() {
    return !this.isMerchantPanFileExists();
  }
  disableCompanyGstPreview() {
    return !this.isCompanyGstFileExists();
  }
  disableMerchantAadhaarPreview() {
    return !this.isMerchantAadhaarExists();
  }
  previewImage(fileType) {}
  onCancel() {}

  async previewMerchantImage(fileType: string) {
    let file: File;
    let fileName: string;
    switch (fileType) {
      case "pan":
        file = this.merchantfiles.panFile;
        this.previewFileType = "PAN";
        fileName = this.data.panFileName;
        break;
      case "aadhaar":
        file = this.merchantfiles.aadhaarFile;
        this.previewFileType = "AADHAAR";
        fileName = this.data.aadhaarFileName;
        break;
      case "gst":
        file = this.merchantfiles.gstFile;
        this.previewFileType = "GST";
        fileName = this.data.gstFileName;
        break;
      case "voterId":
        file = this.merchantfiles.voterIdFile;
        this.previewFileType = "Voter Id";
        fileName = this.data.voterIdFileName;
        break;
      case "otherId":
        file = this.merchantfiles.otherIdFile;
        this.previewFileType = "Other Id";
        fileName = this.data.otherIdFileName;
        break;
      case "cancelledCheque":
        file = this.merchantfiles.cancelledChequeFile;
        this.previewFileType = "Cancelled Cheque";
        fileName = this.data.cancelledChequeFileName;
        break;
      case "passport":
        file = this.merchantfiles.passportFile;
        this.previewFileType = "passport";
        fileName = this.data.passportFileName;
        break;
    }
    if (!file) {
      file = this.getFromDownloaedMerchantFiles(fileType);
    }
    var reader = new FileReader();
    if (file) {
      var mimeType = file.type;
      this.showImage(file);
    } else {
      await this.merchantservice
        .downloadMerchantfile(this.data.merchantId, fileType)
        .then((data) => {
          this.saveDownloadedMerchantFile(
            new File([data], fileType + "File", { type: data.type }),
            fileType
          );

          this.showImage(data);
        });
    }
  }
  onImgCloseClick() {
    $("#imgpreview").modal("hide");
  }
  showImage(file) {
    var reader = new FileReader();
    if (file.type.match(/image\/*/) != null) {
      this.pdf = false;
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
        if (this.imgURL && this.imgURL != "") {
          $("#imgpreview").modal({ backdrop: false });
        }
      };
    } else if (file.type == "application/pdf") {
      this.pdf = true;
      let fileUrl = URL.createObjectURL(file);
      this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
      if (this.imgURL && this.imgURL != "") {
        $("#imgpreview").modal({ backdrop: false });
      }
    }
  }
  saveDownloadedMerchantFile(file: File, inputName) {
    if (!this._downloadedMerchantFiles) {
      this._downloadedMerchantFiles = new MerchantFiles();
    } else if (inputName == "pan") {
      this._downloadedMerchantFiles.panFile = file;
    } else if (inputName == "gst") {
      this._downloadedMerchantFiles.gstFile = file;
    } else if (inputName == "aadhaar") {
      this._downloadedMerchantFiles.aadhaarFile = file;
    } else if (inputName == "votherId") {
      this._downloadedMerchantFiles.voterIdFile = file;
    } else if (inputName == "otherId") {
      this._downloadedMerchantFiles.otherIdFile = file;
    } else if (inputName == "cancelledCheque") {
      this._downloadedMerchantFiles.otherIdFile = file;
    }
  }

  getFromDownloaedMerchantFiles(fileType: string) {
    let file: File;
    if (this._downloadedMerchantFiles) {
      switch (fileType) {
        case "pan":
          file = this._downloadedMerchantFiles.panFile;
          break;
        case "aadhaar":
          file = this._downloadedMerchantFiles.aadhaarFile;
          break;
        case "gst":
          file = this._downloadedMerchantFiles.gstFile;
          break;
        case "voterId":
          file = this._downloadedMerchantFiles.voterIdFile;
          break;
        case "otherId":
          file = this._downloadedMerchantFiles.otherIdFile;
          break;
        case "cancelledCheque":
          file = this._downloadedMerchantFiles.cancelledChequeFile;
          break;
      }
    }
    return file;
  }

  async previewCompanyImage(fileType: string) {
    let file: File;
    if (this.companyfiles) {
      switch (fileType) {
        case "pan":
          file = this.companyfiles.panFile;
          this.previewFileType = "PAN";
          break;
        case "gst":
          file = this.companyfiles.gstFile;
          this.previewFileType = "GST";
          break;
      }
    }
    if (!file) {
      file = this.getFromDownloaedCompanyFiles(fileType);
    }
    var reader = new FileReader();
    if (file) {
      this.showImage(file);
    } else {
      await this.merchantservice
        .downloadCompanyfile(
          this.dataCompanyProfile.merchantCompanyProfileId,
          fileType
        )
        .then((data) => {
          this.saveDownloadedCompanyFile(
            new File([data], fileType + "File", { type: data.type }),
            fileType
          );
          this.showImage(data);
        });
    }
  }
  saveDownloadedCompanyFile(file: File, inputName) {
    if (!this._downloadedCompanyFiles) {
      this._downloadedCompanyFiles = new CompanyFiles();
    } else if (inputName == "pan") {
      this._downloadedCompanyFiles.panFile = file;
    } else if (inputName == "gst") {
      this._downloadedCompanyFiles.gstFile = file;
    }
  }

  getFromDownloaedCompanyFiles(fileType: string) {
    let file: File;
    if (this._downloadedCompanyFiles) {
      switch (fileType) {
        case "pan":
          file = this._downloadedCompanyFiles.panFile;
          break;
        case "gst":
          file = this._downloadedCompanyFiles.gstFile;
          break;
      }
    }
    return file;
  }

  closeScreen() {
    this.data = new MerchantModel();
    this.dataCompanyProfile = new MerchantCompanyProfileModel();
    this.merchantBank = new MerchantBankDetailModel();
    this.bankmerchant = new BankMerchantModel();

    this.companyfiles = undefined;
    this.merchantfiles = undefined;
    this.onClose.emit(false);
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Merchant data has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this._action = "";
            this.closeScreen();
          }
        },
        (reason) => {}
      );
    } else {
      this._action = "";
      this.closeScreen();
    }
  }
  collectData() {
    if (this.selMcc && this.selMcc.length > 0) {
      this.dataCompanyProfile.companyMCC = this.selMcc[0].mccCode;
    }
    if (this.selSourcingBranch && this.selSourcingBranch.length > 0) {
      this.bankmerchant.sourcingBranchId = this.selSourcingBranch[0].bankSourcingBranchId;
    }
    if (Object.keys(this.dataCompanyProfile).length > 0) {
      this.data.companyProfile = this.dataCompanyProfile;
    }
    if (Object.keys(this.merchantBank).length > 0) {
      this.data.bankDetail = this.merchantBank;
    }
    if (Object.keys(this.bankmerchant).length > 0) {
      this.data.bankmerchant = this.bankmerchant;
    }
  }
  onSaveDataClick() {
    if (this.validateData()) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Are you sure to save merchant data?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.collectData();
            this.saveData();
          }
        },
        (reason) => {}
      );
    }
  }
  validateData() {
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    objmsg.title = "Warning";
    objmsg.btnClass = "btn-warning";

    if (
      new Date(this.tools.formatDateToDDMMMYY(this.data.agreementDate)) >
      new Date()
    ) {
      objmsg.message = "Preboard date should not be greater than current date!";
      this.tools.simpleMessage(objmsg);
      return false;
    }
    return true;
  }
  saveData() {
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    this.collectData();
    this.bankmerchantservice
      .saveBankMerchant(this.data, this.merchantfiles, this.companyfiles)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.data = data["result"];
            objmsg.title = "Success";
            objmsg.message = "Merchant Detail successfully saved!";
            objmsg.btnClass = "btn-success";
            this.tools.simpleMessage(objmsg);
            this.resetChanges();
            this.distributeData();
            this._isFileModified = false;
            this._downloadedMerchantFiles = undefined;
            this._downloadedCompanyFiles = undefined;
          } else {
            this.data.merchantStatus = this._data.merchantStatus;
            switch (data["message"]) {
              case "NAMEALREADYEXISTS": {
                objmsg.title = "Alert";
                objmsg.message =
                  "Merchant company name already exists for different record!";
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
              }
              case "INVALIDRECORD": {
                objmsg.title = "Alert";
                objmsg.message = "Invalid record to modify!";
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
              }
              default: {
                objmsg.title = "Error";
                objmsg.message = "Error in save record!";
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
              }
            }
          }
        },
        (err) => {
          this.data.merchantStatus = this._data.merchantStatus;
          objmsg.title = "Error";
          objmsg.message = "Error in save record!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  clearSourcingBranchBaseBankInfo() {
    this.merchantBank.bankSourcingBranchId = undefined;
    this.merchantBank.bankName = undefined;
    this.merchantBank.branchName = undefined;
    this.merchantBank.ifscCode = undefined;
    this.merchantBank.city = undefined;
    this.merchantBank.state = undefined;
    this.merchantBank.pinCode = undefined;
  }
  onSourcingBranchSelect(itm) {
    console.log(itm);
    if (itm) {
      const selSourcingBranchItm = this.sourcingbranchlist.filter(
        (obj) => obj.bankSourcingBranchId == itm.bankSourcingBranchId
      );
      if (selSourcingBranchItm && selSourcingBranchItm.length > 0) {
        this.selSourcingBranch = [selSourcingBranchItm[0]];
        if (selSourcingBranchItm[0].bankIFSC) {
          const bankIfscData = selSourcingBranchItm[0].bankIFSC;
          this.merchantBank.bankSourcingBranchId =
            selSourcingBranchItm[0].bankSourcingBranchId;
          this.merchantBank.bankName = bankIfscData.bankName;
          this.merchantBank.branchName = bankIfscData.branchName;
          this.merchantBank.ifscCode = bankIfscData.ifscCode;
          this.merchantBank.micr = bankIfscData.micr;
          this.merchantBank.city = bankIfscData.city;
          this.merchantBank.state = bankIfscData.state;
          this.merchantBank.pinCode = bankIfscData.pincode;
        } else {
          this.clearSourcingBranchBaseBankInfo();
        }
      } else {
        this.clearSourcingBranchBaseBankInfo();
      }
    } else {
      this.clearSourcingBranchBaseBankInfo();
    }
  }

  getMDRPlanList() {
    let mccSegment: string = "";
    if (this.selMcc && this.selMcc[0]) {
      const selectedMCC = this.mcclist.filter(
        (obj) => obj.mccCode == this.selMcc[0].mccCode
      );
      if (selectedMCC && selectedMCC[0]) {
        mccSegment = selectedMCC[0].businessSegment;
      }
    }

    if (this.mdrplanlist) {
      return this.mdrplanlist.filter(
        (itm) => itm.businessSegment == mccSegment
      );
    }
  }

  getRentDateConfig() {
    return this.tools.getBsDatePickerSetMinDate();
  }

  async setBankIFSCData() {
    const bankifscdata = await this.bankifscservice.getBankInfoOnIFSCCode(
      this.merchantBank.ifscCode
    );

    if (bankifscdata) {
      this.merchantBank.micr = bankifscdata.micr;
      this.merchantBank.bankName = bankifscdata.bankName;
      this.merchantBank.branchName = bankifscdata.branchName;
      this.merchantBank.city = bankifscdata.city;
      this.merchantBank.state = bankifscdata.state;
      this.merchantBank.pinCode = bankifscdata.pincode;
    } else {
      this.merchantBank.micr = undefined;
      this.merchantBank.bankName = undefined;
      this.merchantBank.branchName = undefined;
      this.merchantBank.city = undefined;
      this.merchantBank.state = undefined;
      this.merchantBank.pinCode = undefined;
    }
  }
}
