import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  DoCheck,
  AfterViewInit,
  ChangeDetectorRef
} from "@angular/core";
import { FormsModule, Form } from "@angular/forms";
//import { IMultiSelectOption,IMultiSelectSettings,IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
//import {mul}  from 'ng-multiselect-dropdown'
import { UserModel } from "../../models/user.model";
import { UserService } from "../../services/user.service";
import { UserroleService } from "../../services/userrole.service";
import { Tools } from "../../modules/tools";
import { Router } from "@angular/router";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
import { AuthService } from "../../services/auth.service";
import { AggregatorDistributorModel } from "src/app/models/aggregator.distributor.model";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
import { SimpleMessage } from "../../modules/util.class";
import { throwError } from 'rxjs';
@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"]
})
export class UserComponent implements OnInit, DoCheck, AfterViewInit {
  @Input()
  _param: any;
  @Input()
  _menuId: string;
  @Input()
  _action: string;
  //@Output()
  //cancelClickEvent : EventEmitter<object>= new EventEmitter<object>();
  @Output()
  backClickEvent: EventEmitter<object> = new EventEmitter<object>();
  user: UserModel;
  dropdownList = [];
  selectedItems = [];
  mySettings: any;
  optionsModel: number[];
  myTexts: any;
  dropdownSettings = {};
  _user: UserModel;
  _disableSaveCancel: boolean = true;
  
  aggregatorDistributorList : Array<AggregatorDistributorModel>
  constructor(
    private objuserservice: UserService,
    public tools: Tools,
    private router: Router,
    private ngbModalService: NgbModal,
    private objuserroleservice: UserroleService,
    private auth: AuthService,
    public aggregatordistributorservice: AggregatorDistributorService,
    private cdr: ChangeDetectorRef
  ) {
    //console.log("user constructor called");
    //this.aggregatordistributorservice.loadAggregatorDistrbutorIdNameType();
    this.getAggregatorDistributorList();
  }

  ngOnInit() {
    this.user = new UserModel();
    this.getRoleIdName();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "roleId",
      textField: "roleName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showCheckbox: true,
      enableCheckAll: true,
      enableFilterSelectAll: true,
      filterSelectAllText: "All roles selected",
      maxHeight: 200
    };
    this.selectedItems = undefined;
  }
  ngDoCheck() {
    this._disableSaveCancel = true;
    if (this.isUserModified()) {
      if (this.isAllowAddorEdit()) {
        this._disableSaveCancel = false;
      }
    }
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  onItemSelect(item: any) {
    this.roleSelectDeselect();
  }
  OnItemDeSelect(item: any) {
    this.roleSelectDeselect();
  }
  onSelectAll(items: any) {
    //this.roleSelectDeselect();
    for (let i = 0; i < this.dropdownList.length; i++) {
      if (!this.isRoleExistsInUser(this.dropdownList[i].roleId)) {
        if (!this.user.roleId) {
          this.user.roleId = new Array<number>();
        }
        this.user.roleId.push(this.dropdownList[i].roleId);
      }
    }
    // this.user.roleId=this.dropdownList;
  }
  onDeSelectAll(items: any) {
    //this.roleSelectDeselect();
    this.user.roleId = undefined;
  }
  isRoleExistsInUser(roleId: number) {
    if (this.user.roleId) {
      for (let i = 0; i < this.user.roleId.length; i++) {
        if (this.user.roleId[i] == roleId) {
          return true;
        }
      }
    }
    return false;
  }

  roleSelectDeselect() {
    var roleIds: Array<number> = new Array<number>();
    this.selectedItems.forEach(itm => {
      roleIds.push(itm.roleId);
    });
    this.user.roleId = roleIds;
  }

  saveUser(f: Form) {
    console.log("form data" + f);
    if (
      this.user.password != this.user.userRePassword &&
      this._action == "new"
    ) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const modalRef = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      modalRef.componentInstance.title = "Alert";
      modalRef.componentInstance.message = "Password mismatch!";
      modalRef.componentInstance.btnCLass = "btn-info";
      modalRef.result.then(
        result => {
          return;
        },
        reason => {
          return;
        }
      );
    } else {
      this.objuserservice.saveUser(this.user).subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        if (data["success"]) {
          this._user = this.tools.copyFullObject(this.user);
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Information";
          objmsg.message = "User saved successfully!";
          objmsg.btnClass = "btn-info";
        } else {
          if (data["message"] == "NAMEALREADYEXISTS") {
            objmsg.title = "Warning";
            objmsg.message = "Employee code already exists!";
            objmsg.btnClass = "btn-warning";
          } else if ((data["message"] == "EMAILALREADYEXISTS")) {
            objmsg.title = "Warning";
            objmsg.message = "User email id already exists!";
            objmsg.btnClass = "btn-warning";
          } else if ((data["message"] == "ValidationError")) {
            objmsg.title = "Warning";
            objmsg.message = "Validation failed!";
            objmsg.btnClass = "btn-warning";
          } else {
            objmsg.title = "Error";
            objmsg.message = "Failed to save Role!";
            objmsg.btnClass = "btn-error";
          }
        }
        this.tools.simpleMessage(objmsg);
      });
    }
  }

  getUserDetail(param: any, action: string) {
    this.selectedItems = undefined;
    let _id: number;
    if (param) {
      _id = param.id;
    }

    if (_id) {
      this.objuserservice.getUserDetail(_id).subscribe(data => {
        if (data["success"]) {
          this.user = data["result"];
          this._user = this.tools.copyFullObject(this.user);
          const roleIds: Array<number> = this.user.roleId;
          const roleNames: Array<string> = this.user.roleName;
          let roles: Array<Object> = new Array<Object>();
          for (let i = 0; i < roleIds.length; i++) {
            roles.push({ roleId: roleIds[i], roleName: roleNames[i] });
          }
          this.selectedItems = roles;
        } else {
          alert("Failed to get user list!");
        }
      });
    }
  }
  addNewUser() {
    this.user = new UserModel();
    this.user.distributorId = 0;
    this._user = this.tools.copyFullObject(this.user);
  }
  isActionNew() {
    if (this._action == "new") {
      return true;
    } else {
      return false;
    }
  }
  onCancelClick() {
    if (this.isUserModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.user = this._user = this.tools.copyFullObject(this._user);
          }
        },
        reason => {}
      );
    }
    //this.cancelClickEvent.emit({userId: this.user.userId,isModified : this.isUserModified()});
  }
  onBackbuttonClick() {
    this.backClickEvent.emit({
      userId: this.user.userId,
      isModified: this.isUserModified()
    });
  }
  isUserModified() {
    let user1 = this.user;
    let user2 = this._user;
    if (!(user1 && user2)) {
      return false;
    }
    if (user1.username != user2.username) {
      return true;
    } else if (user1.name != user2.name) {
      return true;
    } else if (user1.userEmail != user2.userEmail) {
      return true;
    } else if (user1.isLocked != user2.isLocked) {
      return true;
    } else if (user1.distributorId != user2.distributorId) {
      return true;
    } else if (user1.phoneNumber != user2.phoneNumber) {
      return true;
    }
    else if (
      (user1.roleId && !user2.roleId) ||
      (!user1.roleId && user2.roleId)
    ) {
      return true;
    } else if (!(user1.roleId && user2.roleId)) {
      return false;
    } else if (user1.roleId.length != user2.roleId.length) {
      return true;
    } else {
      for (let i = 0; i < user1.roleId.length; i++) {
        let found = false;
        for (let j = 0; j < user2.roleId.length; j++) {
          if (user1.roleId[i] == user2.roleId[j]) {
            found = true;
            break;
          }
        }
        if (!found) {
          return true;
        }
      }
    }
    return false;
  }

  getRoleIdName() {
    this.objuserroleservice.getRoleIdName().subscribe(data => {
      if (data["success"]) {
        this.dropdownList = data["result"];
      } else {
        alert("Failed to get role id and name list!");
      }
    });
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isUserModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }
  disableCancel() {
    return !this.isUserModified();
  }
  getpwdvalidationmsg(obj) {
    if (!obj.valid) {
      return "Mandatory, and enter in correct format (minimum one capital and one small letter, one digit and one special charager(@#$%!))!";
    }
  }

  getAggregatorDistributorList() {
    this.aggregatordistributorservice.getAggregatorDistributorIdNameTypeApi().subscribe((data) => {
      this.aggregatorDistributorList = [];

      if (data["success"]) {
        if (data["result"]) {
          const result = data["result"];
          if (result && result.length > 0) {
            result.forEach(element => {
              if (element["type"] == "A" || element["type"] == "D" || element["type"] == "I") {
                const type = element["type"] == "A" ? " (Aggregator)" : element["type"] == "D" ? " (Distributor)" : "";
                const typeNum = element["type"] == "A" ? "1" : element["type"] == "D" ? "1" : "0";
                this.aggregatorDistributorList.push({id : element["id"],"name" : element["name"] + type ,type: typeNum})               
              }
            });
          } 
          this.aggregatorDistributorList.sort((a, b) => {
            const testComp = a.type.localeCompare(b.type);
            if (testComp === 0) {
              return a.name.localeCompare(b.name);
            } else {
              return testComp;
            }
          });
          //this.aggregatorDistributorList = data["result"];
        } else {
          this.aggregatorDistributorList = new Array<
            AggregatorDistributorModel
          >();
        }
      } else {
        throwError(data["message"]);
        this.aggregatorDistributorList = new Array<
          AggregatorDistributorModel
        >();
      }
    });
  }

  onlyNumberKey(event:any) {
     
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }
}
