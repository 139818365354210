import { Component, OnInit, ViewChild, DoCheck } from "@angular/core";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { UserroleService } from "../../services/userrole.service";
import { Tools } from "../../modules/tools";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
import { UserRole } from "../../models/user.model";
import { TopbarText } from "../topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { SimpleMessage, SortClass } from "../../modules/util.class";
import { ExporttocsvService } from "../../services/exporttocsv.service";
@Component({
  selector: "app-userrole",
  templateUrl: "./userrole.component.html",
  styleUrls: ["./userrole.component.css"]
})
export class UserroleComponent implements OnInit, DoCheck {
  //For role list
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Role Name",
      columnFieldName: "roleName",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Description",
      columnFieldName: "description",
      columnClasses: "c-col-pc-30"
    },
    {
      columnHeader: "Privileges",
      columnFieldName: "privileges",
      columnClasses: "c-col-pc-45",
      callbackFunction: this.arrayToString
    }
  ];
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  userroles: Array<object>;
  page: GridPage;
  _menuId: string;
  _action: string;
  _userParam: any;
  isRoleDetailScreen = true;
  searchField = {
    searchText: ""
  };
  //For Role detail
  userrole: UserRole;
  dropdownList = [];
  selectedItems = [];
  mySettings: any;
  optionsModel: number[];
  myTexts: any;
  dropdownSettings = {};
  _userrole: UserRole;
  _disableSaveCancel: boolean = true;
  topbartext: TopbarText[] = [
    { key: "rolemanagement", displayText: "Role Management" }
  ];

  constructor(
    public tools: Tools,
    private objUserroleService: UserroleService,
    private ngbModalService: NgbModal,
    private objGlobal: global,
    private auth: AuthService,
    private csvservice: ExporttocsvService
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/role");
    //console.log(this._menuId);
    this.objGlobal.setActiveMenu(this._menuId);
    this.getRoleList();
  }

  ngOnInit() {
    this.isRoleDetailScreen = false;
    this.userrole = new UserRole();
    this.getAllPrivileges();

    this.selectedItems = [
      { privilege: "menu1->merchant" },
      { privilege: "menu1->distributor" },
      { privilege: "menu1->device" },
      { privilege: "menu4->user" }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "privilege",
      textField: "privilege",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showCheckbox: true,
      enableCheckAll: true,
      enableFilterSelectAll: true,
      filterSelectAllText: "All privileges selected",
      maxHeight: 200
    };
  }
  onCancelClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to discard changes?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.userrole = this.tools.copyFullObject(this._userrole);
        }
      },
      reason => {}
    );
  }

  ngDoCheck() {}
  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isRoleModified()) {
        if (this.isAllowAddOrEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }
  isAllowAddOrEdit() {
    return (
      (this._action == "new" && this.isAllowAdd()) ||
      (this._action == "update" && this.isAllowEdit())
    );
  }
  disableCancel() {
    return !this.isRoleModified();
  }

  getRoles(event) {
    this.getRoleList();
  }
  selectRole(event) {}
  deleteRole(event) {}
  saveRole() {
    let roleData = this.tools.copyFullObject(this.userrole);
    roleData.privileges = this.objPrivToStringPriv(this.userrole.privileges);
    this.objUserroleService.saveRole(roleData).subscribe(
      data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        if (data["success"]) {
          this._userrole = this.tools.copyFullObject(this.userrole);
          this._action = "update";
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Information";
          objmsg.message = "Role saved successfully!";
          objmsg.btnClass = "btn-info";
        } else {
          if (data["message"] == "NAMEALREADYEXISTS") {
            objmsg.title = "Warning";
            objmsg.message = "Role already exists!";
            objmsg.btnClass = "btn-warning";
          } else {
            objmsg.title = "Error";
            objmsg.message = "Failed to save Role!";
            objmsg.btnClass = "btn-error";
          }
        }
        this.tools.simpleMessage(objmsg);
      },
      err => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to save Role!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  getRoleDetail(_id) {
    if (_id) {
      this.objUserroleService.getRoleDetail(_id).subscribe(data => {
        if (data["success"]) {
          this.userrole = data["result"];
          //this.userrole.privileges = this.objPrivToStringPriv(this.userrole.privileges)
          this._userrole = Object.assign({}, this.userrole);
        } else {
          alert("Failed to get role list!");
        }
      });
    }
  }

  getRoleList() {
    this.objUserroleService.getRoleList(this.page, this.searchField).subscribe(
      data => {
        if (data["success"]) {
          this.userroles = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
        } else {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get Roles!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      },
      err => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to get Roles!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }
  onRoleRowDblClick(rolelistval: any) {
    this._action = "update";
    this.isRoleDetailScreen = true;
    //this.router.navigate(['user']);
    this.getRoleDetail(rolelistval.roleId);
  }
  onAddNew() {
    this._action = "new";
    this.isRoleDetailScreen = true;
    this.userrole = new UserRole();
    this._userrole = Object.assign({}, this.userrole);
    //this.userComp.addNewUser();
  }
  onCancel() {
    if (this.isRoleModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.userrole = Object.assign({}, this._userrole);
            //    this.isUserDetailScreen=false;
          }
        },
        reason => {}
      );
    } else {
    }
  }
  /*onCancel(obj) {
  if(obj.isModified) {
    this.confirmService.confirm({title : 'Confirmation', message : 'User has been modified. Are you sure to exit without save?'})
    .then(
      ()=> {
        this._action="";
        this.isUserDetailScreen=false;
      }
      )
  } else {
    this._action="";
    this.isUserDetailScreen=false;
  }
}*/
  onBackClick(obj) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "User detail has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isRoleDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isRoleDetailScreen = false;
    }
  }
  onBackbuttonClick() {
    if (this.isRoleModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "User detail has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isRoleDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isRoleDetailScreen = false;
    }
  }

  isRoleModified() {
    let role1 = this.userrole;
    let role2 = this._userrole;
    if (!(role1 && role2)) {
      return false;
    }
    if (role1.roleName != role2.roleName) {
      return true;
    } else if (role1.description != role2.description) {
      return true;
    } else if (
      (role1.privileges && !role2.privileges) ||
      (!role1.privileges && role2.privileges)
    ) {
      return true;
    } else if (!(role1.privileges && role2.privileges)) {
      return false;
    } else if (role1.privileges.length != role2.privileges.length) {
      return true;
    } else {
      for (let i = 0; i < role1.privileges.length; i++) {
        let found = false;
        for (let j = 0; j < role2.privileges.length; j++) {
          if (role1.privileges[i] == role2.privileges[j]) {
            found = true;
            break;
          }
        }
        if (!found) {
          return true;
        }
      }
    }
    return false;
  }

  arrayToString(arr: string[]) {
    let strvaue: string = "";
    if (arr) {
      arr.forEach(val => {
        if (strvaue != "") {
          strvaue = strvaue + ", ";
        }
        strvaue = strvaue + val;
      });
    }
    return strvaue;
  }

  getAllPrivileges() {
    this.objUserroleService.getAllPriv().subscribe(data => {
      this.dropdownList = data["privileges"];
    });
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    //console.log("is priv " + this._menuId + "=>add");
    return isAdd;
  }
  isAllowEdit() {
    let isEdit = this.auth.isPrivExists(this._menuId + "=>edit");
    //console.log("is priv " + this._menuId + "=>add");
    return isEdit;
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  getUserRoleListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;

    this.objUserroleService.getRoleList(page, this.searchField).subscribe(
      data => {
        let obj: Array<UserRole>;
        if (data["success"]) {
          obj = data["result"];
          this.csvservice.exportToCsv(obj, this.gridcols, undefined, "role");
        } else {
          alert("Failed to get user role list!");
        }
      },
      error => {
        alert("Failed to get user role list!");
      }
    );
  }
  onExcelClick() {
    this.getUserRoleListForExcel();
  }

  stringPrivToObjPriv(priv: string[]) {
    let result;
    if (priv) {
      result = [];
      for (let i = 0; i < priv.length; i++) {
        result.push({"privilege": priv[i]})
      }
    }
    return result;
  }

  objPrivToStringPriv(priv) {
    let result;
    if (priv) {
      result = [];
      for (let i = 0; i < priv.length; i++) {
        result.push(priv[i].privilege)
      }
    }
    return result;
  }
}
