import { Component, OnInit, ViewChild } from "@angular/core";
import { gridColumns } from "src/app/framework/util/components/simplegrid/simplegrid.component";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { MCCModel } from "src/app/models/MCC.model";
import {
  GridPage,
  GridtableComponent
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { Tools } from "src/app/framework/modules/tools";
import { AuthService } from "src/app/framework/services/auth.service";
import { Router } from "@angular/router";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { global } from "src/app/framework/modules/global";
import { MCCService } from "src/app/services/mcc.service";
import { NgYesNoModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-mcc",
  templateUrl: "./mcc.component.html",
  styleUrls: ["./mcc.component.css"]
})
export class MCCComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-8" },
    {
      columnHeader: "MCC Code",
      columnFieldName: "mccCode",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Description",
      columnFieldName: "description",
      columnClasses: "c-col-pc-50"
    },
    {
      columnHeader: "Segment",
      columnFieldName: "businessSegment",
      columnClasses: "c-col-pc-20",
      callbackFunction: this.getSegmentText
    }
  ];
  sortFields = [
    { name: "mccCode", display: "MCC Code" },
    { name: "description", display: "Description" },
    { name: "businessSegment", display: "Segment" }
  ];

  topbartext: TopbarText[] = [{ key: "mcclist", displayText: "MCC List" }];

  searchField = {
    searchText: "",
    businessSegment: ""
  };
  sort: SortClass;
  datalist: Array<MCCModel>;
  data: MCCModel;
  _data: any;
  isDetailScreen: boolean = false;
  _menuId: string;
  _action: string = "";
  page: GridPage;
  _isChange: boolean = false;
  _mccCode: string = "";

  constructor(
    public tools: Tools,
    private auth: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private mccservice: MCCService,
    private objGlobal: global,
    private csvservice: ExporttocsvService
  ) {
    this.sort = new SortClass();
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );

    this.data = new MCCModel();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/mcc");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "mccCode";
    this.getMCCList();
  }

  ngOnInit() {}
  onRowDblClick(e: any) {
    if (e.mccCode) {
      this.topbartext = [{ key: "mccdetail", displayText: "MCC Detail" }];
      this._action = "update";
      this.isDetailScreen = true;
      this.getData(e.mccCode);
      $("#detail").modal({
        show: true,
        backdrop: false,
        focus: true,
        keyboard: false
      });
    }
  }
  getData(mccCode: string) {
    this.mccservice.getMCC(mccCode).subscribe(
      data => {
        if (data["success"]) {
          this.data = data["result"];
          this._data = this.tools.copyFullObject(this.data);
          this._action = "update";
        } else {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          switch (data["message"]) {
            case "INVALIDCODE":
              objmsg.title = "Error";
              objmsg.message = "Invalid MCC code!";
              objmsg.btnClass = "btn-warning";
              break;
            default:
              objmsg.title = "Error";
              objmsg.message = "Failed to get data!";
              objmsg.btnClass = "btn-warning";
          }
          this.tools.simpleMessage(objmsg);
        }
      },
      err => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to get data!";
        objmsg.btnClass = "btn-warning";
      }
    );
  }

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }

  onAddNew() {
    this._action = "new";
    this.isDetailScreen = true;
    this.topbartext = [{ key: "addmcc", displayText: "Add MCC" }];
    this.data = new MCCModel();
    this._data = this.tools.copyFullObject(this.data);
    $("#detail").modal({ show: true, backdrop: false });
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "MCC has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
            $("#detail").modal("hide");
            this.topbartext = [{ key: "mcclist", displayText: "MCC List" }];
            if (this._isChange == true) {
              this._isChange = false;
              this.getMCCList();
            }
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
      $("#detail").modal("hide");
      this.topbartext = [{ key: "mcclist", displayText: "MCC List" }];
      if (this._isChange == true) {
        this._isChange = false;
        this.getMCCList();
      }
    }
  }

  isModified() {
    if (!(this.data && this._data)) {
      return false;
    }
    if (this.data.mccCode != this._data.mccCode) {
      return true;
    } else if (this.data.description != this._data.description) {
      return true;
    } else if (this.data.businessSegment != this._data.businessSegment) {
      return true;
    }
    return false;
  }

  getMCCList() {
    this.mccservice
      .getMCCList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get MCC list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get MCC list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  saveBankIfsc() {
    this.mccservice.saveMCC(this.data, this._action).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      let objmsg: SimpleMessage = new SimpleMessage();
      if (data["success"]) {
        objmsg.title = "Information";
        objmsg.message = "MCC saved successfully!";
        objmsg.btnClass = "btn-info";
        this.data = data["result"];
        this._action = "update";
        this._data = this.tools.copyFullObject(this.data);
        this._isChange = true;
      } else {
        if (data["message"] == "ALREADYEXISTS") {
          objmsg.title = "Warning";
          objmsg.message = "MCC Code already exists!";
          objmsg.btnClass = "btn-warning";
        } else if (data["message"] == "INVALIDCOOE") {
          objmsg.title = "Error";
          objmsg.message = "Invalid MCC Code to modify!";
          objmsg.btnClass = "btn-warning";
        } else {
          objmsg.title = "Error";
          objmsg.message = "Failed to save MCC!";
          objmsg.btnClass = "btn-error";
        }
      }
      this.tools.simpleMessage(objmsg);
    });
  }

  disableSubmit(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }

  disableCancel(f: any) {
    return !this.isModified();
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  getSegmentText(codeValue: String) {
    let value: String = "";
    switch (codeValue) {
      case "E": {
        value = "Emerging";
        break;
      }
      case "N": {
        value = "Non-Emerging";
        break;
      }
    }
    return value;
  }

  onCancelClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to discard changes?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.data = this.tools.copyFullObject(this._data);
        }
      },
      reason => {}
    );
  }
  disableListScreen() {
    return this.isDetailScreen;
  }

  getMCCListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.mccservice.getMCCList(page, this.searchField, sort).subscribe(
      data => {
        let obj: Array<MCCModel>;
        if (data["success"]) {
          obj = data["result"];
          this.csvservice.exportToCsv(obj, this.gridcols, undefined, "mcc");
        } else {
          alert("Failed to get MCC list!");
        }
      },
      error => {
        alert("Failed to get MCC list!");
      }
    );
  }
  onExcelClick() {
    this.getMCCListForExcel();
  }
}
