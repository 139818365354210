import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  MerchantModel,
  MerchantCompanyProfileModel,
  MerchantPartnerModel,
  MerchantBankDetailModel,
  MerchantRiskModel,
  MerchantTerminalModel,
  PartnerFiles,
  MerchantFiles,
  CompanyFiles,
  MerchantChannelLimitModel,
} from "src/app/models/merchant.model";
import { Tools } from "src/app/framework/modules/tools";
import { BankModel } from "src/app/models/bank.model";
import {
  AggregatorDistributorModel,
  AggregatorChannelNetwork,
} from "src/app/models/aggregator.distributor.model";
import { EmployeeModel } from "src/app/models/employee.model";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
import { BankService } from "src/app/services/bank.service";
import { EmployeeService } from "src/app/services/employee.service";
import { MerchantService } from "src/app/services/merchant.service";
import { TransCategoryService } from "src/app/services/transcategory.service";
import { DeviceStockModel } from "src/app/models/device.model";
import { SIMStockModel } from "src/app/models/simstock.model";
import { BankIFSCService } from "src/app/services/bank.ifsc.service";
import { PincodeService } from "src/app/services/pincode.service";
import { PincodeModel } from "src/app/models/pincode.model";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgYesNoModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";
import { DeviceStockService } from "src/app/services/device.service";
import { SIMStockService } from "src/app/services/sim.service";
import { RentalPlanModel } from "src/app/models/rental.plan.model";
import { TransRatesModel } from "src/app/models/trans.rate";
import { RentalplanService } from "src/app/services/rentalplan.service";
import { MDRPlanService } from "src/app/services/mdr.plan.service";
import {
  SimpleMessage,
  MARSResponseErrorMsg,
  ValidMsg,
} from "../../framework/modules/util.class";
import { global } from "src/app/framework/modules/global";
import { MCCModel } from "src/app/models/MCC.model";
import { MCCService } from "src/app/services/mcc.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ChannelNetworkCodeValueModel } from "src/app/models/channel.network.codevalue.model";
import { RegionMasterService } from "src/app/services/region.master.service";
import { AxisErrorResponse, MARSErrorResponse } from "src/app/models/mars.response.error.module";
import { MerchantDetailParentComponenet } from "./merchantdetailparent.component";
import { AuthService } from "src/app/framework/services/auth.service";
import { TerminalDeactivationMessageComponent } from "../terminal-deactivation-message/terminal-deactivation-message.component";
import { TerminalStatusRequestMessageComponent } from '../terminal-status-request-message/terminal.status.request.message.component';

declare var jquery: any;
declare var $: any;

export class MerchantTabs {
  merchantprofiletab: boolean;
  companyprofiletab: boolean;
  banktab: boolean;
  financialstab: boolean;
  terminalstab: boolean;
  risktab: boolean;
}

@Component({
  selector: "app-merchantdetail",
  templateUrl: "./merchantdetail.component.html",
  styleUrls: ["./merchantdetail.component.css"],
})
export class MerchantdetailComponent extends MerchantDetailParentComponenet
  implements OnInit {
  @Input()
  action: string = "";
  @Input()
  tabStatus: MerchantTabs;
  @Input()
  addPriv: boolean = false;
  @Input()
  editPriv: boolean = false;
  @Input()
  approvePriv: boolean = false;
  @Input()
  terminalStatusChangePriv: boolean = false;
  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  banklist: Array<BankModel>;
  modifiedTerminalIdxList: Array<number>;
  salesPersonDropdownSettings = {
    singleSelection: true,
    idField: "empCode",
    textField: "empName",
    allowSearchFilter: true,
    maxHeight: 200,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: "",
  };
  merchantStatusValues: any;

  touchedTerminalDevice: boolean = false;
  touchedTerminalSIM: boolean = false;
  _disableSubmit = false;  
  constructor(
    public tools: Tools,
    public aggregatordistributorservice: AggregatorDistributorService,
    public bankservice: BankService,
    private employeeservice: EmployeeService,
    public merchantservice: MerchantService,
    public transCategoryService: TransCategoryService,
    private bankifscservice: BankIFSCService,
    private pincodeservice: PincodeService,
    private ngbModalService: NgbModal,
    private devicestockservice: DeviceStockService,
    private simstockservice: SIMStockService,
    public mdrplanservice: MDRPlanService,
    public rentalplanservice: RentalplanService,
    private objGlobal: global,
    private mccservice: MCCService,
    private sanitizer: DomSanitizer,
    private regionmasterservice: RegionMasterService,
    private auth: AuthService
  ) {
    super(
      tools,
      aggregatordistributorservice,
      bankservice,
      merchantservice,
      transCategoryService,
      mdrplanservice,
      rentalplanservice
    );
    this._disableSubmit = false; 
    this.imgURL = "assets/images/blank.png";
    this.addNew();
    this.merchantStatusValues = this.merchantservice.merchantStatusValues;
    //this.bankservice.loadBankList(true);
    this.loadAggregatorDistributorInstitution();
    this.employeeservice.loadEmployeeNameWithCode();
    merchantservice.loadMerchantConfig();
    this.populateMccList();
    this.loadRegions();
    this.getBankList();
    this._isMerchantFileModified = false;
    this._isCompanyFileModified = false;
    this._isSelPartnerFileModified = false;
    // merchantservice.loadRentalPlan();

    //console.log("merchant detail constructor called");
  }

  getBankList() {
    this.bankservice.getBankListApi().subscribe((res) => {
      if (res["success"]) {
        this.banklist = res["result"];
      } else {
        this.banklist = undefined;
      }
    });
  }
  ngOnInit() {}
  async loadRegions() {
    this.regions = await this.regionmasterservice.getRegions(false);
    //console.log(this.regions);
  }

  getMDRPlanList() {
    let mccSegment: string = "";
    if (this.selMcc && this.selMcc[0]) {
      const selectedMCC = this.mcclist.filter(
        (obj) => obj.mccCode == this.selMcc[0].mccCode
      );
      if (selectedMCC && selectedMCC[0]) {
        mccSegment = selectedMCC[0].businessSegment;
      }
    }
    if (this.mdrplanlist) {
      return this.mdrplanlist.filter(
        (itm) =>
          itm.aggregator.id == this.aggregator.id &&
          itm.businessSegment == mccSegment
      );
    }
  }
  getRentalPlanList() {
    if (this.rentalplanlist) {
      return this.rentalplanlist.filter(
        (itm) => itm.aggregator.id == this.aggregator.id
      );
    }
  }
  async loadAggregatorDistributorInstitution() {
    this.aggregatorDistributorList = await this.aggregatordistributorservice.loadAggregatorDistrbutorIdNameTypeOfUserSelfChildParentAndInstitution();
  }
  disableCancel() {
    return !this.isModified(true);
  }

  disableSave(f) {
    if (!this.isAllowAddorEdit()) {
      return true;
    } else if (this.noAllowEdit()) {
      return true;
    } else if (!this.isModified(true)) {
      return true;
    } else {
      return false;
    }
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this.action == "new") {
      isAllowSave = this.addPriv;
    } else if (this.action == "update") {
      isAllowSave = this.editPriv;
    }
    return isAllowSave;
  }

  disableSubmit(fMProfile, fCProfile, fBank, fFin, fRisk) {
    if(this._disableSubmit) {
      return true;
    } else if (!this.isAllowAddorEdit()) {
      return true;
    } else if (this.allowTerminalSubmit()) {
      return false;
    } else if (!fMProfile || !fCProfile || !fBank || !fFin || !fRisk) {
      return true;
    } else if (this.noAllowEdit()) {
      return true;
    } else if (!fMProfile.valid) {
      return true;
    } else if (!fCProfile.valid) {
      return true;
    } else if (!fBank.valid) {
      return true;
    } else if (!fFin.valid) {
      return true;
    } else if (!fRisk.valid) {
      return true;
    } else if (!(this.transactionSet && this.transactionSet.length > 0)) {
      return true;
    } else {
      return false;
    }
  }

  isArrObjExists(obj) {
    if (!obj) {
      return false;
    } else if (obj.length < 1) {
      return false;
    } else {
      return true;
    }
  }
  onBackbuttonClick() {
    if (this.isModified(true) == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Merchant data has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.action = "";
            this.closeScreen();
          }
        },
        (reason) => {}
      );
    } else {
      this.action = "";
      this.closeScreen();
    }
  }
  isModified(includeTerminal: boolean) {
    if (!this.data || !this._data) {
      return false;
    } else if (this._isPartnersModified) {
      return true;
    } else if (this._isTerminalsModified && includeTerminal) {
      return true;
    } else if (this._isMerchantFileModified) {
      return true;
    } else if (this._isCompanyFileModified) {
      return true;
    } else if (this.data.aadhaar != this._data.aadhaar) {
      return true;
    } else if (
      !this.tools.isDateSame(
        this.data.applicationDate,
        this._data.applicationDate
      )
    ) {
      return true;
    } else if (this.data.authPersonTitle != this._data.authPersonTitle) {
      return true;
    } else if (
      this.data.authPersonFirstName != this._data.authPersonFirstName
    ) {
      return true;
    } else if (this.data.authPersonLastName != this._data.authPersonLastName) {
      return true;
    } else if (this.data.phoneNumber != this._data.phoneNumber) {
      return true;
    } else if (
      this.data.alternatePhoneNumber != this._data.alternatePhoneNumber
    ) {
      return true;
    } else if (this.data.businessAddress != this._data.businessAddress) {
      return true;
    } else if (
      this.data.businessAddressCity != this._data.businessAddressCity
    ) {
      return true;
    } else if (
      this.data.businessAddressDistrict != this._data.businessAddressDistrict
    ) {
      return true;
    } else if (
      this.data.businessAddressPinCode != this._data.businessAddressPinCode
    ) {
      return true;
    } else if (
      this.data.businessAddressState != this.data.businessAddressState
    ) {
      return true;
    } else if (this.data.companyName != this._data.companyName) {
      return true;
    } else if (this.data.emailId != this._data.emailId) {
      return true;
    } else if (this.data.gender != this._data.gender) {
      return true;
    } else if (this.data.gst != this._data.gst) {
      return true;
    } else if (this.data.loanReimbursement != this._data.loanReimbursement) {
      return true;
    } else if (this.data.merchantMDRPlan != this._data.merchantMDRPlan) {
      return true;
    } else if (this.data.merchantCategory != this._data.merchantCategory) {
      return true;
    } else if (this.data.merchantName != this._data.merchantName) {
      return true;
    } else if (this.data.nationality != this._data.nationality) {
      return true;
    } else if (this.data.otherId != this._data.otherId) {
      return true;
    } else if (this.data.pan != this._data.pan) {
      return true;
    } else if (this.data.partnerSharing != this._data.partnerSharing) {
      return true;
    } else if (
      !this.tools.isDateSame(
        this.data.passportExpiryDate,
        this._data.passportExpiryDate
      )
    ) {
      return true;
    } else if (this.data.passportNumber != this._data.passportNumber) {
      return true;
    } else if (this.data.region != this._data.region) {
      return true;
    } else if (this.data.residenceAddress != this._data.residenceAddress) {
      return true;
    } else if (
      this.data.residenceAddressCity != this._data.residenceAddressCity
    ) {
      return true;
    } else if (
      this.data.residenceAddressDistrict != this._data.residenceAddressDistrict
    ) {
      return true;
    } else if (
      this.data.residenceAddressPinCode != this._data.residenceAddressPinCode
    ) {
      return true;
    } else if (
      this.data.residenceAddressState != this._data.residenceAddressState
    ) {
      return true;
    } else if (this.data.voterId != this._data.voterId) {
      return true;
    } else if (this.data.website != this._data.website) {
      return true;
    } else if (
      this.institution &&
      this._institution &&
      this.institution.id != this._institution.id
    ) {
      return true;
    } else if (
      this.aggregator &&
      this._aggregator &&
      this.aggregator.id != this._aggregator.id
    ) {
      return true;
    } else if (
      this.supermerchant &&
      this._superMerchant &&
      this.supermerchant.id != this._superMerchant.id
    ) {
      return true;
    } else if (
      this.distributor &&
      this._distributor &&
      this.distributor.id != this._distributor.id
    ) {
      return true;
    } else if (
      this.bank &&
      this._bank &&
      this.bank.bankId != this._bank.bankId
    ) {
      return true;
    } else if (this.isModifiedSalesPerson()) {
      return true;
    } else if (this.isModifiedCompanyProfile()) {
      return true;
    } else if (this.isModifiedBankDetail()) {
      return true;
    } else if (this.isModifiedRisk()) {
      return true;
    } else if (this.isModifiedTransRates()) {
      return true;
    }
    return false;
  }
  isItemExistsInArray(itm: Array<any>) {
    let exists: boolean = false;
    if (itm) {
      if (itm[0]) {
        exists = true;
      }
    }
    return exists;
  }
  isModifiedSalesPerson() {
    const oldSalesPersonCode =
      this._data && this._data.salesPerson
        ? this._data.salesPerson.empCode
        : undefined;
    const newSalesPersonCode =
      this.selsalesperson &&
      this.selsalesperson.length > 0 &&
      this.selsalesperson[0]
        ? this.selsalesperson[0].empCode
        : undefined;
    if (oldSalesPersonCode != newSalesPersonCode) {
      return true;
    }
    return false;
  }
  isModifiedFieldCompArray(itm: Array<any>, obj: any, fieldName: string) {
    const isexistsarrayitem = this.isItemExistsInArray(itm);
    if (!isexistsarrayitem && !obj) {
      return false;
    } else if (
      this.isItemExistsInArray &&
      itm[0] &&
      itm[0][fieldName] &&
      !obj
    ) {
      return true;
    } else if (obj && obj[fieldName] && !isexistsarrayitem) {
      return true;
    } else if (obj[fieldName] != itm[0][fieldName]) {
      return true;
    } else {
      return false;
    }
  }
  isModifiedTransRates() {
    if (!this.mdrPlanTransRates || !this._mdrPlanTransRates) {
      return false;
    } else if (this.mdrPlanTransRates && !this._mdrPlanTransRates) {
      return true;
    } else if (!this.mdrPlanTransRates && this._mdrPlanTransRates) {
      return true;
    } else if (this.mdrPlanTransRates && this._mdrPlanTransRates) {
      for (let i = 0; i < this.mdrPlanTransRates.length; i++) {
        if (
          this.mdrPlanTransRates[i].mmFixed !=
          this._mdrPlanTransRates[i].mmFixed
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].mmPercent !=
          this._mdrPlanTransRates[i].mmPercent
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].mmMax != this._mdrPlanTransRates[i].mmMax
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].psFixed !=
          this._mdrPlanTransRates[i].psFixed
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].psPercent !=
          this._mdrPlanTransRates[i].psPercent
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].psMax != this._mdrPlanTransRates[i].psMax
        ) {
          return true;
        }
      }
    }
    return false;
  }
  isModifiedCompanyProfile() {
    if (!this.dataCompanyProfile || !this._dataCompanyProfile) {
      return false;
    } else if (
      this.dataCompanyProfile.businessEntityType !=
      this._dataCompanyProfile.businessEntityType
    ) {
      return true;
    } else if (
      !this.tools.isDateSame(
        this.dataCompanyProfile.comapnyEstablishYear,
        this._dataCompanyProfile.comapnyEstablishYear
      )
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyAlternateMobile !=
      this._dataCompanyProfile.companyAlternateMobile
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyBrandName !=
      this._dataCompanyProfile.companyBrandName
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyContactEmail !=
      this._dataCompanyProfile.companyContactEmail
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyContactMobile !=
      this._dataCompanyProfile.companyContactMobile
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyContactName !=
      this._dataCompanyProfile.companyContactName
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyGstIn !=
      this._dataCompanyProfile.companyGstIn
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyLandline !=
      this._dataCompanyProfile.companyLandline
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyLengalName !=
      this._dataCompanyProfile.companyLengalName
    ) {
      return true;
    } else if (this.isMccCodeModified()) {
      return true;
    } else if (
      this.dataCompanyProfile.companyMerchantType !=
      this._dataCompanyProfile.companyMerchantType
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyNatureOfBusiness !=
      this._dataCompanyProfile.companyNatureOfBusiness
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyPan != this._dataCompanyProfile.companyPan
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyRegAaddress !=
      this._dataCompanyProfile.companyRegAaddress
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyRegAddressCity !=
      this._dataCompanyProfile.companyRegAddressCity
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyRegAddressDistrict !=
      this._dataCompanyProfile.companyRegAddressDistrict
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyRegAddressPinCode !=
      this._dataCompanyProfile.companyRegAddressPinCode
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyRegAddressState !=
      this._dataCompanyProfile.companyRegAddressState
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyRegisterNumber !=
      this._dataCompanyProfile.companyRegisterNumber
    ) {
      return true;
    } else if (
      !this.tools.isDateSame(
        this.dataCompanyProfile.companyRegistrationDate,
        this._dataCompanyProfile.companyRegistrationDate
      )
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.companyRemarks !=
      this._dataCompanyProfile.companyRemarks
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.statementEmailIds !=
      this._dataCompanyProfile.statementEmailIds
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.statementFrequency !=
      this._dataCompanyProfile.statementFrequency
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.statementRequired !=
      this._dataCompanyProfile.statementRequired
    ) {
      return true;
    } else if (this.dataCompanyProfile.tcc != this.dataCompanyProfile.tcc) {
      return true;
    } else if (
      this.dataCompanyProfile.turnoverAmount !=
      this._dataCompanyProfile.turnoverAmount
    ) {
      return true;
    } else if (
      this.dataCompanyProfile.turnoverYear !=
      this._dataCompanyProfile.turnoverYear
    ) {
      return true;
    }
    return false;
  }
  isMccCodeModified() {
    const isExistNewMCC: boolean = this.isItemExistsInArray(this.selMcc);
    if (!isExistNewMCC && !this.dataCompanyProfile.companyMCC) {
      return false;
    } else if (!isExistNewMCC && this.dataCompanyProfile.companyMCC) {
      return true;
    } else if (isExistNewMCC && !this.dataCompanyProfile.companyMCC) {
      return true;
    } else if (this.selMcc[0].mccCode != this.dataCompanyProfile.companyMCC) {
      return true;
    }
    return false;
  }
  isModifiedBankDetail() {
    if (!this.merchantBank || !this._merchantBank) {
      return false;
    } else if (
      this.merchantBank.accountNumber != this._merchantBank.accountNumber
    ) {
      return true;
    } else if (
      this.merchantBank.accountType != this._merchantBank.accountType
    ) {
      return true;
    } else if (this.merchantBank.achMandate != this._merchantBank.achMandate) {
      return true;
    } else if (this.merchantBank.bankName != this._merchantBank.bankName) {
      return true;
    } else if (this.merchantBank.branchName != this._merchantBank.branchName) {
      return true;
    } else if (this.merchantBank.city != this._merchantBank.city) {
      return true;
    } else if (
      this.merchantBank.feeAddressChange != this._merchantBank.feeAddressChange
    ) {
      return true;
    } else if (this.merchantBank.ifscCode != this._merchantBank.ifscCode) {
      return true;
    } else if (this.merchantBank.micr != this._merchantBank.micr) {
      return true;
    } else if (
      this.merchantBank.paymentDetails != this._merchantBank.paymentDetails
    ) {
      return true;
    } else if (
      this.merchantBank.paymentMode != this._merchantBank.paymentMode
    ) {
      return true;
    } else if (
      this.merchantBank.settlmentFlag != this._merchantBank.settlmentFlag
    ) {
      return true;
    } else if (this.merchantBank.pinCode != this._merchantBank.pinCode) {
      return true;
    } else if (this.merchantBank.remarks != this._merchantBank.remarks) {
      return true;
    } else if (
      this.merchantBank.settelmentBy != this._merchantBank.settelmentBy
    ) {
      return true;
    } else if (this.merchantBank.state != this._merchantBank.state) {
      return true;
    } else if (this.merchantBank.umrn != this._merchantBank.umrn) {
      return true;
    }
    return false;
  }
  isModifiedRisk() {
    if (!this.merchantRisk || !this._merchantRisk) {
      return false;
    } else if (
      this.merchantRisk.averageBillAmount !=
      this._merchantRisk.averageBillAmount
    ) {
      return true;
    } else if (this.merchantRisk.cibil != this._merchantRisk.cibil) {
      return true;
    } else if (
      this.merchantRisk.expectedCardBusiness !=
      this._merchantRisk.expectedCardBusiness
    ) {
      return true;
    } else if (
      this.merchantRisk.internationalCardAcc !=
      this._merchantRisk.internationalCardAcc
    ) {
      return true;
    } else if (
      this.merchantRisk.maxUsageDaily != this._merchantRisk.maxUsageDaily
    ) {
      return true;
    } else if (
      this.merchantRisk.maxUsageMonthly != this._merchantRisk.maxUsageMonthly
    ) {
      return true;
    } else if (
      this.merchantRisk.maxUsageWeekly != this._merchantRisk.maxUsageWeekly
    ) {
      return true;
    } else if (
      this.merchantRisk.merchantTypeCode != this._merchantRisk.merchantTypeCode
    ) {
      return true;
    } else if (this.merchantRisk.remarks != this._merchantRisk.remarks) {
      return true;
    } else if (
      this.merchantRisk.settlementDays != this._merchantRisk.settlementDays
    ) {
      return true;
    } else if (
      this.merchantRisk.velocityCheckCount !=
      this._merchantRisk.velocityCheckCount
    ) {
      return true;
    } else if (
      this.merchantRisk.velocityCheckMinute !=
      this._merchantRisk.velocityCheckMinute
    ) {
      return true;
    } else if (
      this.merchantRisk.weekdayEndHour != this._merchantRisk.weekdayEndHour
    ) {
      return true;
    } else if (
      this.merchantRisk.weekdayStartHour != this._merchantRisk.weekdayStartHour
    ) {
      return true;
    } else if (
      this.merchantRisk.weekendEndHour != this._merchantRisk.weekendEndHour
    ) {
      return true;
    } else if (
      this.merchantRisk.weekendStartHour != this._merchantRisk.weekendStartHour
    ) {
      return true;
    } else if (this.isModifiedTransactionLimit()) {
      return true;
    } else if (this.isModifiedTransactionSet()) {
      return true;
    }
    return false;
  }
  isArrayExists(arr: Array<any>) {
    if (arr == undefined) {
      return false;
    }
    if (arr.length < 1) {
      return false;
    }
    return true;
  }
  isModifiedTransactionLimit() {
    const isExistOldData = this.isArrayExists(
      this.merchantRisk.transactionlimit
    );
    const isExistNewData = this.isArrayExists(this.transactionLimitSet);

    if (!(isExistNewData || isExistOldData)) {
      return false;
    }
    if (isExistNewData && !isExistOldData) {
      return true;
    }
    if (!isExistNewData && isExistOldData) {
      return true;
    }
    if (
      this.merchantRisk.transactionlimit.length !=
      this.transactionLimitSet.length
    ) {
      return true;
    }
    let flag: boolean = true;
    for (let i: number = 0; i < this.transactionLimitSet.length && flag; i++) {
      const obj1 = this.transactionLimitSet[i];
      let match: boolean = false;
      for (
        let j: number = 0;
        j < this.merchantRisk.transactionlimit.length && match == false;
        j++
      ) {
        const obj2 = this.merchantRisk.transactionlimit[j];
        if (obj1.channel == obj2.channel) {
          if (
            obj1.maxUsageDaily == obj2.maxUsageDaily &&
            obj1.maxUsageWeekly == obj2.maxUsageWeekly &&
            obj1.maxUsageMonthly == obj2.maxUsageMonthly &&
            obj1.maximum &&
            obj2.maximum &&
            obj1.maximum == obj2.maximum &&
            obj1.minimum &&
            obj2.minimum &&
            obj1.minimum == obj2.minimum
          ) {
            match = true;
          }
        }
      }
      if (match == false) {
        flag = false;
      }
    }
    if (flag == false) {
      return true;
    }

    return false;
  }

  isModifiedTransactionSet() {
    const isExistNewData = this.isArrayExists(this.transactionSet);
    const isExistOldData = this.isArrayExists(this.merchantRisk.transactionset);
    if (!(isExistNewData || isExistOldData)) {
      return false;
    } else if (
      this.isArrayExists(this.transactionSet) &&
      !this.isArrayExists(this.merchantRisk.transactionset)
    ) {
      return true;
    } else if (
      !this.isArrayExists(this.transactionSet) &&
      this.isArrayExists(this.merchantRisk.transactionset)
    ) {
      return true;
    } else if (
      this.transactionSet.length != this.merchantRisk.transactionset.length
    ) {
      return true;
    } else {
      let flag: boolean = true;
      for (let i: number = 0; i < this.transactionSet.length && flag; i++) {
        let obj1: ChannelNetworkCodeValueModel = this.transactionSet[i];
        let match: boolean = false;
        for (
          let j: number = 0;
          j < this.merchantRisk.transactionset.length && match == false;
          j++
        ) {
          let obj2: ChannelNetworkCodeValueModel = this.merchantRisk
            .transactionset[j];
          if (obj1.code == obj2.code && obj1.value == obj2.value) {
            match = true;
          }
        }
        if (!match) {
          flag = false;
        }
      }
      if (flag == false) {
        return true;
      }
    }
  }

  closeScreen() {
    this.data = new MerchantModel();
    this.distributeData(false);
    this.copyInitDataNull();
    this.onClose.emit(false);
  }
  resetchanges() {
    this.merchantfiles = new MerchantFiles();
    this._downloadedMerchantFiles = undefined;
    this._downloadedCompanyFiles = undefined;
    this._isModified = false;
    this._isCompanyFileModified = false;
    this._isMerchantFileModified = false;
    this._isTerminalsModified = false;
    this._isSelPartnerFileModified = false;
    this._isPartnersModified = false;
    this.partnerfiles = undefined;
    this.partnerfileslist = undefined;
  }
  addNew() {
    this.isNewMerchant = true;
    this.action = "new";
    this._disableSubmit = false; 
    this.resetchanges();
    this.data = new MerchantModel();
    this.selsalesperson = new Array<EmployeeModel>();
    this.selMcc = new Array<MCCModel>();
    this.bank = new BankModel();
    this.institution = new AggregatorDistributorModel();

    this.aggregator = new AggregatorDistributorModel();
    this.supermerchant = new AggregatorDistributorModel();
    this.distributor = new AggregatorDistributorModel();
    this.dataCompanyProfile = new MerchantCompanyProfileModel();
    this.merchantPartner = new MerchantPartnerModel();
    this._merchantPartner = new MerchantPartnerModel();
    this.merchantBank = new MerchantBankDetailModel();
    this.merchantRisk = new MerchantRiskModel();
    this.merchantTerminal = new MerchantTerminalModel();
    this._merchantTerminal = new MerchantTerminalModel();
    this.merchantTerminalDevice = new DeviceStockModel();
    this._merchantTerminalDevice = new DeviceStockModel();
    this.merchantTerminalSIM = new SIMStockModel();
    this._merchantTerminalSIM = new SIMStockModel();
    this.merchantTerminalRentalPlan = new RentalPlanModel();
    this._merchantTerminalRentalPlan = new RentalPlanModel();
    this.transactionSet = new Array<ChannelNetworkCodeValueModel>();
    this.newCopyTransRateCategories(true);
    this.setModifedFlagsOff();
    this.partnerfiles = new PartnerFiles();
    this.setDefaults();
    this.copyInitData();
    this.getTerminalTypes();
  }
  setModifedFlagsOff() {
    this._isSelPartnerFileModified = false;
    this._isCompanyFileModified = false;
    this._isMerchantFileModified = false;
    this._isPartnersModified = false;
    this._isTerminalsModified = false;
  }
  async setBankDefault() {
    if (this.banklist && this.banklist.length > 0) {
      this.bank = this.banklist[0];
    } else {
      this.bank = new BankModel();
    }
  }
  setDefaults() {
    this.setBankDefault();
    const agglist = this.getAggregatorList();
    let tmpinstition = undefined;
    if (
      this.aggregatorDistributorList &&
      this.aggregatorDistributorList.length > 0
    ) {
      tmpinstition = this.aggregatorDistributorList.filter(
        (obj) => obj.id == 0
      )[0];
    }
    if (tmpinstition) {
      this.institution = { id: tmpinstition.id, name: tmpinstition.name };

      if (agglist) {
      let defaultAggId = tmpinstition.id;
      let defaultAggName = tmpinstition.name;
      if (this.objGlobal.Auth && this.objGlobal.Auth.aggregatorId) {
        defaultAggId = this.objGlobal.Auth.aggregatorId ? this.objGlobal.Auth.aggregatorId : tmpinstition.id;
        const idx = this.aggregatorDistributorList.findIndex(agg => agg.id == defaultAggId)
        if (idx > -1) {
          defaultAggName = this.aggregatorDistributorList[idx].name;
        }
      }
      this.aggregator = { id: defaultAggId, name: defaultAggName };
      this.supermerchant = { id: defaultAggId, name: defaultAggName };
      this.distributor = { id: defaultAggId, name: defaultAggName };
      this.getAggregatorChannelNetwork();

    } else {
      this.institution = new AggregatorDistributorModel();
      this.aggregator = new AggregatorDistributorModel();
      this.supermerchant = new AggregatorDistributorModel();
      this.distributor = new AggregatorDistributorModel();
      }
    } else {
      this.institution = new AggregatorDistributorModel();
      this.aggregator = new AggregatorDistributorModel();
      this.supermerchant = new AggregatorDistributorModel();
      this.distributor = new AggregatorDistributorModel();
    }
    //this.onInstitutionSelect();
    //this.selaggregator=this.getInstituionList();
    this._data = this.tools.copyFullObject(this.data);
  }
  /*onInstitutionSelect() {
  this.selaggregator=this.aggregatordistributorservice.aggregatorDistributorList.filter(obj=> obj.id==this.institution.id);
}*/
  getInstituionList() {
    return this.aggregatorDistributorList.filter((obj) => obj.type == "I");
  }
  getAggregatorList() {
    const userAggregatorId = this.auth.getUserAggregatorId();
    if (userAggregatorId) {
      return this.aggregatorDistributorList.filter(
        (obj) => obj.type == "A" && obj.id == userAggregatorId
      );
    } else {
      return this.aggregatorDistributorList.filter(
        (obj) => obj.type == "A" || obj.type == "I"
      );
    }
  }
  /*onAggregatorSelect(item : any) {
  let tmpSuperMerchant : Array<AggregatorDistributorModel> =new Array<AggregatorDistributorModel>();
  tmpSuperMerchant.push(item);
  this.aggregatordistributorservice.aggregatorDistributorList.filter(obj=> {
    obj.aggregator && obj.aggregator.id == item.id;
  });
}*/
  getSuperMerchantList() {
    let tmpSuperMerchant: Array<AggregatorDistributorModel> = new Array<
      AggregatorDistributorModel
    >();

    if (this.aggregator) {
      if (this.aggregator) {
        let item = this.aggregator;
        tmpSuperMerchant.push(new AggregatorDistributorModel());
        for (let i = 0; i < this.aggregatorDistributorList.length; i++) {
          if (this.aggregatorDistributorList[i].id == item.id) {
            tmpSuperMerchant[0] = this.aggregatorDistributorList[i];
          }
          if (
            this.aggregatorDistributorList[i].aggregator &&
            this.aggregatorDistributorList[i].aggregator.id == item.id
          ) {
            tmpSuperMerchant.push(this.aggregatorDistributorList[i]);
          }
        }
      }
    }
    return tmpSuperMerchant;
  }
  getDistributorList() {
    let tmpDistributorMerchant: Array<AggregatorDistributorModel> = new Array<
      AggregatorDistributorModel
    >();

    if (this.supermerchant) {
      let item = this.supermerchant;
      tmpDistributorMerchant.push(new AggregatorDistributorModel());
      for (let i = 0; i < this.aggregatorDistributorList.length; i++) {
        if (this.aggregatorDistributorList[i].id == item.id) {
          tmpDistributorMerchant[0] = this.aggregatorDistributorList[i];
        }
        if (
          this.aggregatorDistributorList[i].superMerchant &&
          this.aggregatorDistributorList[i].superMerchant.id == item.id
        ) {
          tmpDistributorMerchant.push(this.aggregatorDistributorList[i]);
        }
      }
    }
    return tmpDistributorMerchant;
  }
  getSalesPersonsList() {
    if (this.employeeservice.employeeNameList) {
      return this.employeeservice.employeeNameList.filter((emp) => {
        const empdept = emp.department
          ? emp.department.toLocaleLowerCase()
          : undefined;
        const empaggregator = emp.aggregator ? emp.aggregator.id : 0;
        const aggId = this.aggregator ? this.aggregator.id : 0;
        if (empdept == "sales" && empaggregator == aggId) {
          return true;
        } else {
          return false;
        }
      });
    }
  }

  onCompanyProfileTabClick(tabkey: string) {
    if (tabkey == "companyprofilepartner") {
      this.companytabactive = "Partners";
      this.companyprofileactivetabpartner = true;
    } else if (tabkey == "companyprofilefiles") {
      this.companytabactive = "Files";
    } else {
      this.companytabactive = "Profile";
      this.companyprofileactivetabpartner = false;
    }
  }
  isStatementRequired() {
    return this.dataCompanyProfile.statementRequired == "Y";
  }
  onOffStatementRequired() {
    this.dataCompanyProfile.statementRequired =
      this.dataCompanyProfile.statementRequired == "Y" ? "N" : "Y";
  }
  isBankAchMandat() {
    return this.merchantBank.achMandate == "Y";
  }
  onOffBankAchMandate() {
    // tslint:disable-next-line: quotemark
    this.merchantBank.achMandate =
      this.merchantBank.achMandate === "Y" ? "N" : "Y";
  }
  isPartnerSharing() {
    return this.data.partnerSharing == "Y";
  }
  onOffPartnerSharing() {
    this.data.partnerSharing = this.data.partnerSharing == "Y" ? "N" : "Y";
  }
  isLoanReimbursement() {
    return this.data.loanReimbursement == "Y";
  }
  onOffLoanReimbursement() {
    this.data.loanReimbursement =
      this.data.loanReimbursement == "Y" ? "N" : "Y";
  }
  isInternationCardAcceptance() {
    return this.merchantRisk.internationalCardAcc == "Y";
  }
  onOffInternationCardAcceptance() {
    this.merchantRisk.internationalCardAcc =
      this.merchantRisk.internationalCardAcc == "Y" ? "N" : "Y";
  }

  async setBusinessAddressPincodeData(e) {
    const pincodedata: PincodeModel = await this.pincodeservice.getPincodeData(
      this.data.businessAddressPinCode
    );
    if (pincodedata) {
      this.data.businessAddressCity = pincodedata.taluk;
      this.data.businessAddressDistrict = pincodedata.district;
      this.data.businessAddressState = pincodedata.state;
    } else {
      this.data.businessAddressCity = undefined;
      this.data.businessAddressDistrict = undefined;
      this.data.businessAddressState = undefined;
      e.target.classList.remove("ng-valid");
      e.target.classList.add("ng-invalid");
    }
    if (this.copyaddress) {
      this.copyMerchantBusinessAddressToResidenceAddress();
    }
    if (this.isTerminalLocationSameBusiAddress()) {
      this.copyMerchantBusinessAddressToTerminalRegAddress();
    }
  }
  async setResidenceAddressPincodeData(e) {
    const pincodedata: PincodeModel = await this.pincodeservice.getPincodeData(
      this.data.residenceAddressPinCode
    );
    if (pincodedata) {
      this.data.residenceAddressCity = pincodedata.taluk;
      this.data.residenceAddressDistrict = pincodedata.district;
      this.data.residenceAddressState = pincodedata.state;
    } else {
      this.data.residenceAddressCity = undefined;
      this.data.residenceAddressDistrict = undefined;
      this.data.residenceAddressState = undefined;
      e.target.classList.remove("ng-valid");
      e.target.classList.add("ng-invalid");
    }
  }
  async setCompanyRegAddressPincodeData(e) {
    const pincodedata: PincodeModel = await this.pincodeservice.getPincodeData(
      this.dataCompanyProfile.companyRegAddressPinCode
    );
    if (pincodedata) {
      this.dataCompanyProfile.companyRegAddressCity = pincodedata.taluk;
      this.dataCompanyProfile.companyRegAddressDistrict = pincodedata.district;
      this.dataCompanyProfile.companyRegAddressState = pincodedata.state;
    } else {
      this.dataCompanyProfile.companyRegAddressCity = undefined;
      this.dataCompanyProfile.companyRegAddressDistrict = undefined;
      this.dataCompanyProfile.companyRegAddressState = undefined;
      e.target.classList.remove("ng-valid");
      e.target.classList.add("ng-invalid");
    }
  }
  async setPartnerBusinessAddressPincodeData(e) {
    const pincodedata: PincodeModel = await this.pincodeservice.getPincodeData(
      this.merchantPartner.businessAddressPinCode
    );
    if (pincodedata) {
      this.merchantPartner.businessAddressCity = pincodedata.taluk;
      this.merchantPartner.businessAddressDistrict = pincodedata.district;
      this.merchantPartner.businessAddressState = pincodedata.state;
    } else {
      this.merchantPartner.businessAddressCity = undefined;
      this.merchantPartner.businessAddressDistrict = undefined;
      this.merchantPartner.businessAddressState = undefined;
      e.target.classList.remove("ng-valid");
      e.target.classList.add("ng-invalid");
    }
  }

  async setTerminalRegAddressPincodeData(e) {
    const pincodedata: PincodeModel = await this.pincodeservice.getPincodeData(
      this.merchantTerminal.registeredAddressPinCode
    );

    if (pincodedata) {
      this.merchantTerminal.registeredAddressCity = pincodedata.taluk;
      this.merchantTerminal.registeredAddressDistrict = pincodedata.district;
      this.merchantTerminal.registeredAddressState = pincodedata.state;
    } else {
      this.merchantTerminal.registeredAddressCity = undefined;
      this.merchantTerminal.registeredAddressDistrict = undefined;
      this.merchantTerminal.registeredAddressState = undefined;
      e.target.classList.remove("ng-valid");
      e.target.classList.add("ng-invalid");
    }
  }
  pincodeMsg() {
    return "Please enter valid pincode!";
  }
  onBlurBusinessAddress() {
    if (this.copyaddress) {
      this.copyMerchantBusinessAddressToResidenceAddress();
    }
    if (this.isTerminalLocationSameBusiAddress()) {
      this.copyMerchantBusinessAddressToTerminalRegAddress();
    }
  }
  onOffCopyMerchantBusinessAddressToResidenceAddress() {
    this.copyaddress = !this.copyaddress;
    if (this.copyaddress) {
      this.copyMerchantBusinessAddressToResidenceAddress();
    } else {
      this.data.residenceAddress = undefined;
      this.data.residenceAddressPinCode = undefined;
      this.data.residenceAddressCity = undefined;
      this.data.residenceAddressDistrict = undefined;
      this.data.residenceAddressState = undefined;
    }
  }
  copyMerchantBusinessAddressToResidenceAddress() {
    this.data.residenceAddress = this.data.businessAddress;
    this.data.residenceAddressPinCode = this.data.businessAddressPinCode;
    this.data.residenceAddressCity = this.data.businessAddressCity;
    this.data.residenceAddressDistrict = this.data.businessAddressDistrict;
    this.data.residenceAddressState = this.data.businessAddressState;
  }
  isTerminalLocationSameBusiAddress() {
    return this.merchantTerminal.regAddressAsBusinessAddress == "Y";
  }
  onOffTerminalLocationSameBusiAddress() {
    if (this.merchantTerminal.regAddressAsBusinessAddress != "Y") {
      this.merchantTerminal.regAddressAsBusinessAddress = "Y";
      this.copyMerchantBusinessAddressToTerminalRegAddress();
    } else {
      this.merchantTerminal.regAddressAsBusinessAddress = "N";
    }
  }
  copyMerchantBusinessAddressToTerminalRegAddress() {
    this.merchantTerminal.registeredAddress = this.data.businessAddress;
    this.merchantTerminal.registeredAddressPinCode = this.data.businessAddressPinCode;
    this.merchantTerminal.registeredAddressCity = this.data.businessAddressCity;
    this.merchantTerminal.registeredAddressDistrict = this.data.businessAddressDistrict;
    this.merchantTerminal.registeredAddressState = this.data.businessAddressState;
  }
  async setBankIFSCData() {
    const bankifscdata = await this.bankifscservice.getBankInfoOnIFSCCode(
      this.merchantBank.ifscCode
    );

    if (bankifscdata) {
      this.merchantBank.micr = bankifscdata.micr;
      this.merchantBank.bankName = bankifscdata.bankName;
      this.merchantBank.branchName = bankifscdata.branchName;
      this.merchantBank.city = bankifscdata.city;
      this.merchantBank.state = bankifscdata.state;
      this.merchantBank.pinCode = bankifscdata.pincode;
    } else {
      this.merchantBank.micr = undefined;
      this.merchantBank.bankName = undefined;
      this.merchantBank.branchName = undefined;
      this.merchantBank.city = undefined;
      this.merchantBank.state = undefined;
      this.merchantBank.pinCode = undefined;
    }
  }
  onNewPartnerClick() {
    if (this.isModifiedMerchantPartner()) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.selPartnerIndex = -1;
            this.merchantPartner = new MerchantPartnerModel();
            this._merchantPartner = this.tools.copyFullObject(
              this.merchantPartner
            );
            this.partnerfiles = new PartnerFiles();
            this._isSelPartnerFileModified = false;
          }
        },
        (reason) => {}
      );
    } else {
      this.selPartnerIndex = -1;
      this.merchantPartner = new MerchantPartnerModel();
      this._merchantPartner = this.tools.copyFullObject(this.merchantPartner);
      this._isSelPartnerFileModified = false;
    }
  }
  newPartnerDisabled() {
    return this.selPartnerIndex == -1;
  }
  updatePartnerGrid() {
    this._merchantPartner = this.tools.copyFullObject(this.merchantPartner);
    if (this.selPartnerIndex == -1) {
      if (!this.merchantPartnerList) {
        this.merchantPartnerList = new Array<MerchantPartnerModel>();
      }
      this.selPartnerIndex = this.merchantPartnerList.push(
        this._merchantPartner
      );
      this.selPartnerIndex = this.selPartnerIndex - 1;
    } else {
      this.merchantPartnerList[this.selPartnerIndex] = this._merchantPartner;
    }
    this.pushPartnerfileInList();
    this._isSelPartnerFileModified = false;
    this._isPartnersModified = true;
  }

  addPartnerDisabled(fpartner) {
    return !(fpartner.valid && this.isModifiedMerchantPartner());
  }
  handlePartnerFileInput(files: FileList, inputName: string) {
    if (files && files.length > 0) {
      const file = files.item(0);
      var mimeType = file.type;
      if (
        mimeType.match(/image\/*/) != null ||
        file.type == "application/pdf"
      ) {
        if (!this.partnerfiles) {
          this.partnerfiles = new PartnerFiles();
        }
        if (inputName == "panFile") {
          this.partnerfiles.panFile = file;
        } else if (inputName == "gstFile") {
          this.partnerfiles.gstFile = file;
        } else if (inputName == "aadharFile") {
          this.partnerfiles.aadhaarFile = file;
        } else if (inputName == "voterIdFile") {
          this.partnerfiles.voterIdFile = file;
        } else if (inputName == "otherIdFile") {
          this.partnerfiles.otherIdFile = file;
        }
        this._isSelPartnerFileModified = true;
      } else {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Alert";
        objmsg.message = "Selected file type is not valid!";
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    }
  }
  pushPartnerfileInList() {
    if (!this.partnerfileslist) {
      this.partnerfileslist = new Array<PartnerFiles>();
    }
    if (!this.partnerfiles) {
      this.partnerfiles = new PartnerFiles();
    }
    this.partnerfiles.key = this.selPartnerIndex;
    let foundKey: Boolean = false;
    for (let i = 0; i < this.partnerfileslist.length; i++) {
      if (this.partnerfileslist[i].key == this.selPartnerIndex) {
        this.partnerfileslist[i] = this.tools.copyFullObject(this.partnerfiles);
        foundKey = true;
        break;
      }
    }
    if (!foundKey) {
      this.partnerfileslist.push(this.partnerfiles);
    }
  }
  setPartnerFile() {
    this.partnerfiles = undefined;
    if (this.partnerfileslist) {
      for (let i = 0; i < this.partnerfileslist.length; i++) {
        if (this.partnerfileslist[i].key == this.selPartnerIndex) {
          this.partnerfiles = this.tools.copyFullObject(
            this.partnerfileslist[i]
          );
          break;
        }
      }
    }
  }
  removePartnerFile(key: number) {
    if (this.partnerfileslist) {
      for (let i = 0; i < this.partnerfileslist.length; i++) {
        if (this.partnerfileslist[i].key == this.selPartnerIndex) {
          this.partnerfileslist.splice(i, 1);
          break;
        }
      }
    }
  }
  handleCompanyFileInput(files: FileList, inputName: string) {
    if (files && files.length > 0) {
      const file = files.item(0);
      var mimeType = file.type;
      if (
        mimeType.match(/image\/*/) != null ||
        file.type == "application/pdf"
      ) {
        if (!this.companyfiles) {
          this.companyfiles = new CompanyFiles();
        }
        if (inputName == "panFile") {
          this.companyfiles.panFile = files.item(0);
        } else if (inputName == "gstFile") {
          this.companyfiles.gstFile = files.item(0);
        } else if (inputName == "addressProofFile") {
          this.companyfiles.addressProofFile = files.item(0);
        } else if (inputName == "establishmentLicenseFile") {
          this.companyfiles.establishmentLicenseFile = files.item(0);
        } else if (inputName == "partnershipDeedFile") {
          this.companyfiles.partnershipDeedFile = files.item(0);
        } else if (inputName == "certificateOfIncorporationFile") {
          this.companyfiles.certificateOfIncorporationFile = files.item(0);
        } else if (inputName == "certificateMemorandumFile") {
          this.companyfiles.memorandumFile = files.item(0);
        } else if (inputName == "otherDoc1") {
          this.companyfiles.otherDoc1File = files.item(0);
        } else if (inputName == "otherDoc2") {
          this.companyfiles.otherDoc2File = files.item(0);
        } else if (inputName == "brdirlist") {
          this.companyfiles.brDirListFile = files.item(0);
        } else if (inputName == "trust_deed") {
          this.companyfiles.trustDeedFile = files.item(0);
        } else if (inputName == "electricitybill") {
          this.companyfiles.electricityBillFile = files.item(0);
        } else if (inputName == "telephonebill") {
          this.companyfiles.telephoneBillFile = files.item(0);
        } else if (inputName == "photograph") {
          this.companyfiles.photographFile = files.item(0);
        } else if (inputName == "cancelledChequeFile") {
          this.companyfiles.cancelledChequeFile = files.item(0);
        }
        this._isCompanyFileModified = true;
      } else {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Alert";
        objmsg.message = "Selected file type is not valid!";
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    }
  }
  handleMerchantFileInput(files: FileList, inputName: string) {
    if (files && files.length > 0) {
      const file = files.item(0);
      var mimeType = file.type;
      if (
        mimeType.match(/image\/*/) != null ||
        file.type == "application/pdf"
      ) {
        if (!this.merchantfiles) {
          this.merchantfiles = new MerchantFiles();
        }
        if (inputName == "panFile") {
          this.merchantfiles.panFile = files.item(0);
        } else if (inputName == "gstFile") {
          this.merchantfiles.gstFile = files.item(0);
        } else if (inputName == "aadharFile") {
          this.merchantfiles.aadhaarFile = files.item(0);
        } else if (inputName == "voterIdFile") {
          this.merchantfiles.voterIdFile = files.item(0);
        } else if (inputName == "otherIdFile") {
          this.merchantfiles.otherIdFile = files.item(0);
        } else if (inputName == "cancelledChequeFile") {
          this.merchantfiles.cancelledChequeFile = files.item(0);
        } else if (inputName == "passportFile") {
          this.merchantfiles.passportFile = files.item(0);
        }
        this._isMerchantFileModified = true;
      } else {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Alert";
        objmsg.message = "Selected file type is not valid!";
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    }
  }
  isModifiedMerchantPartner() {
    if (this.merchantPartner.firstName != this._merchantPartner.firstName) {
      return true;
    } else if (
      this.merchantPartner.lastName != this._merchantPartner.lastName
    ) {
      return true;
    } else if (this.merchantPartner.aadhaar != this._merchantPartner.aadhaar) {
      return true;
    } else if (
      this.merchantPartner.aadhaarFileName !=
      this._merchantPartner.aadhaarFileName
    ) {
      return true;
    } else if (
      this.merchantPartner.alternateNumber !=
      this._merchantPartner.alternateNumber
    ) {
      return true;
    } else if (
      this.merchantPartner.businessAddress !=
      this._merchantPartner.businessAddress
    ) {
      return true;
    } else if (
      this.merchantPartner.businessAddressPinCode !=
      this._merchantPartner.businessAddressPinCode
    ) {
      return true;
    } else if (this.merchantPartner.emailId != this._merchantPartner.emailId) {
      return true;
    } else if (this.merchantPartner.gender != this._merchantPartner.gender) {
      return true;
    } else if (this.merchantPartner.gst != this._merchantPartner.gst) {
      return true;
    } else if (
      this.merchantPartner.gstFileName != this._merchantPartner.gstFileName
    ) {
      return true;
    } else if (
      this.merchantPartner.mobileNumber != this._merchantPartner.mobileNumber
    ) {
      return true;
    } else if (
      this.merchantPartner.nationality != this._merchantPartner.nationality
    ) {
      return true;
    } else if (this.merchantPartner.otherId != this._merchantPartner.otherId) {
      return true;
    } else if (
      this.merchantPartner.otherIdFileName !=
      this._merchantPartner.otherIdFileName
    ) {
      return true;
    } else if (this.merchantPartner.pan != this._merchantPartner.pan) {
      return true;
    } else if (
      this.merchantPartner.panFileName != this._merchantPartner.panFileName
    ) {
      return true;
    } else if (
      this.merchantPartner.passportNumber !=
      this._merchantPartner.passportNumber
    ) {
      return true;
    } else if (this.merchantPartner.voterId != this._merchantPartner.voterId) {
      return true;
    } else if (
      this.merchantPartner.voterIdFileName !=
      this._merchantPartner.voterIdFileName
    ) {
      return true;
    } else if (this.merchantPartner.website != this._merchantPartner.website) {
      return true;
    } else if (
      !this.tools.isDateSame(
        this.merchantPartner.passportExpiryDate,
        this._merchantPartner.passportExpiryDate
      )
    ) {
      return true;
    } else if (this._isSelPartnerFileModified) {
      return true;
    }
  }
  selectPartnerData(event) {
    if (this.isModifiedMerchantPartner()) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this._merchantPartner = this.merchantPartnerList[event.index];
            this.merchantPartner = this.tools.copyFullObject(
              this._merchantPartner
            );
            this._isSelPartnerFileModified = false;
            this.selPartnerIndex = event.index;
            this.setPartnerFile();
          }
        },
        (reason) => {}
      );
    } else {
      this._merchantPartner = this.merchantPartnerList[event.index];
      this.merchantPartner = this.tools.copyFullObject(this._merchantPartner);
      this._isSelPartnerFileModified = false;
      this.selPartnerIndex = event.index;
      this.setPartnerFile();
    }
  }

  removePartnerFromGrid(event) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";

    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to remove record?";
    ngmodalref.result.then(
      (result) => {
        if (result == "yes") {
          this.merchantPartnerList.splice(event.index, 1);
          this.removePartnerFile(event.index);
          if (this.selPartnerIndex == event.index) {
            this._merchantPartner = new MerchantPartnerModel();
            this.merchantPartner = this.tools.copyFullObject(
              this._merchantPartner
            );
            this._isSelPartnerFileModified = false;
            this.selPartnerIndex = -1;
          } else if (this.selPartnerIndex > event.index) {
            this.selPartnerIndex = this.selPartnerIndex - 1;
          }
          this._isPartnersModified = true;
        }
      },
      (reason) => {}
    );
  }

  async onBlurDeviceSrNo() {
    this.touchedTerminalDevice = true;
    if (
      this.merchantTerminalDevice.deviceSerialNumber &&
      this.merchantTerminalDevice.deviceSerialNumber != ""
    ) {
      const tmpdevres = await this.devicestockservice.getDeviceDataOnSr(
        this.merchantTerminalDevice.deviceSerialNumber,
        this.merchantTerminal.merchantTerminalId
      );
      if (tmpdevres.success) {
        const tmpTerminalDevice = tmpdevres.result;
        if (tmpTerminalDevice) {
          if (tmpTerminalDevice.deviceCondition == "F") {
            let objmsg: SimpleMessage = new SimpleMessage();
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            objmsg.title = "Alert";
            objmsg.btnClass = "btn-warning";
            objmsg.message = "Selected device is faulty!";
            this.tools.simpleMessage(objmsg);
            this.deviceStockValidation.isValid = false;
            this.deviceStockValidation.msg = "Selected device is faulty!";
          } else {
            this.merchantTerminalDevice.deviceStockId =
              tmpTerminalDevice.deviceStockId;
            this.deviceStockValidation.isValid = true;
            this.deviceStockValidation.msg = "";
            this.deviceStockValidation.additionalInfo = {"allowMultipleTerminals" : tmpTerminalDevice.allowMultipleTerminals}
          }
          this.merchantTerminalDevice.product.modelName =
            tmpTerminalDevice.product.modelName;
        } else {
          this.merchantTerminalDevice.deviceStockId = undefined;
          this.merchantTerminalDevice.product.modelName = undefined;
        }
      } else {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Alert";
        objmsg.btnClass = "btn-warning";
        switch (tmpdevres.message) {
          case "INVALIDDEVICE":
            objmsg.message = "Not a valid device serial number!";
            break;
          case "ACTIVETERMINALMAPPEDWITHDEVICE":
            objmsg.message = "Device mapped with other terminal!";
            break;
          case "Error":
            objmsg.message = "Failed to get device info!";
            break;
          default:
            objmsg.message = "Failed to get device info!";
            break;
        }
        this.merchantTerminalDevice.product.modelName = undefined;
        this.tools.simpleMessage(objmsg);
        this.deviceStockValidation.isValid = false;
        this.deviceStockValidation.msg = objmsg.message;
      }
    } else {
      this.merchantTerminalDevice.deviceStockId = undefined;
      this.merchantTerminalDevice.product.modelName = undefined;
    }
  }
  async onBlurSIMNo() {
    this.touchedTerminalSIM = true;
    if (
      this.merchantTerminalSIM.simNumber &&
      this.merchantTerminalSIM.simNumber != ""
    ) {
      const tmpTerminalSIMres = await this.simstockservice.getSIMStockIdBySimNumber(
        this.merchantTerminalSIM.simNumber,
        this.merchantTerminal.merchantTerminalId
      );
      if (tmpTerminalSIMres) {
        if (tmpTerminalSIMres.success == true) {
          const result = tmpTerminalSIMres.result;
          this.merchantTerminalSIM.simStockId = result["simStockId"];
          this.simStockValidation.additionalInfo = { allowMultipleTerminals: result["allowMultipleTerminals"] };
          this.simStockValidation.isValid = true;
          this.simStockValidation.msg = "";
        } else {
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Alert";
          objmsg.btnClass = "btn-warning";
          switch (tmpTerminalSIMres.message) {
            case "SIMNOTFOUND":
              objmsg.message = "Not a valid SIM number!";
              break;
            case "SIMALREADYMAPPEDWITHTERMINAL":
              objmsg.message = "SIM mapped with other terminal!";
              break;
            case "Error":
              objmsg.message = "Failed to get SIM info!";
              break;
            default:
              objmsg.message = "Failed to get SIM info!";
              break;
          }
          this.simStockValidation.isValid = false;
          this.simStockValidation.msg = objmsg.message;
          this.tools.simpleMessage(objmsg);
        }
      }
    } else {
      this.merchantTerminalSIM.simStockId = undefined;
    }
  }
  isModifiedTerminalEnableUpdateBtn() {
    if (
      this.merchantTerminalDevice.deviceStockId !=
      this._merchantTerminalDevice.deviceStockId
    ) {
      return true;
    } else if (
      this.isModifiedTerminal(this.merchantTerminal, this._merchantTerminal)
    ) {
      return true;
    } else if (
      this.merchantTerminalRentalPlan.code !=
      this._merchantTerminalRentalPlan.code
    ) {
      return true;
    } else if (
      this.merchantTerminalSIM.simStockId !=
      this._merchantTerminalSIM.simStockId
    ) {
      return true;
    }
  }
  isModifiedObject(obj1: any, obj2: any, field: string) {
    if (obj1 == undefined && obj2 == undefined) {
      return false;
    } else if (obj1 && !obj2) {
      return true;
    } else if (!obj1 && obj2) {
      return true;
    } else if (obj1[field] != obj2[field]) {
      return true;
    }
    return false;
  }
  isModifiedTerminalSave(
    terminal1: MerchantTerminalModel,
    terminal2: MerchantTerminalModel
  ) {
    if (this.isModifiedTerminal(terminal1, terminal2)) {
      return true;
    } else if (
      this.isModifiedObject(terminal1.device, terminal2.device, "deviceStockId")
    ) {
      return true;
    } else if (
      this.isModifiedObject(terminal1.rentalPlan, terminal2.rentalPlan, "code")
    ) {
      return true;
    } else if (
      this.isModifiedObject(terminal1.sim, terminal2.sim, "simStockId")
    ) {
      return true;
    }
    return false;
  }
  isModifiedTerminal(
    terminal1: MerchantTerminalModel,
    terminal2: MerchantTerminalModel
  ) {
    if (terminal1.deviceOwned != terminal2.deviceOwned) {
      return true;
    } else if (terminal1.devicePrice != terminal2.devicePrice) {
      return true;
    } else if (terminal1.installationFee != terminal2.installationFee) {
      return true;
    } else if (terminal1.maxUsageDaily != terminal2.maxUsageDaily) {
      return true;
    } else if (terminal1.maxUsageMonthly != terminal2.maxUsageMonthly) {
      return true;
    } else if (terminal1.maxUsageWeekly != terminal2.maxUsageWeekly) {
      return true;
    } else if (terminal1.registeredAddress != terminal2.registeredAddress) {
      return true;
    } else if (
      terminal1.registeredAddressCity != terminal2.registeredAddressCity
    ) {
      return true;
    } else if (
      terminal1.registeredAddressDistrict != terminal2.registeredAddressDistrict
    ) {
      return true;
    } else if (
      terminal1.registeredAddressPinCode != terminal2.registeredAddressPinCode
    ) {
      return true;
    } else if (
      terminal1.registeredAddressState != terminal2.registeredAddressState
    ) {
      return true;
    } else if (terminal1.deviceLocation != terminal2.deviceLocation) {
      return true;
    } else if (terminal1.remarks != terminal2.remarks) {
      return true;
    } else if (terminal1.rentAmount != terminal2.rentAmount) {
      return true;
    } else if (terminal1.rentFrequency != terminal2.rentFrequency) {
      return true;
    } else if (
      !this.tools.isDateSame(terminal1.rentStartDate, terminal2.rentStartDate)
    ) {
      return true;
    } else if (terminal1.rentalThreshold != terminal2.rentalThreshold) {
      return true;
    } else if (terminal1.rentalType != terminal2.rentalType) {
      return true;
    } else if (terminal1.rentalMode != terminal2.rentalMode) {
      return true;
    } else if (terminal1.terminalType != terminal2.terminalType) {
      return true;
    } else if (terminal1.velocityCheckCount != terminal2.velocityCheckCount) {
      return true;
    } else if (terminal1.velocityCheckMinute != terminal2.velocityCheckMinute) {
      return true;
    } else if (terminal1.storeName != terminal2.storeName) {
      return true;
    } else if (terminal1.phoneNumber != terminal2.phoneNumber) {
      return true;
    } else if (terminal1.emailId != terminal2.emailId) {
      return true;
    } else {
      return false;
    }
  }

  AddNewTerminal() {
    this.isNewTerminal=true;
    this.deviceStockValidation = new ValidMsg();
    this.simStockValidation = new ValidMsg();
    this.deviceStockValidation.isValid = true;
    this.simStockValidation.isValid = true;
    this.terminalactivetabdetail = true;
    this.touchedTerminalDevice = false;
    this.touchedTerminalSIM = false;
    this.selTerminalIndex = -1;
    this.merchantTerminal = new MerchantTerminalModel();
    this.merchantTerminalDevice = new DeviceStockModel();
    this.merchantTerminalSIM = new SIMStockModel();
    this.merchantTerminalRentalPlan = new RentalPlanModel();
    this.merchantTerminal.velocityCheckCount = 1;
    this.merchantTerminal.velocityCheckMinute = 1;
    this.merchantTerminal.maxUsageDaily = 30000;
    this.merchantTerminal.maxUsageWeekly = 200000;
    this.merchantTerminal.maxUsageMonthly = 6000000;
    this.merchantTerminal.rentalType = "R";
    if (
      this.data &&
      this.data.bankmerchant &&
      this.data.merchantStatus == "BB"
    ) {
      this.merchantTerminal.rentalPlan = this.data.bankmerchant.rentalPlan;
      this.merchantTerminal.rentAmount = this.data.bankmerchant.rentAmount;
      if (this.data.bankmerchant.rentStartDate) {
        this.merchantTerminal.rentStartDate = new Date(
          this.data.bankmerchant.rentStartDate
        );
      }
      this.merchantTerminal.rentFrequency = this.data.bankmerchant.rentFrequency;
    }
    this._merchantTerminal = this.tools.copyFullObject(this.merchantTerminal);
    this._merchantTerminalDevice = this.tools.copyFullObject(
      this.merchantTerminalDevice
    );
    this._merchantTerminalSIM = this.tools.copyFullObject(
      this.merchantTerminalSIM
    );
    this._merchantTerminalRentalPlan = this.tools.copyFullObject(
      this.merchantTerminalRentalPlan
    );
  }

  onTerminalBackbuttonClick() {
    if (this.isModifiedTerminalEnableUpdateBtn()) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this._disableSubmit = false; 
            this.terminalactivetabdetail = false;
            this.selTerminalIndex = -1;
          }
        },
        (reason) => {}
      );
    } else {
      this.terminalactivetabdetail = false;
      this.selTerminalIndex = -1;
    }
  }
  validateterminalentry() {
    let isvalid: boolean = true;
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    objmsg.title = "Alert";
    objmsg.btnClass = "btn-warning";
    if (this.deviceStockValidation.isValid == false) {
      isvalid = false;
      objmsg.message = this.deviceStockValidation.msg;
    }
    if (isvalid) {
      if (this.simStockValidation.isValid == false) {
        isvalid = false;
        objmsg.message = this.simStockValidation.msg;
      }
    }
    if (isvalid) {
      if (this.merchantTerminalDevice.deviceStockId) {
        const allowMulitpleDevice = this.deviceStockValidation && this.deviceStockValidation.additionalInfo ? this.deviceStockValidation.additionalInfo.allowMultipleTerminals : false;
        if ((!allowMulitpleDevice) && this.touchedTerminalDevice && this.merchantTerminalList && this.merchantTerminalList.length > 0) {
          let notfound = true;
          for (
            let i: number = 0;
            i < this.merchantTerminalList.length && notfound;
            i++
          ) {
            const deviceStockId = this.merchantTerminalList[i].device
              ? this.merchantTerminalList[i].device.deviceStockId
              : undefined;
            if (
              deviceStockId == this.merchantTerminalDevice.deviceStockId &&
              this.merchantTerminalList[i].merchantTerminalId !=
                this.merchantTerminal.merchantTerminalId
            ) {
              notfound = false;
            }
          }
          if (notfound == false) {
            isvalid = false;
            objmsg.message = "Device already added in list!";
          }
        }
      } else {
        isvalid = false;
        objmsg.message = "Invalid device!";
      }
    }
    if (isvalid) {
      if (this.merchantTerminalSIM.simStockId) {
        const allowMulitpleSIM = this.simStockValidation && this.simStockValidation.additionalInfo ? this.simStockValidation.additionalInfo.allowMultipleTerminals : false;
        if ((!allowMulitpleSIM) && this.touchedTerminalSIM && this.merchantTerminalList && this.merchantTerminalList.length > 0) {
          let notfound = true;
          for (
            let i: number = 0;
            i < this.merchantTerminalList.length && notfound;
            i++
          ) {
            const oldSimStockId = this.merchantTerminalList[i].sim
              ? this.merchantTerminalList[i].sim.simStockId
              : undefined;
            const newSimStockId = this.merchantTerminalSIM
              ? this.merchantTerminalSIM.simStockId
              : undefined;
            if (
              oldSimStockId == newSimStockId &&
              this.merchantTerminalList[i].merchantTerminalId !=
                this.merchantTerminal.merchantTerminalId
            ) {
              notfound = false;
            }
          }
          if (notfound == false) {
            isvalid = false;
            objmsg.message = "SIM already added in list!";
          }
        }
      }
    }
    if (!isvalid) {
      this.tools.simpleMessage(objmsg);
    }
    return isvalid;
  }
  updateTerminalGrid() {
    if (this.merchantTerminal.status != "P" && this.validateterminalentry()) {
      if (this.merchantTerminalDevice.deviceStockId) {
        this.merchantTerminal.device = this.merchantTerminalDevice;
      }
      if (this.merchantTerminalSIM.simStockId) {
        this.merchantTerminal.sim = this.merchantTerminalSIM;
      }
      if (this.merchantTerminalRentalPlan.code) {
        this.merchantTerminal.rentalPlan = this.merchantTerminalRentalPlan.code;
      }
      this._merchantTerminal = this.tools.copyFullObject(this.merchantTerminal);
      this._merchantTerminalDevice = this.tools.copyFullObject(
        this.merchantTerminalDevice
      );
      this._merchantTerminalSIM = this.tools.copyFullObject(
        this.merchantTerminalSIM
      );
      this._merchantTerminalRentalPlan = this.tools.copyFullObject(
        this.merchantTerminalRentalPlan
      );
      if (this.selTerminalIndex == -1) {
        if (!this.merchantTerminalList) {
          this.merchantTerminalList = new Array<MerchantTerminalModel>();
        }
        this.selTerminalIndex = this.merchantTerminalList.push(
          this._merchantTerminal
        );
        this.selTerminalIndex = this.selTerminalIndex - 1;
      } else {
        this.merchantTerminalList[
          this.selTerminalIndex
        ] = this._merchantTerminal;
      }
      this._isTerminalsModified = true;
      if (this.data.merchantStatus == "P") {
        if (!this.modifiedTerminalIdxList) {
          this.modifiedTerminalIdxList = new Array<number>();
        }
        if (
          !this.modifiedTerminalIdxList.find(
            (idx) => idx == this.selTerminalIndex
          )
        ) {
          this.modifiedTerminalIdxList.push(this.selTerminalIndex);
        }
      }
    }
  }

  onTerminalGridRowDblClick(event) {
    this.isNewTerminal=false;
    this.terminalactivetabdetail = true;
    this.deviceStockValidation = new ValidMsg();
    this.simStockValidation = new ValidMsg();
    this.deviceStockValidation.isValid = true;
    this.simStockValidation.isValid = true;
    this.touchedTerminalDevice = false;
    this.touchedTerminalSIM = false;
    this._merchantTerminal = this.merchantTerminalList[event.index];
    this.merchantTerminal = this.tools.copyFullObject(this._merchantTerminal);
    if (this.merchantTerminal.rentStartDate) {
      this.merchantTerminal.rentStartDate = new Date(
        this.merchantTerminal.rentStartDate
      );
      this._merchantTerminal.rentStartDate = new Date(
        this._merchantTerminal.rentStartDate
      );
    }
    if (this.merchantTerminal.device) {
      this.merchantTerminalDevice = this.merchantTerminal.device;
    } else {
      this.merchantTerminalDevice = new DeviceStockModel();
    }
    if (this.merchantTerminal.sim) {
      this.merchantTerminalSIM = this.merchantTerminal.sim;
    } else {
      this.merchantTerminalSIM = new SIMStockModel();
    }
    if (this.merchantTerminal.rentalPlan) {
      this.merchantTerminalRentalPlan.code = this.merchantTerminal.rentalPlan;
    } else {
      this.merchantTerminalRentalPlan = new RentalPlanModel();
    }

    this._merchantTerminalDevice = this.tools.copyFullObject(
      this.merchantTerminalDevice
    );
    this._merchantTerminalSIM = this.tools.copyFullObject(
      this.merchantTerminalSIM
    );
    this._merchantTerminalRentalPlan = this.tools.copyFullObject(
      this.merchantTerminalRentalPlan
    );

    this.selTerminalIndex = event.index;
  }

  deleteTerminalData(event) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";

    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to remove record?";
    ngmodalref.result.then(
      (result) => {
        if (result == "yes") {
          this.merchantTerminalList.splice(event.index, 1);
          this._isTerminalsModified = true;
        }
      },
      (reason) => {}
    );
  }
  addTerminalDisabled(fterminal) {
    return !(fterminal.valid && this.isModifiedTerminalEnableUpdateBtn());
  }
  onCancelTerminal() {
    if (this.isModifiedTerminalEnableUpdateBtn()) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.merchantTerminal = this.tools.copyFullObject(
              this._merchantTerminal
            );
            this.merchantTerminalDevice = this.tools.copyFullObject(
              this._merchantTerminalDevice
            );
            this.merchantTerminalSIM = this.tools.copyFullObject(
              this._merchantTerminalSIM
            );
            this.merchantTerminalRentalPlan = this.tools.copyFullObject(
              this._merchantTerminalRentalPlan
            );
          }
        },
        (reason) => {}
      );
    } else {
      this.merchantTerminal = this.tools.copyFullObject(this._merchantTerminal);
      this.merchantTerminalDevice = this.tools.copyFullObject(
        this._merchantTerminalDevice
      );
      this.merchantTerminalSIM = this.tools.copyFullObject(
        this._merchantTerminalSIM
      );
      this.merchantTerminalRentalPlan = this.tools.copyFullObject(
        this._merchantTerminalRentalPlan
      );
    }
  }
  //selectrentalplan(event) {
  //  this.merchantTerminalRentalPlan.name= event.target.options[event.target.selectedIndex].text;
  //}

  async onSelectRentalPlan() {
    const selrentalplan = this.rentalplanlist.filter(
      (itm) => itm.code == this.merchantTerminalRentalPlan.code
    );
    if (selrentalplan && selrentalplan.length > 0) {
      this.merchantTerminal.rentFrequency = selrentalplan[0].rentFrequency;
      this.merchantTerminal.rentAmount = selrentalplan[0].rent;
    } else {
      this.merchantTerminal.rentFrequency = undefined;
      this.merchantTerminal.rentAmount = undefined;
    }
  }

  async newCopyTransRateCategories(assign: boolean) {
    const transcatlist = await this.transCategoryService.getTransCategoriesList();
    this._newMdrPlanTransRates = new Array<TransRatesModel>();

    for (let i = 0; i < transcatlist.length; i++) {
      let transrate = new TransRatesModel();
      transrate.transCategory = transcatlist[i];
      transrate.fromAmount = transcatlist[i].fromAmount;
      transrate.toAmount = transcatlist[i].toAmount;
      this._newMdrPlanTransRates.push(transrate);
    }
    if (assign) {
      this.mdrPlanTransRates = this._newMdrPlanTransRates;
    }
  }
  onCancel() {
    if (this.isModified(true) == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Merchant data has been modified. Are you sure to discard without save?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.setDataFromInit();
          }
        },
        (reason) => {}
      );
    }
  }
  setDataFromInit() {
    this.data = this.tools.copyFullObject(this._data);
    this.dataCompanyProfile = this.tools.copyFullObject(
      this._dataCompanyProfile
    );
    if (this.dataCompanyProfile.comapnyEstablishYear) {
      this.dataCompanyProfile.comapnyEstablishYear = new Date(
        this.dataCompanyProfile.comapnyEstablishYear
      );
    }
    this.bank = this.tools.copyFullObject(this._bank);
    this.institution = this.tools.copyFullObject(this._institution);
    this.aggregator = this.tools.copyFullObject(this._aggregator);
    this.supermerchant = this.tools.copyFullObject(this._superMerchant);
    this.distributor = this.tools.copyFullObject(this._distributor);
    this.merchantBank = this.tools.copyFullObject(this._merchantBank);
    this.merchantRisk = this.tools.copyFullObject(this._merchantRisk);
    this.mdrPlanTransRates = this.tools.copyFullObject(this._mdrPlanTransRates);
    this.resetchanges();
    this.getTerminalTypes();
  }

  onSaveClick() {
    if (this.data.merchantStatus != "P" || this.isModified(false)) {
      this.collectData();
      this.initSave();
    } else if (this._isTerminalsModified) {
      const modifiedTerminalSet = this.identifyTerminalsChanges("S");
      if (modifiedTerminalSet) {
        this.collectData();
        this.updateTerminals(modifiedTerminalSet);
      } else {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Alert";
        objmsg.message = "No changes found in Merchant or Terminal!";
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    }
  }

  updateTerminals(modifiedTerminalSet: Array<MerchantTerminalModel>) {
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    const multi = modifiedTerminalSet.length > 1 ? true : false;
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    this.merchantservice.terminalUpdateAction(modifiedTerminalSet).subscribe(
      (data) => {
        if (data["success"]) {
          this._isTerminalsModified = false;
          this.getDetail(this.data.merchantId);
          objmsg.title = "Success";
          objmsg.message = multi
            ? "Terminals"
            : "Terminal" + " saved successfully!";
          objmsg.btnClass = "btn-success";
        } else {
          objmsg.title = "Error";
          objmsg.message =
            "Error resposne from server while save " +
            (multi ? "terminals" : "terminal") +
            "!";
          objmsg.btnClass = "btn-error";
        }
        this.tools.simpleMessage(objmsg);
      },
      (err) => {
        objmsg.title = "Error";
        objmsg.message =
          "Error to save " + (multi ? "terminals" : "terminal") + "!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  saveData() {
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";

    const isSubmitClick: boolean = this.data.merchantStatus == "R";
    this.merchantservice
      .saveMerchant(
        this.data,
        this.merchantfiles,
        this.companyfiles,
        this.partnerfileslist
      )
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.data = data["result"];
            objmsg.title = "Success";
            objmsg.message =
              "Merchant Detail successfully " +
              (isSubmitClick ? "submitted!" : "saved!");
            objmsg.btnClass = "btn-success";
            this.tools.simpleMessage(objmsg);
            this._isModified = true;
            this.distributeData(true);
            this._isMerchantFileModified = false;
            this._downloadedMerchantFiles = undefined;
            this._downloadedCompanyFiles = undefined;
            this.resetchanges();
          } else {
            this.data.merchantStatus = this._data.merchantStatus;
            switch (data["message"]) {
              case "NAMEALREADYEXISTS": {
                objmsg.title = "Alert";
                objmsg.message =
                  "Merchant company name already exists for different record!";
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
              }
              case "PHONENUMBERALREADYEXISTS": {
                objmsg.title = "Alert";
                objmsg.message =
                  "Phone Number already exists for different record!";
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
              }
              case "INVALIDRECORD": {
                objmsg.title = "Alert";
                objmsg.message = "Invalid record to modify!";
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
              }
              default: {
                objmsg.title = "Error";
                objmsg.message =
                  "Error in " +
                  (isSubmitClick ? "submit" : "save") +
                  " record!";
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
              }
            }
          }
        },
        (err) => {
          this.data.merchantStatus = this._data.merchantStatus;
          objmsg.title = "Error";
          objmsg.message =
            "Error in " + (isSubmitClick ? "submit" : "save") + " record!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  isTerminalSstatus() {
    let bresult = false;
    this.merchantTerminalList.forEach((obj) => {
      if (obj.status == "S") {
        bresult = true;
      }
    });
    return bresult;
  }

  validateSubmit() {
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    objmsg.title = "Warning";
    objmsg.btnClass = "btn-warning";
    if (!(this.merchantTerminalList && this.merchantTerminalList.length > 0)) {
      objmsg.message = "Atleast one terminal is mandatory!";
      this.tools.simpleMessage(objmsg);
      return false;
    } else if (!this.merchantRisk.merchantTypeCode) {
      objmsg.message = "Merchant Type code in Risk tab is mandatory!";
      this.tools.simpleMessage(objmsg);
      return false;
    }
    return true;
  }
  onSubmitClick() {
    if (this.data.merchantStatus == "P") {
      if (this._isTerminalsModified || this.isTerminalSstatus()) {
        const modifiedTerminalSet = this.identifyTerminalsChanges("R");
        this.collectData();
        this.updateTerminals(modifiedTerminalSet);
      }
    } else if (this.validationPass() && this.validateSubmit()) {
      if (this.validateDocuments()) {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        const ngmodalref = this.ngbModalService.open(
          NgYesNoModalContent,
          ngmodaloptions
        );
        ngmodalref.componentInstance.title = "Confirmation";
        ngmodalref.componentInstance.message =
          "Are you sure to submit merchant data?";
        ngmodalref.result.then(
          (result) => {
            if (result == "yes") {
              this.collectData();
              this.data.merchantStatus = "R";
              this.saveData();
            }
          },
          (reason) => {}
        );
      }
    }
  }

  initSave() {
    if (this.validationPass()) {
      this.data.merchantStatus = "S";
      this.saveData();
    }
  }
  validationPass() {
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    objmsg.title = "Warning";
    objmsg.btnClass = "btn-warning";
    if (
      this.data.applicationDate &&
      new Date(this.tools.formatDateToDDMMMYY(this.data.applicationDate)) >
        new Date()
    ) {
      objmsg.message =
        "Application Date should not be greater than current date";
      this.tools.simpleMessage(objmsg);
      return false;
    }
    if (
      this.data.agreementDate &&
      new Date(this.tools.formatDateToDDMMMYY(this.data.agreementDate)) >
        new Date()
    ) {
      objmsg.message =
        "AgreementDate Date should not be greater than current date";
      this.tools.simpleMessage(objmsg);
      return false;
    }
    if (
      this.data.preboardingDate &&
      this.data.applicationDate &&
      new Date(this.tools.formatDateToDDMMMYY(this.data.applicationDate)) <
        new Date(this.tools.formatDateToDDMMMYY(this.data.preboardingDate))
    ) {
      objmsg.message =
        "Application Date should not be less than preboarding date";
      this.tools.simpleMessage(objmsg);
      return false;
    }

    return true;
  }
  validateDocuments() {
    const selecteddocs = this.selectedFilesCodes();
    const docValidation = this.merchantservice.validateMandatoryDocuments(
      this.dataCompanyProfile.businessEntityType,
      selecteddocs
    );
    if (docValidation.missingdoc == true) {
      let objmsg: SimpleMessage = new SimpleMessage();
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      objmsg.title = "Warning";
      objmsg.message = "Missing Documents " + docValidation.msg + "!";
      objmsg.btnClass = "btn-warning";
      this.tools.simpleMessage(objmsg);
      return false;
    }
    return true;
  }

  getDetail(id: number) {
    this.isNewMerchant=false;
    this._disableSubmit = false; 
    this.setModifedFlagsOff();
    if (id) {
      this.merchantservice.getMerchant(id).subscribe(
        (data) => {
          if (data["success"]) {
            this.data = data["result"];
            this._isModified = true;
            this.distributeData(true);
            this.resetchanges();
          } else {
            if (data["message"] == "INVALIDRECORD") {
              let objmsg: SimpleMessage = new SimpleMessage();
              let ngmodaloptions: NgbModalOptions = {};
              ngmodaloptions.backdrop = "static";
              ngmodaloptions.size = "sm";
              objmsg.title = "Error";
              objmsg.message = "Invalid Merchant Id!";
              objmsg.btnClass = "btn-error";
              this.tools.simpleMessage(objmsg);
            } else if (data["message"] == "Error") {
              let objmsg: SimpleMessage = new SimpleMessage();
              let ngmodaloptions: NgbModalOptions = {};
              ngmodaloptions.backdrop = "static";
              ngmodaloptions.size = "sm";
              objmsg.title = "Error";
              objmsg.message = "Error found in response!";
              objmsg.btnClass = "btn-error";
              this.tools.simpleMessage(objmsg);
            }
          }
        },
        (err) => {
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Error";
          objmsg.message = "Error while fetching record!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }
  showApproveRejectButton() {
    if (this.action == "new") {
      return false;
    } else if (
      this.data.merchantStatus != "R" &&
      this.data.merchantStatus != "H"
    ) {
      return false;
    }
    if (this.data.lastModifiedBy == this.objGlobal.Auth.user) {
      return false;
    } else if (!this.approvePriv) {
      return false;
    }
    return true;
  }
  onHoldClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to hold merchant?";
    ngmodalref.result.then(
      (result) => {
        if (result == "yes") {
          this.approveRejectMerchant("H");
        }
      },
      (reason) => {}
    );
  }
  onApproveClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to approve merchant?";
    ngmodalref.result.then(
      (result) => {
        if (result == "yes") {
          this.approveRejectMerchant("A");
        }
      },
      (reason) => {}
    );
  }
  // onTerminalApproveClick() {
  //   let ngmodaloptions: NgbModalOptions = {};
  //   ngmodaloptions.backdrop = "static";
  //   ngmodaloptions.size = "sm";
  //   const ngmodalref = this.ngbModalService.open(
  //     NgYesNoModalContent,
  //     ngmodaloptions
  //   );
  //   ngmodalref.componentInstance.title = "Confirmation";
  //   ngmodalref.componentInstance.message = "Are you sure to submit Terminal to Bank?";
  //   ngmodalref.result.then(
  //     (result) => {
  //       if (result == "yes") {
  //         this.approveRejectMerchant("A");
  //       }
  //     },
  //     (reason) => {}
  //   );
  // }
  onRejectClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to reject merchant?";
    ngmodalref.result.then(
      (result) => {
        if (result == "yes") {
          this.approveRejectMerchant("J");
        }
      },
      (reason) => {}
    );
  }

  setAllTerminalStatus(status: string) {
    if(this.merchantTerminalList) {
      for (let i = 0; i < this.merchantTerminalList.length; i++) {
        this.merchantTerminalList[i].status = status;
      }
    }
  }
  approveRejectMerchant(approvalType: string) {
    let merchant: MerchantModel = new MerchantModel();
    merchant.merchantId = this.data.merchantId;
    merchant.merchantStatus = approvalType;
    this.merchantservice.approveRejectMerchant(merchant).subscribe(
      (res) => {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        if (res["success"]) {
          const result = res["result"];
          const resultStatus = result["merchantStatus"];
          this.data.merchantStatus = approvalType;
          if (approvalType == "A" && resultStatus !='C') {
            this.data.merchantStatus = "A";
            this.setAllTerminalStatus("A");
           // this.merchantMARSUpdate("A");
          } else {
            const id = res["result"].merchantId;
            this.getDetail(id);
            objmsg.title = "Success";
            objmsg.message =
              "Merchant " +
              (approvalType == "J"
                ? "rejected!"
                : approvalType == "H"
                ? " on hold"
                : "approved!");
            objmsg.btnClass = "btn-success";
            this.tools.simpleMessage(objmsg);
          }
        } else {
          switch (res["message"]) {
            case "INVALIDRECORD": {
              objmsg.title = "Alert";
              objmsg.message =
                "Invalid record to " +
                (approvalType == "J"
                  ? "reject!"
                  : approvalType == "H"
                  ? " hold"
                  : "approve!");
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
            }
            default: {
              objmsg.title = "Error";
              objmsg.message =
                "Failed to " +
                (approvalType == "J"
                  ? "reject"
                  : approvalType == "H"
                  ? " hold"
                  : "approve") +
                " merchant!";
              objmsg.btnClass = "btn-error";
              this.tools.simpleMessage(objmsg);
            }
          }
        }
      },
      (err) => {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Error";
        objmsg.message =
          "Failed to " +
          (approvalType == "J"
            ? "reject"
            : approvalType == "H"
            ? " hold"
            : "approve") +
          " merchant!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }
  marsFailedTerminalCount(res: any) {
    const terminalmarsresponse = res.terminals;
    let failedterminalcount = 0;
    if (terminalmarsresponse) {
      for (let i = 0; i < terminalmarsresponse.length; i++) {
        for (let j = 0; j < this.merchantTerminalList.length; j++) {
          if (
            this.merchantTerminalList[j].merchantTerminalId ==
            terminalmarsresponse[i].merchantTerminalId
          ) {
            this.merchantTerminalList[j].status =
              terminalmarsresponse[i].status;
          }
        }
        if (!terminalmarsresponse[i].success) {
          failedterminalcount = failedterminalcount + 1;
        }
      }
    }
    console.log("count of failed terminal = " + failedterminalcount);
    return failedterminalcount;
  }
  merchantMARSUpdate(strSource: string) {
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    this.merchantservice.merchantMARSUpdate(this.data.merchantId).subscribe(
      (res) => {
        //console.log(res["merchant"]);
        const d = res["merchant"];
        if (d["merchantStatus"]) {
          this.data.merchantStatus = d["merchantStatus"];
        }
        if (res["success"]) {
          const failedterminalcnt = this.marsFailedTerminalCount(res);
          this._disableSubmit = true; 
          if (failedterminalcnt < 1) {
            objmsg.title = "Success";
            objmsg.btnClass = "btn-success";
            if (strSource == "A") {
              objmsg.message =
                "Merchant approved and updated in Axis successfully!";
            } else {
              objmsg.message = "Merchant updated in Axis successfully!";
            }
          } else {
            objmsg.title = "Warning";
            objmsg.btnClass = "btn-success";
            if (strSource == "A") {
              objmsg.message =
                "Merchant approved and updated in Axis successfully " +
                failedterminalcnt +
                " terminal failed to update in Axis!";
            } else {
              objmsg.message =
                "Merchant updated in Axis successfully " +
                failedterminalcnt +
                " terminal failed to update in Axis!";
            }
          }
          this.updateMARSUpdateResponse(res["result"]);
        } else {
          this.data.merchantStatus = "F";  
          objmsg.title = "Error";
          if (strSource == "A") {
            objmsg.message =
              "Merchant approved successfully but Axis Update api returned error response!";
          } else {
            objmsg.message =
              "Merchant Axis Update api returned error response!";
          }
          objmsg.btnClass = "btn-error";
        }
        this.tools.simpleMessage(objmsg);
      }, (err) => {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Error";
        if (strSource == "A") {
          objmsg.message =
            "Merchant approved successfully but error in Axis Update!";
        } else {
          objmsg.message = "Error in Axis Update!";
        }
        objmsg.btnClass = "btn-error";
      }
    );
  }

  noAllowEdit() {
    return this.showApproveRejectButton() || this.noAllowSaveSubmitOnStatus();
  }

  populateMccList() {
    this.mccservice.getAllMCC().subscribe(
      (res) => {
        if (res["success"]) {
          this.mcclist = res["result"];
        } else {
          this.mcclist = new Array<MCCModel>();
          console.error("failed to fetch mcc list!");
        }
      },
      (err) => {
        this.mcclist = new Array<MCCModel>();
        console.error("error while fetching mcc list!" + err);
      }
    );
  }

  async previewMerchantImage(fileType: string) {
    let file: File;
    let fileName: string;
    switch (fileType) {
      case "pan":
        file = this.merchantfiles.panFile;
        this.previewFileType = "PAN";
        fileName = this.data.panFileName;
        break;
      case "aadhaar":
        file = this.merchantfiles.aadhaarFile;
        this.previewFileType = "AADHAAR";
        fileName = this.data.aadhaarFileName;
        break;
      case "gst":
        file = this.merchantfiles.gstFile;
        this.previewFileType = "GST";
        fileName = this.data.gstFileName;
        break;
      case "voterId":
        file = this.merchantfiles.voterIdFile;
        this.previewFileType = "Voter Id";
        fileName = this.data.voterIdFileName;
        break;
      case "otherId":
        file = this.merchantfiles.otherIdFile;
        this.previewFileType = "Other Id";
        fileName = this.data.otherIdFileName;
        break;
      case "cancelledCheque":
        file = this.merchantfiles.cancelledChequeFile;
        this.previewFileType = "Cancelled Cheque";
        fileName = this.data.cancelledChequeFileName;
        break;
      case "passport":
        file = this.merchantfiles.passportFile;
        this.previewFileType = "passport";
        fileName = this.data.passportFileName;
        break;
    }
    if (!file) {
      file = this.getFromDownloaedMerchantFiles(fileType);
    }
    var reader = new FileReader();
    if (file) {
      var mimeType = file.type;
      this.showImage(file);
    } else {
      await this.merchantservice
        .downloadMerchantfile(this.data.merchantId, fileType)
        .then((data) => {
          this.saveDownloadedMerchantFile(
            new File([data], fileType + "File", { type: data.type }),
            fileType
          );

          this.showImage(data);
        });
    }
  }

  saveDownloadedMerchantFile(file: File, inputName) {
    if (!this._downloadedMerchantFiles) {
      this._downloadedMerchantFiles = new MerchantFiles();
    } else if (inputName == "pan") {
      this._downloadedMerchantFiles.panFile = file;
    } else if (inputName == "gst") {
      this._downloadedMerchantFiles.gstFile = file;
    } else if (inputName == "aadhaar") {
      this._downloadedMerchantFiles.aadhaarFile = file;
    } else if (inputName == "votherId") {
      this._downloadedMerchantFiles.voterIdFile = file;
    } else if (inputName == "otherId") {
      this._downloadedMerchantFiles.otherIdFile = file;
    } else if (inputName == "cancelledCheque") {
      this._downloadedMerchantFiles.otherIdFile = file;
    }
  }

  getFromDownloaedMerchantFiles(fileType: string) {
    let file: File;
    if (this._downloadedMerchantFiles) {
      switch (fileType) {
        case "pan":
          file = this._downloadedMerchantFiles.panFile;
          break;
        case "aadhaar":
          file = this._downloadedMerchantFiles.aadhaarFile;
          break;
        case "gst":
          file = this._downloadedMerchantFiles.gstFile;
          break;
        case "voterId":
          file = this._downloadedMerchantFiles.voterIdFile;
          break;
        case "otherId":
          file = this._downloadedMerchantFiles.otherIdFile;
          break;
        case "cancelledCheque":
          file = this._downloadedMerchantFiles.cancelledChequeFile;
          break;
      }
    }
    return file;
  }
  disableMerchantAadhaarPreview() {
    return !this.isMerchantAadhaarExists();
  }
  disableMerchantPanPreview() {
    return !this.isMerchantPanFileExists();
  }
  disableMerchantGstPreview() {
    return !this.isMerchantGstFileExists();
  }
  disableMerchantVoterIdPreview() {
    return !this.isMerchantVoterIdFileExists();
  }
  disableMerchantOtherIdPreview() {
    return !this.isMerchantOtherIdFileExists();
  }
  disableCancelledChequePreview() {
    return !this.isCompanyCancelledChequeExists();
  }
  disableMerchantPassportPreview() {
    return !this.isMerchantPanFileExists();
  }
  async previewCompanyImage(fileType: string) {
    let file: File;
    if (this.companyfiles) {
      switch (fileType) {
        case "pan":
          file = this.companyfiles.panFile;
          this.previewFileType = "PAN";
          break;
        case "gst":
          file = this.companyfiles.gstFile;
          this.previewFileType = "GST";
          break;
        case "addressproof":
          file = this.companyfiles.addressProofFile;
          this.previewFileType = "BUSINESS ADDRES PROOF";
          break;
        case "establishmentlicense":
          file = this.companyfiles.establishmentLicenseFile;
          this.previewFileType = "SHOP/ESTABLISHMENT LICENCE";
          break;
        case "partnershipdeed":
          file = this.companyfiles.partnershipDeedFile;
          this.previewFileType = "PARTNERSHIPDEED";
          break;
        case "certificateofincorporation":
          file = this.companyfiles.certificateOfIncorporationFile;
          this.previewFileType = "COC-Certificate of Incorporation";
          break;
        case "memorandum":
          file = this.companyfiles.memorandumFile;
          this.previewFileType = "MOA-Memorandum & Article of Association";
          break;
        case "brdirlist":
          file = this.companyfiles.brDirListFile;
          this.previewFileType = "BR or DIR List";
          break;
        case "trust_deed":
          file = this.companyfiles.trustDeedFile;
          this.previewFileType = "Trust Deed";
          break;
        case "electricitybill":
          file = this.companyfiles.electricityBillFile;
          this.previewFileType = "Electricity Bill";
          break;
        case "telephonebill":
          file = this.companyfiles.telephoneBillFile;
          this.previewFileType = "Telephone Bill";
          break;
        case "photograph":
          file = this.companyfiles.photographFile;
          this.previewFileType = "Photograph";
          break;
        case "cancelledcheque":
          file = this.companyfiles.cancelledChequeFile;
          this.previewFileType = "Cancelled Cheque";
          break;
        case "otherDoc1":
          file = this.companyfiles.otherDoc1File;
          this.previewFileType = this.dataCompanyProfile.otherDoc1
            ? this.dataCompanyProfile.otherDoc1
            : "Other Document 1";
          break;
        case "otherDoc2":
          file = this.companyfiles.otherDoc2File;
          this.previewFileType = this.dataCompanyProfile.otherDoc2
            ? this.dataCompanyProfile.otherDoc2
            : "Other Document 2";
          break;
        case "brDirList":
          file = this.companyfiles.brDirListFile;
          this.previewFileType = "BR or DIR list";
          break;
        case "trust_deed":
          file = this.companyfiles.trustDeedFile;
          this.previewFileType = "Trust Deed";
          break;
        case "electricitybill":
          file = this.companyfiles.electricityBillFile;
          this.previewFileType = "Electricity Bill";
          break;
        case "telephonebill":
          file = this.companyfiles.telephoneBillFile;
          this.previewFileType = "Telephone Bill";
          break;
      }
    }
    if (!file) {
      file = this.getFromDownloaedCompanyFiles(fileType);
    }
    var reader = new FileReader();
    if (file) {
      this.showImage(file);
    } else {
      await this.merchantservice
        .downloadCompanyfile(
          this.dataCompanyProfile.merchantCompanyProfileId,
          fileType
        )
        .then((data) => {
          this.saveDownloadedCompanyFile(
            new File([data], fileType + "File", { type: data.type }),
            fileType
          );
          this.showImage(data);
        });
    }
  }

  saveDownloadedCompanyFile(file: File, inputName) {
    if (!this._downloadedCompanyFiles) {
      this._downloadedCompanyFiles = new CompanyFiles();
    } else if (inputName == "pan") {
      this._downloadedCompanyFiles.panFile = file;
    } else if (inputName == "gst") {
      this._downloadedCompanyFiles.gstFile = file;
    } else if (inputName == "addresspr") {
      this._downloadedCompanyFiles.addressProofFile = file;
    } else if (inputName == "establishmentlicense") {
      this._downloadedCompanyFiles.establishmentLicenseFile = file;
    } else if (inputName == "partnershipdeed") {
      this._downloadedCompanyFiles.partnershipDeedFile = file;
    } else if (inputName == "certificateofincorporation") {
      this._downloadedCompanyFiles.certificateOfIncorporationFile = file;
    } else if (inputName == "memorandum") {
      this._downloadedCompanyFiles.memorandumFile = file;
    } else if (inputName == "brdirlist") {
      this._downloadedCompanyFiles.brDirListFile = file;
    } else if (inputName == "trust_deed") {
      this._downloadedCompanyFiles.trustDeedFile = file;
    } else if (inputName == "electricitybill") {
      this._downloadedCompanyFiles.electricityBillFile = file;
    } else if (inputName == "telephonebill") {
      this._downloadedCompanyFiles.telephoneBillFile = file;
    } else if (inputName == "photograph") {
      this._downloadedCompanyFiles.photographFile = file;
    } else if (inputName == "cancelledcheque") {
      this._downloadedCompanyFiles.cancelledChequeFile = file;
    } else if (inputName == "otherDoc1") {
      this._downloadedCompanyFiles.otherDoc1File = file;
    } else if (inputName == "otherDoc2") {
      this._downloadedCompanyFiles.otherDoc2File = file;
    }
  }

  getFromDownloaedCompanyFiles(fileType: string) {
    let file: File;
    if (this._downloadedCompanyFiles) {
      switch (fileType) {
        case "pan":
          file = this._downloadedCompanyFiles.panFile;
          break;
        case "gst":
          file = this._downloadedCompanyFiles.gstFile;
          break;
        case "addressproof":
          file = this._downloadedCompanyFiles.addressProofFile;
          break;
        case "establishmentlicense":
          file = this._downloadedCompanyFiles.establishmentLicenseFile;
          break;
        case "partnershipdeed":
          file = this._downloadedCompanyFiles.partnershipDeedFile;
          break;
        case "certificateofincorporation":
          file = this._downloadedCompanyFiles.certificateOfIncorporationFile;
          break;
        case "memorandum":
          file = this._downloadedCompanyFiles.memorandumFile;
          break;
        case "brdirlist":
          file = this._downloadedCompanyFiles.brDirListFile;
          break;
        case "trust_deed":
          file = this._downloadedCompanyFiles.trustDeedFile;
          break;
        case "electricitybill":
          file = this._downloadedCompanyFiles.electricityBillFile;
          break;
        case "telephonebill":
          file = this._downloadedCompanyFiles.telephoneBillFile;
          break;
        case "photograph":
          file = this._downloadedCompanyFiles.photographFile;
          break;
        case "cancelledcheque":
          file = this._downloadedCompanyFiles.cancelledChequeFile;
          break;
        case "otherDoc1":
          file = this._downloadedCompanyFiles.otherDoc1File;
          break;
        case "otherDoc2":
          file = this._downloadedCompanyFiles.otherDoc2File;
          break;
      }
    }
    return file;
  }
  disableCompanyPanPreview() {
    return !this.isCompanyPanProfileFileExists();
  }
  disableCompanyAddressProofPreview() {
    return !this.isCompanyAddressProofFileExists();
  }
  disableCompanyEstablishmentLicensePreview() {
    return !this.isCompanyEstablishmentLicenseFileExists();
  }
  disablePartnershipDeedPreview() {
    return !this.isPartnershipDeedFileExists();
  }
  disableCertificateOfIncorporationPreview() {
    return !this.isCertificateOfIncorporationFileExists();
  }
  disableMemorandumPreview() {
    return !this.isMemorandumFileExists();
  }
  disableOtherDoc1Preview() {
    return !this.isOtherDoc1FileExists();
  }
  disableOtherDoc2Preview() {
    return !this.isOtherDoc2FileExists();
  }
  disableCompanyGstPreview() {
    return !this.isCompanyGstFileExists();
  }

  disableCompanyBrDirPreview() {
    return !this.isCompanyBrDirFileExists();
  }
  disableCompanyTrustDeedPreview() {
    return !this.isCompanyTrustDeedFileExists();
  }
  disableCompanyElectricityBillPreview() {
    return !this.isCompanyElectricityBillExists();
  }
  disableCompanyTelephoneBillPreview() {
    return !this.isCompanyTelephoneBillFileExists();
  }
  disableCompanyPhotographPreview() {
    return !this.isCompanyPhotographFileExists();
  }
  onImgCloseClick() {
    $("#imgpreview").modal("hide");
  }

  async previewPartnerImage(fileType: string) {
    let file: File;
    if (this.partnerfiles) {
      switch (fileType) {
        case "pan":
          file = this.partnerfiles.panFile;
          this.previewFileType = "PAN";
          break;
        case "gst":
          file = this.partnerfiles.gstFile;
          this.previewFileType = "GST";
          break;
        case "aadhaar":
          file = this.partnerfiles.aadhaarFile;
          this.previewFileType = "AADHAAR";
          break;
        case "voterId":
          file = this.partnerfiles.voterIdFile;
          this.previewFileType = "Voter Id";
          break;
        case "otherId":
          file = this.partnerfiles.otherIdFile;
          this.previewFileType = "Other Id";
          break;
      }
    }
    var reader = new FileReader();
    if (file) {
      this.showImage(file);
    } else {
      await this.merchantservice
        .downloadPartnerfile(this.merchantPartner.merchantPartnerId, fileType)
        .then((data) => {
          this.showImage(data);
        });
    }
  }
  showImage(file) {
    var reader = new FileReader();
    if (file.type.match(/image\/*/) != null) {
      this.pdf = false;
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
        if (this.imgURL && this.imgURL != "") {
          $("#imgpreview").modal({ backdrop: false });
        }
      };
    } else if (file.type == "application/pdf") {
      this.pdf = true;
      let fileUrl = URL.createObjectURL(file);
      this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
      if (this.imgURL && this.imgURL != "") {
        $("#imgpreview").modal({ backdrop: false });
      }
    }
  }
  disablePartnerAadhaarPreview() {
    return !(
      (this.merchantPartner && this.merchantPartner.aadhaarFileName) ||
      (this.partnerfiles && this.partnerfiles.aadhaarFile)
    );
  }
  disablePartnerPanPreview() {
    return !(
      (this.merchantPartner && this.merchantPartner.panFileName) ||
      (this.partnerfiles && this.partnerfiles.panFile)
    );
  }
  disablePartnerGstPreview() {
    return !(
      (this.merchantPartner && this.merchantPartner.gstFileName) ||
      (this.partnerfiles && this.partnerfiles.gstFile)
    );
  }
  disablePartnerVoterIdPreview() {
    return !(
      (this.merchantPartner && this.merchantPartner.voterIdFileName) ||
      (this.partnerfiles && this.partnerfiles.voterIdFile)
    );
  }
  disablePartnerOtherIdPreview() {
    return !(
      (this.merchantPartner && this.merchantPartner.otherIdFileName) ||
      (this.partnerfiles && this.partnerfiles.otherIdFile)
    );
  }

  tabClick(tabObj: any) {
    if (tabObj.risktab && this.merchantRisk) {
      if (!this.merchantRisk.maxUsageDaily)
        this.merchantRisk.maxUsageDaily = 30000;
      if (!this.merchantRisk.maxUsageWeekly)
        this.merchantRisk.maxUsageWeekly = 200000;
      if (!this.merchantRisk.maxUsageMonthly)
        this.merchantRisk.maxUsageMonthly = 6000000;
      if (!this.merchantRisk.averageBillAmount)
        this.merchantRisk.averageBillAmount = 1000;
      if (!this.merchantRisk.expectedCardBusiness)
        this.merchantRisk.expectedCardBusiness = 1000000;
    }
  }

  disableHoldButton() {
    let retvalue: boolean = true;
    if (this.data && this.data.merchantStatus == "R") {
      retvalue = false;
    }
    return retvalue;
  }

  noAllowSaveSubmitOnStatus() {
    let noAllow: boolean = false;
    if (this.data.merchantStatus == "R" ||
      this.data.merchantStatus == "H" ||
      this.data.merchantStatus == "E") {
      noAllow = true;
    } else if (this.data.merchantStatus == "P" && !this._isTerminalsModified) {
      noAllow = true;
    }
    return noAllow;
  }

  allowInactiveActive() {
    let result: boolean = false;
    if (this.merchantTerminal.status == "C" || this.merchantTerminal.status == "D") {
      if (this.terminalStatusChangePriv) {
        result = true;
      }
    }
    return result;
  }

  inactiveActivateCaption() {
    let result: string = "";
    if (this.merchantTerminal.status == "C") {
      result = "Deactivate Terminal"
    } else if(this.merchantTerminal.status == "D") {
      result = "Reactivate Terminal" 
    }
    return result;
  }

  allowTerminalMARSUpdate() {
    if (
      this.merchantTerminal.status == "F" ||
      (this.data.merchantStatus == "P" && this.merchantTerminal.status == "A")
    ) {
      return true;
    } else {
      return false;
    }
  }
  inactiveTerminal() {
    if (this.merchantTerminal && this.merchantTerminal.merchantTerminalId) {
      this.merchantservice.inactiveMerchant(this.merchantTerminal).subscribe(
        (res) => {
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          if (res["success"]) {
            const id = res["result"];
            this.getDetail(id);
            objmsg.title = "Success";
            objmsg.message = "Terminal deactivated successfully!";
            objmsg.btnClass = "btn-success";
            this.tools.simpleMessage(objmsg);
          } else {
            switch (res["message"]) {
              case "INVALIDRECORD": {
                objmsg.title = "Alert";
                objmsg.message = "Invalid terminal selected to deactivate!";
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
              }
              default: {
                objmsg.title = "Error";
                objmsg.message = "Failed to deactivate terminal!";
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
              }
            }
          }
        },
        (err) => {
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Error";
          objmsg.message = "Failed to deactivate terminal!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    } else {
      let objmsg: SimpleMessage = new SimpleMessage();
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      objmsg.title = "Warning";
      objmsg.message = "Not valid terminal selected to inactive!";
      objmsg.btnClass = "btn-warning";
      this.tools.simpleMessage(objmsg);
    }
  }
  setBusinessEntityDescriptionEmpty() {
    if (this.dataCompanyProfile.businessEntityType != "99") {
      this.dataCompanyProfile.businessEntityDescription = "";
    }
  }
  onChangeAggregator() {
    this.supermerchant = { id: this.aggregator.id, name: this.aggregator.name };
    this.distributor = { id: this.aggregator.id, name: this.aggregator.name };
    this.getAggregatorChannelNetwork();
  }
  onChangeSuperMerchant() {
    this.distributor = {
      id: this.supermerchant.id,
      name: this.supermerchant.name,
    };
  }
  isIndividualbusinessType() {
    return this.dataCompanyProfile.businessEntityType == "01";
  }
  isExistsTransactionSet(item: ChannelNetworkCodeValueModel) {
    let found: boolean = false;
    if (this.transactionSet) {
      for (
        let i: number = 0;
        i < this.transactionSet.length && found == false;
        i++
      ) {
        if (item.code == this.transactionSet[i].code) {
          found = true;
        }
      }
    }
    return found;
  }
  isNetworkChannelCodeExists(itm: ChannelNetworkCodeValueModel) {
    let found: boolean = false;
    if (this.transactionSet) {
      for (let j = 0; j < this.transactionSet.length && !found; j++) {
        if (itm.code == this.transactionSet[j].code) {
          found = true;
        }
      }
    }
    return found;
  }
  clickRiskChannelNetworkSelectCheckUncheck(itm: AggregatorChannelNetwork) {
    const codevaluelist = itm.transactionSet;
    if (codevaluelist) {
      if (this.isAllCodeValueSelectedNetworkChannel(itm)) {
        for (let i = 0; i < codevaluelist.length; i++) {
          const codevalue = codevaluelist[i];
          if (this.transactionSet) {
            let notfound = true;
            for (let j = 0; j < this.transactionSet.length && notfound; j++) {
              if (codevalue.code == this.transactionSet[j].code) {
                this.transactionSet.splice(j, 1);
                notfound = false;
              }
            }
          }
        }
      } else {
        for (let i = 0; i < codevaluelist.length; i++) {
          const codevalue = codevaluelist[i];
          if (!this.isNetworkChannelCodeExists(codevalue)) {
            if (!this.transactionSet) {
              this.transactionSet = new Array<ChannelNetworkCodeValueModel>();
            }
            this.transactionSet.push(codevalue);
          }
        }
      }
    }
  }
  isAllCodeValueSelectedNetworkChannel(itm: AggregatorChannelNetwork) {
    let isAllSelected: Boolean = true;
    const codevaluelist = itm.transactionSet;

    if (codevaluelist) {
      for (let i = 0; i < codevaluelist.length && isAllSelected; i++) {
        let notfound: boolean = true;
        const codevalue = codevaluelist[i];
        if (this.transactionSet) {
          for (let j = 0; j < this.transactionSet.length && notfound; j++) {
            if (codevalue.code == this.transactionSet[j].code) {
              notfound = false;
            }
          }
          isAllSelected = !notfound;
        }
      }
    }
    return isAllSelected;
  }
  getRiskChannelNetworkCheckboxText(itm: AggregatorChannelNetwork) {
    let resultText: string = "Select All";
    if (this.isAllCodeValueSelectedNetworkChannel(itm)) {
      resultText = "Deselect All";
    }
    return resultText;
  }
  onClickTransactionSet(item: ChannelNetworkCodeValueModel) {
    if (this.transactionSet) {
      let found: boolean = false;
      for (
        let i: number = 0;
        i < this.transactionSet.length && found == false;
        i++
      ) {
        if (item.code == this.transactionSet[i].code) {
          this.transactionSet.splice(i, 1);
          found = true;
        }
      }
      if (!found) {
        if (!this.transactionSet) {
          this.transactionSet = new Array<ChannelNetworkCodeValueModel>();
        }
        this.transactionSet.push(item);
      }
    }
  }
  identifyTerminalsChanges(taction: string) {
    let arrterminals: Array<MerchantTerminalModel> = new Array<
      MerchantTerminalModel
    >();
    let _tmpterminal: Array<MerchantTerminalModel> = this._data
      ? this._data.terminal
      : undefined;

    if (!_tmpterminal || _tmpterminal.length < 1) {
      this.merchantTerminalList.forEach((terminal) => {
        let t = this.tools.copyFullObject(terminal);
        t.actionstatus = "A";
        t.merchant = { merchantId: this.data.merchantId };
        t.status = taction;
        arrterminals.push(t);
      });
    } else {
      this.merchantTerminalList.forEach((_terminal1) => {
        let _found: boolean = false;
        for (let j: number = 0; j < _tmpterminal.length && !_found; j++) {
          if (
            _terminal1.merchantTerminalId === _tmpterminal[j].merchantTerminalId
          ) {
            _found = true;
            const _terminal2 = _tmpterminal[j];
            if (
              (this.data.merchantStatus == "P" &&
                _terminal1.status == "S" &&
                taction == "R") ||
              this.isModifiedTerminalSave(_terminal1, _terminal2)
            ) {
              let t: MerchantTerminalModel = this.tools.copyFullObject(
                _terminal1
              );
              t.actionstatus = "M";
              t.merchant = { merchantId: this.data.merchantId };
              t.status = taction;
              arrterminals.push(t);
            }
          }
        }
        if (_found == false) {
          let t: MerchantTerminalModel = this.tools.copyFullObject(_terminal1);
          t.actionstatus = "A";
          t.merchant = { merchantId: this.data.merchantId };
          t.status = taction;
          arrterminals.push(t);
        }
      });
    }
    for (let i: number = 0; i < _tmpterminal.length; i++) {
      const t_old = _tmpterminal[i];
      let found: boolean = false;
      for (
        let j: number = 0;
        j < this.merchantTerminalList.length && found == false;
        j++
      ) {
        const t_new = this.merchantTerminalList[j];
        if (t_old.merchantTerminalId == t_new.merchantTerminalId) {
          found = true;
        }
      }
      if (found == false) {
        let t: MerchantTerminalModel = this.tools.copyFullObject(t_old);
        t.merchant = { merchantId: this.data.merchantId };
        arrterminals.push(t);
      }
    }
    if (arrterminals.length > 0) {
      return arrterminals;
    } else {
      return undefined;
    }
  }

  hideCompanyProfile() {
    return !(this.companytabactive == "Profile");
  }

  hideCompanyPartner() {
    return !(this.companytabactive == "Partners");
  }

  hideCompanyFiles() {
    return !(this.companytabactive == "Files");
  }

  showMerchantStatus() {
    let result = "";
    if (this.merchantStatusValues) {
      const merchantstatus = this.data.merchantStatus
        ? this.data.merchantStatus
        : "";
      const merchantMarsStatus = this.data.marsStatus
        ? this.data.marsStatus
        : "";
      const merchantcombinedStatus =
        merchantstatus +
        (merchantMarsStatus != "" ? "#" + merchantMarsStatus : "");

      let mstatus = this.merchantStatusValues.find(
        (itm) => itm.value == merchantcombinedStatus
      );
      if (!mstatus) {
        mstatus = this.merchantStatusValues.find(
          (itm) => itm.value == merchantstatus
        );
      }
      if (mstatus) {
        result = mstatus.text;
      }
    }
    return result;
  }

  showMARSResponseError() {
    if (this.data.merchantId) {
      this.merchantservice
        .getMerchantMARSResApi(this.data.merchantId)
        .subscribe(
          (res) => {
           // console.log (res)
            const axisErrData:any=res
            if (res["result"]) {
              const data: AxisErrorResponse = axisErrData.result.result.Data.MerchantOnboardResponseBody[0] || axisErrData.result.result.Data.MerchantOnboardResponseBody;
              let objMsg: MARSResponseErrorMsg = new MARSResponseErrorMsg();
              objMsg.forEntity = this.data.companyName;
              objMsg.errorMsg = data;
              this.tools.marsResponseErrorMessage(objMsg);
            } else {
              console.error(res["message"]);
            }
          },
          (err) => {
            console.error("Error to get MARS Reponse Errors");
          }
        );
    }
  }

  formatnumber(e, obj, field) {
    if (e.target.value == "") {
      obj[field] = undefined;
    } else {
      if (e.target.value != "" && isNaN(Number.parseFloat(e.target.value))) {
        this.tools.invalidNumberAlertMsg(e.target);
      } else {
        if (e.target.value) {
          /*let strValue: string = e.target.value.toString();
          let arr = strValue.split(".");
          if (arr.length > 1) {
            if (arr[1].length < 2) {
              e.target.value = parseFloat(e.target.value).toFixed(2);
            }
          } else {
            e.target.value = parseFloat(e.target.value).toFixed(2);
          }*/

          if (e.target.min) {
            if (+e.target.value < +e.target.min) {
              this.tools.rateAlertMessage(e.target);
            }
          }
          if (e.target.max) {
            if (+e.target.value > +e.target.max) {
              this.tools.rateAlertMessage(e.target);
            }
          }
        }
      }
      //obj[field] = Number.parseFloat(e.target.value);
    }
  }

  isTextSizeOverLimit(obj) {
    return obj.innerHTML.length > 25;
  }

  isCompanyBusinessEntityType() {
    return (
      this.dataCompanyProfile.businessEntityType == "04" ||
      this.dataCompanyProfile.businessEntityType == "05"
    );
  }
  noAllowTerminalEdit() {
    return (
      this.merchantTerminal.status == "P" || this.merchantTerminal.status == "R"
    );
  }

  allowTerminalSubmit() {
    let result: boolean = false;
    if (this.data.merchantStatus == "P") {
      if (this._isTerminalsModified) {
        result = true;
      } else {
        this.merchantTerminalList.forEach((obj) => {
          if (obj.status == "S") {
            result = true;
          }
        });
      }
    }
    return result;
  }

  showTerminalApproveReject() {
    if (
      this.data.merchantStatus == "P" &&
      this.merchantTerminal.status == "R" &&
      this.merchantTerminal.lastModifiedBy != this.objGlobal.Auth.user &&
      this.approvePriv
    ) {
      return true;
    } else {
      return false;
    }
  }

  onRejectTerminalClick() {
    this.approveRejectTerminal("J");
  }
  onApproveTerminalClick() {
    this.approveRejectTerminal("A");
  }

  approveRejectTerminal(action: string) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message =
      "Are you sure to " +
      (action == "R" ? "reject" : "approve") +
      " terminal?";
    ngmodalref.result.then(
      (result) => {
        if (result == "yes") {
          const terminal = {
            merchantTerminalId: this.merchantTerminal.merchantTerminalId,
            status: action,
          };
          this.merchantservice.approveRejectTerminal(terminal).subscribe(
            (data) => {
              if (data["success"]) {
                if (action == "R") {
                  this.merchantTerminal.status = "J";
                  let objmsg: SimpleMessage = new SimpleMessage();
                  let ngmodaloptions: NgbModalOptions = {};
                  ngmodaloptions.backdrop = "static";
                  ngmodaloptions.size = "sm";
                  objmsg.title = "Alert";
                  objmsg.message = "Terminal rejected successfully!";
                  objmsg.btnClass = "btn-success";
                  this.tools.simpleMessage(objmsg);
                } else if(data["result"] && data["result"].merchantStatus=="C"){
                  this.merchantTerminal.status = "C";
                  let objmsg: SimpleMessage = new SimpleMessage();
                  let ngmodaloptions: NgbModalOptions = {};
                  ngmodaloptions.backdrop = "static";
                  ngmodaloptions.size = "sm";
                  objmsg.title = "Alert";
                  objmsg.message = "Terminal activated successfully!";
                  objmsg.btnClass = "btn-success";
                  this.tools.simpleMessage(objmsg);                  
                } else {
                  this.terminalMARUpdate("A");
                }
              } else {
                let objmsg: SimpleMessage = new SimpleMessage();
                let ngmodaloptions: NgbModalOptions = {};
                ngmodaloptions.backdrop = "static";
                ngmodaloptions.size = "sm";
                objmsg.title = "Alert";
                objmsg.btnClass = "btn-error";
                switch (data["message"]) {
                  case "INVALIDRECORD":
                    objmsg.message =
                      "Not a valid terminal to " +
                      (action == "R" ? "reject" : "approve") +
                      "!";
                  default:
                    objmsg.message =
                      "Failed to " +
                      (action == "R" ? "reject" : "approve") +
                      " terminal!";
                    break;
                }
                this.tools.simpleMessage(objmsg);
              }
            },
            (err) => {
              let objmsg: SimpleMessage = new SimpleMessage();
              let ngmodaloptions: NgbModalOptions = {};
              ngmodaloptions.backdrop = "static";
              ngmodaloptions.size = "sm";
              objmsg.title = "Alert";
              objmsg.message = "Error!";
              objmsg.btnClass = "btn-error";
              this.tools.simpleMessage(objmsg);
            }
          );
        }
      },
      (reason) => {}
    );
  }

  onSalesPersonSelect($event) {
    console.log(this.selsalesperson);
  }

  viewTerminalStatusRequest() {
    if (this.merchantTerminal && this.merchantTerminal.merchantTerminalId) {
      this.merchantservice.getTerminalStatusRequests(this.merchantTerminal.merchantTerminalId)
        .subscribe(res => {
          if (res["success"]) {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.windowClass = "xlModal";
            const modalRef = this.ngbModalService.open(
              TerminalStatusRequestMessageComponent,
              ngmodaloptions
            );
            modalRef.componentInstance.requestStatusList = res["result"];
            modalRef.componentInstance.terminalStatus = this.merchantTerminal.status;
            modalRef.componentInstance.terminalId = this.merchantTerminal.merchantTerminalId;
            modalRef.componentInstance.terminalActivationStatus = this.merchantTerminal.activationStatus;
            modalRef.componentInstance.tid = this.merchantTerminal.tid;
            modalRef.componentInstance.deviceSerialNumber = this.merchantTerminalDevice.deviceSerialNumber;
          } else {
            console.error("Failed to get terminal status requests!")
          }
        }, err => {
            console.error("Error to get terminal status requests!")
      })
    }
  }

  reactivateDeactivateTerminal() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.windowClass = "lgModal";

    const modalRef = this.ngbModalService.open(
      TerminalDeactivationMessageComponent,
      ngmodaloptions
    );
    modalRef.componentInstance.terminalType = this.merchantTerminal.terminalType;
    modalRef.componentInstance.terminalStatus = this.merchantTerminal.status;
    modalRef.result.then(
      (result) => {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        if (
          this.merchantTerminal.tid == result.tid &&
          (this.merchantTerminal.terminalType === "UPI" && (!result.deviceSerialNumber) || this.merchantTerminalDevice.deviceSerialNumber ==
            result.deviceSerialNumber)
        ) {
          let obj = {};
          obj["terminalId"] = this.merchantTerminal.merchantTerminalId;
          obj["tid"] = result.tid;
          if (result.deviceSerialNumber) {
            obj["deviceSerialNumber"] = result.deviceSerialNumber;
          }
          if (this.merchantTerminal.status == "D") { 
            obj["status"] = "A";
          } else {
            obj["status"] = "D";
          }
          obj["reason"] = result.deactivationReason;
          this.merchantservice.saveTerminalStatusChangeRequest(obj).subscribe(
            (res) => {
              if (res["success"]) {
                objmsg.title = "Success";
                if (res["message"] == "RequestInQueue") {
                  objmsg.message = "Request saved successfully!";
                } else {
                  objmsg.message = "Terminal deactivated successfully!";
                  this.merchantTerminal.status = "D";
                }
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
              } else {
                objmsg.title = "Failed";
                objmsg.btnClass = "btn-error";
                objmsg.message = "Error response from server!";
                switch (res["message"]) {
                  case "TERMINALID_MISSING":
                    objmsg.message = "Terminal is missing in request!";
                    break;
                  case "DEVICESERIALNUMBER_MISSING":
                    objmsg.message =
                      "Device serial number is missing in request!";
                    break;
                  case "TID_MISSING":
                    objmsg.message = "TID is missing in request!";
                    break;
                  case "STATUS_MISSING":
                    objmsg.message = "Requested status is missing in request!";
                    break;
                  case "REASON_MISSING":
                    objmsg.message =
                      "Deactivation reason is missing in request!";
                    break;
                  case "INVALID_REQUESTTYPE":
                    objmsg.message = "Request status is invalid!";
                    break;
                  case "DEVICESERIALNUMBER_MISMATCH":
                    objmsg.message = "Given device serial number mismatch!";
                    break;
                  case "TID_MISMATCH":
                    objmsg.message = "Give tid mismatch!";
                    break;
                  case "SAME_STATUS_EXISTING":
                    objmsg.message =
                      "Terminal is already in deactivate statue!";
                    break;
                  case "TERMINAL_OR_DEVICE_NOT_FOUND":
                    objmsg.message = "Terminal or device not found!";
                    break;
                  case "REQUEST_IN_Q":
                    objmsg.message =
                      "A request for terminal is already in queue!";
                    break;

                  default:
                }
                this.tools.simpleMessage(objmsg);
              }
            },
            (err) => {
              objmsg.title = "Error";
              objmsg.message = "Error response from server!";
              objmsg.btnClass = "btn-error";
              this.tools.simpleMessage(objmsg);
            }
          );
        } else {
          objmsg.title = "Alert";
          objmsg.message = "Terminal detail mismatch!";
          objmsg.btnClass = "btn-warning";
          this.tools.simpleMessage(objmsg);
        }
        console.log(result);
      },
      (reason) => {}
    );
  }

  isUPITerminalTypeExists() {
    let result = false;
    if (this.merchantTerminal.terminalType) {
      const selTerminalSepList = this.merchantTerminal.terminalType.split("+");
      
      if (selTerminalSepList.find(itm => itm === "UPI")) {
        result = true;
      }
    }
    return result;
  }

  isPOSTerminalTypeExists() {
    const posTypes: Array<string> = ["POS", "MPOS"];
    let result = false;
    if (this.merchantTerminal.terminalType) {
      const selTerminalSepList = this.merchantTerminal.terminalType.split("+");
      if (selTerminalSepList.find(itm => posTypes.includes(itm))) {
        result = true;
      }
    }
    return result;
  }

}
