import { Component, OnInit, ViewChild } from "@angular/core";
import {
  GridtableComponent,
  gridColumns,
  GridPage,
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { TopbarText } from "src/app/framework/components/topbar/topbar.component";
import { SortClass, SimpleMessage } from "src/app/framework/modules/util.class";
import { Tools } from "src/app/framework/modules/tools";
import { global } from "src/app/framework/modules/global";
import { ExporttocsvService } from "src/app/framework/services/exporttocsv.service";
import { AuthService } from "src/app/framework/services/auth.service";
import { ReportService } from "src/app/services/report.service";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-terminalwise-settlement-report",
  templateUrl: "./terminalwise-settlement-report.component.html",
  styleUrls: ["./terminalwise-settlement-report.component.css"],
})
export class TerminalwiseSettlementReportComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-5" },
    {
      columnHeader: "TRANSACTION DATETIME",
      columnFieldName: "txnDate",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS,
    },
    {
      columnHeader: "MID",
      columnFieldName: "mid",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "TERMINAL Id",
      columnFieldName: "tid",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "CARD NUMBER ",
      columnFieldName: "cardNumber",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "RRN",
      columnFieldName: "rrn",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "NET AMOUNT",
      columnFieldName: "netAmount",
      columnClasses: "c-col-pc-12 pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "SETTLEMENT DATETIME",
      columnFieldName: "settleDate",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS,
    },
  ];
  sortFields = [
    { name: "txnDate", display: "Transaction DateTime" },
    { name: "mid", display: "MID" },
    { name: "tid", display: "Terminal Id" },
    { name: "cardNumber", display: "Card Number" },
    { name: "rrn", display: "RRN" },
    { name: "netAmount", display: "Net Amount" },
    { name: "settleDate", display: "Settlement DateTime" },
  ];

  topbartext: TopbarText[] = [
    {
      key: "terminalwisesettlementreport",
      displayText: "SETTLEMENT REPORT - MERCHANT TERMINAL WISE - VIEW",
      class: "col-md-8",
      anchorClass: "h6",
    },
  ];
  searchField = {
    dateFrom: undefined,
    dateTill: undefined,
    tid: undefined,
  };
  datalist: Array<any>;
  page: GridPage;
  _menuId: string;
  //isDetailScreen : boolean=false;

  sort: SortClass;
  constructor(
    public tools: Tools,
    private objGlobal: global,
    private csvservice: ExporttocsvService,
    private auth: AuthService,
    private reportService: ReportService
  ) {
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    this._menuId = this.tools.getMenuName("main/terminalwisesettlementreport");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "txnDate";
    //let d = moment();
    //d = d.add(-1, "month");
    this.searchField.dateFrom = new Date();
    this.searchField.dateTill = new Date();
    this.searchField.tid = undefined;
  }

  ngOnInit() {}

  getReportData(opt) {
    let searchField = {};
    searchField["tid"] = this.searchField.tid;
    if (this.searchField.dateFrom || this.searchField.dateTill) {
      searchField["dateRange"] = new Array<Date>();
      searchField["dateRange"].push(this.searchField.dateFrom);
      searchField["dateRange"].push(this.searchField.dateTill);
    }
    this.reportService
      .getTerminalwiseSettlementReport(this.page, searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message =
              "Failed to terminal wise settlement report report!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to terminal wise settlement report report!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  exportDeactiveTerminalSummaryReportForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    let searchField = {};
    //if (!this.allowAggregatorSelection()) {
    //  this.searchField.aggregatorId = this.auth.getUserDistributorId();
    //}
    //searchField["aggregatorId"] = this.searchField.aggregatorId;
    if (this.searchField.dateFrom || this.searchField.dateTill) {
      searchField["dateRange"] = new Array<Date>();
      searchField["dateRange"].push(this.searchField.dateFrom);
      searchField["dateRange"].push(this.searchField.dateTill);
    }
    searchField["tid"] = this.searchField.tid;

    this.reportService
      .getTerminalwiseSettlementReport(page, searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<any>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "terminalwisesettlement"
            );
          } else {
            alert("Failed to terminal wise settlement report report!");
          }
        },
        (error) => {
          alert("Failed to terminal wise settlement report report!");
        }
      );
  }
  onExcelClick() {
    this.exportDeactiveTerminalSummaryReportForExcel();
  }

  allowAggregatorSelection() {
    return this.auth.getUserDistributorId() ? false : true;
  }

  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }
}
