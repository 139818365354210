import { Injectable } from '@angular/core';
//import { Http, Headers } from '@angular/http';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {AppSettings} from '../config/AppSettings';
import {menuModel} from '../models/menu.model';
import {Tools} from '../modules/tools';
import {Router} from '@angular/router';
import { promise } from 'protractor';
@Injectable()
export class MenuService {
  public menusList : Array<menuModel>;
  
  constructor(private http:HttpClient, private appsetting : AppSettings,private tools : Tools,
    private router : Router) { 
    
  }
  loadMenus() {    
    let menuPromise : Promise<any>;
      let header = new HttpHeaders({'Content-Type' : 'application/json'});
    return new Promise((resolve) =>{
      this.http.get(this.appsetting.get("server-url") + '/api/menus',{headers : header})
    .map(data => this.tools.sortObject(data["result"],"order")).toPromise().then((data) => {
      this.menusList=data; 
      resolve();
    }
    )    
  });  
}

  getChildMenus(parent: string) {
    var parentlist = parent.split("=>");
    var childMenu =undefined;
    if(this.menusList) {
      childMenu =this.menusList;
      parentlist.forEach(menu => {          
          childMenu = childMenu["menu"];
          if(!childMenu) {
            return undefined;
          }
      });      
    }
    return childMenu;
  }
  getParentMenuObject() {
    
    //  this.loadMenus();
    
    return this.menusList;
  }
}
