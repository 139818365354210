import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  HostListener,
  OnDestroy
} from "@angular/core";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../framework/util/components/gridtable/gridtable.component";
import { AuthService } from "../../framework/services/auth.service";
import { global } from "../../framework/modules/global";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Tools } from "../../framework/modules/tools";
import { DeviceStockModel } from "../../models/device.model";
import { ProductService } from "../../services/product.service";
import { SupplierService } from "../../services/supplier.service";
import { DeviceStockService } from "../../services/device.service";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "../../framework/util/components/modalcomponent/modal-content";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { ProductModel } from "src/app/models/product.model";

import { SupplierModel } from "src/app/models/supplier.model";
import { isDate } from "rxjs/internal/util/isDate";
import { AngularWaitBarrier } from "blocking-proxy/built/lib/angular_wait_barrier";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
import { AggregatorDistributorModel } from "src/app/models/aggregator.distributor.model";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
declare var $: any;
class CsvFieldHeaders {
  name: string;
  index?: number;
}
class ValidationErr {
  line: number;
  error: string;
}
class SearchField {
  searchText: string;
  invoiceDate: Date[];
  entryDate: Date[];
  deviceCondition: String;
  constructor() {
    this.searchText = "";
    this.deviceCondition = undefined;
  }
}

@Component({
  selector: "app-devicestock",
  templateUrl: "./devicestock.component.html",
  styleUrls: ["./devicestock.component.css"]
})
export class DevicestockComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "",
      columnFieldName: "deviceStockId",
      columnClasses: "c-col-pc-0"
    },
    {
      columnHeader: "Model Name",
      columnFieldName: "product.modelName",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Serial No.",
      columnFieldName: "deviceSerialNumber",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Product Name",
      columnFieldName: "product.productName",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Invoice No",
      columnFieldName: "invoiceNumber",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Invoice Date",
      columnFieldName: "invoiceDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Entry date",
      columnFieldName: "entryDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Supplier",
      columnFieldName: "supplier.supplierName",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Cond",
      columnFieldName: "deviceCondition",
      columnClasses: "c-col-pc-5",
      callbackFunction: this.getDeviceConditionText
    }
  ];

  validationErrorCols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "Line Number",
      columnFieldName: "line",
      columnClasses: "c-col-pc-15"
    },
    { columnHeader: "Error", columnFieldName: "error" }
  ];

  csvDataCols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "Model Name",
      columnFieldName: "product.modelName",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Serial No.",
      columnFieldName: "deviceSerialNumber",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Invoice No",
      columnFieldName: "invoiceNumber",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Invoice Date",
      columnFieldName: "invoiceDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Supplier",
      columnFieldName: "supplier.supplierName",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Condition",
      columnFieldName: "deviceCondition",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.getDeviceConditionText
    }
  ];
  sortFields = [
    { name: "deviceSerialNumber", display: "Serial Number" },
    { name: "product.modelName", display: "Model Name" },
    { name: "product.productName", display: "Product Name" },
    { name: "invoiceNumber", display: "Invoice Number" },
    { name: "invoiceDate", display: "Invoice Date" },
    { name: "entryDate", display: "Entry Date" },
    { name: "supplier.supplierName", display: "Supplier Name" },
    { name: "deviceCondition", display: "Condition" }
  ];

  topbartext: TopbarText[] = [
    { key: "devicestock", displayText: "Device Stock List" }
  ];
  searchField: SearchField;
  devicestocklist: Array<DeviceStockModel>;
  page: GridPage;
  _menuId: string;
  _action: string = "";
  devicestock: DeviceStockModel;
  _devicestock: any;
  isDetailScreen: boolean = false;
  _isChange: boolean = false;
  sort: SortClass;
  selectedProduct: ProductModel;
  entryMode: string = "E";
  csvFileToUpload: File;
  csvFileHeader: string[] = [
    "ModelName",
    "DeviceSerialNumber",
    "InvoiceNumber",
    "InvoiceDate",
    "SupplierName",
    "Condition"
  ];
  isCsvValidationError: boolean = false;
  isCsvValidData: boolean = false;

  csvData: Array<DeviceStockModel>;
  csvValidationErrors: ValidationErr[];
  aggregatorData: AggregatorDistributorModel;
  _aggregatorData: AggregatorDistributorModel;
  constructor(
    public tools: Tools,
    private ngbModalService: NgbModal,
    private objGlobal: global,
    private auth: AuthService,
    public productservice: ProductService,
    public supplierservice: SupplierService,
    private objdevicestockservice: DeviceStockService,
    private csvservice: ExporttocsvService,
    private aggregatorDistributorService: AggregatorDistributorService
  ) {
    this.objGlobal.currentScope = this;
    this.entryMode = "E";
    this.csvFileToUpload = undefined;
    this.isCsvValidationError = false;
    this.isCsvValidData = false;
    this.searchField = new SearchField();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/devicestock");
    this.objGlobal.setActiveMenu(this._menuId);
    this.devicestock = new DeviceStockModel();
    this.aggregatorData = new AggregatorDistributorModel();
    this._aggregatorData = new AggregatorDistributorModel();
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "deviceSerialNumber";
    productservice.loadProductNameWithId();
    supplierservice.loadSupplierNameWithId();
    //this.getDeviceStockList();
    this.selectedProduct = new ProductModel();
    this.aggregatorDistributorService.loadAggregatorDistrbutorIdNameType();
  }

  ngOnInit() {}
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    //   this.tools.jquerydatepicker();
  }
  ngOnDestroy() {
    /*if (this.isDetailScreen) {
      this.onBackbuttonClick();
    }*/
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  getdevicestocks(event: any) {
    this.getDeviceStockList();
  }
  onAddNew() {
    this._action = "new";
    this.isDetailScreen = true;
    this.topbartext = [{ key: "devicestock", displayText: "Add Device Stock" }];
    this.entryMode = "E";
    this.csvFileToUpload = undefined;
    this.csvData = undefined;
    this.isCsvValidationError = false;
    this.isCsvValidData = false;
    this.csvValidationErrors = undefined;
    this.devicestock = new DeviceStockModel();
    this.aggregatorData = new AggregatorDistributorModel();
    this.devicestock.entryDate = new Date();
    this.selectedProduct = new ProductModel();
    if (this.allowAggregatorSelection()) {
      this.aggregatorData.id = 0;
    }
    this._aggregatorData = this.tools.copyFullObject(this.aggregatorData);
    //this._devicestock=Object.assign({},this.devicestock);
    this._devicestock = this.tools.copyFullObject(this.devicestock);
    //$("#detailscreen")[0].focus();
  }
  getAction() {
    return this._action;
  }
  bulkdeviceupload() {
    this.objdevicestockservice.saveBulkDeviceStock(this.csvData).subscribe(
      data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        if (data["success"]) {
          objmsg.title = "Information";
          objmsg.message = "Device Stock uploaded successfully!";
          objmsg.btnClass = "btn-info";
          this._isChange = true;
          this.csvData = undefined;
          this.csvValidationErrors = undefined;
          this.isCsvValidData = false;
          this.isCsvValidationError = false;
          this.csvFileToUpload = undefined;
          //let inputfile=$("#csvToUpload");
          //if(inputfile && inputfile[0]) {
          //  inputfile[0].value=undefined;
          //};
        } else {
          objmsg.title = "Error";
          objmsg.message = "Failed to save Device stock!";
          objmsg.btnClass = "btn-error";
        }
        this.tools.simpleMessage(objmsg);
      },
      error => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to save bulk device stock!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }
  validateData() {
    let result: boolean = true;
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    objmsg.title = "Warning";
    objmsg.btnClass = "btn-warning";
    if (
      this.devicestock.entryDate &&
      new Date(this.tools.formatDateToDDMMMYY(this.devicestock.entryDate)) >
        new Date()
    ) {
      objmsg.message = "Entry date should not be greater than current date!";
      this.tools.simpleMessage(objmsg);
      result = false;
    } else if (
      this.devicestock.invoiceDate &&
      new Date(this.tools.formatDateToDDMMMYY(this.devicestock.invoiceDate)) >
        new Date()
    ) {
      objmsg.message = "Invoice date should not be greater than current date!";
      this.tools.simpleMessage(objmsg);
      result = false;
    }
    return result;
  }
  saveDeviceStock() {
    if (this.entryMode == "U") {
      this.bulkdeviceupload();
    } else if (this.validateData()) {
      if (this.aggregatorData && this.aggregatorData.id != undefined) {
        this.devicestock.aggregator = { id: this.aggregatorData.id };
      }
      this.objdevicestockservice.saveDeviceStock(this.devicestock).subscribe(
        data => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          if (data["success"]) {
            objmsg.title = "Information";
            objmsg.message = "Device Stock saved successfully!";
            objmsg.btnClass = "btn-info";
            this.devicestock.deviceStockId = data["result"];
            this._devicestock = this.tools.copyFullObject(this.devicestock);
            this._isChange = true;
          } else if (data["message"] == "InputDataNotProvided") {
            objmsg.title = "Error";
            objmsg.message = "Device Stock input data not available!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "SerialNumberNotInInputData") {
            objmsg.title = "Error";
            objmsg.message = "Device serial number not provided available!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "SerialNumberAlreadyExist") {
            objmsg.title = "Error";
            objmsg.message = "Device serial number already exists in stock!";
            objmsg.btnClass = "btn-warning";
          } else {
            objmsg.title = "Error";
            objmsg.message = "Failed to save Device stock!";
            objmsg.btnClass = "btn-warning";
          }
          this.tools.simpleMessage(objmsg);
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to save device stock!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }

  isModified() {
    if (!(this.devicestock && this._devicestock)) {
      return false;
    }
    if (this.devicestock.deviceCondition != this._devicestock.deviceCondition) {
      return true;
    } else if (
      this.devicestock.deviceSerialNumber !=
      this._devicestock.deviceSerialNumber
    ) {
      return true;
    } else if (this.devicestock.allowMultipleTerminals != this._devicestock.allowMultipleTerminals) { 
      return true;
    }else if (
      this.devicestock.entryDate &&
      this._devicestock.entryDate &&
      this.devicestock.entryDate.toString() !=
        this._devicestock.entryDate.toString()
    ) {
      return true;
    } else if (this.devicestock.invoiceDate && !this._devicestock.invoiceDate) {
      return true;
    } else if (!this.devicestock.invoiceDate && this._devicestock.invoiceDate) {
      return true;
    } else if (
      this.devicestock.invoiceDate &&
      this._devicestock.invoiceDate &&
      this.devicestock.invoiceDate.toString() !=
        this._devicestock.invoiceDate.toString()
    ) {
      return true;
    } else if (
      this.devicestock.invoiceNumber != this._devicestock.invoiceNumber
    ) {
      return true;
    } else if (
      this.devicestock.product.productId != this._devicestock.product.productId
    ) {
      return true;
    } else if (
      this.devicestock.supplier.supplierId !=
      this._devicestock.supplier.supplierId
    ) {
      return true;
    } else if (this.isModifiedAggregator()) {
      return true;
    }

    return false;
  }

  isModifiedAggregator() {
    let bresult = false;
    const oldAggregatorId =
      this._aggregatorData && this._aggregatorData.id != undefined
        ? this._aggregatorData.id
        : undefined;
    const newAggregatorId =
      this.aggregatorData && this.aggregatorData.id != undefined
        ? this.aggregatorData.id
        : undefined;
    if (oldAggregatorId != newAggregatorId) {
      bresult = true;
    }
    return bresult;
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (this.entryMode == "U") {
      if (this.isCsvValidData) {
        isDisableSave = false;
      }
    } else if (this.entryMode == "E") {
      if (f.valid) {
        if (this.isModified()) {
          if (this.isAllowAddorEdit()) {
            isDisableSave = false;
          }
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }
  disableCancel(f: any) {
    return !this.isModified();
  }
  getDeviceStockList() {
    this.objdevicestockservice
      .getDeviceStockList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.devicestocklist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get device stock list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get device stock list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  selectDeviceStock() {}
  deleteDeviceStock() {}
  getDeviceConditionText(codeValue: String) {
    let value: String = "";
    switch (codeValue) {
      case "O": {
        value = "Ok";
        break;
      }
      case "F": {
        value = "Faulty";
        break;
      }
    }
    return value;
  }

  onDeviceStockRowDblClick(devicestocklistval: any) {
    this._action = "update";
    this.isDetailScreen = true;
    this.topbartext = [{ key: "devicestock", displayText: "Device Stock" }];
    this.entryMode = "E";
    this.csvFileToUpload = undefined;
    this.csvData = undefined;
    this.isCsvValidationError = false;
    this.isCsvValidData = false;
    this.csvValidationErrors = undefined;
    //this.router.navigate(['user']);
    this.getDeviceStockDetail(devicestocklistval.deviceStockId);
  }

  getDeviceStockDetail(_id: number) {
    if (_id) {
      this.objdevicestockservice.getDeviceStockDetail(_id).subscribe(
        data => {
          if (data["success"]) {
            this.devicestock = data["result"];
            if (this.devicestock.aggregator) {
              this.aggregatorData = this.devicestock.aggregator;
            } else {
              this.aggregatorData = new AggregatorDistributorModel();
            }
            this._aggregatorData = this.tools.copyFullObject(
              this.aggregatorData
            );
            this.convertDateField(this.devicestock);
            this._devicestock = this.tools.copyFullObject(this.devicestock);
            this.selectProduct(this.devicestock.product.productId);
          } else {
            alert("Failed to get device stock list!");
            this.selectedProduct = new ProductModel();
          }
        },
        error => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get device stock!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
          this.selectedProduct = new ProductModel();
        }
      );
    }
  }
  onCancel() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.csvData = undefined;
            this.csvValidationErrors = undefined;
            //    this.isUserDetailScreen=false;
          }
        },
        reason => {}
      );
    } else {
    }
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Device stock has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
            this.topbartext = [
              { key: "devicestock", displayText: "Device Stock List" }
            ];
            if (this._isChange == true) {
              this._isChange = false;
              this.csvData = undefined;
              this.csvValidationErrors = undefined;
              this.getDeviceStockList();
            }
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
      this.topbartext = [
        { key: "devicestock", displayText: "Device Stock List" }
      ];
      if (this._isChange == true) {
        this._isChange = false;
        this.csvData = undefined;
        this.csvValidationErrors = undefined;
        this.getDeviceStockList();
      }
    }
  }
  convertDateField(devStock: DeviceStockModel) {
    if (devStock.invoiceDate) {
      devStock.invoiceDate = new Date(devStock.invoiceDate);
    }
    if (devStock.entryDate) {
      devStock.entryDate = new Date(devStock.entryDate);
    }
  }

  selectProduct(productId: number) {
    let prod: ProductModel;
    if (productId) {
      prod = this.productservice.productList.find(
        product => product.productId == productId
      );
    }
    if (prod) {
      this.selectedProduct = prod;
    } else {
      this.selectedProduct = new ProductModel();
    }
  }

  csvFileInput(file: File) {
    this.csvFileToUpload = file;
    this.csvData = undefined;
    this.csvValidationErrors = undefined;
    this.isCsvValidData = false;
    this.isCsvValidationError = false;
  }
  disablePreview() {
    return !this.csvFileToUpload;
  }
  previewUploadData() {
    this.isCsvValidationError = false;
    this.isCsvValidData = false;
    if (this.csvFileToUpload) {
      var reader = new FileReader();
      reader.readAsText(this.csvFileToUpload);
      const fileName = this.csvFileToUpload.name;
      this.csvData = new Array<DeviceStockModel>();
      this.csvValidationErrors = undefined;
      reader.onload = data => {
        let csvFileData = reader.result;
        let csvFileRecordsArray = (csvFileData as string).split(/\r\n|\n/);
        let cnt: number = 0;
        while (
          csvFileRecordsArray[cnt] != undefined &&
          csvFileRecordsArray[cnt].trim() == ""
        ) {
          cnt = cnt + 1;
        }
        if (csvFileRecordsArray[cnt]) {
          const headers = csvFileRecordsArray[cnt].match(/(“[^”]*”)|[^,]+/g);
          const fieldHeaderIndex = this.csvHeaderIndex(headers);
          //console.log(fieldHeaderIndex);
          if (!this.checkIfHeaderMissing(fieldHeaderIndex)) {
            cnt = cnt + 1;
            for (let i = cnt; i < csvFileRecordsArray.length; i++) {
              let rowdata = csvFileRecordsArray[i].match(/(“[^”]*”)|[^,]+/g);
              if (rowdata != undefined && rowdata.length > 0 && rowdata[0]) {
                let deviceStock: DeviceStockModel = new DeviceStockModel();
                const linenumber = i + 1;
                const validationerror = this.validateAndSetCsvRowData(
                  fieldHeaderIndex,
                  rowdata,
                  deviceStock
                );
                if (!validationerror) {
                  this.csvData.push(deviceStock);
                } else {
                  if (!this.csvValidationErrors) {
                    this.csvValidationErrors = new Array<ValidationErr>();
                  }
                  this.csvValidationErrors.push({
                    line: linenumber,
                    error: validationerror
                  });
                }
              }
              //this.csvRecords.push(rowdata);
            }
          }
        }
        if (
          this.csvValidationErrors &&
          this.csvValidationErrors.length > 0 &&
          this.csvValidationErrors[0]
        ) {
          this.isCsvValidationError = true;
          this.isCsvValidData = false;
        } else {
          this.isCsvValidationError = false;
          this.isCsvValidData = true;
        }
      };
      const _this = this;
      reader.onerror = function() {
        alert("Unable to read " + fileName);
        _this.isCsvValidationError = false;
        _this.isCsvValidData = false;
      };
    } else {
      alert("Please select csv file to upload!");
      //this.csvRecords = [];
    }
  }

  csvHeaderIndex(fileHeader: string[]) {
    let fieldswithindex: Array<CsvFieldHeaders> = new Array<CsvFieldHeaders>();
    for (let i = 0; i < this.csvFileHeader.length; i++) {
      fieldswithindex.push({ name: this.csvFileHeader[i] });
      let found = false;
      for (let j = 0; j < fileHeader.length && !found; j++) {
        if (this.csvFileHeader[i] == fileHeader[j]) {
          found = true;
          fieldswithindex[i].index = j;
        }
      }
    }
    return fieldswithindex;
  }

  checkIfHeaderMissing(fileheaderelements: Array<CsvFieldHeaders>) {
    const missingindexelements = fileheaderelements.filter(
      obj => obj.index == undefined
    );
    let missingfields: string[] = new Array<string>();
    if (missingindexelements.length <= 0) {
      return false;
    }
    for (let i = 0; i < missingindexelements.length; i++) {
      missingfields.push(missingindexelements[i].name);
    }
    const fields = missingfields.join(",");
    if (!this.csvValidationErrors) {
      this.csvValidationErrors = Array<ValidationErr>();
    }
    this.csvValidationErrors.push({
      line: 1,
      error: "Missing header field(s) - " + fields
    });
  }

  validateAndSetCsvRowData(
    fieldsArr: CsvFieldHeaders[],
    csvRecord: string[],
    deviceStock: DeviceStockModel
  ) {
    let tempvalidationerror: string[] = new Array<string>();
    for (let i = 0; i < fieldsArr.length; i++) {
      let fieldVal = csvRecord[fieldsArr[i].index];
      if (fieldsArr[i].name == "ModelName") {
        const selProduct = this.productservice.productList.filter(
          obj => obj.modelName == fieldVal
        );
        if (selProduct && selProduct[0]) {
          if (!deviceStock.product) {
            deviceStock.product = new ProductModel();
          }
          deviceStock.product.productId = selProduct[0].productId;
          deviceStock.product.productName = selProduct[0].productName;
          deviceStock.product.modelName = selProduct[0].modelName;
        } else {
          tempvalidationerror.push("Invalid Model Name");
        }
      } else if (fieldsArr[i].name == "DeviceSerialNumber") {
        const tfilternedSrNumber = this.csvData.filter(
          obj => obj.deviceSerialNumber == fieldVal
        );
        let srNumberError = false;
        if (fieldVal == "") {
          tempvalidationerror.push("Device serial number should not be blank!");
          srNumberError = true;
        }
        if (tfilternedSrNumber && tfilternedSrNumber[0]) {
          tempvalidationerror.push("Duplicate device serial number!");
          srNumberError = true;
        }
        if (fieldVal.length > 30) {
          tempvalidationerror.push(
            "Length of device serial number is more than 30"
          );
          srNumberError = true;
        }
        if (!srNumberError) {
          deviceStock.deviceSerialNumber = fieldVal;
        }
      } else if (fieldsArr[i].name == "InvoiceNumber") {
        let invoiceNumberError = false;
        if (fieldVal == "") {
          tempvalidationerror.push("Invoice number should not be blank!");
          invoiceNumberError = true;
        }
        if (fieldVal.length > 30) {
          tempvalidationerror.push("Length of invoice number is more than 30");
          invoiceNumberError = true;
        }
        if (!invoiceNumberError) {
          deviceStock.invoiceNumber = fieldVal;
        }
      } else if (fieldsArr[i].name == "InvoiceDate") {
        if (isNaN(Date.parse(fieldVal))) {
          tempvalidationerror.push("Invalid invoice date!");
        } else {
          deviceStock.invoiceDate = new Date(fieldVal);
        }
        Date.parse(fieldVal);
      } else if (fieldsArr[i].name == "SupplierName") {
        const selSupplier = this.supplierservice.supplierIdNameList.filter(
          obj => obj.supplierName == fieldVal
        );
        if (selSupplier && selSupplier[0]) {
          if (!deviceStock.supplier) {
            deviceStock.supplier = new SupplierModel();
          }
          deviceStock.supplier.supplierId = selSupplier[0].supplierId;
          deviceStock.supplier.supplierName = selSupplier[0].supplierName;
        } else {
          tempvalidationerror.push("Invalid Supplier Name");
        }
      } else if (fieldsArr[i].name == "Condition") {
        if (fieldVal.toLowerCase() == "ok") {
          deviceStock.deviceCondition = "O";
        } else if (fieldVal.toLowerCase() == "faulty") {
          deviceStock.deviceCondition = "F";
        } else {
          tempvalidationerror.push("Device condition should be Ok or Faulty!");
        }
      }
    }
    if (
      tempvalidationerror &&
      tempvalidationerror.length > 0 &&
      tempvalidationerror[0]
    ) {
      return tempvalidationerror.join("\n");
    } else {
      return undefined;
    }
  }
  getDeviceStockListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.objdevicestockservice
      .getDeviceStockList(page, this.searchField, sort)
      .subscribe(
        data => {
          let obj: Array<DeviceStockModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "devicestock"
            );
          } else {
            alert("Failed to get user list!");
          }
        },
        error => {
          alert("Failed to get user list!");
        }
      );
  }
  onExcelClick() {
    this.getDeviceStockListForExcel();
  }

  /*  onDetailKeypress(e) {
    console.log("======key pressed=========");
   }*/

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: any): void {
    if (event.target.localName == "body" && event.code == "Backspace") {
      if (this.isDetailScreen) {
        this.onBackbuttonClick();
      }
    }
  }
  savebuttontext() {
    if (this.entryMode == "U") {
      return "Upload";
    } else {
      return "Save";
    }
  }

  allowAggregatorSelection() {
    return this.auth.getUserDistributorId() ? false : true;
  }

  getAggregatorList() {
    let aggregatorlist: Array<AggregatorDistributorModel> = undefined;
    if (this.allowAggregatorSelection()) {
      if (
        this.aggregatorDistributorService.aggregatorDistributorList &&
        this.aggregatorDistributorService.aggregatorDistributorList.length > 0
      ) {
        aggregatorlist = this.aggregatorDistributorService.aggregatorDistributorList.filter(
          obj => obj.id == 0 || obj.type == "A"
        );
      }
    }
    return aggregatorlist;
  }
}
