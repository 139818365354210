import { Injectable } from "@angular/core";
import {SupplierModel} from '../models/supplier.model';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {AppSettings} from '../framework/config/AppSettings';
import { throwError } from 'rxjs';
import { GridPage } from '../framework/util/components/gridtable/gridtable.component';
import { SortClass } from '../framework/modules/util.class';

@Injectable()
export class SupplierService {
    supplierIdNameList : SupplierModel[];
    constructor(private http : HttpClient, private appsetting : AppSettings) {

    }
    loadSupplierNameWithId() {
        this.supplierIdNameList=undefined;
        this.getSupplierNameApi().subscribe(data => {
            if(data["success"]) {
                if(data["result"])  {
                    this.supplierIdNameList = data["result"];    
                } else {
                    this.supplierIdNameList = undefined;
                }                
            } else {
                this.supplierIdNameList=undefined;
                throwError(data["message"]);
            }
        });
    }
    
    getSupplierNameApi() {
        let header : HttpHeaders = new HttpHeaders();
        return this.http.get( this.appsetting.get("server-url") + '/api/allsuppliername',{headers : header, observe : "body", responseType : "json"})
        .map(res=>res);
    }


    saveSupplier(supplier: SupplierModel) {
        let header = new HttpHeaders();
        return this.http
            .post(this.appsetting.get("server-url") + "/api/savesupplier", supplier, {
            headers: header
            })
            .map(res => res);
    }

  getSupplierList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (sortField) {
      if (sortField.sortFieldName && sortField.sortDirection != "") {
        httpParam = httpParam.append("sortDirection", sortField.sortDirection);
      }
      if (sortField.sortFieldName && sortField.sortFieldName != "") {
        httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
      }
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/supplierlist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }
}