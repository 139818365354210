import { Component, OnInit, ViewChild } from "@angular/core";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import {
  gridColumns,
  GridPage,
  GridtableComponent
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { Tools } from "src/app/framework/modules/tools";
import { AuthService } from "src/app/framework/services/auth.service";
import { global } from "src/app/framework/modules/global";
import { Router } from "@angular/router";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { MerchantService } from "src/app/services/merchant.service";
import { MerchantModel } from "src/app/models/merchant.model";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ObjectUnsubscribedError } from "rxjs";
import { NgYesNoModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";
import { IndentdetailComponent } from "../indentdetail/indentdetail.component";
import { IndentModel } from "src/app/models/indent.model";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
import { PincodeService } from "src/app/services/pincode.service";
import { PincodeModel } from "src/app/models/pincode.model";

@Component({
  selector: "app-merchant-preboarding",
  templateUrl: "./merchant-preboarding.component.html",
  styleUrls: ["./merchant-preboarding.component.css"]
})
export class MerchantPreboardingComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  @ViewChild(IndentdetailComponent, { static: true })
  indDetailComp: IndentdetailComponent;

  topbartext: Array<TopbarText> = [
    {
      key: "merchantpreboarding",
      displayText: "Merchant Preboarding List",
      class: "col-md-4"
    }
  ];
  currentTab = "merchantpreboarding";
  indentCreateScreen: boolean = false;
  _indentaction: string = "";

  chantVal = { selectedTabIndex: 0 };
  showIndentButton: boolean = false;
  enableIndentButton: boolean = false;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    { columnHeader: "", columnFieldName: "id", columnClasses: "c-col-pc-0" },
    {
      columnHeader: "Application Id",
      columnFieldName: "applicationId",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Status",
      columnFieldName: "preboardingStatus",
      columnClasses: "c-col-pc-8",
      callbackFunction: this.getStatusText,
      callClassFunction: this.statusClass
    },
    {
      columnHeader: "Preboarding Date",
      columnFieldName: "preboardingDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Merchant Name",
      columnFieldName: "authPersonFirstName",
      columnClasses: "c-col-pc-15",
      callbackFunctionRowPrm: this.setMerchantName
    },
    {
      columnHeader: "Company Name",
      columnFieldName: "companyName",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Phone",
      columnFieldName: "phoneNumber",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "Email",
      columnFieldName: "emailId",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Address",
      columnFieldName: "businessAddressCity",
      columnClasses: "c-col-pc-17",
      callbackFunctionRowPrm: this.getAddress
    }
  ];
  sortFields = [
    { name: "merchantId", display: "-" },
    { name: "preboardingDate", display: "Preboarding Date" },
    { name: "merchantName", display: "Merchant Name" },
    { name: "companyName", display: "Marketing Name" },
    { name: "preboardingStatus", display: "Status" },
    { name: "businessAddress", display: "Business Address" },
    { name: "businessAddressCity", display: "Business Address City" },
    { name: "businessAddressDistrict", display: "Business Address District" },
    { name: "businessAddressState", display: "Business Address State" },
    { name: "phoneNumber", display: "Phone Number" },
    { name: "emailId", display: "Email Id" }
  ];

  searchField = {
    searchText: "",
    status: "",
    preboardingDate: ""
  };
  sort: SortClass;
  searchStatusValues = [
    { value: "", text: "-" },
    { value: "pending", text: "Pending" },
    { value: "ok", text: "Ok" }
  ];
  isDetailScreen: boolean = false;

  page: GridPage;
  _menuId: string;
  _action: string = "";
  _isChange = false;
  data: MerchantModel;
  _data: any;
  datalist: Array<MerchantModel>;
  indent_tooltip: string = "";
  constructor(
    private merchantservice: MerchantService,
    private ngbModalService: NgbModal,
    public tools: Tools,
    private auth: AuthService,
    private objGlobal: global,
    private router: Router,
    private csvservice: ExporttocsvService,
    private pincodeservice: PincodeService
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size

    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "merchantId";
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );
    this.objGlobal.setActiveMenu(this._menuId);
    this.data = new MerchantModel();
    this._data = this.tools.copyFullObject(this.data);
    this.getPreboardMerchantList();
  }

  ngOnInit() {}

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  setTopBar(withIndent: boolean) {
    if (withIndent) {
      this.topbartext = [
        {
          key: "merchantpreboarding",
          displayText: "MERCHANT PREBOARDING",
          class: "col-md-4"
        },
        {
          key: "indentcreate",
          displayText: "Indent Creation",
          class: "col-md-4"
        }
      ];
    } else {
      this.topbartext = [
        {
          key: "merchantpreboarding",
          displayText: "MERCHANT PREBOARDING",
          class: "col-md-4"
        }
      ];
    }
  }
  onAddNew() {
    this.isDetailScreen = true;
    this.topbartext = [
      {
        key: "merchantpreboarding",
        displayText: "Add Merchant Preboarding",
        class: "col-md-5"
      }
    ];
    this.showIndentButton = false;
    this.enableIndentButton = false;
    this._action = "new";
    this.data = new MerchantModel();
    this.data.preboardingDate = new Date();
    this._data = this.tools.copyFullObject(this.data);
  }
  getAddress(obj: MerchantModel) {
    let retvalue = "";
    retvalue = obj.businessAddress ? obj.businessAddress : "";
    if (obj.businessAddressCity) {
      if (retvalue != "") {
        retvalue = retvalue + ",";
      }
      retvalue = retvalue + obj.businessAddressCity;
    }
    if (obj.businessAddressDistrict) {
      if (retvalue != "") {
        retvalue = retvalue + ",";
      }
      retvalue = retvalue + obj.businessAddressDistrict;
    }
    if (obj.businessAddressState) {
      if (retvalue != "") {
        retvalue = retvalue + ",";
      }
      retvalue = retvalue + obj.businessAddressState;
    }
    if (obj.businessAddressPinCode) {
      if (retvalue != "") {
        retvalue = retvalue + "-";
      }
      retvalue = retvalue + obj.businessAddressPinCode;
    }
    return retvalue;
  }
  getDatalist() {
    this.getPreboardMerchantList();
  }
  selectData(e) {}
  deleteData(e) {}
  onRowDblClick(e: any) {
    if (e.merchantId) {
      this.isDetailScreen = true;
      this.topbartext = [
        {
          key: "merchantpreboarding",
          displayText: "Merchant Preboarding",
          class: "col-md-4"
        }
      ];
      if (this.isAllowIndent()) {
        this.showIndentButton = true;
        this.enableIndentButton = false;
      }
      this.getDetail(e.merchantId);
    }
  }

  getStatusText(status: String) {
    let statusText: string = "New";
    switch (status) {
      case "N":
        statusText = "New";
        break;
      case "BH":
        statusText = "BUHead hold";
        break;
      case "BA":
        statusText = "BH approved";
        break;
      case "BR":
        statusText = "BH rejected";
        break;
      case "FH":
        statusText = "Fin hold";
        break;
      case "FA":
        statusText = "Fin approved";
        break;
      case "FR":
        statusText = "Fin rejected";
        break;
      case "IC":
        statusText = "Generated";
        break;
    }
    return statusText;
  }
  statusClass(status: String) {
    let statusClass: string;
    switch (status) {
      case "BH":
      case "FH":
        statusClass = "table-primary";
        break;
      case "BA":
      case "FA":
        statusClass = "table-success";
        break;
      case "BR":
      case "FR":
        statusClass = "table-danger";
        break;
    }
    return statusClass;
  }
  getPreboardMerchantList() {
    this.merchantservice
      .getMerchantPreboardList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get preboard merchant list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get preboard merchant list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  disableCancel() {
    return !this.isModified();
  }
  disableSave(f: any) {
    //return false;
    let isDisableSave: boolean = true;
    if (f.valid) {
      const isModified: boolean = this.isModified();
      if (isModified) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }
  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Merchant pre board has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
            this.topbartext = [
              {
                key: "merchantpreboarding",
                displayText: "Merchant Preboarding List",
                class: "col-md-5"
              }
            ];
            this.setTopBar(false);
            this.chantVal.selectedTabIndex = 0;
            this.getDatalist();
            if (this._isChange == true) {
              this._isChange = false;
              this.clearDetail();
            }
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
      this.topbartext = [
        {
          key: "merchantpreboarding",
          displayText: "Merchant Preboarding List",
          class: "col-md-4"
        }
      ];
      this.getDatalist();
      this.setTopBar(false);
      this.chantVal.selectedTabIndex = 0;
      if (this._isChange == true) {
        this._isChange = false;
        this.clearDetail();
      }
    }
  }

  clearDetail() {
    this.data.applicationId = undefined;
    this.data.preboardingDate = undefined;
    this.data.preboardingStatus = undefined;
    this.data.merchantName = undefined;
    this.data.companyName = undefined;
    this.data.merchantId = undefined;
    this.data.businessAddress = undefined;
    this.data.businessAddressCity = undefined;
    this.data.businessAddressDistrict = undefined;
    this.data.businessAddressPinCode = undefined;
    this.data.businessAddressState = undefined;
    this.data.emailId = undefined;
    this.data.phoneNumber = undefined;
    this._data = this.tools.copyFullObject(this.data);
  }
  saveData() {
    let objmsg: SimpleMessage = new SimpleMessage();
    if (
      this.data.preboardingDate == undefined ||
      this.data.companyName == undefined ||
      this.data.companyName == ""
    ) {
      objmsg.title = "Warning";
      objmsg.message = "Please enter mandator fields!";
      objmsg.btnClass = "btn-warning";
      this.tools.simpleMessage(objmsg);
    } else if (
      new Date(this.tools.formatDateToDDMMMYY(this.data.preboardingDate)) >
      new Date()
    ) {
      objmsg.title = "Warning";
      objmsg.message =
        "Pre boarding date should not be more than current data!";
      objmsg.btnClass = "btn-warning";
      this.tools.simpleMessage(objmsg);
    } else {
      this.merchantservice.saveMerchantPreboard(this.data).subscribe(data => {
        if (data["success"]) {
          this._isChange = true;
          objmsg.title = "Information";
          objmsg.message = "Merchant preboarding saved successfully!";
          objmsg.btnClass = "btn-info";
          this.data = data["result"];
          this.data.preboardingDate = new Date(this.data.preboardingDate);
          this.setPreboardingIndentButton();
          this.showIndentButton = true;
          this._data = this.tools.copyFullObject(this.data);
          this.tools.simpleMessage(objmsg);
        } else {
          switch (data["message"]) {
            case "Error":
              objmsg.title = "Error";
              objmsg.message = "Error in saving merchant preboarding!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
            case "InputDataNotExisting":
              objmsg.title = "Warning";
              objmsg.message = "Merchant preboard data not provided!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
            case "InvalidRecord":
              objmsg.title = "Warning";
              objmsg.message = "Invalid id to update merchant preboarding!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
            case "NAMEALREADYEXISTS":
              objmsg.title = "Warning";
              objmsg.message = "Merchant name already exists!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
          }
        }
      });
    }
  }
  setPreboardingIndentButton() {
    if (this.data && this.data.firstIndent && this.data.firstIndent.indentId) {
      this.enableIndentButton = false;
      this.indent_tooltip =
        "Indent exists with indent number - " +
        this.data.firstIndent.indentNumber +
        "!";
    } else {
      this.enableIndentButton = true;
      this.indent_tooltip = "";
    }
  }
  getDetail(id: number) {
    let objmsg: SimpleMessage = new SimpleMessage();
    this.merchantservice.getMerchantPreboardingDetail(id).subscribe(data => {
      if (data["success"]) {
        this._action = "update";
        this.data = data["result"];
        this.data.preboardingDate = new Date(this.data.preboardingDate);
        this.setPreboardingIndentButton();
        this._data = this.tools.copyFullObject(this.data);
      } else {
        switch (data["message"]) {
          case "Error":
            objmsg.title = "Error";
            objmsg.message = "Error in saving merchant preboarding!";
            objmsg.btnClass = "btn-warning";
            this.tools.simpleMessage(objmsg);
            break;
          case "InvalidRecord":
            objmsg.title = "Warning";
            objmsg.message = "Invalid id to update merchant preboarding!";
            objmsg.btnClass = "btn-warning";
            this.tools.simpleMessage(objmsg);
            break;
        }
      }
    });
  }

  getMerchantStatus() {
    switch (this.data.merchantStatus) {
      case "PB":
        return "Pre Boarded";
      default:
        return "";
    }
  }

  isModified() {
    if (this.data.businessAddress != this._data.businessAddress) {
      return true;
    }
    if (this.data.businessAddressCity != this._data.businessAddressCity) {
      return true;
    }
    if (
      this.data.businessAddressDistrict != this._data.businessAddressDistrict
    ) {
      return true;
    }
    if (this.data.businessAddressPinCode != this._data.businessAddressPinCode) {
      return true;
    }
    if (this.data.businessAddressState != this._data.businessAddressState) {
      return true;
    }
    if (this.data.emailId != this._data.emailId) {
      return true;
    }
    if (this.data.companyName != this._data.companyName) {
      return true;
    }
    if (this.data.merchantName != this._data.merchantName) {
      return true;
    }
    if (this.data.phoneNumber != this._data.phoneNumber) {
      return true;
    }
    if (this.data.preboardingDate && !this._data.preboardingDate) {
      return true;
    }
    if (
      this.tools.isDateSame(
        this.data.preboardingDate,
        this._data.preboardingDate
      ) == false
    ) {
      return true;
    }
  }

  onCancel() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            if (this.data.merchantId) {
              this.getDetail(this.data.merchantId);
            } else {
              this.clearDetail();
            }
          }
        },
        reason => {}
      );
    } else {
    }
  }
  createIndent() {
    if (this.topbartext.length < 2) {
      this.setTopBar(true);
      this._indentaction = "new";
      this.indDetailComp.onAddNew();
      this.indDetailComp.fromMerchantScreen = true;
      this.indDetailComp.indent.typeOfSale = "direct";
      this.indDetailComp.selectedMerchant = [
        {
          merchantId: this.data.merchantId,
          merchantName: this.data.merchantName
        }
      ];
      this.indDetailComp.onTypeOfSaleSelect();
    }
    this.indentCreateScreen = true;
    this.chantVal.selectedTabIndex = 1;
  }
  tobarTabClick(key: string) {
    if (key == "indentcreate") {
      this.indentCreateScreen = true;
    } else {
      this.indentCreateScreen = false;
    }
  }

  isAllowIndent() {
    return this.auth.isPrivExists(this._menuId + "=>indent");
  }
  onBackButtonClickIndentScreen(isChange: boolean) {
    this.indentCreateScreen = false;
    this.indDetailComp.clearDetailData();
    if (isChange) {
      this.getDetail(this.data.merchantId);
    }
    this.setTopBar(false);
    this.chantVal.selectedTabIndex = 0;
  }

  getMerchantPreboardListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.merchantservice
      .getMerchantPreboardList(page, this.searchField, this.sort)
      .subscribe(
        data => {
          let obj: Array<MerchantModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "merchantpreboard"
            );
          } else {
            alert("Failed to get merchant preboard list!");
          }
        },
        error => {
          alert("Failed to get merchant preboard list!");
        }
      );
  }
  onExcelClick() {
    this.getMerchantPreboardListForExcel();
  }

  async setBusinessAddressPincodeData(e) {
    const pincodedata: PincodeModel = await this.pincodeservice.getPincodeData(
      this.data.businessAddressPinCode
    );
    if (pincodedata) {
      this.data.businessAddressCity = pincodedata.taluk;
      this.data.businessAddressDistrict = pincodedata.district;
      this.data.businessAddressState = pincodedata.state;
    } else {
      this.data.businessAddressCity = undefined;
      this.data.businessAddressDistrict = undefined;
      this.data.businessAddressState = undefined;
      e.target.classList.remove("ng-valid");
      e.target.classList.add("ng-invalid");
    }
  }

  captalizeLetter(field) {
    field.key = field.key.toUpperCase();
  }

  setMerchantName(obj) {
    let name: string = "";
    if (obj.authPersonFirstName) {
      name = name + obj.authPersonFirstName;
    }
    if (obj.authPersonLastName) {
      if (name != "") {
        name = name + " ";
      }
      name = name + obj.authPersonLastName;
    }
    return name;
  }

  pincodeMsg() {
    return "Please enter valid pincode!";
  }
}
