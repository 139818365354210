import {
  NgModule,
  ModuleWithProviders,
  Provider,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { TransactionreportComponent } from "./components/transactionreport/transactionreport.component";
import { TransactionService } from "./services/transaction.service";
import { TransactionClickComponent } from "./components/transactionreport/trasactionclick.component";
import { TransactionReportBtnRefreshComponent } from "./components/transactionreport/transactionreportbtnrefresh.components";
import { TransreportComponent } from "./components/transreport/transreport.component";
import { FrameworkModule } from "../framework/framework.module";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UpitransreportComponent } from './components/upitransreport/upitransreport.component';
import { TerminallistReportComponent } from './components/terminallist-report/terminallist-report.component';
import { SettlementReportsComponent } from './components/settlement-reports/settlement-reports.component';

const providers: Provider[] = [TransactionService];

@NgModule({
  declarations: [
    TransactionreportComponent,
    TransactionClickComponent,
    TransactionReportBtnRefreshComponent,
    TransreportComponent,
    UpitransreportComponent,
    TerminallistReportComponent,
    SettlementReportsComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    BrowserModule,
    BrowserAnimationsModule,
    FrameworkModule,
    MatSidenavModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: providers,
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [TransactionreportComponent, TransreportComponent,UpitransreportComponent]
})
export class ReportsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ReportsModule,
      providers: providers
    };
  }
}
