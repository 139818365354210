import { Injectable } from "@angular/core";
import { DeviceStockModel, DeviceReturnModel } from "../models/device.model";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";
import { throwError } from "rxjs";
import { Tools } from "../framework/modules/tools";
@Injectable()
export class DeviceStockService {
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}

  saveDeviceStock(deviceStock: DeviceStockModel) {
    deviceStock.merchant = undefined;
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/devicestock",
        deviceStock,
        { headers: header }
      )
      .map(res => res);
  }

  getDeviceStockList(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (searchField.invoiceDate) {
      httpParam = httpParam.append(
        "invoiceDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.invoiceDate[0])
      );
      httpParam = httpParam.append(
        "invoiceDateTill",
        this.tools.formatDateToDDMMMYY(searchField.invoiceDate[1])
      );
    }
    if (searchField.entryDate) {
      httpParam = httpParam.append(
        "entryDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.entryDate[0])
      );
      httpParam = httpParam.append(
        "entryDateTill",
        this.tools.formatDateToDDMMMYY(searchField.entryDate[1])
      );
    }
    if (searchField.deviceCondition && searchField.deviceCondition != "") {
      httpParam = httpParam.append(
        "deviceCondition",
        searchField.deviceCondition
      );
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    httpParam = httpParam.append("searchProductId", "0");
    httpParam = httpParam.append("searchSupplierId", "0");

    return this.http
      .get(this.appsetting.get("server-url") + "/api/devicestocks", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getDeviceStockDetail(deviceStockId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    //param = param.append("deviceStockId", deviceStockId.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/devicestock/" + deviceStockId,
        {
          headers: header,
          observe: "body",
          //params: param,
          responseType: "json"
        }
      )
      .map(res => res);
  }
  getDeviceStockOnSr(deviceSerialNumber: string) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("deviceSerialNumber", deviceSerialNumber);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/devicestockbysrno", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  getDeviceStockOnSrForTerminal(
    deviceSerialNumber: string,
    merchantTerminalId: number
  ) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("deviceSerialNumber", deviceSerialNumber);
    if (merchantTerminalId) {
      param = param.append("merchantTerminalId", merchantTerminalId.toString());
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/devicestockbysrnoforterminal",
        {
          headers: header,
          observe: "body",
          params: param,
          responseType: "json"
        }
      )
      .map(res => res);
  }
  getStockedDeviceSrListByProduct(productId: number, indentId: number) {
    let param: HttpParams = new HttpParams();
    if (productId) {
      param = param.append("productId", productId.toString());
    } else {
      param = param.append("productId", "");
    }
    if (indentId) {
      param = param.append("indentId", indentId.toString());
    } else {
      param = param.append("indentId", "");
    }
    let header = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/stockdevicesrlistbyproductforindent",
        {
          headers: header,
          observe: "body",
          params: param,
          responseType: "json"
        }
      )
      .map(res => res);
  }

  async getDeviceDataOnSr(
    deviceSrNumber: string,
    merchantTerminalId: number
  ): Promise<any> {
    return await this.getDeviceStockOnSrForTerminal(
      deviceSrNumber,
      merchantTerminalId
    )
      .toPromise()
      .then(res => {
        /*
              if(res["success"]) {
                return res["result"];
              } else {
                return undefined;
              }  */
        return res;
      })
      .catch(err => {
        return undefined;
      });
  }

  saveBulkDeviceStock(deviceStock: Array<DeviceStockModel>) {
    for (let i = 0; i < deviceStock.length; i++) {
      deviceStock[i].merchant = undefined;
    }
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/bulkdevicestock",
        deviceStock,
        { headers: header }
      )
      .map(res => res);
  }
}
@Injectable()
export class DeviceReturnService {
  deviceReturnReasonList: Array<string>;
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}
  loadDeviceReturnReason() {
    this.getDeviceReturnReasonApi().subscribe(data => {
      if (data["success"]) {
        if (data["result"]) {
          this.deviceReturnReasonList = data["result"];
        } else {
          this.deviceReturnReasonList = undefined;
        }
      } else {
        throwError(data["message"]);
        this.deviceReturnReasonList = undefined;
      }
    });
  }
  getDeviceReturnReasonApi() {
    let header: HttpHeaders = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/devicereturnreasons", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }
  saveDeviceReturn(deviceStock: DeviceReturnModel) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/devicereturn",
        deviceStock,
        { headers: header }
      )
      .map(res => res);
  }

  getDeviceReturnList(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (searchField.returnDate) {
      httpParam = httpParam.append(
        "returnDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.returnDate[0])
      );
      httpParam = httpParam.append(
        "returnDateTill",
        this.tools.formatDateToDDMMMYY(searchField.returnDate[1])
      );
    }
    if (searchField.deviceCondition && searchField.deviceCondition != "") {
      httpParam = httpParam.append(
        "deviceCondition",
        searchField.deviceCondition
      );
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    //header.append('Content-Type','application/json');

    return this.http
      .get(this.appsetting.get("server-url") + "/api/devicereturns", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getDeviceReturnDetail(deviceReturnId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("deviceReturnId", deviceReturnId.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/devicereturn/" +
          deviceReturnId,
        {
          headers: header,
          observe: "body",
          //params: param,
          responseType: "json"
        }
      )
      .map(res => res);
  }
}
