import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../framework/config/AppSettings';
import { Tools } from '../framework/modules/tools';
import { GridPage } from '../framework/util/components/gridtable/gridtable.component';
import { SortClass } from '../framework/modules/util.class';

@Injectable({
  providedIn: 'root'
})
export class MerchantUploadService {

  constructor(private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools) { }
  
  getExistingMidTidCount(midtidlist) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/existingmidtidcount",
        midtidlist,
        { headers: header }
      )
      .map(res => res);
  }

  uploadMerchant(merchantupload) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/uploadmerchant",
        merchantupload,
        { headers: header }
      )
      .map(res => res);
  }

  getMerchantUploadHistory(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    //httpParam=httpParam.append("searchText", searchField.searchText);
    if (searchField.importDate) {
      if (searchField.importDate[0]) {
        httpParam = httpParam.append(
          "importDateFrom",
          this.tools.formatDateToDDMMMYY(searchField.importDate[0])
        );
      }
      if (searchField.importDate[1]) {
        httpParam = httpParam.append(
          "importDateTill",
          this.tools.formatDateToDDMMMYY(searchField.importDate[1])
        );
      }
    }
    if (searchField.status && searchField.status != "") {
      httpParam = httpParam.append(
        "status",
        searchField.status
      );
    }
    if (searchField.searchText && searchField.searchText != "") {
      httpParam = httpParam.append("searchText", searchField.searchText);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/merchantuploadhistorylist",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }
}
