
export class PincodeModel {
    pinCode? : string;
    officeName? : string;
    officeType? : string;
    deliveryStatus? : string;
    divisionName? : string;
    regionName? : string; 
    circleName? : string;
    taluk? : string;
    district? : string;
    state? : string;
}