import { Component, OnInit, ViewChild } from '@angular/core';
import { GridtableComponent, gridColumns, GridPage } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { SimpleMessage, SortClass } from 'src/app/framework/modules/util.class';
import { Tools } from 'src/app/framework/modules/tools';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { global } from 'src/app/framework/modules/global';
import { AuthService } from 'src/app/framework/services/auth.service';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { CardDetailModel, TransactionModel, UPITransactionModel } from '../../models/transaction.model';
import { MerchantModel } from 'src/app/models/merchant.model';
import { TransactionService } from '../../services/transaction.service';
import { UPIQRCodeDetailsModel } from 'src/app/models/UPIQRCodeDetailsModel';

export class SearchFields {
  dateFrom: Date;
  dateTill: Date;
  amountFrom?: number;
  amountTo?: number;
  customerVpaId?: string;
  merchantVpaId?: string;
  terminalId?: string;
  rrn?: number;
}

@Component({
  selector: 'app-upitransreport',
  templateUrl: './upitransreport.component.html',
  styleUrls: ['./upitransreport.component.css']
})
export class UpitransreportComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  @ViewChild("snav", { static: true }) sideNave: any;
  topbartext: TopbarText[] = [
    {
      key: "upitransactionreport",
      displayText: "UPI Transaction",
      class : "col-md-4" 
    }
  ];
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Customer VPA Id",
      columnFieldName: "payerVPAId",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "DateTime",
      columnFieldName: "datetime",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS
    },
    {
      columnHeader: "Amount",
      columnFieldName: "settAmount",
      columnClasses: "c-col-pc-10 text-right pr-4",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "RR Number",
      columnFieldName: "rrn",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Terminal Id",
      columnFieldName: "terminalId",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "UPI Provider",
      columnFieldName: "txnId",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.getUpiProvider
    },
    {
      columnHeader: "Status",
      columnFieldName: "orgStatus",
      columnClasses: "c-col-pc-12",
      callbackFunctionRowPrm : this.getStatusText
      //callbackFunction: this.getStatusText,
      //callClassFunction: this.statusClass
    }
  ];
  sortFields = [
    { name: "payerVPAId", display: "Customer VPA Id" },
    { name: "datetime", display: "DateTime" },
    /*{ name: "userId", display: "Login Id" },*/
    { name: "settAmount", display: "Amount" },
    { name: "rrn", display: "RR Number" },
    { name: "terminalId", display: "Terminal Id" },
    //{ name: "ackStatus", display: "Status" }
  ];

  searchField: SearchFields;
  sort: SortClass;
  public datalist;
  public data: UPITransactionModel;
  public datamerchant: MerchantModel;
  page: GridPage;
  _menuId: string = "";
  id: number;
  //public upiQRDetails: UPIQRCodeDetailsModel;
  constructor(
    private transactionservice: TransactionService,
    public tools: Tools,
    private ngbModalService: NgbModal,
    private router: Router,
    private objGlobal: global,
    private auth: AuthService,
    private csvservice: ExporttocsvService) { 
      //this.upiQRDetails = new UPIQRCodeDetailsModel();
  
      this.data = new UPITransactionModel();
      this.datamerchant = new MerchantModel();
      this.searchField = new SearchFields();
      this.searchField.dateFrom = new Date();
      this.searchField.dateTill = new Date();
      
      this.page = new GridPage();
      this.page.size = 10; //Default page size
      this.sort = new SortClass();
      //this.sort.sortDirection = "D";
      //this.sort.sortFieldName = "id";
      this._menuId = this.tools.getMenuName(
        this.router.url.substring(1, this.router.url.length)
      );
      this.objGlobal.setActiveMenu(this._menuId);
      this.setTitle();
    
  }
  setTitle() {
    
    if (this.router.url == "/main/upitransactionreport") {
      this.topbartext = [{key: "upi-transaction",displayText: "UPI TRANSACTION",class : "col-md-4"}];
    } else {
      this.topbartext = [{key: "upitransactionreport",displayText: "UPI TRANSACTION REPORT",class : "col-md-4"}];
    }
  }
  ngOnInit() {
  }

  getDatalist() {
    const params = {};
    this.transactionservice
      .getUpiTransactions(this.searchField, this.page, this.sort)
      .subscribe(data => {
        if (data["success"]) {
          this.datalist = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
        }
      });
  }


  dataTimeValue(val: Date) {
    if (val) {
      return this.tools.formatDateToDDMMMYYHHMMSS(val);
    }
  }
  getDateConfig() {
    const date = new Date("01-Jan-1900");
    return this.tools.getBsDatePickerConfig(true, date);
  }

  isAllowExport() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>export");
    return isAdd;
  }

  onExcelClick() {
    this.getDataForExportToExcel();
  }

  getDataForExportToExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.transactionservice
      .getUpiTransactions(this.searchField, page, this.sort)
      .subscribe(
        data => {
          let obj: Array<UPITransactionModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "upi_transaction"
            );
          } else {
            alert("Failed to get UPI transaction list!");
          }
        },
        error => {
          alert("Failed to get UPI transaction list!");
        }
      );
  }
  
  getUpiProvider(val) {
    let result;
    if (val && val.length > 3) {
      result=val.substring(0,3)
    }
    return result;
  }

  getStatusText(obj) {
    if (obj) {
      if (obj.orgStatus == "S" && (obj.orgRespCode == "00"|| obj.orgRespCode == "0")) {
        return "Success"
      } else {
        return "Failed"
      }
    }
  }
  selectData(tran: any) {
    this.data = new UPITransactionModel();
    this.sideNave.open();
    if (tran.id) {
      this.id = tran.id;
      this.transactionservice.getUPITransaction(tran.id).subscribe(
        data => {
          if (data["success"]) {
            this.data = data["result"];
            if (this.data.merchant) {
              this.datamerchant = this.data.merchant;
            } else {
              this.datamerchant = new MerchantModel();
            }
            /*if (this.data.upiQrCodeDetails) {
              this.upiQRDetails = this.data.upiQrCodeDetails;
            } else {
              this.upiQRDetails = new UPIQRCodeDetailsModel();
            }*/
          } else {
            this.data = new UPITransactionModel();
            let objmsg: SimpleMessage = new SimpleMessage();
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            objmsg.title = "Error";
            objmsg.message =
              "Error response from server while fetching transaction data!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          this.data = new UPITransactionModel();
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Error";
          objmsg.message = "Error in fetching transaction data!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }
  

}
