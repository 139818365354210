import { Injectable } from "@angular/core";
import { Tools } from '../framework/modules/tools';
import { MerchantService } from '../services/merchant.service';


@Injectable()
export class UpiMerchantUploadConfig {
   constructor(private tools: Tools, private merchantservice: MerchantService) { 
      if (!merchantservice.configLoaded) {
         this.merchantservice.loadMerchantConfig();
      }
   }
 
   
   getAccountType = (val) => {
      let result = val;
      if (this.merchantservice.bankAccountType) {
         const accType = this.merchantservice.bankAccountType.find(obj => obj.code == val);
         if (accType) {
            result= accType["name"];
         }
      }
      return result;
   }

   getBusinessTurnover = (val) => {
      let result = val;
      if (val === 'S') {
         result = "Small";
      } else if (val === "O") {
         result = "Other";
      }
      return result;
   }
   
   getMerchantService = (val) => {
      let result = val;
      if (val === "R") {
         result = "Retail";
      } else if (val === "C") {
         result = "Corporate";
      }
      return result;
   }

   getFileHeader() {
      return "merchant_name, vpa address, currency, address1, address2, city, state, country, zippostalcode, phone no, email, first_name, last_name, merchant_id, mcc, accountno, ifsccode, aadhar_no, pan_no, account_type, Terminal id, Terminal Location Address, Terminal City, Terminal State, Terminal Counry, Terminal Pincode, Terminal Currency, Branch Code, Branch name, Business turnover, Terminal Name, Merchant Service";
   }
    getUpiMerchantFileUploadColumns() {
      const cols = [
            {
               columnHeader: "Merchant Name",
               columnFieldName: "companyName",
               fileCols : ["merchant_name"],
               columnClasses: "c-width-200px",
               regExpFun: this.tools.namePattern,
               mandatory: true,
               maxLength : 60 
            },
            {
               columnHeader: "VPA Address",
               columnFieldName: "vpaAddress",
               fileCols : ["vpa address"],
               columnClasses: "c-width-150px",
               mandatory: true,
               maxLength : 50
            },
            {
               columnHeader: "Address",
               columnFieldName: "address",
               fileCols : ["address1", "address2"], 
               columnClasses: "c-width-300px",
               mandatory: false,
               maxLength : 250
            },
            {
               columnHeader: "City",
               columnFieldName: "city",
               fileCols : ["city"],
               columnClasses: "c-width-100px",
               mandatory: false,
               maxLength : 50
            },
            {
               columnHeader: "State",
               columnFieldName: "state",
               fileCols : ["state"],
               columnClasses: "c-width-100px",
               mandatory: false,
               maxLength : 50
            },
            {
               columnHeader: "Pincode",
               columnFieldName: "pincode",
               fileCols : ["zippostalcode"],
               columnClasses: "c-width-100px",
               mandatory: false,
               maxLength: 6,
               regExp : "[0-9]{6}"
            },
            {
               columnHeader: "Phone Number",
               columnFieldName: "phoneNumber",
               fileCols : ["phone no"],
               columnClasses: "c-width-100px",
               mandatory: true,
               maxLength: 15,
               regExp : "^[0-9]{10}$"
            },
            {
               columnHeader: "Email",
               columnFieldName: "email",
               fileCols : ["email"],
               columnClasses: "c-width-200px",
               mandatory: false,
               maxLength: 100,
               regExpFun : this.tools.emailPattern
            },
            {
               columnHeader: "First Name",
               columnFieldName: "firstName",
               fileCols : ["first_name"],
               columnClasses: "c-width-200px",
               mandatory: false,
               maxLength: 60,
               regExpFun : this.tools.namePattern
            },
            {
               columnHeader: "Last Name",
               columnFieldName: "lastName",
               fileCols : ["last_name"],
               columnClasses: "c-width-200px",
               mandatory: false,
               maxLength: 60,
               regExpFun : this.tools.namePattern
            },
            {
               columnHeader: "MID",
               columnFieldName: "mid",
               fileCols : ["merchant_id"],
               columnClasses: "c-width-100px",
               mandatory: true,
               maxLength : 15
            },
            {
               columnHeader: "MCC",
               columnFieldName: "companyMCC",
               fileCols : ["mcc"],
               columnClasses: "c-width-100px",
               mandatory: false,
               maxLength : 4
            },
            {
               columnHeader: "Account Number",
               columnFieldName: "accountNumber",
               fileCols : ["accountno"],
               columnClasses: "c-width-150px",
               mandatory: true,
               maxLength: 19,
               regExpFun: this.tools.accountNumberPattern
            },
            
            {
               columnHeader: "IFSC Code",
               columnFieldName: "ifscCode",
               fileCols : ["ifsccode"],
               columnClasses: "c-width-100px",
               mandatory: true,
               maxLength: 11,
               regExp : "^[A-Z]{4}0[A-Z0-9]{6}$"
            },  
            {
               columnHeader: "Aadhaar",
               columnFieldName: "aadhaar",
               fileCols : ["aadhar_no"],
               columnClasses: "c-width-100px",
               mandatory: false,
               minLength : 12,
               maxLength: 12,
               regExp : "^[0-9]{12}$"
            }, 
            {
               columnHeader: "PAN",
               columnFieldName: "pan",
               fileCols : ["pan_no"],
               columnClasses: "c-width-100px",
               mandatory: true,
               minLength : 10,
               maxLength: 10,
               regExp : "^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$"
            },  
            {
               columnHeader: "Account Type",
               columnFieldName: "accountType",
               fileCols : ["account_type"],
               columnClasses: "c-width-100px",
               mandatory: true,
               callbackFunction : this.getAccountType,
               enum: [{ code: "SB", value: "Saving" }, { code: "CA", value: "Current" },
                  { code: "OD", value: "Overdraft" }, { code: "OD", value: "SOD" },
                  { code: "CC", value: "Cash Credit" }, 
                  { code: "LA", value: "Loan" }, { code: "SB", value: "Saving Account" },
                  { code: "CA", value: "Current Account" }, { code: "OD", value: "Overdraft Account" },
                  { code: "CC", value: "Cash Credit Account" }, { code: "LA", value: "Loan Account" }]
            },    
            {
               columnHeader: "TID",
               columnFieldName: "terminalId",
               fileCols : ["Terminal id"],
               columnClasses: "c-width-100px",
               mandatory: true,
               maxLength: 10,
               unique : true
            },    
            {
               columnHeader: "Terminal Location Address",
               columnFieldName: "terminalAddress",
               fileCols : ["Terminal Location Address"],
               columnClasses: "c-width-300px",
               mandatory: false,
               maxLength : 200
            }, 
            {
               columnHeader: "Terminal City",
               columnFieldName: "terminalCity",
               fileCols : ["Terminal City"],
               columnClasses: "c-width-100px",
               mandatory: false,
               maxLength : 50
            },
            {
               columnHeader: "Terminal State",
               columnFieldName: "terminalState",
               fileCols : ["Terminal State"],
               columnClasses: "c-width-100px",
               mandatory: false,
               maxLength : 50
            },
            {
               columnHeader: "Terminal Pincode",
               columnFieldName: "terminalPincode",
               fileCols : ["Terminal Pincode"],
               columnClasses: "c-width-100px",
               mandatory: false,
               maxLength: 6,
               regExp : "[0-9]{6}"
            },    
            {
               columnHeader: "Branch Code",
               columnFieldName: "bankBranchCode",
               fileCols : ["Branch Code"],
               columnClasses: "c-width-100px",
               mandatory: false,
               maxLength : 10
            },    
            {
               columnHeader: "Branch Name",
               columnFieldName: "branchName",
               fileCols : ["Branch name"],
               columnClasses: "c-width-200px",
               mandatory: false,
               maxLength : 60
            },
            {
               columnHeader: "Business turnover",
               columnFieldName: "merchantType",
               fileCols : ["Business turnover"],
               columnClasses: "c-width-150px",
               mandatory: false,
               callbackFunction : this.getBusinessTurnover,
               enum: [{ code: "O", value: "Other" },
                  {code: "S", value: "Small"},
                  { code: "O", value: "Other Merchant" },
                  { code: "S", value: "Small Merchant" }]
            },
            {
               columnHeader: "Terminal Name",
               columnFieldName: "storeName",
               fileCols : ["Terminal Name"],
               columnClasses: "c-width-200px",
               mandatory: false,
               maxLength : 250
            }, 
            {
               columnHeader: "Merchant Service",
               columnFieldName: "merchantService",
               fileCols : ["Merchant Service"],
               columnClasses: "c-width-200px",
               mandatory: false,
               callbackFunction : this.getMerchantService,
               enum: [{ code: "R", value: "Retail" }, { code: "C", value: "Corporate" },
                  { code: "R", value: "Retail Merchant" }, { code: "C", value: "Corporate Merchant" }]
            }, 

      ]
      return cols;
   }
}
