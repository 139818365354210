import { Injectable } from "@angular/core";
import { AppSettings } from "../config/AppSettings";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker/public_api";
import { DatePipe, DecimalPipe } from "@angular/common";
import { isObject } from "rxjs/internal/util/isObject";
import { SimpleMessage, MARSResponseErrorMsg } from "../modules/util.class";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent,
} from "../util/components/modalcomponent/modal-content";
import { CodeName } from "../../models/generic.model";
import { MarsErrorMsgComponent } from "../../components/mars-error-msg/mars-error-msg.component";
import { UploadErrListComponent } from 'src/app/components/upload-err-list/upload-err-list.component';
import { UploadErrorMsg } from 'src/app/models/upload.error.model';
import { HttpClient } from '@angular/common/http';
declare var $: any;

class RouteParam {
  receiver?: string;
  param?: any;
}
@Injectable()
export class Tools {
  public routeMenu: any;
  public routeParam: RouteParam;
  public bsDatepickerConfig: Partial<BsDatepickerConfig>;
  private defaultMinDate: Date = new Date("01-Jan-1900");
  public frequency: CodeName[] = [
    { code: "", name: "-" },
    { code: "D", name: "Daily" },
    { code: "M", name: "Monthly" },
    { code: "Q", name: "Quarterly" },
    { code: "H", name: "Half Yearly" },
    { code: "Y", name: "Yearly" },
    { code: "N", name: "None" },
  ];

  constructor(
    private appsettings: AppSettings,
    private ngbModalService: NgbModal,
    private http: HttpClient
  ) {
    this.setbsDatePickerConfig();
  }
  sortObject(arrObj: Array<any>, field: string, ignoreCase: boolean = true) {
    if (!field) {
      field = "itemName";
    }

    if (arrObj) {
      arrObj.sort((a: any, b: any) => {
        if (ignoreCase) {
          if (a[field] < b[field]) {
            return -1;
          } else if (a[field] > b[field]) {
            return 1;
          } else {
            return 0;
          }
        } else {
          if (a[field].toLowerCase() < b[field].toLowerCase()) {
            return -1;
          } else if (a[field].toLowerCase() > b[field].toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
    return arrObj;
  }

  getrouteMenu() {
    let obj = {};
    for (var prop in this.routeMenu) {
      obj[prop] = this.routeMenu[prop];
    }
    return obj;
  }
  getMenuName(page: string) {
    let menuName: string;
    if (this.routeMenu) {
      if (this.routeMenu["page"] == page) {
        menuName = this.routeMenu["menuName"];
      }
    }
    if (!menuName) {
      menuName = this.appsettings.getPageMenuName(page);
    }
    return menuName;
  }
  setRouteMenu(menuName: string, page: string) {
    this.routeMenu = {
      page: page,
      menuName: menuName,
    };
  }
  getObjParam(receiver: string) {
    if (this.routeParam && this.routeParam.receiver == receiver) {
      return this.routeParam.param;
    }
  }
  getMenuNamePage(menuName: string) {
    return this.appsettings.getMenuNamePage(menuName);
  }
  jquerydatepicker() {
    var dtpickerItem = $(".datepicker");
    var prm: any = {};
    prm.dateFormat = "dd-mm-yy";
    prm.changeMonth = true;
    prm.changeYear = true;
    //console.log("my column");
    for (var i = 0; i < dtpickerItem.length; i++) {
      var element = dtpickerItem[i];
      if (element.attributes.minDate) {
        prm.minDate = element.attributes.minDate.value;
      }
      if (element.attributes.maxDate) {
        prm.maxDate = element.attributes.maxDate.value;
      }
      dtpickerItem.datepicker(prm);
    }
  }
  setbsDatePickerConfig() {
    this.bsDatepickerConfig = Object.assign(
      {},
      {
        dateInputFormat: "DD-MMM-YYYY",
        showWeekNumbers: false,
        rangeInputFormat: "DD-MMM-YYYY",
        minDate: this.defaultMinDate,
        maxDate: new Date(),
      }
    );
  }
  getBsDatePickerSetMinDate() {
    return this.getBsDatePickerConfig(true, new Date("01-Jan-1900"), false);
  }

  getBsDatePickerConfig(
    setDefaultMinDate: boolean = true,
    defaultMinDate: Date = undefined,
    setDefaultMaxDate: boolean = true,
    defaultMaxDate: Date = undefined
  ) {
    let bsDatepickerConfig: Partial<BsDatepickerConfig> = Object.assign(
      {},
      {
        dateInputFormat: "DD-MMM-YYYY",
        showWeekNumbers: false,
        rangeInputFormat: "DD-MMM-YYYY",
      }
    );
    if (setDefaultMinDate) {
      bsDatepickerConfig.minDate = defaultMinDate || new Date("01-Jan-1900");
    }
    if (setDefaultMaxDate) {
      bsDatepickerConfig.maxDate = defaultMaxDate || new Date();
    }
    return bsDatepickerConfig;
  }
  formatDateToDDMMMYY(val: Date) {
    let datepipe = new DatePipe("en");
    return datepipe.transform(val, "dd-MMM-yyyy");
  }

  formatDateToDDMMMYYHHMMSS(val: Date) {
    let datepipe = new DatePipe("en-IN");
    return datepipe.transform(val, 'dd-MMM-yyyy HH:mm:ss');
  
  }
  formateNumberD0(val : number) {
    if (val != undefined) {
      let npipe = new DecimalPipe("en-IN");
      return npipe.transform(val, "1.0-0");
    }
  }
  formateNumberD2(val : number) {
    if (val != undefined) {
      let npipe = new DecimalPipe("en-IN");
      return npipe.transform(val, "1.2-2");
    }
  }
  formatDateToDDMMYY(val: Date) {
    let datepipe = new DatePipe("en");
    return datepipe.transform(val, "dd-MM-yyyy");
  }
  copyObject(obj: Object) {
    return JSON.parse(JSON.stringify(obj));
  }

  copyFullObject(obj: Object) {
    let newobj: any = undefined;
    if (obj) {
      if (obj instanceof Array) {
        newobj = [];
      } else {
        newobj = {};
      }

      Object.keys(obj).forEach((keyval) => {
        if (obj[keyval] instanceof Date) {
          if (obj[keyval]) {
            newobj[keyval] = new Date(obj[keyval]);
          }
        } else if (obj[keyval] instanceof File) {
          if (obj[keyval]) {
            newobj[keyval] = Object.assign(obj[keyval]);
          }
        } else if (typeof obj[keyval] == "object") {
          newobj[keyval] = this.copyFullObject(obj[keyval]);
        } else if (Array.isArray(obj[keyval])) {
          let arr = new Array();
          let aritm: Array<any> = obj[keyval];
          aritm.forEach((itm) => {
            if (itm instanceof Date) {
              arr.push(new Date(itm));
            } else if (isObject(itm)) {
              arr.push(this.copyFullObject(itm));
            } else {
              arr.push(new Date(itm));
            }
          });
        } else {
          newobj[keyval] = obj[keyval];
        }
      });
    }
    return newobj;
  }
  simpleMessage(objSimpleMsg: SimpleMessage) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.size = "lg";
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRef.componentInstance.title = objSimpleMsg.title;
    modalRef.componentInstance.message = objSimpleMsg.message;
    modalRef.componentInstance.btnCLass = objSimpleMsg.btnClass;
    modalRef.result.then(
      (result) => {
        if (objSimpleMsg.fnResult) {
          objSimpleMsg.fnResult();
        }
      },
      (reason) => {
        if (objSimpleMsg.fnReason) {
          objSimpleMsg.fnReason();
        }
      }
    );
  }

  isDateSame(date1: Date, date2: Date) {
    if (date1 && !date2) {
      return false;
    } else if (!date1 && date2) {
      return false;
    } else if (!date1 && !date2) {
      return true;
    } else if (
      this.formatDateToDDMMMYY(date1) == this.formatDateToDDMMMYY(date2)
    ) {
      return true;
    }
    return false;
  }

  marsResponseErrorMessage(objMsg: MARSResponseErrorMsg) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.windowClass = "xlModal";

    const modalRef = this.ngbModalService.open(
      MarsErrorMsgComponent,
      ngmodaloptions
    );
    modalRef.componentInstance.forEntity = objMsg.forEntity;
    modalRef.componentInstance.errorMsg = objMsg.errorMsg;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  UploadErrorsMessage(objMsg : UploadErrorMsg) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.windowClass = "xlModal";

    const modalRef = this.ngbModalService.open(
      UploadErrListComponent,
      ngmodaloptions
    );
    modalRef.componentInstance.errors = objMsg.errors;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }
  
  validateNumber(obj) {
    if (obj.value) {
      if (obj.min) {
        if (+obj.value < +obj.min) {
          this.rateAlertMessage(obj);
        }
      }
      if (obj.max) {
        if (+obj.value > +obj.max) {
          this.rateAlertMessage(obj);
        }
      }
    }
  }

  rateAlertMessage(obj) {
    const msg = this.getRateToolTip(obj);

    let ngmodaloptions: NgbModalOptions = {};
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRef.componentInstance.title = "Alert";
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.btnCLass = "btn-warning";

    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    modalRef.result.then(
      (result) => {
        obj.focus();
      },
      (reason) => {
        obj.focus();
      }
    );
  }

  getRateToolTip(obj) {
    let tooltip: string = undefined;
    if (obj.min && obj.max) {
      tooltip = "value should be between " + obj.min + " & " + obj.max + "";
    } else if (obj.min) {
      tooltip = "value should be more than or equal to " + obj.min + " ";
    } else if (obj.max) {
      tooltip = "value should be less than or equal to " + obj.max + "";
    }
    return tooltip;
  }
  invalidNumberAlertMsg(obj) {
    let ngmodaloptions: NgbModalOptions = {};
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRef.componentInstance.title = "Alert";
    modalRef.componentInstance.message = "invalid number!";
    modalRef.componentInstance.btnCLass = "btn-warning";

    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    modalRef.result.then(
      (result) => {
        obj.focus();
      },
      (reason) => {
        obj.focus();
      }
    );
  }
  validateDate(obj) {
    if (obj.value) {
      const enteredDate = new Date(obj.value);
      if (obj.min) {
        const minDate = new Date(obj.min);
        if (enteredDate < minDate) {
          this.dateAlertMessage(obj);
        }
      }
      if (obj.max) {
        const maxDate = new Date(obj.max);
        if (enteredDate > maxDate) {
          this.dateAlertMessage(obj);
        }
      }
    }
  }
  dateAlertMessage(obj) {
    const msg = this.getDateToolTip(obj);

    let ngmodaloptions: NgbModalOptions = {};
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRef.componentInstance.title = "Alert";
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.btnCLass = "btn-warning";

    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    modalRef.result.then(
      (result) => {
        obj.focus();
      },
      (reason) => {
        obj.focus();
      }
    );
  }

  getDateToolTip(obj) {
    let tooltip: string = undefined;
    if (obj.min && obj.max) {
      tooltip = "value should be between " + obj.min + " & " + obj.max + "";
    } else if (obj.min) {
      tooltip = "value should be more than or equal to " + obj.min + " ";
    } else if (obj.max) {
      tooltip = "value should be less than or equal to " + obj.max + "";
    }
    return tooltip;
  }
  invalidDateAlertMsg(obj) {
    let ngmodaloptions: NgbModalOptions = {};
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRef.componentInstance.title = "Alert";
    modalRef.componentInstance.message = "invalid date!";
    modalRef.componentInstance.btnCLass = "btn-warning";

    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    modalRef.result.then(
      (result) => {
        obj.focus();
      },
      (reason) => {
        obj.focus();
      }
    );
  }

  phoneValidationMsg() {
    return "10 digits only";
  }
  phoneNumberPattern() {
    return "^[0-9]{10}$";
  }
  gstValidationMsg() {
    return "Require format 2 digits, 4 alphabets, 4 digits, 1 alphabet, 1 digit, 1 alphabet and 1 digit!";
  }
  panValidationMsg() {
    return "Require format 5 alphabets, 4 digits and 1 alphabet!";
  }

  aadhaarValidationMsg() {
    return "Require format is 12 digits only!";
  }

  nationalityPattern() {
    return "^[a-zA-z]+([\\s][a-zA-Z]+)*$";
  }
  namePattern() {
    return "^[a-zA-Z]+(([a-zA-Z0-9])*([\\s\\.])*([a-zA-Z0-9])*(\\.)*)*$";
  }
  userNamePattern() {
    return "^[a-zA-Z]+(([a-zA-Z0-9])*([\\s\\.\\-_])*([a-zA-Z0-9])*(\\.\\-_)*)*$";
  }
  userNameValidationMsg() {
    return "Enter valid role name!";
  }
  nameValidationMsg() {
    return "Enter valid name!";
  }
  roleNamePattern() {
    return "^[a-zA-Z]+(([a-zA-Z0-9])*([\\s\\.\\-_])*([a-zA-Z0-9])*(\\.\\-_)*)*$";
  }
  roleNameValidationMsg() {
    return "Enter valid role name!";
  }
  emailPattern() {
    return "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  }
  emailValidationMsg() {
    return "enter correct email format text";
  }
  accountNumberPattern() {
    return "[0-9]{9,18}";
  }
  accountNumberValidationMsg() {
    return "enter valid account number";
  }
  patternAlphaNumericWithSomeSpChar() {
    return "^[a-zA-Z0-9-_()\@\\.,&\\s]+$";
  }
  alphaNumericWithSomeSpCharValidationMsg() {
    return "Allowed pattern - alphanumeric with some special characters (()-.@space";
  }

  patternIfscCode() {
    return "[A-Za-z]{4}[0][\\d]{6}$";
  }

  ifscCodeValidationMsg() {
    return "Enter valid ifsc code";
  }

  micrCodePattern() {
    return "^[A-Za-z0-9]*$";
  }

  micrCodeValidationMsg() {
    return "Enter valid MICR Code";
  }
  branchNamePattern() {
    return "^[a-zA-Z]+(([a-zA-Z0-9-])*([\\s\\.,])*([a-zA-Z0-9])*(\\.)*)*$";
  }

  branchNameValidationMsg() {
    return "Enter valid branch name";
  }
  cityNamePattern() {
    return "^[a-zA-Z]+(([a-zA-Z])*([\\s])*([a-zA-Z])*)*$";
  }

  cityNameValidationMsg() {
    return "Enter valid city name";
  }

  stateNamePattern() {
    return "^[a-zA-Z]+(([a-zA-Z])*([\\s])*([a-zA-Z])*)*$";
  }
  stateCodeValidationMsg() {
    return "Enter valid State Name";
  }

  pinCodePattern() {
    return "[0-9]{6}";
  }
  pinCodeValidationMsg() {
    return "Enter valid pincode";
  }
  patternMCCCode() {
    return "^\\d+$";
  }
  mccCodeValidationMsg() {
    return "Enter valid mcc code";
  }
  patternPersonName() {
    return "^[A-Za-z]([A-Za-z-\\.\\s]*)*$";
  }
  personNameValidationMsg() {
    return "Enter valid name";
  }
  patternEmpCodePattern() {
    return "^[A-Za-z0-9]([A-Za-z0-9-]*)[A-Za-z0-9]$";
  }
  empCodePatternValidationMsg() {
    return "Enter valid emp code";
  }
  patternMobileNumber() {
    return "^[0-9]{10}$";
  }
  mobileNumberValidationMsg() {
    return "Please enter 10 digit valid mobile number";
  }
  patternJobTitle() {
    return "^[A-Za-z]([A-Za-z0-9-\\s\\.]*)[A-Za-z]$";
  }
  jobTitleValidationMsg() {
    return "Enter valid job title";
  }
  patternDesignation() {
    return "^[A-Za-z]([A-Za-z0-9-\\s\\.]*)[A-Za-z]$";
  }
  designationValidationMsg() {
    return "Enter valid designation";
  }
  patternDepartment() {
    return "^[A-Za-z]([A-Za-z0-9-\\s\\.]*)[A-Za-z]$";
  }
  departmentValidationMsg() {
    return "Enter valid job title";
  }

}
