import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { of } from "rxjs/observable/of";
import { PreloadingStrategy, Route } from "@angular/router";
import { environment} from './../../../environments/environment'
@Injectable()
export class AppSettings implements PreloadingStrategy {
  private _config: Object;
  private _menupagemap: object;
  private _privileges: String[];
  constructor(private http: HttpClient) {
    //this.load();
  }
  preload(route: Route, load: Function): Observable<any> {
    return route.data && route.data.preload ? load() : of(null);
  }

  load() {
    return new Promise((resolve, reject) => {
      //console.log("assets/config/" + environment.env_text + "-config.json");
          this.http
            .get("assets/config/" + environment.env_text + "-config.json")
            .map(res => res)
            .catch((error: any) => {
              console.error(error);
              return Observable.throw(error.json().error || "Server error");
            })
            .subscribe(data => {
              this._config = data;
              this.http
                .get("assets/config/menu-page-map.json")
                .map(res => res)
                .catch((error: any) => {
                  console.log(error);
                  return Observable.throw(
                    error.json().error || "Error in menu-page-mapping load"
                  );
                })
                .subscribe(data => {
                  this._menupagemap = data;
                  resolve(true);
                });
            });
    });
  }
  getEnv(key: any) {
    return environment.env_text;
  }
  get(key: any) {
    if (this._config) {
      return this._config[key];
    }
  }
  getMenuPage(menu: string) {
    return this._menupagemap[menu];
  }
  getPageMenuName(page: string) {
    let menuName: string;
    for (var menu in this._menupagemap) {
      if (this._menupagemap[menu] == page) {
        return menu;
      }
    }
  }
  getMenuNamePage(menuName: string) {
    let page: string = null;
    page = this._menupagemap[menuName];
    return page;
  }
  getPrivileges(key: string) {
    return this._privileges[key];
  }
  getAllPrivileges() {
    return this._privileges;
  }
  isPrivExists(key: string) {
    return this._privileges ? (this._privileges[key] ? true : false) : false;
  }
}
