import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpInterceptor,
  HttpHandler,
  HttpHeaders,
  HttpResponse
} from "@angular/common/http";
import { global } from "../modules/global";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { AppSettings } from '../config/AppSettings';

@Injectable()
export class XhrInterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private objGlobal: global,
    private authservice: AuthService,
    private router: Router,
    private appSettings : AppSettings
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let xhr: HttpRequest<any>;

    xhr = this.addAuthenticationToken(req);
    return next
      .handle(xhr)
      .map(event => {
        if (event instanceof HttpResponse) {
          let resheader = {};
          let keys: string[] = event.headers.keys();

          for (let i = 0; i <= keys.length; i++) {
            //console.log("key is " + keys[i]);
            if (keys[i] && keys[i] != "Transfer-Encoding") {
              resheader[keys[i]] = event.headers.get(keys[i]);
            }
          }
          //console.log(resheader);
          event = event.clone({
            headers: new HttpHeaders(resheader)
          });
        }
        return event;
      })
      .catch(error => {
        if (
          xhr.url.includes("/api/token/refreshtoken") ||
          xhr.url.includes("/api/token/generate-token")
        ) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page

          if (xhr.url.includes("/api/token/refreshtoken")) {
            this.authservice.logout();
            this.router.navigate(["login"]);
          }

          return Observable.throw(error);
        }
        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status !== 401) {
          return Observable.throw(error);
        }
        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject
            .filter(result => result !== null)
            .take(1)
            .switchMap(() => next.handle(this.addAuthenticationToken(req)));
        } else {
          this.refreshTokenInProgress = true;

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);

          // Call auth.refreshAccessToken(this is an Observable that will be returned)
          return this.authservice
            .refreshAccessToken()
            .switchMap((token: any) => {
              //When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(token);

              return next.handle(this.addAuthenticationToken(xhr));
            })
            .catch((err: any) => {
              this.refreshTokenInProgress = false;

              this.authservice.logout();
              this.router.navigate(["login"]);
              return Observable.throw(error);
            });
        }
      });
  }

  addAuthenticationToken(req) {
    const reqUrl: string = req.url;
    const serverUrl = this.appSettings.get("server-url");
    const serverNewUrl = this.appSettings.get("server-new_url");
    let isEzbotApi = true;
    let isEzbotNewApi = true;
    console.log("Request URL is "+reqUrl);
    if (serverUrl ||serverNewUrl) {
      isEzbotApi = reqUrl.includes(this.appSettings.get("server-url"));
      isEzbotNewApi = reqUrl.includes(this.appSettings.get("server-new_url"));
    }
    
    let xheaders = {};
    if (req.headers && req.headers.get("Content-Type")) {
      xheaders["Content-Type"] = req.headers.get("Content-Type");
    }
    /*if (req.headers && req.headers.get("Access-Control-Allow-Origin")) {
      xheaders["Access-Control-Allow-Origin"] = req.headers.get("Access-Control-Allow-Origin");
    }*/
    //console.log("API URL is "+isEzbotApi);
    //console.log("New API URL is "+isEzbotNewApi);
    if (this.objGlobal.Auth.token && (isEzbotApi || isEzbotNewApi)) {
      /*if (!this.authservice.loggedin()) {
        this.router.navigate(["login"]);
      }*/
    //  xheaders["Origin"] = "http://localhost:4200"
      xheaders["Authorization"] = "JWT-" + this.objGlobal.Auth.token; 
    }
    return req.clone({ headers: new HttpHeaders(xheaders) });
  }

}
