import { Component, OnInit,HostListener} from '@angular/core';
import {AuthService} from '../../framework/services/auth.service';
import { Router } from '@angular/router';
import {ImageconfigService} from '../../framework/services/imageconfig.service';
import {global} from '../../framework/modules/global';
import {MenuService} from '../../framework/services/menu.service';
import { AppSettings } from '../../framework/config/AppSettings';
import {Tools} from '../../framework/modules/tools';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  private rootmenuwidth : string;
  private rootmenumargin : string;
  constructor(private auth:AuthService, private router: Router,
    public imagePath:ImageconfigService, public objGlobal : global,
    public menuservice : MenuService, private appsetting : AppSettings,
    private tools: Tools) { 
      if(!this.auth.privileges) {
        auth.loadPrivileges().then(()=> {
          if(!this.menuservice.menusList) {
        
            menuservice.loadMenus().then(()=>{
              this.setRootMenus();
            })
          } else {
            this.setRootMenus();
          }  
        })
      } else {
        this.setRootMenus();
      }  
  
    }
  setRootMenus() {
    if(this.menuservice.menusList) {
      if (this.menuservice.menusList.length > 1) {
        let width = 80/this.menuservice.menusList.length;
        if(width >15){width=15}
        this.rootmenuwidth = width + '%'
      } else {
        this.rootmenuwidth = "15%"        
      }
    } 
  }   
  getImageUrl(menu) {
    let imgfile : string = menu.imagefile;
    if(!imgfile) {
      imgfile = menu.name + ".png";
    }
    return "url(assets/images/" + imgfile + ")";
  }

  ngOnInit() {
   
  }
  onLogoutClick(){
    
    this.auth.logout();
    //this.flashmessage.show("You are logged out successfully",{cssClass : "alert-danger",  timeout : 5000});
    this.router.navigate(['login']);    
    return false;
  }
  onMenuClick(menuName :  string,page : string) {
    this.tools.setRouteMenu(menuName,"user");
    if (this.appsetting.getMenuPage(menuName)=="user") {
        this.router.navigate(['user']);
    } else if(this.appsetting.getMenuPage(menuName)=="role") {
      this.router.navigate(['role']);
    }
  }
  getKeyFromSource() {
    
  }
  @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event) {
    this.auth.logout();
}
}
