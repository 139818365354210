export class MasterPricingPlanModel {
  id?: number;
  description?: string;
  merchantType?: string;
  merchantCategory?: string;
}

export class PricingPlanSlabModel {
  id?: number;
  masterPricingPlanId?: number;
  code?: string;
  description?: string;
  merchantCategory?: string;
}

export class mppDataModel {
  id?:any;
  merchantType?: any;
  merchantCategory?: any;
  description?: any;
  mccCodes?: any;
  domesticCardUpto2kOnus?: any;
  domesticCardUpto2kOffus?: any;
  domesticCardGreaterThan2kOnus?: any;
  domesticCardGreaterThan2kOffus?:any;
  creditCardStandardOnus?: any;
  creditCardPremiumOffus?: any;
  allInternationalCards?: any;
  commercialCardMDR?: any;
  remarks?: any;
  mdrPlanTransRates?:any;
}
