import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { Tools } from "../framework/modules/tools";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";

@Injectable()
export class TerminalReportService {
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}

  getTerminaActivationReport(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    //httpParam=httpParam.append("searchText", searchField.searchText);
    if (searchField.activationDate) {
      if (searchField.activationDate[0]) {
        httpParam = httpParam.append(
          "activationDateFrom",
          this.tools.formatDateToDDMMMYY(searchField.activationDate[0])
        );
      }
      if (searchField.activationDate[1]) {
        httpParam = httpParam.append(
          "activationDateTill",
          this.tools.formatDateToDDMMMYY(searchField.activationDate[1])
        );
      }
    }
    if (searchField.terminalCategory && searchField.terminalCategory != "") {
      httpParam = httpParam.append(
        "terminalType",
        searchField.terminalCategory
      );
    }
    if (searchField.productId && searchField.productId != "") {
      httpParam = httpParam.append("productId", searchField.productId);
    }
    if (searchField.activationStatus && searchField.activationStatus != "") {
      httpParam = httpParam.append(
        "activationStatus",
        searchField.activationStatus
      );
    }

    if (
      searchField.aggregatorId != undefined &&
      searchField.aggregatorId != ""
    ) {
      httpParam = httpParam.append("aggregatorId", searchField.aggregatorId);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/terminalactivationreports",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }
}
