import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { UserService } from "../../services/user.service";
import { Tools } from "../../modules/tools";
//import {Router} from '@angular/router';
import { UserComponent } from "../user/user.component";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
import { TopbarText } from "../topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { UserModel } from "../../models/user.model";
import { ExporttocsvService } from "../../services/exporttocsv.service";
import { SimpleMessage } from "../../modules/util.class";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
import { UserroleService } from "../../services/userrole.service";
@Component({
  selector: "app-userlist",
  templateUrl: "./userlist.component.html",
  styleUrls: ["./userlist.component.css"]
})
export class UserlistComponent implements OnInit, AfterViewInit {
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Emp Id",
      columnFieldName: "username",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Name",
      columnFieldName: "name",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Email",
      columnFieldName: "userEmail",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Distributor/White Level",
      columnFieldName: "distributorName",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Role",
      columnFieldName: "roleName",
      columnClasses: "c-col-pc-25"
    }
  ];
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  @ViewChild(UserComponent, { static: true }) userComp: UserComponent;
  users: Array<object>;
  page: GridPage;

  _menuId: string;
  _action: string;
  _userParam: any;
  isUserDetailScreen = true;
  searchField = {
    searchText: "",
    distributorId: "",
    roleId: 0
  };
  topbartext: TopbarText[] = [
    { key: "usermanagement", displayText: "User Management" }
  ];
  _username: string = "";
  roleList: [];
  constructor(
    private objuserservice: UserService,
    private tools: Tools,
    private ngbModalService: NgbModal,
    private objGlobal: global,
    private auth: AuthService,
    private csvservice: ExporttocsvService,
    public aggregatordistributorservice: AggregatorDistributorService,
    private objuserroleservice: UserroleService
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/user");
    //console.log(this._menuId);
    this.objGlobal.setActiveMenu(this._menuId);
    this.getRoleIdName();
    //this.getUserList();
  }

  ngOnInit() {
    this.isUserDetailScreen = false;
  }
  ngAfterViewInit() {}
  getUser(event: any) {
    this.getUserList();
  }
  selectUser(event: any) {}

  getUserList() {
    this.objuserservice.getUserList(this.page, this.searchField).subscribe(
      data => {
        if (data["success"]) {
          this.users = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
        } else {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get Users!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      },
      err => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to get Users!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }
  getRoleIdName() {
    this.objuserroleservice.getRoleIdName().subscribe(data => {
      if (data["success"]) {
        this.roleList = data["result"];
      } else {
        alert("Failed to get role id and name list!");
      }
    });
  }
  onUserRowDblClick(userlistval: any) {
    this._action = "update";
    this._username = userlistval.userId;
    this._userParam = { id: userlistval.userId };
    this.isUserDetailScreen = true;
    //this.router.navigate(['user']);
    this.userComp.getUserDetail(this._userParam, this._action);
  }
  onAddNew() {
    this._action = "new";
    this.isUserDetailScreen = true;
    this.userComp.addNewUser();
  }
  onCancel(obj: any) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isUserDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
    }
  }
  /*onCancel(obj) {
  if(obj.isModified) {
    this.confirmService.confirm({title : 'Confirmation', message : 'User has been modified. Are you sure to exit without save?'})
    .then(
      ()=> {
        this._action="";
        this.isUserDetailScreen=false;
      }
      )
  } else {
    this._action="";
    this.isUserDetailScreen=false;
  }
}*/
  onBackClick(obj) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "User detail has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isUserDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isUserDetailScreen = false;
    }
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    //console.log("is priv " + this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  deleteUser(recObj: any) {
    const objUser: UserModel = recObj.obj;
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to inactive user?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          //console.log("test delete user");
          this.objuserservice.deleteUser(objUser.userId).subscribe(data => {
            if (data["success"]) {
              const modalRefOk = this.ngbModalService.open(
                NgInformationModalContent,
                ngmodaloptions
              );
              modalRefOk.componentInstance.title = "Information";
              modalRefOk.componentInstance.message =
                "User marked inactive successfully!";
              modalRefOk.componentInstance.btnCLass = "btn-info";
              modalRefOk.result.then(
                result => {
                  this.getUserList();
                },
                reason => {
                  this.getUserList();
                }
              );
            } else {
              alert("Failed to delete user!");
            }
          });
        }
      },
      reason => {}
    );
  }

  getUserListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;

    this.objuserservice.getUserList(page, this.searchField).subscribe(
      data => {
        let obj: Array<UserModel>;
        if (data["success"]) {
          obj = data["result"];
          this.csvservice.exportToCsv(obj, this.gridcols, undefined, "user");
        } else {
          alert("Failed to get user list!");
        }
      },
      error => {
        alert("Failed to get user list!");
      }
    );
  }
  onExcelClick() {
    this.getUserListForExcel();
  }
}
