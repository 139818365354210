import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ForgetpasswordComponent } from "./components/forgetpassword/forgetpassword.component";
import { LoginComponent } from "./components/login/login.component";
import { MainComponent } from "./framework/components/main/main.component";
import { AuthGuard } from "./framework/guards/auth.guard";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { AggregatorDistributorListComponent } from "./components/aggregator-distributor-list/aggregator-distributor-list.component";
import { MerchantPreboardingComponent } from "./components/merchant-preboarding/merchant-preboarding.component";
import { MerchantlistComponent } from "./components/merchantlist/merchantlist.component";
import { SimstockComponent } from "./components/simstock/simstock.component";
import { DevicestockComponent } from "./components/devicestock/devicestock.component";
import { DevicereturnComponent } from "./components/devicereturn/devicereturn.component";
import { SimreturnComponent } from "./components/simreturn/simreturn.component";
import { IndentComponent } from "./components/indent/indent.component";
import { MDRPlanComponent } from "./components/mdrplan/mdrplan.component";
import { RentalPlanComponent } from "./components/rental-plan/rental-plan.component";
import { EmployeeComponent } from "./components/employee/employee.component";
import { BankIfscComponent } from "./components/bank-ifsc/bank-ifsc.component";
import { MCCComponent } from "./components/mcc/mcc.component";
import { PincodeComponent } from "./components/pincode/pincode.component";
import { DevicebillingsummaryComponent } from "./components/devicebillingsummary/devicebillingsummary.component";
import { TerminalActivationReportComponent } from "./components/terminal-activation-report/terminal-activation-report.component";
import { UserlistComponent } from "./framework/components/userlist/userlist.component";
import { UserroleComponent } from "./framework/components/userrole/userrole.component";
import { ChangepasswordComponent } from "./components/changepassword/changepassword.component";
import { TransreportComponent } from "./reports/components/transreport/transreport.component";
import { BankmerchantpreboardComponent } from "./components/bankmerchantpreboard/bankmerchantpreboard.component";
import { AppSettings } from "./framework/config/AppSettings";
import { DeviceStockSummaryComponent } from "./components/device-stock-summary/device-stock-summary.component";
import { MasterPricingPlanComponent } from "./components/master-pricing-plan/master-pricing-plan.component";
import { ActiveterminalreportComponent } from "./components/activeterminalreport/activeterminalreport.component";
import { ActiveTerminalSummaryReportComponent } from "./components/active-terminal-summary-report/active-terminal-summary-report.component";
import { TerminalActivationSummaryReportComponent } from "./components/terminal-activation-summary-report/terminal-activation-summary-report.component";
import { TerminalDeactivationMessageComponent } from "./components/terminal-deactivation-message/terminal-deactivation-message.component";
import { TerminalDeactivationSummaryReportComponent } from "./components/terminal-deactivation-summary-report/terminal-deactivation-summary-report.component";
import { TerminalwiseSettlementReportComponent } from "./components/terminalwise-settlement-report/terminalwise-settlement-report.component";
import { SettlementSummaryReportComponent } from './components/settlement-summary-report/settlement-summary-report.component';
import { MerchantProfileComponent } from './components/merchant-profile/merchant-profile.component';
import { ProductService } from './services/product.service';
import { ProductComponent } from './components/product/product.component';
import { SupplierComponent } from './components/supplier/supplier.component';
import { UpitransreportComponent } from './reports/components/upitransreport/upitransreport.component';
import { UpiMerchantUploadComponent } from './components/upi-merchant-upload/upi-merchant-upload.component';
import { CanDeactivateGuard } from './framework/guards/can-deactivated-guard';
import { MerchantUploadHistoryComponent } from './components/merchant-upload-history/merchant-upload-history.component';
import { SourcingBranchComponent } from './components/sourcing-branch/sourcing.branch.component';
import { StaticeQrCodesComponent } from './components/statice-qr-codes/statice-qr-codes.component';
import { TerminallistReportComponent } from './reports/components/terminallist-report/terminallist-report.component';
import { SettlementReportsComponent } from './reports/components/settlement-reports/settlement-reports.component';
import { BoardingDashboardComponent } from "./components/boarding-dashboard/boarding-dashboard.component";
import { MerchantSettlementReportComponent } from "./components/merchant-settlement-report/merchant-settlement-report.component";
import { MerchantSettlementSummaryReportComponent } from "./components/merchant-settlement-summary-report/merchant-settlement-summary-report.component";
import { MerchantPortalSettlementSummaryComponent } from "./components/merchant-portal-settlement-summary/merchant-portal-settlement-summary.component";
import { RevenueSummaryComponent } from "./components/revenue-summary/revenue-summary.component";
import { DatewiseTransactionSummaryComponent } from "./components/datewise-transaction-summary/datewise-transaction-summary.component";
import { TransactionSummaryComponent } from "./components/transaction-summary/transaction-summary.component";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "forgotpassword", component: ForgetpasswordComponent },
  {path: "changepassword",component: ChangepasswordComponent},
  { path: "login", component: LoginComponent },
  {
    path: "main",
    component: MainComponent,
    canActivate: [AuthGuard], canActivateChild : [AuthGuard],
    children: [
      { path: "", component: DashboardComponent },
      {
        path: "dashboard",
        component: DashboardComponent
      },
      {
        path: "aggregator",
        component: AggregatorDistributorListComponent,
        canDeactivate : [CanDeactivateGuard]
      },
      {
        path: "supermerchant",
        component: AggregatorDistributorListComponent,
        canDeactivate : [CanDeactivateGuard]
      },
      {
        path: "distributor",
        component: AggregatorDistributorListComponent,
        canDeactivate : [CanDeactivateGuard]
      },
      {
        path: "merchantpreboarding",
        component: MerchantPreboardingComponent
      },
      {
        path: "merchant",
        component: MerchantlistComponent
      },
      {
        path: "upi-merchant-upload",
        component: UpiMerchantUploadComponent
      },
      {
        path: "devicestock",
        component: DevicestockComponent
      },
      {
        path: "simstock",
        component: SimstockComponent
      },
      {
        path: "devicereturn",
        component: DevicereturnComponent
      },
      {
        path: "simreturn",
        component: SimreturnComponent
      },
      { path: "indent", component: IndentComponent},
      {
        path: "mdrplan",
        component: MDRPlanComponent
      },
      {
        path: "rentalplan",
        component: RentalPlanComponent
      },
      {
        path: "employee",
        component: EmployeeComponent
      },
      { path: "ifsc", component: BankIfscComponent},
      { path: "mcc", component: MCCComponent},
      {
        path: "pincode",
        component: PincodeComponent
      },
      {
        path: "product",
        component: ProductComponent
      },
      {
        path: "supplier",
        component: SupplierComponent
      },
      {
        path: "devicebillingsummaryreport",
        component: DevicebillingsummaryComponent
      },
      {
        path: "terminalactivationreport",
        component: TerminalActivationReportComponent
      },
      {
        path: "devicestocksummary",
        component: DeviceStockSummaryComponent
      },
      { path: "user", component: UserlistComponent },
      { path: "role", component: UserroleComponent },
      {
        path: "changepassword",
        component: ChangepasswordComponent
      },
      {
        path: "transactionreport",
        component: TransreportComponent
      },
      {
        path: "bankmerchantperboard",
        component: BankmerchantpreboardComponent
      },
      {
        path: "bankportal-merchantmanagement",
        component: BankmerchantpreboardComponent
      },
      {
        path: "distributorportal-merchantmanagement",
        component: BankmerchantpreboardComponent
      },
      {
        path: "masterpricingplan",
        component: MasterPricingPlanComponent
      },
      {
        path: "activeterminalreport",
        component: ActiveterminalreportComponent
      },
      {
        path: "bankportal-activeterminalreport",
        component: ActiveterminalreportComponent
      },
      {
        path: "activeterminalsummaryreport",
        component: ActiveTerminalSummaryReportComponent
      },
      {
        path: "terminalactivationsummaryreport",
        component: TerminalActivationSummaryReportComponent
      },
      {
        path: "terminaldeactivationsummaryreport",
        component: TerminalDeactivationSummaryReportComponent
      },
      {
        path: "terminalwisesettlementreport",
        component: TerminalwiseSettlementReportComponent
      },
      {
        path: "settlementreport",
        component: SettlementReportsComponent
      },
      {
        path: "bankportal-settlementreport",
        component: SettlementReportsComponent
      },
      {
        path: "distributorportal-settlementreport",
        component: SettlementReportsComponent
      },
      {
        path: "bankportal-terminalwisesettlementreport",
        component: TerminalwiseSettlementReportComponent
      },
      {
        path: "distributorportal-terminalwisesettlementreport",
        component: TerminalwiseSettlementReportComponent
      },
      {
        path: "settlementsummaryreport",
        component: SettlementSummaryReportComponent
      },
      {
        path: "merchantprofile",
        component: MerchantProfileComponent
      },
      {
        path: "cardtransactionreport",
        component: TransreportComponent
      },
      {
        path: "bankportal-cardtransactionreport",
        component: TransreportComponent
      },
      {
        path: "distributorportal-cardtransactionreport",
        component: TransreportComponent
      },
      {
        path: "upitransactionreport",
        component: UpitransreportComponent
      },
      {
        path: "bankportal-upitransactionreport",
        component: UpitransreportComponent
      },
      {
        path: "distributorportal-upitransactionreport",
        component: UpitransreportComponent
      },
      {
        path: "merchantuploadhistory",
        component: MerchantUploadHistoryComponent
      },
      {
        path: "sourcingbranch",
        component: SourcingBranchComponent
      },
      {
        path: "bankportal-sourcingbranch",
        component: SourcingBranchComponent
      },
      {
        path: "upistaticqrcodes",
        component : StaticeQrCodesComponent
      },
      {
        path: "bankportal-upistaticqrcodes",
        component : StaticeQrCodesComponent
      },
      {
        path: "distributorportal-upistaticqrcodes",
        component : StaticeQrCodesComponent
      },
      {
        path: "report-terminal-list",
        component : TerminallistReportComponent
      },
      {
        path: "merchant-settlement-report",
        component : SettlementReportsComponent
      },
      {
        path: "distributorportal-report-terminallist",
        component : TerminallistReportComponent
      },
      {
        path: "distributorportal-settlementreport",
        component : SettlementReportsComponent
      },
      {
        path: "distributorportal-merchant-settlementreport",
        component : MerchantSettlementReportComponent
      },
      {
        path: "distributorportal-merchant-settlementsummaryreport",
        component : MerchantSettlementSummaryReportComponent
      },
      {
        path: "report-boarding-dashboard",
        component : BoardingDashboardComponent
      },
      {
        path: "report-merchant-settlement",
        component : MerchantSettlementReportComponent
      },
      {
        path: "report-merchant-settlement-summary",
        component : MerchantSettlementSummaryReportComponent
      },
      {
        path: "merchant-portal-settlement-summary",
        component : MerchantPortalSettlementSummaryComponent
      },
      {
        path: "revenue-summary",
        component : RevenueSummaryComponent
      },
      {
        path: "datewise-transaction-summary",
        component : DatewiseTransactionSummaryComponent
      },
      {
        path: "transaction-summary",
        component : TransactionSummaryComponent
      },
      {
        path: "distributorportal-revenue-summary",
        component : RevenueSummaryComponent
      },
      { path: "**", redirectTo: "/login"},
    ],
  },
  { path: "**", redirectTo: "/login"},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppSettings,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
