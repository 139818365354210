import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgYesNoModalContent } from "../../util/components/modalcomponent/modal-content";
export class TopbarText {
  key: string;
  displayText: string;
  class?: string;
  anchorClass?: string;
}
@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.css"]
})
export class TopbarComponent implements OnInit {
  @Input()
  topbartext: TopbarText[];
  @Output()
  tabClick: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  chantVal = { selectedTabIndex: 0 };
  @Input()
  isChildTopbar: boolean = false;

  constructor(
    public objGlobal: global,
    private auth: AuthService,
    private router: Router,
    private ngbModalService: NgbModal
  ) {}

  ngOnInit() {}
  onLogoutClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";

    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to logout?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.auth.logout();
          //this.flashmessage.show("You are logged out successfully",{cssClass : "alert-danger",  timeout : 5000});
          this.router.navigate(["login"]);
          return false;
        }
      },
      reason => {}
    );
  }
  isSelectedTab(index: number) {
    //return this.chantVal.selectedTabIndex == index && this.topbartext.length>1;
    return this.chantVal.selectedTabIndex == index
  }
  onTabClick(tbtext: TopbarText, i: number) {
    if (this.topbartext.length > 1) {
      this.chantVal.selectedTabIndex = i;
      this.tabClick.emit(tbtext.key);
    }
  }
  getTopTextClass(i: number) {
    if (this.topbartext[i].class) {
      return this.topbartext[i].class;
    } else {
      return "col-md-3";
    }
  }
  getTopTextAnchorClass(i: number) {
    if (this.topbartext[i].anchorClass) {
      return this.topbartext[i].anchorClass;
    }
  }
  onChangePassword() {
    this.router.navigate(["main/changepassword"]);
  }

  getTopbarImage() {
    return this.objGlobal.topbarImage
  }
  isTopbarImage() {
    if (this.objGlobal.topbarImage) {
      return true;
    } 
    return false;
    
  }

  userLableSizeLarge() {
    let result: string = "";
    if (this.objGlobal && this.objGlobal.Auth && this.objGlobal.Auth.user) {
      if (this.objGlobal.Auth.user.length > 20) {
        result = "pl-1 pr-1 fontsize-80pc";
      } else if (this.objGlobal.Auth.user.length > 12) {
        result = "pl-2 pr-1 fontsize-90pc";
      }
    }
    return result;
  }
}
