import { Injectable } from "@angular/core";
import {SIMOperatorModel} from '../models/sim.operator.model';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {AppSettings} from '../framework/config/AppSettings';
import { throwError } from 'rxjs';

@Injectable()
export class SIMOperatorService {
    simOperatorList : SIMOperatorModel[];
    constructor(private http : HttpClient, private appsetting : AppSettings) {

    }
    loadSIMOperatorName() {        
        this.getSIMOperatorNameApi().subscribe(data => {
            if(data["success"]) {
                if(data["result"])  {
                    this.simOperatorList = data["result"];    
                } else {
                    this.simOperatorList = undefined;
                }
            } else {
                throwError(data["message"]);
                this.simOperatorList=undefined
            }           
        });
    }
    
    getSIMOperatorNameApi() {
        let header : HttpHeaders = new HttpHeaders();
        return this.http.get( this.appsetting.get("server-url") + '/api/allsimoperators',{headers : header, observe : "body", responseType : "json"})
        .map(res=>res);
    }
}