import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { global } from 'src/app/framework/modules/global';
import { Tools } from 'src/app/framework/modules/tools';
import { SimpleMessage, SortClass } from 'src/app/framework/modules/util.class';
import { AuthService } from 'src/app/framework/services/auth.service';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { gridColumns, GridPage, GridtableComponent } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-settlement-reports',
  templateUrl: './settlement-reports.component.html',
  styleUrls: ['./settlement-reports.component.css']
})
export class SettlementReportsComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "TRANSACTION DATETIME",
      columnFieldName: "txnDate",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS
    },
    {
      columnHeader: "MID",
      columnFieldName: "mid",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "TERMINAL Id",
      columnFieldName: "tid",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "LEGAL NAME",
      columnFieldName: "legalName",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "TRANSACTION TYPE",
      columnFieldName: "transactionType",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "GROSS AMOUNT",
      columnFieldName: "grossAmount",
      columnClasses: "c-col-pc-8",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "MERCHANT DISCOUNT FEES",
      columnFieldName: "merchantDiscountFees",
      columnClasses: "c-col-pc-6",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "IGST",
      columnFieldName: "merchantIGST",
      columnClasses: "c-col-pc-6",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "NET AMOUNT",
      columnFieldName: "netAmount",
      columnClasses: "c-col-pc-8",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "RRN",
      columnFieldName: "rrn",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "SETTLEMENT DATETIME",
      columnFieldName: "settleDate",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS,
    },
  ];
  sortFields = [
    { name: "txnDate", display: "Transaction DateTime" },
    { name: "mid", display: "MID" },
    { name: "tid", display: "Terminal Id" },
    { name: "legalName", display: "Legal Name" },
    { name: "transactionType", display: "Transaction Type" },
    { name: "grossAmount", display: "Gross Amount" },
    { name: "merchantDiscountFees", display: "Discount Rate" },
    { name: "merchantIGST", display: "IGST" },
    { name: "netAmount", display: "Net Amount" },
    { name: "rrn", display: "RRN" },
    { name: "settleDate", display: "Settlement DateTime" },
  ];

  topbartext: TopbarText[] = [
    {
      key: "settlementreport",
      displayText: "SETTLEMENT REPORT",
      class: "col-md-4",
      anchorClass: "h6",
    },
  ];
  searchField = {
    dateFrom: undefined,
    dateTill: undefined,
    transactionType: undefined,
    rrn : undefined,
    tid: undefined,
  };
  datalist: Array<any>;
  page: GridPage;
  _menuId: string;
  //isDetailScreen : boolean=false;

  sort: SortClass;
  constructor(public tools: Tools,
    private objGlobal: global,
    private csvservice: ExporttocsvService,
    private auth: AuthService,
    private reportService: ReportService,
    private router: Router) { 
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    this._menuId = this.tools.getMenuName(
        this.router.url.substring(1, this.router.url.length)
      );
    
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "txnDate";
    //let d = moment();
    //d = d.add(-1, "month");
    this.searchField.dateFrom = new Date();
    this.searchField.dateTill = new Date();
    this.searchField.tid = undefined;
    }

  ngOnInit() {

  }

  getReportData(opt) {
    this.reportService
      .getSettlementReport(this.page, this.searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message =
              "Failed to settlement report report!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to settlement report!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  exportSettlementReportForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();

    this.reportService
      .getSettlementReport(page, this.searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<any>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "settlementreport"
            );
          } else {
            alert("Failed to settlement report!");
          }
        },
        (error) => {
          alert("Failed to terminal wise settlement report!");
        }
      );
  }
  onExcelClick() {
    this.exportSettlementReportForExcel();
  }
  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }
}
