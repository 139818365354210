import { TransCategoryModel } from './trans.category.model';

export class TransRatesModel {
    transCategory? : TransCategoryModel=new TransCategoryModel();
    mmFixed? : number;
    mmPercent? : number;
    mmMax? : number;
    psFixed? : number;
    psPercent? : number;
    psMax? : number;
    fromAmount? : number;
    toAmount? : number;
    position? : number;
    invisible? : boolean;
}