import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../framework/config/AppSettings';
import { MerchantModel } from '../models/merchant.model';
import { Tools } from '../framework/modules/tools';
import { global } from '../framework/modules/global';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
 
export class MerchantProfileService {
  _menuId : string;
  constructor(private appsetting: AppSettings, private http: HttpClient, private tools: Tools,
      private objGlobal : global, private router: Router) { 
      this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );
    this.objGlobal.setActiveMenu(this._menuId);
  }

  getMerchantProfile() {
    let header = new HttpHeaders();
    //let httpParam: HttpParams = new HttpParams();
    //httpParam = httpParam.append("mccCode", mccCode);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/merchantprofile", {
        headers: header,
        observe: "body",
        //params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getFileFromUrl(url: string) {
    let header = new HttpHeaders();
    let httpparam: HttpParams = new HttpParams();
    httpparam = httpparam.append(
      "url",
      url
    );
    return this.http.get( this.appsetting.get("server-url") + "/api/getqr",{params : httpparam, headers : header, responseType : "blob"},)
  }

  downloadMerchantQr() {
    let header = new HttpHeaders();
    return this.http.get(this.appsetting.get("server-url") + "/api/downloadmerchantqr",{headers: header,
          responseType: "blob"})
  }

}
