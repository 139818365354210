import { Component, OnInit, ViewChild } from "@angular/core";
import { TopbarText } from "src/app/framework/components/topbar/topbar.component";
import { MerchantService } from "src/app/services/merchant.service";
import { NgYesNoModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";
import {
  GridtableComponent,
  gridColumns,
  GridPage
} from "src/app/framework/util/components/gridtable/gridtable.component";
import {
  MasterPricingPlanModel,
  PricingPlanSlabModel
} from "src/app/models/master.pricing.plan.model";
import { Tools } from "src/app/framework/modules/tools";
import { AuthService } from "src/app/framework/services/auth.service";
import { Router } from "@angular/router";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { global } from "src/app/framework/modules/global";
import { ExporttocsvService } from "src/app/framework/services/exporttocsv.service";
import { SortClass, SimpleMessage } from "src/app/framework/modules/util.class";
import { MasterPricingPlanService } from "src/app/services/master.pricing.plan.service";
import { MCCService } from "src/app/services/mcc.service";
import { MCCModel } from "src/app/models/MCC.model";
import { mppDataModel } from "src/app/models/master.pricing.plan.model";
import { TransRatesModel } from "src/app/models/trans.rate";
import { TransCategoryService } from "src/app/services/transcategory.service";

@Component({
  selector: "app-master-pricing-plan",
  templateUrl: "./master-pricing-plan.component.html",
  styleUrls: ["./master-pricing-plan.component.css"]
})
export class MasterPricingPlanComponent implements OnInit {
  topbartext: TopbarText[] = [
    { key: "masterpricingplan", displayText: "Master Pricing Plan" }
  ];
  isDetailScreen: boolean = false;
  addMPPScr:boolean=false;
  mppData:mppDataModel;
  updatebtn:boolean=false;
  savebtn:boolean=false;

  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-5" },
    {
      columnHeader: "",
      columnFieldName: "id",
      columnClasses: "c-col-pc-0"
    },
    {
      columnHeader: "Description",
      columnFieldName: "description",
      columnClasses: "c-col-pc-60"
    },
    {
      columnHeader: "Merchant Type",
      columnFieldName: "merchantType",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Merchant Category",
      columnFieldName: "merchantCategory",
      columnClasses: "c-col-pc-20",
     // callbackFunction: this.getMerchantCategory
    }
  ];

  gridcols_slabs: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-5" },
    {
      columnHeader: "",
      columnFieldName: "id",
      columnClasses: "c-col-pc-0"
    },
    {
      columnHeader: "Code",
      columnFieldName: "code",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Description",
      columnFieldName: "description",
      columnClasses: "c-col-pc-60"
    }
  ];
  searchField = {
    searchText: "",
    merchantType: "",
    merchantCategory: ""
  };
  mccDropdownSettings = {
    singleSelection: false,
    idField: "mccCode",
    textField: "description",
    allowSearchFilter: true,
    maxHeight: 150,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: "",
  };
  mcclist: Array<MCCModel>;
  selMcc: Array<MCCModel>;
  mdrPlanTransRates: Array<TransRatesModel>;
  _mdrPlanTransRates: Array<TransRatesModel>;
  sort: SortClass;
  //datalist: Array<MasterPricingPlanModel>;
  datalist: Array< mppDataModel>;
  dataMPP:any;
  datalist_slabs: Array<PricingPlanSlabModel>;
  _menuId: string;
  _id: number = undefined;
  page: GridPage;
  disableSyncButton = false;
  currMasterPricingDesicription: string;
  currMasterPricingMerchantType: string;
  currMasterPricingMerchantCategory: string;
  

  constructor(
    public tools: Tools,
    private auth: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private objGlobal: global,
    private csvservice: ExporttocsvService,
    private masterpricingplanservice: MasterPricingPlanService,
    private mccservice: MCCService,
    public transCategoryService: TransCategoryService,
  ) {
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    this._menuId = this.tools.getMenuName("main/masterpricingplan");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "description";
    this.isDetailScreen = false;
  }

  ngOnInit() {}

  isAllowSync() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>sync");
    return isAdd;
  }
  getList() {
    this.masterpricingplanservice
      .getMPPList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
      //    console.log (data)
         // if (data["success"]) {
          if (data) {
            this.dataMPP=data
            // this.datalist = data["result"].content;
            // this.page.first = data["result"].first;
            // this.page.last = data["result"].last;
            // this.page.number = data["result"].number;
            // this.page.numberOfElements = data["result"].numberOfElements;
            // this.page.offset = data["result"].offset;
            // this.page.size = data["result"].size;
            // this.page.totalElements = data["result"].totalElements;
            // this.page.totalPages = data["result"].totalPages;
            // if (this.page.number == 0) {
            //   this.gridComp.setPages();
            // }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get master pricing plan list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get master pricing plan list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  getMerchantCategory(mcategory: string) {
    let val = "";
    switch (mcategory) {
      case "S":
        val = "Small";
        break;
      case "L":
        val = "Large";
        break;
      case "X":
        val = "Other";
        break;
    }
    return val;
  }

  onSync() {
    this.disableSyncButton = true;
    this.masterpricingplanservice.syncMPP().subscribe(
      data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();

        if (data["success"]) {
          objmsg.title = "Success";
          objmsg.message = "Master pricing plans update successfully!";
          objmsg.btnClass = "btn-success";
        } else {
          objmsg.title = "Error";
          objmsg.message = "Failed to sync master pricing plans!";
          objmsg.btnClass = "btn-error";
        }
        this.tools.simpleMessage(objmsg);
        this.disableSyncButton = false;
      },
      err => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to sync master pricing plans!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
        this.disableSyncButton = false;
      }
    );
  }
  onRowDblClick(rowData: mppDataModel) {
    this.addMPPScr = true; // Show the addMPPScr screen
    this.populateMccList(); // Populate MCC list if necessary
    this.mppData = { ...rowData }; // Copy the row data to mppData

    this.updatebtn=true;
    //console.log(this.mppData)
  }
  // onRowDblClick(e: MasterPricingPlanModel) {
  //   if (e.id) {
  //     this.masterpricingplanservice.getMasterPricingPlanSlabs(e.id).subscribe(
  //       data => {
  //         if (data["success"]) {
  //           this.currMasterPricingDesicription = e.description;
  //           this.currMasterPricingMerchantType = e.merchantType;
  //           this.currMasterPricingMerchantCategory = e.merchantCategory;
  //           this._id = e.id;
  //           this.datalist_slabs = data["result"];
  //           this.isDetailScreen = true;
  //         } else {
  //           let ngmodaloptions: NgbModalOptions = {};
  //           ngmodaloptions.backdrop = "static";
  //           ngmodaloptions.size = "sm";
  //           let objmsg: SimpleMessage = new SimpleMessage();
  //           objmsg.title = "Error";
  //           objmsg.message = "Failed to sync master pricing plans!";
  //           objmsg.btnClass = "btn-error";
  //           this.tools.simpleMessage(objmsg);
  //         }
  //       },
  //       err => {
  //         let ngmodaloptions: NgbModalOptions = {};
  //         ngmodaloptions.backdrop = "static";
  //         ngmodaloptions.size = "sm";
  //         let objmsg: SimpleMessage = new SimpleMessage();
  //         objmsg.title = "Error";
  //         objmsg.message = "Failed to sync master pricing plans!";
  //         objmsg.btnClass = "btn-error";
  //         this.tools.simpleMessage(objmsg);
  //       }
  //     );
  //   }
  // }
  onBackbuttonClick() {
    this.datalist_slabs = undefined;
    this.currMasterPricingDesicription = undefined;
    this.currMasterPricingMerchantType = undefined;
    this.currMasterPricingMerchantCategory = undefined;
    this._id = undefined;
    this.isDetailScreen = false;
     this.addMPPScr=false;
     this.updatebtn=false;
     this.savebtn=false;
  }

  getCurrentMerchantCategory() {
    return this.getMerchantCategory(this.currMasterPricingMerchantCategory);
  }
  onExcelClick() {
    this.getMPPListForExcel();
  }

  getMPPListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.masterpricingplanservice
      .getMPPList(page, this.searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<any>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "master_pricing_plan"
            );
          } else {
            alert("Failed to get Master pricing plan!");
          }
        },
        (error) => {
          alert("Failed to get Master pricing plan!");
        }
      );
  }
  addMPPScreen(){
  this.addMPPScr=true;
  this.populateMccList();
  this.mppData= new mppDataModel();
  this.savebtn=true;
  this.mdrPlanTransRates=new Array<TransRatesModel>();
   this.newCopyTransRateCategories();
  }

  populateMccList() {
    this.mccservice.getAllMCC().subscribe(
      (res) => {
        if (res["success"]) {
          this.mcclist = res["result"];
          //console.log(this.mcclist)
        } else {
          this.mcclist = new Array<MCCModel>();
          console.error("failed to fetch mcc list!");
        }
      },
      (err) => {
        this.mcclist = new Array<MCCModel>();
        console.error("error while fetching mcc list!" + err);
      }
    );
  }
  saveMPP(){
 //console.log(this.mppData)
 //console.log(this.mdrPlanTransRates)
 this.mppData.mdrPlanTransRates = this.mdrPlanTransRates;
 let ngmodaloptions: NgbModalOptions = {};
 ngmodaloptions.backdrop = "static";
 ngmodaloptions.size = "sm";
 const ngmodalref = this.ngbModalService.open(
   NgYesNoModalContent,
   ngmodaloptions
 );
 ngmodalref.componentInstance.title = "Confirmation";
 ngmodalref.componentInstance.message = "Are you sure to save Master Plan Pricing?";
 ngmodalref.result.then(
   (result) => {
     if (result == "yes") {
       document.body.style.cursor = "wait";

       let objmsg: SimpleMessage = new SimpleMessage();
       let ngmodaloptions: NgbModalOptions = {};
       ngmodaloptions.backdrop = "static";
       ngmodaloptions.size = "sm";
       this.masterpricingplanservice.saveMPPPlan(this.mppData).subscribe(
         (data) => {
        //console.log(data)
        if (data["success"]) {
          document.body.style.cursor = "default";
          objmsg.message = "Master Plan Pricing save successfully!";
          objmsg.title = "Success";
          objmsg.btnClass = "btn-success";
          this.tools.simpleMessage(objmsg);
        
         // this.mppData= new mppDataModel();
         setTimeout(() => {
         this.getList();
          this.addMPPScr=false;
          this.savebtn=false;
      }, 2000);
        }
         },
         (error) => {
           document.body.style.cursor = "default";
           objmsg.title = "Error";
           objmsg.message = "Failed to save Master Plan Pricing!";
           objmsg.btnClass = "btn-error";
           this.tools.simpleMessage(objmsg);
          
         }
       );
     }
   },
   (reason) => {}
 );
}

updateMPP(){
let id= this.mppData.id


let ngmodaloptions: NgbModalOptions = {};
 ngmodaloptions.backdrop = "static";
 ngmodaloptions.size = "sm";
 const ngmodalref = this.ngbModalService.open(
   NgYesNoModalContent,
   ngmodaloptions
 );
 ngmodalref.componentInstance.title = "Confirmation";
 ngmodalref.componentInstance.message = "Are you sure to update Master Plan Pricing?";
 ngmodalref.result.then(
   (result) => {
     if (result == "yes") {
       document.body.style.cursor = "wait";

       let objmsg: SimpleMessage = new SimpleMessage();
       let ngmodaloptions: NgbModalOptions = {};
       ngmodaloptions.backdrop = "static";
       ngmodaloptions.size = "sm";
       this.masterpricingplanservice.updateMPPPlan(this.mppData).subscribe(
         (data) => {
      //  console.log(data)
        if (data["success"]) {
          document.body.style.cursor = "default";
          objmsg.message = "Master Plan Pricing updated successfully!";
          objmsg.title = "Success";
          objmsg.btnClass = "btn-success";
          this.tools.simpleMessage(objmsg);
          setTimeout(() => {
            this.getList();
             this.addMPPScr=false;
             this.updatebtn=false;
         }, 2000);
        }
         },
         (error) => {
        //  console.log(error)
           document.body.style.cursor = "default";
           objmsg.title = "Error";
           objmsg.message = "Failed to update Master Plan Pricing!";
           objmsg.btnClass = "btn-error";
           this.tools.simpleMessage(objmsg);
          
         }
       );
     }
   },
   (reason) => {}
 );
}
  areAllFieldsFilled(): boolean {
    return this.mppData && this.mppData.merchantType && this.mppData.merchantCategory &&
           this.mppData.description && this.mppData.mccCodes
          //   && this.mppData.domesticCardUpto2kOnus &&  this.mppData.creditCardPremiumOffus && 
          //  this.mppData.domesticCardUpto2kOffus && this.mppData.domesticCardGreaterThan2kOnus &&
          //  this.mppData.domesticCardGreaterThan2kOffus && this.mppData.creditCardStandardOnus &&
          //  this.mppData.allInternationalCards && this.mppData.commercialCardMDR &&
           this.mppData.remarks;
  }
  onlyNumberKey(event:any) {
     
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }
  async newCopyTransRateCategories() {
    const transcatlist = await this.transCategoryService.getTransCategoriesList();
    this.mdrPlanTransRates = new Array<TransRatesModel>();

    for (let i = 0; i < transcatlist.length; i++) {
      let transrate = new TransRatesModel();
      transrate.transCategory = transcatlist[i];
      transrate.fromAmount = transcatlist[i].fromAmount;
      transrate.toAmount = transcatlist[i].toAmount;
      this.mdrPlanTransRates.push(transrate);
      console.log(this.mdrPlanTransRates)
    }
    this._mdrPlanTransRates = this.tools.copyFullObject(this.mdrPlanTransRates);
  }


  /*isModifiedTransRates() {
    if (this.mdrPlanTransRates && !this._mdrPlanTransRates) {
      return true;
    } else if (!this.mdrPlanTransRates && this._mdrPlanTransRates) {
      return true;
    } else if (this.mdrPlanTransRates && this._mdrPlanTransRates) {
      for (let i = 0; i < this.mdrPlanTransRates.length; i++) {
        if (
          this.mdrPlanTransRates[i].mmFixed !=
          this._mdrPlanTransRates[i].mmFixed
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].mmPercent !=
          this._mdrPlanTransRates[i].mmPercent
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].mmMax != this._mdrPlanTransRates[i].mmMax
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].psFixed !=
          this._mdrPlanTransRates[i].psFixed
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].psPercent !=
          this._mdrPlanTransRates[i].psPercent
        ) {
          return true;
        } else if (
          this.mdrPlanTransRates[i].psMax != this._mdrPlanTransRates[i].psMax
        ) {
          return true;
        }
      }
    }
    return false;
  }*/
}
