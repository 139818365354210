import { Component, OnInit, ViewChild } from "@angular/core";
import {
  GridtableComponent,
  gridColumns,
  GridPage,
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { TopbarText } from "src/app/framework/components/topbar/topbar.component";
import { SortClass, SimpleMessage } from "src/app/framework/modules/util.class";
import { Tools } from "src/app/framework/modules/tools";
import { global } from "src/app/framework/modules/global";
import { ExporttocsvService } from "src/app/framework/services/exporttocsv.service";
import { AuthService } from "src/app/framework/services/auth.service";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
import { ReportService } from "src/app/services/report.service";
import { ProductService } from "src/app/services/product.service";
import * as moment from "moment/moment";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AggregatorDistributorModel } from "src/app/models/aggregator.distributor.model";

@Component({
  selector: "app-terminal-activation-summary-report",
  templateUrl: "./terminal-activation-summary-report.component.html",
  styleUrls: ["./terminal-activation-summary-report.component.css"],
})
export class TerminalActivationSummaryReportComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "ACTIVATION DATE",
      columnFieldName: "activationDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "MODEL",
      columnFieldName: "modelName",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "TYPE OF SALE",
      columnFieldName: "typeOfSale",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "AGGREGATOR NAME",
      columnFieldName: "aggregatorName",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "DISTRIBUTOR NAME",
      columnFieldName: "distributorName",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "COUNT",
      columnFieldName: "CountTerminal",
      columnClasses: "c-col-pc-10",
    },
  ];
  sortFields = [
    { name: "aggregator", display: "Aggregator Name" },
    { name: "distributor", display: "Distributor Name" },
    { name: "model", display: "Model Name" },
    { name: "count", display: "Count" },
    { name: "typeOfSale", display: "Type Of Sale" },
  ];

  topbartext: TopbarText[] = [
    {
      key: "terminalactivationsummaryreport",
      displayText: "Terminal Activation Summary Report",
      class: "col-md-6",
      anchorClass: "h6",
    },
  ];
  searchField = {
    activationDateFrom: undefined,
    activationDateTill: undefined,
    typeOfSale: undefined,
  };
  datalist: Array<any>;
  page: GridPage;
  _menuId: string;
  //isDetailScreen : boolean=false;

  sort: SortClass;
  tid: string = "";

  constructor(
    public tools: Tools,
    private objGlobal: global,
    private csvservice: ExporttocsvService,
    private auth: AuthService,
    private aggregatorDistributorService: AggregatorDistributorService,
    private reportService: ReportService,
    public productservice: ProductService
  ) {
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    this._menuId = this.tools.getMenuName(
      "main/terminalactivationsummaryreport"
    );
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "tid";
    let d = moment();
    d = d.add(-1, "month");
    this.searchField.activationDateFrom = new Date(d.format("DD MMM YYYY"));
    this.searchField.activationDateTill = new Date();
    this.aggregatorDistributorService.loadAggregatorDistrbutorIdNameType();
    productservice.loadProductNameWithId();
    this.searchField.typeOfSale = undefined;
  }

  ngOnInit() {}

  getReportData(opt) {
    let searchField = {};
    //if (!this.allowAggregatorSelection()) {
    //  this.searchField.aggregatorId = this.auth.getUserDistributorId();
    //}
    //searchField["aggregatorId"] = this.searchField.aggregatorId;

    if (
      this.searchField.activationDateFrom ||
      this.searchField.activationDateTill
    ) {
      searchField["activationDate"] = new Array<Date>();
      searchField["activationDate"].push(this.searchField.activationDateFrom);
      searchField["activationDate"].push(this.searchField.activationDateTill);
    }
    searchField["typeOfSale"] = this.searchField.typeOfSale;
    this.reportService
      .getTerminaActivationSummaryReport(this.page, searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to terminal activation summary report!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to terminal activation summary report!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  exportActiveTerminalSummaryReportForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    let searchField = {};
    //if (!this.allowAggregatorSelection()) {
    //  this.searchField.aggregatorId = this.auth.getUserDistributorId();
    //}
    //searchField["aggregatorId"] = this.searchField.aggregatorId;
    if (
      this.searchField.activationDateFrom ||
      this.searchField.activationDateTill
    ) {
      searchField["activationDate"] = new Array<Date>();
      searchField["activationDate"].push(this.searchField.activationDateFrom);
      searchField["activationDate"].push(this.searchField.activationDateTill);
    }
    searchField["typeOfSale"] = this.searchField.typeOfSale;

    this.reportService
      .getTerminaActivationSummaryReport(page, searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<any>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "terminalactivationsummary"
            );
          } else {
            alert("Failed to get terminal activation summary report!");
          }
        },
        (error) => {
          alert("Failed to get active terminal summary report!");
        }
      );
  }
  onExcelClick() {
    this.exportActiveTerminalSummaryReportForExcel();
  }

  allowAggregatorSelection() {
    return this.auth.getUserDistributorId() ? false : true;
  }

  getAggregatorList() {
    let aggregatorlist: Array<AggregatorDistributorModel> = undefined;
    if (this.allowAggregatorSelection()) {
      if (
        this.aggregatorDistributorService.aggregatorDistributorList &&
        this.aggregatorDistributorService.aggregatorDistributorList.length > 0
      ) {
        aggregatorlist = this.aggregatorDistributorService.aggregatorDistributorList.filter(
          (obj) => obj.id == 0 || obj.type == "A"
        );
      }
    }
    return aggregatorlist;
  }

  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }
}
