import { Component, OnInit, ViewChild } from "@angular/core";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { AggregatorDistributorModel } from "src/app/models/aggregator.distributor.model";
import { Router } from "@angular/router";
import { global } from "src/app/framework/modules/global";
import { Tools } from "src/app/framework/modules/tools";
import { AuthService } from "src/app/framework/services/auth.service";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
import { BankService } from "src/app/services/bank.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { EmployeeService } from "src/app/services/employee.service";
import { TransCategoryService } from "src/app/services/transcategory.service";
import {
  GridtableComponent,
  gridColumns,
  GridPage,
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import { BankModel } from "src/app/models/bank.model";
import { AggregatorDistributorComponent } from "../aggregator-distributor/aggregator-distributor.component";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
import { MARSSuperGroupMerchantModel } from "src/app/models/mars.super.group.merchant.model";
import { CanComponentDeactivate } from 'src/app/framework/guards/can-deactivated-guard';

@Component({
  selector: "app-aggregator-distributor-list",
  templateUrl: "./aggregator-distributor-list.component.html",
  styleUrls: ["./aggregator-distributor-list.component.css"],
})
export class AggregatorDistributorListComponent implements OnInit, CanComponentDeactivate {
  _type: string = "";
  _title: string = "";
  topbartext: TopbarText[] = [
    { key: "aggregator", displayText: "Aggregator List" },
  ];
  isDetailScreen: boolean = false;
  isProfile: boolean = false;
  isFinancials: boolean = false;
  isStock: boolean = false;
  isBusinesssummary: boolean = false;
  acttive_tab: string = "";
  page: GridPage;
  _menuId: string;
  _action: string = "";
  diffLabel: any = {
    codeLabel: "Aggregator Code",
    kycLabel: "Aggregator KYC",
    nameLabel: "Aggregator Name",
  };
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  @ViewChild(AggregatorDistributorComponent, { static: true })
  aggrdistrComp: AggregatorDistributorComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    { columnHeader: "", columnFieldName: "id", columnClasses: "c-col-pc-0" },
    {
      columnHeader: "Code",
      columnFieldName: "code",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeaderFunction: this.getNameHeader,
      columnFieldName: "name",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "Enrollment Date",
      columnFieldName: "enrollmentDate",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "Agreement Date",
      columnFieldName: "agreementDate",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "Sales Person",
      columnFieldName: "salesPerson.empName",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "Region",
      columnFieldName: "regionName",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Status",
      columnFieldName: "aggregatorDistributorStatus",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.getStatusText,
    },
  ];
  sortFields = [
    { name: "id", display: "-" },
    { name: "code", display: "Code" },
    { name: "name", display: "Name" },
    { name: "enrollmentDate", display: "Enrollment Date" },
    { name: "agreementDate", display: "Agreement Date" },
    { name: "salesPerson.empName", display: "Sales Person" },
  ];

  searchStatusValues = [
    { value: "", text: "-" },
    { value: "S", text: "New" },
    { value: "R", text: "Ready for approval" },
    { value: "A", text: "Approved" },
    { value: "J", text: "Rejected" },
    { value: "H", text: "On Hold" },
    { value: "I", text: "Inactive" },
    { value: "C", text: "Complete" },
  ];
  searchField = {
    searchText: "",
    status: "",
    enrollmentDate: "",
    agreementDate: "",
  };
  sort: SortClass;
  datalist: Array<AggregatorDistributorModel>;
  data: AggregatorDistributorModel;
  _data: any;
  _isChange: boolean = false;
  detailScreenTab: object = {
    profiletab: true,
    banktab: false,
    financialstab: false,
    //financialstab : false,
    //terminalstab : false,
    //risktab : false
  };
  topbarChangingValue: any = { selectedTabIndex: 0 };
  disableSyncButton: boolean = false;

  constructor(
    private router: Router,
    private objGlobal: global,
    public tools: Tools,
    private auth: AuthService,
    private objaggregatorDistributorService: AggregatorDistributorService,
    public transCategoryService: TransCategoryService,
    private employeeservice: EmployeeService,
    private bankservice: BankService,
    private ngbModalService: NgbModal,
    private csvservice: ExporttocsvService
  ) {
    this.data = new AggregatorDistributorModel();
    this.setType();
    this.setTopbarText();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    //this.objsimreturnservice.loadSIMReturnReason();

    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
  }

  ngOnInit() {}
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }

  isAllowEdit() {
    return this.auth.isPrivExists(this._menuId + "=>edit");
  }
  isApprovePriv() {
    return this.auth.isPrivExists(this._menuId + "=>approve");
  }
  onAddNew() {
    this._action = "new";
    this.aggrdistrComp._action = "new";
    this.isDetailScreen = true;
    this.isProfile = true;
    this.setTopbarText();
    this.topbarChangingValue = { selectedTabIndex: 0 };
    this.aggrdistrComp.addNew();
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  getData(eventVal: any) {
    this.getAggregatorDistributorList();
  }
  selectData(eventVal: any) {}
  deleteData(eventVal: any) {}
  onRowDblClick(aggregatorDistVal: any) {
    if (aggregatorDistVal.id) {
      this.aggrdistrComp._action = "update";
      this._action = "update";
      this.isDetailScreen = true;
      this.setTopbarText();
      this.onTabClick("profiletab");
      this.topbarChangingValue = { selectedTabIndex: 0 };
      this.aggrdistrComp.getDetail(aggregatorDistVal.id);
    }
  }
  setType() {
    if (this.router.url == "/main/distributor") {
      this._type = "D";
      this._title = "Distributor";
      //this.gridcols[3].columnHeader= "Distributor Name";
    } else if (this.router.url == "/main/supermerchant") {
      this._type = "S";
      this._title = "SuperMerchant";
    } else {
      this._type = "A";
      this._title = "Aggregator";
    }
    //this.aggrdistrComp._type=this._type;
    this.setDiffLabel();
    //this.aggrdistrComp.setDiffLabel();
  }
  setTopbarText() {
    if (this.isDetailScreen) {
      this.topbartext = [
        {
          key: "profiletab",
          displayText:
            (this._type == "D"
              ? "Distributor"
              : this._type == "S"
              ? "Super Merchant"
              : "Aggregator") + " Profile",
          class: "col-md-3",
        },
        { key: "banktab", displayText: "Bank", class: "col-md-2" },
        { key: "financialstab", displayText: "Financials", class: "col-md-2" },
        // {key:"stock",displayText:"stock",class:"col-md-2"},
        // {key:"businesssummary",displayText:"Business Summary",class:"col-md-3"}
      ];
    } else {
      this.topbartext = [
        {
          key:
            this._type == "D"
              ? "distributors"
              : this._type == "S"
              ? "supermerchant"
              : "aggregators",
          displayText:
            this._type == "D"
              ? "Distributors List"
              : this._type == "S"
              ? "Super Merchant List"
              : "Aggregators List",
          class: "col-md-3",
        },
      ];
    }
    //this.setGridCols();
  }
  setDiffLabel() {
    if (this._type == "D") {
      this.diffLabel.codeLabel = "Distrbutor Code";
      this.diffLabel.nameLabel = "Distributor Name";
      this.diffLabel.kycLabel = "Distributor KYC";
    } else if (this._type == "S") {
      this.diffLabel.codeLabel = "Super Merchant Code";
      this.diffLabel.nameLabel = "Super Merchant Name";
      this.diffLabel.kycLabel = "Super Merchant KYC";
    } else {
      this.diffLabel.codeLabel = "Aggregator Code";
      this.diffLabel.nameLabel = "Aggregator Name";
      this.diffLabel.kycLabel = "Aggregator KYC";
    }
  }
  /*
  setGridCols() {
    if(this._type=="D") {
      this.gridcols = [{columnHeader : "Sr",isSrCol : true,columnClasses : "c-col-pc-3"},
      {columnHeader : "",columnFieldName : "id",columnClasses : "c-col-pc-0"},
      {columnHeader : "Code",columnFieldName : "code",columnClasses : "c-col-pc-10"},
      {columnHeader : "Aggregator Name",columnFieldName : "name",columnClasses : "c-col-pc-15"},
      {columnHeader : "Enrollment Date",columnFieldName : "enrollmentDate",columnClasses : "c-col-pc-10",callbackFunction : this.tools.formatDateToDDMMMYY},  
      {columnHeader : "Agreement Date",columnFieldName : "agreementDate",columnClasses : "c-col-pc-10",callbackFunction : this.tools.formatDateToDDMMMYY},  
      {columnHeader : "Sale Person",columnFieldName : "salesPerson.empName",columnClasses : "c-col-pc-12"},  
      {columnHeader : "Aggregator",columnFieldName : "aggregator.name",columnClasses : "c-col-pc-12"},  
      {columnHeader : "Super Merchant",columnFieldName : "superMerchant.name",columnClasses : "c-col-pc-10"},  
      {columnHeader : "Region",columnFieldName : "region",columnClasses : "c-col-pc-8"},
      {columnHeader : "Status",columnFieldName : "aggregatorDistrobutorStatus",columnClasses : "c-col-pc-8",callbackFunction : this.getStatusText}];

    } else {
      this.gridcols = [{columnHeader : "Sr",isSrCol : true,columnClasses : "c-col-pc-3"},
      {columnHeader : "",columnFieldName : "id",columnClasses : "c-col-pc-0"},
      {columnHeader : "Code",columnFieldName : "code",columnClasses : "c-col-pc-12"},
      {columnHeader : "Aggregator Name",columnFieldName : "name",columnClasses : "c-col-pc-20"},
      {columnHeader : "Enrollment Date",columnFieldName : "enrollmentDate",columnClasses : "c-col-pc-12",callbackFunction : this.tools.formatDateToDDMMMYY},  
      {columnHeader : "Agreement Date",columnFieldName : "agreementDate",columnClasses : "c-col-pc-12",callbackFunction : this.tools.formatDateToDDMMMYY},  
      {columnHeader : "Sale Person",columnFieldName : "salesPerson.empName",columnClasses : "c-col-pc-20"},  
      {columnHeader : "Region",columnFieldName : "region",columnClasses : "c-col-pc-10"},
      {columnHeader : "Status",columnFieldName : "aggregatorDistrobutorStatus",columnClasses : "c-col-pc-10",callbackFunction : this.getStatusText}];
    }
  }*/
  getStatusText(status: string) {
    let statustext: string = "New";
    switch (status) {
      case "S":
        statustext = "New";
        break;
      case "R":
        statustext = "Ready for approval";
        break;
      case "H":
        statustext = "On Hold";
        break;
      case "C":
        statustext = "Complete";
        break;
      case "I":
        statustext = "Inactive";
        break;
      case "J":
        statustext = "Rejected";
        break;
      case "A":
        statustext = "Approved";
        break;
      case "F":
        statustext = "Failed";
        break;
      default:
        statustext = "New";
        break;
    }
    return statustext;
  }
  tab_click(e) {
    if (e == "financials") {
      this.isFinancials = true;
      this.isStock = false;
      this.isBusinesssummary = false;
      this.isProfile = false;
    } else if (e == "stock") {
      this.isFinancials = false;
      this.isStock = true;
      this.isBusinesssummary = false;
      this.isProfile = false;
    } else if (e == "businesssummary") {
      this.isFinancials = false;
      this.isStock = false;
      this.isBusinesssummary = true;
      this.isProfile = false;
    } else {
      this.isFinancials = false;
      this.isStock = false;
      this.isBusinesssummary = false;
      this.isProfile = true;
    }
  }

  getAggregatorDistributorList() {
    this.objaggregatorDistributorService
      .getAggregatorDistributorList(
        this.page,
        this.searchField,
        this.sort,
        this._type
      )
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            if (this._type == "A") {
              objmsg.message = "Failed to get aggregator list!";
            } else if (this._type == "S") {
              objmsg.message = "Failed to get super merchant list!";
            } else if (this._type == "D") {
              objmsg.message = "Failed to get distributor list!";
            }
            objmsg.btnClass = "btn-error";
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          if (this._type == "A") {
            objmsg.message = "Failed to get aggregator list!";
          } else if (this._type == "S") {
            objmsg.message = "Failed to get super merchant list!";
          } else if (this._type == "D") {
            objmsg.message = "Failed to get distributor list!";
          }
          objmsg.btnClass = "btn-error";
        }
      );
  }
  onTabClick(e: any) {
    this.detailScreenTab = {
      profiletab: false,
      banktab: false,
      financialstab: false,
      // terminalstab : false,
      // risktab : false
    };
    this.detailScreenTab[e] = true;
  }
  onCloseDetailScreen(isChange: boolean) {
    if (isChange == true) {
      this.getAggregatorDistributorList();
    }
    this.isDetailScreen = false;
    this.setTopbarText();
  }

  getNameHeader(_scope) {
    if (!_scope) {
      _scope = this;
    }
    return _scope._type == "D"
      ? "Distributor Name"
      : _scope._type == "S"
      ? "Super Merchant Name"
      : "Aggregator Name";
  }

  getAggregatorDistributorListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    //let sort: SortClass = new SortClass();
    const typeText =
      this._type == "D"
        ? "distributor"
        : this._type == "S"
        ? "Super Merchant"
        : "aggregator";
    this.objaggregatorDistributorService
      .getAggregatorDistributorList(
        page,
        this.searchField,
        this.sort,
        this._type
      )
      .subscribe(
        (data) => {
          let obj: Array<AggregatorDistributorModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              typeText,
              this
            );
          } else {
            alert("Failed to get " + typeText + " list!");
          }
        },
        (error) => {
          alert("Failed to get " + typeText + " list!");
        }
      );
  }
  onExcelClick() {
    this.getAggregatorDistributorListForExcel();
  }

  isAllowSync() {
    return this._type == "A" && this.auth.isPrivExists(this._menuId + "=>sync");
  }

  onSync(type: string) {
    this.disableSyncButton = true;
    const typeText = type == "G" ? "Group Merchant" : type=="A" ? "Aggregator": "Super Merchant";
    this.objaggregatorDistributorService.syncSuperGroupMerchant(type).subscribe(
      (data) => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();

        if (data["success"]) {
          objmsg.title = "Success";
          objmsg.message = typeText + "sync successfully!";
          objmsg.btnClass = "btn-success";
          this.objaggregatorDistributorService.triggerMARSCodeUpdateSubs(type);
        } else {
          objmsg.title = "Error";
          objmsg.message = "Failed to sync " + typeText + "!";
          objmsg.btnClass = "btn-error";
        }
        this.tools.simpleMessage(objmsg);
        this.disableSyncButton = false;
      },
      (err) => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to sync " + typeText + "!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
        this.disableSyncButton = false;
      }
    );
  }

  canDeactivate() {
    //alert ("moving out")
    return true;
  }
}
