import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { Tools } from "../framework/modules/tools";
import { SortClass } from "../framework/modules/util.class";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { IndentModel, IndentActionModel } from "../models/indent.model";

@Injectable()
export class IndentService {
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}
  saveIndent(indent: IndentModel) {
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/indent", indent, {
        headers: header
      })
      .map(res => res);
  }

  getIndentList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (searchField.indentDate) {
      if (searchField.indentDate[0]) {
        httpParam = httpParam.append(
          "indentDateFrom",
          this.tools.formatDateToDDMMMYY(searchField.indentDate[0])
        );
      }
      if (searchField.indentDate[1]) {
        httpParam = httpParam.append(
          "indentDateTill",
          this.tools.formatDateToDDMMMYY(searchField.indentDate[1])
        );
      }
    }
    if (searchField.status && searchField.status != "") {
      httpParam = httpParam.append("status", searchField.status);
    }
    if (searchField.typeOfSale && searchField.typeOfSale != "") {
      httpParam = httpParam.append("typeOfSale", searchField.typeOfSale);
    }
    if (searchField.amountFrom != undefined && searchField.amountFrom != "") {
      httpParam = httpParam.append("amountFrom", searchField.amountFrom);
    }
    if (searchField.amountTo != undefined && searchField.amountTo != "") {
      httpParam = httpParam.append("amountTo", searchField.amountTo);
    }
    if (searchField.qtyFrom != undefined && searchField.qtyFrom != "") {
      httpParam = httpParam.append("qtyFrom", searchField.qtyFrom);
    }
    if (searchField.qtyTo != undefined && searchField.qtyTo != "") {
      httpParam = httpParam.append("qtyTo", searchField.qtyTo);
    }
    if (searchField.rateFrom != undefined && searchField.rateFrom != "") {
      httpParam = httpParam.append("rateFrom", searchField.rateFrom);
    }
    if (searchField.rateTo != undefined && searchField.rateTo != "") {
      httpParam = httpParam.append("rateTo", searchField.rateTo);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/indents", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }
  getIndentDetail(indentId: number) {
    let header = new HttpHeaders();
    //let param: HttpParams = new HttpParams();
    //param = param.append("indentId", indentId.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/indent/" + indentId, {
        headers: header,
        observe: "body",
        //params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  indentAction(indent: IndentModel) {
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/indentaction", indent, {
        headers: header
      })
      .map(res => res);
  }
  indentGeneration(indent: IndentModel) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/indentgeneration",
        indent,
        { headers: header }
      )
      .map(res => res);
  }
}
