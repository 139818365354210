import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { MDRPlanModel } from "src/app/models/mdr.plan.model";
import { Tools } from "src/app/framework/modules/tools";
import { AppSettings } from "../framework/config/AppSettings";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SortClass } from "../framework/modules/util.class";
import { Observable } from "rxjs";
@Injectable()
export class MDRPlanService {
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}

  saveMDRPlan(mdrplan: MDRPlanModel) {
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/mdrplan", mdrplan, {
        headers: header
      })
      .map(res => res);
  }

  getMDRPlanList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);

    if (searchField.creationDate) {
      httpParam = httpParam.append(
        "creationDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.creationDate[0])
      );
      httpParam = httpParam.append(
        "creationDateTill",
        this.tools.formatDateToDDMMMYY(searchField.creationDate[1])
      );
    }
    if (searchField.status && searchField.status != "") {
      httpParam = httpParam.append("status", searchField.status);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/mdrplanlist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getMDRPlanDetail(code: number) {
    let header = new HttpHeaders();
    //let param: HttpParams = new HttpParams();
    //param = param.append("code", code.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/mdrplandetail/" + code, {
        headers: header,
        observe: "body",
        //params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  markMDRPlanInactive(code: number) {
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/inactivemdrplan", code, {
        headers: header
      })
      .map(res => res);
  }

  async loadMDRPlanDetailList(): Promise<Array<MDRPlanModel>> {
    return await this.loadMDRPlanDetailListApi()
      .toPromise()
      .then(
        data => {
          if (data["success"]) {
            const val: Array<MDRPlanModel> = data["result"];
            return val;
          }
        },
        msg => {
          console.error("topromise rejected while getting MDR plan!");
          return undefined;
        }
      )
      .catch(err => {
        console.error("exception while getting MDR plan - " + err);
        return undefined;
      });
  }

  loadMDRPlanDetailListApi() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/mdrplans", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }
  updateToMARS(mdrplan: MDRPlanModel) {
    let header = new HttpHeaders();
    let mdrplanparam: MDRPlanModel = new MDRPlanModel();
    mdrplanparam.code = mdrplan.code;
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/updatemdrplantomars",
        mdrplanparam,
        { headers: header }
      )
      .map(res => res);
  }

  getMDRMARSResApi(mdrCode: number) {
    let header: HttpHeaders = new HttpHeaders();
    //let httpparam: HttpParams = new HttpParams();
    //httpparam = httpparam.append("code", mdrCode.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/mdrmarsresponse/" + mdrCode,
        {
          headers: header,
          //params: httpparam,
          observe: "body",
          responseType: "json"
        }
      )
      .map(res => res);
  }
}
