import { Component, OnInit, ViewChild } from '@angular/core';
import { GridtableComponent, gridColumns, GridPage } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { SortClass, SimpleMessage } from 'src/app/framework/modules/util.class';
import { SupplierModel } from 'src/app/models/supplier.model';
import { Tools } from 'src/app/framework/modules/tools';
import { AuthService } from 'src/app/framework/services/auth.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SupplierService } from 'src/app/services/supplier.service';
import { global } from 'src/app/framework/modules/global';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { NgYesNoModalContent } from 'src/app/framework/util/components/modalcomponent/modal-content';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css']
})
export class SupplierComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-5" },
    {
      columnHeader: "Supplier Name",
      columnFieldName: "supplierName"
    }
  ];
  sortFields = [
    { name: "supplierName", display: "Supplier Name" }
  ];

  topbartext: TopbarText[] = [
    { key: "supplierlist", displayText: "Supplier List" }
  ];

  searchField = {
    searchText: ""
  };
  sort: SortClass;
  datalist: Array<SupplierModel>;
  data: SupplierModel;
  _data: any;
  isDetailScreen: boolean = false;
  _menuId: string;
  _action: string = "";
  page: GridPage;
  _isChange: boolean = false;
  _supplierId: string = "";

  constructor(public tools: Tools,
    private auth: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private objSupplierService: SupplierService,
    private objGlobal: global,
    private csvservice: ExporttocsvService) {
    
    this.sort = new SortClass();
      this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
      );

      this.data = new SupplierModel();
      this.page = new GridPage();
      this.page.size = 10; //Default page size
      this._menuId = this.tools.getMenuName("main/supplier");
      this.objGlobal.setActiveMenu(this._menuId);
      this.sort = new SortClass();
      //this.sort.sortDirection = "D";
      //this.sort.sortFieldName = "supplierId";
      this.getSupplierList();
     }

  ngOnInit() {
  }

  onRowDblClick(e: any) {
    if (e) {
      this.topbartext = [
        { key: "supplierdetail", displayText: "Supplier Detail" }
      ];
      this._action = "update";
      this.isDetailScreen = true;
      this.data = e;
      this._data = this.tools.copyFullObject(this.data);
    }
  }

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }

  onAddNew() {
    this._action = "new";
    this.isDetailScreen = true;
    this.topbartext = [{ key: "addsupplier", displayText: "Add Supplier" }];
    this.data = new SupplierModel();
    this._data = this.tools.copyFullObject(this.data);
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Supplier data has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
            this.topbartext = [
              { key: "supplierlist", displayText: "Supplier List" }
            ];
            if (this._isChange == true) {
              this._isChange = false;
              this.getSupplierList();
            }
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
      this.topbartext = [{ key: "supplierlist", displayText: "Supplier List" }];
      if (this._isChange == true) {
        this._isChange = false;
        this.getSupplierList();
      }
    }
  }

  isModified() {
    if (!(this.data && this._data)) {
      return false;
    }
    if (this.data.supplierName != this._data.supplierName) {
      return true;
    } 
    return false;
  }

  getSupplierList() {
    this.objSupplierService
      .getSupplierList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get supplier list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get supplier list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  saveSupplier() {
    this.objSupplierService
      .saveSupplier(this.data)
      .subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        if (data["success"]) {
          objmsg.title = "Information";
          objmsg.message = "Supplier saved successfully!";
          objmsg.btnClass = "btn-info";
          this.data.supplierId = data["result"];
          this._data = this.tools.copyFullObject(this.data);
          this._isChange = true;
          this._action = "update";
        } else {
          if (data["message"] == "NAME_ALREADYEXISTS") {
            objmsg.title = "Warning";
            objmsg.message = "Supplier Name already exists!";
            objmsg.btnClass = "btn-warning";
          } else {
            objmsg.title = "Error";
            objmsg.message = "Failed to save Supplier data!";
            objmsg.btnClass = "btn-error";
          }
        }
        this.tools.simpleMessage(objmsg);
      });
  }

  disableSubmit(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }

  disableCancel(f: any) {
    return !this.isModified();
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  onCancelClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to discard changes?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.data = this.tools.copyFullObject(this._data);
        }
      },
      reason => {}
    );
  }

  getSupplierListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.objSupplierService
      .getSupplierList(page, this.searchField, sort)
      .subscribe(
        data => {
          let obj: Array<SupplierModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "supplier"
            );
          } else {
            alert("Failed to get Supplier list!");
          }
        },
        error => {
          alert("Failed to get Supplier list!");
        }
      );
  }
  onExcelClick() {
    this.getSupplierListForExcel();
  }
}
