import { AggregatorDistributorModel } from "./aggregator.distributor.model";

export class EmployeeModel {
  empCode?: string;
  empName?: string;
  department?: string;
  designation?: string;
  mobile?: string;
  jobTitle?: string;
  emailId?: string;
  joiningtDate?: Date;
  status?: string;
  marsCode?: number;
  aggregator?: AggregatorDistributorModel;
}
