import { Directive, ElementRef } from '@angular/core';
declare var jQuery:any;
declare var $ :any;
@Directive({
  selector: '[pan-check]'
})
export class PanDirective {

  constructor(el: ElementRef) {
    el.nativeElement.pattern="^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$";
    //el.nativeElement["ng-reflect-pattern"]="^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$";
   }

}
