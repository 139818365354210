import { Component, OnInit, ViewChild } from "@angular/core";
import {
  TopbarText,
  TopbarComponent
} from "../../framework/components/topbar/topbar.component";
import { AuthService } from "../../framework/services/auth.service";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../framework/util/components/gridtable/gridtable.component";
import { Tools } from "../../framework/modules/tools";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { Router } from "@angular/router";
import { BankIFSCModel } from "../../models/bank.ifsc.model";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "src/app/framework/util/components/modalcomponent/modal-content";
import { BankIFSCService } from "../../services/bank.ifsc.service";
import { global } from "src/app/framework/modules/global";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";

@Component({
  selector: "app-bank-ifsc",
  templateUrl: "./bank-ifsc.component.html",
  styleUrls: ["./bank-ifsc.component.css"]
})
export class BankIfscComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "Ifsc Code",
      columnFieldName: "ifscCode",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Bank Name",
      columnFieldName: "bankName",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Branch Name",
      columnFieldName: "branchName",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "City",
      columnFieldName: "city",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "MICR",
      columnFieldName: "micr",
      columnClasses: "c-col-pc-22"
    },
    {
      columnHeader: "Pincode",
      columnFieldName: "pincode",
      columnClasses: "c-col-pc-25"
    },
    {
      columnHeader: "State",
      columnFieldName: "state",
      columnClasses: "c-col-pc-25"
    }
  ];
  sortFields = [
    { name: "ifscCode", display: "Ifsc Code" },
    { name: "bankName", display: "Bank Name" },
    { name: "branchName", display: "Branch Name" },
    { name: "city", display: "City" },
    { name: "state", display: "State" },
    { name: "pincode", display: "Pincode" }
  ];
  topbartext: TopbarText[] = [
    { key: "bankIfsclist", displayText: "Bank IFSC List" }
  ];

  searchField = {
    searchText: ""
  };
  sort: SortClass;
  datalist: Array<BankIFSCModel>;
  data: BankIFSCModel;
  _data: any;
  isBankIfscDetailScreen: boolean = false;
  _menuId: string;
  _action: string = "";
  page: GridPage;
  _isChange: boolean = false;
  _ifscCode: string = "";

  constructor(
    public tools: Tools,
    private auth: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private objbankIfscService: BankIFSCService,
    private objGlobal: global,
    private csvservice: ExporttocsvService
  ) {
    this.sort = new SortClass();
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );

    this.data = new BankIFSCModel();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/ifsc");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "ifscCode";
    this.getBankIfscList();
  }

  ngOnInit() {}

  onRowDblClick(e: any) {
    if (e.ifscCode) {
      this.topbartext = [
        { key: "bankIfscdetail", displayText: "Bank IFSC Detail" }
      ];
      this._action = "update";
      this.isBankIfscDetailScreen = true;
      this.getData(e.ifscCode);
   //   console.log(e.ifscCode);
    }
  }
  getData(ifscCode: string) {
    this.objbankIfscService.getBankIFSC(ifscCode).subscribe(
      data => {
        if (data["success"]) {
          this.data = data["result"];
          this._data = this.tools.copyFullObject(this.data);
          this._action = "update";
        } else {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          switch (data["message"]) {
            case "INVALIDIFSCCODE":
              objmsg.title = "Error";
              objmsg.message = "Invalid IFSC code!";
              objmsg.btnClass = "btn-warning";
              break;
            default:
              objmsg.title = "Error";
              objmsg.message = "Failed to get data!";
              objmsg.btnClass = "btn-warning";
          }
          this.tools.simpleMessage(objmsg);
        }
      },
      err => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to get data!";
        objmsg.btnClass = "btn-warning";
      }
    );
  }

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }

  onAddNew() {
    this._action = "new";
    this.isBankIfscDetailScreen = true;
    this.topbartext = [{ key: "addbankIfsc", displayText: "Add Bank IFSC" }];
    this.data = new BankIFSCModel();
    this._data = this.tools.copyFullObject(this.data);
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Bank Ifsc has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isBankIfscDetailScreen = false;
            this.topbartext = [
              { key: "bankIfsclist", displayText: "Bank IFSC List" }
            ];
            if (this._isChange == true) {
              this._isChange = false;
              this.getBankIfscList();
            }
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isBankIfscDetailScreen = false;
      this.topbartext = [
        { key: "bankIfsclist", displayText: "Bank IFSC List" }
      ];
      if (this._isChange == true) {
        this._isChange = false;
        this.getBankIfscList();
      }
    }
  }

  isModified() {
    if (!(this.data && this._data)) {
      return false;
    }
    if (this.data.ifscCode != this._data.ifscCode) {
      return true;
    } else if (this.data.branchName != this._data.branchName) {
      return true;
    } else if (this.data.bankName != this._data.bankName) {
      return true;
    } else if (this.data.city != this._data.city) {
      return true;
    } else if (this.data.micr != this._data.micr) {
      return true;
    } else if (this.data.pincode != this._data.pincode) {
      return true;
    } else if (this.data.state != this._data.state) {
      return true;
    }
    return false;
  }

  getBankIfscList() {
    this.objbankIfscService
      .getBankIFSCList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get bank ifsc list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get bank ifsc list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  saveBankIfsc() {
    this.objbankIfscService
      .saveBankIFSC(this.data, this._action)
      .subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        if (data["success"]) {
          objmsg.title = "Information";
          objmsg.message = "Bank Ifsc saved successfully!";
          objmsg.btnClass = "btn-info";
          this.data = data["result"];
          this._data = this.tools.copyFullObject(this.data);
          this._isChange = true;
          this._action = "update";
        } else {
          if (data["message"] == "ALREADYEXISTS") {
            objmsg.title = "Warning";
            objmsg.message = "Bank IFSC Code already exists!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "INVALIDIFSCCOOE") {
            objmsg.title = "Error";
            objmsg.message = "Invalid IFSC Code to modify!";
            objmsg.btnClass = "btn-warning";
          } else {
            objmsg.title = "Error";
            objmsg.message = "Failed to save bank IFSC Code!";
            objmsg.btnClass = "btn-error";
          }
        }
        this.tools.simpleMessage(objmsg);
      });
  }

  disableSubmit(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }

  disableCancel(f: any) {
    return !this.isModified();
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  onCancelClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to discard changes?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.data = this.tools.copyFullObject(this._data);
        }
      },
      reason => {}
    );
  }

  getBankIfscListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.objbankIfscService
      .getBankIFSCList(page, this.searchField, sort)
      .subscribe(
        data => {
          let obj: Array<BankIFSCModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "ifsclist"
            );
          } else {
            alert("Failed to get IFSC list!");
          }
        },
        error => {
          alert("Failed to get IFSC list!");
        }
      );
  }
  onExcelClick() {
    this.getBankIfscListForExcel();
  }
}
