import { Component, OnInit, ViewChild } from "@angular/core";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import {
  GridPage,
  GridtableComponent,
  gridColumns,
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { MerchantModel } from "src/app/models/merchant.model";
import { BankmerchantpreboardDetailComponent } from "../bankmerchantpreboard-detail/bankmerchantpreboard-detail.component";
import { Tools } from "src/app/framework/modules/tools";
import { Router } from "@angular/router";
import { AuthService } from "src/app/framework/services/auth.service";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
import { global } from "src/app/framework/modules/global";
import { BankMerchantService } from "src/app/services/bank.merchant.service";
import { BankSourcingBranchModel } from "src/app/models/BankSourcingBranch.model";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-bankmerchantpreboard",
  templateUrl: "./bankmerchantpreboard.component.html",
  styleUrls: ["./bankmerchantpreboard.component.css"],
})
export class BankmerchantpreboardComponent implements OnInit {
  topbartext: TopbarText[] = [
    {
      key: "bankmerchantpreboard",
      displayText: "Merchant Boarding List",
      class: "col-md-4",
    },
  ];
  searchField = {
    searchText: "",
    merchantStatus: "",
    agreementDate: "",
    sourcingBranch: "",
  };
  sort: SortClass;
  merchantStatusValues = [
    { value: "", text: "-" },
    { value: "BB", text: "Pre-Boareded" },
    { value: "S", text: "Boarding" },
    { value: "R", text: "Ready for approval" },
    { value: "A", text: "Approved" },
    { value: "C", text: "Boarded" },
  ];
  sortFields = [
    { name: "merchantId", display: "-" },
    { name: "applicationId", display: "Application Id" },
    { name: "mid", display: "MID" },
    { name: "merchantName", display: "Merchant Name" },
    { name: "phoneNumber", display: "Contact" },
    { name: "businessAddressCity", display: "Business Address City" },
    { name: "merchantStatus", display: "Status" },
  ];
  isDetailScreen: boolean = false;
  page: GridPage;
  datalist: Array<MerchantModel>;
  _menuId: string = "";
  _action: string = "";
  _merchantId: number;

  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  @ViewChild(BankmerchantpreboardDetailComponent, { static: true })
  bankmerchantpreboarddetailComp: BankmerchantpreboardDetailComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "",
      columnFieldName: "merchantId",
      columnClasses: "c-col-pc-0",
    },
    {
      columnHeader: "Application Id",
      columnFieldName: "applicationId",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Boarding Date",
      columnFieldName: "agreementDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "MID",
      columnFieldName: "mid",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Marketing Name",
      columnFieldName: "companyName",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "Merchant Name",
      columnFieldName: "authPersonName",
      columnClasses: "c-col-pc-18",
      callbackFunctionRowPrm: this.setMerchantName,
    },
    {
      columnHeader: "Branch",
      columnFieldName: "bankmerchant.sourcingBranch",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "City",
      columnFieldName: "businessAddressCity",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Status",
      columnFieldName: "merchantStatus",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.getStatusText,
      //callClassFunction: this.statusClass
    },
  ];
  sourcingBranchDropdownSettings = {
    singleSelection: true,
    idField: "bankSourcingBranchId",
    textField: "sourcingBranchName",
    allowSearchFilter: true,
    maxHeight: 200,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: "",
  };
  sourcingbranchlist: Array<BankSourcingBranchModel>;
  _isBankMerchant: boolean = false;
  constructor(
    public tools: Tools,
    private router: Router,
    private auth: AuthService,
    private objGlobal: global,
    private csvservice: ExporttocsvService,
    private bankmerchantservice: BankMerchantService
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "merchantId";
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );
    if (this._menuId == "bankportal-managemerchant") {
      this._isBankMerchant = true;
    }

    this.objGlobal.setActiveMenu(this._menuId);
    this.loadBankSourcingBranch();
    this.getBankMerchantList();
  }

  ngOnInit() {}
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  isEditPriv() {
    return this.auth.isPrivExists(this._menuId + "=>edit");
  }
  loadBankSourcingBranch() {
    if (this.bankmerchantservice.bankSourcingBranchList) {
      this.sourcingbranchlist = this.bankmerchantservice.bankSourcingBranchList;
    } else {
      this.bankmerchantservice.getSourcingBranch().subscribe((data) => {
        if (data["success"]) {
          this.bankmerchantservice.bankSourcingBranchList = data["result"];
          this.sourcingbranchlist = this.bankmerchantservice.bankSourcingBranchList;
        } else {
          console.error("Error from api while fetching sourcingbranch list!");
          this.sourcingbranchlist = undefined;
        }
      });
    }
  }
  getStatusText(v: string) {
    switch (v) {
      case "PB": {
        return "Pre-Boareded";
      }
      case "BB": {
        return "Pre-Boareded";
      }
      case "S": {
        return "Boarding";
      }
      case "R": {
        return "Ready for approval";
      }
      case "A": {
        return "Approved";
      }
      case "C": {
        return "Boarded";
      }
      default:
        return undefined;
    }
  }

  getDatalist() {
    this.getBankMerchantList();
  }
  onAddNew() {
    this.bankmerchantpreboarddetailComp.addNew();
    this.isDetailScreen = true;
    this.topbartext = [
      {
        key: "bankmerchantpreboard",
        displayText: "Merchant Boarding",
        class: "col-md-4",
      },
    ];
  }
  selectData(e) {
    if (e.merchantId) {
      this.isDetailScreen = true;
      this.topbartext = [
        {
          key: "bankmerchantpreboard",
          displayText: "Merchant Boarding",
          class: "col-md-4",
        },
      ];
      this.bankmerchantpreboarddetailComp.getDetail(e.merchantId);
    }
  }

  gridDeleteButton() {}

  setMerchantName(obj) {
    let name: string = "";
    if (obj.authPersonFirstName) {
      name = name + obj.authPersonFirstName;
    }
    if (obj.authPersonLastName) {
      if (name != "") {
        name = name + " ";
      }
      name = name + obj.authPersonLastName;
    }
    return name;
  }

  getBankMerchantList() {
    this.bankmerchantservice
      .getBankMerchantList(this.page, this.searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get merchant list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get merchant list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  getBankMerchantListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.bankmerchantservice
      .getBankMerchantList(page, this.searchField, this.sort)
      .subscribe(
        (data) => {
          let obj: Array<MerchantModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "bankmerchant",
              this
            );
          } else {
            alert("Failed to get Bank Merchant list!");
          }
        },
        (error) => {
          alert("Failed to get Bank Merchant list!");
        }
      );
  }
  onExcelClick() {
    this.getBankMerchantListForExcel();
  }

  onCloseDetailScreen(isChange: boolean) {
    if (isChange == true) {
      this.getDatalist();
    }
    this.isDetailScreen = false;
    this.topbartext = [
      {
        key: "bankmerchantpreboard",
        displayText: "Merchant Boarding List",
        class: "col-md-4",
      },
    ];
  }
  deleteData(e) {}
}
