import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { global } from 'src/app/framework/modules/global';
import { Tools } from 'src/app/framework/modules/tools';
import { SortClass } from 'src/app/framework/modules/util.class';
import { AuthService } from 'src/app/framework/services/auth.service';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { gridColumns, GridPage, GridtableComponent } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { ReportService } from 'src/app/services/report.service';

export class SearchFields {
  dateFrom: Date;
  dateTill: Date;
  transactionType?: string;
  tid?: string;
  rrn?: string;
}

@Component({
  selector: 'app-merchant-settlement-report',
  templateUrl: './merchant-settlement-report.component.html',
  styleUrls: ['./merchant-settlement-report.component.css']
})
export class MerchantSettlementReportComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  @ViewChild("snav", { static: true }) sideNave: any;
  topbartext: TopbarText[] = [
    {
      key: "merchant-settlement-report",
      displayText: "MERCHANT SETTLEMENT",
      class : "col-md-6"
    }
  ];
  searchField: SearchFields;
  sortFields = [
    { name: "settDate", display: "Settlement Date" },
    { name: "tranDate", display: "Transaction Date" },
    /*{ name: "userId", display: "Login Id" },*/
    { name: "tid", display: "TID" },
    { name: "mid", display: "MID" },
    { name: "rrn", display: "RRN" },
    { name: "tranTypeDesc", display: "Transaction Type" },
    { name : "legalName",display : "Legal Name"}
  ];
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Txn Date",
      columnFieldName: "tranDate",
      columnClasses: "c-col-pc-8",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Merchant Id",
      columnFieldName: "mid",
      columnClasses: "c-col-pc-11"
    },
    {
      columnHeader: "Terminal Id",
      columnFieldName: "tid",
      columnClasses: "c-col-pc-7"
    },
    {
      columnHeader: "Legal Name",
      columnFieldName: "legalName",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Tran. Type",
      columnFieldName: "tranTypeDesc",
      columnClasses: "c-col-pc-5"
    },
    {
      columnHeader: "Gross Amount",
      columnFieldName: "grossAmount",
      columnClasses: "c-col-pc-7 text-right pr-2",
      callbackFunction :  this.tools.formateNumberD2
    },
    {
      columnHeader: "Merchant Discount Fees",
      columnFieldName: "commision",
      columnClasses: "c-col-pc-7 text-right pr-2",
      callbackFunction :  this.tools.formateNumberD2
    },
    {
      columnHeader: "IGSG",
      columnFieldName: "gst",
      columnClasses: "c-col-pc-4 text-right pr-2",
      callbackFunction :  this.tools.formateNumberD2
    },
    {
      columnHeader: "Net Payment",
      columnFieldName: "netPayment",
      columnClasses: "c-col-pc-7 text-right pr-2",
      callbackFunction :  this.tools.formateNumberD2
    },
    {
      columnHeader: "RR Number",
      columnFieldName: "rrn",
      columnClasses: "c-col-pc-9"
    },
    {
      columnHeader: "Settlement Date",
      columnFieldName: "settDate",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS
    },
  ];
  page: GridPage;
  _menuId: string = "";
  id: number;
  sort: SortClass;
  datalist = [];
  data : any= {};
  constructor(public tools: Tools,
    //private ngbModalService: NgbModal,
    private router: Router,
    private objGlobal: global,
    private auth: AuthService,
    private reportservice : ReportService,
    private csvservice: ExporttocsvService) {
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );
    this.objGlobal.setActiveMenu(this._menuId);
    this.searchField = new SearchFields();
    this.searchField.transactionType = "All";
    this.searchField.dateFrom = new Date();
    this.searchField.dateTill = new Date();
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    this.sort = new SortClass();
   }

  ngOnInit() {
  }
  isAllowExport() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>export");
    return isAdd;
  }
  getDatalist() {
    const params = {};
    this.reportservice.
      getMerchantSettlementReport(this.page,this.searchField,  this.sort)
      .subscribe(data => {
        if (data["success"]) {
          this.datalist = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
        }
      });
  }

  getDateConfig() {
    const date = new Date("01-Jan-1900");
    return this.tools.getBsDatePickerConfig(true, date);
  }

  onExcelClick() {
    this.getDataForExportToExcel();
  }

  getDataForExportToExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.reportservice
      .getMerchantSettlementReport(page,this.searchField,  this.sort)
      .subscribe(
        data => {
          let obj: [];
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "sttlement_report"
            );
          } else {
            alert("Failed to get Settlement Reports data!");
          }
        },
        error => {
          alert("Failed to get Settlement Reports data!");
        }
      );
  }

    selectData(row: any) {
      this.data = row ;
      this.sideNave.open();
  }
  dataTimeValue = (val: Date) => {
    if (val) {
      return this.tools.formatDateToDDMMMYYHHMMSS(val);
    }
  }
  dataValue = (val: Date) => {
    if (val) {
      return this.tools.formatDateToDDMMMYY(val);
    }
  }

  getMerchantMDR() {
    let val: string;
    if (this.data) {
      if (this.data.mdrPercentage ) {
        val = this.data.mdrPercentage + "%";
      } else {
        val = this.data.mdrFixed;
      }
    }
    return val;
  }
}
