import { MARSErrorResponse } from "../../models/mars.response.error.module";

export class SortClass {
  sortDirection: string;
  sortFieldName: string;
}

export class SimpleMessage {
  title: string;
  message: string;
  btnClass: string;
  fnResult: Function;
  fnReason: Function;
  _scope: any;
}

export class FileModel {
  name: string;
  file: File;
}

export class MARSResponseErrorMsg {
  errorMsg: MARSErrorResponse;
  forEntity: String;
}

export class ValidMsg {
  isValid?: boolean;
  msg?: string;
  additionalInfo?: any;
}
