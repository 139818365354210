import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { global } from 'src/app/framework/modules/global';
import { Tools } from 'src/app/framework/modules/tools';
import { SimpleMessage, SortClass } from 'src/app/framework/modules/util.class';
import { AuthService } from 'src/app/framework/services/auth.service';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { gridColumns, GridPage, GridtableComponent } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { NgYesNoModalContent } from 'src/app/framework/util/components/modalcomponent/modal-content';
import { AggregatorDistributorModel } from 'src/app/models/aggregator.distributor.model';
import { BankIFSCModel } from 'src/app/models/bank.ifsc.model';
import { BankSourcingBranchModel } from 'src/app/models/BankSourcingBranch.model';
import { AggregatorDistributorService } from 'src/app/services/aggregator.distributor.service';
import { BankIFSCService } from 'src/app/services/bank.ifsc.service';
import { SourcingBranchService } from 'src/app/services/sourcing-branch.service';

@Component({
  selector: 'app-sourcing-branch',
  moduleId: module.id,
  templateUrl: 'sourcing.branch.component.html',
  styleUrls: ['sourcing.branch.component.css']
})
export class SourcingBranchComponent {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
  { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
  {
    columnHeader: "Aggregator",
    columnFieldName: "aggregatorName",
    columnClasses: "c-col-pc-20"
  },
  {
    columnHeader: "Branch Code",
    columnFieldName: "sourcingBranchCode",
    columnClasses: "c-col-pc-15"
  },
  {
    columnHeader: "Branch Name",
    columnFieldName: "sourcingBranchName",
    columnClasses: "c-col-pc-21"
  },
  {
    columnHeader: "IFSC Code",
    columnFieldName: "bankIFSC.ifscCode",
    columnClasses: "c-col-pc-10"
  },
  {
    columnHeader: "Bank Name",
    columnFieldName: "bankIFSC.bankName",
    columnClasses: "c-col-pc-15"
  },
  {
    columnHeader: "Bank Branch Name",
    columnFieldName: "bankIFSC.branchName",
    columnClasses: "c-col-pc-15"
  }
  ];

  sortFields = [
    { name: "sourcingBranchCode", display: "Branch Code" },
    { name: "sourcingBranchName", display: "Branch Name" },
    { name: "bankIFSC.ifscCode", display: "ifscCode" }
  ];

  topbartext: TopbarText[] = [
    { key: "sourcing-branch-list", displayText: "Sourcing Branch List" }
  ];

  searchField = {
    searchText: ""
  };
  sort: SortClass;
  datalist: Array<BankSourcingBranchModel>;
  data: BankSourcingBranchModel;
  bankIFSC: BankIFSCModel;
  _bankIFSC: BankIFSCModel;
  _data: any;
  isDetailScreen: boolean = false;
  _menuId: string;
  _action: string = "";
  page: GridPage;
  _isChange: boolean = false;
  _sourcingBranchId: number = 0;
  aggregators: Array<AggregatorDistributorModel> = [];

  constructor(private router: Router,public tools: Tools,
    private auth: AuthService,private objGlobal: global,
    private csvservice: ExporttocsvService,
    private objSourcingBranchService: SourcingBranchService,
    private bankifscservice: BankIFSCService,
    private ngbModalService: NgbModal,
    private aggreatordistributorservice : AggregatorDistributorService
  ) {

    this.sort = new SortClass();
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );
    this._menuId = this.tools.getMenuName("main/sourcingbranch");
    this.objGlobal.setActiveMenu(this._menuId);
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this.data = new BankSourcingBranchModel();
    this.bankIFSC = new BankIFSCModel();
    this.getAggregators();
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  isModified() {
    if (!(this.data && this._data)) {
      return false;
    }
    if (this.data.sourcingBranchCode != this._data.sourcingBranchCode) {
      return true;
    } else if (this.data.sourcingBranchName != this._data.sourcingBranchName) {
      return true;
    } else if (this.bankIFSC.ifscCode != this._bankIFSC.ifscCode) {
      return true;
    } 
    return false;
  }
  getDataList() {
    this.objSourcingBranchService
      .getSourcingBranchList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get sourcing branch list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get sourcing branch list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  disableSubmit(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }

  disableCancel(f: any) {
    return !this.isModified();
  }
  
  onAddNew() {
    this._action = "new";
    this.isDetailScreen = true;
    this.topbartext = [{ key: "add-sourcing-branch", displayText: "Add Sourcing Branch" }];
    this.data = new BankSourcingBranchModel();
    this.bankIFSC = new BankIFSCModel();
    if (this.aggregators && this.aggregators.length === 1) {
      this.data.aggregatorId = this.aggregators[0].id;
    }
    this._data = this.tools.copyFullObject(this.data);
    this._bankIFSC=this.tools.copyFullObject(this.bankIFSC);
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Sourcing branch data has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
            this.topbartext = [
              { key: "sourcing-branch-list", displayText: "Sourcing Branch List" }
            ];
            if (this._isChange == true) {
              this._isChange = false;
              this.getDataList();
            }
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
      this.topbartext = [{ key: "sourcing-branch-list", displayText: "Sourcing Branch List" }];
      if (this._isChange == true) {
        this._isChange = false;
        this.getDataList();
      }
    }
  }

  async getBankIFSCData() {
    const bankifscdata = await this.bankifscservice.getBankInfoOnIFSCCode(
      this.bankIFSC.ifscCode
    );

    if (bankifscdata) {
      this.bankIFSC.micr = bankifscdata.micr;
      this.bankIFSC.bankName = bankifscdata.bankName;
      this.bankIFSC.branchName = bankifscdata.branchName;
      this.bankIFSC.city = bankifscdata.city;
      this.bankIFSC.state = bankifscdata.state;
    } else {
      this.bankIFSC.micr = undefined;
      this.bankIFSC.bankName = undefined;
      this.bankIFSC.branchName = undefined;
      this.bankIFSC.city = undefined;
      this.bankIFSC.state = undefined;
    }
  }
  onRowDblClick(rec : BankSourcingBranchModel) {
    if (rec.bankSourcingBranchId) {
      this.topbartext = [
        { key: "add-sourcing-branch", displayText: "Add Sourcing Branch" }
      ];
      this._action = "update";
      this.isDetailScreen = true;
      this.getData(rec.bankSourcingBranchId);
    }
  }

  getData(id: number) {
    this.objSourcingBranchService.getSourcingBranch(id).subscribe(
      data => {
        if (data["success"]) {
          this.data = data["result"];
          this.bankIFSC = this.data.bankIFSC;
          this._data = this.tools.copyFullObject(this.data);
          this._bankIFSC = this.tools.copyFullObject(this.bankIFSC);
          this._action = "update";
        } else {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          switch (data["message"]) {
            case "Missing_ID":
              objmsg.title = "Error";
              objmsg.message = "Invalid record!";
              objmsg.btnClass = "btn-warning";
              break;
            default:
              objmsg.title = "Error";
              objmsg.message = "Failed to get data!";
              objmsg.btnClass = "btn-warning";
          }
          this.tools.simpleMessage(objmsg);
        }
      },
      err => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to get data!";
        objmsg.btnClass = "btn-warning";
      }
    );
  }

  getAggregators() {
    this.aggreatordistributorservice.getAggregatorIdName().subscribe(data => {
      if (data["success"]) {
        this.aggregators = data["result"];
      } else {
        this.aggregators = [];
        console.error("Faile to get aggregator list");
      }
    }, err => {
        this.aggregators = [];
        console.error("Faile to get aggregator list");        
    })
  }
  onSaveSourcingBranch() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    let validationError: boolean = false;
    let objmsg: SimpleMessage = new SimpleMessage();
    if (!this.data.aggregatorId) {
      objmsg.title = "Warning";
      objmsg.message = "Please select aggregator!";
      objmsg.btnClass = "btn-warning";
      validationError = true;
    } else if (!this.data.sourcingBranchName) {
      objmsg.title = "Warning";
      objmsg.message = "Please enter branch name!";
      objmsg.btnClass = "btn-warning";
      validationError = true;      
    } else if (!this.data.sourcingBranchCode) {
      objmsg.title = "Warning";
      objmsg.message = "Please enter branch code!";
      objmsg.btnClass = "btn-warning";
      validationError = true;      
    } else if (!this.bankIFSC.ifscCode) {
      objmsg.title = "Warning";
      objmsg.message = "Please enter IFSC code!";
      objmsg.btnClass = "btn-warning";
      validationError = true;      
    }
    if (validationError) {
      this.tools.simpleMessage(objmsg);
    } else {
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to save sourcing branch?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.saveSourcingBranch();
          }
        },
        reason => {}
      );
    }
  }
  saveSourcingBranch() {
    this.data.bankIFSC = new BankIFSCModel();
    this.data.bankIFSC=this.bankIFSC;
    this.objSourcingBranchService.saveSourcingBranch(this.data)
      .subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        if (data["success"]) {
          objmsg.title = "Information";
          objmsg.message = "Sourcing branch data saved successfully!";
          objmsg.btnClass = "btn-info";
          this.data = data["result"];
          this.bankIFSC = this.data.bankIFSC;
          this._data = this.tools.copyFullObject(this.data);
          this._bankIFSC = this.tools.copyFullObject(this.bankIFSC);
          this._isChange = true;
          this._action = "update";
        } else {
          if (data["message"] == "Aggregator_Missing") {
            objmsg.title = "Warning";
            objmsg.message = "Please select Aggregator!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "IFSC_Missing") {
            objmsg.title = "Warning";
            objmsg.message = "Please enter IFSC  code!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "Name_Missing") {
            objmsg.title = "Warning";
            objmsg.message = "Please enter Branch name!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "Code_Missing") {
            objmsg.title = "Warning";
            objmsg.message = "Please enter Branch code!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "Invalid_IFSC") {
            objmsg.title = "Warning";
            objmsg.message = "Please enter valid IFSC Code!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "Name_Exists") {
            objmsg.title = "Warning";
            objmsg.message = "Branch name already exists!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "Code_Exists") {
            objmsg.title = "Warning";
            objmsg.message = "Branch code already exists!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "IFSC_Exists") {
            objmsg.title = "Warning";
            objmsg.message = "IFSC code already exists!";
            objmsg.btnClass = "btn-warning";
          } else {
            objmsg.title = "Error";
            objmsg.message = "Failed to save sourcing branch data!";
            objmsg.btnClass = "btn-error";
          }
        }
        this.tools.simpleMessage(objmsg);
      }, err => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();     
        objmsg.title = "Error";
        objmsg.message = "Failed to save Pincode data!";
        objmsg.btnClass = "btn-error";  
        this.tools.simpleMessage(objmsg);
      });
  }

  onCancelClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
            //    this.isUserDetailScreen=false;
          }
        },
        (reason) => {}
      );
    } else {
    }
  }

  onExcelClick() {
    this.getSourcingBranchListForExcel();
  }

  getSourcingBranchListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.objSourcingBranchService
      .getSourcingBranchList(page, this.searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<any>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "sourcing_branch"
            );
          } else {
            alert("Failed to get sourcing branch list!");
          }
        },
        (error) => {
          alert("Failed to get sourcing branch list!");
        }
      );
  }
}
