import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Tools } from 'src/app/framework/modules/tools';
import { SimpleMessage } from 'src/app/framework/modules/util.class';
import { NgYesNoModalContent } from 'src/app/framework/util/components/modalcomponent/modal-content';
import { TerminalStatusRequestModel } from 'src/app/models/terminal.status.request.model';
import { MerchantService } from 'src/app/services/merchant.service';

@Component({
  selector: 'app-terminal-status-request-message',
  moduleId: module.id,
  templateUrl: 'terminal.status.request.message.component.html',
  styleUrls: ['terminal.status.request.message.component.css']
})
export class TerminalStatusRequestMessageComponent {
  @Input()
  requestStatusList: TerminalStatusRequestModel[];

  @Input()
  terminalStatus: string;

  @Input()
  terminalActivationStatus: string;

  @Input()
  terminalId: number;

  @Input()
  tid: string;

  @Input()
  deviceSerialNumber: string;

  statusReason: string;

  validationMessage: string;

  constructor(private tools: Tools, public activeModal: NgbActiveModal,
  private ngbModalService: NgbModal,private merchantservice : MerchantService) {
  }

  getRequestTypeText(requestType : string) {
    let result = undefined;
    switch (requestType) {
      case "D":
        result = "Deactivate";
        break;
      case "A" :
      case "N":
        result = "Activate";
        break;
      default:
        result = undefined;
    }
    return result;
  }

  getRequestStatus(status : string) {
    let result = undefined;
    switch (status) {
      case "N":
        result = "New";
        break;
      case "F":
        result = "Failed";
        break;
      case "P":
        result = "Pending";
        break;
      case "S":
        result = "Success";
        break;
      case "Q":
        result = "Queue";
        break;
      case "I":
        result = "Invalid Status in Response";
        break;
      default:
        result = undefined;
    }
    return result;
  }

  getDateTimeFormat(dateVal : Date) {
    return this.tools.formatDateToDDMMMYYHHMMSS(dateVal);
  }

  ok() {
    this.activeModal.close("ok");
  }

  activateRequestLabel() {
    if (this.requestStatusList && this.requestStatusList.length > 0) {
      let lastRequest = this.requestStatusList[0];
      if (this.terminalActivationStatus != "A" ) {
        if (lastRequest.status == "P") {
          return "Close pending request and create new activation request"
        } else {
          return "Create new activation request"
        }
      } else if (this.terminalActivationStatus == "A" ) {
        if (lastRequest.status == "P") {
          return "Close pending request and create new deactivation request"
        } else {
          return "Create new deactivation request"
        }      
      } 
    }
  }


  activateBtn() {
    if (this.requestStatusList && this.requestStatusList.length > 0) {
      let lastRequest = this.requestStatusList[0];
      if ((this.terminalStatus == "P" || this.terminalStatus == "T") && (lastRequest.status =="P" || lastRequest.status =="F")) {
        return true;
      } 
    }
    return false;
  }

  requestType() {
    if (this.requestStatusList && this.requestStatusList.length > 0) {
      if (this.terminalActivationStatus != "A" ) {
        return "A";
      } else {
        return "D"   
      } 
    }
  }

  statusUpdateClick() {
    if (this.statusReason && this.statusReason != "") {
       this.reRequest();
    } else {
      this.validationMessage = "Please enter reason!"; 
    }
  }

  clearValidation() {
    this.validationMessage = undefined;
  }
  reRequest() {
    const requestType = this.requestType();
    let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Are you sure to create new request for " + (requestType=="D"? "deactivation" : "activation") + "?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            let terminalActivationReq = {};
            terminalActivationReq["terminalId"] = this.terminalId;
            terminalActivationReq["deviceSerialNumber"] = this.deviceSerialNumber;
            terminalActivationReq["tid"] = this.tid;
            terminalActivationReq["status"] = requestType;
            terminalActivationReq["reason"] = this.statusReason;
            this.merchantservice.saveTerminalStatusChangeRequest(terminalActivationReq).
              subscribe(res => {
                let objmsg = new SimpleMessage();
                if (res["success"]) {
                  this.ok();
                  
                  objmsg.title = "Success";
                  objmsg.message = "Request updated successfully!"
                  this.tools.simpleMessage(objmsg);
                } else {
                  objmsg.title = "Failed";
                  objmsg.btnClass = "btn-error";
                  objmsg.message = "Error response from server!";
                  switch (res["message"]) {
                    case "TERMINALID_MISSING":
                      objmsg.message = "Terminal is missing in request!";
                      break;
                    case "DEVICESERIALNUMBER_MISSING":
                      objmsg.message =
                        "Device serial number is missing in request!";
                      break;
                    case "TID_MISSING":
                      objmsg.message = "TID is missing in request!";
                      break;
                    case "STATUS_MISSING":
                      objmsg.message = "Requested status is missing in request!";
                      break;
                    case "REASON_MISSING":
                      objmsg.message =
                        "Deactivation reason is missing in request!";
                      break;
                    case "INVALID_REQUESTTYPE":
                      objmsg.message = "Request status is invalid!";
                      break;
                    case "DEVICESERIALNUMBER_MISMATCH":
                      objmsg.message = "Given device serial number mismatch!";
                      break;
                    case "TID_MISMATCH":
                      objmsg.message = "Give tid mismatch!";
                      break;
                    case "SAME_STATUS_EXISTING":
                      objmsg.message =
                        "Terminal is already in deactivate statue!";
                      break;
                    case "TERMINAL_OR_DEVICE_NOT_FOUND":
                      objmsg.message = "Terminal or device not found!";
                      break;
                    case "REQUEST_IN_Q":
                      objmsg.message =
                        "A request for terminal is already in queue!";
                      break;

                    default:
                  }  
                  this.tools.simpleMessage(objmsg);
                }
              }, err => {
                  this.validationMessage = "server error to update new request!"; 
              })
            
          }
        },
        (reason) => {}
      );
  }
}
