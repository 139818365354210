import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objArraySort'
})
export class ObjectArraySort implements PipeTransform {
  
  transform (array: Array<any>, args :any): Array<any> {
    if(!args) {
      args="itemName";
    }

    if(array){
      array.sort((a:any,b:any)=> {
        if((a[args].toLowerCase() < b[args].toLowerCase())) {
          return -1;
        } else if(a[args].toLowerCase() > b[args].toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return array;
  }
}
