import { IndentModel } from "./indent.model";
import { DeviceStockModel } from "./device.model";
import { SIMStockModel } from "./simstock.model";
import { EmployeeModel } from "./employee.model";
import { BankModel } from "./bank.model";
import { AggregatorDistributorModel } from "./aggregator.distributor.model";
import { TransRatesModel } from "./trans.rate";
import { RentalPlanModel } from "./rental.plan.model";
import { FileModel } from "../framework/modules/util.class";
import { ChannelNetworkCodeValueModel } from "./channel.network.codevalue.model";

export class MerchantModel {
  merchantId?: number;
  applicationId?: string;
  /*
   * To be Update from MARS
   */
  mid?: string;
  merchantFirstName?: string;
  merchantLastName?: string;
  merchantName?: string;
  companyName?: string;
  authPersonTitle?: string;
  authPersonFirstName?: string;
  authPersonLastName?: string;
  preboardingDate?: Date;
  preboardingStatus?: string;

  /*
   * PB - Preboarding;
   */
  merchantStatus?: string;
  marsStatus?: string;
  phoneNumber?: string;
  alternatePhoneNumber?: string;
  emailId?: string;
  website?: string;
  businessAddress?: string;
  businessAddressPinCode?: string;
  businessAddressCity?: string;
  businessAddressDistrict?: string;
  businessAddressState?: string;
  residenceAddress?: string;
  residenceAddressPinCode?: string;
  residenceAddressCity?: string;
  residenceAddressDistrict?: string;
  residenceAddressState?: string;
  salesPerson?: EmployeeModel;
  indents?: Array<IndentModel>;
  firstIndent?: IndentModel;
  applicationDate?: Date;
  agreementDate?: Date;
  region?: number;
  companyProfile?: MerchantCompanyProfileModel;
  sponsorBank?: BankModel;

  //Institution will always EzSwype for which there will default entry in aggregatordistributor with Id "0" and type "I".
  institution?: AggregatorDistributorModel;
  aggregator?: AggregatorDistributorModel;
  superMerchant?: AggregatorDistributorModel;

  //Sub Distributor as group merchant from aggregatordistributor where type="D"
  //This sub-distributor must be child of distributor selected in supermerchant property.
  distributor?: AggregatorDistributorModel;
  aadhaar?: string;
  aadhaarFileName?: string;
  //aadhaarFile? : File;
  voterId?: string;
  voterIdFileName?: string;
  //voterIdFile?: File;
  pan?: string;
  panFileName?: string;
  //panFile? : File;
  gst?: string;
  gstFileName?: string;
  //gstFile? : File;
  otherId?: string;
  otherIdFileName?: string;
  //otherIdFile? : File;

  cancelledChequeNumber?: string;
  cancelledChequeFileName?: string;

  passportNumber?: string;
  passportExpiryDate?: Date;
  passportFileName?: string;
  nationality?: string;

  /*
   * M - Male
   * F - Female
   * T - Transgender
   */
  gender?: string;

  /*
   * Parners detail from class MerchantPartner
   */
  partners?: Array<MerchantPartnerModel>;

  /*
   * Financial Detail
   */
  /*
   * S -Small
   * B - Big
   */
  merchantCategory?: string;
  merchantMDRPlan?: number;

  /*
   * Y -Yes
   * N - No
   */

  partnerSharing?: string;

  /*
   * Y -Yes
   * N - No
   */

  loanReimbursement?: string;

  transRates?: Array<TransRatesModel>;

  /*
   * Terminals from class MerchantPartner
   */
  terminal?: Array<MerchantTerminalModel>;
  risk?: MerchantRiskModel;
  bankDetail?: MerchantBankDetailModel;
  bankmerchant?: BankMerchantModel;
  createdBy?: string;
  creationDate?: Date;
  lastModifiedBy?: string;
  lastModificationDate?: Date;
  merchantRefCode?: number;
  isBankMerchant?: boolean;
  /*
    R - Retail
    C - Corporate
  */
  merchantService?: string;
}

export class MerchantCompanyProfileModel {
  merchantCompanyProfileId?: number;
  merchant?: MerchantModel;
  companyLengalName?: string;
  companyBrandName?: string;
  companyRegAaddress?: string;
  companyRegAddressCity?: string;
  companyRegAddressDistrict?: string;
  companyRegAddressState?: string;
  companyRegAddressPinCode?: string;
  businessEntityType?: string;
  businessEntityDescription?: string;
  companyRegisterNumber?: string;
  companyRegistrationDate?: Date;
  companyNatureOfBusiness?: string;
  companyMCC?: string;
  companyMerchantType?: string;
  companyContactName?: string;
  companyContactMobile?: string;
  companyContactEmail?: string;

  /*
   * Y-Yes
   * N- No
   */
  statementRequired?: string;
  statementFrequency?: string;
  statementEmailIds?: string;
  comapnyEstablishYear?: Date;
  companyPan?: string;
  companyAddressProofFileName?: string;
  companyPanFileName?: string;
  //companyPanFile? : File;
  companyGstIn?: string;
  companyGstFileName?: string;
  companyEstablishmentLicenseFileName?: String;
  companyPartnershipDeedFileName?: String;
  companyCertificateOfIncorporationFileName?: string;
  companyMemorandumFileName?: string;
  companyOtherDoc1FileName?: string;
  companyOtherDoc2FileName?: string;
  cancelledChequeNumber?: string;
  companyCancelledChequeFileName?: string;
  companyBrDirListFileName?: string;
  companyElectricityBillFileName?: string;
  companyTelephoneBillFileName?: string;
  companyPhotgraphFileName?: string;
  companyTrustDeedFileName?: string;
  //companyGstFile? :File;
  turnoverYear?: string;
  turnoverAmount?: number;
  tcc?: string;
  companyAlternateMobile?: string;
  companyLandline?: string;
  companyRemarks?: string;
  otherDoc1?: string;
  otherDoc2?: string;
}

export class MerchantPartnerModel {
  merchantPartnerId?: number;
  merchant?: MerchantModel;
  title?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  mobileNumber?: string;
  alternateNumber?: string;
  emailId?: string;
  website?: string;
  aadhaar?: string;
  aadhaarFileName?: string;
  aadhaarFile?: File;
  voterId?: string;
  voterIdFileName?: string;
  //voterIdFile? : File;
  pan?: string;
  panFileName?: string;
  //panFile? : File;
  gst?: string;
  gstFileName?: string;
  //gstFile? : File;
  otherId?: string;
  otherIdFileName?: string;
  //otherIdFile? : File;
  passportNumber?: string;
  passportExpiryDate?: Date;
  nationality?: string;

  /*
   * M - Male
   * F - Female
   * T - Transgender
   */
  gender?: string;
  businessAddress?: string;
  businessAddressPinCode?: string;
  businessAddressCity?: string;
  businessAddressDistrict?: string;
  businessAddressState?: string;
}

export class MerchantBankDetailModel {
  merchantBankDetialId?: number;
  merchant?: MerchantModel;
  /*
   * D -Direct Credit
   * N - NEFT
   * I - IMPS
   */
  settelmentBy?: string;

  /*
   * S -Savings
   * C -current
   */
  accountType?: string;
  ifscCode?: string;
  bankName?: string;
  branchName?: string;
  city?: string;
  state?: string;
  pinCode?: string;
  /*
   * UTR
   * UPI
   * RTGS
   * NEFT
   */
  paymentMode?: string;
  settlmentFlag?: string;
  accountNumber?: string;
  micr?: string;
  /*
   * Y - Yes
   * N - No
   */
  achMandate?: string;
  umrn?: string;
  feeAddressChange?: number;
  paymentDetails?: string;
  remarks?: string;
  bankSourcingBranchId?: number;
}

export class MerchantTerminalModel {
  merchantTerminalId?: number;
  merchant?: MerchantModel;

  /*
   * To be Update from MARS
   */
  tid?: string;
  device?: DeviceStockModel;

  /*
   * MPOS
   * POS
   * PG
   */
  terminalType?: string;
  sim?: SIMStockModel;

  /*
   * S -Service Provider
   * M -Merchant
   * B - Bank
   */
  deviceOwned?: string;
	vpaId?: string;
  vpaLoginId?: string;
  storeName? : string;
	phoneNumber? :string;
	emailId? : string;
  /*
   * Y - Yes
   * N - No
   */
  regAddressAsBusinessAddress?: string;
  registeredAddress?: string;
  registeredAddressPinCode?: string;
  registeredAddressCity?: string;
  registeredAddressDistrict?: string;
  registeredAddressState?: string;
  deviceLocation?: string;
  rentalPlan?: number;

  /*
   * A - Advance
   * R - Regular
   */
  rentalType?: string;
  rentAmount?: number;
  rentStartDate?: Date;

  /*
   * D - Daily
   * M - Monthly
   * Q - Quarterly
   * H - Half-yealy
   * Y - Yearly
   * N - None
   */
  rentFrequency?: string;
  rentalThreshold?: string;
  devicePrice?: number;
  installationFee?: number;
  rentalMode?: string;
  maxUsageDaily?: number;
  maxUsageWeekly?: number;
  maxUsageMonthly?: number;
  velocityCheckMinute?: number;
  velocityCheckCount?: number;
  remarks?: string;
  status?: string;
  approvedBy?: string;
  approvalDate?: Date;
  actionstatus?: string;
  terminalRefCode?: number;
  createdBy?: string;
  creationDate?: Date;
  lastModifiedBy?: string;
  lastModificationDate?: Date;
  activationStatus?: string;
  activationDate?: Date;
  activationReason?: string;
  deActivationDate?: Date;
  marsStatus?: string;
}

export class MerchantRiskModel {
  merchantRiskId?: number;
  merchant?: MerchantModel;
  weekdayStartHour?: string;
  weekdayEndHour?: string;
  weekendStartHour?: string;
  weekendEndHour?: string;
  expectedCardBusiness?: number;
  averageBillAmount: number;
  maxUsageDaily?: number;
  maxUsageWeekly?: number;
  maxUsageMonthly?: number;
  velocityCheckMinute?: number;
  velocityCheckCount?: number;
  settlementDays?: number;
  cibil?: number;

  /*
   * S - Stand alone
   * I - Integrated
   */
  merchantTypeCode?: string;

  /*
   * Y -Yes
   * N -No
   */
  internationalCardAcc?: string;
  remarks?: string;
  transactionset?: Array<ChannelNetworkCodeValueModel>;
  transactionlimit?: Array<MerchantChannelLimitModel>;
}
export class MerchantFiles {
  key?: number;
  panFile?: File;
  gstFile?: File;
  aadhaarFile?: File;
  voterIdFile?: File;
  otherIdFile?: File;
  cancelledChequeFile?: File;
  passportFile?: File;
}
export class CompanyFiles {
  key: number;
  addressProofFile?: File;
  panFile?: File;
  gstFile?: File;
  aadhaarFile?: File;
  voterIdFile?: File;
  otherIdFile?: File;
  establishmentLicenseFile?: File;
  partnershipDeedFile?: File;
  certificateOfIncorporationFile?: File;
  memorandumFile?: File;
  cancelledChequeFile?: File;
  brDirListFile?: File;
  trustDeedFile?: File;
  electricityBillFile?: File;
  telephoneBillFile?: File;
  photographFile?: File;
  otherDoc1File?: File;
  otherDoc2File?: File;
}
export class PartnerFiles {
  key: number;
  panFile: File;
  gstFile: File;
  aadhaarFile: File;
  voterIdFile: File;
  otherIdFile: File;
}

export class MerchantChannelNetworkModel {
  id?: number;
  channel?: string;
  network?: string;
  transactionset?: Array<ChannelNetworkCodeValueModel>;
}

export class MerchantChannelLimitModel {
  id?: number;
  channel?: string;
  maxUsageDaily?: number;
  maxUsageWeekly?: number;
  maxUsageMonthly?: number;
  maximum?: number;
  minimum?: number;
}

export class BankMerchantModel {
  BankMerchantId?: number;
  merchant?: MerchantModel;
  sourcingBranchId?: number;
  numberOfTerminal?: number;
  rentalPlan?: number;
  rentAmount?: number;
  rentStartDate?: Date;
  /*
   * D - Daily
   * M - Monthly
   * Q - Quarterly
   * H - Half-yealy
   * Y - Yearly
   * N - None
   * 3Y - 3Year Life time
   * 0 - Life Time
   */
  rentFrequency?: string;
  internationalCardAcceptance?: boolean;
  debitCardLessThan2kRate?: number;
  debitCardGreaterThan2kRate?: number;
  creditCardNonPremiumRate?: number;
  creditCardPremiumRate?: number;
  creditCardSuperPremiumRate?: number;
  internationalCardRate?: number;
  cashAtPOSRate?: number;
//new fields
 /* domesticCardUpto2kOnus?: any;
  domesticCardUpto2kOffus?: any;
  domesticCardGreaterThan2kOnus?: any;
  domesticCardGreaterThan2kOffus?:any;
  creditCardStandardOnus?: any;
  creditCardPremiumOffus?: any;
  allInternationalCards?: any;
  commercialCardMDR?: any;*/
}
