import { AggregatorDistributorModel } from './aggregator.distributor.model';
export class RentalPlanModel {
    code? : number;
    name?: string;
    aggregator? : AggregatorDistributorModel;
    creationDate? : Date;
    rent? : number;
    /*
	 * D - Daily
	 * M - Monthly
	 * Q - Quarterly
	 * H - Half-yealy
	 * Y - Yearly
	 * N - None
	 * 3Y - 3Year Life time
	 * 0 - Life Time
	 */
	rentFrequency? : string;
	description? : string;
	status? : string;
	marsCode ? : number;
}