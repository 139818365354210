import { Injectable } from "@angular/core";
import { BankModel } from "../models/bank.model";
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { SimpleMessage } from "../framework/modules/util.class";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AppSettings } from "../framework/config/AppSettings";

@Injectable()
export class BankService {
  public banklist: BankModel[];

  constructor(private http: HttpClient, private appsetting: AppSettings) {}
  getBankList() {
    if (this.banklist) {
      return this.banklist;
    } else {
      this.getBankListApi().subscribe(res => {
        if (res["success"]) {
          this.banklist = res["result"];
          return this.banklist;
        } else {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Error while fetching bank list!";
          objmsg.btnClass = "btn-error";
          return this.banklist;
        }
      });
    }
  }
  loadBankList(refresh: boolean) {
    if (this.banklist == undefined || refresh) {
      this.getBankListApi().subscribe(res => {
        if (res["success"]) {
          this.banklist = res["result"];
        } else {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Error while fetching transaction category list!";
          objmsg.btnClass = "btn-error";
        }
      });
    }
  }

  getBankListApi() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/banknamelist", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }
}
