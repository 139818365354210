import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: "ag-clickable",
  template: `
    <button (click)="click()" class="btn btn-info">
      Show
    </button>
  `,
  styles: [
    `
      .btn {
        line-height: 0.5;
        width: 100%;
      }
    `
  ]
})
export class TransactionClickComponent implements ICellRendererAngularComp {
  @Input() cell: any;
  // @Output() onClicked = new EventEmitter<any>();

  public params: any;
  click(): void {
    //this.onClicked.emit(this.params.node);
    /*this.params.context.componentParent.showClick(
      `Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`
    );*/
    this.params.context.componentParent.showClick(this.params.node);
  }
  refresh(): boolean {
    return false;
  }
  agInit(params: any): void {
    this.params = params;
  }
}
