import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import {
  GridPage,
  gridColumns,
  GridtableComponent,
} from "../../framework/util/components/gridtable/gridtable.component";
import { AuthService } from "../../framework/services/auth.service";
import { global } from "../../framework/modules/global";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Tools } from "../../framework/modules/tools";
import { SIMStockModel } from "../../models/simstock.model";
import { SIMStockService } from "../../services/sim.service";
import { SIMOperatorService } from "../../services/simoperator.service";
import {
  NgInformationModalContent,
  NgYesNoModalContent,
} from "src/app/framework/util/components/modalcomponent/modal-content";

import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { ExcelService } from "src/app/framework/services/excel.service";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
class CsvFieldHeaders {
  name: string;
  index?: number;
}
class ValidationErr {
  line: number;
  error: string;
}
class SearchField {
  searchText: string;
  invoiceDate: Date[];
  entryDate: Date[];
  simStatus: String;
  constructor() {
    this.searchText = "";
    this.simStatus = undefined;
  }
}
@Component({
  selector: "app-simstock",
  templateUrl: "./simstock.component.html",
  styleUrls: ["./simstock.component.css"],
})
export class SimstockComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "",
      columnFieldName: "simStockId",
      columnClasses: "c-col-pc-0",
    },
    {
      columnHeader: "SIM Number",
      columnFieldName: "simNumber",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "MSISDN",
      columnFieldName: "msisdn",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "SIM Operator",
      columnFieldName: "simOperator",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "Invoice No",
      columnFieldName: "invoiceNumber",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "Invoice Date",
      columnFieldName: "invoiceDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "Entry date",
      columnFieldName: "stockEntryDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "Remarks",
      columnFieldName: "remarks",
      columnClasses: "c-col-pc-18",
    },
    {
      columnHeader: "Status",
      columnFieldName: "simStatus",
      columnClasses: "c-col-pc-7",
      callbackFunction: this.getSIMStatusText,
    },
  ];
  validationErrorCols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "Line Number",
      columnFieldName: "line",
      columnClasses: "c-col-pc-15",
    },
    { columnHeader: "Error", columnFieldName: "error" },
  ];

  csvDataCols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "SIM Number",
      columnFieldName: "simNumber",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "MSISDN",
      columnFieldName: "msisdn",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "SIM Operator",
      columnFieldName: "simOperator",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "Invoice No",
      columnFieldName: "invoiceNumber",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "Invoice Date",
      columnFieldName: "invoiceDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "Status",
      columnFieldName: "simStatus",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.getSIMStatusText,
    },
    { columnHeader: "Remarks", columnFieldName: "remarks" },
  ];

  sortFields = [
    { name: "simNumber", display: "SIM Number" },
    { name: "msisdn", display: "MSISDN" },
    { name: "simOperator", display: "SIM Operator" },
    { name: "invoiceNumber", display: "Invoice Number" },
    { name: "invoiceDate", display: "Invoice Date" },
    { name: "stockEntryDate", display: "Entry Date" },
    { name: "simStatus", display: "Status" },
  ];

  topbartext: TopbarText[] = [
    { key: "simstock", displayText: "SIM Stock List" },
  ];
  searchField: SearchField;
  simstocklist: Array<SIMStockModel>;
  page: GridPage;
  _menuId: string;
  _action: string = "";
  simstock: SIMStockModel;
  _simstock: any;
  isSIMStockDetailScreen: boolean = false;
  _isChange: boolean = false;
  sort: SortClass;
  entryMode: string = "E";
  csvFileToUpload: File;
  csvFileHeader: string[] = [
    "SIMNumber",
    "MSISDN",
    "Operator",
    "InvoiceNumber",
    "InvoiceDate",
    "Status",
    "Remarks",
  ];
  isCsvValidationError: boolean = false;
  isCsvValidData: boolean = false;

  csvData: Array<SIMStockModel>;
  csvValidationErrors: ValidationErr[];

  constructor(
    public tools: Tools,
    private ngbModalService: NgbModal,
    private objGlobal: global,
    private auth: AuthService,
    public simoperatorservice: SIMOperatorService,
    private objsimstockservice: SIMStockService,
    private excelservice: ExcelService,
    private csvservice: ExporttocsvService
  ) {
    this.entryMode = "E";
    this.csvFileToUpload = undefined;
    this.csvData = undefined;
    this.isCsvValidationError = false;
    this.isCsvValidData = false;
    this.csvValidationErrors = undefined;
    this.page = new GridPage();
    this.searchField = new SearchField();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/simstock");
    this.objGlobal.setActiveMenu(this._menuId);
    this.simstock = new SIMStockModel();
    this.sort = new SortClass();
    this.sort.sortDirection = "A";
    this.sort.sortFieldName = "simNumber";
    this.simoperatorservice.loadSIMOperatorName();
    //this.getSIMStockList();
  }

  ngOnInit() {}
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  getSIMstocks() {
    this.getSIMStockList();
  }
  onAddNew() {
    this._action = "new";
    this.entryMode = "E";
    this.csvFileToUpload = undefined;
    this.csvData = undefined;
    this.isCsvValidationError = false;
    this.isCsvValidData = false;
    this.csvValidationErrors = undefined;
    this.isSIMStockDetailScreen = true;
    this.topbartext = [{ key: "simstock", displayText: "Add SIM Stock" }];
    this.simstock = new SIMStockModel();
    this.simstock.stockEntryDate = new Date();
    this._simstock = this.tools.copyFullObject(this.simstock);
    //this.convertDateField(this._simstock);
  }
  getAction() {
    return this._action;
  }
  bulksimupload() {
    this.objsimstockservice.saveBulkSIMStock(this.csvData).subscribe(
      (data) => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        if (data["success"]) {
          objmsg.title = "Information";
          objmsg.message = "SIM Stock uploaded successfully!";
          objmsg.btnClass = "btn-info";
          this._isChange = true;
          this.csvData = undefined;
          this.csvValidationErrors = undefined;
          this.isCsvValidData = false;
          this.isCsvValidationError = false;
          this.csvFileToUpload = undefined;
          //let inputfile=$("#csvToUpload");
          //if(inputfile && inputfile[0]) {
          //  inputfile[0].value=undefined;
          //};
        } else {
          objmsg.title = "Error";
          objmsg.message = "Failed to save SIM stock!";
          objmsg.btnClass = "btn-error";
        }
        this.tools.simpleMessage(objmsg);
      },
      (error) => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to save bulk SIM stock!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  saveSIMStock() {
    if (this.entryMode == "U") {
      this.bulksimupload();
    } else {
      this.objsimstockservice.saveSIMStock(this.simstock).subscribe(
        (data) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          if (data["success"]) {
            objmsg.title = "Information";
            objmsg.message = "SIM Stock saved successfully!";
            objmsg.btnClass = "btn-info";
            this._isChange = true;
            this._simstock = this.tools.copyFullObject(this.simstock);
            this.simstock.simStockId = data["result"];
          } else if (data["message"] == "InputDataNotProvided") {
            objmsg.title = "Error";
            objmsg.message = "SIM Stock input data not available!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "SIMNumberNotInInputData") {
            objmsg.title = "Error";
            objmsg.message = "SIM number not provided available!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "SIMNumberAlreadyExist") {
            objmsg.title = "Error";
            objmsg.message = "SIM Number already exists in stock!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "MSISDNAlreadyExist") {
            objmsg.title = "Error";
            objmsg.message = "MSISDN already exists in stock!";
            objmsg.btnClass = "btn-warning";
          } else {
            objmsg.title = "Error";
            objmsg.message = "Failed to save SIM stock!";
            objmsg.btnClass = "btn-warning";
            this.tools.simpleMessage(objmsg);
          }
          this.tools.simpleMessage(objmsg);
        },
        (error) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to save SIM stock!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }
  isModified() {
    //    let user1=this.user;
    //    let user2=this._user;
    if (!(this.simstock && this._simstock)) {
      return false;
    }
    if (this.simstock.simNumber != this._simstock.simNumber) {
      return true;
    } else if (this.simstock.msisdn != this._simstock.msisdn) {
      return true;
    } else if (this.simstock.simOperator != this._simstock.simOperator) {
      return true;
    } else if (
      this.simstock.allowMultiTerminal != this._simstock.allowMultiTerminal
    ) {
      return true;
    } else if (
      this.simstock.stockEntryDate &&
      this._simstock.stockEntryDate &&
      this.simstock.stockEntryDate.toString() !=
        this._simstock.stockEntryDate.toString()
    ) {
      return true;
    } else if (
      this.simstock.invoiceDate &&
      this._simstock.invoiceDate &&
      this.simstock.invoiceDate.toString() !=
        this._simstock.invoiceDate.toString()
    ) {
      return true;
    } else if (this.simstock.invoiceNumber != this._simstock.invoiceNumber) {
      return true;
    } else if (this.simstock.simStatus != this._simstock.simStatus) {
      return true;
    } else if (this.simstock.remarks != this._simstock.remarks) {
      return true;
    }

    return false;
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (this.entryMode == "U") {
      if (this.isCsvValidData) {
        isDisableSave = false;
      }
    } else if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }
  disableCancel(f: any) {
    return !this.isModified();
  }
  getSIMStockList() {
    this.objsimstockservice
      .getSIMStockList(this.page, this.searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.simstocklist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to SIM Stock list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (error) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get SIM stock list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  selectSIMStock() {}
  deleteSIMStock(e: any) {}
  getSIMStatusText(codeValue: String) {
    let value: String = "";
    switch (codeValue) {
      case "A": {
        value = "Active";
        break;
      }
      case "D": {
        value = "Deactive";
        break;
      }
    }
    return value;
  }
  disablePreview() {
    return !this.csvFileToUpload;
  }
  onSIMStockRowDblClick(simstocklistval: any) {
    this._action = "update";
    this.entryMode = "E";
    this.csvFileToUpload = undefined;
    this.csvData = undefined;
    this.isCsvValidationError = false;
    this.isCsvValidData = false;
    this.csvValidationErrors = undefined;
    this.isSIMStockDetailScreen = true;
    this.topbartext = [{ key: "simstock", displayText: "SIM Stock" }];
    //this.router.navigate(['user']);
    this.getSIMStockDetail(simstocklistval.simStockId);
  }

  getSIMStockDetail(_id) {
    if (_id) {
      this.objsimstockservice.getSIMStockDetail(_id).subscribe(
        (data) => {
          if (data["success"]) {
            this.simstock = data["result"];
            if (this.simstock.invoiceDate) {
              this.simstock.invoiceDate = new Date(this.simstock.invoiceDate);
            }
            if (this.simstock.stockEntryDate) {
              this.simstock.stockEntryDate = new Date(
                this.simstock.stockEntryDate
              );
            }
            //this._simstock=Object.assign({},this.simstock);
            this.convertDateField(this.simstock);
            this._simstock = this.tools.copyFullObject(this.simstock);

            //this.convertDateField(this._simstock);
            //console.log("Test");
          } else {
            alert("Failed to get SIM stock!");
          }
        },
        (error) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get SIM stock!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }
  onCancel() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this._action = "";
            //    this.isUserDetailScreen=false;
          }
        },
        (reason) => {}
      );
    } else {
    }
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Device stock has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this._action = "";
            this.isSIMStockDetailScreen = false;
            this.topbartext = [
              { key: "simstock", displayText: "SIM Stock List" },
            ];
            if (this._isChange == true) {
              this._isChange = false;
              this.getSIMStockList();
            }
          }
        },
        (reason) => {}
      );
    } else {
      this._action = "";
      this.isSIMStockDetailScreen = false;
      this.topbartext = [{ key: "simstock", displayText: "SIM Stock List" }];
      if (this._isChange == true) {
        this._isChange = false;
        this.getSIMStockList();
      }
    }
  }
  convertDateField(simStock: SIMStockModel) {
    if (simStock.invoiceDate) {
      simStock.invoiceDate = new Date(simStock.invoiceDate);
    }
    if (simStock.stockEntryDate) {
      simStock.stockEntryDate = new Date(simStock.stockEntryDate);
    }
  }

  csvFileInput(file: File) {
    this.csvFileToUpload = file;
    this.csvData = undefined;
    this.csvValidationErrors = undefined;
    this.isCsvValidData = false;
    this.isCsvValidationError = false;
  }

  previewUploadData() {
    this.isCsvValidationError = false;
    this.isCsvValidData = false;
    if (this.csvFileToUpload) {
      var reader = new FileReader();
      reader.readAsText(this.csvFileToUpload);
      const fileName = this.csvFileToUpload.name;
      this.csvData = new Array<SIMStockModel>();
      this.csvValidationErrors = undefined;
      reader.onload = (data) => {
        let csvFileData = reader.result;
        let csvFileRecordsArray = (csvFileData as string).split(/\r\n|\n/);
        let cnt: number = 0;
        while (
          csvFileRecordsArray[cnt] != undefined &&
          csvFileRecordsArray[cnt].trim() == ""
        ) {
          cnt = cnt + 1;
        }
        if (csvFileRecordsArray[cnt]) {
          const headers = csvFileRecordsArray[cnt].match(/(“[^”]*”)|[^,]+/g);
          const fieldHeaderIndex = this.csvHeaderIndex(headers);
          //console.log(fieldHeaderIndex);
          if (!this.checkIfHeaderMissing(fieldHeaderIndex)) {
            cnt = cnt + 1;
            for (let i = cnt; i < csvFileRecordsArray.length; i++) {
              let rowdata = csvFileRecordsArray[i].match(/(“[^”]*”)|[^,]+/g);
              if (rowdata != undefined && rowdata.length > 0 && rowdata[0]) {
                let simStock: SIMStockModel = new SIMStockModel();
                const linenumber = i + 1;
                const validationerror = this.validateAndSetCsvRowData(
                  fieldHeaderIndex,
                  rowdata,
                  simStock
                );
                if (!validationerror) {
                  this.csvData.push(simStock);
                } else {
                  if (!this.csvValidationErrors) {
                    this.csvValidationErrors = new Array<ValidationErr>();
                  }
                  this.csvValidationErrors.push({
                    line: linenumber,
                    error: validationerror,
                  });
                }
              }
              //this.csvRecords.push(rowdata);
            }
          }
        }
        if (
          this.csvValidationErrors &&
          this.csvValidationErrors.length > 0 &&
          this.csvValidationErrors[0]
        ) {
          this.isCsvValidationError = true;
          this.isCsvValidData = false;
        } else {
          this.isCsvValidationError = false;
          this.isCsvValidData = true;
        }
      };
      const _this = this;
      reader.onerror = function () {
        alert("Unable to read " + fileName);
        _this.isCsvValidationError = false;
        _this.isCsvValidData = false;
      };
    } else {
      alert("Please select csv file to upload!");
      //this.csvRecords = [];
    }
  }

  csvHeaderIndex(fileHeader: string[]) {
    let fieldswithindex: Array<CsvFieldHeaders> = new Array<CsvFieldHeaders>();
    for (let i = 0; i < this.csvFileHeader.length; i++) {
      fieldswithindex.push({ name: this.csvFileHeader[i] });
      let found = false;
      for (let j = 0; j < fileHeader.length && !found; j++) {
        if (this.csvFileHeader[i] == fileHeader[j]) {
          found = true;
          fieldswithindex[i].index = j;
        }
      }
    }
    return fieldswithindex;
  }

  checkIfHeaderMissing(fileheaderelements: Array<CsvFieldHeaders>) {
    const missingindexelements = fileheaderelements.filter(
      (obj) => obj.index == undefined
    );
    let missingfields: string[] = new Array<string>();
    if (missingindexelements.length <= 0) {
      return false;
    }
    for (let i = 0; i < missingindexelements.length; i++) {
      missingfields.push(missingindexelements[i].name);
    }
    const fields = missingfields.join(",");
    if (!this.csvValidationErrors) {
      this.csvValidationErrors = Array<ValidationErr>();
    }
    this.csvValidationErrors.push({
      line: 1,
      error: "Missing header field(s) - " + fields,
    });
  }

  validateAndSetCsvRowData(
    fieldsArr: CsvFieldHeaders[],
    csvRecord: string[],
    simStock: SIMStockModel
  ) {
    let tempvalidationerror: string[] = new Array<string>();
    for (let i = 0; i < fieldsArr.length; i++) {
      let fieldVal = csvRecord[fieldsArr[i].index];
      if (fieldsArr[i].name == "SIMNumber") {
        const tfilternedSimNumber = this.csvData.filter(
          (obj) => obj.simNumber == fieldVal
        );
        let simNumberError = false;
        if (fieldVal == undefined || fieldVal == "") {
          tempvalidationerror.push("SIM number should not be blank!");
          simNumberError = true;
        }
        if (tfilternedSimNumber && tfilternedSimNumber[0]) {
          tempvalidationerror.push("Duplicate SIM number!");
          simNumberError = true;
        }
        if (fieldVal != undefined && fieldVal.length > 20) {
          tempvalidationerror.push("Length of SIM number is more than 20");
          simNumberError = true;
        }
        if (!simNumberError) {
          simStock.simNumber = fieldVal;
        }
      } else if (fieldsArr[i].name == "MSISDN") {
        let msisdnNumberError = false;
        if (fieldVal == undefined || fieldVal == "") {
          tempvalidationerror.push("MSISDN should not be blank!");
          msisdnNumberError = true;
        }
        if (fieldVal != undefined && fieldVal.length > 13) {
          tempvalidationerror.push("Length of MSISDN is more than 13");
          msisdnNumberError = true;
        }
        if (!msisdnNumberError) {
          simStock.msisdn = fieldVal;
        }
      } else if (fieldsArr[i].name == "Operator") {
        if (fieldVal == undefined || fieldVal == "") {
          tempvalidationerror.push("SIM Operator should not be blank!");
        } else {
          const selSimOperator = this.simoperatorservice.simOperatorList.filter(
            (obj) => obj.simOperator == fieldVal
          );
          if (selSimOperator && selSimOperator[0]) {
            simStock.simOperator = selSimOperator[0].simOperator;
          } else {
            tempvalidationerror.push("Invalid SIM Operator");
          }
        }
      } else if (fieldsArr[i].name == "InvoiceNumber") {
        let invoiceNumberError = false;
        if (fieldVal == undefined || fieldVal == "") {
          tempvalidationerror.push("Invoice number should not be blank!");
          invoiceNumberError = true;
        }
        if (fieldVal != undefined && fieldVal.length > 30) {
          tempvalidationerror.push("Length of invoice number is more than 30");
          invoiceNumberError = true;
        }
        if (!invoiceNumberError) {
          simStock.invoiceNumber = fieldVal;
        }
      } else if (fieldsArr[i].name == "InvoiceDate") {
        if (isNaN(Date.parse(fieldVal))) {
          tempvalidationerror.push("Invalid invoice date!");
        } else {
          simStock.invoiceDate = new Date(fieldVal);
        }
        Date.parse(fieldVal);
      } else if (fieldsArr[i].name == "Status") {
        if (fieldVal.toLowerCase() == "active") {
          simStock.simStatus = "A";
        } else if (fieldVal.toLowerCase() == "deactive") {
          simStock.simStatus = "D";
        } else {
          tempvalidationerror.push("SIM Status should be Active or Deactive!");
        }
      } else if (fieldsArr[i].name == "Remarks") {
        simStock.remarks = fieldVal;
      }
    }
    if (
      tempvalidationerror &&
      tempvalidationerror.length > 0 &&
      tempvalidationerror[0]
    ) {
      return tempvalidationerror.join("\n");
    } else {
      return undefined;
    }
  }

  onExcelClick() {
    this.getSIMStockListForExcel();
  }

  getSIMStockListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.objsimstockservice
      .getSIMStockList(page, this.searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<SIMStockModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "simstock"
            );
          } else {
            alert("Failed to get user list!");
          }
        },
        (error) => {
          alert("Failed to get sim stock list!");
        }
      );
  }

  savebuttontext() {
    if (this.entryMode == "U") {
      return "Upload";
    } else {
      return "Save";
    }
  }

  setAllowMultiTerminal() {
    this.simstock.allowMultiTerminal = !this.simstock.allowMultiTerminal;
  }
}
