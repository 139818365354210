import { BankIFSCModel } from "./bank.ifsc.model";

export class BankSourcingBranchModel {
  bankSourcingBranchId?: number;
  aggregatorId?: number;
  aggregatorName?: string;
  sourcingBranchCode?: string;
  sourcingBranchName?: string;
  bankIFSC?: BankIFSCModel;
}
