export class MARSErrorResponse {
  responseDateTime?: Date;
  status?: string;
  error?: string;
  debugId?: string;
  name?: string;
  message?: string;
  errorDetails?: Array<MARSValidationErrorDetail>;
}
export class AxisErrorResponse {
  RESDTTM?: Date;
  status?: string;
  RESDESC?: string;
  REQRRN?: string;
  name?: string;
  message?: string;
  errorDetails?: Array<MARSValidationErrorDetail>;
}
export class MARSValidationErrorDetail {
  field?: string;
  value?: string;
  location?: string;
  issue?: string;
}
