import { Component, OnInit, Input } from '@angular/core';
import {ImageconfigService} from '../../../services/imageconfig.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.css']
})
export class PageheaderComponent implements OnInit {
  @Input()
  pageHeader_1 : string;
  @Input()
  pageHeader_2 : string;
  constructor(private router : Router, public imagePath:ImageconfigService) { }

  ngOnInit() {
  }

  onLogoClick() {
    this.router.navigate(['home']);
  }

}
