import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../../framework/config/AppSettings";
import { Tools } from "../../framework/modules/tools";
import { GridPage } from "../../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../../framework/modules/util.class";

@Injectable()
export class TransactionService {
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}

  getTransactions(searchField, pageinfo: GridPage, sortField: SortClass) {
    //console.log("Backend service called");
    let header = new HttpHeaders();
    let httpparam: HttpParams = new HttpParams();
    const searchDateFrom = this.tools.formatDateToDDMMMYY(searchField.dateFrom);
    const searchDateTill = this.tools.formatDateToDDMMMYY(searchField.dateTill);
    httpparam = httpparam.append("dateFrom", searchDateFrom);
    httpparam = httpparam.append("dateTill", searchDateTill);
    if (searchField.amountFrom) {
      httpparam = httpparam.append("amountFrom", searchField.amountFrom);
    }
    if (searchField.amountTo) {
      httpparam = httpparam.append("amountTo", searchField.amountTo);
    }
    if (searchField.transactionType) {
      httpparam = httpparam.append(
        "transactionType",
        searchField.transactionType
      );
    }
    if (searchField.terminalId) {
      httpparam = httpparam.append("terminalId", searchField.terminalId);
    }
    if (searchField.cardNumber) {
      httpparam = httpparam.append("cardNumber", searchField.cardNumber);
    }
    if (searchField.status) {
      httpparam = httpparam.append(
        "status",
        searchField.status
      );
    }
    if (searchField.rrn) {
      httpparam = httpparam.append("rrn", searchField.rrn);
    }
    httpparam = httpparam.append("page", pageinfo.number.toString());
    httpparam = httpparam.append("size", pageinfo.size.toString());
    if (sortField.sortFieldName) {
      httpparam = httpparam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpparam = httpparam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/transactions", {
        headers: header,
        params: httpparam,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }

  getTransaction(id: number) {
    let header = new HttpHeaders();
    let httpparam: HttpParams = new HttpParams();
    httpparam = httpparam.append("id", id.toString());

    return this.http
      .get(this.appsetting.get("server-url") + "/api/transaction", {
        headers: header,
        params: httpparam,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }

  getUpiTransactions(searchField, pageinfo: GridPage, sortField: SortClass) {
    //console.log("Backend service called");
    let header = new HttpHeaders();
    let httpparam: HttpParams = new HttpParams();
    const searchDateFrom = this.tools.formatDateToDDMMMYY(searchField.dateFrom);
    const searchDateTill = this.tools.formatDateToDDMMMYY(searchField.dateTill);
    httpparam = httpparam.append("dateFrom", searchDateFrom);
    httpparam = httpparam.append("dateTill", searchDateTill);
    if (searchField.amountFrom) {
      httpparam = httpparam.append("amountFrom", searchField.amountFrom);
    }
    if (searchField.amountTo) {
      httpparam = httpparam.append("amountTo", searchField.amountTo);
    }
    if (searchField.customerVpaId) {
      httpparam = httpparam.append(
        "customerVpaId",
        searchField.customerVpaId
      );
    }
    if (searchField.merchantVpaId) {
      httpparam = httpparam.append(
        "merchantVpaId",
        searchField.merchantVpaId
      );
    }
    if (searchField.terminalId) {
      httpparam = httpparam.append("terminalId", searchField.terminalId);
    }
    if (searchField.rrn) {
      httpparam = httpparam.append("rrn", searchField.rrn);
    }
    httpparam = httpparam.append("page", pageinfo.number.toString());
    httpparam = httpparam.append("size", pageinfo.size.toString());
    if (sortField.sortFieldName) {
      httpparam = httpparam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpparam = httpparam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/upitransactions", {
        headers: header,
        params: httpparam,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }

  getUPITransaction(id: number) {
    let header = new HttpHeaders();
    let httpparam: HttpParams = new HttpParams();
    httpparam = httpparam.append("id", id.toString());

    return this.http
      .get(this.appsetting.get("server-url") + "/api/upitransaction", {
        headers: header,
        params: httpparam,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }
  getTransactionsva(startDate: string, endTime: string) {
    const headers = new HttpHeaders()
    let header = new HttpHeaders();
    let httpparam: HttpParams = new HttpParams();
    const body = {
      startDate: startDate,
      endTime: endTime
    };

    return this.http.post('https://eznew.ezswype.in/api/terminal/transactions', body, {
      headers: header,
      // observe: 'response', 
      // responseType: 'text'
    })
    .map(res => res);
  }


  getTransactionsv(startDate: string, endTime: string) {
    let headers = new HttpHeaders();
    headers.append("Content-type", "application/json")
    const body = {
      startDate: startDate,
      endTime: endTime
    };
    return this.http
    //.post(this.appsetting.get("server-url") + "/api/transactions/list", body, {
     .post(this.appsetting.get("server-new_url") + "/api/terminal/transactions", body, {
        headers: headers
      })
      .map(res => res);
  }
}
