import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
//import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {
  NgModule,
  Injectable,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { AgGridModule } from "ag-grid-angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
//import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HomeComponent } from "./components/home/home.component";
import { JwtModule } from "@auth0/angular-jwt";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DevicestockComponent } from "./components/devicestock/devicestock.component";
import {
  DeviceStockService,
  DeviceReturnService,
} from "./services/device.service";
import { ProductService } from "./services/product.service";
import { SupplierService } from "./services/supplier.service";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SimstockComponent } from "./components/simstock/simstock.component";
import { SIMStockService, SIMReturnService } from "./services/sim.service";
import { SIMOperatorService } from "./services/simoperator.service";
import { DevicereturnComponent } from "./components/devicereturn/devicereturn.component";
import { SimreturnComponent } from "./components/simreturn/simreturn.component";
import { IndentComponent } from "./components/indent/indent.component";
import { IndentService } from "./services/indent.service";
import { MerchantService } from "./services/merchant.service";
import { EmployeeService } from "./services/employee.service";
import { AggregatorDistributorService } from "./services/aggregator.distributor.service";
import { TransCategoryService } from "./services/transcategory.service";
import { AggregatorDistributorComponent } from "./components/aggregator-distributor/aggregator-distributor.component";
import { BankService } from "./services/bank.service";
import { MerchantPreboardingComponent } from "./components/merchant-preboarding/merchant-preboarding.component";
import { MerchantPreboardingService } from "./services/merchant.preboarding.service";
import { IndentdetailComponent } from "./components/indentdetail/indentdetail.component";
import { MerchantlistComponent } from "./components/merchantlist/merchantlist.component";
import { MerchantdetailComponent } from "./components/merchantdetail/merchantdetail.component";
import { PincodeService } from "./services/pincode.service";
import { BankIFSCService } from "./services/bank.ifsc.service";
import { MDRPlanComponent } from "./components/mdrplan/mdrplan.component";
import { RentalPlanComponent } from "./components/rental-plan/rental-plan.component";
import { MdrplandetailComponent } from "./components/mdrplandetail/mdrplandetail.component";
import { MDRPlanService } from "./services/mdr.plan.service";
import { TransratesComponent } from "./components/transrates/transrates.component";
import { RentalplanService } from "./services/rentalplan.service";
import { RentalplanDetailComponent } from "./components/rentalplan-detail/rentalplan-detail.component";
import { EmployeeComponent } from "./components/employee/employee.component";
import { BankIfscComponent } from "./components/bank-ifsc/bank-ifsc.component";
import { MCCComponent } from "./components/mcc/mcc.component";
import { MCCService } from "./services/mcc.service";
import { AggregatorDistributorListComponent } from "./components/aggregator-distributor-list/aggregator-distributor-list.component";
import { PanDirective } from "./directives/pan.directive";
import { PincodeComponent } from "./components/pincode/pincode.component";
import { DevicebillingsummaryComponent } from "./components/devicebillingsummary/devicebillingsummary.component";
import { TerminalActivationReportComponent } from "./components/terminal-activation-report/terminal-activation-report.component";
import { TerminalReportService } from "./services/terminalreports.service";
import { ChangepasswordComponent } from "./components/changepassword/changepassword.component";
import { RegionMasterService } from "./services/region.master.service";
import { MarsErrorMsgComponent } from "./components/mars-error-msg/mars-error-msg.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { ForgetpasswordComponent } from "./components/forgetpassword/forgetpassword.component";
import { BankmerchantpreboardComponent } from "./components/bankmerchantpreboard/bankmerchantpreboard.component";
import { BankmerchantpreboardDetailComponent } from "./components/bankmerchantpreboard-detail/bankmerchantpreboard-detail.component";
import { BankMerchantService } from "./services/bank.merchant.service";
import { FrameworkModule } from "./framework/framework.module";
import { ReportsModule } from "./reports/reports.module";
import { IndentFormComponent } from "./components/indent-form/indent-form.component";
import { ReportService } from "./services/report.service";
import { DeviceStockSummaryComponent } from "./components/device-stock-summary/device-stock-summary.component";
import { MasterPricingPlanComponent } from "./components/master-pricing-plan/master-pricing-plan.component";
import { MasterPricingPlanService } from "./services/master.pricing.plan.service";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ActiveterminalreportComponent } from "./components/activeterminalreport/activeterminalreport.component";
import { ActiveTerminalSummaryReportComponent } from "./components/active-terminal-summary-report/active-terminal-summary-report.component";
import { TerminalActivationSummaryReportComponent } from "./components/terminal-activation-summary-report/terminal-activation-summary-report.component";
import { TerminalDeactivationMessageComponent } from "./components/terminal-deactivation-message/terminal-deactivation-message.component";
import { TerminalDeactivationSummaryReportComponent } from './components/terminal-deactivation-summary-report/terminal-deactivation-summary-report.component';
import { TerminalwiseSettlementReportComponent } from './components/terminalwise-settlement-report/terminalwise-settlement-report.component';
import { SettlementSummaryReportComponent } from './components/settlement-summary-report/settlement-summary-report.component';
import { MerchantProfileComponent } from './components/merchant-profile/merchant-profile.component';
import { ProductComponent } from './components/product/product.component';
import { SupplierComponent } from './components/supplier/supplier.component';
import { UpiMerchantUploadComponent } from './components/upi-merchant-upload/upi-merchant-upload.component';
import { UpiMerchantUploadConfig} from './config/upi.merchant.upload.config';
import { UploadErrListComponent } from './components/upload-err-list/upload-err-list.component'
import { CanDeactivateGuard } from './framework/guards/can-deactivated-guard';
import { MerchantUploadHistoryComponent } from './components/merchant-upload-history/merchant-upload-history.component';
import { SourcingBranchComponent } from './components/sourcing-branch/sourcing.branch.component';
import { SourcingBranchService } from './services/sourcing-branch.service';
import { StaticeQrCodesComponent } from './components/statice-qr-codes/statice-qr-codes.component';
import { TerminalStatusRequestMessageComponent } from './components/terminal-status-request-message/terminal.status.request.message.component';
import { BoardingDashboardComponent } from './components/boarding-dashboard/boarding-dashboard.component';
import { MerchantSettlementReportComponent } from './components/merchant-settlement-report/merchant-settlement-report.component';
import { MerchantSettlementSummaryReportComponent } from './components/merchant-settlement-summary-report/merchant-settlement-summary-report.component';
import { MerchantPortalSettlementSummaryComponent } from './components/merchant-portal-settlement-summary/merchant-portal-settlement-summary.component';
import { RevenueSummaryComponent } from './components/revenue-summary/revenue-summary.component';
import { DatewiseTransactionSummaryComponent } from './components/datewise-transaction-summary/datewise-transaction-summary.component';
import { TransactionSummaryComponent } from './components/transaction-summary/transaction-summary.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    DevicestockComponent,
    SimstockComponent,
    DevicereturnComponent,
    SimreturnComponent,
    IndentComponent,
    AggregatorDistributorComponent,
    MerchantPreboardingComponent,
    IndentdetailComponent,
    MerchantlistComponent,
    MerchantdetailComponent,
    MDRPlanComponent,
    RentalPlanComponent,
    MdrplandetailComponent,
    TransratesComponent,
    RentalplanDetailComponent,
    EmployeeComponent,
    BankIfscComponent,
    MCCComponent,
    AggregatorDistributorListComponent,
    PanDirective,
    PincodeComponent,
    DevicebillingsummaryComponent,
    TerminalActivationReportComponent,
    ChangepasswordComponent,
    MarsErrorMsgComponent,
    ForgetpasswordComponent,
    BankmerchantpreboardComponent,
    BankmerchantpreboardDetailComponent,
    IndentFormComponent,
    DeviceStockSummaryComponent,
    MasterPricingPlanComponent,
    ActiveterminalreportComponent,
    ActiveTerminalSummaryReportComponent,
    TerminalActivationSummaryReportComponent,
    TerminalDeactivationMessageComponent,
    TerminalDeactivationSummaryReportComponent,
    TerminalwiseSettlementReportComponent,
    SettlementSummaryReportComponent,
    MerchantProfileComponent,
    ProductComponent,
    SupplierComponent,
    UpiMerchantUploadComponent,
    UploadErrListComponent,
    MerchantUploadHistoryComponent,
    SourcingBranchComponent,
    StaticeQrCodesComponent,
    TerminalStatusRequestMessageComponent,
    BoardingDashboardComponent,
    MerchantSettlementReportComponent,
    MerchantSettlementSummaryReportComponent,
    MerchantPortalSettlementSummaryComponent,
    RevenueSummaryComponent,
    DatewiseTransactionSummaryComponent,
    TransactionSummaryComponent
  ],
  imports: [
    NgbModalModule,
    BrowserModule,
    BrowserAnimationsModule,
    AgGridModule.withComponents([]),
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    //HttpModule,
    HttpClientModule,
    JwtModule,
    MatSidenavModule,
    AppRoutingModule,
    FrameworkModule,
    ReportsModule,
    MatAutocompleteModule,
  ],
  entryComponents: [
    MarsErrorMsgComponent,
    TerminalDeactivationMessageComponent,
    UploadErrListComponent,
    TerminalStatusRequestMessageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    DeviceStockService,
    DeviceReturnService,
    ProductService,
    SupplierService,
    SIMOperatorService,
    SIMStockService,
    SIMReturnService,
    IndentService,
    MerchantService,
    EmployeeService,
    AggregatorDistributorService,
    TransCategoryService,
    BankService,
    MerchantPreboardingService,
    PincodeService,
    BankIFSCService,
    MDRPlanService,
    RentalplanService,
    MCCService,
    TerminalReportService,
    RegionMasterService,
    BankMerchantService,
    ReportService,
    MasterPricingPlanService,
    UpiMerchantUploadConfig,
    SourcingBranchService,
    CanDeactivateGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
