

export class MCCModel {
   mccCode ? : string;
   description? : string;
   /*
   E - Emerging
   N - Non-emerging
   */
  businessSegment ? : string;
}