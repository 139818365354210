import { Injectable } from "@angular/core";
import { PincodeModel } from "../models/pincode.model";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";

@Injectable()
export class PincodeService {
  public pincodelist: Array<PincodeModel> = new Array<PincodeModel>();

  constructor(private http: HttpClient, private appsetting: AppSettings) {}

  async getPincodeData(pincode: string): Promise<PincodeModel> {
    if (!pincode || pincode.length != 6) {
      return undefined;
    }
    let retvalue: PincodeModel = undefined;
    for (let i = 0; i < this.pincodelist.length; i++) {
      if (this.pincodelist[i].pinCode == pincode) {
        retvalue = this.pincodelist[i];
        break;
      }
    }
    if (retvalue) {
      return retvalue;
    } else {
      return await this.getPincodeApi(pincode)
        .then(data => {
          if (data["success"]) {
            retvalue = data["result"];
            this.pincodelist.push(retvalue);
            return retvalue;
          } else {
            if (data["message"] == "PINCODENOTPROVIDED") {
              console.error("Pincode not provided");
            } else if (data["message"] == "NODATAFOUND") {
              console.error("Pincode data not found");
            } else if (data["message"] == "ERROR") {
              console.error("Error in api while getting pincode data");
            }
            return undefined;
          }
        })
        .catch(err => {
          console.error(
            "Error exception in api while getting pincode data - " + err
          );
          return undefined;
        });
    }
  }
  async getPincodeApi(pincode: string) {
    let header = new HttpHeaders();
    //let param: HttpParams = new HttpParams();
    //param = param.append("pincode", pincode);
    const response = await this.http
      .get(this.appsetting.get("server-url") + "/api/pincodedata/" + pincode, {
        headers: header,
        observe: "body",
        //params: param,
        responseType: "json"
      })
      .map(res => res)
      .toPromise();
    return response;
  }
  savePincode(pincode: PincodeModel, actiontype: string) {
    let header = new HttpHeaders();
    if (actiontype == "new") {
      return this.http
        .post(this.appsetting.get("server-url") + "/api/newpincode", pincode, {
          headers: header
        })
        .map(res => res);
    } else {
      return this.http
        .post(
          this.appsetting.get("server-url") + "/api/updatepincode",
          pincode,
          { headers: header }
        )
        .map(res => res);
    }
  }

  getPincodeList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (sortField) {
      if (sortField.sortFieldName && sortField.sortDirection != "") {
        httpParam = httpParam.append("sortDirection", sortField.sortDirection);
      }
      if (sortField.sortFieldName && sortField.sortFieldName != "") {
        httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
      }
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/pincodelist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getPincode(pincode: string) {
    let header = new HttpHeaders();
    //let httpParam: HttpParams = new HttpParams();
    //httpParam = httpParam.append("pincode", pincode);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/pincodedata/" + pincode, {
        headers: header,
        observe: "body",
        //params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }
}
