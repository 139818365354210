import { Component, OnInit, ViewChild } from "@angular/core";
import {
  gridColumns,
  GridPage,
  GridtableComponent,
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { MerchantModel } from "src/app/models/merchant.model";
import { MerchantService } from "src/app/services/merchant.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Tools } from "src/app/framework/modules/tools";
import { AuthService } from "src/app/framework/services/auth.service";
import { global } from "src/app/framework/modules/global";
import { Router } from "@angular/router";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import { IndentdetailComponent } from "../indentdetail/indentdetail.component";
import { MerchantdetailComponent } from "../merchantdetail/merchantdetail.component";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";

@Component({
  selector: "app-merchantlist",
  templateUrl: "./merchantlist.component.html",
  styleUrls: ["./merchantlist.component.css"],
})
export class MerchantlistComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  @ViewChild(MerchantdetailComponent, { static: true })
  merchantdetailComp: MerchantdetailComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "",
      columnFieldName: "merchantId",
      columnClasses: "c-col-pc-0",
    },
    {
      columnHeader: "Application Id",
      columnFieldName: "applicationId",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Application Date",
      columnFieldName: "applicationDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "Agreement Date",
      columnFieldName: "agreementDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "MID",
      columnFieldName: "mid",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Company Name",
      columnFieldName: "companyName",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "Contact",
      columnFieldName: "phoneNumber",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "City",
      columnFieldName: "businessAddressCity",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "Status",
      columnFieldName: "merchantStatus",
      columnClasses: "c-col-pc-10",
      callbackFunctionRowPrm: this.getStatusText,
      callClassFunction: this.statusClass,
      _parentscope: this,
    },
  ];
  sortFields = [
    { name: "merchantId", display: "-" },
    { name: "applicationId", display: "Application Id" },
    { name: "mid", display: "MID" },
    { name: "merchantName", display: "Merchant Name" },
    { name: "phoneNumber", display: "Contact" },
    { name: "businessAddressCity", display: "Business Address City" },
    { name: "merchantStatus", display: "Status" },
  ];

  searchField = {
    searchText: "",
    merchantStatus: "",
    applicationDate: "",
  };
  sort: SortClass;

  isDetailScreen: boolean = false;
  detailScreenTab: object = {
    merchantprofiletab: true,
    companyprofiletab: false,
    banktab: false,
    financialstab: false,
    terminalstab: false,
    risktab: false,
  };
  page: GridPage;
  datalist: Array<MerchantModel>;
  _menuId: string = "";
  _action: string = "";
  _merchantId: number;
  topbartext: TopbarText[] = [
    { key: "merchantlist", displayText: "Merchant List" },
  ];
  topbarChangingValue: any = { selectedTabIndex: 0 };
  merchantStatusValues: any;
  constructor(
    public merchantservice: MerchantService,
    private ngbModalService: NgbModal,
    public tools: Tools,
    private auth: AuthService,
    private objGlobal: global,
    private router: Router,
    private csvservice: ExporttocsvService
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "merchantId";
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );
    this.merchantStatusValues = this.merchantservice.merchantStatusValues;
    this.merchantservice.fillAllMerchantDocumentTemplate();
    this.objGlobal.setActiveMenu(this._menuId);
    this.datalist = new Array<MerchantModel>();
  }

  ngOnInit() {}
  onAddNew() {
    this.isDetailScreen = true;
    this._action = "new";
    this.setTopbarText(true);

    this.onTabClick("merchantprofiletab");
    this.topbarChangingValue = { selectedTabIndex: 0 };
    this.merchantdetailComp.addNew();
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  isEditPriv() {
    return this.auth.isPrivExists(this._menuId + "=>edit");
  }
  isTerminalStatusChangePriv() {
    return this.auth.isPrivExists(this._menuId + "=>terminalstatuschange");
  }
  isApprovePriv() {
    return this.auth.isPrivExists(this._menuId + "=>approve");
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  selectData(e) {
    if (e.merchantId) {
      this._action = "update";
      this._merchantId = e.merchantId;
      this.isDetailScreen = true;
      this.merchantdetailComp.action = "update";
      this.setTopbarText(true);
      this.onTabClick("merchantprofiletab");
      this.topbarChangingValue = { selectedTabIndex: 0 };
      this.merchantdetailComp.getDetail(e.merchantId);
    }
  }
  deleteData(e) {}
  onRowDblClick(e: any) {}

  getStatusText(row: MerchantModel, _parentscope) {
    let result = "";
    if (_parentscope.merchantStatusValues) {
      const merchantstatus = row.merchantStatus ? row.merchantStatus : "";
      const merchantMarsStatus = row.marsStatus ? row.marsStatus : "";
      const merchantcombinedStatus =
        merchantstatus +
        (merchantMarsStatus != "" ? "#" + merchantMarsStatus : "");
      let mstatus = _parentscope.merchantStatusValues.find(
        (itm) => itm.value == merchantcombinedStatus
      );
      if (!mstatus) {
        mstatus = _parentscope.merchantStatusValues.find(
          (itm) => itm.value == merchantstatus
        );
      }
      if (mstatus) {
        result = mstatus.text;
      }
    }
    return result;
  }
  statusClass() {}
  getDatalist() {
    this.merchantservice
      .getMerchantList(this.page, this.searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get merchant list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get merchant list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  setTopbarText(isDetail: boolean) {
    if (isDetail) {
      this.topbartext = [
        {
          key: "merchantprofiletab",
          displayText: "Merchant Profile",
          class: "col-md-3",
        },
        {
          key: "companyprofiletab",
          displayText: "Company Profile",
          class: "col-md-3",
        },
        { key: "banktab", displayText: "Bank", class: "col-md-1" },
        { key: "financialstab", displayText: "Financials", class: "col-md-2" },
        { key: "terminalstab", displayText: "Terminals", class: "col-md-2" },
        { key: "risktab", displayText: "Risk", class: "col-md-1" },
      ];
      this.detailScreenTab = {
        merchantprofiletab: true,
        companyprofiletab: false,
        banktab: false,
        financialstab: false,
        terminalstab: false,
        risktab: false,
      };
    } else {
      this.topbartext = [{ key: "merchantlist", displayText: "Merchant List" }];
    }
  }
  onTabClick(e: any) {
    this.detailScreenTab = {
      merchantprofiletab: false,
      companyprofiletab: false,
      banktab: false,
      financialstab: false,
      terminalstab: false,
      risktab: false,
    };
    this.detailScreenTab[e] = true;
    this.merchantdetailComp.tabClick(this.detailScreenTab);
  }
  onCloseDetailScreen(isChange: boolean) {
    if (isChange == true) {
      this.getDatalist();
    }
    this.isDetailScreen = false;
    this.setTopbarText(false);
  }
  getMerchantListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.merchantservice
      .getMerchantList(page, this.searchField, this.sort)
      .subscribe(
        (data) => {
          let obj: Array<MerchantModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "merchant",this
            );
          } else {
            alert("Failed to get merchant list!");
          }
        },
        (error) => {
          alert("Failed to get merchant list!");
        }
      );
  }
  onExcelClick() {
    this.getMerchantListForExcel();
  }
}
