import { Injectable } from "@angular/core";
import {ProductModel} from '../models/product.model';
import {HttpClient,HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {AppSettings} from '../framework/config/AppSettings';
import { throwError } from 'rxjs';
import { GridPage } from '../framework/util/components/gridtable/gridtable.component';
import { SortClass } from '../framework/modules/util.class';
@Injectable()
export class ProductService {
    productList : ProductModel[];
    constructor(private http : HttpClient, private appsetting : AppSettings) {

    }
    loadProductNameWithId() {        
        this.getProductNameApi().subscribe(data => {
            if(data["success"]) {
                if(data["result"])  {
                    this.productList = data["result"];    
                } else {
                    this.productList = undefined;
                }
            } else {
                throwError(data["message"]);
                this.productList=undefined
            }           
        });
    }
    
    getProductNameApi() {
        let header : HttpHeaders = new HttpHeaders();
        return this.http.get( this.appsetting.get("server-url") + '/api/allproductname',{headers : header, observe : "body", responseType : "json"})
        .map(res=>res);
    }


    saveProduct(product: ProductModel) {
        let header = new HttpHeaders();
        return this.http
            .post(this.appsetting.get("server-url") + "/api/saveproduct", product, {
            headers: header
            })
            .map(res => res);
    }

  getProductList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (sortField) {
      if (sortField.sortFieldName && sortField.sortDirection != "") {
        httpParam = httpParam.append("sortDirection", sortField.sortDirection);
      }
      if (sortField.sortFieldName && sortField.sortFieldName != "") {
        httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
      }
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/productlist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }
}