import { Component, OnInit, Input } from '@angular/core';
import { UploadError } from 'src/app/models/upload.error.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-err-list',
  templateUrl: './upload-err-list.component.html',
  styleUrls: ['./upload-err-list.component.css']
})
export class UploadErrListComponent implements OnInit {
  @Input()
  errors : UploadError[];
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

  ok() {
    this.activeModal.close("ok");
  }
}
