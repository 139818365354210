export class UserRole {
  roleId: number;
  roleName: string;
  description: string;
  privileges: string[];
}
export class UserModel {
  phoneNumber?:number;
  userId: number;
  name: string;
  username: string;
  userEmail?: string;
  password?: string;
  userRePassword?: string;
  distributorId?: number;
  roleId?: number[];
  roleName?: string[];
  distributorName?: string;
  isLocked?: boolean;
}

export class LoginUser {
  userId?: number;
  name?: string;
  username?: string;
  userEmail?: string;
  token?: string;
  aggregatorId?: number;
  aggregatorCode?: string;
  distributorId?: number;
  merchantId?: number;
}
