import { EmployeeModel } from "./employee.model";
import { TransRatesModel } from "./trans.rate";
import { BankModel } from "./bank.model";
import { ChannelNetworkCodeValueModel } from "./channel.network.codevalue.model";

export class AggregatorDistributorModel {
  id?: number;
  name?: string;
  type?: string;
  sponsorBank?: BankModel;
  institution?: AggregatorDistributorModel;
  aggregator?: AggregatorDistributorModel;
  marsCode?: number;
  defaultSuperMerchantMARSCode?: number;
  defaultGroupMerchantMARSCode?: number;
  superMerchant?: AggregatorDistributorModel;
  code?: string;
  enrollmentDate?: Date;
  agreementDate?: Date;
  region?: number;
  regionName?: string;
  aggregatorDistributorStatus?: string;
  salesPerson?: EmployeeModel;
  companyName?: string;
  mobileNumber?: string;
  alternateMobileNumber?: string;
  email?: string;
  website?: string;
  aadhaar?: string;
  voterId?: string;
  pan?: string;
  gst?: string;
  otherId?: string;
  passportNumber?: string;
  passportExpiryDate?: Date;
  businessAddress?: string;
  businessAddressPinCode?: string;
  businessAddressCity?: string;
  businessAddressState?: string;
  residenceAddress?: string;
  residenceAddressPinCode?: string;
  residenceAddressCity?: string;
  residenceAddressState?: string;
  financeRemarks?: string;
  transRates?: Array<TransRatesModel>;
  panFileName?: string;
  aadhaarFileName?: string;
  gstFileName?: string;
  voterIdFileName?: string;
  otherIdFileName?: string;
  bankDetail?: AggregatorDistributorBankDetailModel;
  createdBy?: string;
  creationDate?: Date;
  lastModifiedBy?: string;
  lastModificationDate?: Date;
  kin?: string;
  marsUpdate?: boolean;
}
export class AggregatorDistributorFiles {
  panFile?: File;
  panFileName?: string;
  aadhaarFile?: File;
  aadhaarFileName: string;
  gstFile?: File;
  gstFileName: string;
  voterIdFile: File;
  voterIdFileName: string;
  otherIdFile: File;
  otherIdFileName: string;
}
export class AggregatorDistributorBankDetailModel {
  aggrdistrBankDetailId?: number;
  aggregatordistributor?: AggregatorDistributorModel;
  /*
   * D -Direct Credit
   * N - NEFT
   * I - IMPS
   */
  settelmentBy?: string;

  /*
   * S -Savings
   * C -current
   */
  accountType?: string;
  ifscCode?: string;
  bankName?: string;
  branchName?: string;
  city?: string;
  state?: string;
  pinCode?: string;
  /*
   * UTR
   * UPI
   * RTGS
   * NEFT
   */
  paymentMode?: string;
  accountNumber?: string;
  micr?: string;
  /*
   * Y - Yes
   * N - No
   */
  achMandate?: string;
  umrn?: string;
  feeAddressChange?: number;
  paymentDetails?: string;
  remarks?: string;
  channelnetwork?: Array<AggregatorChannelNetwork>;
}

export class AggregatorChannelNetwork {
  id?: number;
  channel?: string;
  network?: string;
  transactionSet?: Array<ChannelNetworkCodeValueModel>;
  aggregator?: AggregatorDistributorModel;
}
