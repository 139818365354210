import { Injectable } from "@angular/core";
import { SIMStockModel, SIMReturnModel } from "../models/simstock.model";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";
import { throwError } from "rxjs";
import { Tools } from "../framework/modules/tools";

@Injectable()
export class SIMStockService {
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}

  saveSIMStock(simStock: SIMStockModel) {
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/simstock", simStock, {
        headers: header,
      })
      .map((res) => res);
  }

  getSIMStockList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (searchField.invoiceDate) {
      httpParam = httpParam.append(
        "invoiceDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.invoiceDate[0])
      );
      httpParam = httpParam.append(
        "invoiceDateTill",
        this.tools.formatDateToDDMMMYY(searchField.invoiceDate[1])
      );
    }
    if (searchField.entryDate) {
      httpParam = httpParam.append(
        "entryDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.entryDate[0])
      );
      httpParam = httpParam.append(
        "entryDateTill",
        this.tools.formatDateToDDMMMYY(searchField.entryDate[1])
      );
    }
    if (searchField.simStatus && searchField.simStatus != "") {
      httpParam = httpParam.append("simStatus", searchField.simStatus);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }

    return this.http
      .get(this.appsetting.get("server-url") + "/api/simstocks", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }

  getSIMStockDetail(simStockId: number) {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/simstock/" + simStockId, {
        headers: header,
        observe: "body",
        responseType: "json",
      })
      .map((res) => res);
  }
  getSIMStockIdBySimNumberApi(simNumber: string) {
    let header = new HttpHeaders();
    //let httpParam: HttpParams = new HttpParams();
    // httpParam = httpParam.append("SIMNumber", simNumber);
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/simstockidbysimnumber/" +
          simNumber,
        {
          headers: header,
          observe: "body",
          //params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }
  getSIMStockIdBySimNumberForTerminalApi(
    simNumber: string,
    merchantTerminalId: number
  ) {
    let header = new HttpHeaders();
    /*let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("SIMNumber", simNumber);
    if (merchantTerminalId) {
      httpParam = httpParam.append(
        "merchantTerminalId",
        merchantTerminalId.toString()
      );
    }*/
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/simstockidbysimnumberforterminal/" +
          simNumber +
          "/" +
          merchantTerminalId,
        {
          headers: header,
          observe: "body",
          //params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }
  async getSIMStockIdBySimNumber(
    simNumber: string,
    merchantTerminalId: number
  ): Promise<any> {
    if (!merchantTerminalId) {
      merchantTerminalId = 0;
    }
    return await this.getSIMStockIdBySimNumberForTerminalApi(
      simNumber,
      merchantTerminalId
    )
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return undefined;
      });
  }

  saveBulkSIMStock(simStock: Array<SIMStockModel>) {
    // for(let i=0;i<simStock.length;i++) {
    //   simStock[i].merchant=undefined;
    // }
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/bulksimstock", simStock, {
        headers: header,
      })
      .map((res) => res);
  }
}

@Injectable()
export class SIMReturnService {
  simReturnReasonList: Array<string>;
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}
  loadSIMReturnReason() {
    this.getSIMReturnReasonApi().subscribe((data) => {
      if (data["success"]) {
        if (data["result"]) {
          this.simReturnReasonList = data["result"];
        } else {
          this.simReturnReasonList = undefined;
        }
      } else {
        throwError(data["message"]);
        this.simReturnReasonList = undefined;
      }
    });
  }
  getSIMReturnReasonApi() {
    let header: HttpHeaders = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/simreturnreasons", {
        headers: header,
        observe: "body",
        responseType: "json",
      })
      .map((res) => res);
  }
  saveSIMReturn(simReturn: SIMReturnModel) {
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/simreturn", simReturn, {
        headers: header,
      })
      .map((res) => res);
  }

  getSIMReturnList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (searchField.entryDate) {
      httpParam = httpParam.append(
        "entryDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.entryDate[0])
      );
      httpParam = httpParam.append(
        "entryDateTill",
        this.tools.formatDateToDDMMMYY(searchField.entryDate[1])
      );
    }
    if (searchField.simCondition && searchField.simCondition != "") {
      httpParam = httpParam.append("simCondition", searchField.simCondition);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    //header.append('Content-Type','application/json');

    return this.http
      .get(this.appsetting.get("server-url") + "/api/simreturns", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }

  getSIMReturnDetail(simReturnId: number) {
    let header = new HttpHeaders();
    //let param: HttpParams = new HttpParams();
    //param = param.append("simReturnId", simReturnId.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/simreturn/" + simReturnId,
        {
          headers: header,
          observe: "body",
          //params: param,
          responseType: "json",
        }
      )
      .map((res) => res);
  }
}
