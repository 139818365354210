import {
  NgModule,
  Provider,
  ModuleWithProviders,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { Tools } from "./modules/tools";
import { global } from "./modules/global";
import { GridtableComponent } from "./util/components/gridtable/gridtable.component";
import { SimplegridComponent } from "./util/components/simplegrid/simplegrid.component";
import { UserComponent } from "./components/user/user.component";
import { UserlistComponent } from "./components/userlist/userlist.component";
import { UserroleComponent } from "./components/userrole/userrole.component";
import { UserService } from "./services/user.service";
import { UserroleService } from "./services/userrole.service";
import { MainComponent } from "./components/main/main.component";
import { TopbarComponent } from "./components/topbar/topbar.component";
import { AppSettings } from "./config/AppSettings";
import { AuthGuard } from "./guards/auth.guard";
import { AuthService } from "./services/auth.service";
import { ExcelService } from "./services/excel.service";
import { ExporttocsvService } from "./services/exporttocsv.service";
import { ImageconfigService } from "./services/imageconfig.service";
import { MenuService } from "./services/menu.service";
import { XhrInterceptorService } from "./services/xhr-interceptor.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { UserIdleModule } from "angular-user-idle";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "./util/components/modalcomponent/modal-content";
import {
  ConfirmService,
  ConfirmModalComponent,
  ConfirmState,
  ConfirmTemplateDirective
} from "./util/confirm-modal-and-service";
import { ObjectArraySort } from "./util/pipe/pipetransform.pipe";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { JwtModule } from "@auth0/angular-jwt";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { RouterModule } from "@angular/router";
import { PageheaderComponent } from "./util/components/pageheader/pageheader.component";
import { AmountDirective } from "./Directives/amount.directive"

const providers: Provider[] = [
  AppSettings,
  AuthService,
  AuthGuard,
  Tools,
  global,
  UserService,
  UserroleService,
  ExcelService,
  ExporttocsvService,
  ImageconfigService,
  MenuService,
  ConfirmService,
  ConfirmState,
  XhrInterceptorService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: XhrInterceptorService,
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: appSettingFactory,
    deps: [AppSettings],
    multi: true
  }
];
@NgModule({
  declarations: [
    GridtableComponent,
    SimplegridComponent,
    UserComponent,
    UserlistComponent,
    UserroleComponent,
    MainComponent,
    TopbarComponent,
    NgYesNoModalContent,
    NgInformationModalContent,
    ConfirmModalComponent,
    ConfirmTemplateDirective,
    ObjectArraySort,
    PageheaderComponent,
    AmountDirective
  ],
  entryComponents: [
    NgYesNoModalContent,
    NgInformationModalContent,
    UserlistComponent,
    ConfirmModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModalModule,
    BrowserModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    //HttpModule,
    HttpClientModule,
    JwtModule,
    NgMultiSelectDropDownModule,
    RouterModule,
    UserIdleModule.forRoot({ idle: 600, timeout: 1, ping: 1 })
  ],
  providers: providers,
  exports: [
    GridtableComponent,
    SimplegridComponent,
    UserComponent,
    UserlistComponent,
    UserroleComponent,
    MainComponent,
    TopbarComponent,
    AmountDirective
  ]
})
export class FrameworkModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: FrameworkModule,
      providers: providers
    };
  }
}

export function appSettingFactory(provider: AppSettings) {
  return () => provider.load();
}
