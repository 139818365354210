import { Component, OnInit, Input } from "@angular/core";
import {
  MARSErrorResponse,
  MARSValidationErrorDetail
} from "src/app/models/mars.response.error.module";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {DatePipe}from '@angular/common'
@Component({
  selector: "app-mars-error-msg",
  templateUrl: "./mars-error-msg.component.html",
  styleUrls: ["./mars-error-msg.component.css"]
})
export class MarsErrorMsgComponent implements OnInit {
  @Input()
  errorMsg: MARSErrorResponse;
  @Input()
  forEntity?: string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
  ok() {
    this.activeModal.close("ok");
  }
  timeFormate(date: any) {
    // Parse the date string
    const day = date.substring(0, 2);
    const month = date.substring(2, 4);
    const year = date.substring(4, 8);
    const hour = date.substring(8, 10);
    const minute = date.substring(10, 12);
    const second = date.substring(12, 14);

    // Create a new Date object
    const formattedDate = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);

    // Format the date
    const options: Intl.DateTimeFormatOptions = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit',
        hour12: true // Ensure 12-hour format
    };
    const formattedDateString = formattedDate.toLocaleDateString('en-US', options);
    
    return formattedDateString;
}


  isValidationErrors() {
    let result = false;
    if (this.errorMsg && this.errorMsg.errorDetails) {
      const obj = this.errorMsg.errorDetails.filter(obj => obj != undefined);
      if (obj && obj.length > 0) {
        result = true;
      }
    }
    return result;
  }

  getErrorDetails() {
    let errDetail: Array<MARSValidationErrorDetail> = new Array<
      MARSValidationErrorDetail
    >();
    if (this.isValidationErrors()) {
      for (let i: number = 0; i < this.errorMsg.errorDetails.length; i++) {
        if (this.errorMsg.errorDetails[i]) {
          errDetail.push(this.errorMsg.errorDetails[i]);
        }
      }
    }
    return errDetail;
  }
}
