import { Component, OnInit, ViewChild } from "@angular/core";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import {
  GridtableComponent,
  gridColumns,
  GridPage
} from "../../framework/util/components/gridtable/gridtable.component";
import { Tools } from "../../framework/modules/tools";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { global } from "../../framework/modules/global";
import { AuthService } from "../../framework/services/auth.service";
import { IndentModel } from "../../models/indent.model";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { IndentService } from "../../services/indent.service";
import { ProductService } from "../../services/product.service";
import { AggregatorDistributorService } from "../../services/aggregator.distributor.service";
import { EmployeeService } from "../../services/employee.service";
import { MerchantService } from "../../services/merchant.service";
import { DeviceStockService } from "../../services/device.service";
import { _do } from "rxjs-compat/operator/do";
import { __makeTemplateObject } from "tslib";
import { IndentdetailComponent } from "../indentdetail/indentdetail.component";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";

class SearchField {
  searchText: string;
  status: Date[];
  indentDate: String;
  typeOfSale: String;
  amountFrom: number;
  amountTo: number;
  qtyFrom: number;
  qtyTo: number;
  rateFrom: number;
  rateTo: number;
  constructor() {
    this.searchText = "";
    this.status = undefined;
    this.indentDate = undefined;
    this.typeOfSale = undefined;
    this.amountFrom = undefined;
    this.amountTo = undefined;
    this.qtyFrom = undefined;
    this.qtyTo = undefined;
    this.rateFrom = undefined;
    this.rateTo = undefined;
  }
}
@Component({
  selector: "app-indent",
  templateUrl: "./indent.component.html",
  styleUrls: ["./indent.component.css"]
})
export class IndentComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  @ViewChild(IndentdetailComponent, { static: true })
  indDetailComp: IndentdetailComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "",
      columnFieldName: "indentId",
      columnClasses: "c-col-pc-0"
    },
    {
      columnHeader: "Indent Number",
      columnFieldName: "indentNumber",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Status",
      columnFieldName: "currentStatus",
      columnClasses: "c-col-pc-8",
      callbackFunction: this.getStatusText,
      callClassFunction: this.statusClass
    },
    {
      columnHeader: "Indent Date",
      columnFieldName: "indentDate",
      columnClasses: "c-col-pc-8",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Type of Sale",
      columnFieldName: "typeOfSale",
      columnClasses: "c-col-pc-7"
    },
    {
      columnHeader: "Purchaser",
      columnFieldName: "purchaser.name",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Sale Person",
      columnFieldName: "salesPerson.empName",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Company Name",
      columnFieldName: "merchant.companyName",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Device Model",
      columnFieldName: "product.modelName",
      columnClasses: "c-col-pc-6"
    },
    {
      columnHeader: "Qty",
      columnFieldName: "quantity",
      columnClasses: "c-col-pc-3 text-right"
    },
    {
      columnHeader: "Rate",
      columnFieldName: "rate",
      columnClasses: "c-col-pc-6 text-right",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "Amount",
      columnFieldName: "totalAmount",
      columnClasses: "c-col-pc-6 text-right",
      callbackFunction : this.tools.formateNumberD2
    }
  ];
  sortFields = [
    { name: "indentId", display: "-" },
    { name: "indentDate", display: "Indent Date" },
    { name: "typeOfSale", display: "Type of sale" },
    { name: "purchaser.name", display: "Purchaser" },
    { name: "salesPerson.empName", display: "Sales Person" },
    { name: "product.modelName", display: "Device Model" },
    { name: "merchant.merchantName", display: "Merchant" },
    { name: "quantity", display: "Quantity" },
    { name: "rate", display: "Rate" },
    { name: "totalAmount", display: "Amount" }
  ];

  topbartext: TopbarText[] = [{ key: "indent", displayText: "Indent List" }];
  searchField: SearchField;
  indentlist: Array<IndentModel>;
  page: GridPage;
  _menuId: string;
  _action: string = "";
  isDetailScreen: boolean = false;

  sort: SortClass;
  searchStatusValues = [
    { value: "", text: "-" },
    { value: "pending", text: "Pending" },
    { value: "approved", text: "Approved" },
    { value: "rejected", text: "Rejected" },
    { value: "onhold", text: "On hold" }
  ];

  indentId: number = 0;

  constructor(
    public tools: Tools,
    private ngbModalService: NgbModal,
    private objGlobal: global,
    private auth: AuthService,
    private objindentservice: IndentService,
    public productservice: ProductService,
    private aggregatordistributedservice: AggregatorDistributorService,
    private employeeservice: EmployeeService,
    private merchantservice: MerchantService,
    private devicestockservice: DeviceStockService,
    private csvservice: ExporttocsvService
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    //this.objsimreturnservice.loadSIMReturnReason();
    this.searchField = new SearchField();
    this._menuId = this.tools.getMenuName("main/indent");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "indentId";
    this.getIndentList();
  }
  ngOnInit() {}
  isAllowAdd() {
    return this.auth.isPrivExists(this._menuId + "=>add");
  }
  isEditPriv() {
    return this.auth.isPrivExists(this._menuId + "=>edit");
  }
  isBHPriv() {
    return this.auth.isPrivExists(this._menuId + "=>buhead");
  }
  isFinancePriv() {
    return this.auth.isPrivExists(this._menuId + "=>finance");
  }
  isIndentGeneratePriv() {
    return this.auth.isPrivExists(this._menuId + "=>generation");
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  onAddNew() {
    this._action = "new";
    this.isDetailScreen = true;
    this.indDetailComp.onAddNew();
  }
  setTopbarText(actiontype: string) {
    if (!this.isDetailScreen) {
      this.topbartext[0].key = "indent";
      this.topbartext[0].displayText = "Indent";
      this.topbartext[0].class = "col-md-3";
    } else if (actiontype == "N") {
      this.topbartext[0].key = "indentcreation";
      this.topbartext[0].displayText = "Indent Creation";
      this.topbartext[0].class = "col-md-3";
    } else if (actiontype == "BA") {
      this.topbartext[0].key = "indentbuhead";
      this.topbartext[0].displayText = "Indent Approval - Business Head";
      this.topbartext[0].class = "col-md-4";
    } else if (actiontype == "FA") {
      this.topbartext[0].key = "indentbuhead";
      this.topbartext[0].displayText = "Indent Approval - Finance";
      this.topbartext[0].class = "col-md-4";
    } else if (actiontype == "IG") {
      this.topbartext[0].key = "indentbuhead";
      this.topbartext[0].displayText = "Indent Generation";
      this.topbartext[0].class = "col-md-3";
    }
  }
  onCloseDetailScreen(isChange: boolean) {
    if (isChange == true) {
      this.getIndentList();
    }
    this.isDetailScreen = false;
    this.setTopbarText("");
  }
  getindents(e: any) {
    this.getIndentList();
  }
  selectIndent(e: any) {}
  deleteIndent(e: any) {}
  onIndentRowDblClick(indent: any) {
    this.indentId = indent.indentId;
    this.isDetailScreen = true;
    this._action = "update";
    this.indDetailComp.getIndentDetail(indent.indentId);

    //    this.getIndentDetail(indent.indentId);
  }
  getIndentList() {
    this.objindentservice
      .getIndentList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.indentlist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
            if (this.indentId == 0 && this.indentlist.length > 0) {
              this.indentId = this.indentlist[0].indentId;
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get indent list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get indent list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  isBUActionPriv() {
    return this.auth.isPrivExists(this._menuId + "=>buhead");
  }
  getStatusText(status: String) {
    let statusText: string = "New";
    switch (status) {
      case "N":
        statusText = "New";
        break;
      case "BH":
        statusText = "BUHead hold";
        break;
      case "BA":
        statusText = "BH approved";
        break;
      case "BR":
        statusText = "BH rejected";
        break;
      case "FH":
        statusText = "Fin hold";
        break;
      case "FA":
        statusText = "Fin approved";
        break;
      case "FR":
        statusText = "Fin rejected";
        break;
      case "IC":
        statusText = "Generated";
        break;
    }
    return statusText;
  }
  statusClass(status: String) {
    let statusClass: string;
    switch (status) {
      case "BH":
      case "FH":
        statusClass = "table-primary";
        break;
      case "BA":
      case "FA":
        statusClass = "table-success";
        break;
      case "BR":
      case "FR":
        statusClass = "table-danger";
        break;
    }
    return statusClass;
  }

  getIndentListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.objindentservice.getIndentList(page, this.searchField, sort).subscribe(
      data => {
        let obj: Array<IndentModel>;
        if (data["success"]) {
          obj = data["result"];
          this.csvservice.exportToCsv(obj, this.gridcols, undefined, "indent");
        } else {
          alert("Failed to get Indent list!");
        }
      },
      error => {
        alert("Failed to get Indent list!");
      }
    );
  }
  onExcelClick() {
    this.getIndentListForExcel();
  }
}
