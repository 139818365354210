import { Directive, ElementRef, HostListener, Renderer2, OnInit, OnChanges, Pipe, AfterContentChecked } from '@angular/core';

@Directive({
  selector: '[appAmount]'
})
export class AmountDirective implements OnInit, AfterContentChecked{
  private el: HTMLInputElement;
  private isTouched = false;
  constructor(private eleRef: ElementRef, private renderer: Renderer2) {
    this.el = <HTMLInputElement>eleRef.nativeElement;
  }

  ngOnInit() {
    const val = this.el.value
    if (val && (!isNaN(+val))) {
      this.el.value = (+val).toFixed(2);
    }
  }

  ngAfterContentChecked() {
    const val = this.el.value
    if (val && (!isNaN(+val)) && (!this.isTouched)) {
      this.el.value = (+val).toFixed(2);
    }  
  }
  /*ngOnChanges() {
    const val = this.el.value
    if (val && (!isNaN(+val))) {
      this.el.value = (+val).toFixed(2);
    }
  }*/

  @HostListener("focus") onFocus(event : Event) {
    this.isTouched = true;
  }

  @HostListener('blur') onBlur(event: Event) {
    const val = this.el.value
    if (val && (!isNaN(+val))) {
      this.el.value = (+val).toFixed(2);
    }  
  }

  /*@HostListener('change') onChange(event: Event) {
    const val = this.el.value
    console.log("changes Value is " + val);
    if (val && (!isNaN(+val))) {
      this.el.value = (+val).toFixed(2);
    }
  }*/

  /*@HostListener('loadeddata') onLoadedData(event: Event) {
    const val = this.el.value
    console.log("loaded data Value is " + val);
    if (val && (!isNaN(+val))) {
      this.el.value = (+val).toFixed(2);
    }
  }*/
}
