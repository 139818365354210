import { Component, OnInit, ViewChild } from "@angular/core";
import {
  GridtableComponent,
  gridColumns,
  GridPage,
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { Tools } from "src/app/framework/modules/tools";
import { global } from "src/app/framework/modules/global";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
import { TerminalReportService } from "src/app/services/terminalreports.service";
import { TerminalActivationReports } from "../../models/reports.model";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/framework/services/auth.service";
import { AggregatorDistributorModel } from "src/app/models/aggregator.distributor.model";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";

@Component({
  selector: "app-terminal-activation-report",
  templateUrl: "./terminal-activation-report.component.html",
  styleUrls: ["./terminal-activation-report.component.css"],
})
export class TerminalActivationReportComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "TERMINAL ID",
      columnFieldName: "tid",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "MERCHANT ID",
      columnFieldName: "mid",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Device Sr. Number",
      columnFieldName: "deviceSerialNumber",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "MERCHANT NAME",
      columnFieldName: "merchantName",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "ACTIVATION DATE",
      columnFieldName: "activationDate",
      columnClasses: "c-col-pc-8",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "PRODUCT",
      columnFieldName: "productName",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Terminal Category",
      columnFieldName: "terminalType",
      columnClasses: "c-col-pc-8",
    },
    {
      columnHeader: "STATUS",
      columnFieldName: "status",
      columnClasses: "c-col-pc-8",
      callbackFunction: this.getStatusText
    },
    {
      columnHeader: "AGGREGATOR NAME",
      columnFieldName: "aggregatorName",
      columnClasses: "c-col-pc-15",
    },
  ];
  sortFields = [
    { name: "tid", display: "TERMINAL ID" },
    { name: "mid", display: "MERCHANT ID" },
    { name: "companyName", display: "MERCHANT NAME" },
    { name: "activationDate", display: "ACTIVATION DATE" },
    { name: "productName", display: "PRODUCT NAME" },
    { name: "aggregatorName", display: "AGGREGATOR" },
    { name: "deviceSerialNumber", display: "Device Serial Number" },
  ];

  topbartext: TopbarText[] = [
    {
      key: "terminalactivationreport",
      displayText: "Terminal Activation Report",
      class: "col-md-5",
      anchorClass: "h6",
    },
  ];
  searchField = {
    activationDateFrom: undefined,
    activationDateTill: undefined,
    terminalCategory: undefined,
    aggregatorId: undefined,
    product: undefined,
    activationStatus: undefined,
  };
  datalist: Array<TerminalActivationReports>;
  page: GridPage;
  _menuId: string;
  //isDetailScreen : boolean=false;

  sort: SortClass;
  tid: string = "";

  constructor(
    public tools: Tools,
    private objGlobal: global,
    private csvservice: ExporttocsvService,
    private terminalreportsservice: TerminalReportService,
    private auth: AuthService,
    private aggregatorDistributorService: AggregatorDistributorService
  ) {
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    //this.objsimreturnservice.loadSIMReturnReason();

    this._menuId = this.tools.getMenuName("main/terminalactivationreport");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "tid";
    this.searchField.activationDateFrom = new Date();
    this.searchField.activationDateTill = this.searchField.activationDateFrom;
    this.aggregatorDistributorService.loadAggregatorDistrbutorIdNameType();
    this.searchField.aggregatorId = undefined;
    this.searchField.activationStatus = "A";
    //this.getReportData();
  }

  ngOnInit() {}
  getReportData() {
    let searchField = {};
    if (!this.allowAggregatorSelection()) {
      this.searchField.aggregatorId = this.auth.getUserDistributorId();
    }
    searchField["aggregatorId"] = this.searchField.aggregatorId;
    if (
      this.searchField.activationDateFrom ||
      this.searchField.activationDateTill
    ) {
      searchField["activationDate"] = new Array<Date>();
      searchField["activationDate"].push(this.searchField.activationDateFrom);
      searchField["activationDate"].push(this.searchField.activationDateTill);
    }
    this.terminalreportsservice
      .getTerminaActivationReport(this.page, searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
            if (this.tid == "" && this.datalist.length > 0) {
              this.tid = this.datalist[0].tid;
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to terminal activation report!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to terminal activation report!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  exportTerminalActivationReportForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    let searchField = {};
    searchField["searchText"] = "";
    if (!this.allowAggregatorSelection()) {
      this.searchField.aggregatorId = this.auth.getUserDistributorId();
    }
    searchField["aggregatorId"] = this.searchField.aggregatorId;
    if (
      this.searchField.activationDateFrom ||
      this.searchField.activationDateTill
    ) {
      searchField["activationDate"] = new Array<Date>();
      searchField["activationDate"].push(this.searchField.activationDateFrom);
      searchField["activationDate"].push(this.searchField.activationDateTill);
    }
    this.terminalreportsservice
      .getTerminaActivationReport(page, searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<TerminalActivationReports>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "TerminalActivation"
            );
          } else {
            alert("Failed to get terminal activation report!");
          }
        },
        (error) => {
          alert("Failed to get terminal activation report!");
        }
      );
  }
  onExcelClick() {
    this.exportTerminalActivationReportForExcel();
  }

  allowAggregatorSelection() {
    return this.auth.getUserDistributorId() ? false : true;
  }

  getAggregatorList() {
    let aggregatorlist: Array<AggregatorDistributorModel> = undefined;
    if (this.allowAggregatorSelection()) {
      if (
        this.aggregatorDistributorService.aggregatorDistributorList &&
        this.aggregatorDistributorService.aggregatorDistributorList.length > 0
      ) {
        aggregatorlist = this.aggregatorDistributorService.aggregatorDistributorList.filter(
          (obj) => obj.id == 0 || obj.type == "A"
        );
      }
    }
    return aggregatorlist;
  }

  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }

  getStatusText(v: string) {
    switch (v) {
      case "PB": {
        return "Pre-Boareded";
      }
      case "S": {
        return "Boarding";
      }
      case "R": {
        return "Ready for approval";
      }
      case "A": {
        return "Approved";
      }
      case "J": {
        return "Rejected";
      }
      case "H": {
        return "Hold approval";
      }
      case "C": {
        return "Boarded";
      }
      case "I":
      case "D" : {
        return "Deactivated";
      }
      case "F": {
        return "Failed to update in MARS";
      }
      case "P": {
        return "Pending";
      }
      case "E": {
        return "Mars Rejection";
      }
      case "T": {
        return "Waiting for Activation";
      }
      case "V": {
        return "Activation/Deactivation Request Sent"
      }
      default:
        return undefined;
    }
  }
}
