import { Component, OnInit } from '@angular/core';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { gridColumns, GridPage } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { Tools } from 'src/app/framework/modules/tools';
import { global } from 'src/app/framework/modules/global';
import { UpiMerchantUploadConfig} from '../../config/upi.merchant.upload.config'
import { SimpleMessage } from 'src/app/framework/modules/util.class';
import { TableParams } from 'src/app/framework/util/components/simplegrid/simplegrid.component';
import { UploadError, UploadErrorMsg } from 'src/app/models/upload.error.model';
import { MerchantUploadService } from 'src/app/services/merchant-upload.service';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgYesNoModalContent } from 'src/app/framework/util/components/modalcomponent/modal-content';
import {  Subscription, interval } from 'rxjs';


class CsvFieldHeaders {
  name: string;
  index?: number;
}

@Component({
  templateUrl: './upi-merchant-upload.component.html',
  styleUrls: ['./upi-merchant-upload.component.css']
})
export class UpiMerchantUploadComponent implements OnInit {
topbartext: TopbarText[] = [
    { key: "upimerchantupload", displayText: "UPI MERCHANT UPLOAD", class: "col-md-5" }];
  
  gridcols: gridColumns[] = [];
  tableParams: TableParams = {rowClassFunction : this.errorClass};
  colconfig: any[] =[];
  csvFileToUpload: File;
  csvData : any[] = [];
  isCsvValidData : boolean;
  isCsvValidationError: boolean;
  _busy: boolean=false;
  _disableSubmit: boolean = true;
  _previewed = false;
 showAllErrList = (O, col, _scope) => {
    if (this.csvValidationErrors && this.csvValidationErrors.length > 0) {
      let objUploadErrorMsg = new UploadErrorMsg();
      objUploadErrorMsg.errors = this.csvValidationErrors;
      this.tools.UploadErrorsMessage(objUploadErrorMsg);
    }
  }
  recordInfoGridCols: gridColumns[] = [
    {
      columnHeader: "Record#",
      columnFieldName: "recordCount",
      columnClasses: "c-col-pc-15 text-center pl-2 font-size-90pc",
      
    },
    {
      columnHeader: "Existing Merchant#",
      columnFieldName: "existingMIDCount",
      columnClasses: "c-col-pc-15 text-center pl-2 font-size-90pc"
    },
    {
      columnHeader: "Existing Terminal#",
      columnFieldName: "existingTIDCount",
      columnClasses: "c-col-pc-15 text-center pl-2 font-size-90pc"
    },
    {
      columnHeader: "New Merchant#",
      columnFieldName: "newMIDRecordCount",
      columnClasses: "c-col-pc-15 text-center pl-2 font-size-90pc"
    },
    {
      columnHeader: "New Terminal#",
      columnFieldName: "newTIDRecordCount",
      columnClasses: "c-col-pc-15 text-center pl-2 font-size-90pc"
    },
    {
      columnHeader: "Errorneous Record#",
      columnFieldName: "errorneousRecordCount",
      columnClasses: "c-col-pc-20 text-center pl-2 font-size-90pc",
      callClassFunction: this.errorneousRecLink,
      callCellClickFunction : this.showAllErrList
    }
  ];
  page: GridPage;
  data = [];
  _menuId: string;
  recordInfo: Array<MerchantUploadHistoryModel>;
  csvValidationErrors: UploadError[];
  subsLoading: Subscription;
  uploadingText: string="";
  constructor(private tools: Tools, private objGlobal: global,
    private upiMerchantUploadConfig: UpiMerchantUploadConfig,
    private merchantuploadservice: MerchantUploadService,
    private ngbModalService: NgbModal) { 
    this.setCols();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/upi-merchant-upload");
    this.objGlobal.setActiveMenu(this._menuId);
    this._busy = false;
    this.recordInfo = new Array<MerchantUploadHistoryModel>();
    this.recordInfo.push({ recordCount: 0, existingMIDCount : 0,existingTIDCount : 0, newMIDRecordCount:0,newTIDRecordCount :0, errorneousRecordCount : 0,fileName:undefined})
  }

  ngOnInit() {
  }

  subscribeLoadingStart(textSubs : string) {
    this.subsLoading=interval(500).subscribe(count => {
      this.uploadingText = textSubs;
      let cnt = count % 5;
      for (let i = 0; i < cnt; i++) {
        this.uploadingText+="   ."
      }
    }
    )
  }
  unsubscribeLoadingText() {
    this.uploadingText = "";
    this.subsLoading.unsubscribe();
  }
  setCols() {
    this.gridcols = [];
    this.colconfig = this.upiMerchantUploadConfig.getUpiMerchantFileUploadColumns();
    this.gridcols.push({ columnHeader: "Sr", isSrCol: true, columnClasses: "c-width-50px" });
    if (this.colconfig && this.colconfig.length > 0) {
      for (let i = 0; i < this.colconfig.length; i++) {
        const col = this.colconfig[i];
        let gridcol = new gridColumns();
        gridcol.columnHeader = col.columnHeader;
        gridcol.columnFieldName = col.columnFieldName;
        gridcol.columnClasses = col.columnClasses;
        gridcol.columnHeaderFunction = col["columnHeaderFunction"];
        gridcol.callbackFunctionRowPrm = col["callbackFunctionRowPrm"];
        gridcol.callbackFunction = col["callbackFunction"];
        gridcol.callClassFunction = col["callClassFunction"];
        gridcol.callCellClickFunction = col["callCellClickFunction"];
        this.gridcols.push(gridcol);
      }
    }
    this.gridcols.push({
      columnHeader: "Error", columnFieldName: "validationError",
      columnClasses: "c-width-50px link", callbackFunction: this.getErrorText,
      callCellClickFunction : this.showErrList,
   });

  }
  disablePreview() {
    return (!this.csvFileToUpload) || this._busy;
  }

  csvFileInput(file: File) {
    this._disableSubmit = true;
    this.csvFileToUpload = file;
    this.csvData = undefined;
    this.csvValidationErrors = undefined;
    this.isCsvValidData = false;
    this.isCsvValidationError = false;
    this.clearRecordInfo();
  }
  disableSubmit() {
    return this._busy || (!this._previewed) || this.recordInfo[0].recordCount <1 || this.recordInfo[0].errorneousRecordCount>0
  }
  clearRecordInfo() {
    this.recordInfo[0].recordCount = 0;
    this.recordInfo[0].newMIDRecordCount = 0;
    this.recordInfo[0].newTIDRecordCount = 0;
    this.recordInfo[0].existingMIDCount = 0;
    this.recordInfo[0].existingTIDCount = 0;
    this.recordInfo[0].errorneousRecordCount = 0;
    this.recordInfo[0].fileName = undefined;
  }
  async previewUploadData() {
    this._previewed = true;
    this.clearRecordInfo();
    this.isCsvValidationError = false;
    this.isCsvValidData = false;
    this._disableSubmit = true;
    if (this.csvFileToUpload) {
      this._busy = true;
      this.subscribeLoadingStart("parsing and validating");
      //await new Promise(resolve => setTimeout(resolve, 10000));
      var reader = new FileReader();
      reader.readAsText(this.csvFileToUpload);
      const fileName = this.csvFileToUpload.name;
      this.csvData = [];
      this.csvValidationErrors = [];
      //console.log(fileName)
      reader.onload = data => {
        let csvFileData = reader.result;
        let csvFileRecordsArray = (csvFileData as string).split(/\r\n|\n/);
        let cnt: number = 0;
        while (
          csvFileRecordsArray[cnt] != undefined &&
          csvFileRecordsArray[cnt].trim() == ""
        ) {
          cnt = cnt + 1;
        }
        if (csvFileRecordsArray[cnt]) {
          const headers = csvFileRecordsArray[cnt].split("|");
          const fileHeaderIndex = this.mapFieldIndex(headers);
          const fileHeaderMissingInfo = this.isMissingMandatoryColHeader(fileHeaderIndex);
          if (fileHeaderMissingInfo.missingMandatory) {
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Missing mandatory field(s) - " + fileHeaderMissingInfo.missingMadatoryFields + "!";
            objmsg.btnClass = "btn-warning";
            this.tools.simpleMessage(objmsg);
        } else {
            cnt = cnt + 1;
            for (let i = cnt; i < csvFileRecordsArray.length; i++) {
              let rowdata = csvFileRecordsArray[i].split("|");
              if (rowdata != undefined && rowdata.length > 0 && rowdata[0]) {
                const linenumber = i + 1;
                const record = this.parseRecordCheckValidation(linenumber, rowdata, fileHeaderIndex);
                this.csvData.push(record);
              }
            }
            this.recordInfo[0].recordCount = this.csvData ? this.csvData.length : 0;
            this.recordInfo[0].errorneousRecordCount = 0;
            this.recordInfo[0].fileName = fileName;
            const errorenousRecord = this.csvData.filter(obj => obj["validationError"] == true);
            if (errorenousRecord) {
              this.recordInfo[0].errorneousRecordCount = errorenousRecord.length;
            }

            //console.log(this.csvData);
            //console.log(this.csvValidationErrors);
            this._disableSubmit = this.csvData.length > 0 && this.csvValidationErrors.length < 1 ? false : true;
            const midtidlist = this.csvData.map(obj => { return { "mid": obj.mid, "tid": obj.terminalId } });
            this.getMidTidExistingRecordCount(midtidlist);
            //console.log(midtidlist);
          }
        }
        if (
          this.csvValidationErrors &&
          this.csvValidationErrors.length > 0 &&
          this.csvValidationErrors[0]
        ) {
          this.isCsvValidationError = true;
          this.isCsvValidData = false;
        } else {
          this.isCsvValidationError = false;
          this.isCsvValidData = true;
        }
      };
      const _this = this;
      reader.onerror = function() {
        alert("Unable to read " + fileName);
        _this.isCsvValidationError = false;
        _this.isCsvValidData = false;
      };
    } else {
      alert("Please select csv,psv or txt file to upload!");
      //this.csvRecords = [];
    }
    this._busy = false;
    this.unsubscribeLoadingText();
  }

  parseRecordCheckValidation(lineNumber, rowdata,fileHeaderIndex) {
    let record = { lineNumber: lineNumber };
    let validationError = false;
    if (fileHeaderIndex && rowdata) {
      for (let i = 0; i < fileHeaderIndex.length; i++) {
        const col = fileHeaderIndex[i];
        if (col.colsWithIndex) {
          let fieldValue = [];
          for (let j = 0; j < col.colsWithIndex.length; j++) {
            fieldValue.push(rowdata[col.colsWithIndex[j].idx]);
          }
          const value = fieldValue.join(", ");
          record[col["columnFieldName"]] = value;
          const validError = this.validateColumn(lineNumber, col, value,record);
          validationError = validError || validationError
        }
      }
      record["validationError"] = validationError;
    }
    return record;
  }
  
  validateColumn(lineNumber, col, value,record) {
    let validationError = false;
    if (col.mandatory && (value == undefined || value == "")) {
      validationError = true;
      this.csvValidationErrors.push({ line: lineNumber, error: "Missing mandatory field - " + col.columnHeader });
    }
    if (value && col.maxLength && value.length > col.maxLength) {
     validationError = true;
      this.csvValidationErrors.push({ line: lineNumber, error: `Max allowed length of - ${col.columnHeader} is ${col.maxLength} but actual length is ${value.length}` });      
    }
    if (value && col.minLength && value.length < col.minLength) {
      validationError = true;
      this.csvValidationErrors.push({ line: lineNumber, error: `Minimum allowed length of - ${col.columnHeader} is ${col.minLength} but actual length is ${value.length}` });      
    }
    let regExpPattern: string;
    if (value) {
      if (col.regExpFun) {
        regExpPattern = col.regExpFun();
      } else if (col.regExp) {
        regExpPattern = col.regExp;
      }
    }
    if (regExpPattern) {
      const regExp = new RegExp(regExpPattern);
      if (!regExp.test(value)) {
        validationError = true;
        this.csvValidationErrors.push({ line: lineNumber, error: `Field value "${value}" in - ${col.columnHeader} is invalid, does not match pattern` });              
      }
    }
    if (value && col.enum) {
      const allowedEnum: string[] = col.enum;
      const idx = allowedEnum.findIndex(obj => obj["value"].toLowerCase() === value.toLowerCase());
      if (idx < 0) {
        validationError = true;
        this.csvValidationErrors.push({ line: lineNumber, error: `Invalid value ${value} in ${col.columnHeader}, allowed values are - ${allowedEnum.map(obj => obj["value"]).join(", ")}` });      
      } else {
        record[col.columnFieldName] = allowedEnum[idx]["code"];
      }
    }
    if (col.unique) {
      if (this.csvData.findIndex(itm => itm[col["columnFieldName"]] === value) > -1) {
        validationError = true;
        this.csvValidationErrors.push({ line: lineNumber, error: `Duplicate ${col.columnHeader} - ${value}. ${col.columnHeader} should be unique.` });        
      }
    }

    return validationError;
  }

  isMissingMandatoryColHeader(collist) {
    let missingMandatory = false;
    let missingMadatoryFields = "";
    if (collist && collist.length > 0) {
      const mandatoryCols = collist.filter(obj => obj.mandatory)
      if (mandatoryCols && mandatoryCols.length > 0) {
        const missingMandatoryCols = mandatoryCols.filter(obj => (!obj.headerFound));
        if (missingMandatoryCols && missingMandatoryCols.length > 0) {
          missingMandatory = true;
          let fieldName = [];
          for (let i = 0; i < missingMandatoryCols.length; i++) {
            if (missingMandatoryCols[i].fileCols && missingMandatoryCols[i].fileCols) {
              for (let j = 0; j < missingMandatoryCols[i].fileCols.length; j++) {
                fieldName.push(missingMandatoryCols[i].fileCols[j])
              }
            }
          }
          missingMadatoryFields = fieldName.join(", ");
        }
      }
    }
    return { missingMandatory: missingMandatory, missingMadatoryFields: missingMadatoryFields };
  }

  mapFieldIndex(headers : string[]) {
    let collist = this.tools.copyFullObject(this.colconfig);
    if (collist && collist.length > 0) {
      for (let i = 0; i < collist.length; i++) {
        let filecol = collist[i];
        const fileCols = filecol.fileCols;
        if (fileCols && fileCols.length > 0) {
          let found = false;
          for (let j = 0; j < fileCols.length; j++) {
            const idx = headers.findIndex(val => val === fileCols[j])
            if (idx > -1) {
              if (!filecol["colsWithIndex"]) {
                filecol["colsWithIndex"] =[]
              }
              filecol["colsWithIndex"].push({ col: fileCols[j], idx: idx });
              found = true;
            }
          }
          filecol["headerFound"] = found
        }
      }
    }
    return collist;
  }

  checkIfHeaderMissing(fileheaderelements: Array<CsvFieldHeaders>) {
    const missingindexelements = fileheaderelements.filter(
      obj => obj.index == undefined
    );
    let missingfields: string[] = new Array<string>();
    if (missingindexelements.length <= 0) {
      return false;
    }
    for (let i = 0; i < missingindexelements.length; i++) {
      missingfields.push(missingindexelements[i].name);
    }
    const fields = missingfields.join(",");
    if (!this.csvValidationErrors) {
      this.csvValidationErrors = Array<UploadError>();
    }
    this.csvValidationErrors.push({
      line: 1,
      error: "Missing header field(s) - " + fields
    });
  }

  getErrorText(val) {
    if (val) {
      return "Error";
    }
  }

  errorClass(obj) {
    if (obj.validationError) {
      return "text-danger";
    }
  }

  showErrList = (O, col, _scope) => {
    if (O && this.csvValidationErrors && this.csvValidationErrors.length > 0) {
      const lineErrors = this.csvValidationErrors.filter(obj => obj.line === O.lineNumber);
      if (lineErrors) {
        let objUploadErrorMsg = new UploadErrorMsg();
        objUploadErrorMsg.errors = lineErrors;
        this.tools.UploadErrorsMessage(objUploadErrorMsg);
      }
    }
  }

  errorneousRecLink(val) {
    if (val > 0) {
      return "link";
    }
  }
  findDistinctMids(midtidlist) {
    
    let mids: string[] = [];
    if (midtidlist && midtidlist.length > 0) {
      for (let i = 0; i < midtidlist.length; i++) {
        const mid = midtidlist[i].mid;
        if (mids.findIndex(itm => itm === mid) < 0) {
          mids.push(mid);
        }
      }
    }
    return mids.length;
  }

  getMidTidExistingRecordCount(midtidlist) {
    this.merchantuploadservice.getExistingMidTidCount(midtidlist).subscribe(res => {
      if (res["success"]) {
        const result = res["result"];
        this.recordInfo[0].existingMIDCount = result["midCount"];
        this.recordInfo[0].existingTIDCount = result["tidCount"];
        this.recordInfo[0].newMIDRecordCount = this.findDistinctMids(midtidlist) - this.recordInfo[0].existingMIDCount;
        this.recordInfo[0].newTIDRecordCount = this.recordInfo[0].recordCount - this.recordInfo[0].existingTIDCount;
      } else {
        console.error("Error from Api to get existing record count!")
      }
    }, err => {
        console.error("Failed to get existing record count!")
    })
  }
  onSubmitUploadClick() {
    let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to upload merchant?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.submitUploadData();
          }
        },
        reason => {}
      );
  }
  submitUploadData() {
    let merchantUploadData = Object.assign(this.recordInfo[0], {});
    merchantUploadData["data"] = this.csvData;
    let objmsg: SimpleMessage = new SimpleMessage();
    this._busy = true;
    this.subscribeLoadingStart("uploading");
    this.merchantuploadservice.uploadMerchant(merchantUploadData).subscribe(res => {
      if (res["success"]) {
        objmsg.title = "Success";
        objmsg.message = "File uploaded successfully!";
        objmsg.btnClass = "btn-success";
        this.tools.simpleMessage(objmsg);
        console.log("File uploaded successfully");
        this._disableSubmit = true;
      } else {
        const result = res["result"];
        objmsg.message = "Failed to upload File";
        if (result && result["validationError"]) {
          objmsg.message += " - Validation Error. Check Errorneous Record"
          this.updateOnSaveValidationError(result["validationError"]);
        }
        objmsg.message += "!";
        objmsg.title = "Error";
        
        objmsg.btnClass = "btn-danger";
        this.tools.simpleMessage(objmsg);
        console.error("Failed to upload File");
      }
      this._busy = false;
      this._previewed = false;
      this.unsubscribeLoadingText();
    }, err => {
        this._busy = false;
        objmsg.title = "Error";
        objmsg.message = "File uploaded failed!";
        objmsg.btnClass = "btn-danger";
        this.tools.simpleMessage(objmsg);
        this._previewed = false;
        this.unsubscribeLoadingText();
    })
  }

  updateOnSaveValidationError(validationError) {
    this.csvValidationErrors = validationError;
    this.recordInfo[0].errorneousRecordCount = validationError.length;
    for (let i = 0; i < validationError.length; i++) {
      const idx = this.csvData.findIndex(d => d.lineNumber == validationError[i].lineNumber);
      if (idx > -1) {
        this.csvData[idx].validationError = true;
      }
    }
  }
}
