import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";
import { mppDataModel } from "../models/master.pricing.plan.model";
@Injectable()
export class MasterPricingPlanService {
  constructor(private http: HttpClient, private appsetting: AppSettings) {}

  getMPPList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (
      searchField.merchantCategory == "L" ||
      searchField.merchantCategory == "S" ||
      searchField.merchantCategory == "X"
    ) {
      httpParam = httpParam.append(
        "merchantCategory",
        searchField.merchantCategory
      );
    }
    if (searchField.merchantType && searchField.merchantType != "") {
      httpParam = httpParam.append("merchantType", searchField.merchantType);
    }
    if (sortField) {
      if (sortField.sortFieldName && sortField.sortDirection != "") {
        httpParam = httpParam.append("sortDirection", sortField.sortDirection);
      }
      if (sortField.sortFieldName && sortField.sortFieldName != "") {
        httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
      }
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/pricingPlanList", {
       // .get(this.appsetting.get("server-url") + "/api/mpplist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }
  getAllMppList() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/pricingPlanList", {
        headers: header,
        observe: "body",
        responseType: "json",
      })
      .map((res) => res);
  }
  // getAllMppList() {
  //   let header = new HttpHeaders();
  //   return this.http
  //     .get(this.appsetting.get("server-url") + "/api/allmpplist", {
  //       headers: header,
  //       observe: "body",
  //       responseType: "json",
  //     })
  //     .map((res) => res);
  // }
  syncMPP() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/syncmasterplanpricing", {
        headers: header,
        observe: "body",
        responseType: "json",
      })
      .map((res) => res);
  }

  getMasterPricingPlanSlabs(id: number) {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/pricingplanslabs/" + id, {
        headers: header,
        observe: "body",
        responseType: "json",
      })
      .map((res) => res);
  }

  saveMPPPlan(mppplan: mppDataModel) {
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/savePricingPlan", mppplan, {
        headers: header
      })
      .map(res => res);
  }
  updateMPPPlan(mppplan: mppDataModel) {
    let header = new HttpHeaders();
    return this.http
      .put(this.appsetting.get("server-url") + "/api/updatePricingPlanList/"+mppplan.id, mppplan, {
        headers: header
      })
      .map(res => res);
  }
} 
