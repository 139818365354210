import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  AfterViewInit,
} from "@angular/core";
//import { Scope } from '@angular/core/src/profile/wtf_impl';
//import {Scope} from '@angular/core/esm5/src/profile/wtf_impl';
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { SimpleMessage } from "../../../modules/util.class";
import { Tools } from "src/app/framework/modules/tools";

export class gridColumns {
  columnHeader?: string;
  columnHeaderFunction?: Function;
  columnFieldName?: string;
  columnClasses?: string = "";
  callbackFunction?: Function;
  callbackFunctionRowPrm?: Function;
  isSrCol?: boolean = false;
  callClassFunction?: Function;
  callCellClickFunction?: Function;
  _parentscope?: any;
  extra?: any;
}
export class GridPage {
  offset?: number = 0; /*Start record from*/
  totalPages?: number = 0; /*number of pages*/
  totalElements?: number = 0; /*number of records*/
  last?: boolean = true; /*is last page*/
  first?: boolean = true; /*is first page*/
  size?: number = 0; /*page size*/
  number?: number = 0; /* current page*/
  numberOfElements?: number; /*Total number of record*/
}
@Component({
  selector: "app-gridtable",
  templateUrl: "./gridtable.component.html",
  styleUrls: ["./gridtable.component.css"],
})
export class GridtableComponent implements OnInit {
  @Input()
  gridcolumns: gridColumns[];
  @Input()
  data: Array<object>;
  @Input()
  deleteButton: boolean = false;
  @Input()
  page: GridPage;
  @Input()
  currentKeyValue: string;
  @Input()
  keyFieldName: string;
  @Input()
  showDeleteButtonFunction: Function;
  @Input()
  _scope: any;
  @Input()
  disable: boolean = false;
  @Input()
  tableClassName: string;
  @Input()
  rowsCaption: string;
  @Output()
  loadDataEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  gridRowClickEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  deleteClickedEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  rowDblClickEvent: EventEmitter<object> = new EventEmitter<object>();

  pages: number[] = [];
  maxPageButton: number = 5;

  constructor(private tools: Tools) {
    //  console.log(this.gridcolumns);
    /*    if(this.data ==undefined) {
      this.data=new Array<object>();
    }*/
  }

  ngOnInit() {
    this.refreshData("I");
  }

  refreshData(source: string) {
    this.page.number = 0;
    this.triggerLoadDataEvent(0, source);
    this.setPages();
  }
  setPages() {
    this.pages = [];
    this.pages.push(1);
    for (let i = 2; i <= this.page.totalPages && i <= this.maxPageButton; i++) {
      this.pages.push(i);
    }
  }
  movePageButton(moveBy: number) {
    if (
      (moveBy == -1 && this.pages[0] > 1) ||
      (moveBy == 1 && this.pages[this.pages.length - 1] < this.page.totalPages)
    ) {
      for (let i = 0; i < this.pages.length; i++) {
        this.pages[i] = this.pages[i] + moveBy;
      }
    }
  }

  movePage(moveBy: number) {
    if (
      (moveBy == -1 &&
        this.page.number + 1 == this.pages[0] &&
        this.page.number > 0) ||
      (moveBy == 1 &&
        this.page.number + 1 == this.pages[this.pages.length - 1] &&
        this.page.number + 1 < this.page.totalPages)
    ) {
      this.movePageButton(moveBy);
    }
    if (
      (moveBy == -1 && this.page.number + 1 > 1) ||
      (moveBy == 1 && this.page.number + 1 < this.page.totalPages)
    ) {
      this.page.number = this.page.number + moveBy;
      this.triggerLoadDataEvent(this.page.number, "P");
    }
  }
  setPageNumber(page: number) {
    this.triggerLoadDataEvent(page, "P");
  }
  triggerLoadDataEvent(page: number, source: string) {
    if (this.page.size > 0) {
      this.page.number = page;
      var options = {
        pageSize: +this.page.size,
        from: this.page.number * this.page.size,
        source: source,
      };
      this.loadDataEvent.emit(options);
    } else {
      let objmsg: SimpleMessage = new SimpleMessage();
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      objmsg.title = "Alert";
      objmsg.message = "Max Rows should be greater than 0!";
      objmsg.btnClass = "btn-warning";
      this.tools.simpleMessage(objmsg);
    }
  }
  selectRow(obj) {
    if (!this.disable) {
      this.gridRowClickEvent.emit(obj);
    }
  }
  getHeader(col: gridColumns) {
    let headertext: string = "";
    if (col.columnHeaderFunction) {
      headertext = col.columnHeaderFunction(this._scope);
    } else {
      headertext = col.columnHeader;
    }
    return headertext;
  }
  getValue(obj: any, col: gridColumns) {
    let retvalue: string;
    let colfieldname = col.columnFieldName.split(".");
    if (col.callbackFunctionRowPrm) {
      retvalue = col.callbackFunctionRowPrm(obj, col._parentscope,col);
    } else {
      let objColVal: any = obj;
      colfieldname.forEach((part) => {
        if (objColVal) {
          objColVal = objColVal[part];
        }
      });

      if (col.callbackFunction) {
        retvalue = col.callbackFunction(objColVal, col._parentscope,col);
      } else {
        retvalue = objColVal;
        //retvalue= obj[col.columnFieldName];
      }
    }

    return retvalue;
  }
  highlightGridRow(obj) {
    if (obj[this.keyFieldName] == this.currentKeyValue) {
      return true;
    } else {
      return false;
    }
  }
  cutText = function (text: string, length: number) {
    if (text.length > length) {
      return text.substr(1, length - 3) + "...";
    } else {
      return text;
    }
  };
  deleteRec(obj: object, index: number) {
    let options = { obj: obj, index: index };
    this.deleteClickedEvent.emit(options);
  }
  onDblClick(O) {
    if (!this.disable) {
      this.rowDblClickEvent.emit(O);
    }
  }
  getColClass(obj: any, col: gridColumns) {
    let retvalue: string;
    let colfieldname = col.columnFieldName.split(".");
    let objColVal: any = obj;
    colfieldname.forEach((part) => {
      if (objColVal) {
        objColVal = objColVal[part];
      }
    });
    if (col.callClassFunction) {
      retvalue = col.callClassFunction(objColVal);
    }
    if (col.columnClasses) {
      retvalue = retvalue
        ? retvalue + " " + col.columnClasses
        : col.columnClasses;
    }
    return retvalue;
  }

  isDeleteButton(O) {
    if (!this.disable) {
      let retvalue = this.deleteButton;
      if (retvalue && this.showDeleteButtonFunction) {
        retvalue = this.showDeleteButtonFunction(O);
      }
      return retvalue;
    }
  }

  getTableClass() {
    let classNames = "table table-sm table-striped table-bordered ml-0 mr-2 mb-2 ";
    if (this.tableClassName) {
      classNames += this.tableClassName + " ";
    } else {
      classNames += "mh-75 ";
    }
    if (!this.disable) {
      classNames += " c-table-hover ";
    }
    return classNames;
  }

  cellClick(O, col) {
    if (col.callCellClickFunction) {
      col.callCellClickFunction(O, col,col._parentscope);
    }
  }

  getRowsNumEleCaption() {
    let val = this.rowsCaption || 'Row';
    if (this.page.numberOfElements > 1) {
      val += "s";
    }
    return val;
  }

  getRowsPageSizeCaption() {
    let val = this.rowsCaption || 'Row';
    if (this.page.size > 1) {
      val += "s";
    }
    return val;
  }
}
