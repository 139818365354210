import { Component, OnInit, ViewChild } from "@angular/core";
import {
  TopbarText,
  TopbarComponent
} from "../../framework/components/topbar/topbar.component";
import { AuthService } from "../../framework/services/auth.service";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../framework/util/components/gridtable/gridtable.component";
import { Tools } from "../../framework/modules/tools";
import {
  SortClass,
  SimpleMessage,
  MARSResponseErrorMsg
} from "../../framework/modules/util.class";
import { Router } from "@angular/router";
import { EmployeeModel } from "../../models/employee.model";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "src/app/framework/util/components/modalcomponent/modal-content";
import { EmployeeService } from "../../services/employee.service";
import { global } from "src/app/framework/modules/global";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
import { MARSErrorResponse } from "src/app/models/mars.response.error.module";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
import { AggregatorDistributorModel } from "src/app/models/aggregator.distributor.model";

@Component({
  selector: "app-employee",
  templateUrl: "./employee.component.html",
  styleUrls: ["./employee.component.css"]
})
export class EmployeeComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  gridcols: Array<gridColumns> = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "Emp Code",
      columnFieldName: "empCode",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Aggregator",
      columnFieldName: "aggregator.name",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Name",
      columnFieldName: "empName",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Mobile",
      columnFieldName: "mobile",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Designation",
      columnFieldName: "designation",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Department",
      columnFieldName: "department",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Joining Date",
      columnFieldName: "joiningtDate",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYY
    }
  ];
  sortFields = [
    { name: "id", display: "-" },
    { name: "empCode", display: "Employee Code" },
    { name: "empName", display: "Employee Name" }
  ];

  topbartext: TopbarText[] = [
    { key: "employeelist", displayText: "Employee List" }
  ];
  _empCode: string;
  searchField = {
    searchText: ""
  };
  sort: SortClass;
  datalist: Array<EmployeeModel>;
  data: EmployeeModel;
  _data: any;
  isEmployeeDetailScreen: boolean = false;
  _menuId: string;
  _action: string = "";
  page: GridPage;
  _isChange: boolean = false;
  _busy: boolean = false;
  aggregatorData: AggregatorDistributorModel;
  _aggregatorData: AggregatorDistributorModel;

  constructor(
    public tools: Tools,
    private auth: AuthService,
    private router: Router,
    private ngbModalService: NgbModal,
    private objemployeeService: EmployeeService,
    private objGlobal: global,
    private csvservice: ExporttocsvService,
    private aggregatorDistributorService: AggregatorDistributorService
  ) {
    this.sort = new SortClass();
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );

    this.data = new EmployeeModel();
    this.page = new GridPage();
    this.page.size = 10; //Default page size

    this._menuId = this.tools.getMenuName("main/employee");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "empCode";
    this.aggregatorData = new AggregatorDistributorModel();
    this._aggregatorData = new AggregatorDistributorModel();
    this.aggregatorDistributorService.loadAggregatorDistrbutorIdNameType();
    this.getEmployeeList();
  }

  ngOnInit() {}

  onRowDblClick(e: any) {
    if (e.empCode) {
      this._action = "update";
      this.isEmployeeDetailScreen = true;
      this.topbartext = [
        { key: "employeedetail", displayText: "Employee Detail" }
      ];
      this.getData(e.empCode);
    }
  }
  getData(empCode: string) {
    this.objemployeeService.getEmployee(empCode).subscribe(
      data => {
        if (data["success"]) {
          this.data = data["result"];
          if (this.data.joiningtDate) {
            this.data.joiningtDate = new Date(this.data.joiningtDate);
          }
          if (this.data.aggregator) {
            this.aggregatorData = this.data.aggregator;
          } else {
            this.aggregatorData = new AggregatorDistributorModel();
          }
          this._aggregatorData = this.tools.copyFullObject(this.aggregatorData);
          this._data = this.tools.copyFullObject(this.data);
          this._action = "update";
        } else {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          switch (data["message"]) {
            case "INVALIDEMPCODE":
              objmsg.title = "Error";
              objmsg.message = "Invalid employee code!";
              objmsg.btnClass = "btn-warning";
              break;
            default:
              objmsg.title = "Error";
              objmsg.message = "Failed to get data!";
              objmsg.btnClass = "btn-warning";
          }
          this.tools.simpleMessage(objmsg);
        }
      },
      err => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to get data!";
        objmsg.btnClass = "btn-warning";
      }
    );
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }

  onAddNew() {
    this._action = "new";
    this.isEmployeeDetailScreen = true;
    this.topbartext = [{ key: "addemployee", displayText: "Add Employee" }];
    this.aggregatorData = new AggregatorDistributorModel();
    if (this.allowAggregatorSelection()) {
      this.aggregatorData.id = 0;
    }
    this._aggregatorData = this.tools.copyFullObject(this.aggregatorData);

    this.data = new EmployeeModel();
    this._data = this.tools.copyFullObject(this.data);
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Employee has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isEmployeeDetailScreen = false;
            this.topbartext = [
              { key: "employeelist", displayText: "Employee List" }
            ];
            if (this._isChange == true) {
              this._isChange = false;
              this.getEmployeeList();
            }
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isEmployeeDetailScreen = false;
      this.topbartext = [{ key: "employeelist", displayText: "Employee List" }];
      if (this._isChange == true) {
        this._isChange = false;
        this.getEmployeeList();
      }
    }
  }

  isModified() {
    if (!(this.data && this._data)) {
      return false;
    }
    if (this.data.empName != this._data.empName) {
      return true;
    } else if (this.data.mobile != this._data.mobile) {
      return true;
    } else if (this.data.department != this._data.department) {
      return true;
    } else if (this.data.designation != this._data.designation) {
      return true;
    } else if (this.data.jobTitle != this._data.jobTitle) {
      return true;
    } else if (
      !this.tools.isDateSame(this.data.joiningtDate, this._data.joiningtDate)
    ) {
      return true;
    } else if (this.isModifiedAggregator()) {
      return true;
    }
    return false;
  }

  isModifiedAggregator() {
    let bresult = false;
    const oldAggregatorId =
      this._aggregatorData && this._aggregatorData.id != undefined
        ? this._aggregatorData.id
        : undefined;
    const newAggregatorId =
      this.aggregatorData && this.aggregatorData.id != undefined
        ? this.aggregatorData.id
        : undefined;
    if (oldAggregatorId != newAggregatorId) {
      bresult = true;
    }
    return bresult;
  }

  getEmployeeList() {
    this.objemployeeService
      .getEmployeeList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get employee list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get employee list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  onSaveClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to save?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.saveEmployee();
        }
      },
      reason => {}
    );
  }

  saveEmployee() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    let objmsg: SimpleMessage = new SimpleMessage();
    document.body.style.cursor = "wait";
    this._busy = true;
    if (this.aggregatorData && this.aggregatorData.id != undefined) {
      this.data.aggregator = { id: this.aggregatorData.id };
    }
    this.objemployeeService.saveEmployee(this.data, this._action).subscribe(
      data => {
        if (data["success"]) {
          this.data = data["result"];
          this.convertDateField(this.data);
          this._data = this.tools.copyFullObject(this.data);
          this._aggregatorData = this.tools.copyFullObject(this.aggregatorData);
          this._isChange = true;
          if (this._action == "new" || !this.data.marsCode) {
            this._action = "update";
            this.submitToMars("S");
          } else {
            document.body.style.cursor = "default";
            this._busy = false;
            objmsg.title = "Success";
            objmsg.message = "Employee saved successfully!";
            objmsg.btnClass = "btn-warning";
            this.tools.simpleMessage(objmsg);
          }
        } else {
          document.body.style.cursor = "default";
          this._busy = false;
          if (data["message"] == "ALREADYEXISTS") {
            objmsg.title = "Error";
            objmsg.message = "Employee code already exists!";
            objmsg.btnClass = "btn-warning";
          } else if (data["message"] == "INVALIDEMPCODE") {
            objmsg.title = "Error";
            objmsg.message = "Invalid empcode to modify!";
            objmsg.btnClass = "btn-warning";
          } else {
            objmsg.title = "Error";
            objmsg.message = "Failed to save Employee!";
            objmsg.btnClass = "btn-error";
          }
          this.tools.simpleMessage(objmsg);
        }
      },
      err => {
        document.body.style.cursor = "default";
        this._busy = false;
        objmsg.title = "Error";
        objmsg.message = "Failed to save Employee!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  submitToMars(source: string) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    let objmsg: SimpleMessage = new SimpleMessage();
    document.body.style.cursor = "wait";
    this._busy = true;
    this.objemployeeService.updateToMARS(this.data).subscribe(
      res => {
        document.body.style.cursor = "default";
        this._busy = false;
        if (res["success"]) {
          if (res["result"] && res["result"].marsCode) {
            this.data.marsCode = res["result"].marsCode;
            this.data.status = res["result"].status;
          }
          objmsg.title = "Information";
          if (source == "D") {
            objmsg.message = "Employee updated in MARS successfully!";
          } else {
            objmsg.message = "Employee saved successfully!";
          }
          objmsg.btnClass = "btn-info";
        } else {
          const msg: String = res["message"];
          objmsg.title = "Warning";
          if (source == "D") {
            objmsg.message = "MARS update failed, error - " + msg;
          } else {
            objmsg.message =
              "Employee saved successfully but MARS update failed, error - " +
              msg;
          }
          objmsg.btnClass = "btn-warning";
        }
        this.tools.simpleMessage(objmsg);
      },
      err => {
        document.body.style.cursor = "default";
        this._busy = false;
        objmsg.title = "Warning";
        if (source == "D") {
          objmsg.message =
            "Employee saved successfully but MARS update failed, error - " +
            err;
        } else {
          objmsg.message = "MARS update failed, error - " + err;
        }
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  disableSubmit(f: any) {
    let isDisableSave: boolean = true;
    if (!this._busy) {
      if (f.valid) {
        if (this.isModified()) {
          if (this.isAllowAddorEdit()) {
            isDisableSave = false;
          }
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }

  disableCancel(f: any) {
    if (this._busy) {
      return true;
    }
    return !this.isModified();
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  convertDateField(data: EmployeeModel) {
    if (data.joiningtDate) {
      data.joiningtDate = new Date(data.joiningtDate);
    }
  }
  onEmployeeRowDblClick(event) {}
  onCancelClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to discard changes?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.data = this.tools.copyFullObject(this._data);
        }
      },
      reason => {}
    );
  }

  getEmployeeListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.objemployeeService
      .getEmployeeList(page, this.searchField, sort)
      .subscribe(
        data => {
          let obj: Array<EmployeeModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "employee"
            );
          } else {
            alert("Failed to get employee list!");
          }
        },
        error => {
          alert("Failed to get employee list!");
        }
      );
  }
  onExcelClick() {
    this.getEmployeeListForExcel();
  }

  getStatus() {
    let returnValue = "";
    switch (this.data.status) {
      case "A":
        returnValue = "Active";
        break;
      case "P":
        returnValue = "Pending for MARS update";
        break;
      case "I":
        returnValue = "Inactive";
        break;
      case "F":
        returnValue = "MARS Update failed";
        break;
    }
    return returnValue;
  }

  disableBackbutton() {
    return this._busy;
  }

  showMARSResponseError() {
    if (this.data.empCode) {
      this.objemployeeService
        .getEmployeeMARSResApi(this.data.empCode)
        .subscribe(
          res => {
            if (res["result"]) {
              const data: MARSErrorResponse = res["result"];
              let objMsg: MARSResponseErrorMsg = new MARSResponseErrorMsg();
              objMsg.forEntity = this.data.empName;
              objMsg.errorMsg = data;
              this.tools.marsResponseErrorMessage(objMsg);
            } else {
              console.error(res["message"]);
            }
          },
          err => {
            console.error("Error to get MARS Reponse Errors");
          }
        );
    }
  }

  allowAggregatorSelection() {
    return this.auth.getUserDistributorId() ? false : true;
  }

  getAggregatorList() {
    let aggregatorlist: Array<AggregatorDistributorModel> = undefined;

    if (this.allowAggregatorSelection()) {
      if (
        this.aggregatorDistributorService.aggregatorDistributorList &&
        this.aggregatorDistributorService.aggregatorDistributorList.length > 0
      ) {
        aggregatorlist = this.aggregatorDistributorService.aggregatorDistributorList.filter(
          obj => obj.id == 0 || obj.type == "A"
        );
      }
    }
    return aggregatorlist;
  }
}
