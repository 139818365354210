import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { global } from 'src/app/framework/modules/global';
import { Tools } from 'src/app/framework/modules/tools';
import { SimpleMessage } from 'src/app/framework/modules/util.class';
import { AuthService } from 'src/app/framework/services/auth.service';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { gridColumns, GridPage, GridtableComponent } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { AggregatorDistributorModel } from 'src/app/models/aggregator.distributor.model';
import { AggregatorDistributorService } from 'src/app/services/aggregator.distributor.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-transaction-summary',
  templateUrl: './transaction-summary.component.html',
  styleUrls: ['./transaction-summary.component.css']
})
export class TransactionSummaryComponent implements OnInit {


  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  repType = "C";

  searchField = {
    fromDate: undefined,
    tillDate: undefined,
    aggregatorId: undefined,
    tid: undefined,
    transactionType: undefined,
    cardType: undefined,
    product: undefined,
    network: undefined
  };
  datalist: any[] = [];
  page: GridPage;
  _menuId: string;
  tranDate: any;
  constructor(public tools: Tools,
    private router: Router,
    private objGlobal: global,
    private reportService: ReportService,
    private auth: AuthService,
    private csvservice: ExporttocsvService,
    private aggregatorDistributorService: AggregatorDistributorService) { 
        this.repType = "C";
        let dateFrom: Date = new Date();
        dateFrom.setDate(1);
        this.searchField.fromDate = dateFrom;
        this.searchField.tillDate = new Date();
        this.page = new GridPage();
        this.page.size = 20; //Default page size
        console.log(this.router.url.substring(1, this.router.url.length))
        this._menuId = this.tools.getMenuName(
            this.router.url.substring(1, this.router.url.length)
        );
        this.objGlobal.setActiveMenu(this._menuId);
        this.aggregatorDistributorService.loadAggregatorDistrbutorIdNameType();
    }

  ngOnInit() {
  }
  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }
  getTopbar() {
    let topbartext: TopbarText[] = [];
    if (this.repType == "C") {
      topbartext.push(
        {
          key: "tranction-summary",
          displayText: "TRANSACTION SUMMARY - CARD WISE",
          class: "col-md-6",
          anchorClass: "h6",
        });
    } else if (this.repType == "S") {
      topbartext.push(
        {
          key: "tranction-summary",
          displayText: "TRANSACTION SUMMARY - BUSINESS STREAM WISE",
          class: "col-md-6",
          anchorClass: "h6",
        });      
    } else if (this.repType == "P") {
      topbartext.push(
        {
          key: "tranction-summary",
          displayText: "TRANSACTION SUMMARY - PRODUCT WISE",
          class: "col-md-6",
          anchorClass: "h6",
        });      
    } else if (this.repType == "N") {
      topbartext.push(
        {
          key: "tranction-summary",
          displayText: "TRANSACTION SUMMARY - NETWORK WISE",
          class: "col-md-6",
          anchorClass: "h6",
        });      
    }
    return topbartext;
  }

  getGridCos() {
    let gridcols: gridColumns[] = [
      { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" }];
    if (this.repType == "C") {
      gridcols.push({
        columnHeader: "CARD TYPE",
        columnFieldName: "cardType",
        columnClasses: "c-col-pc-15"
      })
    } else if (this.repType == "S") {
      gridcols.push({
        columnHeader: "TRANSACTION TYPE",
        columnFieldName: "transactionType",
        columnClasses: "c-col-pc-15"
      })
    } else if (this.repType == "P") {
      gridcols.push({
        columnHeader: "PRODUCT",
        columnFieldName: "productType",
        columnClasses: "c-col-pc-15"
      })      
    } else if (this.repType == "N") {
      gridcols.push({
        columnHeader: "NETWORK",
        columnFieldName: "network",
        columnClasses: "c-col-pc-15"
      })      
    }
    gridcols.push(
      {
        columnHeader: "TRANSACTION COUNT",
        columnFieldName: "transactionCount",
        columnClasses: "c-col-pc-10 text-right pr-2",
        callbackFunction: this.tools.formateNumberD0
      });
    gridcols.push(
      {
        columnHeader: "TRANSACTION AMOUNT",
        columnFieldName: "transactionAmount",
        columnClasses: "c-col-pc-10 text-right pr-2",
        callbackFunction: this.tools.formateNumberD2
      });
    gridcols.push(
      {
        columnHeader: "MDR AMOUNT",
        columnFieldName: "totalMDR",
        columnClasses: "c-col-pc-10 text-right pr-2",
        callbackFunction: this.tools.formateNumberD2
      });
    gridcols.push(
      {
        columnHeader: "IRF AMOUNT",
        columnFieldName: "totalIRF",
        columnClasses: "c-col-pc-10 text-right pr-2",
        callbackFunction: this.tools.formateNumberD2
      });  
    gridcols.push(
      {
        columnHeader: "REVENU",
        columnFieldName: "totalRevenue",
        columnClasses: "c-col-pc-10 text-right pr-2",
        callbackFunction: this.tools.formateNumberD2
      }); 
    
    return gridcols;
  }
  getReportData() {
    this.datalist = [];
    this.reportService
      .getTransactionSummary(this.repType, this.page, this.searchField)
      .subscribe(
        (res) => {
          
          if (res["success"]) {
            this.page.first = res["result"].first;
            this.page.last = res["result"].last;
            this.page.number = res["result"].number;
            this.page.numberOfElements = res["result"].numberOfElements;
            this.page.offset = res["result"].offset;
            this.page.size = res["result"].size;
            this.page.totalElements = res["result"].totalElements;
            this.page.totalPages = res["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
            this.datalist = res["result"].content;
            //this.datalist = res["result"].content;
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get transaction summary data!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get transaction summary data!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  exportToCsv() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;

    this.reportService
      .getTransactionSummary(this.repType, page, this.searchField)
      .subscribe(
        (res) => {
          
          if (res["success"]) {
            let obj: Array<any>=[];
            const data = res["result"];
            data.forEach(rec => {
              if (rec.data) {
                rec.data.forEach(d => {
                  obj.push(d);
                })
              }
            })
            //obj = res["result"];
            this.csvservice.exportToCsv(
              obj,
              this.getGridCos(),
              undefined,
              "transaction-summary"
            );
          } else {
            alert("Failed to get transaction summary data!");
          }
        },
        (error) => {
          alert("Failed to get transaction summary data!");
        }
      );
  }
  onExcelClick() {
    this.exportToCsv();
  }
  allowAggregatorSelection() {
    return this.auth.getUserAggregatorId() || this.auth.getUserDistributorId() ? false : true;
  }
  getAggregatorList() {
    let aggregatorlist: Array<AggregatorDistributorModel> = undefined;
    if (this.allowAggregatorSelection()) {
      if (
        this.aggregatorDistributorService.aggregatorDistributorList &&
        this.aggregatorDistributorService.aggregatorDistributorList.length > 0
      ) {
        aggregatorlist = this.aggregatorDistributorService.aggregatorDistributorList.filter(
          (obj) => obj.id == 0 || obj.type == "A"
        );
      }
    }
    return aggregatorlist;
  }

  onRepTypeChange() {
    this.getReportData()
  }

}
