import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { MCCModel } from "../models/MCC.model";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";

@Injectable({
  providedIn: "root"
})
export class MCCService {
  constructor(private http: HttpClient, private appsetting: AppSettings) {}

  saveMCC(mcc: MCCModel, actiontype: string) {
    let header = new HttpHeaders();
    if (actiontype == "new") {
      return this.http
        .post(this.appsetting.get("server-url") + "/api/newmcc", mcc, {
          headers: header
        })
        .map(res => res);
    } else {
      return this.http
        .post(this.appsetting.get("server-url") + "/api/updatemcc", mcc, {
          headers: header
        })
        .map(res => res);
    }
  }

  getMCCList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (
      searchField.businessSegment == "E" ||
      searchField.businessSegment == "N"
    ) {
      httpParam = httpParam.append(
        "businessSegment",
        searchField.businessSegment
      );
    }
    if (sortField) {
      if (sortField.sortFieldName && sortField.sortDirection != "") {
        httpParam = httpParam.append("sortDirection", sortField.sortDirection);
      }
      if (sortField.sortFieldName && sortField.sortFieldName != "") {
        httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
      }
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/mcclist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getMCC(mccCode: string) {
    let header = new HttpHeaders();
    //let httpParam: HttpParams = new HttpParams();
    //httpParam = httpParam.append("mccCode", mccCode);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/mcc/" + mccCode, {
        headers: header,
        observe: "body",
        //params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }
  getAllMCC() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/allmcc", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }
}
