import { Component, OnInit, Input } from "@angular/core";
import { IndentModel } from "src/app/models/indent.model";
import * as jspdf from "jspdf";
import { UserOptions } from "jspdf-autotable";
import html2canvas from "html2canvas";

@Component({
  selector: "app-indent-form",
  templateUrl: "./indent-form.component.html",
  styleUrls: ["./indent-form.component.css"]
})
export class IndentFormComponent implements OnInit {
  @Input()
  data: IndentModel;

  constructor() {
    console.log(this.data);
  }

  ngOnInit() {}

  getIndentFieldValue(fieldName: string) {
    if (this.data) {
      return this.data[fieldName];
    } else {
      return undefined;
    }
  }

  downloadpdf() {
    var data = document.getElementById("indentform");
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("MYPdf.pdf"); // Generated PDF
    });
  }
}
