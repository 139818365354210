import { Component, OnInit, ViewChild } from "@angular/core";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../framework/util/components/gridtable/gridtable.component";
import { AuthService } from "../../framework/services/auth.service";
import { global } from "../../framework/modules/global";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Tools } from "../../framework/modules/tools";
import { DeviceStockModel, DeviceReturnModel } from "../../models/device.model";
import {
  DeviceStockService,
  DeviceReturnService
} from "../../services/device.service";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "../../framework/util/components/modalcomponent/modal-content";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { MerchantModel } from "src/app/models/merchant.model";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";

class SearchField {
  searchText: string;
  returnDate: Date[];
  deviceCondition: String;
  constructor() {
    this.searchText = "";
    this.returnDate = undefined;
    this.deviceCondition = undefined;
  }
}

@Component({
  selector: "app-devicereturn",
  templateUrl: "./devicereturn.component.html",
  styleUrls: ["./devicereturn.component.css"]
})
export class DevicereturnComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "",
      columnFieldName: "deviceReturnId",
      columnClasses: "c-col-pc-0"
    },
    {
      columnHeader: "Return Date",
      columnFieldName: "returnDate",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Model Name",
      columnFieldName: "deviceStock.product.modelName",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Serial No.",
      columnFieldName: "deviceStock.deviceSerialNumber",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Merchant Name",
      columnFieldName: "merchant.companyName",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Reason",
      columnFieldName: "reasonOfReturn",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Remarks",
      columnFieldName: "remarks",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Cond",
      columnFieldName: "deviceCondition",
      columnClasses: "c-col-pc-5",
      callbackFunction: this.getDeviceConditionText
    }
  ];
  sortFields = [
    { name: "deviceReturnId", display: "-" },
    { name: "deviceStock.deviceSerialNumber", display: "Serial Number" },
    { name: "deviceStock.product.modelName", display: "Model Name" },
    { name: "merchant.merchantName", display: "Merchant Name" },
    { name: "reasonOfReturn", display: "Reason" },
    { name: "returnDate", display: "Return Date" },
    { name: "Remarks", display: "Remarks" },
    { name: "deviceCondition", display: "Condition" }
  ];

  topbartext: TopbarText[] = [
    { key: "devicereturn", displayText: "Device Return List" }
  ];
  searchField: SearchField;
  devicereturnlist: Array<DeviceReturnModel>;
  page: GridPage;
  _menuId: string;
  _action: string = "";
  devicestock: DeviceStockModel;
  devicereturn: DeviceReturnModel;
  _devicereturn: any;
  isDeviceReturnDetailScreen: boolean = false;
  _isChange: boolean = false;
  sort: SortClass;
  constructor(
    public tools: Tools,
    private ngbModalService: NgbModal,
    private objGlobal: global,
    private auth: AuthService,
    private objdevicestockservice: DeviceStockService,
    public objdevicereturnservice: DeviceReturnService,
    private csvservice: ExporttocsvService
  ) {
    this.devicereturn = new DeviceReturnModel();
    this._devicereturn = new DeviceReturnModel();
    this.searchField = new SearchField();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/devicereturn");
    this.objGlobal.setActiveMenu(this._menuId);

    this.objdevicereturnservice.loadDeviceReturnReason();
    this.sort = new SortClass();
    this.sort.sortDirection = "A";
    this.sort.sortFieldName = "deviceReturnId";
    //this.getDeviceReturnList();
    this.devicestock = new DeviceStockModel();
  }

  ngOnInit() {}

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  getdevicereturns(event: any) {
    this.getDeviceReturnList();
  }
  onAddNew() {
    this._action = "new";
    this.isDeviceReturnDetailScreen = true;
    this.topbartext = [
      { key: "devicereturn", displayText: "Add Device Return" }
    ];
    this.devicereturn = new DeviceReturnModel();
    this.devicestock = new DeviceStockModel();
    this.devicereturn.returnDate = new Date();
    //this._devicestock=Object.assign({},this.devicestock);
    this._devicereturn = this.tools.copyFullObject(this.devicereturn);
  }
  getAction() {
    return this._action;
  }
  validateDeviceReturn() {
    let result: boolean = true;
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    objmsg.title = "Warning";
    objmsg.btnClass = "btn-warning";
    if (
      this.devicereturn.returnDate &&
      new Date(this.tools.formatDateToDDMMMYY(this.devicereturn.returnDate)) >
        new Date()
    ) {
      objmsg.message = "Return date should not be greater than current date!";
      this.tools.simpleMessage(objmsg);
      result = false;
    }
    return result;
  }
  saveDeviceReturn() {
    if (this.validateDeviceReturn()) {
      this.objdevicereturnservice.saveDeviceReturn(this.devicereturn).subscribe(
        data => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          if (data["success"]) {
            const modalRef = this.ngbModalService.open(
              NgInformationModalContent,
              ngmodaloptions
            );
            modalRef.componentInstance.title = "Information";
            modalRef.componentInstance.message =
              "Device Return saved successfully!";
            modalRef.componentInstance.btnCLass = "btn-info";
            modalRef.result.then(
              (result: any) => {
                this._isChange = true;
                //this._devicestock=Object.assign({},this.devicestock);
                this._devicereturn = this.tools.copyFullObject(
                  this.devicereturn
                );
                //this.convertDateField(this._devicestock);
              },
              reason => {}
            );
          } else {
            const modalRef = this.ngbModalService.open(
              NgInformationModalContent,
              ngmodaloptions
            );
            modalRef.componentInstance.title = "Error";
            modalRef.componentInstance.message =
              "Failed to save Device return!";
            modalRef.componentInstance.btnCLass = "btn-warning";
            modalRef.result.then(
              result => {
                this._devicereturn = this.tools.copyFullObject(
                  this.devicereturn
                );
              },
              reason => {}
            );
            alert("Device return not saved");
          }
        },
        error => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to save device return!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }
  isModified() {
    if (!(this.devicereturn && this._devicereturn)) {
      return false;
    }
    if (
      this.devicereturn.deviceCondition != this._devicereturn.deviceCondition
    ) {
      return true;
    } else if (
      this.devicereturn.deviceStock.deviceStockId !=
      this._devicereturn.deviceStock.deviceStockId
    ) {
      return true;
    } else if (
      this.devicereturn.returnDate &&
      this._devicereturn.returnDate &&
      this.devicereturn.returnDate.toString() !=
        this._devicereturn.returnDate.toString()
    ) {
      return true;
    } else if (
      this.devicereturn.merchant.merchantId !=
      this._devicereturn.merchant.merchantId
    ) {
      return true;
    } else if (
      this.devicereturn.reasonOfReturn != this._devicereturn.reasonOfReturn
    ) {
      return true;
    } else if (this.devicereturn.remarks != this._devicereturn.remarks) {
      return true;
    }
    return false;
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (
        this.devicereturn &&
        this.devicereturn.merchant &&
        this.devicereturn.merchant.companyName &&
        this.devicereturn.merchant.companyName != ""
      ) {
        if (this.isModified()) {
          if (this.isAllowAddorEdit()) {
            isDisableSave = false;
          }
        }
      }
    }
    return isDisableSave;
  }
  disableCancel(f: any) {
    return !this.isModified();
  }
  getDeviceReturnList() {
    this.objdevicereturnservice
      .getDeviceReturnList(this.page, this.searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.devicereturnlist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get device return list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        error => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get device return list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  selectDeviceReturn() {}
  getDeviceConditionText(codeValue: String) {
    let value: String = "";
    switch (codeValue) {
      case "O": {
        value = "Ok";
        break;
      }
      case "F": {
        value = "Faulty";
        break;
      }
    }
    return value;
  }

  onDeviceReturnRowDblClick(devicereturnlistval: any) {
    this._action = "update";
    this.isDeviceReturnDetailScreen = true;
    this.topbartext = [{ key: "devicereturn", displayText: "Device Return" }];
    this.getDeviceReturnDetail(devicereturnlistval.deviceReturnId);
  }

  getDeviceReturnDetail(_id: number) {
    if (_id) {
      this.objdevicereturnservice.getDeviceReturnDetail(_id).subscribe(
        data => {
          if (data["success"]) {
            this.devicereturn = data["result"];
            this.convertDateField(this.devicereturn);

            if (this.devicereturn) {
              this.devicestock = this.devicereturn.deviceStock;
            } else {
              this.devicestock = undefined;
            }
            this._devicereturn = this.tools.copyFullObject(this.devicereturn);
          } else {
            alert("Failed to get device return list!");
          }
        },
        error => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get device return!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }
  deleteDeviceReturn() {}
  onCancel() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
          }
        },
        reason => {}
      );
    } else {
    }
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Device return has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDeviceReturnDetailScreen = false;
            this.topbartext = [
              { key: "devicereturn", displayText: "Device Return List" }
            ];
            if (this._isChange == true) {
              this._isChange = false;
              this.getDeviceReturnList();
            }
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDeviceReturnDetailScreen = false;
      this.topbartext = [
        { key: "devicereturn", displayText: "Device Return List" }
      ];
      if (this._isChange == true) {
        this._isChange = false;
        this.getDeviceReturnList();
      }
    }
  }
  convertDateField(devReturn: DeviceReturnModel) {
    if (devReturn.returnDate) {
      devReturn.returnDate = new Date(devReturn.returnDate);
    }
  }

  onBlurDeviceSrNo(deviceSrNo: string) {
    this.objdevicestockservice.getDeviceStockOnSr(deviceSrNo).subscribe(
      data => {
        if (data["success"]) {
          this.devicestock = data["result"];
          this.devicereturn.deviceStock = this.devicestock;
          //if(this._action=="new") {
          if (this.devicestock.merchant) {
            this.devicereturn.merchant = this.devicestock.merchant;
          } else {
            this.devicereturn.merchant = new MerchantModel();
          }
        } else {
          this.devicestock = new DeviceStockModel();
        }
        //}
      },
      err => {
        this.devicestock = new DeviceStockModel();
      }
    );
  }

  getDeviceReturnListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.objdevicereturnservice
      .getDeviceReturnList(page, this.searchField, sort)
      .subscribe(
        data => {
          let obj: Array<DeviceReturnModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "devicereturn"
            );
          } else {
            alert("Failed to get device return list!");
          }
        },
        error => {
          alert("Failed to get device return list!");
        }
      );
  }
  onExcelClick() {
    this.getDeviceReturnListForExcel();
  }
}
