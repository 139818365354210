import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { global } from 'src/app/framework/modules/global';
import { Tools } from 'src/app/framework/modules/tools';
import { SimpleMessage } from 'src/app/framework/modules/util.class';
import { AuthService } from 'src/app/framework/services/auth.service';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { gridColumns, GridPage, GridtableComponent } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { MerchantService } from 'src/app/services/merchant.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-boarding-dashboard',
  templateUrl: './boarding-dashboard.component.html',
  styleUrls: ['./boarding-dashboard.component.css']
})
export class BoardingDashboardComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  topbartext: TopbarText[] = [
    {
      key: "report-boarding-dashboard",
      displayText: "BOARDING DASHBOARD",
      class: "col-md-6",
      anchorClass: "h6",
    },
  ];
  searchField = {
    fromDate: undefined,
    tillDate: undefined
  };
  gridcols: gridColumns[] = [];
  datalist: [];
  page: GridPage;
  _menuId: string;
  month: "";
  constructor(public tools: Tools,
    private merchantservice: MerchantService,
    private router: Router,
    private objGlobal: global,
    private reportService: ReportService,
    private auth: AuthService,
    private csvservice: ExporttocsvService) {
    let dateFrom: Date = new Date();
    dateFrom.setDate(1);
    this.searchField.fromDate = dateFrom;
    this.searchField.tillDate = new Date();
    this.populateGridCols();
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    console.log(this.router.url.substring(1, this.router.url.length))
    this._menuId = this.tools.getMenuName(
        this.router.url.substring(1, this.router.url.length)
    );
    this.objGlobal.setActiveMenu(this._menuId);
   }

  ngOnInit() {
  }

  populateGridCols() {
    this.gridcols.push({
      columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4"
    });
    this.gridcols.push({
      columnHeader: "Month",
      columnFieldName: "month",
      _parentscope: this,
      callbackFunctionRowPrm: this.monthName
    });
    this.gridcols.push({
      columnHeader: "NO OF APPLICATION",
      columnFieldName: "statusWiseCount",
      columnClasses : "text-right pr-2",
      _parentscope: this,
      callbackFunction : this.getApplicationCount
    });
    this.merchantservice.merchantStatusValues.forEach(itm => {
      if (itm.value) {
        this.gridcols.push({
          columnHeader: itm.text,
          columnFieldName : "statusWiseCount",
          extra: itm.value,
          _parentscope: this,
          callbackFunction : this.getApplicationCountByStatus
        })
      }
    })
  }
  getApplicationCount(objval) {
    let count = 0;
    if (objval) {
      objval.forEach(element => {
        count += element.count; 
      });
    }
    return count;
  }
  getApplicationCountByStatus(objval, _parentscope, col: gridColumns) {
    let count = 0;
    
    if (objval) {
      const statusFilterList = objval.filter(element => {
        let status = element.merchantStatus;
        if (status == "S" && element.marsStatus=="B") {
          status = status + "#" + element.marsStatus;
        }
        if (status === col.extra) {
          return true;
        } else {
          return false;
        }
      });
      if (statusFilterList) {
        statusFilterList.forEach(element => {
          count += element.count;      
        });
      }
    }
    return count;
  }
  monthName(row) {
    
    let monthName: string = undefined;
    if (row && row.month) {
      switch (row.month) {
        case 1:
          monthName = "JAN";
          break;
        case 2:
          monthName = "FEB";
          break;
        case 3:
          monthName = "MAR";
          break;
        case 4:
          monthName = "APR";
          break;
        case 5:
          monthName = "MAY";
          break;
        case 6:
          monthName = "JUN";
          break;
        case 7:
          monthName = "JUL";
          break;
        case 8:
          monthName = "AUG";
          break;
        case 9:
          monthName = "SEP";
          break;
        case 10:
          monthName = "OCT";
          break;
        case 11:
          monthName = "NOV";
          break;
        case 12:
          monthName = "DEC";
          break;
      }
      monthName = monthName + "-" + row.year;
    }
    return monthName;
  }

  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }

  getReportData() {
 
    this.reportService
      .getBoardingDashboard(this.page, this.searchField)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
           
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get boarding dashboard data!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get boarding dashboard data!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  exportBoardingDashboardForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;

    this.reportService
      .getBoardingDashboard(page, this.searchField)
      .subscribe(
        (data) => {
          let obj: Array<any>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "boarding-dashboard"
            );
          } else {
            alert("Failed to boarding dashboard data!");
          }
        },
        (error) => {
          alert("Failed to boarding dashboard data!");
        }
      );
  }
  onExcelClick() {
    this.exportBoardingDashboardForExcel();
  }

}
