import { Component, OnInit, ViewChild } from "@angular/core";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import {
  GridPage,
  gridColumns,
  GridtableComponent,
} from "../../framework/util/components/gridtable/gridtable.component";
import { AuthService } from "../../framework/services/auth.service";
import { global } from "../../framework/modules/global";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Tools } from "../../framework/modules/tools";
import {
  NgInformationModalContent,
  NgYesNoModalContent,
} from "../../framework/util/components/modalcomponent/modal-content";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { SIMReturnModel, SIMStockModel } from "src/app/models/simstock.model";
import {
  SIMStockService,
  SIMReturnService,
} from "src/app/services/sim.service";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
class SearchField {
  searchText: string;
  entryDate: Date[];
  simCondition: String;
  constructor() {
    this.searchText = "";
    this.entryDate = undefined;
    this.simCondition = undefined;
  }
}
@Component({
  selector: "app-simreturn",
  templateUrl: "./simreturn.component.html",
  styleUrls: ["./simreturn.component.css"],
})
export class SimreturnComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "",
      columnFieldName: "simReturnId",
      columnClasses: "c-col-pc-0",
    },
    {
      columnHeader: "SIM Number",
      columnFieldName: "simStock.simNumber",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "SIM Operator",
      columnFieldName: "simStock.simOperator",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "Entry Date",
      columnFieldName: "entryDate",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "Reason",
      columnFieldName: "reasonOfReturn",
      columnClasses: "c-col-pc-22",
    },
    {
      columnHeader: "Remarks",
      columnFieldName: "remarks",
      columnClasses: "c-col-pc-25",
    },
    {
      columnHeader: "Cond",
      columnFieldName: "simCondition",
      columnClasses: "c-col-pc-5",
      callbackFunction: this.getSIMConditionText,
    },
  ];
  sortFields = [
    { name: "simReturnId", display: "-" },
    { name: "simStock.simNumber", display: "SIM Number" },
    { name: "reasonOfReturn", display: "Reason" },
    { name: "entryDate", display: "Entry Date" },
    { name: "Remarks", display: "Remarks" },
    { name: "simCondition", display: "Condition" },
  ];

  topbartext: TopbarText[] = [
    { key: "simreturn", displayText: "SIM Return List" },
  ];
  searchField: SearchField;
  simreturnlist: Array<SIMReturnModel>;
  page: GridPage;
  _menuId: string;
  _action: string = "";
  simstock: SIMStockModel;
  simreturn: SIMReturnModel;
  _simreturn: any;
  isSIMReturnDetailScreen: boolean = false;
  _isChange: boolean = false;
  sort: SortClass;
  constructor(
    public tools: Tools,
    private ngbModalService: NgbModal,
    private objGlobal: global,
    private auth: AuthService,
    private objsimstockservice: SIMStockService,
    public objsimreturnservice: SIMReturnService,
    private csvservice: ExporttocsvService
  ) {
    this.simreturn = new SIMReturnModel();
    this._simreturn = new SIMReturnModel();
    this.searchField = new SearchField();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/simreturn");
    this.objGlobal.setActiveMenu(this._menuId);

    this.objsimreturnservice.loadSIMReturnReason();
    this.sort = new SortClass();
    this.sort.sortDirection = "A";
    this.sort.sortFieldName = "simReturnId";
    //this.getSIMReturnList();
    this.simstock = new SIMStockModel();
  }

  ngOnInit() {}

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  getsimreturns(event: any) {
    this.getSIMReturnList();
  }
  onAddNew() {
    this._action = "new";
    this.isSIMReturnDetailScreen = true;
    this.topbartext = [{ key: "simreturn", displayText: "Add SIM Return" }];
    this.simreturn = new SIMReturnModel();
    this.simreturn.entryDate = new Date();

    this._simreturn = this.tools.copyFullObject(this.simreturn);
  }
  getAction() {
    return this._action;
  }
  saveSIMReturn() {
    this.objsimreturnservice.saveSIMReturn(this.simreturn).subscribe(
      (data) => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        // console.log("data success " + data["success"]) ;
        if (data["success"]) {
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Information";
          modalRef.componentInstance.message = "SIM Return saved successfully!";
          modalRef.componentInstance.btnCLass = "btn-info";
          modalRef.result.then(
            (result: any) => {
              this._isChange = true;
              this._simreturn = this.tools.copyFullObject(this.simreturn);
            },
            (reason) => {}
          );
        } else if (data["message"] == "SIMNumberNotFound") {
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "SIM Number not available in stock!";
          modalRef.componentInstance.btnCLass = "btn-warning";
          modalRef.result.then(
            (result) => {
              this._simreturn = this.tools.copyFullObject(this.simreturn);
            },
            (reason) => {}
          );
        } else {
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message = "Failed to save SIM return!";
          modalRef.componentInstance.btnCLass = "btn-warning";
          modalRef.result.then(
            (result) => {
              this._simreturn = this.tools.copyFullObject(this.simreturn);
            },
            (reason) => {}
          );
        }
      },
      (error) => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to save SIM return!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }
  isModified() {
    if (!(this.simreturn && this._simreturn)) {
      return false;
    }
    if (this.simreturn.simCondition != this._simreturn.simCondition) {
      return true;
    } else if (
      this.simreturn.simStock.simNumber != this._simreturn.simStock.simNumber
    ) {
      return true;
    } else if (
      this.simreturn.entryDate &&
      this._simreturn.entryDate &&
      this.simreturn.entryDate.toString() !=
        this._simreturn.entryDate.toString()
    ) {
      return true;
    } else if (
      this.simreturn.reasonOfReturn != this._simreturn.reasonOfReturn
    ) {
      return true;
    } else if (this.simreturn.remarks != this._simreturn.remarks) {
      return true;
    }
    return false;
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }
  disableCancel(f: any) {
    return !this.isModified();
  }
  getSIMReturnList() {
    this.objsimreturnservice
      .getSIMReturnList(this.page, this.searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.simreturnlist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get SIM return list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (error) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get SIM return list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  selectSIMReturn() {}
  deleteSIMReturn() {}
  getSIMConditionText(codeValue: String) {
    let value: String = "";
    switch (codeValue) {
      case "O": {
        value = "Ok";
        break;
      }
      case "F": {
        value = "Faulty";
        break;
      }
    }
    return value;
  }

  onSIMReturnRowDblClick(simreturnlistval: any) {
    this._action = "update";
    this.isSIMReturnDetailScreen = true;
    this.topbartext = [{ key: "simreturn", displayText: "SIM Return" }];
    this.getSIMReturnDetail(simreturnlistval.simReturnId);
  }

  getSIMReturnDetail(_id: number) {
    if (_id) {
      this.objsimreturnservice.getSIMReturnDetail(_id).subscribe(
        (data) => {
          if (data["success"]) {
            this.simreturn = data["result"];
            this.convertDateField(this.simreturn);

            if (this.simreturn) {
              this.simstock = this.simreturn.simStock;
            } else {
              this.simstock = undefined;
            }
            this._simreturn = this.tools.copyFullObject(this.simreturn);
          } else {
            alert("Failed to get SIM return list!");
          }
        },
        (error) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get SIM return!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }
  onCancel() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this._action = "";
          }
        },
        (reason) => {}
      );
    } else {
    }
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "SIM return has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this._action = "";
            this.isSIMReturnDetailScreen = false;
            this.topbartext = [
              { key: "simreturn", displayText: "SIM Return List" },
            ];
            if (this._isChange == true) {
              this._isChange = false;
              this.getSIMReturnList();
            }
          }
        },
        (reason) => {}
      );
    } else {
      this._action = "";
      this.isSIMReturnDetailScreen = false;
      this.topbartext = [{ key: "simreturn", displayText: "SIM Return List" }];
      if (this._isChange == true) {
        this._isChange = false;
        this.getSIMReturnList();
      }
    }
  }
  convertDateField(simReturn: SIMReturnModel) {
    if (simReturn.entryDate) {
      simReturn.entryDate = new Date(simReturn.entryDate);
    }
  }

  getSIMReturnListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.objsimreturnservice
      .getSIMReturnList(page, this.searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<SIMReturnModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "simreturn"
            );
          } else {
            alert("Failed to get SIM return list!");
          }
        },
        (error) => {
          alert("Failed to get SIM return list!");
        }
      );
  }
  onExcelClick() {
    this.getSIMReturnListForExcel();
  }
}
