import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../framework/config/AppSettings';
import { SortClass } from '../framework/modules/util.class';
import { GridPage } from '../framework/util/components/gridtable/gridtable.component';
import { BankSourcingBranchModel } from '../models/BankSourcingBranch.model';

@Injectable({
  providedIn: 'root'
})
export class SourcingBranchService {

  constructor(private http: HttpClient, private appsetting: AppSettings) { }

  getSourcingBranchList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (sortField) {
      if (sortField.sortFieldName && sortField.sortDirection != "") {
        httpParam = httpParam.append("sortDirection", sortField.sortDirection);
      }
      if (sortField.sortFieldName && sortField.sortFieldName != "") {
        httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
      }
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/banksourcingbranches", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getSourcingBranch(id: number) {
    let header = new HttpHeaders();
    //let httpParam: HttpParams = new HttpParams();
    //httpParam = httpParam.append("pincode", pincode);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/sourcingbranch/" + id, {
        headers: header,
        observe: "body",
        //params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  saveSourcingBranch(sourcingBranch: BankSourcingBranchModel) {
    let header = new HttpHeaders();
    return this.http
        .post(
          this.appsetting.get("server-url") + "/api/sourcingbranch",
          sourcingBranch,
          { headers: header }
        )
        .map(res => res);
    
  }
}
