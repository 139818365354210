import { Component, OnInit } from "@angular/core";
import { global } from "src/app/framework/modules/global";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SimpleMessage } from "../../framework/modules/util.class";
import { Tools } from "src/app/framework/modules/tools";
import { NgYesNoModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";
import { AuthService } from "src/app/framework/services/auth.service";
import { Router } from "@angular/router";

export class ChangePassword {
  oldpassword: string;
  password: string;
  password2: string;
}

@Component({
  selector: "app-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.css"]
})
export class ChangepasswordComponent implements OnInit {
  showOtpPopup: boolean = false;
  otp: string = '';
  data: ChangePassword;
  constructor(
    private objGlobal: global,
    private tools: Tools,
    private ngbModalService: NgbModal,
    private auth: AuthService,
    private router: Router
  ) {
    this.objGlobal.setActiveMenu("");
    this.data = new ChangePassword();
  }

  ngOnInit() {}
  disableCancel(f) {
    return !f.valid;
  }

  onPasswordChangeClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    let objmsg: SimpleMessage = new SimpleMessage();

    let validationMessage: string = "";
    let validationFailed: boolean = false;
    if (this.data.oldpassword == undefined && this.data.oldpassword == "") {
      validationMessage = "Please enter old password!";
      validationFailed = true;
    } else if (this.data.password == undefined && this.data.password == "") {
      validationMessage = "Please enter new password!";
      validationFailed = true;
    } else if (this.data.password != this.data.password2) {
      validationMessage = "Confirm password does not match!";
      validationFailed = true;
    }
    if (validationFailed) {
      objmsg.title = "Warning";
      objmsg.message = validationMessage;
      objmsg.btnClass = "btn-warning";
      this.tools.simpleMessage(objmsg);
    } else {
      ///////////////////
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to change password?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
           // this.showOtpPopup=true;
            this.changePassword();
          }
        },
        reason => {}
      );
      //////////////////
    }
  }
 
  changePassword() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    let objmsg: SimpleMessage = new SimpleMessage();
    this.auth.changePassword(this.data).subscribe(
      data => {
        if (data["success"]) {
          this.showOtpPopup=true;
          this.otp="";
          // objmsg.title = "Information";
          // objmsg.message = "Password successfully changed!";
          // objmsg.btnClass = "btn-info";
          // objmsg._scope = this;
          // objmsg.fnResult = this.toLogin;
          // this.tools.simpleMessage(objmsg);
        } else {
          switch (data["message"]) {
            case "Error":
              objmsg.title = "Error";
              objmsg.message = "Error!";
              objmsg.btnClass = "btn-error";
              this.tools.simpleMessage(objmsg);
              break;
            case "INVALIDUSER":
              objmsg.title = "Warning";
              objmsg.message = "Invalid user!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
            case "AUTHFAILED":
              objmsg.title = "Warning";
              objmsg.message = "Authentication failed!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
              case "Phone Nummber not registered for user":
                objmsg.title = "Warning";
                objmsg.message = "Phone Number not registered for user!";
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
          }
        }
      },
      error => {
        console.error("Error =" + error);
        objmsg.title = "Error";
        objmsg.message = "Error!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }
  verifyOtp() {
    console.log(this.otp)
    let objmsg: SimpleMessage = new SimpleMessage();
    this.auth.verifyOTP(this.otp).subscribe(
      data => {
        if (data["success"]) {
          this.showOtpPopup=false;
          objmsg.title = "Information";
          objmsg.message = "Password successfully changed!";
          objmsg.btnClass = "btn-info";
          objmsg._scope = this;
          objmsg.fnResult = this.toLogin;
          this.tools.simpleMessage(objmsg);
        } else {
          switch (data["message"]) {
            case "Error":
              objmsg.title = "Error";
              objmsg.message = "Error!";
              objmsg.btnClass = "btn-error";
              this.tools.simpleMessage(objmsg);
              break;
            case "INVALIDUSER":
              objmsg.title = "Warning";
              objmsg.message = "Invalid user!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
            case "AUTHFAILED":
              objmsg.title = "Warning";
              objmsg.message = "Authentication failed!";
              objmsg.btnClass = "btn-warning";
              this.tools.simpleMessage(objmsg);
              break;
          }
        }
      },
      error => {
        console.error("Error =" + error);
        objmsg.title = "Error";
        objmsg.message = "Error!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );

    // if (this.otp === '1234') { // Replace '1234' with your OTP verification logic
    //   this.showOtpPopup = false;
    //   alert('OTP verified. Proceeding with password change.');
    //   // Add your password change logic here
    // } else {
    //   alert('Invalid OTP');
    // }
  }

  closePopup() {
    this.showOtpPopup = false;
  }

  toLogin(_scope) {
    _scope.router.navigate(["login"]);
  }
}
