export class TransCategoryModel {
  id?: number;
  category?: string;
  categoryText?: string;
  subcategory?: string;
  subcategoryText?: string;
  marsSlabCode?: string;
  fromAmount?: number;
  toAmount?: number;
  hasChild?: string;
  parentId?: number;
  invisible?: boolean;
}
