import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  IndentModel,
  IndentPaymentModel,
  IndentActionModel
} from "src/app/models/indent.model";
import { MerchantModel } from "src/app/models/merchant.model";
import { EmployeeModel } from "src/app/models/employee.model";
import { AggregatorDistributorModel } from "src/app/models/aggregator.distributor.model";
import { DeviceStockModel } from "src/app/models/device.model";
import { Tools } from "src/app/framework/modules/tools";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { global } from "src/app/framework/modules/global";
import { AuthService } from "src/app/framework/services/auth.service";
import { IndentService } from "src/app/services/indent.service";
import { ProductService } from "src/app/services/product.service";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
import { EmployeeService } from "src/app/services/employee.service";
import { MerchantService } from "src/app/services/merchant.service";
import { DeviceStockService } from "src/app/services/device.service";
import { SimpleMessage } from "../../framework/modules/util.class";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "src/app/framework/util/components/modalcomponent/modal-content";

@Component({
  selector: "app-indentdetail",
  templateUrl: "./indentdetail.component.html",
  styleUrls: ["./indentdetail.component.css"]
})
export class IndentdetailComponent implements OnInit {
  @Input()
  addPriv: boolean = false;
  @Input()
  editPriv: boolean = false;
  @Input()
  buheadPriv: boolean = false;
  @Input()
  financePriv: boolean = false;
  @Input()
  generationPriv: boolean = false;
  @Input()
  action: string = "";

  @Output()
  onSetTopBarText: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  fromMerchantScreen: boolean = false;
  indent: IndentModel;
  _indent: any;
  indentpayment: IndentPaymentModel;
  indentpayments: Array<IndentPaymentModel>;
  _indentpayments: Array<IndentPaymentModel>;
  _isChange: boolean = false;
  _requiredSalePerson: boolean = false;
  _requiredEmployee: boolean = false;
  _requiredPurchaser: boolean = false;
  _requiredMerchant: boolean = false;

  _disableSalesPerson: boolean = false;
  _disableEmployee: boolean = false;
  _disablePurchaser: boolean = false;
  _disableMerchant: boolean = false;

  _showSaveCancel: boolean = false;
  _showBUActionButtons: boolean = false;
  _showBUAction: boolean = false;
  _showBUComment: boolean = false;
  _showFinanceActionButtons: boolean = false;
  _showFinanceAction: boolean = false;
  _showFinanceComment: boolean = false;
  _showdevicemapping: boolean = false;

  purchaserdropdownSettings = {
    singleSelection: true,
    idField: "id",
    textField: "name",
    allowSearchFilter: true,
    maxHeight: 200,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: ""
  };
  salesPersonDropdownSettings = {
    singleSelection: true,
    idField: "empCode",
    textField: "empName",
    allowSearchFilter: true,
    maxHeight: 200,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: ""
  };
  employeeDropdownSettings = {
    singleSelection: true,
    idField: "empCode",
    textField: "empName",
    allowSearchFilter: true,
    maxHeight: 200,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: ""
  };
  merchantDropdownSettings = {
    singleSelection: true,
    idField: "merchantId",
    textField: "companyName",
    allowSearchFilter: true,
    maxHeight: 200,
    showCheckbox: false,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: ""
  };
  deviceSrDropdownSettings = {
    singleSelection: false,
    idField: "deviceStockId",
    textField: "deviceSerialNumber",
    allowSearchFilter: true,
    maxHeight: 100,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: "",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 2,
    showCheckbox: true,
    enableCheckAll: true,
    enableFilterSelectAll: true,
    filterSelectAllText: "All privileges selected"
  };
  devicesrnorange = {
    from: "",
    to: ""
  };
  _alloweditdetail = true;
  _alloweditpayment = true;

  merchants: Array<MerchantModel>;
  salespersons: Array<EmployeeModel>;
  employees: Array<EmployeeModel>;
  purchasers: Array<AggregatorDistributorModel>;

  selectedSalesPerson = [];
  _selectedSalesPerson = [];
  selectedEmployee = [];
  _selectedEmployee = [];
  selectedAggrDistr = [];
  _selectedAggrDistr = [];
  selectedMerchant = [];
  _selectedMerchant = [];

  indentActionCreate: IndentActionModel;
  indentActionBU: IndentActionModel;
  indentActionFinance: IndentActionModel;
  _indentActionCreate: IndentActionModel;

  _indentbuactionclass: string;
  _indentbuactiontext: string;
  _indentfinanceactionclass: string;
  _indentfinanceactiontext: string;

  selectedDeviceSrNumber: Array<DeviceStockModel>;
  devices: Array<DeviceStockModel>;

  indentForm: boolean = false;
  constructor(
    public tools: Tools,
    private ngbModalService: NgbModal,
    private objGlobal: global,
    private auth: AuthService,
    private objindentservice: IndentService,
    public productservice: ProductService,
    private aggregatordistributedservice: AggregatorDistributorService,
    private employeeservice: EmployeeService,
    private merchantservice: MerchantService,
    private devicestockservice: DeviceStockService
  ) {
    this.indent = new IndentModel();
    this.clearDetailData();
    this.indentpayment = new IndentPaymentModel();
    productservice.loadProductNameWithId();
    merchantservice.loadMerchantNameWithId();
    aggregatordistributedservice.loadAggregatorDistrbutorIdNameType();
    employeeservice.loadEmployeeNameWithCode();
    productservice.loadProductNameWithId();
  }

  ngOnInit() {}
  clearDetailData() {
    this.indent = new IndentModel();
    this._indent = new IndentModel();
    this.indentpayments = new Array<IndentPaymentModel>();
    this.copyPayment();
    this.indentActionCreate = new IndentActionModel();
    this._indentActionCreate = new IndentActionModel();
    this.indentActionBU = new IndentActionModel();
    this.indentActionFinance = new IndentActionModel();
    this.indentActionCreate = new IndentActionModel();
    this._indentActionCreate = new IndentActionModel();
    this.indentActionBU = new IndentActionModel();
    this.indentActionFinance = new IndentActionModel();
  }
  onAddNew() {
    this.action = "new";
    this._isChange = false;
    //this.isDetailScreen=true;
    this.indent = new IndentModel();
    this.indent.indentDate = new Date();
    this.indentActionCreate = new IndentActionModel();
    this._indentActionCreate = new IndentActionModel();
    this.indentActionBU = new IndentActionModel();
    this.indentActionFinance = new IndentActionModel();
    this.indentpayments = new Array<IndentPaymentModel>();
    this.copyPayment();
    this.indentpayment = new IndentPaymentModel();
    this.selectedMerchant = undefined;
    this.selectedAggrDistr = undefined;
    this.selectedSalesPerson = undefined;
    this.selectedEmployee = undefined;
    this.indent.quantity = 1;
    this.indent.rate = 0;
    this.totalAmount();
    //this.indent.returnDate=new Date();
    //this._devicestock=Object.assign({},this.devicestock);
    this._indent = this.tools.copyFullObject(this.indent);
    this._showSaveCancel = true;
    this._showBUActionButtons = false;
    this._showBUAction = false;
    this._showBUComment = false;
    this._showFinanceActionButtons = false;
    this._showFinanceAction = false;
    this._showFinanceComment = false;
    this._showdevicemapping = false;
    this._alloweditpayment =
      this._showSaveCancel || this._showFinanceActionButtons;
    this.selectedDeviceSrNumber = undefined;
    this.setTopbarTextParent();
  }
  getIndentDetail(_id: number) {
    if (_id) {
      this.selectedDeviceSrNumber = undefined;
      this.objindentservice.getIndentDetail(_id).subscribe(
        data => {
          if (data["success"]) {
            this.indent = data["result"];
            if (this.indent.indentDate) {
              this.indent.indentDate = new Date(this.indent.indentDate);
            }
            this.action = "update";
            this._isChange = false;
            this.loadIndentOtherDataFromModel();
            this._indent = this.tools.copyFullObject(this.indent);
            this.onTypeOfSaleSelect();
            this.populateDevices();
            this.devicesrnorange.from = "";
            this.devicesrnorange.to = "";
          } else {
            alert("Failed to get indent list!");
          }
        },
        error => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message =
            "Failed to get Indent detail, Error - " + error + "!";
          objmsg.btnClass = "btn-error";
        }
      );
    }
  }
  disableCancel(f) {
    return !this.isModified();
  }
  onCancel(obj: any) {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.getIndentDetail(this.indent.indentId);
          }
        },
        reason => {}
      );
    } else {
    }
  }
  disableSave(f) {
    //return false;
    if (this.indent.quantity < 1) {
      return true;
    }
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    //console.log("isdisableSave " + isDisableSave);
    return isDisableSave;
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this.action == "new") {
      isAllowSave = this.addPriv;
    } else if (this.action == "update") {
      isAllowSave = this.editPriv;
    }
    return isAllowSave;
  }
  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Indent has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.action = "";
            this.clearDetailData();
            this.closeScreen();
            //this.isDetailScreen=false;
            //this.setTopbarText();
            /*if(this._isChange==true) {
            this._isChange=false;
            this.getIndentList();
          }*/
            //this.clearDetailData();
          }
        },
        reason => {}
      );
    } else {
      this.action = "";
      /*      this.isDetailScreen=false;
      this.setTopbarText();
      if(this._isChange==true) {
        this._isChange=false;
        this.getIndentList();
      }*/
      this.clearDetailData();
      this.closeScreen();
    }
  }

  isAddPayDisable(fpayment: any) {
    //console.log("is valid");
    return !fpayment.valid && this.allowEditPayment();
  }
  addPayment() {
    if (this.indentpayment.paymentDate > new Date()) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const modalRef = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      modalRef.componentInstance.title = "Information";
      modalRef.componentInstance.message =
        "Payment date should be before or today's date!";
      modalRef.componentInstance.btnCLass = "btn-info";
      modalRef.result.then(
        (result: any) => {},
        reason => {}
      );
    } else {
      let indentpay: IndentPaymentModel = new IndentPaymentModel();
      indentpay.amount = this.indentpayment.amount;
      indentpay.ifscCode = this.indentpayment.ifscCode;
      indentpay.paymentDate = new Date(
        this.tools.formatDateToDDMMMYY(this.indentpayment.paymentDate)
      );
      indentpay.typeOfPayment = this.indentpayment.typeOfPayment;

      if (this.isSamePayDetailFound(indentpay)) {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";

        const ngmodalref = this.ngbModalService.open(
          NgYesNoModalContent,
          ngmodaloptions
        );
        ngmodalref.componentInstance.title = "Confirmation";
        ngmodalref.componentInstance.message =
          "Payment already added with same detail. Add this again?";
        ngmodalref.result.then(
          result => {
            if (result == "yes") {
              this.addPayItemInList(indentpay);
            }
          },
          reason => {}
        );
      } else {
        this.addPayItemInList(indentpay);
      }
    }
  }
  addPayItemInList(indentpay: IndentPaymentModel) {
    this.indentpayments.push(indentpay);
    this.indentpayment.amount = undefined;
    this.indentpayment.ifscCode = undefined;
    this.indentpayment.paymentDate = undefined;
    this.indentpayment.typeOfPayment = undefined;
    this.calculateTotalPayment();
  }
  calculateTotalPayment() {
    let totalReceivedAmt: number = 0;
    for (let i = 0; i < this.indentpayments.length; i++) {
      totalReceivedAmt = totalReceivedAmt + this.indentpayments[i].amount;
    }
    this.indent.totalReceivedAmount = totalReceivedAmt;
  }
  isSamePayDetailFound(indentPay: IndentPaymentModel) {
    if (this.indentpayments.length < 1) {
      return false;
    }
    for (let i = 0; i < this.indentpayments.length; i++) {
      let item = this.indentpayments[i];
      if (item.amount != indentPay.amount) {
        return false;
      }
      if (item.ifscCode != indentPay.ifscCode) {
        return false;
      }
      if (item.typeOfPayment != indentPay.typeOfPayment) {
        return false;
      }
      //console.log(item.paymentDate);
      //console.log(new Date(this.tools.formatDateToDDMMMYY(indentPay.paymentDate)));
      if (
        !(
          item.paymentDate &&
          indentPay.paymentDate &&
          item.paymentDate.getFullYear() ==
            indentPay.paymentDate.getFullYear() &&
          item.paymentDate.getMonth() == indentPay.paymentDate.getMonth() &&
          item.paymentDate.getDate() == indentPay.paymentDate.getDate()
        )
      ) {
        return false;
      }
    }
    return true;
  }

  requiredPaymentIfscCode() {
    if (this.indentpayment.typeOfPayment == "Card") {
      return false;
    } else {
      return true;
    }
  }
  removePayment(i: number) {
    this.indentpayments.splice(i, 1);
    this.calculateTotalPayment();
  }
  getBuHeadActionText() {
    this._indentbuactionclass = "";
    this._indentbuactiontext = "";
    switch (this.indentActionBU.action) {
      case "BA":
        this._indentbuactionclass = "btn-success";
        this._indentbuactiontext = "Approved";
        break;
      case "BR":
        this._indentbuactionclass = "btn-danger";
        this._indentbuactiontext = "Rejected";
        break;
      case "BH":
        this._indentbuactionclass = "btn-primary";
        this._indentbuactiontext = "On hold";
        break;
    }
    if (this._indentbuactiontext == "" && this.indent.skipBHAction == "Y") {
      this._indentbuactiontext = "Not applicable";
      this._indentbuactionclass = "btn-secondary";
    }
  }
  getFinanceActionText() {
    this._indentfinanceactionclass = "";
    this._indentfinanceactiontext = "";
    switch (this.indentActionFinance.action) {
      case "FA":
        this._indentfinanceactionclass = "btn-success";
        this._indentfinanceactiontext = "Approved";
        break;
      case "FR":
        this._indentfinanceactionclass = "btn-danger";
        this._indentfinanceactiontext = "Rejected";
        break;
      case "FH":
        this._indentfinanceactionclass = "btn-primary";
        this._indentfinanceactiontext = "On hold";
        break;
    }
  }

  getFinanceClass() {
    return "btn-danger";
  }
  allowEditDetail() {
    return this._showSaveCancel;
  }
  allowEditTypeOfSale() {
    if (!this.fromMerchantScreen) {
      return this.allowEditDetail();
    } else {
      return false;
    }
  }

  allowEditPayment() {
    return this._alloweditpayment;
  }
  isRequiredPurchaser() {
    return this._requiredPurchaser;
  }
  isRequiredSalesPerson() {
    return this._requiredSalePerson;
  }
  isRequiredEmployee() {
    return this._requiredEmployee;
  }
  isRequiredMerchant() {
    return this._requiredMerchant;
  }

  disablePurchaser() {
    return !this.showSaveCancel() || this._disablePurchaser;
  }
  disableSalesPerson() {
    return !this.showSaveCancel() || this._disableSalesPerson;
  }
  disableEmployee() {
    return !this.showSaveCancel() || this._disableEmployee;
  }
  disableMerchant() {
    if (!this.fromMerchantScreen) {
      return !this.showSaveCancel() || this._disableMerchant;
    } else {
      return true;
    }
  }
  onTypeOfSaleSelect() {
    this.merchants = undefined;
    this.salespersons = undefined;
    this.employees = undefined;
    this.purchasers = undefined;
    this._requiredPurchaser = false;
    this._requiredSalePerson = false;
    this._requiredEmployee = false;
    this._requiredMerchant = false;
    this._disablePurchaser = false;
    this._disableSalesPerson = false;
    this._disableEmployee = false;
    this._disableMerchant = false;
    switch (this.indent.typeOfSale) {
      case "direct":
        this.merchants = this.merchantservice.merchantNameList;
        this.salespersons = this.employeeservice.employeeNameList.filter(
          emp => emp.department == "Sales"
        );
        this.employees = this.employeeservice.employeeNameList.filter(
          emp => emp.department != "Sales"
        );
        this.purchasers = undefined;
        this.selectedAggrDistr = undefined;
        this._disablePurchaser = true;
        this._requiredSalePerson = true;
        this._requiredMerchant = true;
        break;
      case "aggregator":
        this.merchants = undefined;
        this.selectedMerchant = undefined;
        this._disableMerchant = true;
        this.salespersons = this.employeeservice.employeeNameList.filter(
          emp => emp.department == "Sales"
        );
        this.employees = undefined;
        this.selectedEmployee = undefined;
        this._disableEmployee = true;
        this.purchasers = this.aggregatordistributedservice.aggregatorDistributorList.filter(
          obj => obj.type == "A"
        );
        this._requiredPurchaser = true;
        this._disablePurchaser = false;
        break;
      case "distributor":
        this.merchants = undefined;
        this.selectedMerchant = undefined;
        this._disableMerchant = true;
        this.salespersons = this.employeeservice.employeeNameList.filter(
          emp => emp.department == "Sales"
        );
        this.employees = undefined;
        this.selectedEmployee = undefined;
        this._disableEmployee = true;
        this.purchasers = this.aggregatordistributedservice.aggregatorDistributorList.filter(
          obj => obj.type == "D"
        );
        this._requiredPurchaser = true;
        break;
      case "test":
        this.merchants = undefined;
        this.selectedMerchant = undefined;
        this._disableMerchant = true;
        this.salespersons = undefined;
        this.selectedSalesPerson = undefined;
        this._disableSalesPerson = true;
        this.employees = this.employeeservice.employeeNameList.filter(
          emp => emp.department != "Sales"
        );
        this.purchasers = this.aggregatordistributedservice.aggregatorDistributorList;
        this._requiredPurchaser = true;
        break;
      case "demo":
        this.merchants = undefined;
        this.selectedMerchant = undefined;
        this._disableMerchant = true;
        this.salespersons = this.employeeservice.employeeNameList.filter(
          emp => emp.department == "Sales"
        );
        this.employees = this.employeeservice.employeeNameList.filter(
          emp => emp.department != "Sales"
        );
        this.purchasers = undefined;
        this.selectedAggrDistr = undefined;
        this._disablePurchaser = true;
        this._requiredSalePerson = true;
        this._requiredEmployee = true;
        break;
      case "merchantexperience":
        this.merchants = this.merchantservice.merchantNameList;
        this.salespersons = this.employeeservice.employeeNameList.filter(
          emp => emp.department == "Sales"
        );
        this.employees = this.employeeservice.employeeNameList.filter(
          emp => emp.department != "Sales"
        );
        this.purchasers = undefined;
        this.selectedAggrDistr = undefined;
        this._disablePurchaser = true;
        this._requiredSalePerson = true;
        this._requiredEmployee = true;
        this._requiredMerchant = true;
        break;
      case "replacement":
        this.merchants = this.merchantservice.merchantNameList;
        this.salespersons = this.employeeservice.employeeNameList.filter(
          emp => emp.department == "Sales"
        );
        this.employees = this.employeeservice.employeeNameList.filter(
          emp => emp.department != "Sales"
        );
        this.purchasers = undefined;
        this.selectedAggrDistr = undefined;
        this._disablePurchaser = true;
        this._requiredEmployee = true;
        this._requiredMerchant = true;
        break;
    }
  }

  totalAmount() {
    this.indent.totalAmount = this.indent.quantity * this.indent.rate;
  }

  saveIndent($e) {
    this.setComboValueToModel();
    let indentaction: IndentActionModel = new IndentActionModel();
    indentaction.comment = this.indentActionCreate.comment;
    this.indent.indentActions = [indentaction];
    this.indent.payments = this.indentpayments;
    /*if(this.indentpayment && this.indentpayments.length >0) {
      this.indent.payments = this.indentpayments;
    } else {
      this.indent.payments = undefined;
    }*/
    this.copyPayment();
    this.objindentservice.saveIndent(this.indent).subscribe(
      data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        if (data["success"]) {
          objmsg.title = "Information";
          objmsg.message = "Indent saved successfully!";
          objmsg.btnClass = "btn-info";
          this.indent = data["result"];
          this.loadIndentOtherDataFromModel();
          this._isChange = true;
        } else if (data["message"] == "InputDataNotProvided") {
          objmsg.title = "Error";
          objmsg.message = "Indent input data not available!";
          objmsg.btnClass = "btn-warning";
        } else {
          objmsg.title = "Error";
          objmsg.message = "Failed to save Indent!";
          objmsg.btnClass = "btn-error";
        }
        this.tools.simpleMessage(objmsg);
      },
      error => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Error";
        objmsg.message = "Failed to save Indent, Error - " + error + "!";
        objmsg.btnClass = "btn-error";
      }
    );
  }
  loadIndentOtherDataFromModel() {
    if (this.indent.indentDate) {
      this.indent.indentDate = new Date(this.indent.indentDate);
    }
    this.setModelValueToCombo();
    this.setActionFromModel();
    this.indentpayments = this.indent.payments;
    this.copyPayment();
    this._indent = this.tools.copyFullObject(this.indent);
    if (this.showBUActionButtons() && !this.indentActionBU) {
      this.indentActionBU = new IndentActionModel();
    }
    if (this.showFinanceActionButtons && !this.indentActionFinance) {
      this.indentActionFinance = new IndentActionModel();
    }
    this._showSaveCancel = this.showSaveCancel();
    this._showBUActionButtons = this.showBUActionButtons();
    this._showBUAction = this.showBUAction();
    this._showBUComment = this.showBUComment();
    this._showFinanceActionButtons = this.showFinanceActionButtons();
    this._showFinanceAction = this.showFinanceAction();
    this._showFinanceComment = this.showFinanceComment();
    this._showdevicemapping = this.showdevicemapping();
    this.selectedDeviceSrNumber = new Array<DeviceStockModel>();
    let srno = new DeviceStockModel();
    //srno.deviceStockId=282;
    //srno.deviceSerialNumber="1010";
    //this.selectedDeviceSrNumber.push(srno);
    //let srno1=new DeviceStockModel();
    //srno1.deviceStockId=283;
    //srno1.deviceSerialNumber="1011";
    //this.selectedDeviceSrNumber.push(srno1);
    this.selectedDeviceSrNumber = this.indent.devices;
    this.getBuHeadActionText();
    this.getFinanceActionText();
    this._alloweditpayment =
      this._showSaveCancel || this._showFinanceActionButtons;
    this.setTopbarTextParent();
  }
  setComboValueToModel() {
    if (this.selectedSalesPerson && this.selectedSalesPerson[0]) {
      this.indent.salesPerson = {
        empCode: this.selectedSalesPerson[0].empCode
      };
    } else {
      this.indent.salesPerson = undefined;
    }
    if (this.selectedEmployee && this.selectedEmployee[0]) {
      this.indent.employee = { empCode: this.selectedEmployee[0].empCode };
    } else {
      this.indent.employee = undefined;
    }
    if (this.selectedAggrDistr && this.selectedAggrDistr[0]) {
      this.indent.purchaser = { id: this.selectedAggrDistr[0].id };
    } else {
      this.indent.purchaser = undefined;
    }
    if (this.selectedMerchant && this.selectedMerchant[0]) {
      this.indent.merchant = {
        merchantId: this.selectedMerchant[0].merchantId
      };
    } else {
      this.indent.merchant = undefined;
    }
  }

  setModelValueToCombo() {
    if (this.indent.salesPerson.empCode) {
      this.selectedSalesPerson = [
        {
          empCode: this.indent.salesPerson.empCode,
          empName: this.indent.salesPerson.empName
        }
      ];
      this._selectedSalesPerson = [
        {
          empCode: this.indent.salesPerson.empCode,
          empName: this.indent.salesPerson.empName
        }
      ];
    } else {
      this.selectedSalesPerson = undefined;
      this._selectedSalesPerson = undefined;
    }
    if (this.indent.employee.empCode) {
      this.selectedEmployee = [
        {
          empCode: this.indent.employee.empCode,
          empName: this.indent.employee.empName
        }
      ];
      this._selectedEmployee = [
        {
          empCode: this.indent.employee.empCode,
          empName: this.indent.employee.empName
        }
      ];
    } else {
      this.selectedEmployee = undefined;
      this._selectedEmployee = undefined;
    }
    if (this.indent.purchaser.id) {
      this.selectedAggrDistr = [
        { id: this.indent.purchaser.id, name: this.indent.purchaser.name }
      ];
      this._selectedAggrDistr = [
        { id: this.indent.purchaser.id, name: this.indent.purchaser.name }
      ];
    } else {
      this.selectedAggrDistr = undefined;
      this._selectedAggrDistr = undefined;
    }
    if (this.indent.merchant.merchantId) {
      this.selectedMerchant = [
        {
          merchantId: this.indent.merchant.merchantId,
          companyName: this.indent.merchant.companyName
        }
      ];
      this._selectedMerchant = [
        {
          merchantId: this.indent.merchant.merchantId,
          companyName: this.indent.merchant.companyName
        }
      ];
    } else {
      this.selectedMerchant = undefined;
      this._selectedMerchant = undefined;
    }
  }

  setActionFromModel() {
    let indentActCreate = this.indent.indentActions.filter(
      obj => obj.action == "N"
    );
    if (indentActCreate && indentActCreate.length > 0) {
      this.indentActionCreate = indentActCreate[indentActCreate.length - 1]; //this.getIndentActionRecord(indentActCreate);
    } else {
      this.indentActionCreate = new IndentActionModel();
    }
    this._indentActionCreate.comment = this.indentActionCreate.comment;
    let indentActBU = this.indent.indentActions.filter(
      obj => obj.action == "BA" || obj.action == "BR" || obj.action == "BH"
    );
    if (indentActBU && indentActBU.length > 0) {
      this.indentActionBU = indentActBU[indentActBU.length - 1]; //this.getIndentActionRecord(indentActBU);
    } else {
      this.indentActionBU = new IndentActionModel();
    }
    let indentActFinance = this.indent.indentActions.filter(
      obj => obj.action == "FA" || obj.action == "FR" || obj.action == "FH"
    );
    if (indentActFinance && indentActFinance.length > 0) {
      this.indentActionFinance = indentActFinance[indentActFinance.length - 1]; //this.getIndentActionRecord(indentActFinance);
    } else {
      this.indentActionFinance = new IndentActionModel();
    }
  }
  getIndentActionRecord(indentActFilterData: Array<IndentActionModel>) {
    if (indentActFilterData && indentActFilterData.length > 0) {
      let seq = -1;
      let index = 0;
      for (let i: number = 0; i < indentActFilterData.length; i++) {
        if (indentActFilterData[i].seq > seq) {
          seq = indentActFilterData[i].seq;
          index = i;
        }
      }
      return indentActFilterData[index];
    } else {
      return new IndentActionModel();
    }
  }
  isModified() {
    if (this.indent.indentDate && !this._indent.indentDate) {
      return true;
    }
    if (!this.indent.indentDate && this._indent.indentDate) {
      return true;
    }
    if (
      this.indent.indentDate &&
      this._indent.indentDate &&
      this.tools.formatDateToDDMMMYY(this.indent.indentDate) !=
        this.tools.formatDateToDDMMMYY(this._indent.indentDate)
    ) {
      return true;
    }
    if (this.indent.product.productId != this._indent.product.productId) {
      return true;
    }
    if (this.indent.quantity != this._indent.quantity) {
      return true;
    }
    if (this.indent.rate != this._indent.rate) {
      return true;
    }
    if (this.indent.totalReceivedAmount != this._indent.totalReceivedAmount) {
      return true;
    }
    if (this.indent.typeOfSale != this._indent.typeOfSale) {
      return true;
    }
    if (this.isCmbModified()) {
      return true;
    }
    if (this.isPaymentModified()) {
      return true;
    }
    if (this.isModifiedComments()) {
      return true;
    }
    return false;
  }
  isModifiedComments() {
    if (this._showSaveCancel) {
      if (this.indentActionCreate.comment != this._indentActionCreate.comment) {
        return true;
      }
    }
    return false;
  }
  getCmbToCheckModify() {
    const cmbValueChange = [
      {
        cmbname: "purchaser",
        data: this.selectedAggrDistr,
        _data: this._selectedAggrDistr,
        id: "id"
      },
      {
        cmbname: "salesperson",
        data: this.selectedSalesPerson,
        _data: this._selectedSalesPerson,
        id: "empCode"
      },
      {
        cmbname: "employee",
        data: this.selectedEmployee,
        _data: this._selectedEmployee,
        id: "empCode"
      },
      {
        cmbname: "merchant",
        data: this.selectedMerchant,
        _data: this._selectedMerchant,
        id: "merchantId"
      }
    ];
    return cmbValueChange;
  }
  isCmbModified() {
    const cmbValueChange = this.getCmbToCheckModify();
    for (let i = 0; i < cmbValueChange.length; i++) {
      const data = cmbValueChange[i].data;
      const _data = cmbValueChange[i]._data;
      const _idField: string = cmbValueChange[i].id.toString();
      if (data && !_data) {
        return true;
      }
      if (!data && _data) {
        return true;
      }
      if (data && _data) {
        if (data.length != _data.length) {
          return true;
        }
        if (data.length > 0) {
          const constDataValue = data[0];
          const _constDataValue = _data[0];
          if (constDataValue[_idField] != _constDataValue[_idField]) {
            return true;
          }
        }
      }
    }

    return false;
  }
  isPaymentModified() {
    if (this.indentpayments && !this._indentpayments) {
      return true;
    }
    if (!this.indentpayments && this._indentpayments) {
      return true;
    }
    if (this.indentpayments && this._indentpayments) {
      if (this.indentpayments.length != this._indentpayments.length) {
        return true;
      }
      if (this._indentpayments.length > 0) {
        for (let i = 0; i < this.indentpayments.length; i++) {
          let value: boolean = true;
          for (let j = 0; j < this.indentpayments.length; j++) {
            if (
              this.indentpayments[i].amount == this._indentpayments[j].amount &&
              this.indentpayments[i].ifscCode ==
                this._indentpayments[j].ifscCode &&
              this.indentpayments[i].typeOfPayment ==
                this._indentpayments[j].typeOfPayment &&
              this.tools.formatDateToDDMMMYY(
                this.indentpayments[i].paymentDate
              ) ==
                this.tools.formatDateToDDMMMYY(
                  this._indentpayments[j].paymentDate
                )
            ) {
              value = false;
            }
          }
          if (value) {
            return true;
          }
        }
      }
    }

    return false;
  }
  copyPayment() {
    this._indentpayments = new Array<IndentPaymentModel>();
    for (let i = 0; i < this.indentpayments.length; i++) {
      let indentpayment: IndentPaymentModel = new IndentPaymentModel();
      indentpayment.amount = this.indentpayments[i].amount;
      indentpayment.ifscCode = this.indentpayments[i].ifscCode;
      indentpayment.paymentDate = this.indentpayments[i].paymentDate;
      indentpayment.typeOfPayment = this.indentpayments[i].typeOfPayment;
      this._indentpayments.push(indentpayment);
    }
  }

  showSaveCancel() {
    if (this.isAllowAddorEdit()) {
      if (!this.indent.currentStatus || this.indent.currentStatus == "") {
        return true;
      } else if (this.indent.currentStatus == "N") {
        if (
          !(this.allowBUActionForNewIndent() || this.showFinanceActionButtons())
        ) {
          return true;
        }
      } else if (
        this.indent.currentStatus == "BR" &&
        this.indentActionBU.action != this.objGlobal.Auth.user
      ) {
        return true;
      } else if (
        this.indent.currentStatus == "FR" &&
        this.indentActionFinance.action != this.objGlobal.Auth.user
      ) {
        return true;
      }
      return false;
    }
  }
  isBUActionPriv() {
    return this.buheadPriv;
  }
  allowBUActionForNewIndent() {
    if (this.indent.currentStatus == "N") {
      if (this.indent.skipBHAction != "Y") {
        if (
          this.isBUActionPriv() &&
          this.indentActionCreate.actionUser != this.objGlobal.Auth.user
        ) {
          return true;
        }
      }
    }
    return false;
  }
  allowBUActionForHoldIndent() {
    return (
      this.indent.currentStatus == "BH" &&
      this.indentActionBU.actionUser == this.objGlobal.Auth.user
    );
  }
  showBUActionButtons() {
    const returnvalue =
      this.allowBUActionForNewIndent() || this.allowBUActionForHoldIndent();
    return returnvalue;
  }
  disableBUHoldButton() {
    return this.indent.currentStatus == "BH";
  }
  disableFinanceHoldButton() {
    return this.indent.currentStatus == "FH";
  }
  isBUActionPassed() {
    switch (this.indent.currentStatus) {
      case "BA":
      case "BR":
      case "BH":
      case "FA":
      case "FR":
      case "FH":
      case "IC":
        return true;
    }
    return false;
  }
  showBUAction() {
    return this.isBUActionPassed() || this.indent.skipBHAction == "Y";
  }
  showBUComment() {
    if (this.showBUActionButtons()) {
      return true;
    }
    return this.isBUActionPassed();
  }
  isFinanceActionPassed() {
    switch (this.indent.currentStatus) {
      case "FA":
      case "FR":
      case "FH":
      case "IC":
        return true;
    }
    return false;
  }
  showdevicemapping() {
    let result: boolean = false;
    switch (this.indent.currentStatus) {
      case "FA":
      case "IC":
        result = true;
        break;
    }
    if (result) {
      result = this.generationPriv;
    }
    return result;
  }
  showFinanceComment() {
    if (this.showFinanceActionButtons()) {
      return true;
    }
    return this.isFinanceActionPassed();
  }
  showFinanceAction() {
    return this.isFinanceActionPassed();
  }
  isFinanceActionPriv() {
    return this.generationPriv;
  }
  showFinanceActionButtons() {
    if (this.indent.currentStatus == "BA") {
      if (
        this.indentActionBU.actionUser != this.objGlobal.Auth.user &&
        this.indentActionCreate.actionUser != this.objGlobal.Auth.user
      ) {
        if (this.isFinanceActionPriv()) {
          return true;
        }
      }
    } else if (this.indent.currentStatus == "FH") {
      if (this.indentActionFinance.actionUser == this.objGlobal.Auth.user) {
        return true;
      }
    } else if (
      this.indent.currentStatus == "N" &&
      this.indent.skipBHAction == "Y"
    ) {
      if (this.indentActionCreate.actionUser != this.objGlobal.Auth.user) {
        if (this.isFinanceActionPriv()) {
          return true;
        }
      }
    }
    return false;
  }

  buHeadApprove() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to approve indent?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.indentActionBU.action = "BA";
          this.indentAction(this.indentActionBU, false);
        }
      },
      reason => {}
    );
  }

  buHeadReject() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to reject indent?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.indentActionBU.action = "BR";
          this.indentAction(this.indentActionBU, false);
        }
      },
      reason => {}
    );
  }
  buHeadOnHold() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message =
      "Are you sure to put indent on hold?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.indentActionBU.action = "BH";
          this.indentAction(this.indentActionBU, false);
        }
      },
      reason => {}
    );
  }
  financeApprove() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to approve indent?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.indentActionFinance.action = "FA";
          this.indentAction(this.indentActionFinance, true, true);
        }
      },
      reason => {}
    );
  }

  financeReject() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to reject indent?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.indentActionFinance.action = "FR";
          this.indentAction(this.indentActionFinance, false);
        }
      },
      reason => {}
    );
  }
  financeOnHold() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message =
      "Are you sure to put indent on hold?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.indentActionFinance.action = "FH";
          this.indentAction(this.indentActionFinance, true);
        }
      },
      reason => {}
    );
  }
  getBuHeadActionClass() {
    return this._indentbuactionclass;
  }
  getFinanceActionClass() {
    return this._indentfinanceactionclass;
  }

  indentAction(
    indentaction: IndentActionModel,
    modifyPayment: boolean = false,
    checkPayment: boolean = false
  ) {
    let objmsg: SimpleMessage = new SimpleMessage();
    if (
      checkPayment &&
      this.indent.totalAmount > this.indent.totalReceivedAmount
    ) {
      objmsg.title = "Error";
      objmsg.message = "Total received amount is less than total amount!";
      objmsg.btnClass = "btn-warning";
      this.tools.simpleMessage(objmsg);
    } else {
      let apiIndentAction: IndentActionModel = new IndentActionModel();
      apiIndentAction.action = indentaction.action;
      apiIndentAction.comment = indentaction.comment;
      let apiIndent: IndentModel = new IndentModel();
      apiIndent.indentId = this.indent.indentId;
      apiIndent.currentStatus = this.indent.currentStatus;
      apiIndent.indentActions = [apiIndentAction];
      if (modifyPayment) {
        if (this.isPaymentModified()) {
          apiIndent.payments = this.indentpayments;
        }
      }
      this.indent.devices = undefined;
      this.objindentservice.indentAction(apiIndent).subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        if (data["success"]) {
          let msgtxt: String = "";
          switch (indentaction.action) {
            case "BA":
            case "FA":
              msgtxt = "Approved";
              break;
            case "BR":
            case "FR":
              msgtxt = "Rejected";
              break;
            case "BH":
            case "FH":
              msgtxt = "On Hold";
          }
          objmsg.title = "Information";
          objmsg.message = "Indent " + msgtxt;
          objmsg.btnClass = "btn-info";
          this.indent = data["result"];
          this.loadIndentOtherDataFromModel();
          this._isChange = true;
        } else if (data["message"] == "NO_INDENT") {
          objmsg.title = "Error";
          objmsg.message = "Indent not provided!";
          objmsg.btnClass = "btn-warning";
        } else if (data["message"] == "INVALID_INDENT") {
          objmsg.title = "Error";
          objmsg.message = "Invalid Indent!";
          objmsg.btnClass = "btn-warning";
        } else if (data["message"] == "INDENT_ACTIONALREADYDONE") {
          objmsg.title = "Error";
          objmsg.message = "Indent action already done!";
          objmsg.btnClass = "btn-warning";
          this.getIndentDetail(this.indent.indentId);
        } else {
          objmsg.title = "Error";
          objmsg.message = "Failed to save Indent!";
          objmsg.btnClass = "btn-warning";
        }
        this.tools.simpleMessage(objmsg);
      });
    }
  }
  populateDevices() {
    this.devices = undefined;
    if (this._showdevicemapping) {
      this.devicestockservice
        .getStockedDeviceSrListByProduct(
          this.indent.product.productId,
          this.indent.indentId
        )
        .subscribe(
          data => {
            if (data["success"]) {
              this.devices = data["result"];
            } else {
              let ngmodaloptions: NgbModalOptions = {};
              ngmodaloptions.backdrop = "static";
              ngmodaloptions.size = "sm";
              let objmsg: SimpleMessage = new SimpleMessage();
              objmsg.title = "Error";
              objmsg.message = "Failed to get Devices!";
              objmsg.btnClass = "btn-error";
            }
          },
          error => {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message =
              "Error while fetching device list, Error - " + error + "!";
            objmsg.btnClass = "btn-error";
          }
        );
    }
  }
  getSelectedDeviceCount() {
    if (!this.selectedDeviceSrNumber) {
      return 0;
    } else {
      return this.selectedDeviceSrNumber.length;
    }
  }
  onDeviceSrTickClick() {
    let filterDeviceSrItems: Array<DeviceStockModel>;
    let selDevices: Array<DeviceStockModel> = new Array<DeviceStockModel>();
    if (this.selectedDeviceSrNumber) {
      for (let i = 0; i < this.selectedDeviceSrNumber.length; i++) {
        selDevices.push(this.selectedDeviceSrNumber[i]);
      }
    }
    if (this.devicesrnorange.from && this.devicesrnorange.from != "") {
      if (this.devicesrnorange.to && this.devicesrnorange.to != "") {
        filterDeviceSrItems = this.devices.filter(
          obj =>
            obj.deviceSerialNumber >= this.devicesrnorange.from &&
            obj.deviceSerialNumber <= this.devicesrnorange.to
        );
      } else {
        filterDeviceSrItems = this.devices.filter(
          obj => obj.deviceSerialNumber >= this.devicesrnorange.from
        );
      }
    } else {
      filterDeviceSrItems = this.devices.filter(
        obj => obj.deviceSerialNumber <= this.devicesrnorange.to
      );
    }
    if (filterDeviceSrItems) {
      for (let i = 0; i < filterDeviceSrItems.length; i++) {
        if (
          !this.selectedDeviceSrNumber.find(
            item => item.deviceStockId == filterDeviceSrItems[i].deviceStockId
          )
        ) {
          selDevices.push(filterDeviceSrItems[i]);
        }
      }
    }

    this.selectedDeviceSrNumber = selDevices;
  }
  onIndentGenerationButtonClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to generate indent?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this.indentGenerate();
        }
      },
      reason => {}
    );
  }
  indentGenerate() {
    let objmsg: SimpleMessage = new SimpleMessage();
    if (
      !this.selectedDeviceSrNumber ||
      this.selectedDeviceSrNumber.length != this.indent.quantity
    ) {
      objmsg.title = "Error";
      objmsg.message = "Mapped device is not equal to the required quantity!";
      objmsg.btnClass = "btn-warning";
      this.tools.simpleMessage(objmsg);
    } else {
      let apiIndent: IndentModel = new IndentModel();
      apiIndent.indentId = this.indent.indentId;
      apiIndent.currentStatus = this.indent.currentStatus;
      apiIndent.devices = this.selectedDeviceSrNumber;
      this.objindentservice.indentGeneration(apiIndent).subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        if (data["success"]) {
          objmsg.title = "Information";
          objmsg.message = "Indent generated successfully!";
          objmsg.btnClass = "btn-info";
          this.indent = data["result"];
          this.getIndentDetail(this.indent.indentId);
          this._isChange = true;
        } else if (data["message"] == "NO_INDENT") {
          objmsg.title = "Error";
          objmsg.message = "Indent not provided!";
          objmsg.btnClass = "btn-warning";
        } else if (data["message"] == "INVALID_INDENT") {
          objmsg.title = "Error";
          objmsg.message = "Invalid Indent!";
          objmsg.btnClass = "btn-warning";
        } else if (data["message"] == "NO_DEVICE") {
          objmsg.title = "Error";
          objmsg.message = "No device to map!";
          objmsg.btnClass = "btn-warning";
          this.getIndentDetail(this.indent.indentId);
        } else {
          objmsg.title = "Error";
          objmsg.message = "Failed to generate indent!";
          objmsg.btnClass = "btn-warning";
        }
        this.tools.simpleMessage(objmsg);
      });
    }
  }

  setTopbarTextParent() {
    let option: string = "";
    if (this._showSaveCancel) {
      option = "N";
    } else if (this._showBUActionButtons) {
      option = "BA";
    } else if (this._showFinanceActionButtons) {
      option = "FA";
    } else if (this._showdevicemapping) {
      option = "IG";
    }
    if (this.onSetTopBarText) {
      this.onSetTopBarText.emit(option);
    }
  }

  closeScreen() {
    this.clearDetailData();
    this.onClose.emit(this._isChange);
  }

  qtyMessage(qty) {
    console.log(qty.errors);
  }
  formPreviewReset() {
    this.indentForm = !this.indentForm;
  }
  formPreviewCaption() {
    if (this.indentForm) {
      return "Indent Detail";
    } else {
      return "Preview Form";
    }
  }
}
