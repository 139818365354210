import { Component, OnInit, ViewChild } from "@angular/core";
import { TopbarText } from "src/app/framework/components/topbar/topbar.component";
import {
  gridColumns,
  GridtableComponent,
  GridPage
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { DeviceStockSummaryModel } from "src/app/models/reports.model";
import { Tools } from "src/app/framework/modules/tools";
import { global } from "src/app/framework/modules/global";
import { ExporttocsvService } from "src/app/framework/services/exporttocsv.service";
import { AuthService } from "src/app/framework/services/auth.service";
import { ReportService } from "src/app/services/report.service";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { SimpleMessage } from "src/app/framework/modules/util.class";

@Component({
  selector: "app-device-stock-summary",
  templateUrl: "./device-stock-summary.component.html",
  styleUrls: ["./device-stock-summary.component.css"]
})
export class DeviceStockSummaryComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "",
      columnFieldName: "productId",
      columnClasses: "c-col-pc-0"
    },
    {
      columnHeader: "PRODUCT NAME",
      columnFieldName: "productName",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "DEVICE PURCHASED",
      columnFieldName: "devicePurchased",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "DEVICE SOLD",
      columnFieldName: "deviceSold",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "DEVICE STOCK",
      columnFieldName: "deviceStock",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "DEVICE RETURNED",
      columnFieldName: "deviceReturned",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "DAMAGE STOCK",
      columnFieldName: "damageStock",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "STOCK READY FOR BILLING",
      columnFieldName: "stockReadyForBilling",
      columnClasses: "c-col-pc-20"
    }
  ];

  topbartext: TopbarText[] = [
    {
      key: "devicestockreport",
      displayText: "Device Stock",
      class: "col-md-4",
      anchorClass: "h6"
    }
  ];
  searchField = {
    date: undefined
  };
  datalist: Array<DeviceStockSummaryModel>;
  page: GridPage;
  _menuId: string;
  //isDetailScreen : boolean=false;

  productId: number = undefined;
  constructor(
    public tools: Tools,
    private objGlobal: global,
    private csvservice: ExporttocsvService,
    private reportsservice: ReportService,
    private auth: AuthService
  ) {
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    //this.objsimreturnservice.loadSIMReturnReason();

    this._menuId = this.tools.getMenuName("main/devicestocksummary");
    this.objGlobal.setActiveMenu(this._menuId);
    this.searchField.date = new Date();
  }

  ngOnInit() {}

  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }

  getReportData() {
    let searchField = {};

    this.reportsservice
      .getDeviceStockSummary(this.page, this.searchField)
      .subscribe(
        data => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
            if (this.productId == undefined && this.datalist.length > 0) {
              this.productId = this.datalist[0].productId;
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to device stock summary report!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to device stock summary report!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  exportReportForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;

    this.reportsservice.getDeviceStockSummary(page, this.searchField).subscribe(
      data => {
        let obj: Array<DeviceStockSummaryModel>;
        if (data["success"]) {
          obj = data["result"];
          this.csvservice.exportToCsv(
            obj,
            this.gridcols,
            undefined,
            "DeviceStockSummary"
          );
        } else {
          alert("Failed to get device stock summary report!");
        }
      },
      error => {
        alert("Failed to get device stock summary report!");
      }
    );
  }
  onExcelClick() {
    this.exportReportForExcel();
  }
}
