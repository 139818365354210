import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";
import { Tools } from "../framework/modules/tools";
import { AppSettings } from "../framework/config/AppSettings";

@Injectable()
export class ReportService {
  constructor(
    private http: HttpClient,
    private tools: Tools,
    private appsetting: AppSettings
  ) {}
  getDeviceBillingSummary(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    if (searchField.indentDate) {
      if (searchField.indentDate[0]) {
        httpParam = httpParam.append(
          "indentDateFrom",
          this.tools.formatDateToDDMMMYY(searchField.indentDate[0])
        );
      }
      if (searchField.indentDate[1]) {
        httpParam = httpParam.append(
          "indentDateTill",
          this.tools.formatDateToDDMMMYY(searchField.indentDate[1])
        );
      }
    }
    if (searchField.typeOfSale && searchField.typeOfSale != "") {
      httpParam = httpParam.append("typeOfSale", searchField.typeOfSale);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/devicebillingsummary", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }

  getDeviceStockSummary(pageinfo: GridPage, searchField: any) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    if (searchField.date) {
      httpParam = httpParam.append(
        "date",
        this.tools.formatDateToDDMMMYY(searchField.date)
      );
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/devicestocksummary", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }

  getActiveTerminaReport(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    //httpParam=httpParam.append("searchText", searchField.searchText);
    if (searchField.activationDate) {
      if (searchField.activationDate[0]) {
        httpParam = httpParam.append(
          "activationDateFrom",
          this.tools.formatDateToDDMMMYY(searchField.activationDate[0])
        );
      }
      if (searchField.activationDate[1]) {
        httpParam = httpParam.append(
          "activationDateTill",
          this.tools.formatDateToDDMMMYY(searchField.activationDate[1])
        );
      }
    }
    if (
      searchField.aggregatorId != undefined &&
      searchField.aggregatorId != ""
    ) {
      httpParam = httpParam.append("aggregatorId", searchField.aggregatorId);
    }
 
    if (searchField.terminalCategory) {
      httpParam = httpParam.append(
        "terminalType",
        searchField.terminalCategory
      );
    }
    if (searchField.productId) {
      httpParam = httpParam.append("productId", searchField.productId);
    }
    if (searchField.activationStatus) {
      httpParam = httpParam.append(
        "activationStatus",
        searchField.activationStatus
      );
    }

    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/activeterminalreport", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }

  getActiveTerminalSummaryReport(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    //httpParam=httpParam.append("searchText", searchField.searchText);
    if (searchField.activationDate) {
      httpParam = httpParam.append(
        "activationDate",
        this.tools.formatDateToDDMMMYY(searchField.activationDate)
      );
    }
    if (
      searchField.aggregatorId != undefined &&
      searchField.aggregatorId != ""
    ) {
      httpParam = httpParam.append("aggregatorId", searchField.aggregatorId);
    }
    if (searchField.productId) {
      httpParam = httpParam.append("productId", searchField.productId);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/activeterminalsummaryreport",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getTerminaActivationSummaryReport(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    //httpParam=httpParam.append("searchText", searchField.searchText);
    if (searchField.activationDate) {
      if (searchField.activationDate[0]) {
        httpParam = httpParam.append(
          "activationDateFrom",
          this.tools.formatDateToDDMMMYY(searchField.activationDate[0])
        );
      }
      if (searchField.activationDate[1]) {
        httpParam = httpParam.append(
          "activationDateTill",
          this.tools.formatDateToDDMMMYY(searchField.activationDate[1])
        );
      }
    }
    if (searchField.typeOfSale != undefined && searchField.typeOfSale != "") {
      httpParam = httpParam.append("typeOfSale", searchField.typeOfSale);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/terminalactivationsummaryreport",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getTerminaDeactivationSummaryReport(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    //httpParam=httpParam.append("searchText", searchField.searchText);
    if (searchField.deactivationDate) {
      if (searchField.deactivationDate[0]) {
        httpParam = httpParam.append(
          "deactivationDateFrom",
          this.tools.formatDateToDDMMMYY(searchField.deactivationDate[0])
        );
      }
      if (searchField.deactivationDate[1]) {
        httpParam = httpParam.append(
          "deactivationDateTill",
          this.tools.formatDateToDDMMMYY(searchField.deactivationDate[1])
        );
      }
    }
    if (
      searchField.aggregatorId != undefined &&
      searchField.aggregatorId != ""
    ) {
      httpParam = httpParam.append("aggregatorId", searchField.aggregatorId);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/terminaldeactivationsummaryreport",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getSettlementReport(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    //httpParam=httpParam.append("searchText", searchField.searchText);
    if (searchField.dateFrom) {
      httpParam = httpParam.append(
        "dateFrom",
        this.tools.formatDateToDDMMMYY(searchField.dateFrom)
      );
    }
    if (searchField.dateTill) {
      httpParam = httpParam.append(
        "dateTill",
        this.tools.formatDateToDDMMMYY(searchField.dateTill)
      );
    }
    
    if (searchField.tid != undefined && searchField.tid != "") {
      httpParam = httpParam.append("tid", searchField.tid);
    }
    if (searchField.rrn != undefined && searchField.rrn != "") {
      httpParam = httpParam.append("rrn", searchField.rrn);
    }
    if (searchField.transactionType != undefined && searchField.transactionType != "") {
      httpParam = httpParam.append("transactionType", searchField.transactionType);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/settlementreport",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }


  getSettlementSummaryReport(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    //httpParam=httpParam.append("searchText", searchField.searchText);
    if (searchField.dateRange) {
      if (searchField.dateRange[0]) {
        httpParam = httpParam.append(
          "dateFrom",
          this.tools.formatDateToDDMMMYY(searchField.dateRange[0])
        );
      }
      if (searchField.dateRange[1]) {
        httpParam = httpParam.append(
          "dateTill",
          this.tools.formatDateToDDMMMYY(searchField.dateRange[1])
        );
      }
    }
    if (searchField.transactionType != undefined && searchField.transactionType != "") {
      httpParam = httpParam.append("transactionType", searchField.transactionType);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/settlementSummaryReport",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
    }
  
  getPaymentDistinctTranType() {
    let header = new HttpHeaders();
    return this.http.get(this.appsetting.get("server-url") + "/api/getpaymentdistincttranstype", {
      headers: header,
      observe: "body",
      responseType: "json",
    }).map((res) => res);
  }

  getTerminalListReport(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    //httpParam=httpParam.append("searchText", searchField.searchText);
    if (searchField.dateFrom) {
      httpParam = httpParam.append(
        "dateFrom",
        this.tools.formatDateToDDMMMYY(searchField.dateFrom)
      );
    }
    if (searchField.dateTill) {
      httpParam = httpParam.append(
        "dateTill",
        this.tools.formatDateToDDMMMYY(searchField.dateTill)
      );
    }
    if (searchField.status != undefined && searchField.status != "") {
      httpParam = httpParam.append("status", searchField.status);
    }
    if (searchField.productId != undefined && searchField.productId.toString() != "") {
      httpParam = httpParam.append("productId", searchField.productId.toString());
    }
    if (searchField.mid != undefined && searchField.mid != "") {
      httpParam = httpParam.append("mid", searchField.mid);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/terminallistreport",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
    }
  

    getTerminalwiseSettlementReport(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    //httpParam=httpParam.append("searchText", searchField.searchText);
    if (searchField.dateRange) {
      if (searchField.dateRange[0]) {
        httpParam = httpParam.append(
          "dateFrom",
          this.tools.formatDateToDDMMMYY(searchField.dateRange[0])
        );
      }
      if (searchField.dateRange[1]) {
        httpParam = httpParam.append(
          "dateTill",
          this.tools.formatDateToDDMMMYY(searchField.dateRange[1])
        );
      }
    }
    if (searchField.tid != undefined && searchField.tid != "") {
      httpParam = httpParam.append("tid", searchField.tid);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/terminalwisesettlementreport",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getBoardingDashboard(
    pageinfo: GridPage,
    searchField: any
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    if (searchField.fromDate) {
        httpParam = httpParam.append(
          "dateFrom",
          this.tools.formatDateToDDMMMYY(searchField.fromDate)
        )
    }
    if (searchField.tillDate) {
        httpParam = httpParam.append(
          "dateTill",
          this.tools.formatDateToDDMMMYY(searchField.tillDate)
        )
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/boardingdashboard",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getMerchantSettlementReport(
    pageinfo: GridPage,
    searchField: any,
    sortField : any
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    if (searchField.dateFrom) {
        httpParam = httpParam.append(
          "dateFrom",
          this.tools.formatDateToDDMMMYY(searchField.dateFrom)
        )
    }
    if (searchField.dateTill) {
        httpParam = httpParam.append(
          "dateTill",
          this.tools.formatDateToDDMMMYY(searchField.dateTill)
        )
    }
    if (searchField.transactionType) {
        httpParam = httpParam.append(
          "transactionType",
          searchField.transactionType
        )
    }
    if (searchField.tid) {
        httpParam = httpParam.append(
          "tid",
          searchField.tid
        )
    }
    if (searchField.rrn) {
        httpParam = httpParam.append(
          "rrn",
          searchField.rrn
        )
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/merchantsettlementreport",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getMerchantSettlementSummary(
    pageinfo: GridPage,
    searchField: any
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    if (searchField.fromDate) {
        httpParam = httpParam.append(
          "dateFrom",
          this.tools.formatDateToDDMMMYY(searchField.fromDate)
        )
    }
    if (searchField.tillDate) {
        httpParam = httpParam.append(
          "dateTill",
          this.tools.formatDateToDDMMMYY(searchField.tillDate)
        )
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/merchantsettlementsummary",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getMerchantPortalSettlementSummary(
    pageinfo: GridPage,
    searchField: any
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    if (searchField.fromDate) {
        httpParam = httpParam.append(
          "dateFrom",
          this.tools.formatDateToDDMMMYY(searchField.fromDate)
        )
    }
    if (searchField.tillDate) {
        httpParam = httpParam.append(
          "dateTill",
          this.tools.formatDateToDDMMMYY(searchField.tillDate)
        )
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/merchantportalsettlementsummary",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }


  getRevenueSummary(
    pageinfo: GridPage,
    searchField: any
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    if (searchField.fromDate) {
        httpParam = httpParam.append(
          "dateFrom",
          this.tools.formatDateToDDMMMYY(searchField.fromDate)
        )
    }
    if (searchField.tillDate) {
        httpParam = httpParam.append(
          "dateTill",
          this.tools.formatDateToDDMMMYY(searchField.tillDate)
        )
    }
    if (searchField.aggregatorId) {
        httpParam = httpParam.append(
          "aggregatorId",
          searchField.aggregatorId
        )      
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/revenuesummary",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }


  getDatewiseTransactionSummary(
    pageinfo: GridPage,
    searchField: any
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    if (searchField.fromDate) {
        httpParam = httpParam.append(
          "dateFrom",
          this.tools.formatDateToDDMMMYY(searchField.fromDate)
        )
    }
    if (searchField.tillDate) {
        httpParam = httpParam.append(
          "dateTill",
          this.tools.formatDateToDDMMMYY(searchField.tillDate)
        )
    }
    if (searchField.aggregatorId) {
        httpParam = httpParam.append(
          "aggregatorId",
          searchField.aggregatorId
        )      
    }
    if (searchField.transactionType) {
        httpParam = httpParam.append(
          "transactionType",
          searchField.transactionType
        )      
    }
    if (searchField.tid) {
        httpParam = httpParam.append(
          "tid",
          searchField.tid
        )      
    }
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/datewisetransactionsummary",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getTransactionSummary(
    reportType : string,
    pageinfo: GridPage,
    searchField: any
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    if (searchField.fromDate) {
        httpParam = httpParam.append(
          "dateFrom",
          this.tools.formatDateToDDMMMYY(searchField.fromDate)
        )
    }
    if (searchField.tillDate) {
        httpParam = httpParam.append(
          "dateTill",
          this.tools.formatDateToDDMMMYY(searchField.tillDate)
        )
    }
    httpParam = httpParam.append("reportType", reportType);
    if (searchField.aggregatorId) {
        httpParam = httpParam.append(
          "aggregatorId",
          searchField.aggregatorId
        )      
    }
    if (searchField.tid) {
      httpParam = httpParam.append("tid", searchField.tid);
    }
    if (reportType == "C") {
      if (searchField.cardType) {
        httpParam = httpParam.append("card", searchField.cardType);
      }      
    } else if(reportType=="S") {
      if (searchField.transactionType) {
        httpParam = httpParam.append("transactionType", searchField.transactionType);
      }
    } else if (reportType == "P") {
      if (searchField.product) {
        httpParam = httpParam.append("product", searchField.product);
      }
    } else if (reportType == "N") {
      if (searchField.newtork) {
        httpParam = httpParam.append("network", searchField.network);
      }
    }

    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/transactionsummary",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }
}
