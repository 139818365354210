import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import "rxjs/add/operator/map";
import { global } from "../modules/global";
import { environment} from '../../../environments/environment'
@Injectable()
export class ImageconfigService {
  private imagePathList: any;
  constructor(private _http: HttpClient, private objglobal: global) {
    this.loadImageFilesPath()
      .toPromise()
      .then(imagePaths => {
        let whiteLabelCodesString = this.objglobal.getCurrentWhiteLabelCode();
        if (whiteLabelCodesString) { whiteLabelCodesString += ",";}
        whiteLabelCodesString += "EZSWYPE";
        const whiteLabelCodes = whiteLabelCodesString.split(",");
        this.imagePathList = {};
        for (let i = 0; i < whiteLabelCodes.length; i++) {
          const aggCode = whiteLabelCodes[i];
          if (imagePaths[aggCode]) {
            this.imagePathList[aggCode] = imagePaths[aggCode];
          }
        }
        this.objglobal.isImagePathSet = true;

        this.objglobal.imageLoadSubject.next();
      });
  }

  loadImageFilesPath() {
    //alert(this.objglobal.getCurrentWhiteLabelCode());
    const imageconfigfilepath= "assets/config/" + environment.env_text + "-imagepath.json";
    return this._http
      .get(imageconfigfilepath)
      .map(
        (response: Response) =>
          response
      );
  }
  getImageFilePath(key: string) {
    let path: string = undefined;
    if (this.objglobal.isImagePathSet && this.imagePathList) {
      if (this.objglobal.Auth.aggregatorCode) {
        if (this.imagePathList[this.objglobal.Auth.aggregatorCode]) {
          path = this.imagePathList[this.objglobal.Auth.aggregatorCode][key]
        } else {
          path = this.imagePathList["EZSWYPE"][key]
        }
      } else {
        let whiteLableCodeUrl = this.objglobal.getCurrentWhiteLabelCode();
        if (whiteLableCodeUrl && this.imagePathList[whiteLableCodeUrl]) {
          path = this.imagePathList[whiteLableCodeUrl][key];
        }
        if (!path) { path = this.imagePathList["EZSWYPE"][key]; }
      }
    }
    if(!path) {path="assets/images/" + key + ".png";}
    //console.log("image file path of === " + key + " ======== " + path);
    return path;
  }

  getImageFilePathUserAggregator(key: string) {
    let path: string = undefined;
    if (this.objglobal.isImagePathSet && this.imagePathList) {
      if (this.objglobal.Auth.aggregatorCode) {
        if (this.imagePathList[this.objglobal.Auth.aggregatorCode]) {
          path = this.imagePathList[this.objglobal.Auth.aggregatorCode][key]
        }
      }
    }
    return path;
  }

  loadTopbarImage() {
    const topbarImageFile = this.getImageFilePathUserAggregator("topbar-agg-image");
    
    if (topbarImageFile) {
      this._http.get(topbarImageFile, { responseType: "blob" }).subscribe(file => {
        var mimeType = file.type;
        //if (mimeType.match(/image\/*/) == null) {
        // this.message = "Only images are supported.";
        //    return;
        //}
        if (mimeType.match(/image\/*/) != null) {
          //this.imagePath = files;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (_event) => {
            this.objglobal.topbarImage = reader.result;
          };
        }
      
      })
    }
    
  }
}
