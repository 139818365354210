import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { global } from 'src/app/framework/modules/global';
import { Tools } from 'src/app/framework/modules/tools';
import { SimpleMessage, SortClass } from 'src/app/framework/modules/util.class';
import { AuthService } from 'src/app/framework/services/auth.service';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { gridColumns, GridPage, GridtableComponent } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { ProductModel } from 'src/app/models/product.model';
import { MerchantService } from 'src/app/services/merchant.service';
import { ProductService } from 'src/app/services/product.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-terminallist-report',
  templateUrl: './terminallist-report.component.html',
  styleUrls: ['./terminallist-report.component.css']
})
export class TerminallistReportComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "TERMINAL ID",
      columnFieldName: "tid",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "MERCHANT ID",
      columnFieldName: "mid",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "MERCHANT NAME",
      columnFieldName: "companyName",
      columnClasses: "c-col-pc-25",
    },
    {
      columnHeader: "ACTIVATION DATE",
      columnFieldName: "activationDate",
      columnClasses: "c-col-pc-13",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "PRODUCT",
      columnFieldName: "productName",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "STATUS",
      columnFieldName: "status",
      columnClasses: "c-col-pc-14",
      callbackFunction: this.getStatusText
    }
  ];
  sortFields = [
    { name: "tid", display: "Terminal Id" },
    { name: "mid", display: "Merchant Id" },
    { name: "companyName", display: "Merchant Name" },
    { name: "activationDate", display: "Activation Date" },
    { name: "productName", display: "Product Name" },
   /* { name: "aggregatorName", display: "AGGREGATOR" },*/
    { name: "deviceSerialNumber", display: "Device Serial Number" },
  ];

  topbartext: TopbarText[] = [
    {
      key: "terminalactivationreport",
      displayText: "Terminal Activation Report",
      class: "col-md-5",
      anchorClass: "h6",
    },
  ];
  searchField = {
    dateFrom: undefined,
    dateTill: undefined,
    status: undefined,
    productId: undefined,
    mid: undefined
  };
  datalist: Array<any>;
  page: GridPage;
  _menuId: string;
  //isDetailScreen : boolean=false;

  sort: SortClass;
  tid: string = "";
  productList: ProductModel[];
  statusList: any[];
  constructor(public tools: Tools,private objGlobal: global,
    private csvservice: ExporttocsvService, private auth: AuthService,
    private router: Router, private productService: ProductService,
    private merchantService : MerchantService, private reportService : ReportService) {
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    //this.objsimreturnservice.loadSIMReturnReason();

    this._menuId = this.tools.getMenuName(
        this.router.url.substring(1, this.router.url.length)
      );
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "tid";
    this.searchField.dateFrom = new Date();
    this.searchField.dateTill = this.searchField.dateFrom;
    
    this.getProducts();
    this.getStatuses();
  }

    ngOnInit() {
    }


  getStatusText(v: string) {
    switch (v) {
      case "PB": {
        return "Pre-Boareded";
      }
      case "S": {
        return "Boarding";
      }
      case "R": {
        return "Ready for approval";
      }
      case "A": {
        return "Approved";
      }
      case "J": {
        return "Rejected";
      }
      case "H": {
        return "Hold approval";
      }
      case "C": {
        return "Boarded";
      }
      case "I":
      case "D" : {
        return "Deactivated";
      }
      case "F": {
        return "Failed to update in MARS";
      }
      case "P": {
        return "Pending";
      }
      case "E": {
        return "Mars Rejection";
      }
      case "T": {
        return "Waiting for Activation";
      }
      case "V": {
        return "Activation/Deactivation Request Sent"
      }
      default:
        return undefined;
    }
  }

  getProducts() {
    this.productService.getProductNameApi().subscribe(res => {
      if (res["success"]) {
        this.productList = res["result"];
      } else {
        console.error("Failed to load product list");
      }
    }, err => {
        console.log("Error to get product list");
    })
  }

  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }
  getStatuses() {
    this.statusList = this.merchantService.getTerminalStatuses();
  }


  getReportData() {

    this.reportService
      .getTerminalListReport(this.page, this.searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
            if (this.tid == "" && this.datalist.length > 0) {
              this.tid = this.datalist[0].tid;
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to terminal list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to terminal list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  exportTerminalListReportForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    
    this.reportService
      .getTerminalListReport(page, this.searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<any>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "TerminalList"
            );
          } else {
            alert("Failed to get terminal list!");
          }
        },
        (error) => {
          alert("Failed to get terminal list!");
        }
      );
  }
  onExcelClick() {
    this.exportTerminalListReportForExcel();
  }

}
