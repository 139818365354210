import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: "ag-transactionreportbtnrefresh",
  template: `
    <button
      type="submit"
      class="btn btn-outline-primary ml-1"
      (click)="onClick()"
    >
      <img src="assets/images/Refresh_16.png" />
    </button>
  `,
  styles: [
    `
      .btn {
        line-height: 0.5;
        width: 100%;
      }
    `
  ]
})
export class TransactionReportBtnRefreshComponent
  implements ICellRendererAngularComp {
  //@Output() onClicked = new EventEmitter<boolean>();

  public params: any;
  onClick(): void {
    this.params.context.componentParent.refreshClick();
    //this.onClicked.emit();
  }
  refresh(): boolean {
    return false;
  }
  agInit(params: any): void {
    this.params = params;
  }
}
