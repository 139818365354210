import { Component, OnInit, ViewChild } from '@angular/core';
import { GridtableComponent, GridPage, gridColumns } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { SortClass, SimpleMessage } from 'src/app/framework/modules/util.class';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { Tools } from 'src/app/framework/modules/tools';
import { global } from 'src/app/framework/modules/global';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { MerchantUploadService } from 'src/app/services/merchant-upload.service';
import { AuthService } from 'src/app/framework/services/auth.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-merchant-upload-history',
  templateUrl: './merchant-upload-history.component.html',
  styleUrls: ['./merchant-upload-history.component.css']
})
export class MerchantUploadHistoryComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "File Name",
      columnFieldName: "fileName",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "Import Date Time",
      columnFieldName: "importDateTime",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS
    },
    {
      columnHeader: "User",
      columnFieldName: "importUser",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Record#",
      columnFieldName: "recordCount",
      columnClasses: "c-col-pc-8 text-right mr-2",
    },
    {
      columnHeader: "New MID#",
      columnFieldName: "newMIDRecordCount",
      columnClasses: "c-col-pc-9  text-right mr-2",
    },
    {
      columnHeader: "New TID#",
      columnFieldName: "newTIDRecordCount",
      columnClasses: "c-col-pc-9 text-right mr-2",
    },
    {
      columnHeader: "Existing MID#",
      columnFieldName: "existingMIDCount",
      columnClasses: "c-col-pc-10 text-right mr-2",
      
    },
    {
      columnHeader: "Existing TID#",
      columnFieldName: "existingTIDCount",
      columnClasses: "c-col-pc-10 text-right mr-2",
      
    },
    {
      columnHeader: "Status",
      columnFieldName: "importStatus",
      columnClasses: "c-col-pc-8",
      callbackFunction : this.getStatusText
    }
  ];
  sortFields = [
    { name: "", display: "-" },
    { name: "importDateTime", display: "Import Date" },
    { name: "fileName", display: "File Name" },
    { name: "importStatus", display: "Status" },
    { name: "importUser", display : "User"},
    { name: "recordCount", display: "Record Count" },
    { name: "newMIDRecordCount", display: "New MID Count" },
    { name: "newTIDRecordCount", display: "Net TID Count" },
    { name: "existingMIDCount", display: "Existing MID Count" },
    { name: "existingTIDCount", display: "Existing TID Count" }
  ];

  topbartext: TopbarText[] = [
    {
      key: "merchantuploadhistory",
      displayText: "Merchant Upload History",
      class: "col-md-5",
      anchorClass: "h6",
    },
  ];
  searchField = {
    importDateFrom: undefined,
    importDateTill: undefined,
    searchText: undefined,
    status: undefined
  };
  datalist: Array<MerchantUploadHistoryModel>;
  page: GridPage;
  _menuId: string;
  //isDetailScreen : boolean=false;

  sort: SortClass;
  tid: string = "";

  constructor(public tools: Tools,
    private objGlobal: global,
    private csvservice: ExporttocsvService,
    private merchantuploadservice: MerchantUploadService,
    private auth: AuthService) {
      this.page = new GridPage();
      this.page.size = 20; //Default page size
    //this.objsimreturnservice.loadSIMReturnReason();

    this._menuId = this.tools.getMenuName("main/merchantuploadhistory");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "id";
    //this.searchField.importDateFrom = new Date();
    //this.searchField.importDateTill = this.searchField.importDateFrom;

  }

  ngOnInit() {
  }
  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }
  getStatusText(val) {
    
    if (val == "S") {
      return "Success";
    } else if (val == "F") {
      return "Fail";
    } else {
      return "";
    }
  }

  getReportData() {
    let searchField = {};
    if (
      this.searchField.importDateFrom ||
      this.searchField.importDateTill
    ) {
      searchField["importDate"] = new Array<Date>();
      searchField["importDate"].push(this.searchField.importDateFrom);
      searchField["importDate"].push(this.searchField.importDateTill);
    }
    searchField["searchText"] = this.searchField.searchText;
    searchField["status"] = this.searchField.status;
    this.merchantuploadservice
      .getMerchantUploadHistory(this.page, searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get merchant upload history!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get merchant upload history!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  exportMerchantUploadForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    let searchField = {};
    if (
      this.searchField.importDateFrom ||
      this.searchField.importDateTill
    ) {
      searchField["importDate"] = new Array<Date>();
      searchField["importDate"].push(this.searchField.importDateFrom);
      searchField["importDate"].push(this.searchField.importDateTill);
    }
    searchField["searchText"] = this.searchField.searchText;
    searchField["status"] = this.searchField.status;
    this.merchantuploadservice
      .getMerchantUploadHistory(page, searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<MerchantUploadHistoryModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "merchantuploadhistory"
            );
          } else {
            alert("Failed to export merchant upload hisotry!");
          }
        },
        (error) => {
          alert("Failed to export merchant upload hisotry!");
        }
      );
  }

  onExcelClick() {
    this.exportMerchantUploadForExcel();
  }

}
