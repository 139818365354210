import { ProductModel } from "./product.model";
import { SupplierModel } from "./supplier.model";
import { MerchantModel } from "./merchant.model";
import { AggregatorDistributorModel } from "./aggregator.distributor.model";

export class DeviceStockModel {
  deviceStockId?: number;
  deviceSerialNumber?: string;
  product?: ProductModel = new ProductModel();
  invoiceNumber?: string;
  invoiceDate?: Date;
  entryDate?: Date;
  supplier?: SupplierModel = new SupplierModel();
  deviceCondition?: string;
  allowMultipleTerminals?: boolean;
  merchant?: MerchantModel = new MerchantModel();
  aggregator?: AggregatorDistributorModel;
}

export class DeviceReturnModel {
  deviceReturnId?: number;
  returnDate?: Date;
  deviceStock?: DeviceStockModel = new DeviceStockModel();
  merchant?: MerchantModel = new MerchantModel();
  reasonOfReturn?: string;
  deviceCondition?: string;
  remarks?: string;
}
