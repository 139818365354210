import { Injectable } from "@angular/core";
import { MerchantPreboardingModel } from "../models/merchant.preboarding.model";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";
import { Tools } from "../framework/modules/tools";

@Injectable()
export class MerchantPreboardingService {
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}
  saveDeviceStock(merchantPreboard: MerchantPreboardingModel) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/merchantpreboarding",
        merchantPreboard,
        { headers: header }
      )
      .map(res => res);
  }
  getMerchantPreboardList(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (searchField.invoiceDate) {
      httpParam = httpParam.append(
        "preboardingDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.preboardingDate[0])
      );
      httpParam = httpParam.append(
        "preboardingDateTill",
        this.tools.formatDateToDDMMMYY(searchField.preboaringDate[1])
      );
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/preboardmerchants", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getMerchantPreboardingDetail(id: number) {
    let header = new HttpHeaders();
    //let param: HttpParams = new HttpParams();
    //param = param.append("id", id.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/merchantpreboarding/" + id,
        {
          headers: header,
          observe: "body",
          //params: param,
          responseType: "json"
        }
      )
      .map(res => res);
  }
}
