import { Component, OnInit, ViewChild } from "@angular/core";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import {
  GridtableComponent,
  GridPage,
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { gridColumns } from "src/app/framework/util/components/gridtable/gridtable.component";
import { Tools } from "src/app/framework/modules/tools";
import { global } from "src/app/framework/modules/global";
import { AuthService } from "src/app/framework/services/auth.service";
import { MDRPlanModel } from "src/app/models/mdr.plan.model";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { RentalplanService } from "src/app/services/rentalplan.service";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgYesNoModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";
import { RentalplanDetailComponent } from "../rentalplan-detail/rentalplan-detail.component";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
import { RentalPlanModel } from "src/app/models/rental.plan.model";

@Component({
  selector: "app-rental-plan",
  templateUrl: "./rental-plan.component.html",
  styleUrls: ["./rental-plan.component.css"],
})
export class RentalPlanComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  @ViewChild(RentalplanDetailComponent, { static: true })
  rentalplanDetailComp: RentalplanDetailComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "Plan Name",
      columnFieldName: "name",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "Plan Code",
      columnFieldName: "code",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "Creation Date",
      columnFieldName: "creationDate",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.tools.formatDateToDDMMMYY,
    },
    {
      columnHeader: "Aggregator",
      columnFieldName: "aggregator.name",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "Description",
      columnFieldName: "description",
      columnClasses: "c-col-pc-20",
    },
    {
      columnHeader: "Status",
      columnFieldName: "status",
      columnClasses: "c-col-pc-10",
      _parentscope: this,
      callClassFunction: this.statusClass,
      callbackFunction: this.getStatusText,
    },
  ];
  sortFields = [
    { name: "code", display: "Plan Code" },
    { name: "name", display: "Plan Name" },
    { name: "creationDate", display: "Creation Date" },
    { name: "aggregator.name", display: "Aggregator" },
    { name: "status", display: "Status" },
  ];
  statuses = [
    { name: "A", display: "Active" },
    { name: "I", display: "InActive" },
    { name: "P", display: "Pending for MARS update" },
    { name: "F", display: "MARS Update failed" },
  ];
  topbartext: TopbarText[] = [
    { key: "rentalplanlist", displayText: "Rental Plan List" },
  ];
  page: GridPage;
  datalist: Array<MDRPlanModel>;
  _menuId: string;
  _action: string;
  isDetailScreen: boolean = false;
  searchField = {
    searchText: "",
    creationDate: "",
    status: "",
  };
  _code: string = "";
  sort: SortClass;
  constructor(
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private rentalplanservice: RentalplanService,
    private ngbModalService: NgbModal,
    private csvservice: ExporttocsvService
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/rentalplan");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "code";
    this.getDataList();
  }

  ngOnInit() {}
  getDataList() {
    this.rentalplanservice
      .getRentalPlanList(this.page, this.searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get Retanl plan list!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get Retanl plan list!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  onAddNew() {
    this.rentalplanDetailComp.action = "new";
    this._action = "new";
    this.isDetailScreen = true;
    this.topbartext = [
      { key: "newrentalplan", displayText: "New Rental Plan" },
    ];
    this.rentalplanDetailComp.onAddNew(true);
  }
  selectData(e: any) {
    if (e.code) {
      this._action = "update";
      this._code = e.code;
      this.isDetailScreen = true;
      this.rentalplanDetailComp.action = "update";
      this.topbartext = [
        { key: "rentalplandetail", displayText: "Rental Plan Detail" },
      ];
      this.rentalplanDetailComp.getRentalPlanDetail(e.code);
    }
  }

  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  isAllowAdd() {
    return this.auth.isPrivExists(this._menuId + "=>add");
  }
  isEditPriv() {
    return this.auth.isPrivExists(this._menuId + "=>edit");
  }
  onCloseDetailScreen(isChange: boolean) {
    if (isChange == true) {
      this.getDataList();
    }
    this.isDetailScreen = false;
    this.topbartext = [
      { key: "rentalplanlist", displayText: "Rental Plan List" },
    ];
  }
  deleteRecord(e: any) {
    if (e.obj && e.obj.code) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to delete MDR Plan?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.rentalplanservice.markRentalPlanInactive(e.obj.code).subscribe(
              (data) => {
                if (data["success"]) {
                  let objmsg: SimpleMessage = new SimpleMessage();
                  let ngmodaloptions: NgbModalOptions = {};
                  ngmodaloptions.backdrop = "static";
                  ngmodaloptions.size = "sm";
                  objmsg.title = "Success";
                  objmsg.message = "Rental Plan marked inactive!";
                  objmsg.btnClass = "btn-success";
                  this.tools.simpleMessage(objmsg);
                  this.getDataList();
                } else {
                  let objmsg: SimpleMessage = new SimpleMessage();
                  let ngmodaloptions: NgbModalOptions = {};
                  ngmodaloptions.backdrop = "static";
                  ngmodaloptions.size = "sm";
                  objmsg.title = "Error";
                  objmsg.btnClass = "btn-error";
                  if (data["message"] == "INVALIDCODE") {
                    objmsg.message = "Invalid Rental Plan!";
                  } else if (data["message"] == "Error") {
                    objmsg.message = "Failed to inactive Rental Plan!";
                  }
                  this.tools.simpleMessage(objmsg);
                }
              },
              (error) => {
                let objmsg: SimpleMessage = new SimpleMessage();
                let ngmodaloptions: NgbModalOptions = {};
                ngmodaloptions.backdrop = "static";
                ngmodaloptions.size = "sm";
                objmsg.title = "Error";
                objmsg.message = "Failed to inactive Rental Plan!";
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
              }
            );
          }
        },
        (reason) => {}
      );
    } else {
      let objmsg: SimpleMessage = new SimpleMessage();
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      objmsg.title = "Warning";
      objmsg.message = "No Rental Plan selected to mark inactive!";
      objmsg.btnClass = "btn-warning";
      this.tools.simpleMessage(objmsg);
    }
  }

  enableGridDeleteCheck = function (obj: MDRPlanModel) {
    return obj.status == "A";
  };
  getStatusText(codeValue: String, paraentscope) {
    let value: String = "";
    const arStatus = paraentscope.statuses.filter(
      (obj) => obj.name == codeValue
    );
    if (arStatus && arStatus.length > 0) {
      value = arStatus[0].display;
    }
    return value;
  }

  getRentalPlanListForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.rentalplanservice
      .getRentalPlanList(page, this.searchField, sort)
      .subscribe(
        (data) => {
          let obj: Array<RentalPlanModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "rentalplan"
            );
          } else {
            alert("Failed to get Rental plan list!");
          }
        },
        (error) => {
          alert("Failed to get Rental plan list!");
        }
      );
  }
  onExcelClick() {
    this.getRentalPlanListForExcel();
  }
  statusClass(status: String) {
    let statusClass: string;
    switch (status) {
      case "P":
        statusClass = "table-primary";
        break;
      case "BA":
      case "A":
        statusClass = "table-success";
        break;
      case "F":
        statusClass = "table-warning";
        break;
      case "I":
        statusClass = "table-danger";
        break;
    }
    return statusClass;
  }
}
