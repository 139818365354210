import { Component, OnInit, ViewChild } from "@angular/core";
import { IndentModel } from "src/app/models/indent.model";
import {
  GridPage,
  GridtableComponent
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { SortClass, SimpleMessage } from "../../framework/modules/util.class";
import { TopbarText } from "../../framework/components/topbar/topbar.component";
import { gridColumns } from "src/app/framework/util/components/simplegrid/simplegrid.component";
import { Tools } from "src/app/framework/modules/tools";
import { global } from "src/app/framework/modules/global";
import { IndentService } from "src/app/services/indent.service";
import { ExporttocsvService } from "../../framework/services/exporttocsv.service";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { DeviceBillingSummaryReportModel } from "../../models/reports.model";
import { ReportService } from "src/app/services/report.service";
import { AuthService } from "src/app/framework/services/auth.service";

@Component({
  selector: "app-devicebillingsummary",
  templateUrl: "./devicebillingsummary.component.html",
  styleUrls: ["./devicebillingsummary.component.css"]
})
export class DevicebillingsummaryComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "Billing Date",
      columnFieldName: "billingDate",
      columnClasses: "c-col-pc-13",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Type of Sale",
      columnFieldName: "typeOfSale",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Aggregator",
      columnFieldName: "aggregatorName",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Distributor",
      columnFieldName: "distributorName",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Device Model",
      columnFieldName: "deviceModel",
      columnClasses: "c-col-pc-13"
    },
    {
      columnHeader: "Qty",
      columnFieldName: "quantity",
      columnClasses: "c-col-pc-5 text-right pr-2"
    },
    {
      columnHeader: "Amount",
      columnFieldName: "amount",
      columnClasses: "c-col-pc-10 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    }
    /*,
  {columnHeader : "Status",
  columnFieldName : "currentStatus",
  //columnClasses : "c-col-pc-10",
  callbackFunction : this.getStatusText,
  callClassFunction : this.statusClass
  }*/
  ];
  sortFields = [
    { name: "indentDate", display: "Billing Date" },
    { name: "typeOfSale", display: "Type of sale" },
    { name: "quantity", display: "Quantity" },
    { name: "totalAmount", display: "Amount" }
  ];

  topbartext: TopbarText[] = [
    { key: "devicebillingsummary", displayText: "Device Billing Summary" }
  ];
  searchField = {
    typeOfSale: "",
    indentDateFrom: "",
    indentDateTill: ""
  };
  datalist: Array<DeviceBillingSummaryReportModel>;
  page: GridPage;
  _menuId: string;
  sort: SortClass;

  constructor(
    public tools: Tools,
    private objGlobal: global,
    private objreportservice: ReportService,
    private csvservice: ExporttocsvService,
    private auth: AuthService
  ) {
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    //this.objsimreturnservice.loadSIMReturnReason();

    this._menuId = this.tools.getMenuName("main/devicebillingsummaryreport");
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "indentDate";
    this.getReportData();
  }

  ngOnInit() {}
  getReportData() {
    let searchField = {};
    searchField["typeOfSale"] = this.searchField.typeOfSale;
    if (this.searchField.indentDateFrom || this.searchField.indentDateTill) {
      searchField["indentDate"] = new Array<Date>();
      searchField["indentDate"].push(this.searchField.indentDateFrom);
      searchField["indentDate"].push(this.searchField.indentDateTill);
    }
    this.objreportservice
      .getDeviceBillingSummary(this.page, searchField, this.sort)
      .subscribe(
        data => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get device billing summary report!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get device billing summary report!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  exportBillingReportSummaryForExcel() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    let searchField = {};
    searchField["typeOfSale"] = this.searchField.typeOfSale;
    if (this.searchField.indentDateFrom || this.searchField.indentDateTill) {
      searchField["indentDate"] = new Array<Date>();
      searchField["indentDate"].push(this.searchField.indentDateFrom);
      searchField["indentDate"].push(this.searchField.indentDateTill);
    }
    this.objreportservice
      .getDeviceBillingSummary(page, searchField, sort)
      .subscribe(
        data => {
          let obj: Array<IndentModel>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "devicebillingsummary"
            );
          } else {
            alert("Failed to get billing summary report!");
          }
        },
        error => {
          alert("Failed to get billing summary report!");
        }
      );
  }
  onExcelClick() {
    this.exportBillingReportSummaryForExcel();
  }
  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }
}
