import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { SimpleMessage } from "../framework/modules/util.class";
import { TransCategoryModel } from "../models/trans.category.model";

@Injectable()
export class TransCategoryService {
  private transCategoryList: TransCategoryModel[];
  constructor(private http: HttpClient, private appsetting: AppSettings) {}

  async getTransCategoriesList(): Promise<Array<TransCategoryModel>> {
    if (this.transCategoryList != undefined) {
      return this.transCategoryList;
    } else {
      return await this.getTranCategoiesApi()
        .toPromise()
        .then(res => {
          if (res["success"]) {
            this.transCategoryList = res["result"];
            return this.transCategoryList;
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Error while fetching transaction category list!";
            objmsg.btnClass = "btn-error";
            return this.transCategoryList;
          }
        });
    }
  }

  getTranCategoiesApi() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/transcategorylist", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }
}
