import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { global } from 'src/app/framework/modules/global';
import { Tools } from 'src/app/framework/modules/tools';
import { SimpleMessage } from 'src/app/framework/modules/util.class';
import { AuthService } from 'src/app/framework/services/auth.service';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { gridColumns, GridPage, GridtableComponent } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-merchant-portal-settlement-summary',
  templateUrl: './merchant-portal-settlement-summary.component.html',
  styleUrls: ['./merchant-portal-settlement-summary.component.css']
})
export class MerchantPortalSettlementSummaryComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  topbartext: TopbarText[] = [
    {
      key: "merchant-portal-settlement-summary",
      displayText: "SETTLEMENT SUMMARY - MERCHANT",
      class: "col-md-6",
      anchorClass: "h6",
    },
  ];
  searchField = {
    fromDate: undefined,
    tillDate: undefined
  };
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "DATETIME",
      columnFieldName: "tranDate",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "TOTAL TRANSACTION AMOUNT",
      columnFieldName: "transactionAmount",
      columnClasses: "c-col-pc-15 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "RENT",
      columnFieldName: "totalRent",
      columnClasses: "c-col-pc-15 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "DEDUCTIONS",
      columnFieldName: "deductionAmount",
      columnClasses: "c-col-pc-15 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "INCENTIVES",
      columnFieldName: "totalIncetiveAmount",
      columnClasses: "c-col-pc-15 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    },
    {
      columnHeader: "TOTAL SETLLED AMOUNT",
      columnFieldName: "settlementAmount",
      columnClasses: "c-col-pc-15 text-right pr-2",
      callbackFunction : this.tools.formateNumberD2
    }
  ];
  datalist: [];
  page: GridPage;
  _menuId: string;
  tranDate: any;
  constructor(public tools: Tools,
    private router: Router,
    private objGlobal: global,
    private reportService: ReportService,
    private auth: AuthService,
    private csvservice: ExporttocsvService) { 
        let dateFrom: Date = new Date();
        dateFrom.setDate(1);
        this.searchField.fromDate = dateFrom;
        this.searchField.tillDate = new Date();
        this.page = new GridPage();
        this.page.size = 20; //Default page size
        console.log(this.router.url.substring(1, this.router.url.length))
        this._menuId = this.tools.getMenuName(
            this.router.url.substring(1, this.router.url.length)
        );
        this.objGlobal.setActiveMenu(this._menuId);

    }

  ngOnInit() {
  }
  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }

  getReportData() {
 
    this.reportService
      .getMerchantPortalSettlementSummary(this.page, this.searchField)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
           
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get settlement summary data!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get settlement summary data!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  exportToCsv() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;

    this.reportService
      .getMerchantPortalSettlementSummary(page, this.searchField)
      .subscribe(
        (data) => {
          let obj: Array<any>;
          if (data["success"]) {
            obj = data["result"];
            this.csvservice.exportToCsv(
              obj,
              this.gridcols,
              undefined,
              "merchant-settlement-summary"
            );
          } else {
            alert("Failed to get settlement summary data!");
          }
        },
        (error) => {
          alert("Failed to get settlement summary data!");
        }
      );
  }
  onExcelClick() {
    this.exportToCsv();
  }

}
