import { Injectable } from "@angular/core";
import { RegionMasterModel } from '../models/region.master.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../framework/config/AppSettings';

@Injectable()
export class RegionMasterService {

   private regions : Array<RegionMasterModel>

   constructor(private http:HttpClient,private appsetting:AppSettings) {
      
   }

   async getRegions(refresh : boolean) :Promise<Array<RegionMasterModel>> {
      if(refresh || this.regions == undefined) {
         await this.getRegionsApi().then(data => {
            if(data["success"]) {
               this.regions=data["result"];
            } else {
               this.regions=undefined;
               console.error("Error in api while fetching regions");
            }
         });

      } 
      return this.regions;
   }

   async getRegionsApi() {
      let header = new HttpHeaders();
      return await this.http.get( this.appsetting.get("server-url") + '/api/regions',
      {headers : header, observe : "body", responseType : "json"})
      .map(res=>res).toPromise();
   }
}