import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { global } from 'src/app/framework/modules/global';
import { Tools } from 'src/app/framework/modules/tools';
import { SimpleMessage, SortClass } from 'src/app/framework/modules/util.class';
import { AuthService } from 'src/app/framework/services/auth.service';
import { ExporttocsvService } from 'src/app/framework/services/exporttocsv.service';
import { gridColumns, GridPage, GridtableComponent } from 'src/app/framework/util/components/gridtable/gridtable.component';
import { UPIQRCodeDetailsModel } from 'src/app/models/UPIQRCodeDetailsModel';
import { MerchantProfileService } from 'src/app/services/merchant-profile.service';
import { StaticQrCodesService } from 'src/app/services/static-qr-codes.service';
declare var jquery: any;
declare var $: any;
import * as jspdf from "jspdf";
import html2canvas from "html2canvas";
import { Observable, Observer } from 'rxjs';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-statice-qr-codes',
  templateUrl: './statice-qr-codes.component.html',
  styleUrls: ['./statice-qr-codes.component.css']
})
  
export class StaticeQrCodesComponent implements OnInit {
  @ViewChild(GridtableComponent, { static: true }) gridComp: GridtableComponent;
  gridcolsexport: gridColumns[] = [
    {
      columnHeader: "On Boarding Date",
      columnFieldName: "onBoardingDate",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Mobile Number",
      columnFieldName: "phoneNumber"
    },
    {
      columnHeader: "Login Id",
      columnFieldName: "loginId"
    },
    {
      columnHeader: "Merchant Id",
      columnFieldName: "mid"
    },
    {
      columnHeader: "Terminal Id",
      columnFieldName: "tid"
    },
    {
      columnHeader: "MCC Code",
      columnFieldName: "mcc"
    },
    {
      columnHeader: "State",
      columnFieldName: "state"
    },
    {
      columnHeader: "Merchant Name",
      columnFieldName: "companyName"
    },
    {
      columnHeader: "Address",
      columnFieldName: "address"
    },
    {
      columnHeader: "Merchant Activation Date",
      columnFieldName: "activationDate",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Merchant DeActivation Date",
      columnFieldName: "deActivationDate",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "Merchant Type",
      columnFieldName: "merchantType",
      callbackFunction : this.getMerchantType
    },
    {
      columnHeader: "Account No",
      columnFieldName: "accountNumber"
    },
    {
      columnHeader: "Merchant VPA ID",
      columnFieldName: "vpaId"
    },
    {
      columnHeader: "Branch Code",
      columnFieldName: "sourcingBranchCode"
    },
    {
      columnHeader: "Branch Name",
      columnFieldName: "sourcingBranchName"
    },
    {
      columnHeader: "Merchant Txn_Status",
      columnFieldName: "merchantTxnStatus"
    },
    {
      columnHeader: "Merchant Category",
      columnFieldName: "merchantCategory"
    },
    {
      columnHeader: "User Email",
      columnFieldName: "emailId"
    },
    {
      columnHeader: "Merchant Service",
      columnFieldName: "merchantService",
      callbackFunction : this.getMerchantServiceText
    }]
				
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "DATE TIME",
      columnFieldName: "datetime",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.formatDateToDDMMMYYHHMMSS
    },
    {
      columnHeader: "LAST UPDATED ON",
      columnFieldName: "lastUpdatedOn",
      columnClasses: "c-col-pc-14",
      callbackFunction: this.tools.formatDateToDDMMMYY
    },
    {
      columnHeader: "MERCHANT ID",
      columnFieldName: "merchantId",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "TERMINAL ID",
      columnFieldName: "terminalId",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "VPA ADDRESS",
      columnFieldName: "vpaAddress",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "REQUEST TYPE",
      columnFieldName: "requestType",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "CUB",
      columnFieldName: "cub",
      columnClasses: "c-col-pc-8",
    },
    {
      columnHeader: "QR",
      columnFieldName: "qrCodeUrl",
      callbackFunction: this.getQr,
      columnClasses: "c-col-pc-8",
      callClassFunction : this.terminalCellClass,
      callCellClickFunction : this.qrClick,
      _parentscope : this
    },
    {
      columnHeader: "",
      columnFieldName: "sourcingBranchCode",
      columnClasses : "c-col-pc-0"
    }
  ];
  sortFields = [
    { name: "datetime", display: "DATE TIME" },
    { name: "lastUpdatedOn", display: "DATE TIME" },
    { name: "merchantId", display: "MERCHANT ID" },
    { name: "terminalId", display: "TERMINAL ID" },
    { name: "vpaAddress", display: "VPA ADDRESS" },
    { name: "requestType", display: "REQUEST TYPE" },
    { name: "cub", display: "CUB" }
  ];

  topbartext: TopbarText[] = [
    {
      key: "static-qr-codes",
      displayText: "STATIC QR CODES",
      class: "col-md-5",
      anchorClass: "h6",
    },
  ];
  searchField = {
    dateFrom: undefined,
    dateTill: undefined,
    mid: undefined,
    tid: undefined,
    vpaAddress: undefined
  };
  datalist: Array<UPIQRCodeDetailsModel>;
  page: GridPage;
  _menuId: string;
  data: any = {};
  //isDetailScreen : boolean=false;

  sort: SortClass;
  id: number = 0;
  qrURL: string;
  vpaId: string;
  sourcingBranchCode: string;
  tid: string;
  terminalLocation: string;
  constructor(public tools: Tools,
  private objGlobal: global,
    private csvservice: ExporttocsvService,
    private auth: AuthService,
    private staticqrcodesservice: StaticQrCodesService,
    private ngbModalService: NgbModal,
    private router: Router,private merchantprofileservice : MerchantProfileService) {
    
    this.page = new GridPage();
    this.page.size = 20; //Default page size

    this._menuId = this.tools.getMenuName(
        this.router.url.substring(1, this.router.url.length)
    );
    this.objGlobal.setActiveMenu(this._menuId);
    this.sort = new SortClass();
    this.sort.sortDirection = "D";
    this.sort.sortFieldName = "datetime";
    this.searchField.dateFrom = new Date();
    this.searchField.dateTill = this.searchField.dateFrom;
    this.qrURL = "assets/images/blank.png";
  }

  ngOnInit() {
  }
  getReportData() {
    this.staticqrcodesservice.getStaticQrCodes(this.page, this.searchField, this.sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.datalist = data["result"].content;
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
            if (this.id == 0 && this.datalist.length > 0) {
              this.id = this.datalist[0].id;
            }
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get static qr codes report!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get static qr codes report!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  onExcelClick() {
    //this.exportTerminalActivationReportForExcel();
  }
  isAllowExport() {
    return this.auth.isPrivExists(this._menuId + "=>export");
  }

  getQr(val) {
    if (val) {
      return 'QR';
    }
  }

  terminalCellClass(val) {
    if (val && val != "") {
      return "link";
    }
  }

  qrClick(O, col, _scope) {
    _scope.data = O;
    _scope.vpaId = O.vpaAddress;
    _scope.sourcingBranchCode = O.sourcingBranchCode;
    _scope.terminalLocation = O.deviceLocation;
    _scope.tid = O.terminalId;
    if (O.qrCodeUrl && O.qrCodeUrl != "") {
           
      _scope.merchantprofileservice.getFileFromUrl(O.qrCodeUrl).subscribe(data => {
        const strFileNameArr = O.qrCodeUrl.split("/");
        const fileName = strFileNameArr[strFileNameArr.length - 1];
   
        let file: File = new File([data], fileName, { "type": data.type });
      
        var reader = new FileReader();
        if (file) {
          var mimeType = data.type;

          if (mimeType.match(/image\/*/) != null) {

            reader.readAsDataURL(file);
            reader.onload = (_event) => {
              const fileUrl = reader.result;
              _scope.qrURL = fileUrl;
              if (_scope.qrURL && _scope.qrURL != "") {
                $("#qrreview").modal({ backdrop: false });
              }
            };
          }
        }
      }, err => {
          console.error("Error to get qr code file");
      })
     
    } else {
      _scope.qrURL = "assets/images/blank.png";
    }
  }

  onQrCloseClick() {
    $("#qrreview").modal("hide");
  }

  downloadpdf() {
    var data = document.getElementById("qrcontent");
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, pageHeight);
      let fileName = this.sourcingBranchCode ? (this.sourcingBranchCode + "_") : "";
      fileName += this.tid ? (this.tid + "_") : "";
      fileName += "qr.pdf"
      pdf.save(fileName); // Generated PDF
    });
  }
  onExportClick() {
    this.exportCSV();
  }

  exportCSV() {
    let page: GridPage = new GridPage();
    page.size = 0;
    page.offset = 1;
    let sort: SortClass = new SortClass();
    this.staticqrcodesservice.getStaticQrCodes(page, this.searchField, sort)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.csvservice.exportToCsv(
              data["result"],
              this.gridcolsexport,
              undefined,
              "UPI Merchant Report"
            );
          } else {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            objmsg.message = "Failed to get static qr codes report!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        (err) => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          let objmsg: SimpleMessage = new SimpleMessage();
          objmsg.title = "Error";
          objmsg.message = "Failed to get static qr codes report!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }

  getMerchantType(val) {
    if (val === "S") {
      return "Small";
    } else if (val === "B" || val=="O") {
      return "Other";
    }
    return undefined;
  }

  getMerchantServiceText(val) {
    if (val === "R") {
      return "Retail";
    }
    if (val == "C") {
      return "Corporate";
    }
    return undefined;
  }
}
