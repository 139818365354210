import {
  MerchantModel,
  MerchantChannelLimitModel,
  CompanyFiles,
  MerchantFiles,
  PartnerFiles,
  MerchantTerminalModel,
  MerchantRiskModel,
  MerchantCompanyProfileModel,
  MerchantPartnerModel,
  MerchantBankDetailModel,
} from "src/app/models/merchant.model";
import {
  ValidMsg,
  FileModel,
  MARSResponseErrorMsg,
  SimpleMessage,
} from "src/app/framework/modules/util.class";
import { RegionMasterModel } from "src/app/models/region.master.model";
import { ChannelNetworkCodeValueModel } from "src/app/models/channel.network.codevalue.model";
import {
  AggregatorChannelNetwork,
  AggregatorDistributorModel,
} from "src/app/models/aggregator.distributor.model";
import { TransRatesModel } from "src/app/models/trans.rate";
import { MDRPlanModel } from "src/app/models/mdr.plan.model";
import { RentalPlanModel } from "src/app/models/rental.plan.model";
import { gridColumns } from "src/app/framework/util/components/simplegrid/simplegrid.component";
import { TopbarText } from "src/app/framework/components/topbar/topbar.component";
import { MCCModel } from "src/app/models/MCC.model";
import { EmployeeModel } from "src/app/models/employee.model";
import { BankModel } from "src/app/models/bank.model";
import { SIMStockModel } from "src/app/models/simstock.model";
import { DeviceStockModel } from "src/app/models/device.model";
import { Tools } from "src/app/framework/modules/tools";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
import { BankService } from "src/app/services/bank.service";
import { MerchantService } from "src/app/services/merchant.service";
import { TransCategoryService } from "src/app/services/transcategory.service";
import { AxisErrorResponse, MARSErrorResponse } from "src/app/models/mars.response.error.module";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { MDRPlanService } from "src/app/services/mdr.plan.service";
import { RentalplanService } from "src/app/services/rentalplan.service";


export class MerchantDetailParentComponenet {
  data: MerchantModel;
  _data: any;
  dataCompanyProfile: MerchantCompanyProfileModel;
  _dataCompanyProfile: MerchantCompanyProfileModel;
  merchantPartner: MerchantPartnerModel;
  _merchantPartner: MerchantPartnerModel;
  merchantPartnerList: Array<MerchantPartnerModel>;
  _merchantPartnerList: Array<MerchantPartnerModel>;
  merchantPartnerSelRowIdx: number = -1;
  merchantBank: MerchantBankDetailModel;
  _merchantBank: MerchantBankDetailModel;
  merchantRisk: MerchantRiskModel;
  _merchantRisk: MerchantRiskModel;
  merchantTerminalList: Array<MerchantTerminalModel>;
  _merchantTerminalList: Array<MerchantTerminalModel>;
  merchantTerminal: MerchantTerminalModel;
  _merchantTerminal: MerchantTerminalModel;

  merchantTerminalDevice: DeviceStockModel;
  _merchantTerminalDevice: DeviceStockModel;
  merchantTerminalSIM: SIMStockModel;
  _merchantTerminalSIM: SIMStockModel;
  merchantTerminalRentalPlan: RentalPlanModel;
  _merchantTerminalRentalPlan: RentalPlanModel;
  bank: BankModel;
  _bank: BankModel;
  institution: AggregatorDistributorModel;
  _institution: AggregatorDistributorModel;
  allinstitutions: Array<AggregatorDistributorModel>;
  aggregator: AggregatorDistributorModel;
  _aggregator: AggregatorDistributorModel;
  supermerchant: AggregatorDistributorModel;
  _superMerchant: AggregatorDistributorModel;
  distributor: AggregatorDistributorModel;
  _distributor: AggregatorDistributorModel;
  selsalesperson: Array<EmployeeModel>;
  copyaddress: boolean = false;

  selPartnerIndex: number = -1;
  _isSelPartnerFileModified: boolean = false;
  _isPartnersModified: boolean = false;
  selTerminalIndex: number = -1;
  _isTerminalsModified: boolean = false;

  merchantTransRates: Array<TransRatesModel>;
  _merchantTransRates: Array<TransRatesModel>;
  _isModified: boolean = false;
  aggregatorDistributorList: Array<AggregatorDistributorModel> = new Array<
    AggregatorDistributorModel
  >();
  terminalType: Array<string>;
  mccDropdownSettings = {
    singleSelection: true,
    idField: "mccCode",
    textField: "description",
    allowSearchFilter: true,
    maxHeight: 150,
    closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: "",
  };
  mcclist: Array<MCCModel>;
  selMcc: Array<MCCModel>;

  companyprofiletopbartext: TopbarText[] = [
    { key: "companyprofiledefault", displayText: "Profile" },
    { key: "companyprofilefiles", displayText: "Files" },
    { key: "companyprofilepartner", displayText: "Partners" },
  ];
  companyprofileactivetabpartner: boolean = false;
  companyprofileactivetabfiles: boolean = false;
  companytabactive = "Profile";
  terminalactivetabdetail: boolean = false;
  partnergridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "First Name",
      columnFieldName: "firstName",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Last Name",
      columnFieldName: "lastName",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Company Name",
      columnFieldName: "companyName",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.getGridCompanyName,
      _parentscope: this,
    },
    {
      columnHeader: "Aadhaar",
      columnFieldName: "aadhaar",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "PAN",
      columnFieldName: "pan",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "GST",
      columnFieldName: "gst",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Passport",
      columnFieldName: "passportNumber",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Address",
      columnFieldName: "businessAddress",
      columnClasses: "c-col-pc-20",
    },
  ];
  terminalgridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Terminal ID",
      columnFieldName: "tid",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Location",
      columnFieldName: "registeredAddress",
      columnClasses: "c-col-pc-25",
    },
    {
      columnHeader: "Model",
      columnFieldName: "device.product.modelName",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "Device Sr No",
      columnFieldName: "device.deviceSerialNumber",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "Rental Plan",
      columnFieldName: "rentalPlan",
      columnClasses: "c-col-pc-15",
      _parentscope: this,
      callbackFunction: this.getRentalPlanName,
    },
    {
      columnHeader: "Status",
      columnFieldName: "status",
      callbackFunctionRowPrm : this.getTerminalStatusGrid,
      //callbackFunction: this.getStatusText,
      columnClasses: "c-col-pc-15",
      _parentscope : this
    }
  ];
  mdrplanlist: Array<MDRPlanModel>;
  rentalplanlist: Array<RentalPlanModel>;
  mdrPlanTransRates: Array<TransRatesModel>;
  _newMdrPlanTransRates: Array<TransRatesModel>;
  _mdrPlanTransRates: Array<TransRatesModel>;
  _isCompanyFileModified: boolean = false;
  _isMerchantFileModified: boolean = false;
  files: Array<FileModel>;

  partnerfiles: PartnerFiles;
  partnerfileslist: Array<PartnerFiles>;

  merchantfiles: MerchantFiles;
  _downloadedMerchantFiles: MerchantFiles;
  _downloadedCompanyFiles: CompanyFiles;
  companyfiles: CompanyFiles;
  previewFileType: string;
  imgURL: any;
  pdf: boolean = false;
  arrAggregatorChannelNetwork: Array<AggregatorChannelNetwork>;

  transactionLimitSet: Array<MerchantChannelLimitModel>;
  transactionSet: Array<ChannelNetworkCodeValueModel>;
  regions: Array<RegionMasterModel>;

  deviceStockValidation: ValidMsg;
  simStockValidation: ValidMsg;
  terminalStatusValues: any;

  isNewMerchant: boolean = true;
  isNewTerminal: boolean = true;
  constructor(
    public tools: Tools,
    public aggregatordistributorservice: AggregatorDistributorService,
    public bankservice: BankService,
    public merchantservice: MerchantService,
    public transCategoryService: TransCategoryService,
    public mdrplanservice: MDRPlanService,
    public rentalplanservice: RentalplanService
  ) {
    this.terminalStatusValues = this.merchantservice.terminalStatusValues;
    this.loadMDRandRentalPlanList();
  }
  collectData() {
    if (this.bank.bankId) {
      this.data.sponsorBank = this.bank;
    } else {
      this.data.sponsorBank = undefined;
    }
    if (
      this.selsalesperson &&
      this.selsalesperson.length > 0 &&
      this.selsalesperson[0] &&
      this.selsalesperson[0].empCode
    ) {
      this.data.salesPerson = this.selsalesperson[0];
    } else {
      this.data.salesPerson = undefined;
    }
    if (this.institution.id != undefined) {
      this.data.institution = this.institution;
    } else {
      this.data.institution = undefined;
    }
    if (this.aggregator.id != undefined) {
      this.data.aggregator = this.aggregator;
    } else {
      this.data.aggregator = undefined;
    }
    if (this.supermerchant.id != undefined) {
      this.data.superMerchant = this.supermerchant;
    } else {
      this.data.superMerchant = undefined;
    }
    if (this.distributor.id != undefined) {
      this.data.distributor = this.distributor;
    } else {
      this.data.distributor = undefined;
    }
    if (this.selMcc && this.selMcc[0] && this.selMcc[0].mccCode) {
      if (!this.dataCompanyProfile) {
        this.dataCompanyProfile = new MerchantCompanyProfileModel();
      }
      this.dataCompanyProfile.companyMCC = this.selMcc[0].mccCode;
    } else {
      if (this.dataCompanyProfile) {
        this.dataCompanyProfile.companyMCC = undefined;
      }
    }
    if (
      this.dataCompanyProfile &&
      Object.keys(this.dataCompanyProfile).length > 0
    ) {
      this.data.companyProfile = this.dataCompanyProfile;
    } else {
      this.data.companyProfile = undefined;
    }
    if (this.merchantPartnerList && this.merchantPartnerList.length > 0) {
      this.data.partners = this.merchantPartnerList;
    } else {
      this.data.partners = undefined;
    }
    if (this.merchantTerminalList && this.merchantTerminalList.length > 0) {
      this.data.terminal = this.merchantTerminalList;
    } else {
      this.data.terminal = undefined;
    }
    if (this.merchantBank && Object.keys(this.merchantBank).length > 0) {
      this.data.bankDetail = this.merchantBank;
    } else {
      this.data.bankDetail = undefined;
    }
    this.data.transRates = this.mdrPlanTransRates;

    if (this.transactionLimitSet && this.transactionLimitSet.length > 0) {
      if (!this.merchantRisk) {
        this.merchantRisk = new MerchantRiskModel();
      }
      this.merchantRisk.transactionlimit = undefined;
      this.merchantRisk.transactionlimit = this.transactionLimitSet;
    } else {
      this.merchantRisk.transactionlimit = undefined;
    }
    if (this.transactionSet && this.transactionSet.length > 0) {
      if (!this.merchantRisk) {
        this.merchantRisk = new MerchantRiskModel();
        this.merchantRisk.transactionset = undefined;
      }
      this.merchantRisk.transactionset = undefined;
      this.removedTranSetNotExistForAggregator();
      this.merchantRisk.transactionset = this.transactionSet;
    } else {
      this.merchantRisk.transactionset = undefined;
    }
    if (this.merchantRisk && Object.keys(this.merchantRisk).length > 0) {
      this.data.risk = this.merchantRisk;
    } else {
      this.data.risk = undefined;
    }
  }
  async loadMDRandRentalPlanList() {
    this.mdrplanlist = await this.mdrplanservice.loadMDRPlanDetailList();
    this.rentalplanlist = await this.rentalplanservice.loadRentalPlanDetailList();
  }
  distributeData(copyinit: boolean) {
    if (this.data && this.data.agreementDate) {
      this.data.agreementDate = new Date(this.data.agreementDate);
    }
    if (this.data && this.data.applicationDate) {
      this.data.applicationDate = new Date(this.data.applicationDate);
    }
    if (this.data && this.data.passportExpiryDate) {
      this.data.passportExpiryDate = new Date(this.data.passportExpiryDate);
    }
    if (this.data.sponsorBank) {
      this.bank = this.data.sponsorBank;
    } else {
      this.bank = new BankModel();
    }
    this.selsalesperson = [this.data.salesPerson];
    if (this.data.institution) {
      this.institution = this.data.institution;
    } else {
      this.institution = new AggregatorDistributorModel();
    }
    if (this.data.aggregator) {
      this.aggregator = this.data.aggregator;
      this.getAggregatorChannelNetwork();
    } else {
      this.aggregator = new AggregatorDistributorModel();
    }
    if (this.data.superMerchant) {
      this.supermerchant = this.data.superMerchant;
    } else {
      this.supermerchant = new AggregatorDistributorModel();
    }
    if (this.data.distributor) {
      this.distributor = this.data.distributor;
    } else {
      this.distributor = new AggregatorDistributorModel();
    }
    if (this.data.companyProfile) {
      this.dataCompanyProfile = this.data.companyProfile;
      if (this.dataCompanyProfile.comapnyEstablishYear) {
        this.dataCompanyProfile.comapnyEstablishYear = new Date(
          this.dataCompanyProfile.comapnyEstablishYear
        );
      }
      if (this.dataCompanyProfile.companyRegistrationDate) {
        this.dataCompanyProfile.companyRegistrationDate = new Date(
          this.dataCompanyProfile.companyRegistrationDate
        );
      }
    } else {
      this.dataCompanyProfile = new MerchantCompanyProfileModel();
    }
    this.getTerminalTypes();
    this.selMcc = this.mcclist.filter(
      (obj) => obj.mccCode == this.dataCompanyProfile.companyMCC
    );
    this.merchantPartnerList = this.data.partners;
    this.merchantTerminalList = this.data.terminal;
    if (this.data.bankDetail) {
      this.merchantBank = this.data.bankDetail;
    } else {
      this.merchantBank = new MerchantBankDetailModel();
    }
    //this.onSelectMDRPlan();
    if (this.data.transRates) {
      //this.mdrPlanTransRates = this.data.transRates;
      this.populateMDRRates(this.data.transRates);
      if (this.data.merchantStatus == "BB" && this.data.bankmerchant) {
        this.bankMerchantMDRDistribution();
      }
    } else {
      this.mdrPlanTransRates = this._newMdrPlanTransRates;
      if (this.data.merchantStatus == "BB" && this.data.bankmerchant) {
        this.bankMerchantMDRDistribution();
      }
    }
    if (this.data.risk) {
      this.merchantRisk = this.data.risk;
      if (this.merchantRisk.transactionlimit) {
        this.transactionLimitSet = this.tools.copyFullObject(
          this.merchantRisk.transactionlimit
        );
      }
      if (this.merchantRisk.transactionset) {
        this.transactionSet = this.tools.copyFullObject(
          this.merchantRisk.transactionset
        );
      }
    } else {
      this.transactionLimitSet = new Array<MerchantChannelLimitModel>();
      this.transactionSet = new Array<ChannelNetworkCodeValueModel>();
    }
    if (this.merchantPartnerList) {
      if (
        this.selPartnerIndex < this.merchantPartnerList.length &&
        this.selPartnerIndex > -1
      ) {
        this.merchantPartner = this.merchantPartnerList[this.selPartnerIndex];
      } else {
        this.selPartnerIndex = -1;
        this.merchantPartner = new MerchantPartnerModel();
      }
    } else {
      this.selPartnerIndex = -1;
      this.merchantPartner = new MerchantPartnerModel();
    }
    if (copyinit) {
      this.copyInitData();
    }
  }

  removedTranSetNotExistForAggregator() {
    if (this.transactionSet && this.transactionSet.length > 0) {
      let found = false;
      for (let i = 0; i < this.transactionSet.length; ) {
        found = false;
        if (this.arrAggregatorChannelNetwork) {
          for (
            let j = 0;
            j < this.arrAggregatorChannelNetwork.length && !found;
            j++
          ) {
            if (this.arrAggregatorChannelNetwork[j].transactionSet) {
              for (
                let k = 0;
                k < this.arrAggregatorChannelNetwork[j].transactionSet.length &&
                !found;
                k++
              ) {
                if (
                  this.arrAggregatorChannelNetwork[j].transactionSet[k].code ==
                  this.transactionSet[i].code
                ) {
                  found = true;
                }
              }
            }
          }
        }
        if (found) {
          i++;
        } else {
          this.transactionSet.splice(i, 1);
        }
      }
    }
  }
  bankMerchantMDRDistribution() {
    if (this.data && this.data.bankmerchant) {
      let allrootItem = this.mdrPlanTransRates.filter(
        (obj) => !obj.transCategory.parentId
      );
      if (allrootItem && allrootItem.length > 0) {
        allrootItem.forEach((obj) => {
          if (
            obj.transCategory.category == "D" &&
            obj.transCategory.subcategory == "domesticDebitUpTo2000"
          ) {
            obj.mmPercent = this.data.bankmerchant.debitCardLessThan2kRate;
          } else if (
            obj.transCategory.category == "D" &&
            obj.transCategory.subcategory == "domesticDebitAbove2000"
          ) {
            obj.mmPercent = this.data.bankmerchant.debitCardGreaterThan2kRate;
          } else if (
            obj.transCategory.category == "C" &&
            obj.transCategory.subcategory == "classicOrGoldOrElectron"
          ) {
            obj.mmPercent = this.data.bankmerchant.creditCardNonPremiumRate;
          } else if (
            obj.transCategory.category == "C" &&
            obj.transCategory.subcategory == "platinumOrRewards"
          ) {
            obj.mmPercent = this.data.bankmerchant.creditCardPremiumRate;
          } else if (
            obj.transCategory.category == "C" &&
            (obj.transCategory.subcategory ==
              "businessOrCorporateOrcommercialOrpurchasing" ||
              obj.transCategory.subcategory == "signatureOrInfiniteOrothers")
          ) {
            obj.mmPercent = this.data.bankmerchant.creditCardSuperPremiumRate;
          } else if (
            obj.transCategory.category == "C" &&
            (obj.transCategory.subcategory == "internationalDebitCard" ||
              obj.transCategory.subcategory == "internationalCreditCard")
          ) {
            obj.mmPercent = this.data.bankmerchant.internationalCardRate;
          } else if (
            obj.transCategory.category == "O" &&
            obj.transCategory.subcategory == "cashAtPos"
          ) {
            obj.mmPercent = this.data.bankmerchant.internationalCardRate;

            let childItems = this.mdrPlanTransRates.filter(
              (objchild) =>
                objchild.transCategory.parentId == obj.transCategory.id
            );
            if (childItems && childItems.length > 0) {
              childItems.forEach((child) => {
                child.mmPercent = this.data.bankmerchant.cashAtPOSRate;
              });
            }
          }
        });
      }
    }
  }
  copyInitData() {
    this._data = this.tools.copyFullObject(this.data);
    this._dataCompanyProfile = this.tools.copyFullObject(
      this.dataCompanyProfile
    );
    this._bank = this.tools.copyFullObject(this.bank);
    this._institution = this.tools.copyFullObject(this.institution);
    this._aggregator = this.tools.copyFullObject(this.aggregator);
    this._superMerchant = this.tools.copyFullObject(this.supermerchant);
    this._distributor = this.tools.copyFullObject(this.distributor);
    this._merchantBank = this.tools.copyFullObject(this.merchantBank);
    this._merchantRisk = this.tools.copyFullObject(this.merchantRisk);
    this._mdrPlanTransRates = this.tools.copyFullObject(this.mdrPlanTransRates);
  }

  copyInitDataNull() {
    this._data = undefined;
    this._dataCompanyProfile = undefined;
    this._bank = undefined;
    this._institution = undefined;
    this._aggregator = undefined;
    this._superMerchant = undefined;
    this._distributor = undefined;
    this._merchantBank = undefined;
    this._merchantRisk = undefined;
    this._mdrPlanTransRates = undefined;
  }
  async getAggregatorChannelNetwork() {
    this.arrAggregatorChannelNetwork = await this.aggregatordistributorservice.getAggregatorChannelNetwork(
      this.aggregator.id
    );
    this.setChannelLimitList();
  }

  setChannelLimitList() {
    let channellist = new Array<MerchantChannelLimitModel>();
    if (this.arrAggregatorChannelNetwork) {
      for (
        let i: number = 0;
        i < this.arrAggregatorChannelNetwork.length;
        i++
      ) {
        let channelName = this.arrAggregatorChannelNetwork[i].channel;
        let missing = true;
        for (let k: number = 0; k < channellist.length && missing; k++) {
          if (channelName == channellist[k].channel) {
            missing = false;
          }
        }
        if (missing) {
          let objchannel = new MerchantChannelLimitModel();
          objchannel.channel = this.arrAggregatorChannelNetwork[i].channel;
          let nofound = true;
          if (this.transactionLimitSet) {
            for (
              let j: number = 0;
              j < this.transactionLimitSet.length && nofound;
              j++
            ) {
              if (this.transactionLimitSet[j].channel == objchannel.channel) {
                objchannel.maxUsageDaily = this.transactionLimitSet[
                  j
                ].maxUsageDaily;
                objchannel.maxUsageMonthly = this.transactionLimitSet[
                  j
                ].maxUsageMonthly;
                objchannel.maxUsageWeekly = this.transactionLimitSet[
                  j
                ].maxUsageWeekly;
                objchannel.maximum = this.transactionLimitSet[j].maximum;
                objchannel.minimum = this.transactionLimitSet[j].minimum;
                nofound = false;
              }
            }
          }
          if (nofound) {
            objchannel.maxUsageDaily = 30000;
            objchannel.maxUsageWeekly = 200000;
            objchannel.maxUsageMonthly = 6000000;
            objchannel.minimum = 1;
            objchannel.maximum = 30000;
          }
          channellist.push(objchannel);
        }
      }
    }
    //console.log(channellist);
    this.transactionLimitSet = channellist;
  }
  getStatusText(v: string) {
    switch (v) {
      case "PB": {
        return "Pre-Boareded";
      }
      case "S": {
        return "Boarding";
      }
      case "R": {
        return "Ready for approval";
      }
      case "A": {
        return "Approved";
      }
      case "J": {
        return "Rejected";
      }
      case "H": {
        return "Hold approval";
      }
      case "C": {
        return "Boarded";
      }
      case "I":
      case "D" : {
        return "Deactivated";
      }
      case "F": {
        return "Failed to update in MARS";
      }
      case "P": {
        return "Pending";
      }
      case "E": {
        return "Mars Rejection";
      }
      case "T": {
        return "Waiting for Activation";
      }
      case "V": {
        return "Activation/Deactivation Request Sent"
      }
      default:
        return undefined;
    }
  }

  getRentalPlanName(v: number, parentscope: any) {
    let rentalPlanName: string;
    if (parentscope.rentalplanlist) {
      const selrentalplan = parentscope.rentalplanlist.filter(
        (itm) => itm.code == v
      );
      if (selrentalplan && selrentalplan.length > 0) {
        rentalPlanName = selrentalplan[0].name;
      } else {
        rentalPlanName = undefined;
      }
    }
    return rentalPlanName;
  }
  getGridCompanyName(val, parentscope) {
    return parentscope.data.companyName;
  }

  showTerminalMARSResponseError() {
    if (this.merchantTerminal.merchantTerminalId) {
      this.merchantservice
        .getMerchantTerminalMARSResApi(this.merchantTerminal.merchantTerminalId)
        .subscribe(
          (res) => { 
            const axisErrData:any=res
            if (res["result"]) {
              const data: AxisErrorResponse = axisErrData.result.result.Data.MerchantOnboardResponseBody;
              let objMsg: MARSResponseErrorMsg = new MARSResponseErrorMsg();
              objMsg.forEntity = this.data.companyName;
              objMsg.errorMsg = data;
              this.tools.marsResponseErrorMessage(objMsg);
            } else {
              console.error(res["message"]);
            }
          },
          (err) => {
            console.error("Error to get MARS Reponse Errors");
          }
        );
    }
  }

  terminalMARUpdate(source: string) {
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    this.merchantservice
      .merchantTerminalMARSUpdate(this.merchantTerminal.merchantTerminalId)
      .subscribe(
        (res) => {
          if (res["success"]) {
            objmsg.title = "Success";
            objmsg.btnClass = "btn-success";
            objmsg.message = objmsg.message =
              "Terminal " +
              (source == "A" ? "approved and" : "") +
              " updated in Axis successfully!";
            this.merchantTerminal.status = "P";

          } else {
            objmsg.title = "Error";
            objmsg.message =
              "Terminal " +
              (source == "A" ? "approved but" : "") +
              " Failed to update in Axis!";
            objmsg.btnClass = "btn-error";
          }
          const terminalres = res["result"];
          if (terminalres) {
            if (terminalres["terminalRefCode"]) {
              this.merchantTerminal.terminalRefCode =
                terminalres["terminalRefCode"];
            }
            if (terminalres["status"]) {
              this.merchantTerminal.status = terminalres["status"];
            }
          }
          this.tools.simpleMessage(objmsg);
        },
        (err) => {
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Error";
          objmsg.message = "Error in MARS Update!";
          objmsg.btnClass = "btn-error";
        }
      );
  }
  updateMARSUpdateResponse(result) {
    if (result) {
      if (result.merchant) {
        if (result.merchant.merchantStatus) {
          this.data.merchantStatus = result.merchant.merchantStatus;
          this.data.merchantRefCode = result.merchant.merchantRefCode;
        }
      }
      if (result.terminal && result.terminal.length > 0) {
        for (let i: number = 0; i < result.terminal.length; i++) {
          let terminal = result.terminal[i];
          if (terminal && terminal.merchantTerminalId) {
            let notfound: boolean = true;
            for (
              let j: number = 0;
              j < this.merchantTerminalList.length && notfound;
              j++
            ) {
              if (
                terminal[i].merchantTerminalId ==
                this.merchantTerminalList[j].merchantTerminalId
              ) {
                notfound = false;
                if (terminal.status) {
                  this.merchantTerminalList[j].status = terminal.status;
                }
                if (terminal.terminalRefCode) {
                  this.merchantTerminalList[j].terminalRefCode =
                    terminal.terminalRefCode;
                }
              }
            }
          }
        }
      }
    }
  }
  isMerchantAadhaarExists() {
    return (
      (this.data && this.data.aadhaarFileName) ||
      (this.merchantfiles && this.merchantfiles.aadhaarFile)
    );
  }
  isMerchantPanFileExists() {
    return (
      (this.data && this.data.panFileName) ||
      (this.merchantfiles && this.merchantfiles.panFile)
    );
  }

  isMerchantGstFileExists() {
    return (
      (this.data && this.data.gstFileName) ||
      (this.merchantfiles && this.merchantfiles.gstFile)
    );
  }

  isMerchantVoterIdFileExists() {
    return (
      (this.data && this.data.voterIdFileName) ||
      (this.merchantfiles && this.merchantfiles.voterIdFile)
    );
  }

  isCompanyBrDirListExists() {
    return (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyBrDirListFileName) ||
      (this.companyfiles && this.companyfiles.brDirListFile)
    );
  }
  isCompanyTrustDeedExists() {
    return (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyTrustDeedFileName) ||
      (this.companyfiles && this.companyfiles.trustDeedFile)
    );
  }
  isMerchantOtherIdFileExists() {
    return (
      (this.data && this.data.otherIdFileName) ||
      (this.merchantfiles && this.merchantfiles.otherIdFile)
    );
  }

  isMerchantCancelledChequeExists() {
    return (
      (this.data && this.data.cancelledChequeFileName) ||
      (this.merchantfiles && this.merchantfiles.cancelledChequeFile)
    );
  }

  isMerchantPassportxists() {
    return (
      (this.data && this.data.passportFileName) ||
      (this.merchantfiles && this.merchantfiles.passportFile)
    );
  }
  isCompanyCancelledChequeExists() {
    let bresult = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyCancelledChequeFileName) ||
      (this.companyfiles && this.companyfiles.cancelledChequeFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isCompanyAddressProofFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyAddressProofFileName) ||
      (this.companyfiles && this.companyfiles.addressProofFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isCompanyEstablishmentLicenseFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyEstablishmentLicenseFileName) ||
      (this.companyfiles && this.companyfiles.establishmentLicenseFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isPartnershipDeedFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyPartnershipDeedFileName) ||
      (this.companyfiles && this.companyfiles.partnershipDeedFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isCertificateOfIncorporationFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyCertificateOfIncorporationFileName) ||
      (this.companyfiles && this.companyfiles.certificateOfIncorporationFile)
    ) {
      bresult = true;
    }
    return bresult;
  }

  isMemorandumFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyMemorandumFileName) ||
      (this.companyfiles && this.companyfiles.memorandumFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isOtherDoc1FileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyOtherDoc1FileName) ||
      (this.companyfiles && this.companyfiles.otherDoc1File)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isOtherDoc2FileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyOtherDoc2FileName) ||
      (this.companyfiles && this.companyfiles.otherDoc2File)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isCompanyGstFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile && this.dataCompanyProfile.companyGstFileName) ||
      (this.companyfiles && this.companyfiles.gstFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isCompanyBrDirFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyBrDirListFileName) ||
      (this.companyfiles && this.companyfiles.brDirListFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isCompanyTrustDeedFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyTrustDeedFileName) ||
      (this.companyfiles && this.companyfiles.trustDeedFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isCompanyElectricityBillExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyElectricityBillFileName) ||
      (this.companyfiles && this.companyfiles.electricityBillFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isCompanyTelephoneBillFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyTelephoneBillFileName) ||
      (this.companyfiles && this.companyfiles.telephoneBillFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isCompanyPhotographFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile &&
        this.dataCompanyProfile.companyPhotgraphFileName) ||
      (this.companyfiles && this.companyfiles.photographFile)
    ) {
      bresult = true;
    }
    return bresult;
  }
  isCompanyPanProfileFileExists() {
    let bresult: boolean = false;
    if (
      (this.dataCompanyProfile && this.dataCompanyProfile.companyPanFileName) ||
      (this.companyfiles && this.companyfiles.panFile)
    ) {
      bresult = true;
    }
    return bresult;
  }

  selectedFilesCodes() {
    let selectfilescodes: Array<string> = new Array<string>();
    if (this.isMerchantAadhaarExists()) {
      selectfilescodes.push("AADHAAR");
    }
    if (this.isCompanyAddressProofFileExists()) {
      selectfilescodes.push("ADDRESSPROOF");
    }
    if (this.isCompanyCancelledChequeExists()) {
      selectfilescodes.push("CANCELLEDCHEQUE");
    }
    if (this.isCertificateOfIncorporationFileExists()) {
      selectfilescodes.push("CERTIFICATE_INCORPORATION");
    }
    if (this.isCompanyEstablishmentLicenseFileExists()) {
      selectfilescodes.push("ESTABLISHMENT_LICENSE");
    }
    if (this.isCompanyGstFileExists()) {
      selectfilescodes.push("GST");
    }
    if (this.isMemorandumFileExists()) {
      selectfilescodes.push("MEMORANDUM");
    }
    if (this.isCompanyPanProfileFileExists()) {
      selectfilescodes.push("PAN");
    }
    if (this.isPartnershipDeedFileExists()) {
      selectfilescodes.push("PARTNERSHIP_DEED");
    }
    if (this.isMerchantVoterIdFileExists()) {
      selectfilescodes.push("VOTERID");
    }
    if (this.isCompanyBrDirFileExists()) {
      selectfilescodes.push("BOARDRESOLUTION_LISTOFDIRECTORS");
    }
    if (this.isCompanyTrustDeedFileExists()) {
      selectfilescodes.push("TRUST_DEED");
    }
    if (this.isCompanyTelephoneBillFileExists()) {
      selectfilescodes.push("TELEPHONEBILL");
    }
    if (this.isCompanyElectricityBillExists()) {
      selectfilescodes.push("ELECTRICITYBILL");
    }
    if (this.isCompanyPhotographFileExists()) {
      selectfilescodes.push("PHOTOGRAPH");
    }
    if (
      this.dataCompanyProfile &&
      this.dataCompanyProfile.otherDoc1 &&
      this.isOtherDoc1FileExists()
    ) {
      selectfilescodes.push(this.dataCompanyProfile.otherDoc1);
    }
    if (
      this.dataCompanyProfile &&
      this.dataCompanyProfile.otherDoc2 &&
      this.isOtherDoc2FileExists()
    ) {
      selectfilescodes.push(this.dataCompanyProfile.otherDoc2);
    }
    return selectfilescodes;
  }

  getDocTitle(docType: string) {
    return this.merchantservice.docTypeDisplayName(
      this.dataCompanyProfile.businessEntityType,
      docType
    );
  }

  isDocTypeVisible(docType: string) {
    return this.merchantservice.docTypeVisible(
      this.dataCompanyProfile.businessEntityType,
      docType
    );
  }

  getTimeFormatMessage() {
    return "Enter valid 24 Hr time format HH.MM e.g. 10.30";
  }

  btnLabelTerminalUpdate() {
    return this.selTerminalIndex >= 0 ? "Update Terminal" : "Add Terminal";
  }

  passportExpiryDateConfig() {
    return this.tools.getBsDatePickerConfig(
      true,
      new Date("01-Jan-1900"),
      false
    );
  }

  allowFutureDateConfig() {
    return this.tools.getBsDatePickerConfig(
      true,
      new Date("01-Jan-1900"),
      false
    );
  }
  async onSelectMDRPlan() {
    const selmdrplan = this.mdrplanlist.filter(
      (itm) => itm.code == this.data.merchantMDRPlan
    );
    if (selmdrplan && selmdrplan.length > 0) {
      this.populateMDRRates(selmdrplan[0].transRates);
      this.data.merchantCategory = selmdrplan[0].merchantCategory;
      this.data.partnerSharing = selmdrplan[0].partnerSharing;
    } else {
      this.mdrPlanTransRates = this._newMdrPlanTransRates;
      this.data.merchantCategory = undefined;
      this.data.partnerSharing = undefined;
    }
  }
  populateMDRRates(transrate: TransRatesModel[]) {
    //console.log("test");
    if (
      transrate &&
      transrate.length > 0 &&
      this.mdrPlanTransRates &&
      this.mdrPlanTransRates.length > 0
    ) {
      for (let i = 0; i < this.mdrPlanTransRates.length; i++) {
        let trate = transrate.find((itm) => {
          return (
            itm.transCategory.id == this.mdrPlanTransRates[i].transCategory.id
          );
        });
        if (trate) {
          this.mdrPlanTransRates[i].mmFixed = trate.mmFixed;
          this.mdrPlanTransRates[i].mmMax = trate.mmMax;
          this.mdrPlanTransRates[i].mmPercent = trate.mmPercent;
          this.mdrPlanTransRates[i].psFixed = trate.psFixed;
          this.mdrPlanTransRates[i].psMax = trate.psMax;
          this.mdrPlanTransRates[i].psPercent = trate.psPercent;
          this.mdrPlanTransRates[i].transCategory.invisible =
            trate.transCategory.invisible;
        }
      }
    }
    //console.log(this.mdrPlanTransRates);
  }

  getTerminalTypes() {
    this.terminalType = [];
    if (this.dataCompanyProfile.companyMerchantType) {
      this.terminalType = this.merchantservice.getTerminalTypeOnMerchantType(this.dataCompanyProfile.companyMerchantType);
    }
    //this.terminalType = result;
  }

    showTerminalStatus() {
    return this.getTerminalStatus(this.merchantTerminal.status, this.merchantTerminal.marsStatus);
  }

  getTerminalStatusGrid(terminal: MerchantTerminalModel, _this) {
    return _this.getTerminalStatus(terminal.status, terminal.marsStatus);
  }

  getTerminalStatus(terminalSatus : string,marsStatus : string) {
    let result = "";
    if (terminalSatus) {
      const terminalstatus = terminalSatus
        ? terminalSatus
        : "";
      const terminalMarsStatus = marsStatus
        ? marsStatus
        : "";
      const terminalCombinedStatus =
        terminalstatus +
        (terminalMarsStatus != "" ? "#" + terminalMarsStatus : "");

      let mstatus = this.terminalStatusValues.find(
        (itm) => itm.value == terminalCombinedStatus
      );
      if (!mstatus) {
        mstatus = this.terminalStatusValues.find(
          (itm) => itm.value == terminalstatus
        );
      }
      if (mstatus) {
        result = mstatus.text;
      }
    }
    return result;
  }
}
