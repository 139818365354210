import { Injectable } from "@angular/core";
import {
  MerchantModel,
  MerchantFiles,
  CompanyFiles,
  PartnerFiles,
  MerchantTerminalModel,
} from "../models/merchant.model";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { throwError } from "rxjs";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass, FileModel } from "../framework/modules/util.class";
import { Tools } from "../framework/modules/tools";
import { RentalPlanModel } from "../models/rental.plan.model";
import {
  MerchantDocumentTemplateModel,
  DocumentTypeCodeName,
  DocumentTypeBusinessEntity,
} from "../models/merchantdocumenttemplatemodel";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { stringify } from "@angular/compiler/src/util";

@Injectable()
export class MerchantService {
  configLoaded: boolean = false;
  merchantNameList: Array<MerchantModel>;
  rentalPlanList: Array<RentalPlanModel>;
  companyBusinessType: any;
  merchantType: any;
  typeOfStatement: any;
  bankSettlementBy: any;
  bankAccountType: any;
  paymentMode: any;
  merchantmdrplan: any;
  rentalPlan: any;
  terminalType: any;
  deviceOwnedBy: any;
  documenttemplates: Array<MerchantDocumentTemplateModel>;
  merchantStatusValues = [
    { value: "", text: "-" },
    { value: "PB", text: "Pre-Boareded" },
    /*{ value: "B", text: "Refer Back" },*/
    { value: "S", text: "Boarding" },
    { value: "S#B", text: "Refer Back" },
    { value: "R", text: "Ready for approval" },
    { value: "A", text: "Approved" },
    { value: "C", text: "Boarded" },
    { value: "F", text: "MARS Update Failed" },
    { value: "P", text: "MARS Resp. Pending" },
    { value: "E", text: "MARS Rejected" },
    { value: "J", text: "Rejected" },
    { value: "H", text: "Hold Approval" },
  ];
  terminalStatusValues = [
    { value: "PB", text: "Pre-Boareded" },
    { value : "S",text :"Boarding"},
    { value : "R",text :"Ready for approval"},  
    { value : "A",text :"Approved"},  
    { value : "J",text :"Rejected"},  
    { value : "J",text :"Hold approval"},  
    { value : "C",text :"Boarded"},  
    { value : "I",text :"Deactivated"}, 
    { value : "D",text :"Deactivated"},  
    { value : "F",text :"Failed to update in MARS"},
    { value : "P",text :"Pending"},  
    { value : "E",text :"Mars Rejection"},  
    { value : "T",text :"Waiting for Activation"}, 
    { value : "V",text :"Activation/Deactivation Request Sent"},
    { value : "S#B",text :"Refer Back"}
  ];
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}

  loadMerchantNameWithId() {
    this.getMerchantNameApi().subscribe((data) => {
      if (data["success"]) {
        if (data["result"]) {
          this.merchantNameList = data["result"];
        } else {
          this.merchantNameList = undefined;
        }
      } else {
        throwError(data["message"]);
        this.merchantNameList = undefined;
      }
    });
  }

  getMerchantNameApi() {
    let header: HttpHeaders = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/allmerchantidname", {
        headers: header,
        observe: "body",
        responseType: "json",
      })
      .map((res) => res);
  }
  saveMerchantPreboard(merchantPreboard: MerchantModel) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/merchantpreboarding",
        merchantPreboard,
        { headers: header }
      )
      .map((res) => res);
  }
  getMerchantPreboardList(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (searchField.preboardingDate) {
      httpParam = httpParam.append(
        "preboardingDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.preboardingDate[0])
      );
      httpParam = httpParam.append(
        "preboardingDateTill",
        this.tools.formatDateToDDMMMYY(searchField.preboardingDate[1])
      );
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/preboardmerchants", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }

  getMerchantPreboardingDetail(id: number) {
    let header = new HttpHeaders();
    //let param: HttpParams = new HttpParams();
    //param = param.append("id", id.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/merchantpreboarding/" + id,
        {
          headers: header,
          observe: "body",
          //params: param,
          responseType: "json",
        }
      )
      .map((res) => res);
  }
  loadMerchantConfig() {
    if (!this.configLoaded) {
      this.http
        .get<any>("assets/config/merchantConfig.json", { responseType: "json" })
        .map((res) => res)
        .subscribe((data) => {
          this.configLoaded = true;
          this.companyBusinessType = data["CompanyBusinessType"];
          this.merchantType = data["MerchantType"];
          //this.statementFrequency=data["StatementFrequency"];
          this.typeOfStatement = data["TypeOfStatement"];
          this.bankSettlementBy = data["BankSettlementBy"];
          this.bankAccountType = data["BankAccountType"];
          this.paymentMode = data["PaymentMode"];
          this.terminalType = data["TerminalType"];
          this.deviceOwnedBy = data["DeviceOwnedBy"];
        }),
        (err: any) => {
          console.error(err);
          this.companyBusinessType = [{}];
          this.merchantType = [{}];
          //this.statementFrequency=[{}];
          this.typeOfStatement = [{}];
          this.bankSettlementBy = [{}];
          this.bankAccountType = [{}];
          this.paymentMode = [{}];
          this.terminalType = [{}];
          this.deviceOwnedBy = [{}];
        };
    }
  }

  loadRentalPlan() {
    this.rentalPlanApi().subscribe(
      (res) => {
        if (res["success"]) {
          this.rentalPlanList = res["result"];
        } else {
          console.error("Error in api while fetching rental plan!");
        }
      },
      (err) => {
        this.rentalPlanList = undefined;
      }
    );
  }
  private rentalPlanApi() {
    let header: HttpHeaders = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/getallrentalplan", {
        headers: header,
        observe: "body",
        responseType: "json",
      })
      .map((res) => res);
  }

  saveMerchant(
    merchant: MerchantModel,
    merchantFiles: MerchantFiles,
    companyFiles: CompanyFiles,
    partnerFileList: Array<PartnerFiles>
  ) {
    // const header : HttpHeaders = new HttpHeaders();
    const header: HttpHeaders = new HttpHeaders();

    // header.append("Content-Type","application/x-www-form-urlencoded");
    let mfiles: Array<FileModel> = new Array<FileModel>();
    let cfiles: Array<FileModel> = new Array<FileModel>();
    let pfiles: Array<FileModel> = new Array<FileModel>();
    if (merchantFiles) {
      Object.keys(merchantFiles).forEach((key) => {
        if (merchantFiles[key] && merchantFiles[key] instanceof File) {
          let tfile: FileModel = new FileModel();
          tfile.name = key;
          tfile.file = merchantFiles[key];
          mfiles.push(tfile);
        }
      });
    }
    if (companyFiles) {
      Object.keys(companyFiles).forEach((key) => {
        if (companyFiles[key] && companyFiles[key] instanceof File) {
          let tfile: FileModel = new FileModel();
          tfile.name = key;
          tfile.file = companyFiles[key];
          cfiles.push(tfile);
        }
      });
    }
    if (partnerFileList && partnerFileList.length > 0) {
      for (let i = 0; i < partnerFileList.length; i++) {
        let partnerfiles: PartnerFiles = partnerFileList[i];
        Object.keys(partnerfiles).forEach((key) => {
          if (partnerfiles[key] && partnerfiles[key] instanceof File) {
            let tfile: FileModel = new FileModel();
            tfile.name = key + "." + partnerfiles.key;
            tfile.file = partnerfiles[key];
            pfiles.push(tfile);
          }
        });
      }
    }
    let formdata: FormData = new FormData();
    if (
      merchant.companyProfile &&
      Object.keys(merchant.companyProfile).length == 0
    ) {
      merchant.companyProfile = undefined;
    }

    let foundFiles: boolean = false;
    if (mfiles && mfiles.length > 0) {
      mfiles.forEach((f) => {
        foundFiles = true;
        formdata.append("merchantfiles", f.file, f.name);
      });
    }
    if (cfiles && cfiles.length > 0) {
      cfiles.forEach((f) => {
        foundFiles = true;
        formdata.append("companyfiles", f.file, f.name);
      });
    }
    if (pfiles && pfiles.length > 0) {
      pfiles.forEach((f) => {
        foundFiles = true;
        formdata.append("partnersfiles", f.file, f.name);
      });
    }
    if (foundFiles) {
      const blobOverrides = new Blob([JSON.stringify(merchant)], {
        type: "application/json",
      });
      formdata.append("merchant", blobOverrides);
      return this.http
        .post(
          this.appsetting.get("server-url") + "/api/savemerchantwithfiles",
          formdata,
          { headers: header }
        )
        .map((res) => res);
    } else {
      return this.http
        .post(
          this.appsetting.get("server-url") + "/api/savemerchant",
          merchant,
          { headers: header, responseType: "json" }
        )
        .map((res) => res);
    }
  }

  terminalUpdateAction(terminals: Array<MerchantTerminalModel>) {
    const header: HttpHeaders = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/terminalaction",
        terminals,
        {
          headers: header,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getMerchant(merchantId: number) {
    let header = new HttpHeaders();
    //let httpParam: HttpParams = new HttpParams();
    //httpParam = httpParam.append("merchantId", "" + merchantId);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/merchant/" + merchantId, {
        headers: header,
        observe: "body",
        //params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }

  getMerchantList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);

    if (searchField.applicationDate) {
      httpParam = httpParam.append(
        "applicationDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.applicationDate[0])
      );
      httpParam = httpParam.append(
        "applicationDateTill",
        this.tools.formatDateToDDMMMYY(searchField.applicationDate[1])
      );
    }
    if (searchField.merchantStatus && searchField.merchantStatus != "") {
      httpParam = httpParam.append(
        "merchantStatus",
        searchField.merchantStatus
      );
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }

    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/merchants", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }

  approveRejectMerchant(merchant: MerchantModel) {
    const header: HttpHeaders = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/approverejectmerchant",
        merchant,
        { headers: header, responseType: "json" }
      )
      .map((res) => res);
  }

  approveRejectTerminal(merchantterminal: MerchantTerminalModel) {
    const header: HttpHeaders = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/approverejectterminal",
        merchantterminal,
        { headers: header, responseType: "json" }
      )
      .map((res) => res);
  }

  async downloadMerchantfile(
    merchantId: number,
    fileType: string
  ): Promise<Blob> {
    let header: HttpHeaders = new HttpHeaders({
      Accept: "text/csv; charset=utf-8",
    });

    //let httpparam: HttpParams = new HttpParams();
    //httpparam = httpparam.append("merchantId", merchantId.toString());
    //httpparam = httpparam.append("fileType", fileType);
    return await this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/downloadmerchantfile/" +
          fileType +
          "/" +
          merchantId,
        {
          headers: header,
          //  params: httpparam,
          responseType: "blob",
        }
      )
      .map((res) => res)
      .toPromise();
  }

  async downloadCompanyfile(
    companyProfileId: number,
    fileType: string
  ): Promise<Blob> {
    let header: HttpHeaders = new HttpHeaders({
      Accept: "text/csv; charset=utf-8",
    });

    //let httpparam: HttpParams = new HttpParams();
    //httpparam = httpparam.append(
    //  "merchantCompanyProfileId",
    //  companyProfileId.toString()
    //);
    //httpparam = httpparam.append("fileType", fileType);
    return await this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/downloadmerchantcompanyfile/" +
          fileType +
          "/" +
          companyProfileId,
        {
          headers: header, //params: httpparam,
          responseType: "blob",
        }
      )
      .map((res) => res)
      .toPromise();
  }

  async downloadPartnerfile(
    merchantPartnerId: number,
    fileType: string
  ): Promise<Blob> {
    let header: HttpHeaders = new HttpHeaders({
      Accept: "text/csv; charset=utf-8",
    });

    /*let httpparam: HttpParams = new HttpParams();
    httpparam = httpparam.append(
      "merchantPartnerId",
      merchantPartnerId.toString()
    );
    httpparam = httpparam.append("fileType", fileType); */
    return await this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/downloadmerchantpartnerfile/" +
          fileType +
          "/" +
          merchantPartnerId,
        {
          headers: header, //params: httpparam,
          responseType: "blob",
        }
      )
      .map((res) => res)
      .toPromise();
  }

  inactiveMerchant(merchantTerminal: MerchantTerminalModel) {
    const header: HttpHeaders = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/inactiveterminal",
        merchantTerminal,
        { headers: header, responseType: "json" }
      )
      .map((res) => res);
  }

  merchantMARSUpdate(merchantId: number) {
    const header: HttpHeaders = new HttpHeaders();
    let merchant = new MerchantModel();
    merchant.merchantId = merchantId;
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/merchantmarsupdate",
        merchant,
        {
          headers: header,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getMerchantMARSResApi(merchantId: number) {
    let header: HttpHeaders = new HttpHeaders();
    //let httpparam: HttpParams = new HttpParams();
    //httpparam = httpparam.append("merchantId", merchantId.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/merchantmarsresponse/" +
          merchantId,
        {
          headers: header,
          //params: httpparam,
          observe: "body",
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getMerchantTerminalMARSResApi(merchantTerminalId: number) {
    let header: HttpHeaders = new HttpHeaders();
    /*let httpparam: HttpParams = new HttpParams();
    httpparam = httpparam.append(
      "merchantTerminalId",
      merchantTerminalId.toString()
    ); */
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/merchantterminalmarsresponse/" +
          merchantTerminalId,
        {
          headers: header,
          //params: httpparam,
          observe: "body",
          responseType: "json",
        }
      )
      .map((res) => res);
  }
  merchantTerminalMARSUpdate(merchantTerminalId: number) {
    const header: HttpHeaders = new HttpHeaders();
    let merchantterminal = new MerchantTerminalModel();
    merchantterminal.merchantTerminalId = merchantTerminalId;
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/merchantterminalmarsupdate",
        merchantterminal,
        {
          headers: header,
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  fillAllMerchantDocumentTemplate() {
    let header: HttpHeaders = new HttpHeaders();
    this.http
      .get(this.appsetting.get("server-url") + "/api/merchantdoctemplates", {
        headers: header,
        observe: "body",
        responseType: "json",
      })
      .subscribe((data) => {
        if (data["result"]) {
          this.documenttemplates = data["result"];
        }
      });
  }
  getDocTemplates() {
    return this.documenttemplates;
  }

  getBusinessEntityDocTemplates(businessentity: string) {
    if (this.documenttemplates) {
      return this.documenttemplates.filter(
        (obj) => obj.businessEntity == businessentity
      );
    }
  }
  getBusinessEntityMandatoryDocTemplates(businessentity: string) {
    if (this.documenttemplates) {
      return this.documenttemplates.filter(
        (obj) => obj.businessEntity == businessentity && obj.mandatory == "Y"
      );
    }
  }

  validateMandatoryDocuments(
    businessEntity: string,
    selectedDocs: Array<string>
  ) {
    const mandatorydocs: Array<MerchantDocumentTemplateModel> = this.getBusinessEntityMandatoryDocTemplates(
      businessEntity
    );
    let ismissingdoc: boolean = false;
    let missingdocs: Array<MerchantDocumentTemplateModel> = new Array<
      MerchantDocumentTemplateModel
    >();
    if (mandatorydocs && mandatorydocs.length > 0) {
      for (let i = 0; i < mandatorydocs.length; i++) {
        const mandatorydoc: MerchantDocumentTemplateModel = mandatorydocs[i];
        const manddoctypes = mandatorydoc.documentType;
        const found = this.isMissingMerchantDocTemplateItem(
          mandatorydoc,
          selectedDocs
        );
        if (found == false) {
          ismissingdoc = true;
          missingdocs.push(mandatorydoc);
        }
      }
    }
    let obj: any = {};
    const msg = this.createMessageMissingDoc(missingdocs, businessEntity);
    obj.missingdoc = ismissingdoc;
    obj.msg = msg;

    return obj;
  }

  isMissingMerchantDocTemplateItem(
    templateItem: MerchantDocumentTemplateModel,
    selectedDocs: Array<string>
  ) {
    let found = true;
    if (
      templateItem &&
      templateItem.documentType &&
      templateItem.documentType.length > 0
    ) {
      found = false;
      for (let j = 0; j < templateItem.documentType.length && !found; j++) {
        const docCodeType = templateItem.documentType[j].documentType.split(
          "-"
        );
        const doctype = docCodeType[1];
        if (selectedDocs && selectedDocs.length > 0) {
          for (let k = 0; k < selectedDocs.length && !found; k++) {
            if (doctype == selectedDocs[k]) {
              found = true;
            }
          }
        }
      }
    }
    return found;
  }
  createMessageMissingDoc(
    missingDocs: Array<MerchantDocumentTemplateModel>,
    businessEntity: string
  ) {
    let messagemissingdocs: string = "";
    if (missingDocs && missingDocs.length > 0) {
      for (let i = 0; i < missingDocs.length; i++) {
        if (
          missingDocs[i].documentType &&
          missingDocs[i].documentType.length > 0
        ) {
          let docTypeDoclistMsg = "";

          for (let j = 0; j < missingDocs[i].documentType.length; j++) {
            const doccode = missingDocs[i].documentType[j].documentType.split(
              "-"
            )[1];
            const docnme = this.docTypeDisplayName(businessEntity, doccode);
            if (docTypeDoclistMsg != "") {
              docTypeDoclistMsg = docTypeDoclistMsg + "/";
            }
            docTypeDoclistMsg = docTypeDoclistMsg + docnme;
          }
          if (messagemissingdocs != "") {
            messagemissingdocs = messagemissingdocs + ",";
          }
          messagemissingdocs = messagemissingdocs + docTypeDoclistMsg;
        }
      }
    }
    return messagemissingdocs;
  }

  docTypeDisplayName(businessEntity: string, doctypecodetext: string) {
    const codetext: string = businessEntity + "-" + doctypecodetext;
    let displayname = undefined;
    let notfound = true;
    if (this.documenttemplates && this.documenttemplates.length > 0) {
      const busentdoctemplate = this.documenttemplates.filter(
        (obj) => obj.businessEntity == businessEntity
      );
      if (busentdoctemplate && busentdoctemplate.length > 0) {
        for (let i = 0; i < busentdoctemplate.length && notfound; i++) {
          if (
            busentdoctemplate[i].documentType &&
            busentdoctemplate[i].documentType.length > 0
          ) {
            for (
              let j = 0;
              j < busentdoctemplate[i].documentType.length && notfound;
              j++
            ) {
              if (
                busentdoctemplate[i].documentType[j].documentType == codetext
              ) {
                displayname =
                  busentdoctemplate[i].documentType[j].documentTypeName;
                notfound = false;
              }
            }
          }
        }
      }
    }
    if (!displayname) {
      displayname = this.docTypeCodeToName(doctypecodetext);
    }
    return displayname;
  }

  docTypeCodeToName(doctypecode: string) {
    let docTypeName: string = "";
    switch (doctypecode) {
      case "AADHAAR":
        docTypeName = "Aadhaar";
        break;
      case "ADDRESSPROOF":
        docTypeName = "Address proof";
        break;
      case "CANCELLEDCHEQUE":
        docTypeName = "Cancelled cheque";
        break;
      case "CERTIFICATE_INCORPORATION":
        docTypeName = "COC-Certificate of Incorporation";
        break;
      case "ESTABLISHMENT_LICENSE":
        docTypeName = "Shop/Establishment License";
        break;
      case "GST":
        docTypeName = "GST";
        break;
      case "MEMORANDUM":
        docTypeName = "MOA-Memorandum & Article of Association";
        break;
      case "PAN":
        docTypeName = "PAN";
        break;
      case "PARTNERSHIP_DEED":
        docTypeName = "Partnership deed";
        break;
      case "VOTERID":
        docTypeName = "Voter Id";
        break;
      case "BOARDRESOLUTION_LISTOFDIRECTORS":
        docTypeName = "Board Resolution and List of Directors";
        break;
      case "ELECTRICITYBILL":
        docTypeName = "Electricity Bill";
        break;
      case "GOVERNMENT_APPROVAL_TRUST":
        docTypeName = "Any Govt Approval for the Trust";
        break;
      case "ID_ADDRESS_PROOF_SIGNATORY":
        docTypeName = "ID and Address Proof for Signatory";
        break;
      case "PASSPORT":
        docTypeName = "Passport";
        break;
      case "RATIONCARD":
        docTypeName = "Ration card";
        break;
      case "TELEPHONEBILL":
        docTypeName = "Telephone bill";
        break;
      case "TRUST_DEED":
        docTypeName = "Trust deed";
        break;
      case "PHOTOGRAPH":
        docTypeName = "Two Latest Photographs";
        break;
      case "TRUST_DEED":
        docTypeName = "Certified True Copy of Trust Deed";
        break;
    }
    return docTypeName;
  }

  docTypeVisible(businessEntity: string, doctypecodetext: string) {
    const codetext: string = businessEntity + "-" + doctypecodetext;
    let notfound = true;
    if (this.documenttemplates && this.documenttemplates.length > 0) {
      const busentdoctemplate = this.documenttemplates.filter(
        (obj) => obj.businessEntity == businessEntity
      );
      if (busentdoctemplate && busentdoctemplate.length > 0) {
        for (let i = 0; i < busentdoctemplate.length && notfound; i++) {
          if (
            busentdoctemplate[i].documentType &&
            busentdoctemplate[i].documentType.length > 0
          ) {
            for (
              let j = 0;
              j < busentdoctemplate[i].documentType.length && notfound;
              j++
            ) {
              if (
                busentdoctemplate[i].documentType[j].documentType == codetext
              ) {
                notfound = false;
              }
            }
          }
        }
      }
    }
    return !notfound;
  }

  terminalListUpdate(terminals: Array<MerchantTerminalModel>) {
    const header: HttpHeaders = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/terminals", terminals, {
        headers: header,
        responseType: "json",
      })
      .map((res) => res);
  }

  saveTerminalStatusChangeRequest(obj: any) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/terminalStatusRequest",
        obj,
        { headers: header }
      )
      .map((res) => res);
  }

  terminalTypeList() {
    const terminalTypes = {
      "POS": [{ "code": "MPOS", "name": "MPOS" }, { "code": "POS", "name": "POS" }],
      "PG": [{ "code": "PG", "name": "PG" }, { "code": "UPI", "name": "UPI" }, { "code": "PG+UPI", "name": "PG+UPI" }],
      "UPI": [{ "code": "UPI", "name": "UPI" }],
      "POS+UPI": [{ "code": "MPOS", "name": "MPOS" }, { "code": "POS", "name": "POS" }, { "code": "UPI", "name": "UPI" }, { "code": "MPOS+UPI", "name": "MPOS+UPI" }, { "code": "POS+UPI", "name": "POS+UPI" }],
      "BQR": [{ "code": "BQR", "name": "Bharat QR" }],
      "MATM": [{ "code": "MATM", "name": "Micro ATM" }],
      "AEPS": [{ "code": "AEPS", "name": "AEPS" }],
      "POS+MATM": [{ "code": "POS", "name": "POS" }, { "code": "MPOS", "name": "MPOS" }, { "code": "MATM", "name": "Micro ATM" }, { "code": "POS+MATM", "name": "POS+Micro ATM" }, { "code": "MPOS+MATM", "name": "MPOS+Micro ATM" }],
      "POS+UPI+MATM": [{ "code": "POS", "name": "POS" }, { "code": "MPOS", "name": "MPOS" }, { "code": "UPI", "name": "UPI" }, { "code": "POS+UPI", "name": "POS+UPI" }, { "code": "MPOS+UPI", "name": "MPOS+UPI" }, { "code": "UPI+MATM", "name": "UPI+Micro ATM" }, { "code": "POS+UPI+MATM", "name": "POS+UPI+Micro ATM" }, { "code": "MPOS+UPI+MATM", "name": "MPOS+UPI+Micro ATM" }],
      "POS+UPI+MATM+AEPS": [{ "code": "POS", "name": "POS" }, { "code": "MPOS", "name": "MPOS" }, { "code": "UPI", "name": "UPI" }, { "code": "MATM", "name": "Micro ATM" }, { "code": "AEPS", "name": "AEPS" }, { "code": "POS+UPI", "name": "POS+UPI" }, { "code": "MPOS+UPI", "name": "MPOS+UPI" }, { "code": "POS+UPI+MATM", "name": "POS+UPI+Micro ATM" }, { "code": "MPOS+UPI+MATM", "name": "MPOS+UPI+Micro ATM" }, { "code": "POS+UPI+MATM+AEPS", "name": "POS+UPI+Micro ATM+AEPS" }, { "code": "MPOS+UPI+MATM+AEPS", "name": "MPOS+UPI+Micro ATM+AEPS" }, { "code": "UPI+MATM+AEPS", "name": "UPI+Micro ATM+AEPS" }, { "code": "UPI+MATM", "name": "UPI+Micro ATM" }, { "code": "MATM+AEPS", "name": "Micro ATM+AEPS" }],
    };
    return terminalTypes;
  }
  getTerminalTypeOnMerchantType(merchantType: string) {
    const terminalTypes = this.terminalTypeList();
    return terminalTypes[merchantType];
  }

  getAllTerminalType() {
    const terminalTypeList = this.terminalTypeList();
    console.log(terminalTypeList);
    let terminalTypeValuesList = []
    if (terminalTypeList) {
      let terminalTypeKeys = Object.keys(terminalTypeList);
      for (let i = 0; i < terminalTypeKeys.length; i++) {
        const terminalTypeValues = terminalTypeList[terminalTypeKeys[i]];
        if (terminalTypeValues) {
          for (let j = 0; j < terminalTypeValues.length; j++) {
            const terminalTypeValue = terminalTypeValues[j];
            let idx = -1;
            if (this.terminalTypeList) {
              idx = terminalTypeValuesList.findIndex(obj => obj.code === terminalTypeValue.code);
            }
            if (idx < 0) {
              terminalTypeValuesList.push(terminalTypeValue);
            }
          }
        }
      }
    }
    return terminalTypeValuesList;
  }

  getTerminalStatusRequests(merchantTerminalId: number) {
    let header: HttpHeaders = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/terminalStatusRequest/" +
          merchantTerminalId,
        {
          headers: header,
          //params: httpparam,
          observe: "body",
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getTerminalStatuses() {
    return [{ code: "PB", text: "Pre-Boareded" },
      { code: "S", text: "Boarding" },
      { code: "R", text: "Ready for approval" },
      { code: "A", text: "Approved" },
      { code: "J", text: "Rejected" },
      { code: "H", text: "Hold approval" },
      { code: "C", text: "Boarded" },
     /* { code: "I", text: "Inactive" },*/
      { code: "D", text: "Deactivated" },
      { code: "F", text: "Failed to update in MARS" },
      { code: "P", text: "Pending" },
      { code: "E", text: "MARS Rejection" },
      { code: "T", text: "Waiting for Activation" },
      { code: "V", text: "Activation/Deactivation Request Sent" }];
  }
}
