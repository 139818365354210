import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../framework/config/AppSettings";
import { EmployeeModel } from "../models/employee.model";
import { throwError } from "rxjs";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";

@Injectable()
export class EmployeeService {
  employeeNameList: EmployeeModel[];
  constructor(private http: HttpClient, private appsetting: AppSettings) {}
  loadEmployeeNameWithCode() {
    this.getEmployeeNameApi().subscribe(data => {
      if (data["success"]) {
        if (data["result"]) {
          this.employeeNameList = data["result"];
        } else {
          this.employeeNameList = undefined;
        }
      } else {
        throwError(data["message"]);
        this.employeeNameList = undefined;
      }
    });
  }

  getEmployeeNameApi() {
    let header: HttpHeaders = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/allemployeenamelist", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }

  saveEmployee(emp: EmployeeModel, actiontype: string) {
    let header = new HttpHeaders();
    if (actiontype == "new") {
      return this.http
        .post(this.appsetting.get("server-url") + "/api/newemployee", emp, {
          headers: header
        })
        .map(res => res);
    } else {
      return this.http
        .post(this.appsetting.get("server-url") + "/api/updateemployee", emp, {
          headers: header
        })
        .map(res => res);
    }
  }
  updateToMARS(emp: EmployeeModel) {
    let header = new HttpHeaders();
    let empparam: EmployeeModel = new EmployeeModel();
    empparam.empCode = emp.empCode;
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/updateemptomars",
        empparam,
        { headers: header }
      )
      .map(res => res);
  }
  getEmployeeList(pageinfo: GridPage, searchField: any, sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (sortField) {
      if (sortField.sortFieldName && sortField.sortDirection != "") {
        httpParam = httpParam.append("sortDirection", sortField.sortDirection);
      }
      if (sortField.sortFieldName && sortField.sortFieldName != "") {
        httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
      }
    }

    return this.http
      .get(this.appsetting.get("server-url") + "/api/employees", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getEmployee(empCode: string) {
    let header = new HttpHeaders();
    //let httpParam: HttpParams = new HttpParams();
    //httpParam = httpParam.append("empCode", empCode);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/employee/" + empCode, {
        headers: header,
        observe: "body",
        //params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getEmployeeMARSResApi(empCode: string) {
    let header: HttpHeaders = new HttpHeaders();
    let httpparam: HttpParams = new HttpParams();
    httpparam = httpparam.append("empCode", empCode.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/employeemarsresponse", {
        headers: header,
        params: httpparam,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }
}
