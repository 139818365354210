import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../framework/config/AppSettings';
import { Tools } from '../framework/modules/tools';
import { SortClass } from '../framework/modules/util.class';
import { GridPage } from '../framework/util/components/gridtable/gridtable.component';

@Injectable({
  providedIn: 'root'
})
export class StaticQrCodesService {

  constructor(private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools) { }
  
  getStaticQrCodes(pageinfo: GridPage,
    searchField: any,
    sortField: SortClass) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());

    if (searchField.dateFrom) {
      httpParam = httpParam.append("dateFrom", this.tools.formatDateToDDMMMYY(searchField.dateFrom));
    }
    if (searchField.dateTill) {
      httpParam = httpParam.append("dateTill", this.tools.formatDateToDDMMMYY(searchField.dateTill));
    }
    if (searchField.tid) {
      httpParam = httpParam.append("tid", searchField.tid);
    } 
    if (searchField.mid) {
      httpParam = httpParam.append("mid", searchField.mid);
    } 
    if (searchField.vpaAddress) {
      httpParam = httpParam.append("vpaId", searchField.vpaAddress);
    } 

    if (sortField.sortFieldName) {
      if (!sortField.sortDirection) {
        sortField.sortDirection = "D";
      }
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }

    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/staticqrcodes",
        {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json",
        }
      )
      .map((res) => res);
  }
}
