import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  GridPage,
  gridColumns,
  GridtableComponent,
} from "src/app/framework/util/components/gridtable/gridtable.component";
import { global } from "src/app/framework/modules/global";
import { Tools } from "src/app/framework/modules/tools";
import { AuthService } from "src/app/framework/services/auth.service";
import {
  SortClass,
  SimpleMessage,
  MARSResponseErrorMsg,
} from "../../framework/modules/util.class";
import {
  AggregatorDistributorModel,
  AggregatorDistributorBankDetailModel,
  AggregatorDistributorFiles,
} from "src/app/models/aggregator.distributor.model";
import { AggregatorDistributorService } from "../../services/aggregator.distributor.service";
import { TransCategoryService } from "../../services/transcategory.service";
import { EmployeeService } from "src/app/services/employee.service";
import { BankService } from "src/app/services/bank.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { EmployeeModel } from "src/app/models/employee.model";
import { BankModel } from "src/app/models/bank.model";
import {
  NgInformationModalContent,
  NgYesNoModalContent,
} from "src/app/framework/util/components/modalcomponent/modal-content";
import { MerchantService } from "src/app/services/merchant.service";
import { TransRatesModel } from "src/app/models/trans.rate";
import { BankIFSCService } from "src/app/services/bank.ifsc.service";
import { PincodeService } from "src/app/services/pincode.service";
import { PincodeModel } from "src/app/models/pincode.model";
import { DomSanitizer } from "@angular/platform-browser";
import { type } from "os";
import { RegionMasterModel } from "src/app/models/region.master.model";
import { RegionMasterService } from "src/app/services/region.master.service";
import { MARSErrorResponse } from "src/app/models/mars.response.error.module";
import { MARSSuperGroupMerchantModel } from "src/app/models/mars.super.group.merchant.model";

declare var jquery: any;
declare var $: any;
import * as jspdf from "jspdf";
import html2canvas from "html2canvas";
import { Observable, Observer, Subscription } from 'rxjs';
import { AbstractControl, Form, NgForm, ValidationErrors } from "@angular/forms";

export class AggregatorDistributorTabs {
  profiletab: boolean;
  financialstab: boolean;
  banktab: boolean;
}

@Component({
  selector: "app-aggregator-distributor",
  templateUrl: "./aggregator-distributor.component.html",
  styleUrls: ["./aggregator-distributor.component.css"],
})
export class AggregatorDistributorComponent implements OnInit, OnDestroy {
  @Input()
  _isAllowAdd: boolean = false;
  @Input()
  _isAllowEdit: boolean = false;
  @Input()
  approvePriv: boolean = false;
  @Input() set getType(value: string) {
    
       this._type = value;
       this.getAllSuperGroupMerchants();
    
  }
  _type: string;
  @Input()
  tabStatus: AggregatorDistributorTabs;
  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  _title: string = "";
  _action: string = "";
  isProfile: boolean = false;
  isFinancials: boolean = false;
  isStock: boolean = false;
  isBusinesssummary: boolean = false;
  acttive_tab: string = "";
  page: GridPage;

  diffLabel: any = {
    codeLabel: "Aggregator Code",
    kycLabel: "Aggregator KYC",
    nameLabel: "Aggregator Name",
  };

  data: AggregatorDistributorModel;
  _data: any;
  superMerchant: AggregatorDistributorModel;
  _superMerchant: AggregatorDistributorModel;
  institution: AggregatorDistributorModel;
  _institution: AggregatorDistributorModel;
  aggregator: AggregatorDistributorModel;
  _aggregator: AggregatorDistributorModel;
  _isChange: boolean = false;
  salesPerson: EmployeeModel;
  _salesPerson: EmployeeModel;
  sponsorbank: BankModel;
  _sponsorbank: BankModel;
  bankDetail: AggregatorDistributorBankDetailModel;
  _bankDetail: AggregatorDistributorBankDetailModel;
  aggregatordistributorFiles: AggregatorDistributorFiles;
  _downloadedFiles: AggregatorDistributorFiles;
  transRates: Array<TransRatesModel>;
  _newTransRates: Array<TransRatesModel>;
  _transRates: Array<TransRatesModel>;
  _isFileModified: boolean = false;
  aggregatorDistributorList: Array<AggregatorDistributorModel> = new Array<
    AggregatorDistributorModel
  >();
  imgURL: any;
  previewFileType: string;
  pdf: boolean = false;

  _busy: boolean = false;

  aggregatorlist: Array<AggregatorDistributorModel> = new Array<
    AggregatorDistributorModel
  >();
  supermerchantlist: Array<AggregatorDistributorModel> = new Array<
    AggregatorDistributorModel
  >();
  regions: Array<RegionMasterModel>;
  marssupergroupmerchant: Array<MARSSuperGroupMerchantModel>;
  subsMARSCodeUpdate: Subscription;
  constructor(
    private router: Router,
    private objGlobal: global,
    public tools: Tools,
    private auth: AuthService,
    private aggregatordistributorservice: AggregatorDistributorService,
    public transCategoryService: TransCategoryService,
    public merchantservice: MerchantService,
    public employeeservice: EmployeeService,
    public bankservice: BankService,
    private ngbModalService: NgbModal,
    private bankifscservice: BankIFSCService,
    private pincodeservice: PincodeService,
    private sanitizer: DomSanitizer,
    private regionmasterservice: RegionMasterService
  ) {
    this.assignNew();
    merchantservice.loadMerchantConfig();
    this.loadAggregatorDistributorInstitution();
    this.transCategoryService.getTransCategoriesList();
    this.employeeservice.loadEmployeeNameWithCode();
    this.bankservice.loadBankList(true);
    this.loadRegions();
    this.bankservice.getBankList();
    this._isFileModified = false;
    this.imgURL = "assets/images/blank.png";
    this.subscribeMARSCodeChange();
  }
  assignNew() {
    this.data = new AggregatorDistributorModel();
    this.sponsorbank = new BankModel();
    this.salesPerson = new EmployeeModel();
    this.superMerchant = new AggregatorDistributorModel();
    this.institution = new AggregatorDistributorModel();
    this.aggregator = new AggregatorDistributorModel();
    this.bankDetail = new AggregatorDistributorBankDetailModel();
    this.aggregatordistributorFiles = new AggregatorDistributorFiles();
    this._downloadedFiles = undefined;
    this.newCopyTransRateCategories(true);
    this._isFileModified = false;
    this.sponsorbank.bankId = 1;
  }
  ngOnInit() { }
  ngOnDestroy() {
    this.subsMARSCodeUpdate.unsubscribe();
  }
  subscribeMARSCodeChange() {
    this.subsMARSCodeUpdate = this.aggregatordistributorservice.getMARSCodeUpdateObs().
      subscribe(type => {
        this.getAllSuperGroupMerchants();
      })
  }
  async loadRegions() {
    this.regions = await this.regionmasterservice.getRegions(false);
    this.getAllSuperGroupMerchants();
    //console.log(this.regions);
     //console.log(this.regions);
  }
  addNew() {
    this.assignNew();
    this.createCopyData();
    if (!this.marssupergroupmerchant) {
      this.getAllSuperGroupMerchants()
    }
  }
  createCopyData() {
    this._data = this.tools.copyFullObject(this.data);
    this._sponsorbank = this.tools.copyFullObject(this.sponsorbank);
    this._salesPerson = this.tools.copyFullObject(this.salesPerson);
    this._superMerchant = this.tools.copyFullObject(this.superMerchant);
    this._institution = this.tools.copyFullObject(this.institution);
    this._aggregator = this.tools.copyFullObject(this.aggregator);
    this._bankDetail = this.tools.copyFullObject(this.bankDetail);
    this._transRates = this.tools.copyFullObject(this.transRates);
  }
  resetData() {
    this.data = this.tools.copyFullObject(this._data);
    this.sponsorbank = this.tools.copyFullObject(this._sponsorbank);
    this.salesPerson = this.tools.copyFullObject(this._salesPerson);
    this.superMerchant = this.tools.copyFullObject(this._superMerchant);
    this.institution = this.tools.copyFullObject(this._institution);
    this.aggregator = this.tools.copyFullObject(this._aggregator);
    this.bankDetail = this.tools.copyFullObject(this._bankDetail);
    this.aggregatordistributorFiles = new AggregatorDistributorFiles();
    this._downloadedFiles = undefined;
  }
  selectData(eventVal: any) {}
  deleteData(eventVal: any) {}
  async loadAggregatorDistributorInstitution() {
    this.aggregatorDistributorList = await this.aggregatordistributorservice.loadAggregatorDistrbutorIdNameTypeOfUserSelfChildParentAndInstitution();
  }
  getAggregatorDistributorDetail(_id) {
    if (!this.marssupergroupmerchant) {
      this.getAllSuperGroupMerchants()
    }
    if (_id) {
      this.aggregatordistributorservice
        .getAggregatorDistributorDetail(_id)
        .subscribe(
          (data) => {
            if (data["success"]) {
              this.data = data["result"];
              if (this.data.agreementDate) {
                this.data.agreementDate = new Date(this.data.agreementDate);
              }
              if (this.data.enrollmentDate) {
                this.data.enrollmentDate = new Date(this.data.enrollmentDate);
              }

              this.convertDateField(this.data);
              this._data = this.tools.copyFullObject(this.data);
            } else {
              let ngmodaloptions: NgbModalOptions = {};
              ngmodaloptions.backdrop = "static";
              ngmodaloptions.size = "sm";
              let objmsg: SimpleMessage = new SimpleMessage();
              objmsg.title = "Error";
              if (this._type == "A") {
                objmsg.message = "Failed to get aggregator list!";
              } else if (this._type == "S") {
                objmsg.message = "Failed to get super merchant list!";
              } else if (this._type == "D") {
                objmsg.message = "Failed to get distributor list!";
              }
              objmsg.btnClass = "btn-error";
            }
          },
          (err) => {
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            let objmsg: SimpleMessage = new SimpleMessage();
            objmsg.title = "Error";
            if (this._type == "A") {
              objmsg.message = "Failed to get aggregator list!";
            } else if (this._type == "S") {
              objmsg.message = "Failed to get super merchant list!";
            } else if (this._type == "D") {
              objmsg.message = "Failed to get distributor list!";
            }
            objmsg.btnClass = "btn-error";
          }
        );
    }
  }
  setDiffLabel() {
    if (this._type == "D") {
      this.diffLabel.codeLabel = "Distrbutor Code";
      this.diffLabel.nameLabel = "Distributor Name";
      this.diffLabel.kycLabel = "Distributor KYC";
    } else {
      this.diffLabel.codeLabel = "Aggregator Code";
      this.diffLabel.nameLabel = "Aggregator Name";
      this.diffLabel.kycLabel = "Aggregator KYC";
    }
  }
  getCodeLabel() {
    return this.getTypeText() + " Code";
  }
  getNameLabel() {
    return this.getTypeText() + " Name";
  }
  getKYCLabel() {
    return this.getTypeText() + " KYC";
  }
  tab_click(e) {
    if (e == "financials") {
      this.isFinancials = true;
      this.isStock = false;
      this.isBusinesssummary = false;
      this.isProfile = false;
    } else if (e == "stock") {
      this.isFinancials = false;
      this.isStock = true;
      this.isBusinesssummary = false;
      this.isProfile = false;
    } else if (e == "businesssummary") {
      this.isFinancials = false;
      this.isStock = false;
      this.isBusinesssummary = true;
      this.isProfile = false;
    } else {
      this.isFinancials = false;
      this.isStock = false;
      this.isBusinesssummary = false;
      this.isProfile = true;
    }
  }

  /*saveAggregatorDist() {
    if(this.salesPerson.empCode) {
      this.data.salesPerson=this.salesPerson;    
    }
    if(this.sponsorbank.bankId) {
      this.data.sponsorBank=this.sponsorbank;
    }
    this.data.type=this._type;
    this.aggregatordistributorservice.saveAggregatorDistributor(this.data).subscribe(data =>{    
    let ngmodaloptions : NgbModalOptions = {};
    ngmodaloptions.backdrop='static';
    ngmodaloptions.size="sm"    
    let objmsg : SimpleMessage=new SimpleMessage();
    if(data["success"]) {
      if(data['type']=='A'){
        var title = 'Aggregator';
      } else {
        var title = 'Distributor';
      }                            
      objmsg.title="Information";
      objmsg.message=title+" saved successfully!";
      objmsg.btnClass="btn-info";
      this.data=data["result"];
      this.convertDateField(this.data);
      this._isChange=true;
    } else if(data["message"]=="InputDataNotProvided") {      
      objmsg.title="Error";
      objmsg.message="Indent input data not available!";
      objmsg.btnClass="btn-warning";  
    } else{                
      objmsg.title="Error";
      objmsg.message="Failed to save Indent!";
      objmsg.btnClass="btn-error";
    } 
    this.tools.simpleMessage(objmsg); 
  })
  }*/

  disableCancel() {
    if (this._busy) {
      return true;
    }
    return !this.isModified();
  }
  disableSave() {
    if (this._busy) {
      return true;
    } else if (!this.isAllowAddorEdit()) {
      return true;
    } else if (!this.data.name && !this.data.enrollmentDate) {
      return true;
    } else if (!this.isModified()) {
      return true;
    }
    return false;
  }
  disableSubmit(fProfile, fFin, fBank) {
    if (this._busy) {
      return true;
    }
    if (!this.isAllowAddorEdit()) {
      return true;
    } else if (this.noAllowSaveSubmitOnStatus()) {
      return true;
    } else if (!fProfile || !fFin || !fBank) {
      return true;
    } else if (!fProfile.valid || !fFin.valid || !fBank.valid) {
      return true;
    } else if (
      this.data.aggregatorDistributorStatus == "C" &&
      !this.isModified()
    ) {
      return true;
    }
    return false;
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this._isAllowAdd;
    } else if (this._action == "update") {
      isAllowSave = this._isAllowEdit;
    }
    return isAllowSave;
  }

  handleFileInput(file: File, inputName) {
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) != null || file.type == "application/pdf") {
      if (!this.aggregatordistributorFiles) {
        this.aggregatordistributorFiles = new AggregatorDistributorFiles();
      }
      if (inputName == "panFile") {
        this.aggregatordistributorFiles.panFile = file;
      }
      if (inputName == "gstFile") {
        this.aggregatordistributorFiles.gstFile = file;
      }
      if (inputName == "aadhaarFile") {
        this.aggregatordistributorFiles.aadhaarFile = file;
      }
      this._isFileModified = true;
    } else {
      let objmsg: SimpleMessage = new SimpleMessage();
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      objmsg.title = "Alert";
      objmsg.message = "Selected file type is not valid!";
      objmsg.btnClass = "btn-warning";
      this.tools.simpleMessage(objmsg);
    }
  }

  convertDateField(data: AggregatorDistributorModel) {
    if (data.agreementDate) {
      data.agreementDate = new Date(data.agreementDate);
    }
    if (data.enrollmentDate) {
      data.enrollmentDate = new Date(data.enrollmentDate);
    }
  }

  isPartnerSharing() {
    return true;
  }
  isBankAchMandat() {
    return this.bankDetail.achMandate == "Y";
  }
  onOffBankAchMandate() {
    // tslint:disable-next-line: quotemark
    this.bankDetail.achMandate = this.bankDetail.achMandate === "Y" ? "N" : "Y";
  }
  async newCopyTransRateCategories(assign: boolean) {
    const transcatlist = await this.transCategoryService.getTransCategoriesList();
    this._newTransRates = new Array<TransRatesModel>();

    for (let i = 0; i < transcatlist.length; i++) {
      let transrate = new TransRatesModel();
      transrate.transCategory = transcatlist[i];
      transrate.fromAmount = transcatlist[i].fromAmount;
      transrate.toAmount = transcatlist[i].toAmount;
      this._newTransRates.push(transrate);
    }
    if (assign) {
      this.transRates = this._newTransRates;
    }
  }

  onBackbuttonClick() {
    //alert("back button clicked");
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        this.getTypeText() +
        " data has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this._action = "";
            this.closeScreen();
          }
        },
        (reason) => {}
      );
    } else {
      this._action = "";
      this.closeScreen();
    }
  }
  onCancel() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        this.getTypeText() +
        " data has been modified. Are you sure to discard without save?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.resetData();
          }
        },
        (reason) => {}
      );
    }
  }
  isModified() {
    //    let user1=this.user;
    //    let user2=this._user;
    if (!(this.data && this._data)) {
      return false;
    } else if (this._isFileModified) {
      return true;
    } else if (this.data.name != this._data.name) {
      return true;
    } else if (this.data.companyName != this._data.companyName) {
      return true;
    } else if (this.data.mobileNumber != this._data.mobileNumber) {
      return true;
    } else if (this.data.marsCode != this._data.marsCode) {
      return true;
    } else if (
      this.data.defaultSuperMerchantMARSCode !=
      this._data.defaultSuperMerchantMARSCode
    ) {
      return true;
    } else if (
      this.data.defaultGroupMerchantMARSCode !=
      this._data.defaultGroupMerchantMARSCode
    ) {
      return true;
    } else if (
      !this.tools.isDateSame(
        this.data.enrollmentDate,
        this._data.enrollmentDate
      )
    ) {
      return true;
    } else if (
      !this.tools.isDateSame(this.data.agreementDate, this._data.agreementDate)
    ) {
      return true;
    } else if (this.data.email != this._data.email) {
      return true;
    } else if (this.data.pan != this._data.pan) {
      return true;
    } else if (this.data.gst != this._data.gst) {
      return true;
    } else if (this.data.aadhaar != this._data.aadhaar) {
      return true;
    } else if (this.data.voterId != this._data.voterId) {
      return true;
    } else if (this.data.otherId != this._data.otherId) {
      return true;
    } else if (this.data.businessAddress != this._data.businessAddress) {
      return true;
    } else if (
      this.data.businessAddressPinCode != this._data.businessAddressPinCode
    ) {
      return true;
    } else if (
      this.data.businessAddressState != this._data.businessAddressState
    ) {
      return true;
    } else if (this.data.email != this._data.email) {
      return true;
    } else if (this.data.financeRemarks != this._data.remarks) {
      return true;
    } else if (this.data.passportNumber != this._data.passportNumber) {
      return true;
    } else if (
      !this.tools.isDateSame(
        this.data.passportExpiryDate,
        this._data.passportExpiryDate
      )
    ) {
      return true;
    } else if (this.data.region != this._data.region) {
      return true;
    } else if (this.data.residenceAddress != this._data.residenceAddress) {
      return true;
    } else if (
      this.data.residenceAddressPinCode != this._data.residenceAddressPinCode
    ) {
      return true;
    } else if (
      this.data.residenceAddressState != this._data.residenceAddressState
    ) {
      return true;
    } else if (
      this.aggregator &&
      this._aggregator &&
      this.aggregator.id != this._aggregator.id
    ) {
      return true;
    } else if (
      this.superMerchant &&
      this._superMerchant &&
      this.superMerchant.id != this._superMerchant.id
    ) {
      return true;
    } else if (
      this.sponsorbank &&
      this._sponsorbank &&
      this.sponsorbank.bankId != this._sponsorbank.bankId
    ) {
      return true;
    } else if (
      this.getObjectFieldValue(this.salesPerson, "empCode") !=
      this.getObjectFieldValue(this.data.salesPerson, "empCode")
    ) {
      return true;
    } else if (this.isModifiedBankDetail()) {
      return true;
    } else if (this.isModifiedTransRates()) {
      return true;
    }

    return false;
  }
  getObjectFieldValue(obj: any, fieldName: string) {
    let retValue: any;
    if (obj) {
      retValue = obj[fieldName];
    }
    return retValue;
  }
  isModifiedTransRates() {
    if (
      !(this.transRates && this.transRates.length > 0) &&
      !(this._transRates && this._transRates.length > 0)
    ) {
      return false;
    } else if (
      this.transRates &&
      this.transRates.length > 0 &&
      !(this._transRates && this._transRates.length > 0)
    ) {
      return true;
    } else if (
      !(this.transRates && this.transRates.length > 0) &&
      this._transRates &&
      this._transRates.length > 0
    ) {
      return true;
    } else if (this.transRates && this._transRates) {
      for (let i = 0; i < this.transRates.length; i++) {
        if (this.transRates[i].mmFixed != this._transRates[i].mmFixed) {
          return true;
        } else if (
          this.transRates[i].mmPercent != this._transRates[i].mmPercent
        ) {
          return true;
        } else if (this.transRates[i].mmMax != this._transRates[i].mmMax) {
          return true;
        } else if (this.transRates[i].psFixed != this._transRates[i].psFixed) {
          return true;
        } else if (
          this.transRates[i].psPercent != this._transRates[i].psPercent
        ) {
          return true;
        } else if (this.transRates[i].psMax != this._transRates[i].psMax) {
          return true;
        }
      }
    }
    return false;
  }
  isModifiedBankDetail() {
    if (!this.bankDetail || !this._bankDetail) {
      return false;
    } else if (
      this.bankDetail.accountNumber != this._bankDetail.accountNumber
    ) {
      return true;
    } else if (this.bankDetail.accountType != this._bankDetail.accountType) {
      return true;
    } else if (this.bankDetail.achMandate != this._bankDetail.achMandate) {
      return true;
    } else if (this.bankDetail.bankName != this._bankDetail.bankName) {
      return true;
    } else if (this.bankDetail.branchName != this._bankDetail.branchName) {
      return true;
    } else if (this.bankDetail.city != this._bankDetail.city) {
      return true;
    } else if (
      this.bankDetail.feeAddressChange != this._bankDetail.feeAddressChange
    ) {
      return true;
    } else if (this.bankDetail.ifscCode != this._bankDetail.ifscCode) {
      return true;
    } else if (this.bankDetail.micr != this._bankDetail.micr) {
      return true;
    } else if (
      this.bankDetail.paymentDetails != this._bankDetail.paymentDetails
    ) {
      return true;
    } else if (this.bankDetail.paymentMode != this._bankDetail.paymentMode) {
      return true;
    } else if (this.bankDetail.pinCode != this._bankDetail.pinCode) {
      return true;
    } else if (this.bankDetail.remarks != this._bankDetail.remarks) {
      return true;
    } else if (this.bankDetail.settelmentBy != this._bankDetail.settelmentBy) {
      return true;
    } else if (this.bankDetail.state != this._bankDetail.state) {
      return true;
    } else if (this.bankDetail.umrn != this._bankDetail.umrn) {
      return true;
    }
    return false;
  }

  closeScreen() {
    // this.data=new MerchantModel();
    // this.distributeData(false);
    // this.copyInitDataNull();
    this.onClose.emit(false);
  }

  getInstituionList() {
    return this.aggregatorDistributorList.filter((obj) => obj.type == "I");
  }
  getAggregatorList() {
    let tmpaggregator: Array<AggregatorDistributorModel> = new Array<
      AggregatorDistributorModel
    >();

    if (
      this.institution &&
      this.institution.id != undefined &&
      this._type != "A"
    ) {
      let item = this.institution;
      tmpaggregator.push(new AggregatorDistributorModel());
      for (let i = 0; i < this.aggregatorDistributorList.length; i++) {
        if (this.aggregatorDistributorList[i].id == item.id) {
          tmpaggregator[0] = this.aggregatorDistributorList[i];
        }
        if (this.aggregatorDistributorList[i].type == "A") {
          tmpaggregator.push(this.aggregatorDistributorList[i]);
        }
      }
    }
    this.aggregatorlist = tmpaggregator;
    if (this.aggregatorlist && this.aggregatorlist.length > 0) {
      this.aggregator.id = this.aggregatorlist[0].id;
    }
    this.getSuperMerchantList();
  }

  getSuperMerchantList() {
    let tmpSuperMerchant: Array<AggregatorDistributorModel> = new Array<
      AggregatorDistributorModel
    >();

    if (
      this.aggregator &&
      this.aggregator.id != undefined &&
      this._type == "D"
    ) {
      let item = this.aggregator;
      tmpSuperMerchant.push(new AggregatorDistributorModel());
      for (let i = 0; i < this.aggregatorDistributorList.length; i++) {
        if (this.aggregatorDistributorList[i].id == item.id) {
          tmpSuperMerchant[0] = this.aggregatorDistributorList[i];
        }
        if (
          this.aggregatorDistributorList[i].aggregator &&
          this.aggregatorDistributorList[i].aggregator.id == item.id &&
          this.aggregatorDistributorList[i].type == "S"
        ) {
          tmpSuperMerchant.push(this.aggregatorDistributorList[i]);
        }
      }
    }
    this.supermerchantlist = tmpSuperMerchant;
    if (this.supermerchantlist && this.supermerchantlist.length > 0) {
      this.superMerchant.id = this.supermerchantlist[0].id;
    }
  }

  saveData() {
    this.collectData();
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const isSubmitClick: boolean = this.data.aggregatorDistributorStatus == "R";
    this.aggregatordistributorservice
      .saveAggregatorDistributor(this.data, this.aggregatordistributorFiles)
      .subscribe(
        (data) => {
          if (data["success"]) {
            this.data = data["result"];
            this.convertDates();
            objmsg.title = "Success";
            objmsg.message =
              this.getTypeText() +
              " successfully " +
              (isSubmitClick ? "submitted " : "saved!");
            objmsg.btnClass = "btn-success";
            this.tools.simpleMessage(objmsg);
            this._isChange = true;
            this.distributeData(true);
          } else {
            this.data.aggregatorDistributorStatus = this._data.aggregatorDistributorStatus;
            switch (data["message"]) {
              case "NAMEALREADYEXISTS": {
                objmsg.title = "Alert";
                objmsg.message =
                  this.getTypeText() +
                  " name already exists for different record!";
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
              }
              case "INVALIDRECORD": {
                objmsg.title = "Alert";
                objmsg.message = "Invalid record to modify!";
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
              }
              default: {
                objmsg.title = "Error";
                objmsg.message =
                  "Error in " +
                  (isSubmitClick ? "submit " : "save") +
                  " record!";
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
              }
            }
          }
        },
        (err) => {
          this.data.aggregatorDistributorStatus = this._data.aggregatorDistributorStatus;
          objmsg.title = "Error";
          objmsg.message =
            "Error in " + (isSubmitClick ? "submit " : "save") + " record!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  onSaveClick(fProfile, fFin, fBank) {
    let errMsg = this.validateNonRequiredFieldValidation(fProfile);
    if (!errMsg.error) {
      errMsg = this.validateNonRequiredFieldValidation(fFin);
      if (!errMsg.error) {
        errMsg = this.validateNonRequiredFieldValidation(fBank);
      }
    }
    if (errMsg.error) {
      let simpleMsg: SimpleMessage = new SimpleMessage();
      simpleMsg.message = errMsg.errorText;
      simpleMsg.title = "Warning";
      simpleMsg.btnClass = "btn-warning";
      this.tools.simpleMessage(simpleMsg);
      return false;
    } 
    if (this.validatedata()) {
      this.data.aggregatorDistributorStatus = "S";
      this.saveData();
    }
  }

  validateNonRequiredFieldValidation(f: NgForm) {
    const controls: string[] = Object.keys(f.controls);
    let errorField: string = undefined;
    let errFound: boolean = false;
    for (let i = 0; i < controls.length && (!errFound); i++) {
      if (f.controls[controls[i]].errors && (!f.controls[controls[i]].errors["required"])) {
        errFound = true;
        errorField = this.aggregatordistributorservice.getFieldDisplayName(controls[i]);
      }
    }
    return { error: errFound, errorText: "Invalid value for " +  errorField + "!" };
  }
  onSubmitClick(fProfile,fFin,fBank) {
    
    if (this.validatedata()) {
      this.data.aggregatorDistributorStatus = "R";
      this.saveData();
    }
  }
  validatedata() {
    let objmsg: SimpleMessage = new SimpleMessage();
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    objmsg.title = "Warning";
    objmsg.btnClass = "btn-warning";
    if (
      this.data.enrollmentDate &&
      new Date(this.tools.formatDateToDDMMMYY(this.data.enrollmentDate)) >
        new Date()
    ) {
      objmsg.message =
        "Enrollment date should not be greater than current date!";
      this.tools.simpleMessage(objmsg);
      return false;
    }
    if (
      this.data.agreementDate &&
      new Date(this.tools.formatDateToDDMMMYY(this.data.agreementDate)) >
        new Date()
    ) {
      objmsg.message =
        "Agreement date should not be greater than current date!";
      this.tools.simpleMessage(objmsg);
      return false;
    }
    return true;
  }
  collectData() {
    this.data.type = this._type;
    if (this.sponsorbank && this.sponsorbank.bankId) {
      this.data.sponsorBank = this.sponsorbank;
    } else {
      this.data.sponsorBank = undefined;
    }
    if (this.salesPerson && this.salesPerson.empCode) {
      this.data.salesPerson = this.salesPerson;
    } else {
      this.data.salesPerson = undefined;
    }
    if (this.institution && this.institution.id != undefined) {
      this.data.institution = this.institution;
    } else {
      this.data.institution = undefined;
    }
    if (this.aggregator.id != undefined) {
      this.data.aggregator = this.aggregator;
    } else {
      this.data.aggregator = undefined;
    }
    if (this.superMerchant.id != undefined) {
      this.data.superMerchant = this.superMerchant;
    } else {
      this.data.superMerchant = undefined;
    }
    if (this.bankDetail && Object.keys(this.bankDetail).length > 0) {
      this.data.bankDetail = this.bankDetail;
    } else {
      this.data.bankDetail = undefined;
    }
    this.data.transRates = this.transRates;
  }
  distributeData(copyinit: boolean) {
    this.aggregatordistributorFiles = new AggregatorDistributorFiles();
    this._downloadedFiles = undefined;
    this._isFileModified = false;
    if (this.data.sponsorBank) {
      this.sponsorbank = this.data.sponsorBank;
    } else {
      this.bankDetail = new BankModel();
    }
    if (this.salesPerson) {
      this.salesPerson = this.data.salesPerson;
    } else {
      this.salesPerson = new EmployeeModel();
    }
    if (this.data.institution) {
      this.institution = this.data.institution;
    } else {
      this.institution = new AggregatorDistributorModel();
    }
    this.getAggregatorList();
    if (this.data.aggregator) {
      this.aggregator = this.data.aggregator;
    } else {
      this.aggregator = new AggregatorDistributorModel();
    }
    this.getSuperMerchantList();
    if (this.data.superMerchant) {
      this.superMerchant = this.data.superMerchant;
    } else {
      this.superMerchant = new AggregatorDistributorModel();
    }
    if (this.data.bankDetail) {
      this.bankDetail = this.data.bankDetail;
    } else {
      this.bankDetail = new AggregatorDistributorBankDetailModel();
    }
    if (this.data.transRates) {
      this.transRates = this.data.transRates;
    } else {
      this.transRates = this._newTransRates;
    }
    if (copyinit) {
      this.createCopyData();
    }
  }

  copyInitDataNull() {
    this._data = undefined;
    this._sponsorbank = undefined;
    this._institution = undefined;
    this._aggregator = undefined;
    this._superMerchant = undefined;
    this._bankDetail = undefined;
    this._transRates = undefined;
  }
  convertDates() {
    if (this.data.enrollmentDate) {
      this.data.enrollmentDate = new Date(this.data.enrollmentDate);
    }
    if (this.data.agreementDate) {
      this.data.agreementDate = new Date(this.data.agreementDate);
    }
    if (this.data.passportExpiryDate) {
      this.data.passportExpiryDate = new Date(this.data.passportExpiryDate);
    }
  }
  getDetail(id: number) {
    this._isChange = false;
    this._isFileModified = false;
    if (id) {
      this.aggregatordistributorservice
        .getAggregatorDistributorDetail(id)
        .subscribe(
          (data) => {
            if (data["success"]) {
              this.data = data["result"];
              this.convertDates();
              this._isChange = true;
              this.distributeData(true);
            } else {
              if (data["message"] == "INVALIDRECORD") {
                let objmsg: SimpleMessage = new SimpleMessage();
                let ngmodaloptions: NgbModalOptions = {};
                ngmodaloptions.backdrop = "static";
                ngmodaloptions.size = "sm";
                objmsg.title = "Error";
                objmsg.message = "Invalid " + this.getTypeText() + " Id!";
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
              } else if (data["message"] == "Error") {
                let objmsg: SimpleMessage = new SimpleMessage();
                let ngmodaloptions: NgbModalOptions = {};
                ngmodaloptions.backdrop = "static";
                ngmodaloptions.size = "sm";
                objmsg.title = "Error";
                objmsg.message = "Error found in response!";
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
              }
            }
          },
          (err) => {
            let objmsg: SimpleMessage = new SimpleMessage();
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            objmsg.title = "Error";
            objmsg.message = "Error while fetching record!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        );
    }
  }
  showApproveRejectButton() {
    if (this._action == "new") {
      return false;
    } else if (
      this.data.aggregatorDistributorStatus != "R" &&
      this.data.aggregatorDistributorStatus != "H"
    ) {
      return false;
    }
    if (this.data.lastModifiedBy == this.objGlobal.Auth.user) {
      return false;
    } else if (!this.approvePriv) {
      return false;
    }
    return true;
  }
  onApproveClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message =
      "Are you sure to approve " + this.getTypeText() + "?";
    ngmodalref.result.then(
      (result) => {
        if (result == "yes") {
          this.approveRejectAggregatorDistributor("A");
        }
      },
      (reason) => {}
    );
  }

  marsaction() {
    if (this._type == "A") {
      this.updateAggregatorDataFromMARS("D");
    } else {
      this.submitToMars("D");
    }
  }
  updateAggregatorDataFromMARS(source: string) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    let objmsg: SimpleMessage = new SimpleMessage();
    this.aggregatordistributorservice
      .updateAggregatorFromMARS(this.data)
      .subscribe(
        (res) => {
          //this.tools.simpleMessage(objmsg);
          document.body.style.cursor = "default";
          if (res["success"]) {
            if (res["result"] && res["result"].marsCode) {
              this.data.aggregatorDistributorStatus =
                res["result"].aggregatorDistributorStatus;
              this._data.aggregatorDistributorStatus =
                res["result"].aggregatorDistributorStatus;
            }
            objmsg.title = "Success";
            if (source == "D") {
              objmsg.message =
                this.getTypeText() + "Successfully updated data from MARS !";
            } else {
              objmsg.message = this.getTypeText() + " approved !";
            }
            objmsg.btnClass = "btn-success";
          } else {
            const msg: String = res["message"];
            objmsg.title = "Warning";
            this.data.aggregatorDistributorStatus = "F";
            this._data.aggregatorDistributorStatus = "F";
            if (source == "D") {
              objmsg.message =
                this.getTypeText() + "update from MARS failed, error - " + msg;
            } else {
              objmsg.message =
                this.getTypeText() +
                " approved but MARS update from MARS failed, error - " +
                msg;
            }
            objmsg.btnClass = "btn-warning";
          }

          this._busy = false;
          this.tools.simpleMessage(objmsg);
        },
        (err) => {
          document.body.style.cursor = "default";
          objmsg.title = "Warning";
          this.data.aggregatorDistributorStatus = "F";
          this._data.aggregatorDistributorStatus = "F";
          if (source == "D") {
            objmsg.message =
              this.getTypeText() + "Update from MARS failed, error - " + err;
          } else {
            objmsg.message =
              this.getTypeText() +
              " approved but Update from MARS failed, error - " +
              err;
          }
          objmsg.btnClass = "btn-warning";
          this.tools.simpleMessage(objmsg);
        }
      );
  }
  submitToMars(source: string) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    let objmsg: SimpleMessage = new SimpleMessage();

    this.aggregatordistributorservice.updateToMARS(this.data).subscribe(
      (res) => {
        document.body.style.cursor = "default";
        this._busy = false;
        if (res["success"]) {
          if (res["result"] && res["result"].marsCode) {
            this.data.marsCode = res["result"].marsCode;
            this.data.aggregatorDistributorStatus =
              res["result"].aggregatorDistributorStatus;

            this._data.marsCode = res["result"].marsCode;
            this._data.aggregatorDistributorStatus =
              res["result"].aggregatorDistributorStatus;
          }
          objmsg.title = "Success";
          if (source == "D") {
            objmsg.message =
              this.getTypeText() + " updated in MARS successfully !";
          } else {
            objmsg.message = this.getTypeText() + " approved !";
          }
          objmsg.btnClass = "btn-success";
        } else {
          const msg: String = res["message"];
          objmsg.title = "Warning";
          this.data.aggregatorDistributorStatus = "F";
          this._data.aggregatorDistributorStatus = "F";
          if (source == "D") {
            objmsg.message =
              this.getTypeText() + " MARS update failed, error - " + msg;
          } else {
            objmsg.message =
              this.getTypeText() +
              " approved but MARS update failed, error - " +
              msg;
          }
          objmsg.btnClass = "btn-warning";
        }
        this.tools.simpleMessage(objmsg);
        document.body.style.cursor = "default";
        this._busy = false;
      },
      (err) => {
        document.body.style.cursor = "default";
        this._busy = false;
        this.data.aggregatorDistributorStatus = "F";
        this._data.aggregatorDistributorStatus = "F";
        objmsg.title = "Warning";
        if (source == "D") {
          objmsg.message =
            this.getTypeText() + " MARS update failed, error - " + err;
        } else {
          objmsg.message =
            this.getTypeText() +
            " approved but MARS update failed, error - " +
            err;
        }
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  onHoldClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message =
      "Are you sure to Hold " + this.getTypeText() + "?";
    ngmodalref.result.then(
      (result) => {
        if (result == "yes") {
          this.approveRejectAggregatorDistributor("H");
        }
      },
      (reason) => {}
    );
  }
  getTypeText() {
    return this._type == "D"
      ? "Distributor"
      : this._type == "S"
      ? "Super Merchant"
      : "Aggregator";
  }
  onRejectClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message =
      "Are you sure to reject " + this.getTypeText() + "?";
    ngmodalref.result.then(
      (result) => {
        if (result == "yes") {
          this.approveRejectAggregatorDistributor("J");
        }
      },
      (reason) => {}
    );
  }
  approveRejectAggregatorDistributor(approvalType: string) {
    let aggdist: AggregatorDistributorModel = new AggregatorDistributorModel();
    aggdist.id = this.data.id;
    aggdist.aggregatorDistributorStatus = approvalType;

    document.body.style.cursor = "wait";
    this._busy = true;

    this.aggregatordistributorservice
      .approveRejectAggregatorDistributor(aggdist)
      .subscribe(
        (res) => {
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          if (res["success"]) {
            const taggdistr = res["result"];
            this.data.aggregatorDistributorStatus = taggdistr.aggregatorDistributorStatus;
            //this.data.marsUpdate = taggdistr.marsUpdate;
            //this.getDetail(id);
            if (approvalType == "A" && (!this.data.marsUpdate)) {
              if (this._type != "A") {
                this.submitToMars("A");
              } else {
                this.updateAggregatorDataFromMARS("A");
              }
            } else {
              objmsg.title = "Success";
              objmsg.message =
                this.getTypeText() +
                (approvalType == "J"
                  ? " rejected!"
                  : approvalType == "H"
                  ? " hold!"
                  : " approved!");
              objmsg.btnClass = "btn-success";
              this.tools.simpleMessage(objmsg);
              document.body.style.cursor = "default";
              this._busy = false;
            }
          } else {
            switch (res["message"]) {
              case "INVALIDRECORD": {
                objmsg.title = "Alert";
                objmsg.message =
                  "Invalid record to " +
                  (approvalType == "J"
                    ? "reject!"
                    : approvalType == "H"
                    ? " hold!"
                    : "approve!");
                objmsg.btnClass = "btn-warning";
                this.tools.simpleMessage(objmsg);
                break;
              }
              default: {
                objmsg.title = "Error";
                objmsg.message =
                  "Failed to " +
                  (approvalType == "J"
                    ? "reject"
                    : approvalType == "H"
                    ? " hold!"
                    : "approve") +
                  " " +
                  this.getTypeText() +
                  "!";
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
              }
            }
            document.body.style.cursor = "default";
            this._busy = false;
          }
        },
        (err) => {
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Error";
          objmsg.message =
            "Failed to " +
            (approvalType == "J" ? "reject" : "approve") +
            " " +
            this.getTypeText() +
            "!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
          document.body.style.cursor = "default";
          this._busy = false;
        }
      );
  }
  noAllowSaveSubmitOnStatus() {
    let noAllow: boolean = false;
    if (this.data.aggregatorDistributorStatus == "R") {
      noAllow = true;
    } else if (this.data.aggregatorDistributorStatus == "A") {
      noAllow = true;
    } else if (this.data.aggregatorDistributorStatus == "H") {
      noAllow = true;
    }
    return noAllow;
  }
  noAllowEdit() {
    return this.showApproveRejectButton() || this.noAllowSaveSubmitOnStatus();
  }
  async setBankIFSCData() {
    const bankifscdata = await this.bankifscservice.getBankInfoOnIFSCCode(
      this.bankDetail.ifscCode
    );

    if (bankifscdata) {
      this.bankDetail.micr = bankifscdata.micr;
      this.bankDetail.bankName = bankifscdata.bankName;
      this.bankDetail.branchName = bankifscdata.branchName;
      this.bankDetail.city = bankifscdata.city;
      this.bankDetail.state = bankifscdata.state;
      this.bankDetail.pinCode = bankifscdata.pincode;
    } else {
      this.bankDetail.micr = undefined;
      this.bankDetail.bankName = undefined;
      this.bankDetail.branchName = undefined;
      this.bankDetail.city = undefined;
      this.bankDetail.state = undefined;
      this.bankDetail.pinCode = undefined;
    }
  }
  async onBlurBusinessAddressPincode(e) {
    const pincodedata: PincodeModel = await this.pincodeservice.getPincodeData(
      this.data.businessAddressPinCode
    );
    if (pincodedata) {
      this.data.businessAddressCity = pincodedata.taluk;
      //this.data.businessAddressDistrict=pincodedata.district;
      this.data.businessAddressState = pincodedata.state;
    } else {
      this.data.businessAddressCity = undefined;
      //this.data.registeredAddressDistrict=undefined;
      this.data.businessAddressState = undefined;
      e.target.classList.remove("ng-valid");
      e.target.classList.add("ng-invalid");
    }
  }
  async onBlurResidenceAddressPincode(e) {
    const pincodedata: PincodeModel = await this.pincodeservice.getPincodeData(
      this.data.residenceAddressPinCode
    );
    if (pincodedata) {
      this.data.residenceAddressCity = pincodedata.taluk;
      //this.data.businessAddressDistrict=pincodedata.district;
      this.data.residenceAddressState = pincodedata.state;
    } else {
      this.data.residenceAddressCity = undefined;
      //this.data.registeredAddressDistrict=undefined;
      this.data.residenceAddressState = undefined;
      e.target.classList.remove("ng-valid");
      e.target.classList.add("ng-invalid");
    }
  }
  isItemExistsInArray(itm: Array<any>) {
    let exists: boolean = false;
    if (itm) {
      if (itm.length > 0) {
        exists = true;
      }
    }
    return exists;
  }
  isModifiedFieldCompArray(itm: Array<any>, obj: any, fieldName: string) {
    const isexistsarrayitem = this.isItemExistsInArray(itm);
    if (!isexistsarrayitem && !obj) {
      return false;
    } else if (this.isItemExistsInArray && itm[0][fieldName] && !obj) {
      return true;
    } else if (obj && obj[fieldName] && !isexistsarrayitem) {
      return true;
    } else if (obj[fieldName] != itm[0][fieldName]) {
      return true;
    } else {
      return false;
    }
  }

  async previewImage(fileType: string) {
    let file: File;
    switch (fileType) {
      case "pan":
        file = this.aggregatordistributorFiles.panFile;
        this.previewFileType = "PAN";
        break;
      case "aadhaar":
        file = this.aggregatordistributorFiles.aadhaarFile;
        this.previewFileType = "AADHAAR";
        break;
      case "gst":
        file = this.aggregatordistributorFiles.gstFile;
        this.previewFileType = "GST";
        break;
      case "voterId":
        file = this.aggregatordistributorFiles.voterIdFile;
        this.previewFileType = "Voter Id";
        break;
      case "otherId":
        file = this.aggregatordistributorFiles.otherIdFile;
        this.previewFileType = "Other Id";
        break;
    }
    if (!file) {
      file = this.getFromDownloaedFiles(fileType);
    }
    var reader = new FileReader();
    if (file) {
      var mimeType = file.type;
      //if (mimeType.match(/image\/*/) == null) {
      // this.message = "Only images are supported.";
      //    return;
      //}
      if (mimeType.match(/image\/*/) != null) {
        //this.imagePath = files;
        this.pdf = false;
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.imgURL = reader.result;
          if (this.imgURL && this.imgURL != "") {
            $("#imgpreview").modal({ backdrop: false });
          }
        };
      } else if (file.type == "application/pdf") {
        let fileUrl = URL.createObjectURL(file);
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
        this.pdf = true;
        if (this.imgURL && this.imgURL != "") {
          $("#imgpreview").modal({ backdrop: false });
        }
      } else {
        return;
      }
    } else {
      await this.aggregatordistributorservice
        .downloadfile(this.data.id, fileType)
        .then((data) => {
          this.saveDownloadedFile(
            new File([data], fileType + "File", { type: data.type }),
            fileType
          );
          if (data.type.match(/image\/*/) != null) {
            reader.readAsDataURL(data);
            reader.onload = (_event) => {
              this.imgURL = reader.result;
              if (this.imgURL && this.imgURL != "") {
                $("#imgpreview").modal({ backdrop: false });
              }
            };
            //$("#imgpreview").modal({backdrop: false});
          } else if (data.type == "application/pdf") {
            this.pdf = true;
            let fileUrl = URL.createObjectURL(data);
            this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(
              fileUrl
            );
            if (this.imgURL && this.imgURL != "") {
              $("#imgpreview").modal({ backdrop: false });
            }
          }
        });
    }
  }
  onImgCloseClick() {
    $("#imgpreview").modal("hide");
  }
  disablePanPreview() {
    return !(this.data.panFileName || this.aggregatordistributorFiles.panFile);
  }
  disableAadhaarPreview() {
    return !(
      this.data.aadhaarFileName || this.aggregatordistributorFiles.aadhaarFile
    );
  }
  disableGstPreview() {
    return !(this.data.gstFileName || this.aggregatordistributorFiles.gstFile);
  }
  saveDownloadedFile(file: File, inputName) {
    if (!this._downloadedFiles) {
      this._downloadedFiles = new AggregatorDistributorFiles();
    }
    if (inputName == "pan") {
      this._downloadedFiles.panFile = file;
    }
    if (inputName == "gst") {
      this._downloadedFiles.gstFile = file;
    }
    if (inputName == "aadhaar") {
      this._downloadedFiles.aadhaarFile = file;
    }
  }

  getFromDownloaedFiles(fileType: string) {
    let file: File;
    if (this._downloadedFiles) {
      switch (fileType) {
        case "pan":
          file = this._downloadedFiles.panFile;
          break;
        case "aadhaar":
          file = this._downloadedFiles.aadhaarFile;
          break;
        case "gst":
          file = this._downloadedFiles.gstFile;
          break;
        case "voterId":
          file = this._downloadedFiles.voterIdFile;
          break;
        case "otherId":
          file = this._downloadedFiles.otherIdFile;
          break;
      }
    }
    return file;
  }

  disableHoldButton() {
    let retvalue: boolean = true;
    if (this.data && this.data.aggregatorDistributorStatus == "R") {
      retvalue = false;
    }
    return retvalue;
  }
  getAggregatorDistributorStatus() {
    let status: string = "New";
    switch (this.data.aggregatorDistributorStatus) {
      case "S":
        status = "New";
        break;
      case "R":
        status = "Ready for approval";
        break;
      case "H":
        status = "On Hold";
        break;
      case "C":
        status = "Active";
        break;
      case "I":
        status = "Inactive";
        break;
      case "J":
        status = "Rejected";
        break;
      case "A":
        status = "Approved";
        break;
      case "F":
        status = "Failed to MARS update";
        break;
      default:
        status = "New";
        break;
    }
    return status;
  }

  disableBackbutton() {
    return this._busy;
  }

  marsCodeToolTip() {
    return "Only digits, max 999999, are allowed!";
  }
  showMarsErrorButton() {
    if (this.data.aggregatorDistributorStatus == "F" && this.data.type != "A") {
      return true;
    } else {
      return false;
    }
  }
  showMARSResponseError() {
    if (this.data.id) {
      this.aggregatordistributorservice
        .getAggregatorDistributorMARSResApi(this.data.id)
        .subscribe(
          (res) => {
            if (res["result"]) {
              const data: MARSErrorResponse = res["result"];
              let objMsg: MARSResponseErrorMsg = new MARSResponseErrorMsg();
              objMsg.forEntity = this.data.name;
              objMsg.errorMsg = data;
              this.tools.marsResponseErrorMessage(objMsg);
            } else {
              console.error(res["message"]);
            }
          },
          (err) => {
            console.error("Error to get MARS Reponse Errors");
          }
        );
    }
  }
  pincodeMsg() {
    return "Please enter valid pincode!";
  }

  getSalesPersonList() {}
  getAllSuperGroupMerchants() {
    
    if (this._type == "A") {
      this.aggregatordistributorservice.allSuperGroupMerchant().subscribe(
        (res) => {
          if (res["success"]) {
            this.marssupergroupmerchant = res["result"];
          } else {
            console.error(
              "error while fetching mars super group list " + res["message"]
            );
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }
  getMarsAggregators() {
    if (this.marssupergroupmerchant) {
      return this.marssupergroupmerchant.filter((itm) => itm.type == "A");
    }
  }
  getMarsSuperMerchant() {
    if (this.data.marsCode && this.marssupergroupmerchant) {
      return this.marssupergroupmerchant.filter(
        (itm) => itm.type == "S" && itm.aggregatorCode == this.data.marsCode
      );
    }
  }
  getMarsGroupMerchant() {
    if (this.data.defaultSuperMerchantMARSCode && this.marssupergroupmerchant) {
      return this.marssupergroupmerchant.filter(
        (itm) =>
          itm.type == "G" &&
          itm.superMerchantCode == this.data.defaultSuperMerchantMARSCode
      );
    }
  }

  allowMARSUpdate() {
    if (this.data.aggregatorDistributorStatus == 'F' || (this.data.aggregatorDistributorStatus == 'A' && (!this.data.marsUpdate))) {
      return true;
    } else {
      return false;
    }
  }

  downloadpdf() {
    var data = document.getElementById("imgpreview");
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("MYPdf.pdf"); // Generated PDF
    });
  }
}
