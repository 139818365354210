import { Injectable } from "@angular/core";
import { Tools } from "../framework/modules/tools";
import { AppSettings } from "../framework/config/AppSettings";
import { RentalPlanModel } from "../models/rental.plan.model";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass } from "../framework/modules/util.class";

@Injectable({
  providedIn: "root"
})
export class RentalplanService {
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) {}

  saveRentalPlan(rentalplan: RentalPlanModel) {
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/rentalplan", rentalplan, {
        headers: header
      })
      .map(res => res);
  }

  getRentalPlanList(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (searchField.creationDate) {
      httpParam = httpParam.append(
        "creationDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.creationDate[0])
      );
      httpParam = httpParam.append(
        "creationDateTill",
        this.tools.formatDateToDDMMMYY(searchField.creationDate[1])
      );
    }
    if (searchField.status && searchField.status != "") {
      httpParam = httpParam.append("status", searchField.status);
    }
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/rentalplanlist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getRentalPlanDetail(code: number) {
    let header = new HttpHeaders();
    //let param: HttpParams = new HttpParams();
    //param = param.append("code", code.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/rentalplandetail/" + code,
        {
          headers: header,
          observe: "body",
          //params: param,
          responseType: "json"
        }
      )
      .map(res => res);
  }
  markRentalPlanInactive(code: number) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/inactiverentalplan",
        code,
        { headers: header }
      )
      .map(res => res);
  }

  async loadRentalPlanDetailList(): Promise<Array<RentalPlanModel>> {
    return await this.loadRentalPlanDetailListApi()
      .toPromise()
      .then(
        data => {
          if (data["success"]) {
            const val: Array<RentalPlanModel> = data["result"];
            return val;
          } else {
            return undefined;
          }
        },
        msg => {
          console.error("topromise rejected while getting Retanl plan!");
          return undefined;
        }
      )
      .catch(err => {
        console.error("exception while getting Rental plan - " + err);
        return undefined;
      });
  }

  loadRentalPlanDetailListApi() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/rentalplans", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }

  updateToMARS(rentalplan: RentalPlanModel) {
    let header = new HttpHeaders();
    let rentalplanparam: RentalPlanModel = new RentalPlanModel();
    rentalplanparam.code = rentalplan.code;
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/updateretnalplantomars",
        rentalplanparam,
        { headers: header }
      )
      .map(res => res);
  }

  getRentalMARSResApi(rentalPlanCode: number) {
    let header: HttpHeaders = new HttpHeaders();
    //let httpparam: HttpParams = new HttpParams();
    //httpparam = httpparam.append("code", rentalPlanCode.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/rentalmarsresponse/" +
          rentalPlanCode,
        {
          headers: header,
          //params: httpparam,
          observe: "body",
          responseType: "json"
        }
      )
      .map(res => res);
  }
}
