import { Injectable } from "@angular/core";
import {
  AggregatorDistributorModel,
  AggregatorDistributorFiles,
  AggregatorChannelNetwork,
} from "../models/aggregator.distributor.model";
import { AppSettings } from "../framework/config/AppSettings";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { throwError, Observable, Subject } from "rxjs";
import { GridPage } from "../framework/util/components/gridtable/gridtable.component";
import { SortClass, FileModel } from "../framework/modules/util.class";
import { buffer } from "rxjs-compat/operator/buffer";
import { Tools } from "../framework/modules/tools";
import { BankSourcingBranchModel } from '../models/BankSourcingBranch.model';

@Injectable()
export class AggregatorDistributorService {
  paymentMode: any;
  aggregatorDistributorList: Array<AggregatorDistributorModel> = new Array<
    AggregatorDistributorModel
  >();
  private subMARSCodeUpdate = new Subject<any>();
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tools: Tools
  ) { }
  public getMARSCodeUpdateObs() {
    return this.subMARSCodeUpdate.asObservable();
  }
  public triggerMARSCodeUpdateSubs(type : string) {
    this.subMARSCodeUpdate.next(type);
  }
  loadAggregatorDistrbutorIdNameType() {
    this.getAggregatorDistributorIdNameTypeApi().subscribe((data) => {
      if (data["success"]) {
        if (data["result"]) {
          this.aggregatorDistributorList = data["result"];
        } else {
          this.aggregatorDistributorList = new Array<
            AggregatorDistributorModel
          >();
        }
      } else {
        throwError(data["message"]);
        this.aggregatorDistributorList = new Array<
          AggregatorDistributorModel
        >();
      }
    });
  }
  async loadAggregatorDistrbutorIdNameTypeOfUserSelfChild() {
    return await this.getAggregatorDistributorIdNameTypeApiOfUserSelfAndChild()
      .toPromise()
      .then((data) => {
        if (data["success"]) {
          if (data["result"]) {
            return data["result"];
          } else {
            return new Array<AggregatorDistributorModel>();
          }
        } else {
          throwError(data["message"]);
          return new Array<AggregatorDistributorModel>();
        }
      });
  }
  async loadAggregatorDistrbutorIdNameTypeOfUserSelfChildParentAndInstitution() {
    return await this.getAggregatorDistributorIdNameTypeOfUserSelfChildParentAndInstitutionApi()
      .toPromise()
      .then((data) => {
        if (data["success"]) {
          if (data["result"]) {
            return data["result"];
          } else {
            return new Array<AggregatorDistributorModel>();
          }
        } else {
          throwError(data["message"]);
          return new Array<AggregatorDistributorModel>();
        }
      });
  }
  loadAggregatorDistrbutorIdNameTypeAndSet(
    aggdistrlist: Array<AggregatorDistributorModel>
  ) {
    this.getAggregatorDistributorIdNameTypeApi().subscribe((data) => {
      if (data["success"]) {
        if (data["result"]) {
          aggdistrlist = data["result"];
        } else {
          aggdistrlist = new Array<AggregatorDistributorModel>();
        }
      } else {
        throwError(data["message"]);
        aggdistrlist = new Array<AggregatorDistributorModel>();
      }
    });
  }
  getAggregatorDistributorIdNameTypeApi() {
    let header: HttpHeaders = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/aggregatordistributornametypeid",
        { headers: header, observe: "body", responseType: "json" }
      )
      .map((res) => res);
  }
  getAggregatorDistributorIdNameTypeApiOfUserSelfAndChild() {
    let header: HttpHeaders = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/aggregatordistributornametypeidofuserselfandchild",
        { headers: header, observe: "body", responseType: "json" }
      )
      .map((res) => res);
  }
  getAggregatorDistributorIdNameTypeOfUserSelfChildParentAndInstitutionApi() {
    let header: HttpHeaders = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/aggregatordistributornametypeidofuserselchildparentandinstitution",
        { headers: header, observe: "body", responseType: "json" }
      )
      .map((res) => res);
  }
  getAggregatorDistributorList(
    pageinfo: GridPage,
    searchField: any,
    sortField: SortClass,
    _type: string
  ) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("type", _type);
    httpParam = httpParam.append("searchText", searchField.searchText);
    if (sortField.sortFieldName) {
      httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
    }
    if (sortField.sortDirection) {
      httpParam = httpParam.append("sortDirection", sortField.sortDirection);
    }
    if (searchField.enrollmentDate) {
      httpParam = httpParam.append(
        "enrollmentDateFrom",
        this.tools.formatDateToDDMMMYY(searchField.enrollmentDate[0])
      );
      httpParam = httpParam.append(
        "enrollmentDateTill",
        this.tools.formatDateToDDMMMYY(searchField.enrollmentDate[1])
      );
    }
    if (searchField.status && searchField.status != "") {
      httpParam = httpParam.append("status", searchField.status);
    }
    return this.http
      .get(this.appsetting.get("server-url") + "/api/aggregatorsdistributors", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json",
      })
      .map((res) => res);
  }

  saveAggregatorDistributor(
    aggdist: AggregatorDistributorModel,
    aggdistFiles: AggregatorDistributorFiles
  ) {
    const header: HttpHeaders = new HttpHeaders();
    let adfiles: Array<FileModel> = new Array<FileModel>();
    if (aggdistFiles) {
      Object.keys(aggdistFiles).forEach((key) => {
        if (aggdistFiles[key] && aggdistFiles[key] instanceof File) {
          let tfile: FileModel = new FileModel();
          tfile.name = key;
          tfile.file = aggdistFiles[key];
          adfiles.push(tfile);
        }
      });
    }
    let formdata: FormData = new FormData();
    let foundFiles: boolean = false;
    if (adfiles && adfiles.length > 0) {
      adfiles.forEach((f) => {
        foundFiles = true;
        formdata.append("aggregatordistributorfiles", f.file, f.name);
      });
    }
    if (foundFiles) {
      const blobOverrides = new Blob([JSON.stringify(aggdist)], {
        type: "application/json",
      });
      formdata.append("aggregatordistributor", blobOverrides);
      return this.http
        .post(
          this.appsetting.get("server-url") +
            "/api/aggregatordistributorwithfiles",
          formdata,
          { headers: header }
        )
        .map((res) => res);
    } else {
      return this.http
        .post(
          this.appsetting.get("server-url") + "/api/aggregatordistributor",
          aggdist,
          { headers: header, responseType: "json" }
        )
        .map((res) => res);
    }
  }

  getAggregatorDistributorDetail(aggDistId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("id", aggDistId.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/aggregatordistributor/" +
          aggDistId,
        {
          headers: header,
          observe: "body",
          // params: param,
          responseType: "json",
        }
      )
      .map((res) => res);
  }
  approveRejectAggregatorDistributor(aggrdistr: AggregatorDistributorModel) {
    const header: HttpHeaders = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") +
          "/api/approverejectaggregatordistributor",
        aggrdistr,
        { headers: header, responseType: "json" }
      )
      .map((res) => res);
  }

  async downloadfile(id: number, fielType: string): Promise<Blob> {
    let header: HttpHeaders = new HttpHeaders({
      Accept: "text/csv; charset=utf-8",
    });

    let httpparam: HttpParams = new HttpParams();
    httpparam = httpparam.append("id", id.toString());
    httpparam = httpparam.append("idType", fielType);
    return await this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/downloadaggregatordistributorfile/" +
          fielType +
          "/" +
          id,
        {
          headers: header,
          //params: httpparam,
          responseType: "blob",
        }
      )
      .map((res) => res)
      .toPromise();
  }

  updateToMARS(aggrdistr: AggregatorDistributorModel) {
    let header = new HttpHeaders();
    let aggrdistrparam: AggregatorDistributorModel = new AggregatorDistributorModel();
    aggrdistrparam.id = aggrdistr.id;
    aggrdistrparam.type = aggrdistr.type;
    return this.http
      .post(
        this.appsetting.get("server-url") +
          "/api/updategroupsupermerchanttomars",
        aggrdistrparam,
        { headers: header }
      )
      .map((res) => res);
  }

  updateAggregatorFromMARS(aggrdistr: AggregatorDistributorModel) {
    let header = new HttpHeaders();
    let aggrdistrparam: AggregatorDistributorModel = new AggregatorDistributorModel();
    aggrdistrparam.id = aggrdistr.id;
    aggrdistrparam.type = aggrdistr.type;
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/updateaggregatordatafrommars",
        aggrdistrparam,
        { headers: header }
      )
      .map((res) => res);
  }
  async getAggregatorChannelNetwork(
    aggregatorId: number
  ): Promise<Array<AggregatorChannelNetwork>> {
    return await this.getAggregatorChannelNetworkApi(aggregatorId)
      .toPromise()
      .then((data) => {
        let aggregatorchannelnetwork: Array<AggregatorChannelNetwork>;
        if (data["result"]) {
          aggregatorchannelnetwork = data["result"];
        } else {
          console.log(data["message"]);
        }
        return aggregatorchannelnetwork;
      });
  }

  getAggregatorChannelNetworkApi(aggregatorId: number) {
    let header: HttpHeaders = new HttpHeaders();

    // httpparam = httpparam.append("aggregatorId", aggregatorId.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/getgggregatortransactionset/" +
          aggregatorId,
        {
          headers: header,
          //params: httpparam,
          observe: "body",
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getAggregatorDistributorMARSResApi(aggregatodistributorid: number) {
    let header: HttpHeaders = new HttpHeaders();
    //let httpparam: HttpParams = new HttpParams();
    //httpparam = httpparam.append("id", aggregatodistributorid.toString());
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/aggrdistrmarsresponse/" +
          aggregatodistributorid,
        {
          headers: header,
          //params: httpparam,
          observe: "body",
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  syncSuperGroupMerchant(type: string) {
    let header = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/syncsupergroupmerchant/" +
          type,
        {
          headers: header,
          observe: "body",
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  allSuperGroupMerchant() {
    let header = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/marsallsupergroupmerchant",
        {
          headers: header,
          observe: "body",
          responseType: "json",
        }
      )
      .map((res) => res);
  }

  getAggregatorIdName() {
    let header: HttpHeaders = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/aggregatornameid",
        { headers: header, observe: "body", responseType: "json" }
      )
      .map((res) => res);
  }

  saveSourcingBranch(sourcingbranch: BankSourcingBranchModel) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/sourcingbranch",
        sourcingbranch,
        { headers: header }
      )
      .map(res => res);
    
  }

  getFieldDisplayName(fieldName: string) {
    const fieldNameList : object = {
      "enrollmentDate":"Enrollment Date",
      "agreementdate":"Agreement Date",
      "region":"Region",
      "salesPerson":"Sales Person",
      "sponsorbank":"Spronsor Bank",
      "institution":"Institution",
      "aggregatordistributorname" : "Name",
      "companyname":"Company Name",
      "mobilenumber":"Mobile Number",
      "email":"Email",
      "website":"Website",
      "aadhaar": "Aadhaar",
      "pan":"PAN",
      "gst":"GST",
      "passportNumber":"Passport Number",
      "businessAddress" : "Business Address",
      "businessAddressPinCode" : "Business Address Pincode",
      "businessAddressCity": "Business Address City",
      "businessAddressState" : "Business Address State",
      "residenceAddress" : "Resendence Address",
      "residenceAddressPinCode":"Resendence Address Pincode",
      "residenceAddressCity" : "Resendence Address City",
      "residenceAddressState" : "Resendence Address State",
      "aggregatorkin": "KIN",
      "bankSettlementBy" : "Settlement By",
      "bankAccountType" : "Account Type",
      "bankIFSCCode" : "IFSC Code",
      "bankName" : "Bank Name",
      "branchName" : "Branch Name",
      "bankCity" : "Bank City",
      "bankState" : "Bank State",
      "merchantBankPinCode" : "Bank Pincode",
      "bankPaymentMode" : "Payment Mode",
      "bankAccountNumber" : "Account Number",
      "bankMICR" : "Bank MICR",
      "bankUMRN" : "UMRN",
      "bankRemarks" : "Bank Remarks",
      "bankFeeForAddressChange" : "Bank Fee for Address Change",
      "bankPaymentDetails" : "Payment details"
    }

    let result = fieldNameList[fieldName] ? fieldNameList[fieldName] : fieldName;
    return result;
  }
}
