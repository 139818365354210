import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
export class gridColumns {
  columnHeader: string;
  columnFieldName?: string;
  columnClasses?: string = "";
  callbackFunction?: Function;
  callbackFunctionRowPrm?: Function;
  callCellClickFunction?: Function;
  isSrCol?: boolean = false;
  callClassFunction?: Function;
  _parentscope?: any;
}
export class TableParams {
  rowClassFunction?: Function;
}
@Component({
  selector: "app-simplegrid",
  templateUrl: "./simplegrid.component.html",
  styleUrls: ["./simplegrid.component.css"],
})
export class SimplegridComponent implements OnInit {
  @Input()
  gridcolumns: gridColumns[];
  @Input()
  tableParams: TableParams;
  @Input()
  data: Array<object>;
  @Input()
  deleteButton: boolean = false;
  @Output()
  loadDataEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  gridRowClickEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  deleteClickedEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  rowDblClickEvent: EventEmitter<object> = new EventEmitter<object>();

  ngOnInit() {
    this.refreshData();
  }
  refreshData() {
    this.loadDataEvent.emit(this.data);
  }
  selectRow(data, i) {
    let obj = { data: data, index: i };
    this.gridRowClickEvent.emit(obj);
  }
  getValue(obj: any, col: gridColumns) {
    let retvalue: string;
    let colfieldname = col.columnFieldName.split(".");
    if (col.callbackFunctionRowPrm) {
      retvalue = col.callbackFunctionRowPrm(obj,col._parentscope);
    } else {
      let objColVal: any = obj;
      colfieldname.forEach((part) => {
        if (objColVal) {
          objColVal = objColVal[part];
        }
      });

      if (col.callbackFunction) {
        //retvalue= col.callbackFunction(obj[col.columnFieldName]);
        retvalue = col.callbackFunction(objColVal, col._parentscope);
      } else {
        retvalue = objColVal;
        //retvalue= obj[col.columnFieldName];
      }
    }
    return retvalue;
  }
  cutText = function (text: string, length: number) {
    if (text.length > length) {
      return text.substr(1, length - 3) + "...";
    } else {
      return text;
    }
  };
  deleteRec(obj: object, index: number) {
    let options = { obj: obj, index: index };
    this.deleteClickedEvent.emit(options);
  }
  onDblClick(data, i) {
    let obj = { data: data, index: i };
    this.rowDblClickEvent.emit(obj);
  }
  getColClass(obj: any, col: gridColumns) {
    let retvalue: string;
    let colfieldname = col.columnFieldName.split(".");
    let objColVal: any = obj;
    colfieldname.forEach((part) => {
      if (objColVal) {
        objColVal = objColVal[part];
      }
    });
    if (col.callClassFunction) {
      retvalue = col.callClassFunction(objColVal);
    }
    if (col.columnClasses) {
      retvalue = retvalue
        ? retvalue + " " + col.columnClasses
        : col.columnClasses;
    }
    return retvalue;
  }

  cellClick(O, col) {
    if (col.callCellClickFunction) {
      col.callCellClickFunction(O, col,col._parentscope);
    }
  }

  rowClass(O) {
    if (this.tableParams && this.tableParams.rowClassFunction) {
      return this.tableParams.rowClassFunction(O);
    }
  }
}
