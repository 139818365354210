import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { gridColumns } from "../util/components/gridtable/gridtable.component";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable({
  providedIn: "root"
})
@Injectable()
export class ExcelService {
  constructor() {}

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    gridcol: gridColumns[]
  ): void {
    const convertedjson = this.getConvertedData(json, gridcol);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertedjson);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"]
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array"
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  getConvertedData(json: any[], gridcol: gridColumns[]) {
    let jsonarr: Array<any> = new Array<any>();
    for (let cnt = 0; cnt < json.length; cnt++) {
      let jsonitem = json[cnt];
      let jsonobj = {};
      let addItem = false;
      for (let i = 0; i < gridcol.length; i++) {
        if (
          !(
            gridcol[i].isSrCol ||
            gridcol[i].columnHeader == "" ||
              gridcol[i].columnHeader == undefined
          )
        ) {
          if (jsonitem[gridcol[i].columnFieldName]) {
            jsonobj[gridcol[i].columnHeader] = this.getValue(
              jsonitem,
              gridcol[i]
            );
            addItem = true;
          }
        }
      }
      if (addItem) {
        jsonarr.push(jsonobj);
      }
    }
    return jsonarr;
  }

  getValue(obj: any, col: gridColumns) {
    let retvalue: string;
    let colfieldname = col.columnFieldName.split(".");
    if (col.callbackFunctionRowPrm) {
      retvalue = col.callbackFunctionRowPrm(obj);
    } else {
      let objColVal: any = obj;
      colfieldname.forEach(part => {
        if (objColVal) {
          objColVal = objColVal[part];
        }
      });

      if (col.callbackFunction) {
        //retvalue= col.callbackFunction(obj[col.columnFieldName]);
        retvalue = col.callbackFunction(objColVal);
      } else {
        retvalue = objColVal;
        //retvalue= obj[col.columnFieldName];
      }
    }
    return retvalue;
  }
}
