import { TransRatesModel } from "./trans.rate";
import { AggregatorDistributorModel } from "./aggregator.distributor.model";

export class MDRPlanModel {
  code?: number;
  name?: string;
  masterPricingPlanId?: number;
  aggregator?: AggregatorDistributorModel;
  creationDate?: Date;
  /*
   * S -Small
   * B - Big
   */
  merchantCategory?: string;

  /*
   * Y -Yes
   * N - No
   */
  partnerSharing?: string;

  /*
   * E - Emerging
   * N - Non emerging
   */

  businessSegment?: string;
  transRates?: Array<TransRatesModel>;
  description?: string;

  /*
   * A - Active
   * I - Inactive
   */
  status?: string;
  marsCode?: number;
}
