import { Component, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import {
  GridOptions,
  IDatasource,
  IGetRowsParams,
  GridApi
} from "ag-grid-community";
import { TransactionService } from "src/app/reports/services/transaction.service";
import {
  TopbarComponent,
  TopbarText
} from "../../../framework/components/topbar/topbar.component";
import { TransactionClickComponent } from "./trasactionclick.component";
import { TransactionReportBtnRefreshComponent } from "./transactionreportbtnrefresh.components";
import { Tools } from "src/app/framework/modules/tools";
import { TransactionModel } from "src/app/reports/models/transaction.model";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import {
  SimpleMessage,
  SortClass
} from "../../../framework/modules/util.class";
import { GridPage } from "src/app/framework/util/components/gridtable/gridtable.component";
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-transactionreport",
  templateUrl: "./transactionreport.component.html",
  styleUrls: ["./transactionreport.component.css"]
})
export class TransactionreportComponent implements OnInit {
  @ViewChild("agGrid", { static: true }) agGrid: AgGridAngular;
  @ViewChild("snav", { static: true }) sideNave: any;
  topbartext: TopbarText[] = [
    {
      key: "transactionreport",
      displayText: "Transaction Report"
    }
  ];
  public gridOptions: Partial<GridOptions>;
  private gridApi: GridApi;
  public gridColumnApi;
  public gridColumnDefs;
  public datalist;
  public data: TransactionModel;
  public cacheOverflowSize;
  public maxConcurrentDatasourceRequests;
  public infiniteInitialRowCount;
  public frameworkComponents;
  public context;
  public searchField;
  public sort: SortClass;
  page: GridPage;
  constructor(
    private transactionservice: TransactionService,
    public tools: Tools,
    private ngbModalService: NgbModal
  ) {
    this.searchField = { dateFrom: new Date(), dateTill: new Date() };
    this.context = { componentParent: this };
    this.setGridConfig();
    this.data = new TransactionModel();
    this.setRowData();
  }
  setGridConfig() {
    this.setGridColDef();
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 2;
    this.infiniteInitialRowCount = 2;
    this.frameworkComponents = {
      clickableComponent: TransactionClickComponent,
      headerRefreshBtnComponent: TransactionReportBtnRefreshComponent
    };
    this.gridOptions = {
      headerHeight: 45,
      rowHeight: 30,
      cacheBlockSize: 50,
      paginationPageSize: 50
      //rowModelType: "infinite"
    };
  }

  ngOnInit() {}

  setRowData() {
    const params = {};
    this.transactionservice
      .getTransactions(this.searchField, this.page, this.sort)
      .subscribe(data => {
        let out;
        let totalRecords;
        if (data["success"]) {
          this.datalist = data["result"];
        }
      });
  }

  setGridColDef() {
    this.gridColumnDefs = [
      {
        headerName: "Invoice Id",
        field: "id",
        sortable: true,
        filter: "agNumberColumnFilter",
        //checkboxSelection: true,
        sort: "desc",
        resizable: true
      },
      {
        headerName: "DateTime",
        field: "datetime",
        sortable: true,
        filter: "agDateColumnFilter",
        resizable: true
      },
      {
        headerName: "Login Id",
        field: "userId",
        sortable: true,
        type: "numericColumn",
        filter: "true",
        resizable: true
      },
      {
        headerName: "Amount",
        field: "amount",
        sortable: true,
        filter: "agNumberColumnFilter",
        type: "numericColumn",
        resizable: true
      },
      {
        headerName: "Customer Id",
        field: "customerid",
        sortable: true,
        filter: true,
        type: "numericColumn",
        resizable: true
      },
      {
        headerName: "Transaction Type",
        field: "transactionType",
        sortable: true,
        filter: true,
        resizable: true
      },
      {
        headerName: "Status",
        field: "status",
        sortable: true,
        filter: true,
        resizable: true
      },
      {
        headerComponentFramework: TransactionReportBtnRefreshComponent,
        field: "value",
        cellRenderer: "clickableComponent",
        colId: "params"
      }
    ];
  }
  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    const selectedDataStringPresentation = selectedData
      .map(node => node.make + " " + node.model)
      .join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  }

  onGridReady(params) {
    console.log("On grid ready");
    this.gridApi = params.api;
    this.gridColumnApi = params.gridColumnApi;

    var datasource = {
      getRows: (params: IGetRowsParams) => {
        this.transactionservice
          .getTransactions(this.searchField, this.page, this.sort)
          .subscribe(data => {
            let out;
            let totalRecords;
            if (data["success"]) {
              out = data["result"];
              totalRecords = 1000;
            }
            params.successCallback(out, totalRecords);
          });
      }
    };
    //this.gridApi.setDatasource(datasource);
    this.gridApi.sizeColumnsToFit();
  }
  refreshClick() {
    this.setRowData();
  }

  showClick(node) {
    this.sideNave.open();
    const id: number = node && node.data ? node.data.id : undefined;
    if (id) {
      this.transactionservice.getTransaction(id).subscribe(
        data => {
          if (data["success"]) {
            this.data = data["result"];
          } else {
            this.data = undefined;
            let objmsg: SimpleMessage = new SimpleMessage();
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            objmsg.title = "Error";
            objmsg.message =
              "Error response from server while fetching transaction data!";
            objmsg.btnClass = "btn-error";
            this.tools.simpleMessage(objmsg);
          }
        },
        err => {
          this.data = undefined;
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Error";
          objmsg.message = "Error in fetching transaction data!";
          objmsg.btnClass = "btn-error";
          this.tools.simpleMessage(objmsg);
        }
      );
    }
  }
  onPaginationChanged(params) {}
}
