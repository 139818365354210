import { Component, OnInit } from '@angular/core';
import { MerchantProfileService } from 'src/app/services/merchant-profile.service';
import { MerchantModel, MerchantBankDetailModel } from 'src/app/models/merchant.model';
import { TopbarText } from 'src/app/framework/components/topbar/topbar.component';
import { MerchantService } from 'src/app/services/merchant.service';
import { TransRatesModel } from 'src/app/models/trans.rate';
import { Tools } from 'src/app/framework/modules/tools';
import { global } from 'src/app/framework/modules/global';
import { gridColumns } from 'src/app/framework/util/components/gridtable/gridtable.component';
declare var jquery: any;
declare var $: any;
import * as jspdf from "jspdf";
import { UserOptions } from "jspdf-autotable";
import html2canvas from "html2canvas";
import { Observable, Observer } from 'rxjs';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-merchant-profile',
  templateUrl: './merchant-profile.component.html',
  styleUrls: ['./merchant-profile.component.css']
})
export class MerchantProfileComponent implements OnInit {
  qrURL: any;
  topbartext: TopbarText[] = [
    { key: "merchantprofile", displayText: "MERCHANT PROFILE", class: "col-md-5" },
    { key: "profileterminal", displayText: "TERMINALS",class:"col-md-4" }
  ];
  data: any;
  transRates: Array<TransRatesModel>;
  merchantTerminalList: [];
  _menuId: string;
  _isTerminalScreen: boolean;
  vpaId: string;
  terminalLocation: string;
  terminalgridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Terminal ID",
      columnFieldName: "tid",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "VPA ID",
      columnFieldName: "vpaId",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "Location",
      columnFieldName: "deviceLocation",
      columnClasses: "c-col-pc-15",
    },
    {
      columnHeader: "Model",
      columnFieldName: "modelName",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "Device Sr No",
      columnFieldName: "deviceSerialNumber",
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "Rental Plan",
      columnFieldName: "rentalPlanName",
      columnClasses: "c-col-pc-14"
      //_parentscope: this,
      //callbackFunction: this.getRentalPlanName,
    },
    {
      columnHeader: "Status",
      columnFieldName: "status",
      callbackFunction: this.getStatusText,
      columnClasses: "c-col-pc-12",
    },
    {
      columnHeader: "QR",
      columnFieldName: "qrCodeUrl",
      callbackFunction: this.getQr,
      columnClasses: "c-col-pc-8",
      callClassFunction : this.terminalCellClass,
      callCellClickFunction : this.qrClick,
      _parentscope : this
    },
  ];

  constructor(private merchantprofileservice: MerchantProfileService,
    private merchantService: MerchantService, private tools: Tools,
    private objGlobal: global,private sanitizer: DomSanitizer) {
    this._isTerminalScreen = false;
    this.qrURL = "assets/images/blank.png";
    this.data = {};
    this._menuId = this.tools.getMenuName("main/merchantprofile");
    this.objGlobal.setActiveMenu(this._menuId);
    this.merchantService.loadMerchantConfig();
    this.getMerchantProfile();
   }

  ngOnInit() {
  }
  getAccountType() {
    let result = "";
    if (this.merchantService.bankAccountType && this.data.accountType) {
      const acType = this.merchantService.bankAccountType.find(item => item.code === this.data.accountType)
      if (acType) {
        result = acType.name;
      }
    }
    return result;
  }

  onTabClick(event) {
    if (event === "profileterminal") {
      this._isTerminalScreen = true;
    } else {
      this._isTerminalScreen = false;
    }
  }
  /*getRentalPlanName(v: number, parentscope: any) {
    let rentalPlanName: string;
    if (parentscope.rentalplanlist) {
      const selrentalplan = parentscope.rentalplanlist.filter(
        (itm) => itm.code == v
      );
      if (selrentalplan && selrentalplan.length > 0) {
        rentalPlanName = selrentalplan[0].name;
      } else {
        rentalPlanName = undefined;
      }
    }
    return rentalPlanName;
  }*/  

  getStatusText(v: string) {
    switch (v) {
      case "PB": {
        return "Pre-Boareded";
      }
      case "S": {
        return "Boarding";
      }
      case "R": {
        return "Ready for approval";
      }
      case "A": {
        return "Approved";
      }
      case "J": {
        return "Rejected";
      }
      case "C": {
        return "Boarded";
      }
      default:
        return undefined;
    }
  }

  getMerchantProfile() {
    this.merchantprofileservice.getMerchantProfile().subscribe(res => {
      if (res["success"]) {
        this.data = res["result"]
        this.transRates = this.data.transRates;
        this.merchantTerminalList = this.data.terminals;
      } else {
       // this.data = new MerchantModel()
      }
    }, err => {
        //this.data = new MerchantModel()
    })
  }
  onQrCloseClick() {
    $("#qrreview").modal("hide");
  }
  getQr(val) {
    if (val) {
      return 'QR';
    }
  }
  qrClick_3(O, col, _scope) {
    if (O.qrCodeUrl && O.qrCodeUrl != "") {
      _scope.qrURL = O.qrCodeUrl;
       $("#qrreview").modal({ backdrop: false });
    }
    _scope.merchantprofileservice.getFileFromUrl(O.qrCodeUrl).subscribe(data => {
      console.log(data);
    })
    /*fetch("https://img.etimg.com/thumb/width-640,height-480,imgsize-49172,resizemode-1,msid-74960608/wealth/save/how-does-upi-work/upi-twitter.jpg").then(res => {
      console.log(res);
    }).then();*/
  }

  qrClick(O, col, _scope) {
    _scope.vpaId = O.vpaId;
    _scope.terminalLocation = O.deviceLocation;
    if (O.qrCodeUrl && O.qrCodeUrl != "") {
           
      _scope.merchantprofileservice.getFileFromUrl(O.qrCodeUrl).subscribe(data => {
        const strFileNameArr = O.qrCodeUrl.split("/");
        const fileName = strFileNameArr[strFileNameArr.length - 1];
   
        let file: File = new File([data], fileName, { "type": data.type });
      
        var reader = new FileReader();
        if (file) {
          var mimeType = data.type;

          if (mimeType.match(/image\/*/) != null) {

            reader.readAsDataURL(file);
            reader.onload = (_event) => {
              const fileUrl = reader.result;
              _scope.qrURL = fileUrl;
              if (_scope.qrURL && _scope.qrURL != "") {
                $("#qrreview").modal({ backdrop: false });
              }
            };
          }
        }
      }, err => {
          console.error("Error to get qr code file")
      })
     
    } else {
      _scope.qrURL = "assets/images/blank.png";
    }
  }

  terminalCellClass(val) {
    if (val && val != "") {
      return "link";
    }
  }

  downloadpdf() {
    var data = document.getElementById("qrcontent");
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, pageHeight);
      pdf.save(this.vpaId + "_qr.pdf"); // Generated PDF
    });
  }

  qrClick_2(O, col, _scope) {
    if (O.qrCodeUrl && O.qrCodeUrl != "") {
      _scope.getBase64ImageFromURL(O.qrCodeUrl).subscribe(base64data => {
      console.log(base64data);
      _scope.qrURL = 'data:image/jpg;base64,' + base64data;
      });
    }
  }
  
  getBase64ImageFromURL(url: string,_scope) {
    return Observable.create((observer: Observer<string>) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(_scope.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(_scope.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    console.log(dataURL);
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
}
