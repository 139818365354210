import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-terminal-deactivation-message",
  templateUrl: "./terminal-deactivation-message.component.html",
  styleUrls: ["./terminal-deactivation-message.component.css"],
})
export class TerminalDeactivationMessageComponent implements OnInit {
  @Input()
  terminalType: string;
  @Input()
  terminalStatus: string;
  
  terminal_tid: string;
  deviceSerialNumber: string;
  deactivationReason: string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  ok() {
    this.activeModal.close({
      tid: this.terminal_tid,
      deviceSerialNumber: this.deviceSerialNumber,
      deactivationReason: this.deactivationReason,
    });
  }
  cancel() {
    this.activeModal.dismiss("cancel");
  }
  disableOk() {
    let result: boolean = true;
    if (
      (this.terminalType==="UPI" || this.deviceSerialNumber) &&
      this.terminal_tid &&
      this.deactivationReason
    ) {
      result = false;
    }
    return result;
  }

  setTitle() {
    if (this.terminalStatus == "D") {
      return "Reactivate Terminal";
    } else {
      return "Deactivate Terminal";
    }
  }
}
