export class SIMStockModel {
  simStockId?: number;
  simNumber?: string;
  msisdn?: string;
  simOperator?: string;
  invoiceNumber?: string;
  invoiceDate?: Date;
  stockEntryDate?: Date;
  simStatus?: string;
  remarks?: string;
  allowMultiTerminal?: boolean;
  constructor() {}
}

export class SIMReturnModel {
  simReturnId?: number;
  entryDate?: Date;
  simCondition?: string;
  reasonOfReturn?: string;
  remarks?: string;
  simStock?: SIMStockModel = new SIMStockModel();

  constructor() {}
}
