import { AppSettings } from "../config/AppSettings";
import { Injectable } from "@angular/core";
import { Component } from "ag-grid-community";
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
@Injectable()
export class global {
  isImagePathSet: boolean = false;
  imageLoadSubject= new Subject<any>();
  currentScope: any;
  activeMainMenu: string;
  activeMenu: string;
  currentMenuPath: string;
  whiteLabelCode: string;
  topbarImage: any;
  Auth: {
    token: any;
    user: string;
    aggregatorId?: number;
    aggregatorCode?: string;
    distributorId?: number;
    merchantId?: number;
  };
  constructor(private appsetting: AppSettings) {
    this.Auth = {
      token: "",
      user: "",
      distributorId: undefined,
      merchantId: undefined
    };
    // this.loadAuthToken();
  }
  setActiveMenu(menuname: string) {
    let menusdata = menuname.split("=>");
    this.activeMainMenu = menusdata[0];
    //console.log("set active menu " + menusdata);
    if (menusdata[1]) {
      this.activeMenu = menusdata[1];
    } else {
      this.activeMenu = menusdata[0];
    }
  }
  getCurrentWhiteLabelCode() {
    if (!this.whiteLabelCode) {
      this.whiteLabelCode = this.getWhiteLableCodeOfUrl();
    }
    return this.whiteLabelCode;
  }
  getWhiteLableCodeOfUrl() {
    let whitelabelcode: string = "EZSWYPE";
    if (
      this.appsetting &&
      this.appsetting.get("whitelabel-url") &&
      this.appsetting.get("whitelabel-url")[document.location.origin]
    ) {
      whitelabelcode = this.appsetting.get("whitelabel-url")[
        document.location.origin
      ];
    }
    return whitelabelcode;
  }

  getImageLoadSubject() {
    return this.imageLoadSubject.asObservable();
  }

}
