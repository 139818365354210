import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-yesno',
  template: `<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="yes()">{{ok}}</button>
  <button type="button" class="btn btn-secondary" (click)="no()">{{cancel}}</button>
</div>`
})
export class NgYesNoModalContent {
  @Input() title : string;
  @Input() message : string;
  @Input() ok : string="Yes";
  @Input() cancel : string="No";
  constructor(public activeModal: NgbActiveModal) {}
  yes() {
      this.activeModal.close('yes');    
  }

  no() {
    this.activeModal.dismiss("no");
  }
}

@Component({
    selector: 'modal-information',
    template: `<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" [ngClass]="btnClass" (click)="ok()">Ok</button>   
  </div>`
  })
  export class NgInformationModalContent {
    @Input() title : string="Information";
    @Input() message : string;
    @Input() btnClass : string = "btn-info"
    constructor(public activeModal: NgbActiveModal) {}
    ok() {
        this.activeModal.close('ok');    
    }  
  }