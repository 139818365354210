import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { RentalPlanModel } from "src/app/models/rental.plan.model";
import { AggregatorDistributorModel } from "src/app/models/aggregator.distributor.model";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AggregatorDistributorService } from "src/app/services/aggregator.distributor.service";
import { Tools } from "src/app/framework/modules/tools";
import { RentalplanService } from "src/app/services/rentalplan.service";
import { NgYesNoModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";
import {
  SimpleMessage,
  MARSResponseErrorMsg,
} from "../../framework/modules/util.class";
import { MARSErrorResponse } from "src/app/models/mars.response.error.module";

@Component({
  selector: "app-rentalplan-detail",
  templateUrl: "./rentalplan-detail.component.html",
  styleUrls: ["./rentalplan-detail.component.css"],
})
export class RentalplanDetailComponent implements OnInit {
  @Input()
  addPriv: boolean = false;
  @Input()
  editPriv: boolean = false;
  @Input()
  action: string = "";
  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  _isChange: boolean = false;
  data: RentalPlanModel;
  _data: RentalPlanModel;
  aggregator: AggregatorDistributorModel;
  _aggregator: AggregatorDistributorModel;
  _busy: boolean = false;
  userAggregatorDistributorList: Array<AggregatorDistributorModel>;
  constructor(
    private ngbModalService: NgbModal,
    public aggregatordistributedservice: AggregatorDistributorService,
    public tools: Tools,
    private rentalplanservice: RentalplanService
  ) {
    this.onAddNew(false);
    this.loadAggregatorDistributor();
  }

  ngOnInit() {}
  async loadAggregatorDistributor() {
    this.userAggregatorDistributorList = await this.aggregatordistributedservice.loadAggregatorDistrbutorIdNameTypeOfUserSelfChild();
  }
  onAddNew(copydummy: boolean) {
    this._isChange = false;
    this.data = new RentalPlanModel();
    this.aggregator = new AggregatorDistributorModel();
    if (copydummy) {
      this.copyInitData();
    }
  }
  copyInitData() {
    this._data = this.tools.copyFullObject(this.data);
    this._aggregator = this.tools.copyFullObject(this.aggregator);
  }
  closeScreen() {
    this.data = new RentalPlanModel();
    this._data = undefined;
    this.onClose.emit(this._isChange);
  }
  getAggregatorList() {
    let aggreationlist: Array<AggregatorDistributorModel>;
    if (this.userAggregatorDistributorList) {
      aggreationlist = this.userAggregatorDistributorList.filter(
        (item) => item.type == "I" || item.type == "A"
      );
    }
    return aggreationlist;
  }
  isModified() {
    if (!this.data || !this._data) {
      return false;
    }
    if (this.data.name != this._data.name) {
      return true;
    } else if (this.data.name != this._data.name) {
      return true;
    } else if (this.data.rent != this._data.rent) {
      return true;
    } else if (this.data.rentFrequency != this._data.rentFrequency) {
      return true;
    } else if (this.data.description != this._data.description) {
      return true;
    } else if (this.aggregator && !this._aggregator) {
      return true;
    } else if (!this.aggregator && this._aggregator) {
      return true;
    } else if (
      this.aggregator &&
      this._aggregator &&
      this.aggregator.id != this._aggregator.id
    ) {
      return true;
    }
    return false;
  }

  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Rental plan has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.action = "";
            this.closeScreen();
          }
        },
        (reason) => {}
      );
    } else {
      this.action = "";
      this.closeScreen();
    }
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this.action == "new") {
      isAllowSave = this.addPriv;
    } else if (this.action == "update") {
      isAllowSave = this.editPriv;
    }
    return isAllowSave;
  }
  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (!this._busy) {
      if (f.valid) {
        if (this.isModified()) {
          if (this.isAllowAddorEdit()) {
            isDisableSave = false;
          }
        }
      }
    }
    return isDisableSave;
  }
  disableCancel() {
    if (this._busy) {
      return true;
    }
    return !this.isModified();
  }

  disableBackbutton() {
    return this._busy;
  }

  onSaveClick() {
    if (this.isModified()) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Are you sure to save Rental Plan?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            if (this.aggregator.id != undefined) {
              this.data.aggregator = this.aggregator;
            }
            let objmsg: SimpleMessage = new SimpleMessage();
            let ngmodaloptions: NgbModalOptions = {};
            ngmodaloptions.backdrop = "static";
            ngmodaloptions.size = "sm";
            document.body.style.cursor = "wait";
            this._busy = true;
            this.rentalplanservice.saveRentalPlan(this.data).subscribe(
              (data) => {
                if (data["success"]) {
                  this.data = data["result"];
                  this.aggregator = this.data.aggregator;
                  this.copyInitData();
                  this._isChange = true;
                  if (this.action == "new" || !this.data.marsCode) {
                    this.action = "update";
                    this.submitToMars("S");
                  } else {
                    document.body.style.cursor = "default";
                    objmsg.title = "Success";
                    objmsg.message = "Rental Plan successfully saved!";
                    objmsg.btnClass = "btn-success";
                    this._busy = false;
                    this.tools.simpleMessage(objmsg);
                  }
                } else {
                  document.body.style.cursor = "default";
                  this._busy = false;
                  if (data["message"] == "INVALIDCODE") {
                    objmsg.message = "Invalid code to save rental plan!";
                  } else if (data["message"] == "NAMEALREADYEXISTS") {
                    objmsg.message = "Plan name already exists for aggregator!";
                  } else if (data["message"] == "Error") {
                    objmsg.message = "Failed to save Rental Plan!";
                  }
                  objmsg.title = "Error";
                  objmsg.btnClass = "btn-error";
                  this.tools.simpleMessage(objmsg);
                }
              },
              (error) => {
                objmsg.title = "Error";
                objmsg.message = "Failed to save Rental Plan!";
                objmsg.btnClass = "btn-error";
                this.tools.simpleMessage(objmsg);
                document.body.style.cursor = "default";
                this._busy = false;
              }
            );
          }
        },
        (reason) => {}
      );
    }
  }

  submitToMars(source: string) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    let objmsg: SimpleMessage = new SimpleMessage();
    document.body.style.cursor = "wait";
    this._busy = true;
    this.rentalplanservice.updateToMARS(this.data).subscribe(
      (res) => {
        document.body.style.cursor = "default";
        this._busy = false;
        if (res["success"]) {
          if (res["result"] && res["result"].marsCode) {
            this.data.marsCode = res["result"].marsCode;
            this.data.status = res["result"].status;

            this._data.marsCode = res["result"].marsCode;
            this._data.status = res["result"].status;
          }
          objmsg.title = "Success";
          if (source == "D") {
            objmsg.message = "Rental Plan MARS updated in MARS successfully!";
          } else {
            objmsg.message = "Rental Plan successfully saved!";
          }
          objmsg.btnClass = "btn-success";
        } else {
          const msg: String = res["message"];
          objmsg.title = "Warning";
          if (source == "D") {
            objmsg.message = "MARS update failed, error - " + msg;
          } else {
            objmsg.message =
              "Rental Plan saved successfully but MARS update failed, error - " +
              msg;
          }
          objmsg.btnClass = "btn-warning";
        }
        this.tools.simpleMessage(objmsg);
      },
      (err) => {
        document.body.style.cursor = "default";
        this._busy = false;
        objmsg.title = "Warning";
        if (source == "D") {
          objmsg.message = "MARS update failed, error - " + err;
        } else {
          objmsg.message =
            "Rental Plan saved successfully but MARS update failed, error - " +
            err;
        }
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  getRentalPlanDetail(code: number) {
    this._isChange = false;
    this.rentalplanservice.getRentalPlanDetail(code).subscribe(
      (data) => {
        if (data["success"]) {
          this.data = data["result"];
          this.aggregator = this.data.aggregator;
          this.copyInitData();
        } else {
          let objmsg: SimpleMessage = new SimpleMessage();
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          objmsg.title = "Error";
          objmsg.btnClass = "btn-error";
          if (data["message"] == "INVALIDCODE") {
            objmsg.message = "Invalid Rental Plan!";
            this.tools.simpleMessage(objmsg);
          } else if (data["message"] == "Error") {
            objmsg.message = "Failed to save Rental Plan!";
            this.tools.simpleMessage(objmsg);
          }
        }
      },
      (error) => {
        let objmsg: SimpleMessage = new SimpleMessage();
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        objmsg.title = "Error";
        objmsg.message = "Failed to save Rental Plan!";
        objmsg.btnClass = "btn-error";
        this.tools.simpleMessage(objmsg);
      }
    );
  }

  onCancelClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Are you sure to discard the changes?";
      ngmodalref.result.then(
        (result) => {
          if (result == "yes") {
            this.data = this._data;
            this.aggregator = this._aggregator;
            this.copyInitData();
          }
        },
        (reason) => {}
      );
    }
  }

  getStatus() {
    let returnValue = "";
    switch (this.data.status) {
      case "A":
        returnValue = "Active";
        break;
      case "P":
        returnValue = "Pending for MARS update";
        break;
      case "I":
        returnValue = "Inactive";
        break;
      case "F":
        returnValue = "MARS Update failed";
        break;
    }
    return returnValue;
  }

  showMARSResponseError() {
    if (this.data.code) {
      this.rentalplanservice.getRentalMARSResApi(this.data.code).subscribe(
        (res) => {
          if (res["result"]) {
            const data: MARSErrorResponse = res["result"];
            let objMsg: MARSResponseErrorMsg = new MARSResponseErrorMsg();
            objMsg.forEntity = this.data.name;
            objMsg.errorMsg = data;
            this.tools.marsResponseErrorMessage(objMsg);
          } else {
            console.error(res["message"]);
          }
        },
        (err) => {
          console.error("Error to get MARS Reponse Errors");
        }
      );
    }
  }
}
