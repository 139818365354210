import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { ImageconfigService } from "../../services/imageconfig.service";
import { global } from "../../modules/global";
import { MenuService } from "../../services/menu.service";
import { AppSettings } from "../../config/AppSettings";
import { Tools } from "../../modules/tools";
import { UserIdleService } from "angular-user-idle";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { SimpleMessage } from "../../modules/util.class";
import { Subscription } from 'rxjs';
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"]
})
export class MainComponent implements OnInit, OnDestroy {
  //counterTimer: number = 0;
  imageLoadSubs: Subscription;
  constructor(
    private auth: AuthService,
    private router: Router,
    public imagePath: ImageconfigService,
    private objGlobal: global,
    public menuservice: MenuService,
    private appsetting: AppSettings,
    private tools: Tools,
    private userIdle: UserIdleService,
    
  ) {
    //this.setDefaultMenuActive();
    //console.log("main constructor called");
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.subscribeSessionTimeout();
    this.imageLoadSubscription()
    if (!this.auth.privileges) {
      auth.loadPrivileges().then(() => {
        if (!this.menuservice.menusList) {
          menuservice.loadMenus().then(() => {
            //            console.log(this.menuservice.menusList);
          });
        }
      });
    }
    
  }
  ngOnDestroy() {}
  ngOnInit() { }
  imageLoadSubscription() {
    if (this.objGlobal.Auth.aggregatorCode && this.objGlobal.isImagePathSet) {
      this.imagePath.loadTopbarImage();
    } else {
      this.imageLoadSubs = this.objGlobal.getImageLoadSubject().subscribe(msg => {
        if (this.objGlobal.Auth.aggregatorCode && this.objGlobal.isImagePathSet) {
          this.imagePath.loadTopbarImage();
          this.imageLoadSubs.unsubscribe();
        }
      })
    }
  }
  subscribeSessionTimeout() {
    this.userIdle.onTimeout().subscribe(() => {
      if (!this.auth.isSessionTimeout) {
        this.userIdle.stopWatching();
        this.userIdle.stopTimer();
        this.auth.logout();
        this.router.navigate(["login"]);
        this.auth.isSessionTimeout = true;
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        let objmsg: SimpleMessage = new SimpleMessage();
        objmsg.title = "Timeout";
        objmsg.message = "Session expired!";
        objmsg.btnClass = "btn-warning";
        this.tools.simpleMessage(objmsg);
      }
    });
  }
  isActiveMainMenu(id: string) {
    return id == this.objGlobal.activeMainMenu;
  }
  isActiveMenu(id: string) {
    // console.log("active menu is " + this.objGlobal.activeMenu);
    return id == this.objGlobal.activeMenu;
  }
  isChildExists(menu: any) {
    return menu.child && menu.child.length > 0;
  }
  setActiveMainMenu(menu: any) {
    this.objGlobal.activeMainMenu = menu.name;
    if (!this.isChildExists(menu)) {
      this.setActiveMenu(menu);
    }
  }
  setActiveMenu(menu: any) {
    if (!this.isChildExists(menu)) {
      this.objGlobal.activeMenu = menu.name;
    }
  }
  /*onDashboardClick() {
    this.setDefaultMenuActive();
    this.objGlobal.currentMenuPath = "dashboard";
    this.router.navigate(["main/dashboard"]);
  }
  setDefaultMenuActive() {
    this.objGlobal.activeMainMenu = "dashboard";
    this.objGlobal.activeMenu = "dashboard";
  }*/
  onMainMenuClick(menu: any) {
    this.setActiveMainMenu(menu);
  }
  onMenuClick(mainmenu: any, menu: any) {
    //console.log(menu.name);
    /*if (
      this.objGlobal.currentScope &&
      this.objGlobal.currentScope["isDetailScreen"]
    ) {
      console.log("Detail Screen open");
      if (this.objGlobal.currentScope.isModified()) {
        console.log("Is Modified");
      }
    }*/
    if (menu) {
      this.setActiveMenu(menu);
      this.objGlobal.currentMenuPath = mainmenu.name + "=>" + menu.name;
    } else {
        this.objGlobal.currentMenuPath = mainmenu.name;
        this.objGlobal.activeMainMenu = mainmenu.name;
        this.objGlobal.activeMenu = mainmenu.name;
    }

    const page = this.tools.getMenuNamePage(this.objGlobal.currentMenuPath);
    this.router.navigate([page]);
  }
}
