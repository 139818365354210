import { Component, OnInit, Input } from "@angular/core";
import { TransRatesModel } from "src/app/models/trans.rate";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Tools } from "src/app/framework/modules/tools";
import { NgInformationModalContent } from "src/app/framework/util/components/modalcomponent/modal-content";

@Component({
  selector: "app-transrates",
  templateUrl: "./transrates.component.html",
  styleUrls: ["./transrates.component.css"]
})
export class TransratesComponent implements OnInit {
  @Input()
  mdrPlanTransRates: Array<TransRatesModel>;
  @Input()
  _partnersharing: boolean = false;
  constructor(public tools: Tools, private ngbModalService: NgbModal) {}

  ngOnInit() {}

  getNonChildPlanTrans() {
    
    if (this.mdrPlanTransRates) {
      const nonchildplans = this.mdrPlanTransRates.filter(
        itm => itm.transCategory.parentId == undefined
      );
      return nonchildplans;
    } else {
      return undefined;
    }
  }

  getChildPlanTrans(id: number) {
    if (this.mdrPlanTransRates) {
      const childplans = this.mdrPlanTransRates.filter(
        itm => itm.transCategory.parentId == id
      );
      return childplans;
    } else {
      return undefined;
    }
  }
  formatnumber(e, obj, field) {
    if (e.target.value == "") {
      obj[field] = undefined;
    } else {
      if (e.target.value != "" && isNaN(Number.parseFloat(e.target.value))) {
        this.tools.invalidNumberAlertMsg(e.target);
      } else {
        if (e.target.value) {
          let strValue: string = e.target.value.toString();
          let arr = strValue.split(".");
          if (arr.length > 1) {
            if (arr[1].length < 2) {
              e.target.value = parseFloat(e.target.value).toFixed(2);
            }
          } else {
            e.target.value = parseFloat(e.target.value).toFixed(2);
          }

          if (e.target.min) {
            if (+e.target.value < +e.target.min) {
              this.tools.rateAlertMessage(e.target);
            }
          }
          if (e.target.max) {
            if (+e.target.value > +e.target.max) {
              this.tools.rateAlertMessage(e.target);
            }
          }
        }
      }
      obj[field] = Number.parseFloat(e.target.value);
    }
  }
  invalidNumberAlertMsg(obj) {
    let ngmodaloptions: NgbModalOptions = {};
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRef.componentInstance.title = "Alert";
    modalRef.componentInstance.message = "invalid number!";
    modalRef.componentInstance.btnCLass = "btn-warning";

    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    modalRef.result.then(
      result => {
        obj.focus();
      },
      reason => {
        obj.focus();
      }
    );
  }

  /*rateAlertMessage(obj) {
    const msg = this.tools.getRateToolTip(obj);

    let ngmodaloptions: NgbModalOptions = {};
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRef.componentInstance.title = "Alert";
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.btnCLass = "btn-warning";

    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    modalRef.result.then(
      result => {
        obj.focus();
      },
      reason => {
        obj.focus();
      }
    );
  }
  updateAmount(e, obj, field) {
    obj[field] = e;
    //console.log(e);
  }*/
}
